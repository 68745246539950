import React from 'react';

import { amount, getDate, getImgSrc } from 'func/common';

import { IMyBetsTable } from './types';
import styles from './styles.module.scss';

const MyBetsTable: React.FC<IMyBetsTable> = ({ titles, history }) => {
  return (
    <div className={styles.myBetsTableContent}>
      <div className={styles.myBetsTableThead}>
        <div className={styles.myBetsTableTheadTr}>
          {titles.map((title) => (
            <div className={styles.myBetsTableTheadTh} key={title}>
              <b>{title}</b>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.myBetsTableTbody}>
        {history.map((item) => (
          <div key={item.id} className={styles.myBetsTableTbodyTr} onClick={item.func}>
            <div className={styles.myBetsTableTbodyTd}>
              <span>{getDate(item.createdAt.toString(), true)}</span>
            </div>
            <div className={styles.myBetsTableTbodyTd}>
              <img className={styles.myBetsTableCurrencyImg} src={getImgSrc(item?.displayName)} alt="icon" />
              <small>{String(amount(item.amount)) + ' ' + item?.displayName}</small>
            </div>
            <div className={styles.myBetsTableTbodyTd}>
              <span>{item?.multiplier}</span>
            </div>
            <div className={styles.myBetsTableTbodyTd}>
              <img className={styles.myBetsTableCurrencyImg} src={getImgSrc(item?.displayName)} alt="icon" />
              {Number(item.payout) > 0 ? (
                <strong>{String(amount(item.payout)) + ' ' + item?.displayName}</strong>
              ) : (
                <code>{String(amount(item.payout)) + ' ' + item?.displayName}</code>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyBetsTable;
