import styled from 'styled-components';

const CoinFlipStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1400px;
  padding: 0 0 95px 30px;
  min-height: 100vh;

  .coin__lottery-wrap {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .coin__content--wrap {
    display: flex;
    width: calc(100% - 375px);
    height: 100%;
    flex-direction: column;
    padding: 0 30px 0 0;
  }

  .coin__content--wrap__history {
    display: grid;
    grid-template-columns: minmax(200px, 100%) minmax(min-content, auto);
    grid-column-gap: 15px;
    margin-top: 15px;
  }

  .coin__tooltip {
    display: flex;
    width: 100%;
    margin: 15px 0 19px;

    overflow: hidden;
  }

  @media (max-width: 1300px) {
  }
  @media (max-width: 1260px) {
    padding: 0 30px 95px; //remove to 15 after table get added
    .coin__lottery-wrap {
      flex-direction: column;
    }
    .coin__content--wrap {
      width: 100%;
      padding: 0;
    }
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
    padding: 0 15px 15px;
  }
  @media (max-width: 580px) {
    .coin__content--wrap__history {
      grid-column-gap: 15px;
    }
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 400px) {
  }
`;

export default CoinFlipStyle;
