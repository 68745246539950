import React from 'react';
import { useQuery } from '@apollo/client';

import { prepareHistoryResults } from 'func/prepareDataEvents';
import { EVENT_HISTORY_TABLE_TITLES } from 'components/constants/table';
import TableWithoutPagination from 'components/Base/Table/TableWithoutPagination/TableWithoutPagination';
import { GET_CLOSED_EVENTS } from 'graphQl/query/events/events';

const HistoryEventsTable: React.FC = () => {
  const { data } = useQuery(GET_CLOSED_EVENTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        offset: 0,
        limit: 20,
      },
    },
  });

  return (
    <div className="table__body">
      <div className="table__body1">
        <TableWithoutPagination
          thWidth="25%"
          titles={EVENT_HISTORY_TABLE_TITLES}
          prepareDataTable={prepareHistoryResults}
          data={data?.closedEvents?.items}
          config="eventHistory"
          cutCurrency
        />
      </div>
    </div>
  );
};

export default HistoryEventsTable;
