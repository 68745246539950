import { TranslationType } from 'types';

export enum UserPreferenceType {
  promoEmail = 'promoEmail',
  newsEmail = 'newsEmail',
  accountActivityAlert = 'accountActivityAlert',
  withdrawal = 'withdrawal',
  banned = 'banned',
  language = 'language',
  preferredToken = 'preferredToken',
  enableBet = 'enableBet',
}

export const getPreferencesItems = (t: TranslationType) => [
  { title: t('account.preferences.newsletter'), value: false, type: UserPreferenceType.newsEmail },
  { title: t('account.preferences.offers'), value: false, type: UserPreferenceType.promoEmail },
  // { title: t('account.preferences.allerts'),
  // value: false, type: UserPreferenceType.accountActivityAlert },
];
