/* eslint-disable no-useless-escape */
import React from 'react';

import { useLimbo } from 'context/contextLimbo/contextLimbo';
import { Types } from 'context/contextLimbo/limboReduser';
import { isDisabledButtonLimbo } from 'func/prepareDataLimbo';
import { useLocalization } from 'components/Internationalization';

import { BigPlus, BNMinus, prepareAmount } from 'func/common';
import { bigOrEqual, lessOrEqual, lessThen } from 'func/prepareDataDice';

import styles from './styles.module.scss';

const LimboOptionInput: React.FC = () => {
  const { translate } = useLocalization();
  const [state, dispatch] = useLimbo();

  const { optionInputValue, gameCondition } = state;

  const handleChangeInput = (e) => {
    let value = '';
    value = e.target.value;
    const valid = /^\-?\d+\.\d*$|^\-?[\d]*$/;
    const number = /^(?=.*\d)\d*(?:\.\d{0,2})?$/g;

    if (e.nativeEvent.inputType === 'deleteContentBackward') {
      dispatch({ type: Types.SET_INP_OPTION_VALUE, payload: { value: e.target.value } });
      return;
    }
    if (value === '0') {
      dispatch({ type: Types.SET_INP_OPTION_VALUE, payload: { value: '1' } });
      return;
    }
    if (value === '1.00') {
      dispatch({ type: Types.SET_INP_OPTION_VALUE, payload: { value: '1.01' } });
      return;
    }
    if (Number(value) > 4999.99) {
      dispatch({ type: Types.SET_INP_OPTION_VALUE, payload: { value: '4999.99' } });
      return;
    }
    if (!valid.test(value)) {
      const n = value.match(number);
      value = n ? n[0] : '';
    }
    if (number.test(value)) {
      dispatch({ type: Types.SET_INP_OPTION_VALUE, payload: { value } });
    }
  };

  const handleMinus = () => {
    const total = BNMinus(optionInputValue, 1);
    if (optionInputValue && lessOrEqual(total, '1.01')) {
      dispatch({ type: Types.SET_INP_OPTION_VALUE, payload: { value: '1.01' } });
      return;
    }
    dispatch({ type: Types.SET_INP_OPTION_VALUE, payload: { value: total } });
  };

  const handlePlus = () => {
    const option = prepareAmount(optionInputValue);
    const betValue = BigPlus(option, 1);

    if (lessThen(betValue, 4999.99)) {
      const inpVal = BigPlus(optionInputValue, 1);
      dispatch({ type: Types.SET_INP_OPTION_VALUE, payload: { value: inpVal } });
    }
    if (bigOrEqual(BigPlus(optionInputValue, 1), 4999.99)) {
      dispatch({ type: Types.SET_INP_OPTION_VALUE, payload: { value: '4999.99' } });
    }
  };

  const handleBlur = () => {
    if (!optionInputValue || Number(optionInputValue) < 1.01) {
      dispatch({ type: Types.SET_INP_OPTION_VALUE, payload: { value: '2' } });
    }
  };

  return (
    <div className={styles.limboOptionInputWrap}>
      <button
        disabled={isDisabledButtonLimbo(gameCondition)}
        type="button"
        className={styles.limboOptionInputContentBtn}
        onClick={handleMinus}
      >
        -
      </button>
      <div className={styles.limboOptionInputContent}>
        <h3>{translate('limbo.input.title')}</h3>
        <input
          disabled={isDisabledButtonLimbo(gameCondition)}
          type="text"
          className={styles.limboOptionInputContentInput}
          value={optionInputValue}
          onChange={handleChangeInput}
          onBlur={handleBlur}
        />
      </div>
      <button
        disabled={isDisabledButtonLimbo(gameCondition)}
        type="button"
        className={styles.limboOptionInputContentBtn}
        onClick={handlePlus}
      >
        +
      </button>
    </div>
  );
};

export default LimboOptionInput;
