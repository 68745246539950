import React, { useState } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';
import RankSystemCard from './components/RankSystemCard';
import { IRankSystem } from './types';

const RankSystem: React.FC<IRankSystem> = ({ rankList }) => {
  const [myRank, setMyRank] = useState({ my: null, active: 0 });

  return (
    <div className={clsx(styles.rankSystemWrap, myRank.my === myRank.active && styles.rankSystemActive)}>
      <RankSystemCard rankList={rankList} setMyRank={setMyRank} />
    </div>
  );
};

export default RankSystem;
