import React from 'react';

import AutoSwitch from 'components/Games/base/AutoSwitch';

import { IKenoCheckboxAuto } from './types';
import styles from './styles.module.scss';

const KenoCheckboxAuto: React.FC<IKenoCheckboxAuto> = ({ value, onCheck, disable }) => {
  return (
    <div className={styles.kenoCheckboxAutoWrap}>
      <AutoSwitch checked={value} disabled={disable} onCheck={onCheck} />
    </div>
  );
};

export default KenoCheckboxAuto;
