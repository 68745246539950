import soundOnIcon from 'assets/img/WheelFortune/sound-on.webp';
import soundOffIcon from 'assets/img/WheelFortune/sound-off.webp';

import styles from './styles.module.scss';
import { IWheelOfFortuneMusic } from './types';

const WheelOfFortuneMusic: React.FC<IWheelOfFortuneMusic> = ({ enabled, onChange }) => {
  return (
    <button className={styles.button} type="button" onClick={() => onChange(!enabled)}>
      <img className={styles.icon} src={enabled ? soundOnIcon : soundOffIcon} alt="Sound" />
    </button>
  );
};

export default WheelOfFortuneMusic;
