import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

const MinesSliderDataStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 55px;
  width: 100%;
  padding: 0 30px;
  overflow: hidden;

  .mines-slider {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .slick-slide {
    div {
      display: flex;
      width: 100%;
      height: 55px;
      justify-content: center;
      align-items: center;
    }
  }

  .slide {
    height: 45px;
    max-width: 100px;

    background: rgba(255, 255, 255, 0.1);
    mix-blend-mode: normal;
    border-radius: 16px;
    display: flex !important;
    flex-direction: column;
    justify-content: space-evenly !important;
    align-items: center !important;
    text-align: center;

    border: 2px solid rgba(255, 255, 255, 0.2);

    h6,
    h5 {
      font-family: 'Montserrat';
      font-style: normal;
      margin: 0;
    }
    h6 {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #ffffff;
    }
    h5 {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #888aa7;
    }
  }
  .slide.active {
    border: 2px solid #ffffff;
    h5 {
      color: #ffffff;
    }
  }

  .slick-prev {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid #ffffff;
      border-width: 0 5px 5px 0;
      display: inline-block;
      padding: 7px;
      height: 20px;
      cursor: url(${point}), auto !important;
      transform: rotate(135deg);
      opacity: 1;
    }
  }
  .slick-next {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 3px;
      border: 1px solid #ffffff;
      border-width: 0 5px 5px 0;
      display: inline-block;
      padding: 7px;
      height: 20px;
      cursor: url(${point}), auto !important;
      transform: rotate(-45deg);
      opacity: 1;
    }
  }

  @media (max-width: 1300px) {
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
    .slick-slide {
      div {
        height: 40px;
      }
    }

    .slide {
      height: 40px;
      max-width: 62px;
      font-size: 12px;
      border-radius: 12px;
      h6 {
        font-size: 12px;
      }
      h5 {
        font-size: 10px;
      }
    }
    .slick-prev {
      &::before {
        top: 5px;
        left: 0;
        border-width: 0 3px 3px 0;
        padding: 5px;
        height: 10px;
      }
    }
    .slick-next {
      &::before {
        top: 5px;
        left: 4px;
        border: 1px solid #ffffff;
        border-width: 0 3px 3px 0;
        padding: 5px;
        height: 10px;
      }
    }
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 580px) {
    margin: -5px 0 -9px 0;
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 480px) {
  }
`;

export default MinesSliderDataStyle;
