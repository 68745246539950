import React from 'react';

import { useMines } from 'context/contextMines/contextMines';
import { Types } from 'context/contextMines/minesReduser';
import { disableButtonsMines } from 'func/prepareDataMines';

import BetAmountBlock from 'components/Games/base/BetAmountBlock';

import styles from './styles.module.scss';

const MinesBetAmountBlock: React.FC = () => {
  const [state, dispatch] = useMines();

  const { gameSettings, betAmountValue, isHotkeys, gameCondition, gameId } = state;

  const handleBetAmount = (value) => {
    dispatch({ type: Types.SET_BET_AMOUNT, payload: { betAmount: value } });
  };

  return (
    <div className={styles.minesBetAmountBlockWrap}>
      <BetAmountBlock
        minimumBet={gameSettings.minBet}
        maxBet={gameSettings.maxBet}
        betAmountValue={betAmountValue}
        disable={disableButtonsMines(gameCondition)}
        isHotKeys={isHotkeys}
        setBetAmountValue={handleBetAmount}
        gameId={gameId}
      />
    </div>
  );
};

export default MinesBetAmountBlock;
