import dayjs from 'dayjs';
import { useContext } from 'react';

import img from 'assets/img/Events/starWars.webp';

import { media } from 'config';
import { popUps } from 'components/constants/constants';
import { ContextPopUps } from 'context';
import { getImgSrc } from 'func/common';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';
import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile } from 'store/user/user.selectors';

import Button from 'components/Base/Button';
import LazyLoadImg from 'components/Base/LazyLoadImg/LazyLoadImg';

import TimerEvent from '../TimerEvent';
import styles from './styles.module.scss';
import { IEventFront } from './types';

const FrontCard: React.FC<IEventFront> = ({
  image,
  onClick,
  type,
  title,
  description,
  eventPrizeToken,
  prize,
  startAt,
  endAt,
  id,
  totalWagerTokenCode,
  onTick,
}) => {
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { translate } = useLocalization();

  const user = useAppSelector(userProfile);
  const now = dayjs();

  const handleResults = () => {
    setPopUpsOpen({
      modalOpen: popUps.tableResults,
      data: {
        id,
        eventPrizeToken,
        totalWagerTokenCode,
        type,
        fullScreen: true,
      },
    });
  };

  return (
    <div className={styles.eventsFrontWrap}>
      <LazyLoadImg src={image ? `${media.events}${image}` : img} className={styles.eventsFrontImg} />
      <div className={styles.eventsFrontContent}>
        <div className={styles.eventsFrontBtnWrap}>
          <div className={styles.eventsFrontBtnType}>{type || ''}</div>
          <button type="button" onClick={onClick} className={styles.eventsFrontBtnFlip}>
            {translate('events.buttons.more-info')}
          </button>
        </div>
        <h2 className={styles.eventsFrontTitle}>{translate(title)}</h2>
        <div className={styles.eventsFrontDescription}>{translate(description)}</div>
        {user ? (
          <div className={styles.eventsFrontPrize}>
            <h3>{translate('events.prize')}:</h3>
            <img src={getImgSrc(eventPrizeToken)} alt="icon" />
            <h4>{prize}</h4>
            <h5>{eventPrizeToken}</h5>
          </div>
        ) : null}
        <div className={styles.eventsFrontTimerWrap}>
          <TimerEvent dateStart={startAt} dateEnd={endAt} frontCard onTick={onTick} />
          {user ? (
            <Button
              variant={ButtonType.secondary}
              title={translate('events.buttons.results')}
              color="#24365B"
              width="125px"
              onclick={handleResults}
              disabled={!dayjs(startAt).isBefore(now)}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FrontCard;
