export enum Links {
  home = '/',
  games = '/games',
  dividends = '/dividends',
  cashback = '/cashback',
  referral = '/referral',
  events = '/events/',
  dashboard = '/dashboard',
  account = '/account',
  transactions = '/transactions',
  security = '/security',
  bets = '/bets',
  terms = '/terms-of-service',
  whitepaper = '/whitepaper',
  diceSeo = '/dice',
  ukSeo = '/uk',
  dice = '/in-house/dice',
  error = '/error',
  slots = '/slots/:gameId?',
  limbo = '/limbo',
  inHouseLimbo = '/in-house/limbo',
  bonus = '/bonus',
  affiliates = '/affiliates',
  coinflip = '/coinflip',
  inHouseCoinflip = '/in-house/coinflip',
  missions = '/missions',
  roadmap = '/roadmap',
  tournament = '/tournament/:tournamentId?',
  tournaments = 'tournaments',
  starwars = 'starwars',
  specialEvent = '/special-event/:eventName?',
  keno = '/keno',
  inHouseKeno = '/in-house/keno',
  mines = '/mines',
  inHouseMines = '/in-house/mines',
  notFound = '/404',
  roulette = '/roulette',
  live = '/live',
  slotsSeo = '/slots',
  promoMaterials = '/promo-materials',
  blog = '/blog',
  blogPost = '/blog/:slug',
  faq = '/faq',
  faqCategory = '/faq/:categoryName',
  blackJack = '/in-house/blackjack',
  seoBlackJack = '/blackjack',
  vip = '/vip',
  blackJackVip = '/in-house/blackjack-vip',
  seoBitcoin = '/bitcoin',
  seoEthereum = '/ethereum',
  landing = '/landing/',
  landingJoin = 'deposit-bonus',
  texasHoldem = '/in-house/texas-holdem',
  texasHoldemVip = '/in-house/texas-holdem-vip',
  tetherUsdt = '/tether-usdt',
  landingSignupBonus = 'signup-bonus',
  plinko = '/plinko',
  inHousePlinko = '/in-house/plinko',
}

export enum Tokens {
  authToken = 'authToken',
  refreshToken = 'refreshToken',
}

export enum GraphqlErrors {
  unauthenticated = 'unauthenticated',
  unauthorized = 'unauthorized',
}
