import { gql } from '@apollo/client';

export const GET_REFERRAL_ACTIVITY = gql`
  query referralActivity($input: ReferralsActivityPaginationParams!) {
    referralActivity(paginationData: $input) {
      count
      items {
        alias
        avatar {
          avatar
        }
        rank {
          id
        }
        createdAt
        totalMined
        totalWager
        wallets {
          tokenCode
          displayName
        }
      }
    }
  }
`;
