import clsx from 'clsx';
import { useContext, useEffect, useRef, useState } from 'react';

import { ReactComponent as CrossIcon } from 'assets/img/Notification/cross.svg';

import { CustomNotificationType } from 'types';
import { getNotificationIconByType, getNotificationsContent } from 'func/notifications';
import NotificationContext from 'context/contextNotifications/context';
import { NOTIFICATION_DISPLAY_TIME } from 'context/contextNotifications';

import styles from './styles.module.scss';
import { INotificationPreview } from './types';

const CustomBonusNotification: React.FC<INotificationPreview> = ({ notification }) => {
  const { onRemovePendingNotification } = useContext(NotificationContext);

  const notificationRef = useRef<HTMLLIElement>(null);

  const [animate, setAnimate] = useState(true);
  const [hidden, setHidden] = useState(false);
  const [timeoutToHide, setTimeoutToHide] = useState<NodeJS.Timeout | undefined>();
  const [timeoutToRemove, setTimeoutToRemove] = useState<NodeJS.Timeout | undefined>();

  // animate show
  useEffect(() => {
    setTimeout(() => {
      setAnimate(false);
    }, 0);
  }, []);

  // animate hidden
  useEffect(() => {
    const newTimeout = setTimeout(() => setHidden(true), NOTIFICATION_DISPLAY_TIME);

    setTimeoutToHide(newTimeout);
  }, []);

  useEffect(() => {
    if (hidden) {
      const newTimeout = setTimeout(() => onRemovePendingNotification(notification.id), 2000);

      setTimeoutToRemove(newTimeout);
    }
  }, [hidden]);

  const handleMouseEnter = () => {
    clearTimeout(timeoutToHide);
    clearTimeout(timeoutToRemove);
    setTimeoutToHide(undefined);
    setTimeoutToRemove(undefined);
    setHidden(false);
  };

  const handleMouseLeave = () => {
    const newTimeout = setTimeout(() => setHidden(true), NOTIFICATION_DISPLAY_TIME);

    setTimeoutToHide(newTimeout);
  };

  const handleRemove = () => {
    onRemovePendingNotification(notification.id);
  };

  return (
    <li
      ref={notificationRef}
      className={clsx(styles.wrapper, animate ? styles.transform : '', hidden ? styles.hidden : '')}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.borderGold} />
      <div className={styles.contentWrapper}>
        <img
          className={styles.bonusCustomImage}
          src={getNotificationIconByType(notification.type)}
          alt={notification.type}
        />
        <div>
          <p className={styles.description}>{getNotificationsContent(notification.content)}</p>
          <p
            className={clsx(
              styles.customBonusTitle,
              notification.customType === CustomNotificationType.customBonusReject ? styles.red : '',
            )}
          >
            {notification.title}
          </p>
        </div>
      </div>
      <button className={styles.removeButton} type="button" onClick={handleRemove}>
        <CrossIcon className={styles.removeIcon} />
      </button>
    </li>
  );
};

export default CustomBonusNotification;
