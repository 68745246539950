import rankVipIcon from 'assets/img/ranks/rowDetails/rank-vip.webp';
import rankAvatarsIcon from 'assets/img/ranks/rowDetails/rank-avatars.webp';
import GBFlag from 'assets/img/Flags/GB.svg';
import GermanyFlag from 'assets/img/Flags/DE.svg';
import ItalianoFlag from 'assets/img/Flags/IT.svg';
import IndonesiaFlag from 'assets/img/Flags/MC.svg';
import SpainFlag from 'assets/img/Flags/ES.svg';
import PortugalFlag from 'assets/img/Flags/PT.svg';
import FranceFlag from 'assets/img/Flags/FR.svg';
import TurkishFlag from 'assets/img/Flags/TR.svg';
import PolishFlag from 'assets/img/Flags/PL.svg';
import RussianFlag from 'assets/img/Flags/RU.svg';

import { GAMES_TABS_NAMES } from './constants';
import { TranslationType } from '../../types';
import { GameSubcategory } from './games';

export const tabsListGamesPage = [
  {
    title: GAMES_TABS_NAMES.favorite.title,
    idTitle: GAMES_TABS_NAMES.favorite.urlTitle,
    id: GameSubcategory.favorite,
  },
  {
    title: GAMES_TABS_NAMES.new.title,
    idTitle: GAMES_TABS_NAMES.new.urlTitle,
    id: GameSubcategory.new,
  },
  {
    title: GAMES_TABS_NAMES.popular.title,
    idTitle: GAMES_TABS_NAMES.popular.urlTitle,
    id: GameSubcategory.popular,
  },
  {
    title: GAMES_TABS_NAMES.bonus.title,
    idTitle: GAMES_TABS_NAMES.bonus.urlTitle,
    id: GameSubcategory.bonus,
  },
  {
    title: GAMES_TABS_NAMES.dropsAndWins.title,
    idTitle: GAMES_TABS_NAMES.dropsAndWins.urlTitle,
    id: GameSubcategory.dropsAndWins,
  },
  {
    title: GAMES_TABS_NAMES.vip.title,
    idTitle: GAMES_TABS_NAMES.vip.urlTitle,
    id: GameSubcategory.vip,
  },
];

export const TOURNAMENT_TIMER_STATE = {
  started: 'popups.bonus.login.started',
  notStarted: 'popups.bonus.login.notStarted',
  finished: 'popups.bonus.login.finished',
};

export enum ESounds {
  wheel = 'wheel',
}

export enum TournamentType {
  tournament = 'Tournament',
  specialEvent = 'Special Event',
}

export enum AvatarType {
  default = 'Default',
  premium = 'Premium',
}

export const ranksRowList = [
  { id: 'vip', title: 'VIP Status', image: rankVipIcon },
  { id: 'avatars', title: 'Premium Avatars', image: rankAvatarsIcon },
];

export const getRanksRowList = (t: TranslationType) => [
  { id: 'vip', title: t('cashback.ranks.vip.status.text'), image: rankVipIcon },
  { id: 'avatars', title: t('cashback.ranks.premium.text'), image: rankAvatarsIcon },
];

export const footerTelegramChats = [
  { title: 'General', img: GBFlag, link: 'https://t.me/starbets_io' },
  { title: 'Deutsch', img: GermanyFlag, link: 'https://t.me/starbets_io_de' },
  { title: 'Italiano', img: ItalianoFlag, link: 'https://t.me/starbets_io_it' },
  { title: 'Indonesia', img: IndonesiaFlag, link: 'https://t.me/starbets_io_idn' },
  { title: 'Español', img: SpainFlag, link: 'https://t.me/starbets_io_sp' },
  { title: 'Português', img: PortugalFlag, link: 'https://t.me/starbets_io_pt' },
  { title: 'Français', img: FranceFlag, link: 'https://t.me/starbets_io_fr' },
  { title: 'Türkçe', img: TurkishFlag, link: 'https://t.me/starbets_io_tr' },
  { title: 'Pojski', img: PolishFlag, link: 'https://t.me/starbets_io_pl' },
  { title: 'Русский', img: RussianFlag, link: 'https://t.me/starbets_io_ru' },
];

export enum PageShadow {
  referral = 'linear-gradient(134.02deg, rgba(209, 99, 23, 0.3) 25.51%, rgba(209, 99, 23, 0) 70.06%)',
  cashback = 'linear-gradient(134.02deg, rgba(28, 105, 202, 0.3) 25.51%, rgba(28, 105, 202, 0) 70.06%)',
  dividends = 'linear-gradient(134.02deg, rgba(105, 149, 26, 0.3) 25.51%, rgba(105, 149, 26, 0) 70.06%)',
  bonus = 'linear-gradient(134.02deg, rgba(35, 194, 127, 0.3) 25.51%, rgba(35, 194, 127, 0) 70.06%)',
  security = 'linear-gradient(134.02deg, rgba(194, 35, 40, 0.3) 25.51%, rgba(194, 35, 40, 0) 70.06%)',
  transactions = 'linear-gradient(134.02deg, rgba(23, 176, 15, 0.3) 25.51%, rgba(23, 176, 15, 0) 70.06%)',
  games = 'linear-gradient(134.02deg, rgba(162, 39, 197, 0.3) 25.51%, rgba(162, 39, 197, 0) 70.06%)',
}
