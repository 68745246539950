import clsx from 'clsx';

import styles from './styles.module.scss';
import { IButtonTooltip } from './types';

const ButtonTooltip: React.FC<React.PropsWithChildren<IButtonTooltip>> = ({
  text = '',
  className = '',
  textClassName = '',
  children,
}) => {
  return (
    <div className={clsx(styles.wrapper, className)}>
      {text ? <span className={clsx(styles.tooltip, textClassName)}>{text}</span> : null}
      {children}
    </div>
  );
};

export default ButtonTooltip;
