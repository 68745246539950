import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';

import hotKeysImage from 'assets/img/CoinFlip/hotKeysCoin.webp';

import LiveStatsContext from 'context/contextLiveStats/context';
import useGame from 'hooks/useGame';
import { useAppSelector } from 'hooks/useAppSelector';
import { ContextPopUps } from 'context';
import SocketContext from 'context/contextSocket/context';
import { DOCUMENT_TITLE_SETTINGS, popUps } from 'components/constants/constants';
import {
  controlDataKeyCoinFlip,
  EGamesButtonsBar,
  EGamesNames,
  EGamesSlugName,
  FastModeGames,
  HotKeys,
} from 'components/constants/games';
import { GET_BETS_HISTORY } from 'graphQl/query/betsPage/betsHistory';
import { getAllHistoryLimbo } from 'func/prepareDataLimbo';
import { userProfile } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';

import DocumentTitle from 'components/Base/DocumentTitle';
import GameOptionsButtonsNew from 'components/Games/base/GameOptionsButtonsNew';
import LotteryDesktop from 'components/Games/Lottery/LotteryDesktop/LotteryDesktop';

import CoinFlipStyle from './styles';
import TableCoin from './components/TableCoin';
import CoinHistory from './components/CoinHistory';
import CoinContent from './components/CoinContent';

const CoinFlip: React.FC = () => {
  const { translate } = useLocalization();

  const isMobile = useMediaQuery({ query: '(max-width: 1260px)' });

  const { enabled, onToggleStatistics } = useContext(LiveStatsContext);
  const { connected, joinGameRoom, leaveGameRoom } = useContext(SocketContext);
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [historyTable, setHistoryTable] = useState(null);
  const [history, setHistory] = useState([]);
  const [coinId, setCoinId] = useState(null);
  const [fastMode, setFastMode] = useState(
    JSON.parse(localStorage.getItem(FastModeGames.coin)) !== null
      ? JSON.parse(localStorage.getItem(FastModeGames.coin))
      : true,
  );
  const [isHotKeys, setIsHotKeys] = useState(JSON.parse(localStorage.getItem(HotKeys.coin)) || false);

  const user = useAppSelector(userProfile);
  const coinFlipGame = useGame({ slug: EGamesSlugName.coinFlip, providerName: 'In-House', userId: user?.id }, !user);

  console.log(coinFlipGame);

  const { data: dataHistory } = useQuery(GET_BETS_HISTORY, {
    skip: !user || !coinId,
    variables: {
      input: {
        limit: 28,
        offset: 0,
        gameId: coinId,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const handleToggleStats = () => {
    onToggleStatistics(!enabled, isMobile ? false : undefined);
  };

  const handleFastMode = () => {
    const fast = !fastMode;
    setFastMode(fast);
    localStorage.setItem(FastModeGames.coin, fast.toString());
  };

  const handleHotKey = (keys) => {
    setIsHotKeys(keys);
    localStorage.setItem(HotKeys.coin, keys.toString());
  };

  const handleSwitchHotKey = () =>
    setPopUpsOpen({
      modalOpen: popUps.hotKeys,
      data: {
        onSwitch: handleHotKey,
        isHotKeys,
        keysImage: hotKeysImage,
        controlKeyData: controlDataKeyCoinFlip,
      },
    });

  const handleVerify = () => {
    setPopUpsOpen(popUps.coinflipVerify);
  };

  const handleLimits = () => setPopUpsOpen({ modalOpen: popUps.gamesLimits, data: { gameId: coinId } });

  const handleRules = () => setPopUpsOpen({ modalOpen: popUps.gamesRules, data: { gameName: EGamesNames.coinFlip } });

  const dataButtons = [
    {
      toolTipText: translate('inHouse.tooltip.live'),
      activeImg: enabled,
      func: handleToggleStats,
      id: EGamesButtonsBar.statistic,
    },
    {
      toolTipText: translate('inHouse.tooltip.speed'),
      activeImg: fastMode,
      func: handleFastMode,
      id: EGamesButtonsBar.speed,
    },
    {
      toolTipText: translate('inHouse.tooltip.verify'),
      activeImg: false,
      func: handleVerify,
      id: EGamesButtonsBar.verify,
    },
    {
      toolTipText: translate('inHouse.tooltip.limits'),
      activeImg: false,
      func: handleLimits,
      id: EGamesButtonsBar.limits,
    },
    {
      toolTipText: translate('inHouse.tooltip.rules'),
      activeImg: false,
      func: handleRules,
      id: EGamesButtonsBar.rules,
    },
    {
      toolTipText: translate('inHouse.tooltip.keys'),
      activeImg: isHotKeys,
      func: handleSwitchHotKey,
      id: EGamesButtonsBar.keys,
    },
  ];

  useEffect(() => {
    if (dataHistory) {
      const newCoinHistory = dataHistory.userBets.items.map((item, index) => ({
        ...item,
        isBlack: !!(index % 2),
      }));
      setHistoryTable(getAllHistoryLimbo(newCoinHistory));
    }
  }, [dataHistory]);

  useEffect(() => {
    if (coinFlipGame) {
      setCoinId(coinFlipGame.id);
    }
  }, [coinFlipGame]);

  useEffect(() => {
    joinGameRoom();

    return () => {
      leaveGameRoom();
    };
  }, [connected]);

  return (
    <CoinFlipStyle>
      <DocumentTitle data={DOCUMENT_TITLE_SETTINGS.coin} />
      <div className="coin__lottery-wrap">
        <div className="coin__content--wrap">
          <div className="coin__content--wrap__history">
            <CoinHistory data={history} />
            <GameOptionsButtonsNew dataButtons={dataButtons} />
          </div>
          <CoinContent
            coinId={coinId}
            isHotKeys={isHotKeys}
            fastMode={fastMode}
            setHistory={setHistory}
            setHistoryTable={setHistoryTable}
            history={history}
            historyTable={historyTable}
          />
        </div>
        <LotteryDesktop />
      </div>
      <TableCoin history={historyTable} />
    </CoinFlipStyle>
  );
};

export default React.memo(CoinFlip);
