import { useState } from 'react';
import EstimatedDividends from './components/EstimatedDividends';
import StarTokens from './components/StarToken/StarTokens';
import styles from './styles.module.scss';

const CardBlockSecond: React.FC = () => {
  const [percent, setPercent] = useState(0);
  return (
    <div className={styles.dividendsCardsBlockSecond}>
      <StarTokens percent={percent} setPercent={setPercent} />
      <EstimatedDividends percent={percent} />
    </div>
  );
};

export default CardBlockSecond;
