import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

export const StyledTableWrap = styled.div`
  overflow: auto;
  width: 100%;
  scrollbar-width: thin;
  border-bottom: 10px solid transparent;
  &::-webkit-scrollbar {
    width: 3px;
    height: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #50566a;
    -webkit-border-radius: 1ex;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

interface Props {
  thwidth: string;
  config?: string;
  thwidthmob?: string;
  cutcurrency?: string;
  tablepopup?: string;
}
const StyledTable = styled.table.withConfig({
  shouldForwardProp: (prop) => !['config'].includes(prop),
})<Props>`
  border: none;
  height: 100%;
  caption-side: bottom;

  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
  td,
  th {
    border: none;
    height: 50px;
  }
  td {
    padding: 5px 0 5px 31px;
    height: 50px;
    box-sizing: border-box;
    position: relative;
  }
  .status-win,
  .status-complete {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    color: #8bc834;
    margin: 0;
  }
  .status-lose,
  .status-failed {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    color: #f02f41;
    margin: 0;
  }
  .user-alias {
    display: inline-block;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    margin: 0 0 0 0;
  }
  .date-format {
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 14px;
    color: #ffffff;
    margin: 0 0 0 0;
  }
  .default-text,
  .status-collected,
  .status-expired,
  .status-canceled,
  .cut-text {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    margin: 0 0 0 0;
  }
  .status-collected {
    color: #99d290;
  }
  .status-expired {
    color: #fccd00;
  }
  .status-canceled {
    color: #ee4d6a;
  }
  .cut-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .flag-img {
    height: 11px;
    margin-right: 7px;
    margin-bottom: 5px;
  }
  .currency-img {
    width: 45px;
    height: 45px;
    margin-top: -5px;
    margin-left: -10px;
  }
  .avatar-img {
    width: 30px;
    border-radius: 50%;
    margin: 0 10px 0 0;
  }
  .place-img {
    width: 25px;
    margin-left: -5px;
  }
  .table__copy {
    position: relative;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .table__copy-icon {
    position: absolute;
    right: 20px;
    top: -18px;
    cursor: url(${point}), auto !important;
    &:active {
      border: 6px solid transparent;
    }
  }
  .table__form-copy-tooltip {
    position: absolute;
    width: 50px;
    height: 20px;
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 10px;
    color: #ffffff;
    background-color: red;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #808080;
    visibility: hidden;
    transform: translateY(-100%);
    transition: all 0.1s ease-in-out;
    transition-duration: 200ms;
    transition-delay: 200ms;
    border-radius: 19px;
  }
  .table__form-copy-tooltip.open {
    visibility: visible;
    transform: translateY(-200%);
  }
  .table__link {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    margin: 0;
    cursor: url(${point}), auto !important;
  }

  .flex-column {
    display: flex;
    flex-direction: row !important;
    align-items: center;
  }

  tbody tr {
    height: 50px;
    white-space: nowrap;
    vertical-align: center;
    &:nth-of-type(even) {
      background-color: #00000033;
    }
  }
  thead > tr {
    background-color: rgba(109, 157, 251, 0.08);
    border-radius: 19px;
    white-space: nowrap;
    vertical-align: center;
  }
  thead > tr > th {
    padding: 5px 0 5px 31px;
    &:nth-of-type(1) {
      border-bottom-left-radius: 19px;
      border-top-left-radius: 19px;
      padding-right: 10px;
    }
    &:nth-last-of-type(1) {
      border-bottom-right-radius: 19px;
      border-top-right-radius: 19px;
      padding-right: 10px;
    }
  }
  tbody > tr > td {
    padding: 5px 0 5px 31px;
    &:nth-last-of-type(1) {
      border-bottom-right-radius: 19px;
      border-top-right-radius: 19px;
      padding-right: 10px;
    }
    &:nth-of-type(1) {
      border-bottom-left-radius: 19px;
      border-top-left-radius: 19px;
      padding-right: 10px;
    }
  }

  b {
    color: #6987c7;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    margin: 0;
  }

  @media (max-width: 1250px) {
  }

  @media (max-width: 1024px) {
    .default-text,
    .cut-text,
    .status-win,
    .status-lose,
    .status-complete,
    .status-collected,
    .status-expired,
    .status-canceled,
    .status-failed {
      font-size: 12px;
    }
    .currency-img {
      width: 30px;
      height: 30px;
    }
    .table__link {
      font-size: 12px;
    }
  }

  @media (max-width: 863px) {
    .user-alias {
      font-size: 10px;
    }
    .avatar-img {
      width: 25px;
    }
    .default-text,
    .status-win,
    .status-lose,
    .status-complete,
    .status-collected,
    .status-expired,
    .status-canceled,
    .status-failed {
      font-size: 10px;
    }
    .currency-img {
      width: 30px;
      height: 30px;
    }
    b {
      font-size: 13px;
    }
    .date-format {
      font-size: 10px;
    }
    .table__link {
      font-size: 9px;
    }
  }
  @media (max-width: 700px) {
  }

  @media (max-width: 580px) {
    .user-alias {
      font-size: 8px;
      font-size: ${(props) => props.config === 'place' && '10px'};
    }
    .avatar-img {
      width: 20px;
    }
    .place-img {
      width: 20px;
    }
  }
  @media (max-width: 480px) {
    .default-text,
    .status-win,
    .status-lose {
      display: ${(props) => props.cutcurrency && 'inline-block'};
      height: ${(props) => props.cutcurrency && '14px'};
    }

    .flag-img {
      height: 8px;
      height: ${(props) => props.config === 'transactions' && '11px'};
      margin-right: 7px;
      margin-bottom: 0;
    }
    .currency-img {
      width: 25px;
      height: 25px;
      margin: ${(props) => (props.cutcurrency ? '0 2px 0 -5px' : '-2px 0 0 -5px')};
    }
  }
  @media (max-width: 400px) {
    .date-format {
      font-size: 10px;
    }
  }
  @-moz-document url-prefix('') {
    thead > tr {
      background-color: transparent;
    }
    thead > tr > th {
      background-color: #6e70753b;
    }
    tbody tr {
      &:nth-of-type(even) {
        td {
          background-color: #00000033;
        }
        background-color: transparent;
      }
    }
  }
`;

export default StyledTable;
