import React from 'react';

import { useMines } from 'context/contextMines/contextMines';
import { popUps } from 'components/constants/constants';

import MyBetsTableWrap from 'components/Games/base/MyBetsTable';

import styles from '../../styles.module.scss';

const MyMinesBets: React.FC = () => {
  const [state] = useMines();
  const { historyList } = state;

  return (
    <div className={styles.minesTableMyBetsWrap}>
      <MyBetsTableWrap history={historyList} popUpsWindow={popUps.minesBetDetails} />
    </div>
  );
};

export default MyMinesBets;
