import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;

  cursor: url(${point}), auto !important;

  fill: #3757dd;

  background: none;
  border: none;

  &:hover {
    fill: #7a2cff;
  }
`;

export const StyledButtonWrapper = styled.div`
  position: relative;

  .btn__copy-tooltip {
    position: absolute;
    width: 50px;
    height: 20px;
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 10px;
    color: #ffffff;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #808080;
    visibility: hidden;
    transform: translateY(-100%);
    transition: all 0.1s ease-in-out;
    border-radius: 19px;
  }
  .btn__copy-tooltip.open {
    visibility: visible;
    transform: translateY(-200%);
  }
`;
