import { animateScroll as scroll } from 'react-scroll';

import { media } from 'config';
import {
  CATEGORY_NAMES,
  EGamesCategories,
  EGamesTabs,
  GameCategoryRequest,
  GAMES_QUALITY,
  GAMES_TABS_NAMES,
  popUps,
} from 'components/constants/constants';
import { IGameResponse } from 'types/requestTypes';
import { GameCategory, GameType, TranslationType } from 'types';
import { CustomNavigateFunction } from 'hooks/useNavigate';
import { DEFAULT_PARAMS, GameSubcategory } from '../components/constants/games';

export const setGameName = (gameName: string): string =>
  gameName
    .replace(/[!@#$%^&*(),.?":{}<>]/g, '-')
    .replace(/ /g, '-')
    .toLowerCase();

export const getGameName = (gameName: string): string =>
  gameName
    .replace(/[!@#$%^&*(),.?":{}<>]/g, '-')
    .replace(/ /g, '-')
    .toLowerCase();

export const getGameImageUrl = (providerName: string, image: string): string => {
  return `${media.games}${providerName ? providerName + '/' : ''}${image}`;
};

export const getFirstElem = (array: any, id: any): any => {
  const ind = array.findIndex((elem) => elem.titleURL === id);
  const firstElem = array.splice(ind, 1);
  return [firstElem[0], ...array];
};

export const setMainSearchParam = (navigate: CustomNavigateFunction, param: string, id: string): void => {
  navigate({ search: `${param}=${id}` });
};

export const setAdditionalSearchParam = (
  navigate: CustomNavigateFunction,
  location: any,
  id: string,
  title: string,
): any => {
  const params = new URLSearchParams(location);

  params.delete(id);
  params.append(id, title);

  navigate({ search: params.toString() });
};

export const isDisabledProviders = (categoryUrl: string, tabName = ''): boolean => {
  if (tabName === EGamesTabs.dropsAndWins) return true;

  switch (categoryUrl) {
    case CATEGORY_NAMES.inHouse.urlTitle:
      return true;
    case GAMES_TABS_NAMES.favorite.urlTitle:
      return true;
    case GAMES_TABS_NAMES.new.urlTitle:
      return true;
    case GAMES_TABS_NAMES.popular.urlTitle:
      return true;
    case GAMES_TABS_NAMES.bonus.urlTitle:
      return true;
    default:
      return false;
  }
};

export const getProvidersDropdown = (
  providersList: Array<{ name: string }>,
  navigate: CustomNavigateFunction,
  locationSearch: string,
  providerURL: string,
  t: TranslationType,
): Array<{ func: () => void; title: string; titleURL: string }> => {
  const defaultProvider = t(GAMES_QUALITY.providers.titles.allProvidersTitle);
  const providers = [{ name: defaultProvider }, ...providersList];

  const providerData = providers.map((elem) => {
    const elemToURL = elem.name === defaultProvider ? GAMES_QUALITY.providers.titles.allProvidersURL : elem.name;

    return {
      title: elem.name,
      titleURL: elemToURL,
      func: () => {
        setAdditionalSearchParam(navigate, locationSearch, GAMES_QUALITY.providers.id, elemToURL);
      },
    };
  });
  if (providerURL) {
    return getFirstElem(providerData, providerURL);
  }
  return providerData;
};

export const getGameCardClass = (bonusIsActive: boolean, bonus: boolean, freeSpin: boolean): string => {
  if (freeSpin) {
    return 'slotCardFreeSpins';
  }

  if (!bonusIsActive) {
    return 'slotCardDemo';
  }

  switch (bonus) {
    case false:
      return 'slotCardDemoNoBonus';
    case true:
      return 'slotCardDemo';
    default:
      return 'slotCardDemo';
  }
};

export const getCategoryName = (categoryName: string): string => {
  switch (categoryName) {
    case EGamesCategories.allGames:
      return '';
    case EGamesCategories.inHouse:
      return EGamesCategories.inHouse;
    case EGamesCategories.slots:
      return EGamesCategories.slots;
    case EGamesCategories.tableGames:
      return EGamesCategories.tableGames;
    case EGamesCategories.liveCasino:
      return EGamesCategories.liveCasino;
    case EGamesCategories.virtualSport:
      return EGamesCategories.virtualSport;
    default:
      return '';
  }
};

export const getCategoryToRequest = (categoryName: string): string | null => {
  switch (categoryName) {
    case EGamesCategories.inHouse:
      return GameCategoryRequest.InHouse;
    case EGamesCategories.slots:
      return GameCategoryRequest.Slots;
    case EGamesCategories.tableGames:
      return GameCategoryRequest.TableGames;
    case EGamesCategories.liveCasino:
      return GameCategoryRequest.LiveCasino;
    case EGamesCategories.virtualSport:
      return GameCategoryRequest.VirtualSports;
    default:
      return null;
  }
};

export const getProviderRequest = (providerName: string): string | null => {
  if (providerName === GAMES_QUALITY.providers.titles.allProvidersURL) return null;
  return providerName === DEFAULT_PARAMS ? null : providerName;
};

const handleInitGame = (path: string, navigate: CustomNavigateFunction) => {
  navigate(path);
  scroll.scrollTo(0, { duration: 0, delay: 0 });
};

const handleInitSlotsGame =
  (slug: string, navigate: CustomNavigateFunction, setPopUpsOpen: (el: string) => void) => (tokenAccess) => {
    if (tokenAccess) {
      const slotsPath = `/slots/${slug}`;

      handleInitGame(slotsPath, navigate);
    } else {
      setPopUpsOpen(popUps.login);
    }
  };

export const getFormattedGames = (
  games: IGameResponse[],
  navigate: CustomNavigateFunction,
  setPopUpsOpen: (el: string) => void,
  t: TranslationType,
): GameType[] => {
  if (!games) return [];

  return games.map((g) => ({
    gameId: g.id,
    providerGameId: g.gameId,
    src: getGameImageUrl(g.providerName, g.image),
    modeTitle: t('general.buttons.play.now'),
    providerName: g.providerName,
    name: g.name,
    category: g.category,
    title: g.name,
    slug: g.slug,
    isFavorite: g.isFavorite,
    freeSpins: false,
    popular: g.popular,
    new: g.new,
    isBonusGame: g.isBonusGame,
    drops: g.isDropsAndWins,
    isBlocked: g.isBlocked,
    vip: g.vip,
    onClick:
      g.category === GameCategory.InHouse
        ? () => handleInitGame(`/in-house/${g.slug}`, navigate)
        : handleInitSlotsGame(g.slug, navigate, setPopUpsOpen),
  }));
};

export const isDisplayGamesTab = (id: GameSubcategory, subCategoriesList: GameSubcategory[]): string => {
  if (!subCategoriesList?.length) return 'true';
  if (subCategoriesList.includes(id)) return 'true';
  return 'false';
};
