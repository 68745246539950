import { useContext, useEffect, useState } from 'react';
import { ContextPopUps } from 'context';
import clsx from 'clsx';

import ButtonSubmit from 'components/Base/PopUps/components/ButtonSubmit';
import { ButtonType } from 'components/Base/Button/types';
import {
  BLACK_JACK_HOT_KEYS_BET_OPTION,
  BLACK_JACK_HOT_KEYS_BET_OPTION_VIP,
  BLACK_JACK_HOT_KEYS_END_OF_GAME,
  BLACK_JACK_HOT_KEYS_IN_GAME,
  BLACK_JACK_HOT_KEYS_TOPICS,
  EBlackJackHotkeysTopic,
} from 'components/constants/blackJack';
import { useLocalization } from 'components/Internationalization';

import { IBlackjackHotkey, IHotKeysBlackJack } from './types';
import styles from './styles.module.scss';

const HotKeysBlackJack: React.FC<IHotKeysBlackJack> = ({ data }) => {
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { translate, language } = useLocalization();

  const { onSwitch, isHotKeys, isVip } = data;

  const [checked, setChecked] = useState(isHotKeys);
  const [keyTopic, setKeyTopic] = useState(EBlackJackHotkeysTopic.betOption);
  const [keysOptions, setHotKeysOptions] = useState(null);

  const handleClick = () => {
    setChecked(!checked);
    onSwitch(!checked);
  };

  const getOption = (topic: EBlackJackHotkeysTopic) => {
    let newHotkeys: IBlackjackHotkey;

    switch (topic) {
      case EBlackJackHotkeysTopic.betOption:
        newHotkeys = !isVip ? BLACK_JACK_HOT_KEYS_BET_OPTION : BLACK_JACK_HOT_KEYS_BET_OPTION_VIP;
        break;
      case EBlackJackHotkeysTopic.inGame:
        newHotkeys = BLACK_JACK_HOT_KEYS_IN_GAME;
        break;
      case EBlackJackHotkeysTopic.endOfGame:
        newHotkeys = BLACK_JACK_HOT_KEYS_END_OF_GAME;
        break;
      default:
        newHotkeys = !isVip ? BLACK_JACK_HOT_KEYS_BET_OPTION : BLACK_JACK_HOT_KEYS_BET_OPTION_VIP;
    }

    newHotkeys.data = newHotkeys.data.map((k) => ({ ...k, description: translate(k.description) }));

    return newHotkeys;
  };

  useEffect(() => {
    if (keyTopic) {
      setHotKeysOptions(getOption(keyTopic));
    }
  }, [keyTopic, language]);

  const handleClose = () => {
    setPopUpsOpen(null);
  };

  return (
    <div className={styles.hotKeyBlackJack}>
      <h2 className={styles.hotKeyBlackJackTitle}>{translate('popups.hotkeys.title')}</h2>
      <img src={keysOptions?.icon} alt="keys" className={styles.hotKeyBlackJackImage} key={keyTopic} />
      <div className={styles.hotKeyBlackJackTopicWrap}>
        {BLACK_JACK_HOT_KEYS_TOPICS.map((t) => (
          <h3
            key={t}
            className={clsx(
              styles.hotKeyBlackJackTopicItem,
              t === keyTopic ? styles.hotKeyBlackJackTopicItemActive : '',
            )}
            onClick={() => setKeyTopic(t)}
          >
            {t}
          </h3>
        ))}
      </div>
      <div className={styles.hotKeyBlackJackOptionsWrap}>
        {keysOptions
          ? keysOptions.data.map((k) => (
              <div key={k.description} className={styles.hotKeyBlackJackOptionsItem}>
                <h4>{k.key}</h4>
                <h5>{k.description}</h5>
              </div>
            ))
          : null}
      </div>
      <div className={styles.hotKeyBlackJackFooter}>
        <p>{checked ? translate('popups.hotkeys.enabled') : translate('popups.hotkeys.disabled')}</p>
        <div className={styles.hotKeyBlackJackInputWrap}>
          <input id="checkbox__hot" type="checkbox" checked={checked} onChange={handleClick} />
          <label htmlFor="checkbox__hot" className={styles.hotKeyBlackJackInputLabel} />
        </div>
      </div>
      <div className={styles.hotKeyBlackJackClose}>
        <ButtonSubmit
          className={styles.button}
          handleButton={handleClose}
          variant={ButtonType.primary}
          width="148px"
          title={translate('general.buttons.close')}
          color="#242c45"
        />
      </div>
    </div>
  );
};

export default HotKeysBlackJack;
