import Big from 'big.js';
import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import confetti from 'assets/img/Missions/Confetti.webp';
import multiplierImage from 'assets/img/Missions/multiplierIcon.webp';
import prizeImage from 'assets/img/Missions/prize.webp';

import { MissionRoundStatus } from 'types';
import { amount, getImgSrc } from 'func/common';
import { useCountdown } from 'hooks/useCountDown';
import { COLLECT_MISSION_REWARD } from 'graphQl/query/missions';
import { ContextPopUps } from 'context';
import { popUps } from 'components/constants/constants';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import Button from 'components/Base/Button';

import { MissionsStagePrizeStyle } from './styles';
import { IMissionsStagePrize } from './types';

const MissionsStagePrize: React.FC<IMissionsStagePrize> = ({ data }) => {
  const { translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [allCompleted, setAllCompleted] = useState(false);
  const [reward, setReward] = useState('0');
  const [status, setStatus] = useState<MissionRoundStatus>(MissionRoundStatus.active);

  const [collectMissionReward] = useMutation(COLLECT_MISSION_REWARD);

  const [days, hours, minutes, seconds] = useCountdown(data.endAt);

  useEffect(() => {
    if (data) {
      const newReward = Big(data.prize).mul(data.multiplier).toFixed();

      setReward(newReward);
      setStatus(data.status);
      setAllCompleted(Boolean(data.allCompleted));
    }
  }, [data]);

  const handleCollect = async () => {
    const variables = { userMissionRoundId: data.missionId };

    await collectMissionReward({ variables })
      .then(() => {
        data.onRefetchRounds();
        setPopUpsOpen({
          modalOpen: popUps.collect,
          data: [
            {
              title: amount(reward),
              src: data.prizeTokenCode,
              displayName: data.prizeTokenCode,
            },
          ],
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('[COLLECT_MISSION_REWARD_ERROR]: ', err);
      });
  };

  return (
    <MissionsStagePrizeStyle>
      <div className="missions__prize--images__wrap">
        <img src={confetti} alt="icon" className="missions__prize--images__confetti" />
        <img src={multiplierImage} alt="icon" className="missions__prize--images__multiplier" />
        <img src={prizeImage} alt="icon" className="missions__prize--images__prize" />
        <h3 className="missions__prize--images__multiplier--amount">x{data.multiplier}</h3>
        <h4 className="missions__prize--images__title">{translate('popups.mission.prize.text')}</h4>
        <h5 className="missions__prize--images__amount">
          <img
            className="mission__currency-icon-small"
            src={getImgSrc(data.prizeTokenCode)}
            alt={data.prizeTokenCode}
          />
          {amount(data.prize, true, 4)} {data.prizeTokenCode}
        </h5>
      </div>
      <div className="missions__prize--reward">{translate('popups.mission.prize.title')}</div>
      <div className="missions__prize--reward__amount">
        <img className="mission__currency-icon" src={getImgSrc(data.prizeTokenCode)} alt={data.prizeTokenCode} />
        {amount(reward, true, 4)} {data.prizeTokenCode}
      </div>
      {status === MissionRoundStatus.active && !allCompleted ? (
        seconds.includes('-') ? (
          <div className="missions__timer-wrapper">
            <p className="desc">{translate('popups.mission.prize.subtitle')}</p>
          </div>
        ) : (
          <div className="missions__timer-wrapper">
            <p className="missions__timer">
              {days}
              <span className="missions__timer-divider">:</span>
              {hours}
              <span className="missions__timer-divider">:</span>
              {minutes}
              <span className="missions__timer-divider">:</span>
              {seconds}
            </p>
            <span className="missions__timer-description">{translate('popups.mission.prize.timer.title')}:</span>
          </div>
        )
      ) : null}
      <Button
        variant={ButtonType.primary}
        title={translate('popups.mission.prize.button')}
        width="240px"
        disabled={(status === MissionRoundStatus.active && !allCompleted) || status === MissionRoundStatus.collected}
        onclick={handleCollect}
      />
    </MissionsStagePrizeStyle>
  );
};

export default MissionsStagePrize;
