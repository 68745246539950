import { OutLinks, UserType } from 'components/constants/constants';

import NavLink from 'components/Base/Links/NavLink';

import { IBurgerNavElem } from './types';
import styles from './styles.module.scss';

const BurgerNavElem: React.FC<IBurgerNavElem> = ({ title, link, src, user, onClick }) => {
  return (
    <div onClick={onClick} className={styles.burgerElemWrap}>
      {title === 'Referral' && user?.type === UserType.affiliate ? (
        <a href={OutLinks.affiliateApp} target="_blank" rel="noreferrer">
          <div className={styles.burgerElemContent}>
            <img src={src} alt="icon" className={styles.burgerElemIcon} />
            <h3 className={styles.burgerElemTitle}>Affiliate</h3>
          </div>
        </a>
      ) : (
        <NavLink caseSensitive to={link}>
          <div className={styles.burgerElemContent}>
            <img src={src} alt="icon" className={styles.burgerElemIcon} />
            <h3 className={styles.burgerElemTitle}>{title}</h3>
          </div>
        </NavLink>
      )}
    </div>
  );
};

export default BurgerNavElem;
