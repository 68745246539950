import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

interface Props {
  top: string;
  right: string;
  width: string;
  height: string;
  arrow?: string;
}

const TooltipStyle = styled.div.withConfig({
  shouldForwardProp: (prop) => !['right'].includes(prop),
})<Props>`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 13px;
  color: white;
  z-index: 500;

  .tooltip-custom__wrapper {
    height: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tooltip-custom__content {
    margin-top: 25%;
    background: rgba(7, 11, 22, 0.92);
    border-radius: 25px;
    opacity: 0;
    right: ${(props) => props.right || 0};
    top: ${(props) => props.top || 0};
    position: absolute;
    padding: 13px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    z-index: 10;
    transform: translateY(0);
    transition: max-height 0.1s ease-in-out;
    transition-duration: 00ms;
    transition-delay: 00ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    visibility: hidden;
    max-height: 0;
  }
  .tooltip-custom__content.open {
    visibility: visible;
    transition: all 0.2s ease-in-out;
    transition-duration: 200ms;
    transition-delay: 200ms;
    max-height: 500px;
    opacity: 1;
  }

  .tooltip-custom__message {
    text-align: center;
  }

  .tooltip-custom__copy {
    position: absolute;
    width: 16px;
    width: 16px;
    top: 30%;
    right: 6%;
    &:focus {
      width: 22px;
      height: 22px;
      border: 2px solid pink;
    }
    &:hover {
      cursor: url(${point}), auto !important;
    }
  }
`;

export default TooltipStyle;
