import { pathOr } from 'ramda';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import tipsConfirmIcon from 'assets/img/PopUps/ConfirmationIcon/tipsConfirm.webp';

import { ContextPopUps, ContextTokenCode } from 'context';
import { popUps } from 'components/constants/constants';
import { ResponseErrors } from 'components/constants/errors';
import { SEND_TIPS } from 'graphQl/mutations/tips/tips';
import { GET_ALL_WALLETS, GET_WALLET } from 'graphQl/query/wallet/wallet';
import { GET_USER } from 'graphQl/query/auth/profile';
import { FIND_USER_BY_ALIAS } from 'graphQl/query/tips/tips';
import { USER_SECURITY } from 'graphQl/query/auth/auth';
import { amount, getImgSrc } from 'func/common';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import Avatar from 'components/Base/Avatar';
import Button from 'components/Base/Button';

import styles from './styles.module.scss';
import { ITipsConfirm } from './types';

const TipsConfirm: React.FC<ITipsConfirm> = ({ data }) => {
  const { translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { tokenCode } = useContext(ContextTokenCode);

  const [disable, setDisable] = useState(false);
  const [error, setError] = useState(null);
  const [avatar, setAvatar] = useState<string>();

  const { data: userData } = useQuery(FIND_USER_BY_ALIAS, {
    skip: !data,
    variables: { alias: data.userName },
  });
  const [sendTips] = useMutation(SEND_TIPS, { refetchQueries: [GET_ALL_WALLETS, GET_WALLET] });
  const { data: user } = useQuery(GET_USER, { fetchPolicy: 'cache-and-network' });
  const { data: userSecurity } = useQuery(USER_SECURITY, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (userData) {
      const userAvatar = pathOr<string>('', ['findUsersByExactAlias', 'avatar'], userData);

      console.log(userData);

      if (userAvatar) {
        setAvatar(userAvatar);
      }
    }
  }, [userData]);

  const handleSubmitOnTwoFA = async (errorCodeSetter, securityCodeValue) => {
    try {
      await sendTips({
        variables: {
          input: {
            tokenCode: data.selectCurrency ? data.selectCurrency.token : tokenCode.token,
            alias: data.userName,
            amount: data.tipsAmount,
            emailOtp: securityCodeValue,
            securityCode: securityCodeValue,
          },
        },
      });
      setPopUpsOpen(null);
    } catch (err) {
      if (err.message === ResponseErrors.deletedWallet) {
        errorCodeSetter({ message: 'This user is inactive' });
      } else {
        errorCodeSetter(err);
      }
    }
  };

  const handleSendTips = async () => {
    setDisable(true);

    if (!userData.findUsersByExactAlias) {
      setError('User not found');
      return;
    }

    const security = pathOr<Record<string, boolean>>({}, ['userSecurity'], userSecurity);
    const { email_2fa, google_2fa } = security;

    const mfaDisabled = !email_2fa && !google_2fa;

    if (mfaDisabled) {
      await sendTips({
        variables: {
          input: {
            tokenCode: data.selectCurrency ? data.selectCurrency.token : tokenCode.token,
            alias: data.userName,
            amount: data.tipsAmount,
          },
        },
      })
        .then(() => setPopUpsOpen(null))
        .catch((err) => {
          setError(err.message);
          setDisable(false);
        });

      return;
    }

    setPopUpsOpen({
      modalOpen: popUps.verifySecurityCode,
      data: {
        onSubmit: handleSubmitOnTwoFA,
        autoSendOtp: true,
        title: google_2fa ? 'Google' : 'Email',
        email: user?.profile?.email,
      },
    });
  };

  return (
    <>
      <div className={styles.tipsConfirmTitle}>{translate('popups.tips.confirm.title')}</div>
      <img src={tipsConfirmIcon} alt="icon" className={styles.tipsConfirmationImage} />
      <div className={styles.tipsConfirmContent}>
        <div className={styles.tipsConfirmItem}>
          <h4>{translate('popups.tips.confirm.send')}:</h4>
          <p>
            <Avatar className={styles.avatar} userAvatar={avatar} withBorder={false} />
            {data.userName}
          </p>
        </div>
        <div className={styles.tipsConfirmItem}>
          <h4>{translate('popups.tips.confirm.amount')}:</h4>
          <p>
            <img src={getImgSrc(data.selectCurrency ? data.selectCurrency.name : tokenCode.name)} alt="icon" />
            {amount(data.tipsAmount)}
          </p>
        </div>
      </div>
      {error && <h3 className={styles.h3}>{error}</h3>}
      <Button
        className={styles.button}
        variant={ButtonType.primary}
        title={translate('popups.tips.confirm.button')}
        width="170px"
        onclick={handleSendTips}
        disabled={disable}
      />
    </>
  );
};

export default TipsConfirm;
