import styled from 'styled-components';

interface Props {
  payout?: number;
}

const MinesPopUpStyle = styled.div<Props>`
  display: flex;
  flex-direction: column;
  padding: 0 30px 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;

  .title {
    font-family: 'Montserrat';
    font-weight: 800;
    font-size: 36px;
    color: #ffffff;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 10px 0 10px;
  }

  .subtitle {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .subtitle-item1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 25px;
    p {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #888aa7;
      margin: 5px 0 0 0;
      padding: 0;
    }
  }

  .subtitle-item2 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    h3 {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      margin: 5px 0 0 0;
      padding: 0;
    }
  }

  .seeds {
    margin: 0.5rem 0 0;
  }

  .seeds .subtitle-item1 {
    margin-right: 1rem;
    min-width: 100px;
  }

  .seeds .subtitle-item1 p {
    margin-top: 8px;
    padding: 0.3rem 0;
  }

  .seeds .subtitle-item2 {
    flex-grow: 1;
    overflow: hidden;
  }

  div.seeds-text {
    box-sizing: border-box;
    display: flex;
    align-items: center;

    padding: 0.3rem 0.6rem;
    margin: 5px 0 0 0;
    width: 100%;

    border: 2px solid #3757dd;
    border-radius: 14px;
    overflow: hidden;
  }

  div.seeds-text p {
    margin: 0;

    color: #ffffff;
    font-size: 0.8rem;
    font-family: 'Montserrat';
    font-weight: 600;
    line-height: 17px;
    text-align: left;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  div.seeds-text button {
    margin-left: auto;
  }

  .verify-button-wrapper {
    margin-top: 1rem;
  }

  .amount-block {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
  }
  .amount {
    width: 150px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 16px;
    background: #2f374f;
    border: 2px solid rgba(136, 138, 167, 0.29);
    padding: 0 0 0 17px;
  }
  .payout {
    width: 150px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 16px;
    background: #2f374f;
    border: 2px solid rgba(136, 138, 167, 0.29);
    padding: 0 0 0 17px;
  }
  .amount-title {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.09px;
    color: #888aa7;
    margin: 0;
    padding: 0;
  }
  .amount-subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 -5px;
  }
  .token-icon {
    width: 30px;
    height: 30px;
  }
  .amount-result {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.09px;
    color: #ffffff;
  }

  .verify-button {
    text-transform: uppercase;
  }

  .screen-mines {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 0;
  }
  .screen-item-square {
    display: flex;
    max-width: 300px;
    flex-wrap: wrap;
    height: 270px;
  }
  .screen-square {
    width: 50px;
    height: 45px;
    margin: 4px;
    border-radius: 12px;
  }
  .screen__mines-detail-wrap {
    display: flex;
    width: 280px;
    height: 48px;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 0 0 0;
  }
  .screen-item-drop {
    width: 150px;
    height: 38px;
    border-radius: 24px;
    background: #00000033;
    color: #ffffff;
    padding: 0 15px 0 20px;
    opacity: 1;
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-top: -5px;
    i {
      position: absolute;
      top: 12px;
      right: 20px;
      border: 1px solid #313e57;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      height: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
  .square__active,
  .square__win,
  .square__default,
  .square__lose,
  .square__single {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-align: center;
    mix-blend-mode: normal;
    box-shadow: 0 5px 9px 5px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    border-radius: 16px;
  }
  .square__win {
    background: radial-gradient(78.2% 77.86% at 48.56% 48.93%, rgba(0, 94, 18, 0) 41.63%, rgba(14, 150, 40, 0.5) 100%);
    border: 3px solid #0e9628;
    img {
      width: 40px;
      height: 40px;
      opacity: 1;
      margin-bottom: -3px;
    }
  }

  .square__lose,
  .square__single {
    background: rgba(22, 32, 53, 1);
    border: 2px solid rgba(22, 32, 53, 1);
    img {
      width: 46px;
      height: 41px;
      opacity: 1;
    }
  }
  .square__single {
    border: 3px solid #ed5b12;
    img {
      width: 44px;
      height: 39px;
      opacity: 1;
    }
  }

  .square__finished {
    opacity: 0.3;
  }

  @media (max-width: 1024px) {
  }
  @media (max-width: 863px) {
  }
  @media (max-width: 540px) {
    .title {
      font-size: 24px;
    }
    .subtitle-item1 {
      margin-right: 15px;
      width: 50px;
      p {
        font-size: 12px;
      }
    }
    .subtitle-item2 {
      h3 {
        font-size: 10px;
        word-break: break-all;
      }
    }
    .amount-block {
      justify-content: space-between;
    }
    .payout,
    .amount {
      width: 140px;
      padding: 0 0 0 10px;
      height: 50px;
    }
    .amount-title {
      font-size: 12px;
    }
    .token-icon {
      width: 25px;
      height: 25px;
    }
    .amount-result {
      font-size: 12px;
    }
    .screen-item-square {
      max-width: 260px;
      height: 230px;
    }
    .screen-square {
      width: 45px;
      height: 40px;
      margin: 2px;
      border-radius: 10px;
    }
    .square__win {
      img {
        width: 36px;
        height: 36px;
        opacity: 1;
        margin-bottom: -3px;
      }
    }

    .square__lose,
    .square__single {
      img {
        width: 41px;
        height: 36px;
        opacity: 1;
      }
    }
    .square__single {
      border: 3px solid #ed5b12;
      img {
        width: 39px;
        height: 34px;
        opacity: 1;
      }
    }
  }
  @media (max-width: 480px) {
  }
  @media (max-width: 370px) {
  }
`;

export default MinesPopUpStyle;
