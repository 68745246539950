import { gql } from '@apollo/client';

export const GET_BET = gql`
  query bet($betId: String!) {
    bet(id: $betId) {
      amount
      createdAt
      id
      betOn
      isAuto
      multiplier
      payout
      result
      token {
        tokenCode
        displayName
      }
      serverSeed
      playerSeed
    }
  }
`;
