import { useContext } from 'react';

import { getImgSrc, handleSelectInputValue, prepareAmount } from 'func/common';
import { bigOrEqual } from 'func/prepareDataDice';
import { ContextWallet } from 'context';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import Button from 'components/Base/Button';

import BuyTicketBlockStyle from './styles';
import { BuyTicketSize, IBuyTicketBlock } from './types';

const BuyTicketBlock: React.FC<IBuyTicketBlock> = ({
  size,
  inputValue,
  onChange,
  onSubmit,
  disable,
  ticketPrice,
  disabledInput,
}) => {
  const { walletUserStar } = useContext(ContextWallet);
  const { translate } = useLocalization();

  const handleChange = (e) => {
    let value;

    value = e.target.value;
    const valid = /^\?\d+\\d*$|^\?[\d]*$/;
    const number = /\\d+\\d*|\[\d]*|[\d]+\[\d]*|[\d]+/;

    if (e.nativeEvent.inputType === 'deleteContentBackward') {
      onChange(e.target.value);
      return;
    }

    if (value < 1) {
      onChange(1);
      return;
    }

    if (value === '.') {
      onChange(1);
      return;
    }

    const formattedValue = prepareAmount(value);
    const formattedWallet = prepareAmount(walletUserStar || 0);

    if (bigOrEqual(formattedValue, formattedWallet)) {
      onChange(Math.trunc(walletUserStar || 1));
      return;
    }

    if (!valid.test(value)) {
      const n = value.match(number);
      value = n ? n[0] : '';
    }
    onChange(value);
  };
  const handleReset = () => onChange(1);

  return (
    <BuyTicketBlockStyle size={size}>
      <div className="buy__ticket-content">
        <div className="buy__ticket-content__item item1">
          <p className="buy__ticket-content__item-title">{translate('popups.lottery.price')}</p>
        </div>
        <div className="buy__ticket-content__item item2">
          <img alt="icon" src={getImgSrc('STAR')} />
          <h4>{`${ticketPrice} STAR Token`}</h4>
        </div>
      </div>
      <div className="buy__ticket-content-submit">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => handleChange(e)}
          disabled={disabledInput}
          onFocus={handleSelectInputValue}
        />
        <label className={disabledInput ? 'input--label--reset disabled' : 'input--label--reset'} onClick={handleReset}>
          x
        </label>
        <div className="buy__ticket-content-submit--button">
          <Button
            variant={ButtonType.primary}
            disabled={disable}
            width={size === BuyTicketSize.BIG ? '150px' : '110px'}
            title={translate('popups.lottery.buttons.buy')}
            onclick={onSubmit}
          />
        </div>
      </div>
    </BuyTicketBlockStyle>
  );
};

export default BuyTicketBlock;
