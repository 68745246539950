import { IEvent, ITournament, RankItem } from 'types/requestTypes';
import { IExchangeRates, ILotteryState, ISetting, IToken } from 'types';

export interface IGeneralDataState {
  vipRank: number;
  ranksList: null | RankItem[];
  exchangeRates: IExchangeRates;
  eventsList: null | IEvent[];
  tournamentsList: null | ITournament[];
  lotteryState: null | ILotteryState;
  settings: ISetting[];
  activeTokens: IToken[];
}

export const generalDataInitialState: IGeneralDataState = {
  vipRank: 11,
  ranksList: null,
  exchangeRates: {},
  eventsList: null,
  tournamentsList: null,
  lotteryState: null,
  settings: [],
  activeTokens: [],
};
