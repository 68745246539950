import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { useLocalization } from 'components/Internationalization';
import { CHANGELLY_AVAILABLE_COUNTRIES } from 'graphQl/query/buyCrypto';

import styles from './styles.module.scss';
import { BuyCryptoSettings, BuyCryptoState, IAvailableCountryResponse, ProviderCode } from './types';
import BuyCryptoProviders from './components/BuyCryptoProviders';
import BuyCryptoContent from './components/BuyCryptoContent';
import BuyCryptoNotAvailable from './components/BuyCryptoNotAvailable';

const BuyCrypto: React.FC = () => {
  const { translate } = useLocalization();

  const [actionState, setActionState] = useState<BuyCryptoState>(BuyCryptoState.default);
  const [provider, setProvider] = useState<ProviderCode>(ProviderCode.moonpay);
  const [countries, setCountries] = useState<IAvailableCountryResponse[]>([]);
  const [sendAmount, setSendAmount] = useState(BuyCryptoSettings.amount);
  const [selectedFiat, setSelectedFiat] = useState(BuyCryptoSettings.fiat);
  const [selectedCrypto, setSelectedCrypto] = useState(BuyCryptoSettings.crypto);

  const { data: availableCountryResponse } = useQuery(CHANGELLY_AVAILABLE_COUNTRIES, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (availableCountryResponse) {
      const isAvailable = availableCountryResponse.changellyAvailableCountries.isAvailable;
      if (!isAvailable) {
        setActionState(BuyCryptoState.notAvailable);
        setCountries(availableCountryResponse.changellyAvailableCountries.availableCountries);
        return;
      }

      setActionState(BuyCryptoState.available);
    }
  }, [availableCountryResponse]);

  return (
    <>
      <p className={styles.buyTitle}>{translate('popups.wallet.nav.buy.crypto')}</p>
      {actionState === BuyCryptoState.available ? (
        <>
          <BuyCryptoProviders provider={provider} setProvider={setProvider} />
          <BuyCryptoContent
            provider={provider}
            sendAmount={sendAmount}
            setSendAmount={setSendAmount}
            selectedFiat={selectedFiat}
            setSelectedFiat={setSelectedFiat}
            selectedCrypto={selectedCrypto}
            setSelectedCrypto={setSelectedCrypto}
          />
        </>
      ) : null}
      {actionState === BuyCryptoState.notAvailable ? <BuyCryptoNotAvailable countries={countries} /> : null}
    </>
  );
};

export default BuyCrypto;
