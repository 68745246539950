import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import kenoSlotIcon from 'assets/img/Keno/keno-slot.webp';

import config from 'config';
import { GET_BET } from 'graphQl/query/bet/bet';
import { setSquareListForBetDetails } from 'func/prepareDataKeno';
import { EKenoSquareClass } from 'components/constants/games';

import Loader from 'components/Base/Loader/Loader';
import BetDetailsWrap from '../base/BetDetailsWrap';

import { IBet } from '../base/BetDetailsWrap/types';
import styles from './styles.module.scss';
import { IKenoBetDetails } from './types';

const { sha256ValidatorUrl } = config;

const KenoBetDetails: React.FC<IKenoBetDetails> = ({ data }) => {
  const [bet, setBet] = useState<IBet>();
  const [squaresList, setSquaresList] = useState([]);

  const { data: betData, loading } = useQuery(GET_BET, {
    variables: { betId: data.id },
  });

  useEffect(() => {
    const newBet = pathOr(undefined, ['bet'], betData);

    if (newBet) {
      setBet(newBet);
      setSquaresList(setSquareListForBetDetails(newBet?.result?.sequence, newBet?.betOn?.prediction));
    }
  }, [betData]);

  if (loading || !bet) return <Loader pages />;

  const handleVerify = () => {
    window.open(sha256ValidatorUrl, '_blank');
  };

  return (
    <BetDetailsWrap bet={bet} withVerify onVerify={handleVerify}>
      <div className={styles.kenoBetSquaresContent}>
        {squaresList.map((el) => (
          <div key={el.index} className={`${styles.kenoSquareItemDetail} ${styles[el.className]}`}>
            <p>{+el.index + 1}</p>
            {el.className === EKenoSquareClass.detailWin ? <img src={kenoSlotIcon} alt="icon" /> : null}
          </div>
        ))}
      </div>
    </BetDetailsWrap>
  );
};

export default KenoBetDetails;
