import styled from 'styled-components';

import { IProgressBarCircle } from './types';

export const CircleStyle = styled.div.withConfig({
  shouldForwardProp: (prop) => !['progresswidth'].includes(prop),
})<IProgressBarCircle>`
  top: ${(props) => props.top && props.top + '%'};
  right: ${(props) => props.right && props.right + 'px'};
  left: ${(props) => props.left && props.left + '%'};
  bottom: ${(props) => props.bottom && props.bottom + 'px'};
  border: ${(props) => {
    if (props.start <= props.progresswidth) {
      return '1px solid #FFFFFF';
    }
    return 'none';
  }};
  background: ${(props) => {
    if (props.start <= props.progresswidth) {
      return '#8BC834';
    }
    return 'rgba(255, 255, 255, 0.2)';
  }};
  @media (max-width: 470px) {
    display: ${(props) => (props.start === 25 || props.start === 75) && 'none'};
  }
`;
