import React from 'react';

import { INotificationContext } from './types';

const voidFunction = () => null;

const NotificationContext = React.createContext<INotificationContext>({
  notificationListOpen: false,
  pendingNotifications: [],
  notifications: [],
  onOpenNotificationList: voidFunction,
  onRead: voidFunction,
  onReadAll: voidFunction,
  onRemove: voidFunction,
  onRemoveAll: voidFunction,
  onRemovePendingNotification: voidFunction,
  onPushCustomNotification: voidFunction,
});

export default NotificationContext;
