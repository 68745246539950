import React, { useEffect, useState } from 'react';

import { useKeno } from 'context/contextKeno/contextKeno';
import { Types } from 'context/contextKeno/kenoReduser';
import { useLocalization } from 'components/Internationalization';

import { disableKenoButtons, getKenoDropTitle, getKenoRiskDrop } from 'func/prepareDataKeno';
import styles from './styles.module.scss';
import KenoRiskDropdown from './KenoRiskDropdown';

const KenoRiskAuto: React.FC = () => {
  const { translate } = useLocalization();

  const [state, dispatch] = useKeno();

  const { risk, gameCondition, disable } = state;

  const [dropdownRisk, setDropdownRisk] = useState([]);

  useEffect(() => {
    if (risk) {
      const riskList = getKenoRiskDrop(translate);
      setDropdownRisk(
        riskList.map((el) => ({
          title: el.title,
          idTitle: el.idTitle,
          func: () => dispatch({ type: Types.RISK, payload: { risk: el.idTitle } }),
        })),
      );
    }
  }, [risk]);

  return (
    <div className={styles.kenoRiskAutoWrap}>
      <KenoRiskDropdown
        dropTitle={getKenoDropTitle(risk, dropdownRisk)}
        dropData={dropdownRisk}
        disable={disableKenoButtons(gameCondition, disable)}
      />
    </div>
  );
};

export default KenoRiskAuto;
