import styled from 'styled-components';
import { EVENT_STATE } from 'components/constants/constants';

interface Props {
  eventstate?: string;
  frontcard?: string;
}

const TimerStile = styled.div.withConfig({
  shouldForwardProp: (prop) => !['right'].includes(prop),
})<Props>`
  color: #fff;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  width: 149px;
  align-items: flex-start;
  margin-right: ${(props) => props.frontcard && '20px'};

  .timer__content {
    width: 100%;
    height: 38px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    border: 3px;
    color: #fff;
    background: ${(props) => {
      if (props.eventstate === EVENT_STATE.started) {
        return 'linear-gradient(180deg, #0E9628 0%, #7DB30A 100%)';
      }
      return '#24365B';
    }};
    background-clip: padding-box;
    //border: solid 3px transparent;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -3px;
      border-radius: 20px;
      background: ${(props) => {
        if (props.eventstate === EVENT_STATE.finished) {
          return '#888AA7';
        }
        return 'linear-gradient(180deg, #0E9628 0%, #7DB30A 100%)';
      }};
    }

    span {
      display: inline-block;
      flex-shrink: 0;
      width: 95px;
      text-align: center;
      font-size: 12px;
      font-weight: 700;
    }

    img {
      width: 15px;
      margin: 0 3px 3px -3px;
    }

    border: ${(props) => {
      if (!props.frontcard) {
        if (props.eventstate === EVENT_STATE.finished) {
          return '3px solid #888AA7';
        }
        if (props.eventstate !== EVENT_STATE.started) {
          return '3px';
        }
      }
    }};
  }

  .timer__uppercase {
    text-transform: uppercase;
  }

  p {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #696c80;
    //margin: 0 0 7px 0;
    margin: 0 0 7px 0;
  }

  @media (max-width: 1200px) {
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 580px) {
  }
  @media (max-width: 530px) {
  }
  @media (max-width: 400px) {
  }
`;

export default TimerStile;
