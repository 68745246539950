import clsx from 'clsx';

import { ReactComponent as UserIcon } from 'assets/img/common/user.svg';
import { ReactComponent as DotIcon } from 'assets/img/common/dot.svg';

import { getCurrencySymbol } from 'func/common';

import styles from './styles.module.scss';
import { ISlotLive } from './types';

const blackjackPlayers = 7;

const SlotLive: React.FC<ISlotLive> = ({ table, className }) => {
  const { currency, tableLimits, availableSeats, totalSeatedPlayers, tableOpen } = table;

  const hasPlayers = totalSeatedPlayers > 1;

  return (
    <>
      <div className={clsx(styles.status, className)}>
        <div className={styles.dotWrapper}>
          <DotIcon
            className={clsx(styles.dot, tableOpen ? styles.open : styles.closed, 'animate__animated animate__pulse')}
          />
        </div>
        <span>{tableOpen ? 'Open' : 'Closed'}</span>
      </div>
      {hasPlayers ? (
        <div className={clsx(styles.players, className)}>
          <UserIcon className={styles.playerIcon} />
          <span className={styles.playersCount}>{totalSeatedPlayers}</span>
        </div>
      ) : null}
      {availableSeats ? (
        <div className={clsx(styles.players, className)}>
          <UserIcon className={styles.playerIcon} />
          <span className={styles.playersCount}>
            {blackjackPlayers - availableSeats} / {blackjackPlayers}
          </span>
        </div>
      ) : null}
      {tableLimits ? (
        <div className={clsx(styles.limits, !hasPlayers && !availableSeats ? styles.withoutPlayers : '', className)}>
          <span className={styles.symbol}>{getCurrencySymbol(currency)}</span>
          <span className={styles.amount}>
            {tableLimits.minBet} - {tableLimits.maxBet}
          </span>
        </div>
      ) : null}
    </>
  );
};

export default SlotLive;
