import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

interface Props {
  checkboxValue: boolean;
  config?: string;
}

const AutoCheckboxStyle = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${(props) => props.config === 'limbo' && 'column'};
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.09px;
    color: #ffffff;
    margin: ${(props) => {
      if (props.config === 'limbo') return '0 0 10px 12px';
      return '0 10px 0 0';
    }};
  }

  .auto__checkbox-wrap {
    position: relative;
    margin-top: -5px;
    height: 100%;
    input {
      opacity: 0;
      z-index: 1;
      border-radius: 19px;
      width: 42px;
      height: 21px;
      &:checked + .auto__checkbox-label {
        background: linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
        &::after {
          content: '';
          display: block;
          border-radius: 50%;
          width: 21px;
          height: 21px;
          margin-left: 29px;
          transition: 0.2s;
        }
      }
      &:disabled + .auto__checkbox-label {
        pointer-events: none;
      }
    }
  }
  .auto__checkbox-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 55px;
    height: 30px;
    border-radius: 25px;
    background: #bebebe;
    cursor: url(${point}), auto !important;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 21px;
      height: 21px;
      margin: 4px 3px 3px 5px;
      background: #ffffff;
      box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
      transition: 0.2s;
    }
  }

  @media (max-width: 1300px) {
  }
  @media (max-width: 1250px) {
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
    flex-direction: ${(props) => props.config === 'limbo' && 'row'};
    p {
      margin: ${(props) => props.config === 'limbo' && '0 10px 0 0'};
    }
  }
  @media (max-width: 580px) {
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 400px) {
  }
  @media (max-width: 360px) {
  }
`;

export default AutoCheckboxStyle;
