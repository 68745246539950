import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

const CoinOptionButtonsStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  margin: 15px 0;
  p {
    margin: 0;
  }

  .coin__button--multiply {
    width: 0;
    height: 0;
    justify-content: flex-start;
    align-items: center;
    background: linear-gradient(355.07deg, #0e4d96 3.61%, #2b79d5 92.33%);
    mix-blend-mode: normal;
    border-radius: 16px;
    cursor: url(${point}), auto !important;
    border: none;
    box-shadow: none;
    touch-action: manipulation;
    outline: none;

    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    display: none;

    &:active {
      filter: brightness(110%);
    }
    &:hover {
      filter: brightness(110%);
    }
    &:disabled {
      opacity: 0.7;
      pointer-events: none;
      filter: brightness(70%);
    }
  }
  .coin__button--regular {
    height: 0;
    width: 0;
    justify-content: flex-start;
    align-items: center;
    background: rgba(48, 60, 78, 0.5);
    mix-blend-mode: normal;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 16px;
    cursor: url(${point}), auto !important;
    box-shadow: none;
    outline: none;
    touch-action: manipulation;

    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    display: none;

    &:active {
      background: linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
    }
    &:focus {
      background: linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
      border: none;
    }
    &:hover {
      filter: brightness(110%);
    }
    &:disabled {
      opacity: 0.7;
      pointer-events: none;
      border: 2px solid rgba(255, 255, 255, 0.2);
    }
  }

  .coin__button--regular.active,
  .coin__button--multiply.active {
    animation: appearCoinButtons 1s;
    transform-origin: center;
    display: flex;
    opacity: 1;
    height: 74px;
    width: 48.5%;
    visibility: visible;
    padding: 0 0 0 5%;
    &:nth-of-type(1) {
      width: 49.5%;
    }
  }

  .coin__button--img {
    display: inline-block;
    width: 45px;
    margin-right: 10px;
  }

  .coin__button--profit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    h4 {
      margin: 0;
      color: #ffffff;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.09px;
    }
    h5 {
      margin: 0;
      color: #ffffff;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.09px;
      span {
        display: inline-block;
        margin-right: 5px;
        color: #ffffff;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.09px;
      }
    }
  }

  @keyframes appearCoinButtons {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 1300px) {
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
    .coin__button--regular.active,
    .coin__button--multiply.active {
      padding: 0 0 0 10px;
      &:nth-of-type(1) {
        width: 48.5%;
      }
    }
    .coin__button--img {
      width: 30px;
    }

    .coin__button--profit {
      h4 {
        font-size: 16px;
      }
      h5 {
        font-size: 12px;
      }
    }
  }
  @media (max-width: 580px) {
  }
  @media (max-width: 500px) {
    .coin__button--regular.active,
    .coin__button--multiply.active {
      height: 70px;
    }
  }
  @media (max-width: 460px) {
    padding-left: 5px;
    .coin__button--img {
      width: 35px;
      margin-right: 5px;
    }

    .coin__button--profit {
      h4 {
        font-size: 16px;
      }
      h5 {
        font-size: 12px;
        span {
          display: none;
        }
      }
    }
  }
  @media (max-width: 360px) {
  }
`;

export default CoinOptionButtonsStyle;
