import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import Table from 'components/Base/Table';
import EmptyTable from 'components/Base/EmptyTable';
import { useLocalization } from 'components/Internationalization';
import { TranslationType } from 'types';

interface Props {
  cutCurrency?: boolean;
  thWidth: string;
  titles: Array<{ columnClass: string; title: string }>;
  config?: string;
  prepareDataTable: (data: any, t?: TranslationType) => any;
  data: any;
}

const TableWithoutPagination: React.FC<Props> = ({ cutCurrency, thWidth, titles, config, prepareDataTable, data }) => {
  const { translate } = useLocalization();
  const isMobile = useMediaQuery({ query: '(max-width: 581px)' });
  const [dataTable, setDataTable] = useState([]);
  const [perPage, setPerPage] = useState(20);

  useEffect(() => {
    if (isMobile) {
      setPerPage(10);
    } else {
      setPerPage(20);
    }
  }, [isMobile]);

  useEffect(() => {
    if (data) {
      const result = prepareDataTable(data, translate);
      setDataTable(result.slice(0, perPage));
    }
  }, [data, perPage]);

  if (dataTable.length < 1) {
    return <EmptyTable lines={2} marginTop="0" />;
  }

  return <Table data={dataTable} titles={titles} thWidth={thWidth} cutCurrency={cutCurrency} config={config} />;
};

export default TableWithoutPagination;
