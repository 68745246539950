import { useState } from 'react';

import { useLocalization } from 'components/Internationalization';

import BuyCryptoNotAvailableLayout from './buyCryptoNotAvailableLayout';
import { IBuyCryptoNotAvailable } from './types';

const BuyCryptoNotAvailable: React.FC<IBuyCryptoNotAvailable> = ({ countries }) => {
  const { translate } = useLocalization();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(!isOpen);

  const handleBlur = (event) => {
    if (event.relatedTarget?.tabIndex >= 100000) return;
    setIsOpen(false);
  };

  return (
    <BuyCryptoNotAvailableLayout
      countries={countries}
      isOpen={isOpen}
      onOpen={handleOpen}
      onBlur={handleBlur}
      titleText={translate('popups.wallet.buy.crypto.not.available.title')}
      subtitleText={translate('popups.wallet.buy.crypto.not.available.subtitle')}
      dropText={translate('popups.wallet.buy.crypto.not.available.drop')}
    />
  );
};

export default BuyCryptoNotAvailable;
