import Slider from 'react-slick';
import { useEffect, useRef, useState } from 'react';

import { disableSliderArrow } from 'func/prepareDataLottery';
import { useLocalization } from 'components/Internationalization';

import LotterySliderStyle from './styles';
import { ILotteryRoundSlider } from './types';

const LotteryRoundSlider: React.FC<ILotteryRoundSlider> = ({ data, setCurrentRound, currentRound }) => {
  const { translate } = useLocalization();

  const carouselRef = useRef(null);

  const [active, setActive] = useState(null);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    className: 'lottery-slider',
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: data?.length - 1,
    arrows: false,
    autoplay: false,
    rtl: false,
  };

  const gotoNext = () => {
    if (active < 9) {
      setActive(+active + 1);
      setCurrentRound(+active + 1);
      carouselRef.current.slickGoTo(+active + 1);
    } else {
      setActive(9);
      setCurrentRound(9);
      carouselRef.current.slickGoTo(9);
    }
  };

  const gotoPrev = () => {
    if (active < 1) {
      setActive(0);
      setCurrentRound(0);
      carouselRef.current.slickGoTo(0);
    } else {
      setActive(active - 1);
      setCurrentRound(active - 1);
      carouselRef.current.slickGoTo(active - 1);
    }
  };

  useEffect(() => {
    if (data) {
      setActive(data?.length - 1);
      setCurrentRound(data?.length - 1);
    }
  }, [data]);

  useEffect(() => {
    if (currentRound) {
      carouselRef.current.slickGoTo(currentRound);
    }
  }, [currentRound]);

  if (!data) return null;

  return (
    <LotterySliderStyle>
      <i
        className={
          disableSliderArrow('left', active, data.length - 1) ? 'slider-arrow left disable' : 'slider-arrow left'
        }
        onClick={gotoPrev}
      />
      <i
        className={
          disableSliderArrow('right', active, data.length - 1) ? 'slider-arrow right disable' : 'slider-arrow right'
        }
        onClick={gotoNext}
      />
      <Slider {...settings} ref={carouselRef}>
        {data.map((slide) => (
          <div key={slide.id} className="slide-lottery">
            <h5>
              {translate('popups.lottery.round')} {slide.id}
            </h5>
          </div>
        ))}
      </Slider>
    </LotterySliderStyle>
  );
};

export default LotteryRoundSlider;
