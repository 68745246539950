import styled from 'styled-components';
import headBg from 'assets/img/Lottery/lotteryHeaderImg.webp';

const LotteryDesktopHeaderStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  //height: 116px;
  width: 100%;

  border-radius: 20px;
  padding: 20px 10px 10px 20px;
  margin-bottom: 10px;
  background-image: url(${headBg});
  background-size: cover;

  .lot__header-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 44px;
      margin: 0;

      letter-spacing: 0.21px;

      color: #ffffff;
    }
  }
  .lot__header--content {
    padding: 10px 0 0 0;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .lot__header--content h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    margin: 0;

    color: #fcf6e2;
  }
  .lot__header-round {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      padding: 0 5px 0 10px;
      margin: 0;

      color: #ffffff;
    }

    img {
      display: inline-block;
      width: 29px;
      height: 29px;
    }
  }

  .lot__header--content-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .lot__header--content-prize {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 35px;
      display: inline-block;
      margin-left: -5px;
    }
    p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      margin: 0;

      color: #ffffff;
    }
  }

  @media (max-width: 1300px) {
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1100px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 580px) {
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 400px) {
  }
`;

export default LotteryDesktopHeaderStyle;
