import { useEffect, useState } from 'react';

import { useAppSelector } from 'hooks/useAppSelector';
import {
  DividendsPageTablesTitles,
  DIVIDENDS_TABLE_TITLES,
  DIVIDENDS_TABLE_TITLES_UNAUTH,
} from 'components/constants/table';
import { userToken } from 'store/user/user.selectors';
import { ITableTitle } from 'components/common/TablesTitles/types';

import TablesTitles from 'components/common/TablesTitles';
import AllDividends from './components/AllDividends';
import MyCollections from './components/MyCollections';
import MyDividends from './components/MyDividends';

import styles from './styles.module.scss';

const ReferralTable: React.FC = () => {
  const token = useAppSelector(userToken);

  const [table, setTable] = useState(DividendsPageTablesTitles.activity);
  const [titles, setTitles] = useState<ITableTitle[]>([]);

  const handleTitle = (title) => setTable(title);

  useEffect(() => {
    const newTabs = token ? DIVIDENDS_TABLE_TITLES : DIVIDENDS_TABLE_TITLES_UNAUTH;

    setTitles(newTabs);
  }, [token]);

  return (
    <div className={styles.dividendsTables}>
      <div className={styles.dividendsTablesTitles}>
        <TablesTitles currentTitle={table} titles={titles} onTitle={handleTitle} />
      </div>
      <div className={styles.dividendsTablesWrap}>
        {table === DividendsPageTablesTitles.activity ? <AllDividends /> : null}
        {table === DividendsPageTablesTitles.collection ? <MyCollections /> : null}
        {table === DividendsPageTablesTitles.dividends ? <MyDividends /> : null}
      </div>
    </div>
  );
};

export default ReferralTable;
