import React from 'react';
import ReactTooltip from 'react-tooltip';

import { Links } from 'components/constants';
import { useLocalization } from 'components/Internationalization';

import gamesIcon from 'assets/img/PageTitles/Games_Icon.svg';

import NavLink from 'components/Base/Links/NavLink';

import GamesAdditional from '../GamesAdditional';
import styles from './styles.module.scss';
import { ISidebarGamesItem } from './types';

const SidebarGamesItem: React.FC<ISidebarGamesItem> = ({ onMenu }) => {
  const { translate } = useLocalization();
  return (
    <>
      <div className="sidebar__item" key={translate('nav.games')} data-tip data-for="sidebarGames">
        <NavLink caseSensitive to={Links.games}>
          <div className="sidebar__item-content" onClick={onMenu}>
            <img src={gamesIcon} alt="icon" className="sidebar__icon" />
            <h4 className="sidebar__item-title">{translate('nav.games')}</h4>
          </div>
        </NavLink>
      </div>
      <ReactTooltip
        id="sidebarGames"
        place="right"
        effect="solid"
        className={styles.wrapper}
        delayHide={400}
        arrowColor="transparent"
        overridePosition={({ left, top }) => {
          // top = top + 30;
          left = left - 10;
          return { top, left };
        }}
      >
        <GamesAdditional />
      </ReactTooltip>
    </>
  );
};

export default SidebarGamesItem;
