import { gql } from '@apollo/client';

export const GET_TOURNAMENT = gql`
  query tournaments {
    tournaments {
      id
      name
      info
      deskBanner
      tabBanner
      mobBanner
      prize
      startAt
      endAt
      rules
      type
      provider {
        name
        logo
        currencies
      }
      games {
        id
      }
    }
  }
`;
