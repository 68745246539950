import { gql } from '@apollo/client';

export const COMMIT_LIMBO_HASH = gql`
  mutation commitLimboHash($hash: String!) {
    commitLimboHash(hash: $hash) {
      commitVector
      stateId
    }
  }
`;

export const PLAY_LIMBO_BET = gql`
  mutation playLimboBet($bet: LimboBet!) {
    playLimboBet(bet: $bet) {
      coefficient
      multiplier
      payout
      seed
      id
    }
  }
`;
