import { ETokenCode } from 'components/constants/constants';

import gamesIcon from 'assets/img/Landing/Join/games.webp';
import gamesBgIcon from 'assets/img/Landing/Join/games-bg.webp';
import dividendsIcon from 'assets/img/Landing/Join/dividends.webp';
import dividendsBgIcon from 'assets/img/Landing/Join/dividends-bg.webp';
import cashbackIcon from 'assets/img/Landing/Join/cashback.webp';
import cashbackBgIcon from 'assets/img/Landing/Join/cashback-bg.webp';

import registerIcon from 'assets/img/Landing/Join/register.webp';
import depositIcon from 'assets/img/Landing/Join/deposit.webp';
import playIcon from 'assets/img/Landing/Join/play-games.webp';
import bonusIcon from 'assets/img/Landing/Join/bonus.webp';

export const LANDING_JOIN_CURRENCY = [
  ETokenCode.BTC,
  ETokenCode.ETH,
  ETokenCode.BNB,
  ETokenCode.TRX,
  ETokenCode.USDT,
  ETokenCode.LTC,
];

export const LANDING_JOIN_FEATURES = [
  {
    title: 'landing.join.games.title',
    subtitle: 'landing.join.games.subtitle',
    mainImage: gamesIcon,
    bgImage: gamesBgIcon,
  },
  {
    title: 'landing.join.dividends.title',
    subtitle: 'landing.join.dividends.subtitle',
    mainImage: dividendsIcon,
    bgImage: dividendsBgIcon,
  },
  {
    title: 'landing.join.cashback.title',
    subtitle: 'landing.join.cashback.subtitle',
    mainImage: cashbackIcon,
    bgImage: cashbackBgIcon,
  },
];

export const LANDING_JOIN_CARDS = [
  {
    title: 'landing.join.card.account.title',
    subtitle: 'landing.join.card.account.subtitle',
    number: 1,
    icon: registerIcon,
  },
  {
    title: 'landing.join.card.deposit.title',
    subtitle: 'landing.join.card.deposit.subtitle',
    number: 2,
    icon: depositIcon,
  },
  { title: 'landing.join.games.title', subtitle: 'landing.join.games.subtitle', number: 3, icon: playIcon },
  { title: 'landing.join.card.bonus.title', subtitle: 'landing.join.card.bonus.subtitle', number: 4, icon: bonusIcon },
];

export const LANDING_JOIN_TERMS = [
  'landing.join.popups.para1',
  'landing.join.popups.para2',
  'landing.join.popups.para3',
  'landing.join.popups.para4',
  'landing.join.popups.para5',
  'landing.join.popups.para6',
  'landing.join.popups.para7',
  'landing.join.popups.para7',
  'landing.join.popups.para9',
  'landing.join.popups.para10',
  'landing.join.popups.para11',
  'landing.join.popups.para12',
  'landing.join.popups.para13',
];
