import React, { useEffect, useState } from 'react';
import { getTrackBackground, Range } from 'react-range';
import { generate as id } from 'shortid';

import DiceRangePopUpStyle from './styles';

interface Props {
  inputRangeValue: [number];
  rollUnderValue: boolean;
}

const RANGE_STEP = 1;
const RANGE_MIN_VALUE = -3;
const RANGE_MAX_VALUE = 102;
const ROLL_UNDER_COLORS = ['#7DB30A', '#D01111'];
const ROLL_OVER_COLORS = ['#D01111', '#7DB30A'];

const RangeDicePopUp: React.FC<Props> = ({ inputRangeValue, rollUnderValue }) => {
  const [backgroundColor, setBackgroundColor] = useState('');
  const [predictionNumber, setPredictionNumber] = useState(-1);
  const [keyValue, setKeyValue] = useState('');

  useEffect(() => {
    if (inputRangeValue) {
      const newBackgroundColor = getTrackBackground({
        values: inputRangeValue,
        colors: rollUnderValue ? ROLL_UNDER_COLORS : ROLL_OVER_COLORS,
        min: RANGE_MIN_VALUE,
        max: RANGE_MAX_VALUE,
      });
      setBackgroundColor(newBackgroundColor);
      setPredictionNumber(inputRangeValue[0]);
      setTimeout(() => setKeyValue(id()), 600);
    }
    return () => {
      setPredictionNumber(-1);
      setBackgroundColor('');
      setKeyValue('');
    };
  }, [inputRangeValue, rollUnderValue]);

  if (!inputRangeValue || predictionNumber < 0 || !keyValue) return <div />;

  return (
    <DiceRangePopUpStyle MIN={RANGE_MIN_VALUE}>
      <div className="dice__range-wrap">
        <Range
          values={[predictionNumber]}
          step={RANGE_STEP}
          min={RANGE_MIN_VALUE}
          max={RANGE_MAX_VALUE}
          disabled
          onChange={() => {}}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={props.style}
              className="dice__range-line-wrap"
            >
              <div
                ref={props.ref}
                style={{ background: backgroundColor, alignSelf: 'center' }}
                className="dice__range-line"
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div {...props} style={props.style} className="dice__range-thumb">
              {predictionNumber}
            </div>
          )}
        />
      </div>
    </DiceRangePopUpStyle>
  );
};

export default RangeDicePopUp;
