import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { RESEND_EMAIL_TWO_FA } from 'graphQl/query/auth/auth';
import { AuthErrors } from 'components/constants/auth';
import { ButtonType } from 'components/Base/Button/types';

import ButtonSubmit from 'components/Base/PopUps/components/ButtonSubmit';
import Input2FA from 'components/Base/Input/Input2FA';
import { useLocalization } from 'components/Internationalization';

import SecurityCodeContainer from './styles';
import { IVerifySecurityCode } from './types';

const VerifySecurityCode: React.FC<IVerifySecurityCode> = ({ data, currentEmail }) => {
  const { translate, language } = useLocalization();
  const [showTooltip, setShowTooltip] = useState(false);
  const [error, setError] = useState<Error>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [disableButton, setDisableButton] = useState(false);

  const [sendOtpToEmail] = useLazyQuery(RESEND_EMAIL_TWO_FA, { fetchPolicy: 'no-cache' });

  const { onSubmit, title, email, autoSendOtp = false } = data;

  useEffect(() => {
    if (error) {
      if (error.message === AuthErrors.expiredSLT) {
        setErrorMessage(translate('popups.verify.security.code.error.expired'));
        return;
      }

      if (error.message === AuthErrors.tooManyRequests) {
        setErrorMessage(translate('popups.verify.security.code.error.too.many'));
        return;
      }

      setErrorMessage(error.message);
    }
  }, [error, language]);

  useEffect(() => {
    if (title === 'Email' && autoSendOtp) {
      sendOtpToEmail();
    }
  }, [title, autoSendOtp, email, currentEmail]);

  const handleChange = (value) => {
    setInputValue(value);
    setError(null);
    setErrorMessage('');
  };

  const handleSubmit = async () => {
    if (inputValue.length < 6) {
      setErrorMessage(translate('popups.verify.security.code.error.submit'));
      return;
    }

    try {
      setDisableButton(true);
      await onSubmit(setError, inputValue);
    } catch (e) {
      console.log(e);
      setDisableButton(false);
    } finally {
      setDisableButton(false);
    }
  };

  const handleResendEmail = async () => {
    await sendOtpToEmail()
      .then(() => {
        setShowTooltip(true);
        setTimeout(() => {
          setShowTooltip(false);
        }, 1000);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err.message);
      });
  };

  return (
    <SecurityCodeContainer>
      <h2 className="userSecurity__title">{`${title} 2FA`}</h2>
      <p className="userSecurity__subtitle">
        {title === 'Google'
          ? translate('popups.verify.security.code.title.google')
          : translate('popups.verify.security.code.title.email')}
      </p>
      <div className="userSecurity__inputContainer">
        <Input2FA allowedCharacters="numeric" onChange={handleChange} inputClassName="input-2fa-style" />
      </div>
      <div className="userSecurity__errorContainer">{errorMessage}</div>
      <ButtonSubmit
        handleButton={handleSubmit}
        variant={ButtonType.secondary}
        width="100%"
        title={translate('general.buttons.submit')}
        disabled={disableButton}
        color="#242c45"
        dependencies={inputValue}
      />
      {title === 'Google' ? (
        <p className="userSecurity__support">
          {translate('popups.verify.security.code.description')} <span>support@starbets.io</span>
        </p>
      ) : (
        <div className="userSecurity__resend-wrap">
          {translate('popusps.verify.security.code.tooltip.title')}
          <button type="submit" className="userSecurity__resend-wrap-btn" onClick={handleResendEmail}>
            {translate('popusps.verify.security.code.tooltip.text')}
          </button>
          <div className={!showTooltip ? 'link--tooltip' : 'link--tooltip open'}>
            {translate('popups.verify.security.code.footer.text')}
          </div>
        </div>
      )}
    </SecurityCodeContainer>
  );
};
export default VerifySecurityCode;
