import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { generate as id } from 'shortid';

import DiceGameContext from 'context/contextDiceGame/context';
import { DICE_BET_ANIMATION_DURATION } from 'components/constants/games';

import styles from './styles.module.scss';
import { DiceAnimationState } from './types';

const DiceGameLuckyNumber: React.FC = () => {
  const { animation, firstBet, history, onToggleAnimationInProgress } = useContext(DiceGameContext);

  const [luckyNumber, setLuckyNumber] = useState('00');
  const [animationState, setAnimationState] = useState<DiceAnimationState>(DiceAnimationState.stop);
  const [numbers, setNumbers] = useState<JSX.Element[]>([]);
  const [win, setWin] = useState(false);

  useEffect(() => {
    if (animation) {
      const randomNumbers = new Array(90).fill(undefined).map(() => Math.floor(Math.random() * (99 - 1)) + 1);
      const spinElements = randomNumbers.map((num) => (
        <span key={id()} className={styles.animation}>
          {num}
        </span>
      ));

      setNumbers(spinElements);
    } else {
      setNumbers([]);
    }
  }, [animation]);

  useEffect(() => {
    const lastBet = history[0];

    if (lastBet) {
      if (animation && !firstBet) {
        setAnimationState(DiceAnimationState.before);
        onToggleAnimationInProgress(true);

        setTimeout(() => {
          setAnimationState(DiceAnimationState.inProgress);
        }, 100);

        setTimeout(() => {
          setWin(lastBet.outcome === 'WIN');
          setAnimationState(DiceAnimationState.after);
          setLuckyNumber(String(lastBet.luckyNumber));
        }, DICE_BET_ANIMATION_DURATION - 200);

        setTimeout(() => {
          setAnimationState(DiceAnimationState.stop);
          onToggleAnimationInProgress(false);
        }, DICE_BET_ANIMATION_DURATION);
      } else {
        setWin(lastBet.outcome === 'WIN');
        setLuckyNumber(String(lastBet.luckyNumber));
      }
    }
  }, [history]);

  return (
    <div className={styles.wrapper}>
      {firstBet ? <span>00</span> : null}
      {!firstBet ? (
        <span
          className={clsx(
            win ? styles.win : styles.lose,
            animationState === DiceAnimationState.before ? styles.beforeAnimation : '',
            animationState === DiceAnimationState.inProgress ? styles.hidden : '',
            animationState === DiceAnimationState.after ? styles.afterAnimation : '',
            animationState === DiceAnimationState.stop ? styles.stop : '',
            !animation ? styles.withoutAnimation : '',
          )}
        >
          {luckyNumber}
        </span>
      ) : null}
      {animationState === DiceAnimationState.inProgress ? numbers : null}
    </div>
  );
};

export default DiceGameLuckyNumber;
