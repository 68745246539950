import { Helmet } from 'react-helmet';

import { IBlogMeta } from './types';

const BlogMeta: React.FC<IBlogMeta> = ({ article }) => {
  const {
    title,
    meta_title,
    meta_description,
    canonical_url,
    og_title,
    og_description,
    og_image,
    twitter_title,
    twitter_description,
    twitter_image,
    published_at,
    updated_at,
  } = article;

  return (
    <Helmet>
      <title>{meta_title || title || 'Starbets'}</title>
      <meta name="referrer" content="no-referrer-when-downgrade" />
      <meta property="og:site_name" content="StarBets" />
      <meta property="og:type" content="article" />
      <meta property="article:publisher" content="https://starbets.io/" />
      {meta_description ? <meta name="description" content={meta_description} /> : null}
      {canonical_url ? <link rel="canonical" href={canonical_url} /> : null}
      {og_title ? <meta property="og:title" content={og_title} /> : null}
      {og_description ? <meta property="og:description" content={og_description} /> : null}
      {og_image ? <meta property="og:image" content={og_image} /> : null}
      {published_at ? <meta property="article:published_time" content={published_at} /> : null}
      {updated_at ? <meta property="article:modified_time" content={updated_at} /> : null}
      {twitter_title ? <meta name="twitter:title" content={twitter_title} /> : null}
      {twitter_description ? <meta name="twitter:description" content={twitter_description} /> : null}
      {twitter_image ? <meta name="twitter:image" content={twitter_image} /> : null}
    </Helmet>
  );
};

export default BlogMeta;
