import { gql } from '@apollo/client';

export const DIVIDENDS_QUERIES_AUTH = gql`
  query allDividends {
    dividend {
      availableBalance
      token {
        tokenCode
        displayName
      }
    }
    totalDividendCollection {
      token {
        tokenCode
        displayName
      }
      totalCollected
    }
    starToken {
      maxSupply
      totalBurned
      totalMined
      totalStaked
    }
    miningStage {
      price
      rangeEnd
      rangeStart
    }
    tokenMiningPrices {
      miningPrice
      tokenCode
      displayName
    }
    dividendDistributions {
      amount
      token {
        tokenCode
        displayName
      }
      dividendBatch {
        distributedAt
        totalStaked
      }
    }
    dividendCollections {
      amount
      createdAt
      token {
        tokenCode
        displayName
      }
    }
  }
`;
