export enum VipBenefitsCategory {
  bonus = 'bonus',
  support = 'support',
  extra = 'extra',
}

export interface IVipBonusList {
  icon: string;
  title: string;
  description: string;
  action?: {
    link: string;
    title: string;
  };
}
