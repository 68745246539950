/* eslint-disable global-require */

import { Outlet } from 'react-router-dom';
import { Pagination, PostOrPage, Tag } from '@tryghost/content-api';
import { useEffect, useState } from 'react';

import { BlogSort } from 'components/constants/blog';
import { searchArticles, sortArticlesByPopular } from 'func/blog';

import BlogContext from './context';

const POSTS_PER_PAGE = 8;

const DEFAULT_PAGINATION = {
  page: 1,
  pages: 1,
  next: 1,
  prev: 1,
};

const BlogProvider: React.FC = () => {
  const [articles, setArticles] = useState<PostOrPage[]>([]);
  const [allArticles, setAllArticles] = useState<PostOrPage[]>([]);
  const [page, setPage] = useState(1);
  const [tag, setTag] = useState<Tag>();
  const [pagination, setPagination] = useState<Pagination>();
  const [searched, setSearched] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllArticles().then((a) => {
      setAllArticles(a);
    });
  }, []);

  useEffect(() => {
    setDefaultArticles(allArticles, page);
  }, [allArticles, page]);

  async function getAllArticles() {
    if (allArticles.length) {
      return allArticles;
    }

    setLoading(true);

    const url = `${window.location.origin}/blog-articles.json`;
    const posts = await fetch(url)
      .then(async (res) => res.json())
      .catch(() => [])
      .finally(() => {
        setLoading(false);
      });

    return posts;
  }

  function setDefaultArticles(allPosts: PostOrPage[], p: number) {
    const totalPages = Math.ceil(allPosts.length / POSTS_PER_PAGE);
    const skip = (p - 1) * POSTS_PER_PAGE;
    const offset = POSTS_PER_PAGE * p;

    const newArticles = allArticles.slice(skip, offset);

    setArticles(newArticles);
    setPagination({
      page: p,
      pages: totalPages,
      next: p + 1 > totalPages ? totalPages : p + 1,
      prev: p - 1 <= 0 ? 1 : p - 1,
      total: allPosts.length,
      limit: POSTS_PER_PAGE,
    });
  }

  const handleFilterArticlesByTag = (t: Tag) => {
    const articlesWithTag = allArticles.filter((a) => {
      const hasTag = a.tags?.find((at) => at.id === t.id);

      return hasTag;
    });

    setArticles(articlesWithTag);
    setPagination({
      ...DEFAULT_PAGINATION,
      limit: articlesWithTag.length,
      total: articlesWithTag.length,
    });
  };

  const handleSortPopularArticles = () => {
    const sortedPosts = sortArticlesByPopular(allArticles);

    setArticles(sortedPosts);
    setPagination({
      ...DEFAULT_PAGINATION,
      limit: sortedPosts.length,
      total: sortedPosts.length,
    });
  };

  const handleSearchArticles = (search: string) => {
    const filteredPosts = searchArticles(allArticles, search);

    setSearched(true);
    setArticles(filteredPosts);
    setPagination({
      ...DEFAULT_PAGINATION,
      limit: filteredPosts.length,
      total: filteredPosts.length,
    });
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleGetArticlesByTag = (t?: Tag) => {
    if (!t) {
      setDefaultArticles(allArticles, 1);
      setTag(t);
      return;
    }

    handleFilterArticlesByTag(t);
    setTag(t);
  };

  const handleChangeSort = async (sort: BlogSort) => {
    if (sort === BlogSort.date) {
      setDefaultArticles(allArticles, 1);
      return;
    }

    handleSortPopularArticles();
  };

  const handleSearch = async (search: string) => {
    if (!search) {
      setDefaultArticles(allArticles, 1);
      setSearched(false);
      return;
    }

    handleSearchArticles(search);
  };

  return (
    <BlogContext.Provider
      value={{
        articles,
        allArticles,
        pagination,
        tag,
        searched,
        loading,
        onChangePage: handleChangePage,
        onSearch: handleSearch,
        onGetArticlesByTag: handleGetArticlesByTag,
        onChangeSort: handleChangeSort,
      }}
    >
      <Outlet />
    </BlogContext.Provider>
  );
};

export default BlogProvider;
