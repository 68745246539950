import { MINES_CONDITION, SQUARE_CLASSES } from 'components/constants/constants';
import bombImage from 'assets/img/Mines/bomb-otoram.io.webp';
import diamondImage from 'assets/img/Mines/diamond.webp';
import equation from 'assets/img/Mines/quation.webp';
import { amount, BNMultiply, removeComas } from 'func/common';
import { bigOrEqual, lessThen } from 'func/prepareDataDice';
import { Token } from 'types/requestTypes';
import { IWinInfoMines } from 'context/contextMines/types';
import { HistoryElem, TranslationType } from 'types';

const preloadImagesArray = [bombImage, diamondImage, equation];

export interface SquareState {
  id: string;
  item: number;
  condition: string;
}

interface ResultMines {
  betId?: string;
  mines?: number[];
  multiplayer?: string;
  payout?: string;
  predictions?: number[];
}

export const detectSliderChange = (currentSlide: number, slideToShow: number): boolean => {
  const arrNum = [
    slideToShow,
    slideToShow * 2,
    slideToShow * 3,
    slideToShow * 4,
    slideToShow * 5,
    slideToShow * 6,
    slideToShow * 7,
    slideToShow * 8,
    slideToShow * 9,
    slideToShow * 10,
  ];
  return arrNum.includes(currentSlide);
};

export const getDefaultSquaresData = (): SquareState[] => {
  const result = new Array(25).fill(null).map((_, ind) => ({
    id: `elem${ind}`,
    item: ind,
    condition: SQUARE_CLASSES.default,
  }));
  return result;
};

export const getMinesDropData = (
  setter: (el: number) => void,
  t: TranslationType,
): Array<{ title: string; id: number; func: () => void }> => {
  const result = new Array(24).fill(null).map((_, ind) => ({
    title: `${t('mines.dropdown.text')} ${ind + 1}`,
    id: ind + 1,
    func: () => setter(ind + 1),
  }));
  return result;
};

export const disableButtonsMines = (state: string): boolean => {
  switch (state) {
    case MINES_CONDITION.notStarted:
      return false;
    case MINES_CONDITION.finished:
      return false;
    case MINES_CONDITION.autoNotStarted:
      return false;
    default:
      return true;
  }
};

export const disableSubmitButton = (state: string, hit: number, autoItems: number[]): boolean => {
  if (state === MINES_CONDITION.notStarted) return false;
  if (state === MINES_CONDITION.autoStarted) return false;
  if (state === MINES_CONDITION.selection) return true;
  if (state === MINES_CONDITION.finished) return false;
  if (state === MINES_CONDITION.autoNotStarted && autoItems.length < 1) return true;
  if (state === MINES_CONDITION.autoNotStarted && autoItems.length >= 1) return false;
  if (!hit) return true;
  return false;
};

export const getSquareImage = (str: string): string => {
  preloadImagesArray.forEach((img) => {
    const image = new Image();
    image.src = img;
  });
  switch (str) {
    case `${SQUARE_CLASSES.win} ${SQUARE_CLASSES.finished}`:
      return diamondImage;
    case SQUARE_CLASSES.win:
      return diamondImage;
    case `${SQUARE_CLASSES.lose} ${SQUARE_CLASSES.finished}`:
      return bombImage;
    case SQUARE_CLASSES.lose:
      return bombImage;
    case SQUARE_CLASSES.loseSingle:
      return bombImage;
    default:
      return equation;
  }
};

export const getSubmitTitle = (state: string, hit: number): boolean => {
  if (state === MINES_CONDITION.autoNotStarted) return false;
  if (state === MINES_CONDITION.autoStarted) return false;
  if (state === MINES_CONDITION.notStarted) return true;
  if (state === MINES_CONDITION.finished) return true;
  if (!hit) return true;
  return false;
};

export const getCollectResult = (
  state: { 1: number[] },
  minesSelected: number,
  hit: number,
  bet: string,
  maxPayout: string,
): number | string => {
  const multiplayer = state[minesSelected][hit - 1];
  const currentAmount = removeComas(bet);
  const result = BNMultiply(multiplayer, Number(currentAmount));
  return bigOrEqual(result, maxPayout || 0) ? amount(maxPayout) : amount(result);
};

export const setActiveMinesSquare = (state: SquareState[], itemNum: number): SquareState[] =>
  state.map((el) => {
    if (el.item === itemNum) {
      return { ...el, condition: SQUARE_CLASSES.active };
    }
    return el;
  });

export const setWinMinesSquare = (state: SquareState[], itemNum: number): SquareState[] =>
  state.map((el) => {
    if (el.item === itemNum) {
      return { ...el, condition: SQUARE_CLASSES.win };
    }
    return el;
  });

export const setLoseMinesSquares = (state: SquareState[], itemNum: number, result: ResultMines): SquareState[] =>
  state.map((el) => {
    if (el.item === itemNum) {
      return { ...el, condition: SQUARE_CLASSES.loseSingle };
    }
    if (result.mines.includes(el.item) && el.item !== itemNum) {
      return { ...el, condition: `${SQUARE_CLASSES.lose} ${SQUARE_CLASSES.finished}` };
    }
    if (result.predictions.includes(el.item)) {
      return { ...el, condition: SQUARE_CLASSES.win };
    }
    return { ...el, condition: `${SQUARE_CLASSES.win} ${SQUARE_CLASSES.finished}` };
  });

export const setCollectMinesSquares = (state: SquareState[], result: ResultMines): SquareState[] =>
  state.map((el) => {
    if (result.mines.includes(el.item)) {
      return { ...el, condition: `${SQUARE_CLASSES.lose} ${SQUARE_CLASSES.finished}` };
    }
    if (result.predictions.includes(el.item)) {
      return { ...el, condition: SQUARE_CLASSES.win };
    }
    return { ...el, condition: `${SQUARE_CLASSES.win} ${SQUARE_CLASSES.finished}` };
  });

export const setSessionMinesSquare = (state: SquareState[], prediction: number[]): SquareState[] =>
  state.map((el) => {
    if (prediction.includes(el.item)) {
      return { ...el, condition: SQUARE_CLASSES.win };
    }
    return { ...el, condition: SQUARE_CLASSES.default };
  });

export const setWinGameMinesSquare = (state: SquareState[], itemNum: number, result: ResultMines): SquareState[] =>
  state.map((el) => {
    if (el.item === itemNum) {
      return { ...el, condition: SQUARE_CLASSES.win };
    }
    if (result.predictions.includes(el.item)) {
      return el;
    }
    return { ...el, condition: `${SQUARE_CLASSES.lose} ${SQUARE_CLASSES.finished}` };
  });

export const getAllHistory = (
  state: Array<{
    multiplier: string;
    payout: string;
    createdAt: string;
    id: string;
    amount: string;
    isAuto: boolean;
    tokenCode: string;
    displayName: string;
    isBlack: boolean;
  }>,
): Array<{ num: string; result: string }> =>
  state.map((el) => ({
    num: lessThen(el.payout, '0.00000001') ? '0x' : `${el.multiplier}x`,
    result: Number(el.payout) > 0 ? 'win' : 'lose',
    createdAt: el.createdAt,
    id: el.id,
    amount: el.amount,
    isAuto: el.isAuto,
    multiplier: lessThen(el.payout, '0.00000001') ? '0x' : `${el.multiplier}x`,
    payout: el.payout,
    tokenCode: el.tokenCode,
    displayName: el.displayName,
    betId: el.id,
  }));

export const setAllHistory = (
  state: HistoryElem[],
  result: HistoryElem,
  token: Token,
  betAmountValue: string,
): HistoryElem[] => {
  const date = new Date();
  const isBlack = !state[0]?.isBlack;
  const object = {
    num: lessThen(result.payout, '0.00000001') ? '0x' : `${result.multiplier}x`,
    result: Number(result.payout) > 0 ? 'win' : 'lose',
    createdAt: date,
    id: result.betId,
    amount: betAmountValue,
    multiplier: lessThen(result.payout, '0.00000001') ? '0x' : `${result.multiplier}x`,
    payout: result.payout,
    displayName: token.name,
    isBlack,
  };
  return [object, ...state.slice(0, 30)];
};

export const setPopUpMinesSquares = (
  state: SquareState[],
  predictions: number[],
  res: { outcome: string; sequence: number[] },
): SquareState[] => {
  const isLoss = res.outcome === 'LOSS';
  let result = [];
  if (!isLoss) {
    result = state.map((el) => {
      if (res.sequence.includes(el.item)) {
        return { ...el, condition: `${SQUARE_CLASSES.lose} ${SQUARE_CLASSES.finished}` };
      }
      if (predictions.includes(el.item)) {
        return { ...el, condition: SQUARE_CLASSES.win };
      }
      return { ...el, condition: `${SQUARE_CLASSES.win} ${SQUARE_CLASSES.finished}` };
    });
  } else {
    const winArr = [...predictions];
    const lossSquare = winArr.pop();
    result = state.map((el) => {
      if (lossSquare === el.item) {
        return { ...el, condition: SQUARE_CLASSES.loseSingle };
      }
      if (res.sequence.includes(el.item) && el.item !== lossSquare) {
        return { ...el, condition: `${SQUARE_CLASSES.lose} ${SQUARE_CLASSES.finished}` };
      }
      if (winArr.includes(el.item)) {
        return { ...el, condition: SQUARE_CLASSES.win };
      }
      return { ...el, condition: `${SQUARE_CLASSES.win} ${SQUARE_CLASSES.finished}` };
    });
  }
  return result;
};

export const setPopUpMinesAuto = (state: SquareState[], predictions: number[], mines: number[]): SquareState[] =>
  state.map((el) => {
    if (predictions.includes(el.item) && mines.includes(el.item)) {
      return { ...el, condition: SQUARE_CLASSES.loseSingle };
    }
    if (mines.includes(el.item)) {
      return { ...el, condition: `${SQUARE_CLASSES.lose} ${SQUARE_CLASSES.finished}` };
    }
    if (predictions.includes(el.item)) {
      return { ...el, condition: SQUARE_CLASSES.win };
    }
    return { ...el, condition: `${SQUARE_CLASSES.win} ${SQUARE_CLASSES.finished}` };
  });

export const setAutoSelectMineSquare = (state: SquareState[], itemNum: number): SquareState[] =>
  state.map((el) => {
    if (el.item === itemNum) {
      return {
        ...el,
        condition:
          el.condition === SQUARE_CLASSES.autoSelection ? SQUARE_CLASSES.default : SQUARE_CLASSES.autoSelection,
      };
    }
    return el;
  });

export const getIsAutoPick = (state: string): boolean => {
  if (state === MINES_CONDITION.autoNotStarted) return true;
  return false;
};

export const getIsDisabledSquareItem = (
  state: string,
  classCondition: string,
  picked: number[],
  minesSelected: number,
): boolean => {
  if (state === MINES_CONDITION.autoStarted) {
    if (picked.length >= minesSelected) {
      return true;
    }
    return true;
  }
  if (state === MINES_CONDITION.autoNotStarted) {
    if (25 - picked.length <= minesSelected && classCondition === SQUARE_CLASSES.default) {
      return true;
    }
  }
  if (state === MINES_CONDITION.notStarted) {
    return true;
  }
  if (classCondition === SQUARE_CLASSES.autoSelection) {
    return false;
  }
  if (classCondition !== SQUARE_CLASSES.default) {
    return true;
  }
  return false;
};

export const checkAutoPick = (state: SquareState[], item: number, mines: number, pickState: number[]): boolean => {
  const current = state.find((el) => el.item === item);
  if (current.condition === SQUARE_CLASSES.default && pickState.length >= 25 - mines) {
    return true;
  }
  return false;
};

export const onAddAutoPickItem = (state: number[], item: number): number[] => {
  if (state.includes(item)) {
    const result = [...state];
    const ind = result.indexOf(item);
    result.splice(ind, 1);
    return result;
  }
  return [...state, item];
};

export const onChangeMineSetItems = (state: number[], mines: number): number[] => {
  const arrReturn = [...state];
  const result = arrReturn.slice(0, 25 - mines);
  return result;
};

export const onChangeMineSetSquare = (squares: SquareState[], mines: number, state: number[]): SquareState[] => {
  const arrReturn = [...state];
  const result = arrReturn.slice(0, 25 - mines);
  return squares.map((el) => {
    if (result.includes(el.item)) {
      return { ...el, condition: SQUARE_CLASSES.autoSelection };
    }
    return { ...el, condition: SQUARE_CLASSES.default };
  });
};

export const getDisableCheckbox = (state: string): boolean => {
  if (state === MINES_CONDITION.autoNotStarted) return false;
  if (state === MINES_CONDITION.notStarted) return false;
  if (state === MINES_CONDITION.finished) return false;
  return true;
};

export const getAutoSquareResult = (state: SquareState[], mines: number[], pickState: number[]): SquareState[] =>
  state.map((el) => {
    if (mines.includes(el.item)) {
      if (pickState.includes(el.item)) {
        return { ...el, condition: `${SQUARE_CLASSES.loseSingle}` };
      }
      return { ...el, condition: `${SQUARE_CLASSES.lose} ${SQUARE_CLASSES.finished}` };
    }
    if (pickState.includes(el.item)) {
      return { ...el, condition: `${SQUARE_CLASSES.win}` };
    }

    return { ...el, condition: `${SQUARE_CLASSES.win} ${SQUARE_CLASSES.finished}` };
  });

export const getAutoSquareOnEndAnimation = (state: SquareState[], pickState: number[]): SquareState[] =>
  state.map((el) => {
    if (pickState.includes(el.item)) {
      return { ...el, condition: SQUARE_CLASSES.autoSelection };
    }

    return { ...el, condition: SQUARE_CLASSES.default };
  });

export const getWinInfo = (payout: string, multiplier: string, maxPay: string): IWinInfoMines => ({
  amount: String(amount(bigOrEqual(payout, maxPay) ? maxPay : payout)),
  multiplier,
  bigWin: bigOrEqual(multiplier, 10),
});
