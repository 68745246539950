import MiningPriceTokenDetails from './components/MiningPriceTokenDeatails';
import StarPool from './components/StarPool';
import styles from './styles.module.scss';
import { StyledThirdBlock } from './style';

const CardBlockThird: React.FC = () => (
  <StyledThirdBlock className={styles.dividendsCardsBlockThird}>
    <MiningPriceTokenDetails />
    <StarPool />
  </StyledThirdBlock>
);

export default CardBlockThird;
