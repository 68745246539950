import { pathOr } from 'ramda';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import * as yup from 'yup';

import { ContextPopUps, ContextTokenCode } from 'context';
import { ETokenCode, popUps } from 'components/constants/constants';
import { getImgSrc } from 'func/common';
import { filterNetworksAddressBook, filterNetworksDeposit } from 'func/prepareDataPopUps';
import { ADDRESS_BOOK_RECORD, UPDATE_ADDRESS_BOOK_RECORD } from 'graphQl/mutations/withdraw/withdraw';
import { USER_SECURITY } from 'graphQl/query/auth/auth';
import { GET_USER } from 'graphQl/query/auth/profile';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';
import { NETWORKS_WITH_MEMO } from 'components/constants/withdraw';
import { GET_ADDRESS_BOOK } from 'graphQl/query/withdraw/withdraw';

import ButtonSubmit from 'components/Base/PopUps/components/ButtonSubmit';
import InputCustom from 'components/Base/Input/InputCustom';
import DropDown from 'components/Base/DropDown';
import Checkbox from 'components/Base/CheckBox';

import SaveWalletAddressFormStyle from './stylex';
import { ISaveWalletAddressForm } from './types';
import { getMemoValidationSchema } from 'func/withdraw';

const WAValidator = window.WAValidator;

const SaveWalletAddressForm: React.FC<ISaveWalletAddressForm> = ({
  data,
  selectedNetwork,
  setSelectedNetwork,
  setSelectedToken,
  selectedToken,
  setActivePopUp,
}) => {
  const { translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { tokenWithNetworksList } = useContext(ContextTokenCode);

  const [disableButton, setDisableButton] = useState(false);
  const [addressName, setAddressName] = useState(data?.name || '');
  const [addressValue, setAddressValue] = useState(data?.address || '');
  const [dropdownData, setDropdownData] = useState(null);
  const [saveTokenCode, setSaveTokenCode] = useState(data?.tokenCode || selectedToken.token);
  const [errorValid, setErrorValid] = useState(null);
  const [checkboxValue, setCheckboxValue] = useState(data?.address ? data?.isWhitelisted : false);
  const [networkDropdown, setNetworkDropdown] = useState(null);
  const [networkLocal, setNetworkLocal] = useState(data?.address ? data?.selectedNetwork : selectedNetwork);
  const [memoPhrase, setMemoPhrase] = useState(data?.memo);
  const [withMemo, setWithMemo] = useState(false);
  const [memoValidationSchema, setMemoValidationSchema] = useState<yup.AnySchema>();

  const { data: userSecurity } = useQuery(USER_SECURITY, { fetchPolicy: 'cache-and-network' });

  const { data: user } = useQuery(GET_USER, { fetchPolicy: 'cache-only' });

  const [addAddressBookRecord] = useMutation(ADDRESS_BOOK_RECORD, { refetchQueries: [GET_ADDRESS_BOOK] });
  const [updateWalletAddress] = useMutation(UPDATE_ADDRESS_BOOK_RECORD, { refetchQueries: [GET_ADDRESS_BOOK] });

  const handleAddressName = (event) => {
    setErrorValid('');
    setAddressName(event.target.value);
  };

  const handleAddressValue = (event) => {
    setErrorValid('');
    setAddressValue(event.target.value);
  };

  const handleMemoPhrase = (phrase) => {
    try {
      const isValid = !phrase || !memoValidationSchema || memoValidationSchema.validateSync(phrase);

      if (isValid) {
        setErrorValid('');
        setMemoPhrase(phrase);
      }
    } catch {
      // pass
    }
  };

  const handleCheckbox = () => setCheckboxValue(!checkboxValue);

  const validateWalletAddress = () => {
    const tokenValidation = networkLocal === ETokenCode.BSC ? ETokenCode.BNB : networkLocal;

    if (!addressName) {
      const errorMessage = translate('popups.wallet.addressBook.edit.errors.name');

      setErrorValid(errorMessage);
      return true;
    }

    if (!networkLocal) {
      const errorMessage = translate('popups.wallet.addressBook.edit.errors.network');

      setErrorValid(errorMessage);
      return true;
    }

    if (!WAValidator.validate(addressValue, tokenValidation, 'both') || !addressValue) {
      const errorMessage = translate('popups.wallet.addressBook.edit.errors.address');

      setErrorValid(errorMessage);
      return true;
    }

    setErrorValid(null);
    return false;
  };

  const handleSaveOnTwoFa = async (errorCodeSetter, code) => {
    try {
      await addAddressBookRecord({
        variables: {
          formData: {
            name: addressName,
            address: addressValue,
            isWhitelisted: checkboxValue,
            tokenCode: saveTokenCode,
            networkCode: networkLocal,
            memo: memoPhrase,
          },
          code,
        },
      });
      setPopUpsOpen({
        modalOpen: popUps.walletNavigation,
        data: {
          active: popUps.addressBook,
          config: popUps.deposit,
          token: selectedToken,
          network: selectedNetwork,
        },
      });
    } catch (errorMess) {
      errorCodeSetter(errorMess);
    }
  };

  const handleUpdateOnTwoFA = async (errorCodeSetter, code) => {
    try {
      await updateWalletAddress({
        variables: {
          formData: {
            isWhitelisted: checkboxValue,
            id: data?.walletId,
            address: addressValue,
            name: addressName,
            tokenCode: saveTokenCode,
            networkCode: networkLocal,
            memo: memoPhrase,
          },
          code,
        },
      });
      setPopUpsOpen({
        modalOpen: popUps.walletNavigation,
        data: {
          config: popUps.deposit,
          active: popUps.addressBook,
          token: selectedToken,
          network: selectedNetwork,
        },
      });
    } catch (errorMess) {
      errorCodeSetter(errorMess);
    }
  };

  const handleUpdateWallet = async () => {
    if (validateWalletAddress()) return;
    setDisableButton(true);

    const security = pathOr<Record<string, boolean>>({}, ['userSecurity'], userSecurity);
    const { email_2fa, google_2fa } = security;

    const mfaDisabled = !email_2fa && !google_2fa;

    if (checkboxValue && !mfaDisabled) {
      setPopUpsOpen({
        modalOpen: popUps.verifySecurityCode,
        data: {
          onSubmit: handleUpdateOnTwoFA,
          autoSendOtp: true,
          title: google_2fa ? 'Google' : 'Email',
          email: user?.profile?.email,
        },
      });
      return;
    }

    await updateWalletAddress({
      variables: {
        formData: {
          isWhitelisted: checkboxValue,
          id: data?.walletId,
          address: addressValue,
          name: addressName,
          tokenCode: saveTokenCode,
          networkCode: networkLocal,
          memo: memoPhrase,
        },
      },
    });
    setActivePopUp(popUps.addressBook);
  };

  const handleSubmit = async () => {
    if (validateWalletAddress()) return;
    setDisableButton(true);

    const security = pathOr<Record<string, boolean>>({}, ['userSecurity'], userSecurity);
    const { email_2fa, google_2fa } = security;

    const mfaDisabled = !email_2fa && !google_2fa;

    if (checkboxValue && !mfaDisabled) {
      setPopUpsOpen({
        modalOpen: popUps.verifySecurityCode,
        data: {
          onSubmit: handleSaveOnTwoFa,
          autoSendOtp: true,
          title: google_2fa ? 'Google' : 'Email',
          email: user?.profile?.email,
        },
      });

      return;
    }

    await addAddressBookRecord({
      variables: {
        formData: {
          name: addressName,
          address: addressValue,
          isWhitelisted: checkboxValue,
          tokenCode: saveTokenCode,
          networkCode: networkLocal,
          memo: memoPhrase,
        },
      },
    });
    setActivePopUp(popUps.addressBook);
  };

  useEffect(() => {
    const newWithMemo = NETWORKS_WITH_MEMO.includes(selectedNetwork);

    if (newWithMemo) {
      const newMemoValidationSchema = getMemoValidationSchema(selectedNetwork);

      setMemoValidationSchema(newMemoValidationSchema || undefined);
    } else {
      setMemoValidationSchema(undefined);
    }

    setWithMemo(newWithMemo);
  }, [selectedNetwork]);

  useEffect(() => {
    if (tokenWithNetworksList) {
      const result = tokenWithNetworksList.tokenWithNetworks.filter((elem) => elem.tokenCode !== 'STAR');
      const prepareData = result.map((elem) => ({
        title: elem.displayName,
        img: getImgSrc(elem.displayName),
        tokenCode: elem.tokenCode,
        networks: elem.networks,
        func: () => {
          filterNetworksDeposit(
            elem.networks,
            tokenWithNetworksList.networks,
            setNetworkDropdown,
            setNetworkLocal,
            setErrorValid,
          );
          setSaveTokenCode(elem.tokenCode);
          setErrorValid('');
        },
      }));
      const firstElem = prepareData.find((elem) => elem.tokenCode === saveTokenCode);
      const properData = [firstElem, ...prepareData.filter((elem) => elem.tokenCode !== saveTokenCode)];
      setDropdownData(properData);
      filterNetworksDeposit(firstElem.networks, tokenWithNetworksList.networks, setNetworkDropdown, setNetworkLocal);
      if (data?.address || networkLocal) {
        const res = filterNetworksAddressBook(
          firstElem.networks,
          tokenWithNetworksList.networks,
          networkLocal,
          setNetworkLocal,
        );
        setNetworkDropdown(res);
        setNetworkLocal(networkLocal);
      }
    }
  }, [tokenWithNetworksList]);

  useEffect(() => {
    if (saveTokenCode) {
      setSelectedToken({
        token: saveTokenCode,
        name: saveTokenCode === ETokenCode.BSC ? ETokenCode.BNB : saveTokenCode,
      });
    }
    if (networkLocal) {
      setSelectedNetwork(networkLocal);
    }
  }, [networkLocal, saveTokenCode]);

  if (!dropdownData) return null;

  return (
    <SaveWalletAddressFormStyle>
      <div className="save__title">
        {data?.address
          ? translate('popups.wallet.addressBook.edit.edit')
          : translate('popups.wallet.addressBook.edit.new')}
      </div>
      <div className="save__item">
        <h6>{translate('popups.wallet.addressBook.edit.currency')}:</h6>
        <DropDown data={dropdownData} width="100%" textPadding="50px" />
      </div>
      <div className="save__item">
        <h6>{translate('popups.wallet.addressBook.edit.network')}:</h6>
        {networkDropdown && <DropDown data={networkDropdown} width="100%" textPadding="20px" />}
      </div>
      <div className="save__item">
        <h6>{translate('popups.wallet.addressBook.edit.name')}:</h6>
        <InputCustom
          id="addressRoute"
          name="addressRoute"
          type="text"
          onChange={handleAddressName}
          value={addressName}
          inputwidth="100%"
        />
      </div>
      <div className="save__item">
        <h6>{translate('popups.wallet.addressBook.edit.address')}:</h6>
        <InputCustom
          id="address"
          name="address"
          type="text"
          onChange={handleAddressValue}
          value={addressValue}
          inputwidth="100%"
        />
      </div>
      {withMemo ? (
        <div className="save__item">
          <h6>{translate('popups.wallet.addressBook.edit.memo')}:</h6>
          <InputCustom
            id="memoPhrase"
            name="memoPhrase"
            type="text"
            inputwidth="100%"
            value={memoPhrase}
            onChange={(ev) => handleMemoPhrase(ev.target.value)}
          />
        </div>
      ) : null}
      <div className="save__checkbox">
        <Checkbox id="twoFa" name="twoFa" checked={checkboxValue} onChange={handleCheckbox} border config="purple" />
        <p>{translate('popups.wallet.addressBook.edit.whitelist')}</p>
      </div>
      <h4>{translate('popups.wallet.addressBook.edit.checkbox')}</h4>
      <div className="save__error">{errorValid}</div>
      <ButtonSubmit
        className="save__button"
        handleButton={data?.address ? handleUpdateWallet : handleSubmit}
        variant={ButtonType.primary}
        width="288px"
        title={translate('general.buttons.continue')}
        disabled={disableButton}
      />
    </SaveWalletAddressFormStyle>
  );
};

export default SaveWalletAddressForm;
