import clsx from 'clsx';

import { ReactComponent as ArrowIcon } from 'assets/img/common/arrow.svg';

import styles from './styles.module.scss';
import { IGamesSlideButtonNext } from './types';

const GamesSlideButtonNext: React.FC<IGamesSlideButtonNext> = ({ hasNext, onNext }) => {
  return (
    <button className={clsx(styles.wrapper, styles.next)} disabled={!hasNext} onClick={onNext}>
      <ArrowIcon className={styles.icon} />
    </button>
  );
};

export default GamesSlideButtonNext;
