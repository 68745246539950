import React, { useState } from 'react';

import EstimatedFrontCard from './components/EstimateFrontCard';
import EstimateBackCard from './components/EstimateBackCard';
import { IEstimateDividends } from './types';
import EstimatedStyle from './styles';

const EstimatedDividends: React.FC<IEstimateDividends> = ({ percent }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <EstimatedStyle isflipped={isFlipped}>
      <div id="estimated" className={isFlipped ? 'flipped' : ''}>
        <div className="side front">
          <EstimatedFrontCard percent={percent} setIsFlipped={setIsFlipped} isFlipped={isFlipped} />
        </div>
        <div className="side back">
          <EstimateBackCard setIsFlipped={setIsFlipped} isFlipped={isFlipped} />
        </div>
      </div>
    </EstimatedStyle>
  );
};

export default EstimatedDividends;
