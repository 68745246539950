import { gql } from '@apollo/client';

export const GET_LOTTERY_TICKETS = gql`
  query myLotteryTickets {
    myLotteryTickets {
      id
      lotteryRoundId
      numberOfTickets
    }
  }
`;

export const GET_LOTTERY_HISTORY = gql`
  query lotteryRounds {
    lotteryRounds {
      displayName
      endAt
      id
      increment
      lotteryHistory {
        id
        lotteryRoundId
        place
        prize
        user {
          alias
          avatar {
            avatar
          }
          rank {
            id
          }
        }
      }
    }
  }
`;

export const GET_LOTTERY_ROUND_INFO = gql`
  query currentLotteryRound {
    currentLotteryRound {
      id
      prize
      prizeTokenCode
      numberOfTickets
      rewardCode
      ticketPrice
      startAt
      endAt
      displayName
    }
  }
`;
