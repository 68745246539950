import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';
import rankBg from 'assets/img/ranks/rank-bg.webp';

const RankSystemSliderStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;

  font-family: 'Montserrat';

  .slick-slider .slick-list {
    width: 100%;
    height: 100%;
  }
  .slick-track {
    height: 100%;
    div {
      div {
        height: 100%;
      }
    }
  }

  .slick-dots {
    bottom: 25px !important;
  }

  .slick-dots > li > button {
    cursor: url(${point}), auto !important;
    width: 10px !important;
    height: 10px !important;
  }

  .slick-dots > li > button::before {
    font-size: 8px !important;
    color: #ffffff !important;
    cursor: url(${point}), auto !important;
  }

  .slick-dots > li.slick-active > button:before {
    cursor: url(${point}), auto !important;
  }

  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    width: 40px !important;
    height: 40px !important;
    cursor: url(${point}), auto !important;
    color: #ffffff !important;
    border: none;
    outline: 0;
    background: rgba(255, 255, 255, 0.24) !important;
    border-radius: 8px !important;
  }

  .slick-next {
    top: 90% !important;
    background: transparent !important;
    right: 25px !important;
  }

  .slick-prev {
    top: 90% !important;
    background: transparent !important;
    left: 25px !important;
    z-index: 2 !important;
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: 1;
    color: red !important;
  }
  .slick-next:before {
    content: '' !important;
    border: 1px solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    height: 0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-right: 5px;
  }
  .slick-prev:before {
    content: '' !important;
    border: 1px solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    height: 0;
    transform: rotate(-225deg);
    -webkit-transform: rotate(-225deg);
    margin-left: 5px;
  }

  .rank__slider {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .rank__item {
    display: flex !important;
    width: 100%;
    height: 100%;
    background-image: url(${rankBg});
    background-size: cover;
    background-repeat: no-repeat;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 35px;
    position: relative;
  }

  .rank__item-height {
    width: 0;
    height: 260px;
  }

  img[class^='rank__item--img'] {
    display: inline-block;
    filter: drop-shadow(0 0 35px #86115f);
  }

  .rank__item--img0 {
    height: 124px;
    margin-top: 46px;
  }
  .rank__item--img1 {
    height: 154px;
    margin-top: 25px;
  }
  .rank__item--img2 {
    height: 150px;
    margin-top: 29px;
  }
  .rank__item--img3 {
    height: 150px;
    margin-top: 29px;
  }
  .rank__item--img4 {
    height: 154px;
    margin-top: 25px;
  }
  .rank__item--img5 {
    height: 186px;
    margin-top: 12px;
  }
  .rank__item--img6 {
    height: 153px;
    margin-top: 28px;
  }
  .rank__item--img7 {
    height: 186px;
    margin-top: 9px;
  }
  .rank__item--img8 {
    height: 169px;
    margin-top: 19px;
  }
  .rank__item--img9 {
    height: 169px;
    margin-top: 20px;
  }
  .rank__item--img10 {
    height: 185px;
    margin-top: 10px;
  }
  .rank__item--img11 {
    height: 185px;
    margin-top: 10px;
  }
  .rank__item--img12 {
    height: 216px;
    margin-top: 2px;
  }
  .rank__item--img13 {
    height: 228px;
  }
  .rank__item--img14 {
    height: 228px;
  }
  .rank__item--img15 {
    height: 228px;
  }
  .rank__item--img16 {
    height: 256px;
  }

  .rank__item--status {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 15px;
    h2 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #fccd00;
      margin: 0;
      padding: 5px 7px;
      background: linear-gradient(180deg, rgba(214, 145, 21, 0.4) 0%, rgba(214, 145, 21, 0.16) 100%), #424862;
      border-radius: 4px;
    }
    h3 {
      margin: 0 0 0 3px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #795620;
      padding: 5px 7px;
      background: linear-gradient(90deg, #ffffb2 0%, #ebbb2e 100%), #424862;
      border-radius: 4px;
    }
  }

  @media (max-width: 1390px) {
  }
  @media (max-width: 1280px) {
    .slick-next {
      top: 95% !important;
      right: 25px !important;
    }

    .slick-prev {
      top: 95% !important;
      left: 25px !important;
    }
    .rank__item--status {
      padding-bottom: 0;
    }
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1150px) {
  }
  @media (max-width: 1080px) {
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 952px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 840px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 700px) {
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 616px) {
  }
  @media (max-width: 580px) {
    width: 100%;
    margin-bottom: 30px;
  }
  @media (max-width: 530px) {
  }
  @media (max-width: 500px) {
  }
`;

export default RankSystemSliderStyles;
