import Big from 'big.js';

import firstMission from 'assets/img/Missions/Stages/EmptyStages/1.0.webp';
import stars10 from 'assets/img/Missions/Stars/1.webp';
import stars20 from 'assets/img/Missions/Stars/2.webp';
import stars30 from 'assets/img/Missions/Stars/3.webp';

import { GameCategory, IGame, IMission, IMissionProgress, MissionStars } from 'types';
import {
  MISSION_IMAGES_WITH_PROGRESS_BY_POSTION,
  MISSION_IMAGE_BY_POSITION,
  MISSION_POPUPS_IMAGES_WITH_PROGRESS,
  PERCENTAGE_OF_COMPLETED_MISSION,
  PERCENTAGE_OF_HALF_COMPLETED_MISSION,
} from 'components/constants/missions';

export interface IMissionMultiplier {
  multiplier: string;
  image: string;
}

export interface IMissionFormattedProgress {
  betAmount: string;
  progress: string;
}

const getStarProgressInPercentage = (numOfBets: number, progress = '0') => {
  return Big(progress).div(numOfBets).mul(100).toFixed(2);
};

const getImageWithProgressByPostion = (star: MissionStars, progress: string, position: number) => {
  if (Big(progress).lt(PERCENTAGE_OF_HALF_COMPLETED_MISSION)) {
    return MISSION_IMAGES_WITH_PROGRESS_BY_POSTION[position][star][0];
  }

  if (Big(progress).gte(PERCENTAGE_OF_COMPLETED_MISSION)) {
    return MISSION_IMAGES_WITH_PROGRESS_BY_POSTION[position][star][2];
  }

  return MISSION_IMAGES_WITH_PROGRESS_BY_POSTION[position][star][1];
};

export const getMissionImageByProgress = (mission: IMission, position: number, progress?: IMissionProgress): string => {
  const { num_bets: numberOfBets } = mission;

  const oneStarProgress = getStarProgressInPercentage(numberOfBets, progress?.oneStarProgress);
  const twoStarProgress = getStarProgressInPercentage(numberOfBets, progress?.twoStarProgress);
  const threeStarProgress = getStarProgressInPercentage(numberOfBets, progress.threeStarProgress);

  if (!Big(oneStarProgress).gte(PERCENTAGE_OF_COMPLETED_MISSION)) {
    return getImageWithProgressByPostion('one', oneStarProgress, position);
  }

  if (!Big(twoStarProgress).gte(PERCENTAGE_OF_COMPLETED_MISSION)) {
    return getImageWithProgressByPostion('two', twoStarProgress, position);
  }

  return getImageWithProgressByPostion('three', threeStarProgress, position);
};

const getPopUpImageWithProgress = (star: MissionStars, progress: string) => {
  if (Big(progress).lt(PERCENTAGE_OF_HALF_COMPLETED_MISSION)) {
    return MISSION_POPUPS_IMAGES_WITH_PROGRESS[star][0];
  }

  if (Big(progress).gte(PERCENTAGE_OF_COMPLETED_MISSION)) {
    return MISSION_POPUPS_IMAGES_WITH_PROGRESS[star][2];
  }

  return MISSION_POPUPS_IMAGES_WITH_PROGRESS[star][1];
};

export const getMissionPopUpImageByProgress = (mission: IMission, progress?: IMissionProgress): string => {
  const { num_bets: numberOfBets } = mission;

  const oneStarProgress = getStarProgressInPercentage(numberOfBets, progress?.oneStarProgress);
  const twoStarProgress = getStarProgressInPercentage(numberOfBets, progress?.twoStarProgress);
  const threeStarProgress = getStarProgressInPercentage(numberOfBets, progress?.threeStarProgress);

  if (!Big(oneStarProgress).gte(PERCENTAGE_OF_COMPLETED_MISSION)) {
    return getPopUpImageWithProgress('one', oneStarProgress);
  }

  if (!Big(twoStarProgress).gte(PERCENTAGE_OF_COMPLETED_MISSION)) {
    return getPopUpImageWithProgress('two', twoStarProgress);
  }

  return getPopUpImageWithProgress('three', threeStarProgress);
};

export const getMissionImageByPosition = (position: number): string => {
  const imageByPosition = MISSION_IMAGE_BY_POSITION[position - 1];

  return imageByPosition || firstMission;
};

export const getMissionMultipliersWithImage = (mission: IMission): IMissionMultiplier[] => {
  return [
    { multiplier: `x${mission.oneStarMultiplier}`, image: stars10 },
    { multiplier: `x${mission.twoStarMultiplier}`, image: stars20 },
    { multiplier: `x${mission.threeStarMultiplier}`, image: stars30 },
  ];
};

export const getMissionProgress = (mission: IMission, progress?: IMissionProgress): IMissionFormattedProgress[] => {
  return [
    { betAmount: mission.oneStarMinBet, progress: progress?.oneStarProgress || '0' },
    { betAmount: mission.twoStarMinBet, progress: progress?.twoStarProgress || '0' },
    { betAmount: mission.threeStarMinBet, progress: progress?.threeStarProgress || '0' },
  ];
};

export const getFormattedGameName = (game?: IGame | null, gameCategory?: string | null): string => {
  return game?.name || gameCategory || GameCategory.InHouse;
};

export const getMissionGameLink = (game?: IGame | null, gameCategory?: string | null): string => {
  if (game) {
    if (game.category === GameCategory.InHouse) {
      return `/in-house/${game.slug}`;
    }

    return `/slots/${game.slug}`;
  }

  return `/games?category=${gameCategory?.toLowerCase()?.replace(/ /gm, '-')}`;
};

export const checkIfAllMissionsCompleted = (missions: IMission[], missionsPorgress: IMissionProgress[]) => {
  return missions.every((m) => {
    const userMission = missionsPorgress.find((mp) => mp.missionId === m.id);

    if (!userMission) return false;

    const { num_bets: numberOfBets } = m;
    const { oneStarProgress, twoStarProgress, threeStarProgress } = userMission;

    const progress = [oneStarProgress, twoStarProgress, threeStarProgress];

    return progress.every((p) => Big(p).gte(numberOfBets));
  });
};
