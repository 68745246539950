import activeSearchIcon from 'assets/img/GamesFiler/active-search.webp';

import { handleSelectInputValue } from 'func/common';
import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';
import { IGamesNavigationInput } from './types';

const GamesNavigationInput: React.FC<IGamesNavigationInput> = ({ inputValue, onSearch }) => {
  const { translate } = useLocalization();

  return (
    <div className={styles.gamesNavInput}>
      <input
        type="text"
        value={inputValue}
        onChange={onSearch}
        className={styles.gamesNavInputArea}
        placeholder={translate('games.nav.search')}
        onFocus={handleSelectInputValue}
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      />
      <img src={activeSearchIcon} alt="icon" className={styles.searchIcon} />
    </div>
  );
};

export default GamesNavigationInput;
