import React from 'react';

import TournamentProvider from 'context/contextTournaments';

import TournamentsContent from './components/TournamentsContent';

const Tournaments: React.FC = () => {
  return (
    <TournamentProvider>
      <TournamentsContent />
    </TournamentProvider>
  );
};

export default Tournaments;
