import { useLocalization } from 'components/Internationalization';
import { TEXAS_HOLDEM_POKER_HANDS } from 'components/constants/texasHoldem';

import styles from './styles.module.scss';

const TexasHoldemPokerHands: React.FC = () => {
  const { translate } = useLocalization();

  return (
    <ul className={styles.list}>
      {TEXAS_HOLDEM_POKER_HANDS.map((h) => (
        <li key={h.title} className={styles.item}>
          <p className={styles.title}>{translate(h.title)}</p>
          <div className={styles.cards}>
            {h.cards.map((c) => (
              <img key={`${h.title}-${c}`} className={styles.card} src={c} alt="card" />
            ))}
          </div>
          <p className={styles.desc}>{translate(h.desc)}</p>
        </li>
      ))}
    </ul>
  );
};

export default TexasHoldemPokerHands;
