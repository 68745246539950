import clsx from 'clsx';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import { sha3_256 } from 'js-sha3';

import { GET_USER } from 'graphQl/query/auth/profile';
import { amount, getImgSrc } from 'func/common';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import CopyButton from 'components/Base/CopyButton';
import Button from 'components/Base/Button';

import { IBetDetailsWrap } from './types';
import styles from './styles.module.scss';

const BetDetailsWrap: React.FC<IBetDetailsWrap> = ({ children, bet, withVerify, onVerify }) => {
  const { translate } = useLocalization();

  const { data: user } = useQuery(GET_USER, {
    fetchPolicy: 'cache-only',
  });

  const getDate = (string: string): string => dayjs(string).format('MM/DD/YYYY  hh:mm:ss A');

  return (
    <div className={styles.betDetailsWrap}>
      <div className={styles.betDetailsTitle}>{translate('popups.bets.details.title')}</div>
      <div className={styles.betDetailsSubtitle}>
        <div className={styles.betDetailsSubtitleItem1}>
          <p>{translate('popups.bets.details.player')}:</p>
          <p>{translate('popups.bets.details.date')}:</p>
          <p>{translate('popups.bets.details.id')}:</p>
        </div>
        <div className={styles.betDetailsSubtitleItem2}>
          <h3>{user?.profile?.alias}</h3>
          <h3>{getDate(bet?.createdAt)}</h3>
          <h3>{bet?.id}</h3>
        </div>
      </div>
      <div className={styles.betDetailsAmountBlock}>
        <div className={styles.betDetailsAmount}>
          <p className={styles.betDetailsAmountTitle}>{translate('popups.bets.details.amount')}</p>
          <div className={styles.betDetailsAmountSubtitle}>
            <img alt="icon" src={getImgSrc(bet?.token?.displayName)} className={styles.betDetailsTokenIcon} />
            <span className={styles.betDetailsAmountResult}>{amount(bet?.amount)}</span>
          </div>
        </div>
        <div className={styles.betDetailsMultiplier}>
          <p className={styles.betDetailsAmountTitle}>{translate('popups.bets.details.multiplier')}</p>
          <div className={styles.betDetailsAmountSubtitle}>
            <span className={styles.betDetailsAmountResult}>{bet?.multiplier}</span>
          </div>
        </div>
        <div className={styles.betDetailsPayout}>
          <p className={styles.betDetailsAmountTitle}>{translate('popups.bets.details.payout')}</p>
          <div className={styles.betDetailsAmountSubtitle}>
            <img src={getImgSrc(bet?.token?.displayName)} alt="icon" className={styles.betDetailsTokenIcon} />
            <span className={styles.betDetailsAmountResult}>{amount(bet?.payout)}</span>
          </div>
        </div>
      </div>
      {children}
      {withVerify ? (
        <>
          <div className={clsx(styles.betDetailsSubtitle, styles.seeds)}>
            <div className={styles.subtitleItem}>
              <p>{translate('popups.bets.details.server-seed')}:</p>
            </div>
            <div className={styles.subtitleItem}>
              <div className={styles.seedsText}>
                <p>{bet?.serverSeed}</p>
                <CopyButton copyContent={bet?.serverSeed} />
              </div>
            </div>
          </div>
          <div className={clsx(styles.betDetailsSubtitle, styles.seeds)}>
            <div className={styles.subtitleItem}>
              <p>{translate('popups.bets.details.hash')}:</p>
            </div>
            <div className={styles.subtitleItem}>
              <div className={styles.seedsText}>
                <p>{sha3_256(bet?.serverSeed || '')}</p>
                <CopyButton copyContent={sha3_256(bet?.serverSeed || '')} />
              </div>
            </div>
          </div>
          <div className={styles.verifyButtonWrapper}>
            <Button
              className={styles.button}
              title={translate('popups.bets.details.verify')}
              variant={ButtonType.primary}
              onclick={onVerify}
              width="100px"
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default BetDetailsWrap;
