import { useEffect, useState } from 'react';

import { media } from 'config';

import Link from 'components/Base/Links/Link';

import styles from './styles.module.scss';
import { IWelcomeSlide } from './types';

const WelcomeSlide: React.FC<IWelcomeSlide> = ({ slide, onClick }) => {
  const [withLink, setWithLink] = useState(false);

  useEffect(() => {
    setWithLink(Boolean(slide.url));
  }, [slide]);

  const Tag = withLink ? Link : 'div';

  return (
    <Tag className={styles.wrapper} to={slide.url} onClick={() => onClick(slide.id, slide.deskImage)}>
      <picture>
        <source srcSet={`${media.slider}${slide.deskImage}`} media="(min-width: 1024px)" />
        <source srcSet={`${media.slider}${slide.tabImage}`} media="(min-width: 700px)" />
        <img src={`${media.slider}${slide.mobImage}`} alt={`Slider image ${slide.id}`} />
      </picture>
    </Tag>
  );
};

export default WelcomeSlide;
