import { gql } from '@apollo/client';

export const GET_BETS_HISTORY = gql`
  query userBets($input: BetsPaginationParams!) {
    userBets(paginationData: $input) {
      count
      items {
        amount
        createdAt
        isAuto
        multiplier
        payout
        result
        id
        tokenCode
        displayName
      }
    }
  }
`;

export const USER_BETS_HISTORY = gql`
  query userBetsList($input: BetsListPaginationParams!) {
    userBetsList(paginationData: $input) {
      count
      items {
        date
        game
        amount
        multiplier
        payout
        tokenCode
        displayName
      }
    }
  }
`;
