import { ETokenCode } from 'components/constants/constants';

export enum WheelSetting {
  bgColor = 'wheel_background_color',
  buttonColor = 'wheel_button_color',
  textColor = 'wheel_text_color',
  duration = 'wheel_duration',
  status = 'wheel_status',
  style = 'wheel_style',
}

export type WheelSettings = Record<WheelSetting, string>;

export interface IWheelOfFortuneReward {
  id: string;
  prize: string;
  displayName: string;
  tokenCode: ETokenCode;
  weight: number;
  rankId: number;
}
