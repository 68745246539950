import { gql } from '@apollo/client';

export const GET_GAME_SETTINGS = gql`
  query gameSettings($input: String!) {
    gameSettings(gameId: $input) {
      defaultBet
      maximumBet
      minimumBet
      maximumPayout
      token {
        tokenCode
        displayName
      }
    }
  }
`;
