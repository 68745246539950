import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ArticleObject } from 'intercom-client';

import IntercomContext from 'context/contextIntercom/context';
import { Links } from 'components/constants';

import Title from './components/Title';
import FAQContent from './components/FAQContent';
import FAQSectionStyle from './styles';

const FAQSection: React.FC = () => {
  const { pathname, search } = useLocation();
  const { articles, collections } = useContext(IntercomContext);

  const [collectionKey, setCollectionKey] = useState('');
  const [collectionId, setCollectionId] = useState('');
  const [sectionArticles, setSectionArticles] = useState<ArticleObject[]>([]);

  useEffect(() => {
    const newCollectionKey = pathname.includes(Links.faq) ? '' : `${pathname}${search}`;
    setCollectionKey(newCollectionKey);
  }, [pathname, search]);

  useEffect(() => {
    if (collectionKey && collections.length) {
      const newCollection = collections.find((c) => collectionKey.toLowerCase().includes(c.key));

      setCollectionId(newCollection?.id || '');
    } else {
      setCollectionId('');
    }
  }, [collectionKey, collections]);

  useEffect(() => {
    if (collectionId && articles.length) {
      const filteredArticles = articles.filter((a) => a.parent_id === collectionId);

      setSectionArticles(filteredArticles);
    } else {
      setSectionArticles([]);
    }
  }, [articles, collectionId]);

  if (!sectionArticles.length) return null;

  return (
    <FAQSectionStyle>
      <Title />
      <FAQContent sectionArticles={sectionArticles} />
    </FAQSectionStyle>
  );
};

export default FAQSection;
