import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';

import { useLocalization } from 'components/Internationalization';
import { getTournamentTimerState } from 'func/prepareDataTournaments';
import { TOURNAMENT_TIMER_STATE } from 'components/constants/pages';

import styles from './styles.module.scss';
import { ITournamentCardTimer } from './types';

const TournamentCardTimer: React.FC<ITournamentCardTimer> = ({ startAt, endAt }) => {
  const { translate } = useLocalization();

  const [timerState, setTimerState] = useState('');
  const [isEventStarted, setIsEventStarted] = useState(false);
  const [isEventFinished, setIsEventFinished] = useState(false);

  const { days, hours, minutes, seconds, restart } = useTimer({
    expiryTimestamp: dayjs(endAt).toDate(),
    onExpire: handleExpire,
  });

  useEffect(() => {
    const now = dayjs();
    const started = dayjs(startAt).isBefore(now);
    const finished = dayjs(endAt).isAfter(now);
    const countDownDate = dayjs(started ? endAt : startAt).toDate();

    restart(countDownDate, true);

    setTimerState(getTournamentTimerState(finished, started));
    setIsEventStarted(started);
    setIsEventFinished(finished);
  }, []);

  const getFormattedTime = (time: number): string => {
    if (time > 99) {
      return String(time);
    }

    if (time <= 0) {
      return '00';
    }

    return ('0' + String(time)).slice(-2);
  };

  function handleExpire() {
    setTimerState(getTournamentTimerState(isEventFinished, isEventStarted));
  }

  return (
    <div className={styles.tournamentCardTimerWrap}>
      <h3>{translate(timerState !== TOURNAMENT_TIMER_STATE.finished ? timerState : 'events.tournaments.finished')}</h3>
      {timerState !== TOURNAMENT_TIMER_STATE.finished && (
        <div className={styles.tournamentCardTimerItemsWrap}>
          <div className={styles.tournamentCardTimerItem}>
            <p>{getFormattedTime(days)}</p>
            <span>{translate('general.timer.day')}</span>
          </div>
          <div className={styles.tournamentCardTimerItem}>
            <p>{getFormattedTime(hours)}</p>
            <span>{translate('general.timer.hour')}</span>
          </div>
          <div className={styles.tournamentCardTimerItem}>
            <p>{getFormattedTime(minutes)}</p>
            <span>{translate('general.timer.min')}</span>
          </div>
          <div className={styles.tournamentCardTimerItem}>
            <p>{getFormattedTime(seconds)}</p>
            <span>{translate('general.timer.sec')}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TournamentCardTimer;
