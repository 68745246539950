import styled from 'styled-components';

import signupIcon from 'assets/img/PopUps/registr-side-icon.webp';

export const SignUpWrapper = styled.article`
  font-family: 'Montserrat';
  color: #ffffff;
  display: flex;
  width: 100%;
  height: 100%;

  .signup__image {
    width: 370px;
    height: 100%;
    background-image: url(${signupIcon});
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media (max-width: 1100px) {
    .signup__image {
      display: none;
      width: 0;
    }
  }
`;
