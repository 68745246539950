import { CardGameStake } from 'types';
import { FastModeGames, HotKeys } from 'components/constants/games';
import { INITIAL_ACTIONS, INITIAL_PLAYER_HAND } from 'components/constants/blackJack';

import { IBlackJackInitialState } from './types';

export const blackJackInitialState: IBlackJackInitialState = {
  fastMode:
    JSON.parse(localStorage.getItem(FastModeGames.blackJack)) !== null
      ? JSON.parse(localStorage.getItem(FastModeGames.blackJack))
      : true,
  isHotkeys: JSON.parse(localStorage.getItem(HotKeys.blackJack)) || false,
  hash: '',
  gameId: '',
  walletUSD: '0',
  isHighMode: CardGameStake.default,
  chips: [],
  chipsHistory: [],
  prevChipsHistory: [],
  betAmount: '0',
  prevBetAmount: '0',
  minBet: 0,
  maxBet: 0,
  actions: INITIAL_ACTIONS,
  playerHand: INITIAL_PLAYER_HAND,
  dealerHand: [],
  historyList: [],
  payout: '0',
  isSessionStarted: false,
  emptyBetAmount: '',
  isStarToken: false,
};
