import { amount, getImgSrc } from 'func/common';
import { getDisplayName } from 'func/prepareDataHeader';

import Slider from 'components/Base/Slider';

import styles from './styles.module.scss';
import { IRatesSlider } from './types';

const RatesSlider: React.FC<IRatesSlider> = ({ ratesList }) => {
  return (
    <>
      <Slider className={styles.btcRateWrap} autoPlay interval={4000} stopOnHover>
        {ratesList.map((r) => (
          <div key={r.token} className={styles.slide}>
            <img src={getImgSrc(r.token)} alt="icon" className={styles.btcRateImg} />
            <p>{`1 ${getDisplayName(r.token)} = $ ${amount(r.value)}`}</p>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default RatesSlider;
