import { useRef } from 'react';

import { ReactComponent as CrossIcon } from 'assets/img/common/cross.svg';

import { ReferralPageTablesTitles } from 'components/constants/table';
import { ICalendarRef } from 'components/Base/Calendar/types';
import { useAppSelector } from 'hooks/useAppSelector';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';

import Calendar from 'components/Base/Calendar';

import styles from './styles.module.scss';
import { ITablesTitles } from './types';

const TablesTitles: React.FC<ITablesTitles> = ({
  currentTitle,
  titles,
  dateFrom,
  dateTo,
  showDate,
  resetIsActive,
  setDateFrom,
  setDateTo,
  setShowDate,
  onTitle,
  onReset,
}) => {
  const { translate } = useLocalization();
  const calendarRef = useRef<ICalendarRef>(null);

  const token = useAppSelector(userToken);

  const pageWithCalendar: string[] = [ReferralPageTablesTitles.activity];

  const handleReset = () => {
    if (onReset) {
      const [newStartDate, newEndDate] = onReset();
      calendarRef.current?.onReset(newStartDate, newEndDate);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.tableTitlesWrap}>
        {titles.map((elem) => (
          <div
            key={elem.title}
            className={currentTitle === elem.link ? styles.tablesTitlesCurrent : styles.tablesTitles}
          >
            <p onClick={() => onTitle(elem.link)}>{translate(elem.title)}</p>
          </div>
        ))}
      </div>
      {pageWithCalendar.includes(currentTitle) ? (
        <div className={styles.calendar}>
          {resetIsActive && onReset ? (
            <button type="button" className={styles.resetButton} onClick={handleReset}>
              <CrossIcon className={styles.resetIcon} />
            </button>
          ) : null}
          <Calendar
            calendarRef={calendarRef}
            startDate={dateFrom}
            endDate={dateTo}
            showDate={showDate}
            disabled={!token}
            setStartDate={(date) => setDateFrom(date)}
            setEndDate={(date) => setDateTo(date)}
            setShowDate={setShowDate}
            handleCalendarClose={() => null}
          />
        </div>
      ) : null}
    </div>
  );
};

export default TablesTitles;
