import { gql } from '@apollo/client';

export const DIVIDENDS_QUERIES_NOT_AUTH = gql`
  query allDividendsNotAuth {
    starToken {
      maxSupply
      totalBurned
      totalMined
      totalStaked
    }
    miningStage {
      price
      rangeEnd
      rangeStart
    }
    tokenMiningPrices {
      miningPrice
      tokenCode
      displayName
    }
    dividendDistributions {
      amount
      token {
        tokenCode
        displayName
      }
      dividendBatch {
        distributedAt
        totalStaked
      }
    }
  }
`;
