import { ApolloClient, ApolloProvider, InMemoryCache, from } from '@apollo/client';

import { useSession } from 'context/contextSessionManagement/context';

import { createAuthLink, createErrorLink, createHTTPLink } from './service';

const ApolloClientProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { refreshTokens, logout } = useSession();

  const authLink = createAuthLink();
  const errorLink = createErrorLink(refreshTokens, logout);
  const httpLink = createHTTPLink();
  const cache = new InMemoryCache();

  const client = new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache,
    name: 'Web UI App',
    version: '0.0.1',
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
