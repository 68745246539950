import { PostOrPage } from '@tryghost/content-api';
import { useContext, useEffect, useState } from 'react';

import BlogContext from 'context/contextBlog/context';

import BlogArticlePreview from '../BlogArticlePreview';

import styles from './styles.module.scss';
import { IBlogMoreArticles } from './types';

const BlogMoreArticles: React.FC<IBlogMoreArticles> = ({ slug }) => {
  const { allArticles } = useContext(BlogContext);

  const [moreArticles, setMoreArticles] = useState<PostOrPage[]>([]);

  useEffect(() => {
    const articleIndex = allArticles.findIndex((a) => a.slug === slug);

    if (articleIndex < 3) {
      const newArticles = allArticles.slice(4, 7);

      setMoreArticles(newArticles);
      return;
    }

    const oneOfFirstArticle = articleIndex < 3;
    const lastArticle = allArticles.length === articleIndex + 1;

    if (oneOfFirstArticle || lastArticle) {
      const newArticles = allArticles.slice(4, 7);

      setMoreArticles(newArticles);
      return;
    }

    const lastThreeArticles = allArticles.length - 4;

    if (lastThreeArticles < articleIndex) {
      const newArticles = allArticles.slice(articleIndex + 1);

      if (newArticles.length < 2) {
        const fullArticles = [...newArticles, allArticles[3], allArticles[4]];

        setMoreArticles(fullArticles);
        return;
      }

      if (newArticles.length < 3) {
        const fullArticles = [...newArticles, allArticles[3]];

        setMoreArticles(fullArticles);
        return;
      }

      setMoreArticles(newArticles);
    }

    const newArticles = allArticles.slice(articleIndex + 1, articleIndex + 4);

    setMoreArticles(newArticles);
  }, [allArticles, slug]);

  return moreArticles.length ? (
    <section className={styles.content}>
      <h3 className={styles.title}>More Articles</h3>
      <ul className={styles.articles}>
        {moreArticles.map((a, i) => (
          <BlogArticlePreview key={`more-${a.id}`} article={a} index={i} />
        ))}
      </ul>
    </section>
  ) : null;
};

export default BlogMoreArticles;
