import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

interface Props {
  checkboxValue: boolean;
}

const InfoBetsDicePopUpStyle = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #888aa7;
  padding: 10px 5px 0;
  h3 {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #ffffff;
    margin: 0;
    padding-left: 7px;
  }
  .info__bets-elem1 {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    h3 {
      padding-left: 37px;
    }
    p {
      margin: 0;
    }
  }
  .info__bets-elem2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    p {
      margin: 0;
    }
  }
  .info__bets-elem3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    p {
      margin: 0;
    }
  }

  .info__bets-elem4 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-right: 5px;
    width: auto;
    p {
      padding-right: 2px;
      margin: 0;
    }
  }

  .info__bets-checkbox-wrap1 {
    position: relative;
    margin-left: 0;
    margin-right: 10px;
    margin-top: 5px;
    height: 100%;
    pointer-events: none;
    input {
      opacity: 0;
      z-index: 1;
      border-radius: 19px;
      width: 42px;
      height: 21px;
      &:checked + .info__bets-checkbox-label {
        background: linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
        &::after {
          content: '';
          display: block;
          border-radius: 50%;
          width: 21px;
          height: 21px;
          margin-left: 29px;
          transition: 0.2s;
        }
      }
    }
  }
  .info__bets-checkbox-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 55px;
    height: 30px;
    border-radius: 25px;
    background: #bebebe;
    cursor: url(${point}), auto;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 21px;
      height: 21px;
      margin: 4px 3px 3px 5px;
      background: #ffffff;
      box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
      transition: 0.2s;
    }
  }
  h3 {
    margin-top: 10px;
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 580px) {
  }

  @media (max-width: 540px) {
    p {
      font-size: 9px;
    }
    h3 {
      font-size: 11px;
    }
    .info__bets-elem1 {
      h3 {
        padding-left: 15px;
      }
    }
  }
  @media (max-width: 360px) {
    p {
      font-size: 7px;
    }
    h3 {
      font-size: 10px;
    }
    .info__bets-elem3 {
      h3 {
        width: 100px;
      }
    }
  }
`;

export default InfoBetsDicePopUpStyle;
