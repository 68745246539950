import { gql } from '@apollo/client';

export const UPDATE_LOGIN_REQUIRE_MFA = gql`
  mutation ($isRequired: Boolean!, $securityCode: String, $emailOtp: String) {
    updateLoginRequireMfa(isRequired: $isRequired, securityCode: $securityCode, emailOtp: $emailOtp) {
      status
    }
  }
`;

export const ENABLE_GOOGLE_MFA = gql`
  mutation ($code: String!) {
    enableGoogleMFA(code: $code) {
      message
    }
  }
`;

export const ENABLE_EMAIL_MFA = gql`
  mutation ($code: String!) {
    enableEmailMFA(code: $code) {
      message
    }
  }
`;

export const DISABLE_MFA = gql`
  mutation ($securityCode: String!) {
    disableMFA(securityCode: $securityCode)
  }
`;
