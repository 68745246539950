import youtubeIcon from 'assets/img/common/YoutubeLogo.svg';
import { useLocalization } from 'components/Internationalization';

import ButtonLink from 'components/common/ButtonLink';

import styles from './styles.module.scss';

const ReferralButtonLink: React.FC = () => {
  const { translate } = useLocalization();
  return (
    <div className={styles.wrapper}>
      <ButtonLink
        icon={youtubeIcon}
        link="https://youtu.be/dYZUaf89VeY?si=ybB_RzNlo4qg4BiB"
        title={translate('referral.link.button.title')}
        subtitle={translate('referral.link.button.subtitle')}
      />
    </div>
  );
};

export default ReferralButtonLink;
