import { useState } from 'react';

import { InHouseGamesPageTablesTitles, IN_HOUSE_TABLE_TITLES } from 'components/constants/table';

import TablesTitles from 'components/common/TablesTitles';

import styles from './styles.module.scss';
import MyMinesBets from './components/MyMinesBets';

const MinesTables: React.FC = () => {
  const [table, setTable] = useState(InHouseGamesPageTablesTitles.myBets);

  const handleTitle = (title) => setTable(title);

  return (
    <div className={styles.minesTablesWrap}>
      <TablesTitles currentTitle={table} titles={IN_HOUSE_TABLE_TITLES} onTitle={handleTitle} />
      {table === InHouseGamesPageTablesTitles.myBets ? <MyMinesBets /> : null}
    </div>
  );
};

export default MinesTables;
