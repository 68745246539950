import React, { useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import { useAppSelector } from 'hooks/useAppSelector';
import { DIVIDENDS_QUERIES_AUTH } from 'graphQl/query/dividends/dividendsQueriesAuth';
import { DIVIDENDS_QUERIES_NOT_AUTH } from 'graphQl/query/dividends/dividendsQueriesNotAuth';
import { PageShadow } from 'components/constants/pages';
import { userToken } from 'store/user/user.selectors';

import PageTitleShadow from 'components/Base/PageTitleShadow';
import DocumentTitle from 'components/Base/DocumentTitle';

import { DOCUMENT_TITLE_SETTINGS } from 'components/constants/constants';
import CardBlockFirst from './components/CardBlockFirst';
import CardBlockSecond from './components/CardBlockSecond';
import CardBlockThird from './components/CardBlockThird';
import DividendsTable from './components/DividendsTable';
import DividendsButtonLink from './components/DividendsButtonLink';
import Title from './components/Title';
import styles from './styles.module.scss';

const PreRegistrationPage: React.FC = () => {
  const token = useAppSelector(userToken);

  useQuery(DIVIDENDS_QUERIES_AUTH, {
    fetchPolicy: 'cache-and-network',
    skip: !token,
  });
  const [allDividendsNotAuth] = useLazyQuery(DIVIDENDS_QUERIES_NOT_AUTH, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!token) {
      allDividendsNotAuth();
    }
  }, [token]);

  return (
    <div className={styles.dividendsWrap}>
      <DocumentTitle data={DOCUMENT_TITLE_SETTINGS.dividends} />
      <Title />
      <PageTitleShadow background={PageShadow.dividends} />
      <div className={styles.pageWrapper}>
        <DividendsButtonLink />
        <CardBlockFirst />
        <CardBlockSecond />
        <CardBlockThird />
        <DividendsTable />
      </div>
    </div>
  );
};

export default React.memo(PreRegistrationPage);
