import { useContext } from 'react';

import phoneHand from 'assets/img/common/phone-hand.webp';

import { ContextPopUps } from 'context';
import { ButtonType } from 'components/Base/Button/types';

import ButtonSubmit from 'components/Base/PopUps/components/ButtonSubmit';
import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';

const PasswordSuccess: React.FC = () => {
  const { translate } = useLocalization();

  const { setPopUpsOpen } = useContext(ContextPopUps);
  const handleClose = () => {
    setPopUpsOpen(null);
  };
  return (
    <div className={styles.successWrap}>
      <div className={styles.successTitle}>{translate('popups.password.success.title')}</div>
      <div className={styles.successForm}>
        <img src={phoneHand} alt="icon" className={styles.successImg} />
      </div>
      <ButtonSubmit
        handleButton={handleClose}
        variant={ButtonType.secondary}
        width="288px"
        title={translate('general.buttons.close')}
        color="#242c45"
      />
    </div>
  );
};

export default PasswordSuccess;
