import { useEffect, useMemo, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';

import { getRankImg, getVipRankNum } from 'func/prepareDataCashback';
import { useLocalization } from 'components/Internationalization';

import RankSystemSliderStyles from './styles';
import { IRankSystemSlider } from './types';

const RankSystemSlider: React.FC<IRankSystemSlider> = ({ rankList, setActiveSlide, active }) => {
  const { translate } = useLocalization();
  const refSlide = useRef<Slider>(null);

  const [vipStatue, setVipStatus] = useState(null);

  const settings: Settings = useMemo(
    () => ({
      dots: false,
      slidesToShow: 1,
      speed: 200,
      arrows: true,
      className: 'rank__slider',
      autoplay: false,
      slidesToScroll: 1,
      beforeChange: (prev, next) => {
        setActiveSlide(next + 1);
      },
    }),
    [],
  );

  useEffect(() => {
    if (active) {
      refSlide.current?.slickGoTo(active - 1);
    }
  }, [active]);

  useEffect(() => {
    if (rankList) {
      setVipStatus(getVipRankNum(rankList));
    }
  }, [rankList, active]);

  return (
    <RankSystemSliderStyles>
      <Slider ref={refSlide} {...settings}>
        {rankList.map((rank, index) => (
          <div className="rank__item" key={rank.cashback}>
            <img src={getRankImg(rank.id)} alt="icon" className={`rank__item--img${index}`} />
            <div className="rank__item--status">
              <h2>{`${translate('cashback.ranks.rank.text')} ${rank.id}`}</h2>
              {rank.id >= vipStatue ? <h3>{translate('cashback.ranks.vip.text')}</h3> : null}
            </div>
          </div>
        ))}
      </Slider>
    </RankSystemSliderStyles>
  );
};

export default RankSystemSlider;
