import { gql } from '@apollo/client';

export const GET_FOOTER_DATA = gql`
  query footer {
    footer {
      id
      category
      name
      url
      external
      weight
    }
  }
`;
