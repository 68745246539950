/* eslint-disable no-console */

import decode from 'jwt-decode';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';

import { useAppSelector } from 'hooks/useAppSelector';
import { userToken } from 'store/user/user.selectors';
import { getAuthToken, getRefreshToken } from 'func/auth';
import { useSession } from 'context/contextSessionManagement/context';

import { IDecodedToken } from './types';

const LoginLayer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { refreshTokens, logout } = useSession();

  const token = useAppSelector(userToken);

  useEffect(() => {
    const authToken = getAuthToken();
    const refreshToken = getRefreshToken();

    if (authToken) {
      const { exp } = decode<IDecodedToken>(authToken);

      const now = dayjs();
      const expireAt = dayjs(exp * 1000);

      // is expired
      if (expireAt.isBefore(now)) {
        refreshTokens();
        return;
      }
    }

    // does the user have a session
    if (!authToken && refreshToken) {
      refreshTokens();
      return;
    }

    // state includes user data, but session already expired
    if (!authToken && !refreshToken && token) {
      logout();
    }
  }, []);

  useEffect(() => {
    let tokenTimeout: NodeJS.Timeout;

    if (token) {
      const { exp } = decode<IDecodedToken>(token);

      const now = dayjs();
      const expireAt = dayjs(exp * 1000).subtract(30, 'seconds');
      const diff = expireAt.diff(now).valueOf();

      tokenTimeout = setTimeout(() => {
        refreshTokens();
      }, diff);
    }

    return () => {
      if (tokenTimeout) {
        clearTimeout(tokenTimeout);
      }
    };
  }, [token]);

  return <>{children}</>;
};

export default LoginLayer;
