import * as R from 'ramda';
import { IExchangeRates, TranslationType } from 'types';

import { IFooterItem, InfoBlockAction } from 'components/Footer/components/InfoBlock/types';

export const getRatesArray = (rates: IExchangeRates): Array<{ token: string; value: string }> => {
  const list = Object.entries(rates).map(([key, value]) => {
    return { token: key, value };
  });
  return list;
};

export const getFooterLinksList = (
  list: IFooterItem[],
  t: TranslationType,
  handlePopUps: (el: string) => void,
): InfoBlockAction[] => {
  const properList = list.map<InfoBlockAction>((i) => ({
    ...i,
    name: t(i.name),
    category: t(i.category),
    func: () => handlePopUps(i.url),
  }));

  return properList;
};

export const getCategoryFooterList = (footerItems: IFooterItem[], t: TranslationType): string[] => {
  const categories = footerItems.reduce<Array<{ key: string; name: string }>>((arr, item) => {
    const { category: key } = item;

    const name = t(key);
    const newCategory = { key, name };

    const exists = arr.find((c) => c.key === key);

    if (exists) {
      return arr;
    }

    return [...arr, newCategory];
  }, []);

  const sortedCategories = R.sort((a, b) => a.key.localeCompare(b.key), categories);
  const newCategories = sortedCategories.map((c) => c.name);

  return newCategories;
};
