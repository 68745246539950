import { useContext } from 'react';

import SocketContext from 'context/contextSocket/context';

function useSocketRequest<Payload, Response>(ActionType: string) {
  const { socket } = useContext(SocketContext);

  const socketRequest = async (betData: Payload): Promise<Response> => {
    if (!socket) return;

    return new Promise((res) => {
      socket.volatile.emit(ActionType, betData, (data: Response) => {
        res(data);
      });
    });
  };

  return socketRequest;
}

export default useSocketRequest;
