import React, { useState } from 'react';
import clsx from 'clsx';

import { IGamesNavigationItem } from './types';
import styles from './styles.module.scss';

const GamesNavigationItem: React.FC<IGamesNavigationItem> = ({
  inactiveImage,
  activeImage,
  title,
  activeTab,
  onClick,
}) => {
  const [hovering, setHovering] = useState(false);
  return (
    <div
      className={clsx(styles.gamesNavItem, activeTab && styles.active)}
      onClick={onClick}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <img
        alt="icon"
        src={activeTab ? activeImage : hovering ? activeImage : inactiveImage}
        className={styles.gamesNavItemInactiveImage}
      />
      <p className={styles.gamesNavItemPara}>{title}</p>
    </div>
  );
};

export default GamesNavigationItem;
