import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { useAppSelector } from 'hooks/useAppSelector';
import { EBonus, GAMES_REQUEST_STEP } from 'components/constants/games';
import { getMyActiveBonuses } from 'func/prepareDataBonus';
import { dataIsDisplayBonus, dataUserBonuses } from 'store/data/data.selectors';
import { useLocalization } from 'components/Internationalization';
import { ButtonType } from 'components/Base/Button/types';

import Button from 'components/Base/Button';
import SlotsCard from './components/SlotsCard';

import { CardElem, IGamesCards } from './types';
import styles from './styles.module.scss';

const GamesCards: React.FC<IGamesCards> = ({
  data,
  titleShow,
  className = '',
  onLoad,
  isDisableButton,
  hideButton,
}) => {
  const { translate } = useLocalization();

  const [bonusIsActive, setBonusIsActive] = useState(false);

  const userBonuses = useAppSelector(dataUserBonuses);
  const isDisplayBonus = useAppSelector(dataIsDisplayBonus);

  useEffect(() => {
    if (isDisplayBonus === EBonus.available && userBonuses) {
      const currentBonuses = getMyActiveBonuses(userBonuses);

      setBonusIsActive(Boolean(currentBonuses?.length));
      return;
    }

    setBonusIsActive(false);
  }, [isDisplayBonus, userBonuses]);

  return (
    <>
      <div className={clsx(styles.gamesCardsWrap, className)}>
        {titleShow && (
          <div className={styles.gamesTitle}>
            <div className={styles.gamesLine} />
            <p>all games</p>
          </div>
        )}
        <div className={styles.gamesCard}>
          {data.map((elem: CardElem, index) => (
            <SlotsCard
              key={elem.gameId}
              id={elem.gameId}
              gameId={elem.gameId}
              category={elem.category}
              title={elem.title}
              src={elem.src}
              onClick={elem.onClick}
              modeTitle={elem.modeTitle}
              providerName={elem.providerName}
              isFavorite={elem.isFavorite}
              index={index}
              freeSpins={elem?.freeSpins}
              new={elem?.new}
              name={elem.name}
              slug={elem.slug}
              popular={elem?.popular}
              isBonusGame={elem?.isBonusGame}
              drops={elem?.drops}
              bonusIsActive={bonusIsActive}
              game={elem}
              providerGameId={elem.providerGameId}
              isNew={elem.new}
              isBlocked={elem.isBlocked}
              vip={elem.vip}
            />
          ))}
        </div>
        {data.length > GAMES_REQUEST_STEP - 1 && (
          <div className={styles.gamesAddMore}>
            {!hideButton ? (
              <Button
                className={styles.button}
                variant={ButtonType.primary}
                width="145px"
                onclick={onLoad}
                title={translate('games.buttons.view-more')}
                disabled={isDisableButton}
              />
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default GamesCards;
