import { gql } from '@apollo/client';

export const GET_MY_CASHBACKS = gql`
  query myCashbacksBalances {
    myCashbacks {
      availableBalance
      token {
        tokenCode
        displayName
      }
      totalCollected
    }
  }
`;

export const GET_MY_CASHBACKS_BALANCE = gql`
  query myCashbacksBalance {
    myCashbacks {
      availableBalance
      token {
        tokenCode
        displayName
      }
      totalCollected
    }
    convertedCashbackAvailableCollectionBalance {
      availableBalance
      tokenCode
      displayName
    }
  }
`;
