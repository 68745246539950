import clsx from 'clsx';
import { useContext } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

import rakebackIcon from 'assets/img/Activities/rakeback.webp';
import { ReactComponent as StarIcon } from 'assets/img/Rakeback/star.svg';

import { useAppSelector } from 'hooks/useAppSelector';
import { ContextPopUps } from 'context';
import { popUps } from 'components/constants/constants';
import useRakeback from 'hooks/useRakeback';
import { userToken } from 'store/user/user.selectors';

import styles from './styles.module.scss';
import { IRakebackActivity } from './types';

const RakebackActivity: React.FC<IRakebackActivity> = ({ onToggleMenu }) => {
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const { matchRank, available, collectable, percentage, minutes, seconds } = useRakeback();

  const token = useAppSelector(userToken);

  const handleRakeback = () => {
    if (!token) {
      setPopUpsOpen(popUps.registration);
      return;
    }

    setPopUpsOpen(popUps.rakebackCollect);
    onToggleMenu();
  };

  return (
    <div className={styles.wrapper} onClick={handleRakeback}>
      <div>
        <p className={clsx(styles.title, styles.colored)}>Rake</p>
        <p className={styles.title}>Back</p>
      </div>
      <div className={styles.iconWrapper}>
        <img className={styles.icon} src={rakebackIcon} alt="Rakeback" />
      </div>
      {collectable && available ? <StarIcon className={styles.collectable} /> : null}
      {matchRank && !available ? (
        <div className={styles.timeWrapper} onClick={handleRakeback}>
          <div className={styles.progress}>
            <CircularProgressbarWithChildren
              minValue={0}
              maxValue={100}
              value={percentage}
              styles={buildStyles({
                pathColor: '#FCCD00',
                trailColor: 'rgba(255, 255, 255, 0.2)',
              })}
            >
              <span className={styles.time}>
                {minutes}:{`0${seconds}`.slice(-2)}
              </span>
            </CircularProgressbarWithChildren>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default RakebackActivity;
