import { useContext, useEffect, useState } from 'react';
import Big from 'big.js';
import { pathOr } from 'ramda';
import { useMutation, useQuery } from '@apollo/client';

import { useAppSelector } from 'hooks/useAppSelector';
import { BuyTicketSize } from 'components/Games/Lottery/base/BuyTicketBlock/types';
import { bigThen, lessThen } from 'func/prepareDataDice';
import { popUps } from 'components/constants/constants';
import { BUY_TICKET } from 'graphQl/mutations/lottery/lottery';
import { ContextPopUps, ContextWallet } from 'context';
import { GET_LOTTERY_ROUND_INFO, GET_LOTTERY_TICKETS } from 'graphQl/query/lottery/lottery';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';

import LotteryTitleSquare from 'components/Games/Lottery/base/LotteryTitleSquare';
import BuyTicketBlock from 'components/Games/Lottery/base/BuyTicketBlock';

import LotteryContentStyle from './styles';
import { ILotteryContent } from './types';

const LotteryContent: React.FC<ILotteryContent> = ({ myTicketsRef, totalTickets, ticketPrice, onSubmitRefetch }) => {
  const { walletUserStar } = useContext(ContextWallet);
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { translate } = useLocalization();

  const [inputValue, setInputValue] = useState(1);
  const [disableButton, setDisableButton] = useState(false);
  const [disabledInput, setDisabledInput] = useState(false);
  const [myTickets, setMyTickets] = useState(0);
  const [chanceToWin, setChanceToWin] = useState(null);

  const token = useAppSelector(userToken);

  const { data: lotteryTickets } = useQuery(GET_LOTTERY_TICKETS, {
    fetchPolicy: 'cache-and-network',
    skip: !token,
  });
  const [buyLotteryTicket] = useMutation(BUY_TICKET, {
    refetchQueries: [GET_LOTTERY_ROUND_INFO, GET_LOTTERY_TICKETS],
  });

  useEffect(() => {
    myTicketsRef.current = (tickets: number) => setMyTickets(tickets);
  }, []);

  useEffect(() => {
    const newTickets = pathOr(0, ['myLotteryTickets', 'numberOfTickets'], lotteryTickets);

    setMyTickets(newTickets);
  }, [lotteryTickets]);

  const handleSubmit = async () => {
    if (!token) {
      setPopUpsOpen(popUps.login);
      return;
    }

    setDisableButton(true);

    try {
      const response = await buyLotteryTicket({
        variables: {
          input: { numberOfTickets: Number(inputValue) },
        },
      });

      if (response) {
        const newTickets = pathOr(myTickets, ['data', 'buyLotteryTicket'], response);

        setMyTickets(newTickets);
      }

      onSubmitRefetch();
      setDisableButton(false);
      setInputValue(1);
    } catch (error) {
      console.log(error.message); // eslint-disable-line
      setDisableButton(false);
    }
  };

  useEffect(() => {
    if (inputValue && walletUserStar && bigThen(inputValue * ticketPrice, walletUserStar)) {
      setDisableButton(true);
    } else if (inputValue <= 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [inputValue, walletUserStar, ticketPrice]);

  useEffect(() => {
    const newChance = (myTickets / totalTickets) * 100;

    if (Number.isNaN(newChance) || !Number.isFinite(newChance)) {
      setChanceToWin('0');
    } else {
      setChanceToWin(Big(newChance).toFixed(4));
    }
  }, [myTickets, totalTickets]);

  useEffect(() => {
    if (walletUserStar && lessThen(walletUserStar, ticketPrice)) {
      setDisabledInput(true);
      setDisableButton(true);
    }
    if (walletUserStar && bigThen(walletUserStar, ticketPrice)) {
      setDisabledInput(false);
      setDisableButton(false);
    }
  }, [walletUserStar, ticketPrice]);

  return (
    <LotteryContentStyle>
      <div className="lot__content-title-wrap">
        <LotteryTitleSquare
          width="30%"
          height="50px"
          title={myTickets}
          subtitle={translate('popups.lottery.my-tickets')}
        />
        <LotteryTitleSquare
          width="30%"
          height="50px"
          title={totalTickets}
          subtitle={translate('popups.lottery.total-tickets')}
        />
        <LotteryTitleSquare
          width="30%"
          height="50px"
          title={`${chanceToWin} %`}
          subtitle={translate('popups.lottery.chance')}
        />
      </div>
      <BuyTicketBlock
        size={BuyTicketSize.SMALL}
        inputValue={inputValue}
        onChange={setInputValue}
        onSubmit={handleSubmit}
        disable={disableButton}
        ticketPrice={ticketPrice}
        disabledInput={disabledInput}
      />
    </LotteryContentStyle>
  );
};

export default LotteryContent;
