import { useContext, useEffect, useState } from 'react';

import SocketContext from 'context/contextSocket/context';
import useSocketRequest from 'hooks/useSocketRequest';
import { ContextPopUps } from 'context';
import { useLocalization } from 'components/Internationalization';
import { popUps } from 'components/constants/constants';
import { ButtonType } from 'components/Base/Button/types';
import { SocketActions } from 'types';

import CopyButton from 'components/Base/CopyButton';
import Button from 'components/Base/Button';

import styles from './styles.module.scss';

const DiceVerify: React.FC = () => {
  const { translate } = useLocalization();
  const { connected } = useContext(SocketContext);
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [hash, setHash] = useState('');

  useEffect(() => {
    getHash(undefined).then((newHash) => setHash(newHash));
  }, []);

  const getHash = useSocketRequest<undefined, string>(SocketActions.diceHash);

  const handleLogin = () => {
    setPopUpsOpen(popUps.login);
  };

  return (
    <div className={styles.diceVerifyWrap}>
      <h3>{translate('popups.bets.verify.title')}</h3>
      <p>{translate('popups.bets.verify.dice.fairness')}</p>
      <p>{translate('popups.bets.verify.current-hash')}</p>
      <p>{translate('popups.bets.verify.end-round')}</p>
      <p>{translate('popups.bets.verify.compare')}</p>
      {connected ? (
        <>
          <div className={styles.hash}>
            <div className={styles.hashTitle}>
              <p className={styles.bold}>{translate('popups.bets.verify.hash')}</p>
            </div>
            <div className={styles.hashContent}>
              <div className={styles.hashText}>
                <p>{hash}</p>
                <CopyButton copyContent={hash} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.authRequired}>
          <p>
            <b>{translate('popups.bets.verify.unavailable')}</b>
          </p>
          <Button
            className={styles.button}
            variant={ButtonType.secondary}
            onclick={handleLogin}
            width="141px"
            color="#2A3A5D"
            type="button"
            title={translate('header.login')}
          />
        </div>
      )}
    </div>
  );
};

export default DiceVerify;
