import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import accountImg from 'assets/img/PageTitles/Account_Icon.svg';

import { useLocalization } from 'components/Internationalization';
import { getImgSrc } from 'func/common';
import { GET_TOTAL_STATISTIC } from 'graphQl/query/homePage/statistics';
import { getStatisticsTitles } from 'components/constants/homepage';

import styles from './styles.module.scss';

const Statistics: React.FC = () => {
  const { language, translate } = useLocalization();

  const [titles, setTitles] = useState([]);

  const { data } = useQuery(GET_TOTAL_STATISTIC, {
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    if (data) {
      const newTitles = getStatisticsTitles(translate, data);

      setTitles(newTitles);
    }
  }, [data, language]);

  if (!titles.length) return null;

  return (
    <div className={styles.homeStatisticsWrap}>
      <div className={styles.homeStatisticsTitle}>
        <div className={styles.homeStatisticsTitleLine} />
        <p>{translate('homepage.statistics.title')}</p>
      </div>
      <div className={styles.homeStatisticsCards}>
        {titles.map((elem) => (
          <div className={styles.homeStatisticsCardsItem} key={elem.src}>
            <div className={styles.homeStatisticsCardsItemTitle}>
              <div className={styles.homeStatisticsCardsItemImg}>
                <img
                  src={elem.value === 'accounts' ? accountImg : getImgSrc('BTC')}
                  alt="icon"
                  className={elem.value === 'accounts' ? styles.currencyStatisticAccount : styles.currencyStatistic}
                />
                <p>{elem.value === 'accounts' ? elem.amount : `${elem.amount} BTC`}</p>
              </div>
              <h3>{elem.title}</h3>
            </div>
            <img
              src={elem.src}
              alt="icon"
              className={elem.value === 'accounts' ? styles.statisticsAccount : styles.statisticsImg}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Statistics;
