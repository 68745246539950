import { gql } from '@apollo/client';

export const GET_TOTAL_STATISTIC = gql`
  query {
    numberOfAccounts
    totalDividends
    totalCashbacks
    totalReferrals
  }
`;
