import { gql } from '@apollo/client';

export const QUERY_LIST_FOR_NOT_AUTH = gql`
  query notAuth {
    tokenMiningPrices {
      miningPrice
      tokenCode
      displayName
    }
    getProviders {
      name
      logo
    }
    getSettings {
      name
      value
    }
    footerLogos {
      id
      logo
      weight
    }
    wheelStyle {
      wheel_style
      wheel_background_color
      wheel_button_color
    }
    ranks {
      id
      wager
      cashback
      bonus
      tips
      avatars
      vip
      vault
      rakeback
      displayName
    }
    exchangeRates {
      token {
        tokenCode
      }
      usdValue
    }
    footer {
      id
      category
      name
      url
      external
      weight
    }
    activeTokens {
      tokenCode
      displayName
      weight
    }
    tokenWithNetworks {
      tokenCode
      displayName
      name
      networks {
        minimumDeposit
        minimumWithdrawal
        withdrawalFee
        network {
          code
        }
      }
    }
    networks {
      code
      explorer
      name
      standard
    }
    missionRound {
      id
      startAt
      endAt
      prize
      prizeTokenCode
    }
    tournaments {
      id
      name
      info
      deskBanner
      tabBanner
      mobBanner
      prize
      startAt
      endAt
      rules
      type
      provider {
        name
        logo
        currencies
      }
      games {
        id
      }
    }
    bonuses {
      id
      name
      rules
      maxBonus
      minDeposit
      minBet
      maxBet
      status
      depositDuration
      type
      freeSpinSetting {
        gameId
        game {
          name
          image
          slug
        }
      }
    }
  }
`;
