/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import Big from 'big.js';

import { TableElem, Token } from 'types/requestTypes';
import { ETokenCode, TABLE_CLASSNAMES } from 'components/constants/constants';

import { DropElem } from 'components/Pages/DividendsPage/components/CardBlockSecond/components/EstimatedDividends/components/EstimateFrontCard/types';
import { amount, BNDivide, getDate, getImgSrc, removeComas } from './common';
import { EDividendsDropdown } from '../components/constants/dividends';

interface DataElem {
  title: string | number;
  src: string;
}

interface DividendCollections {
  amount?: number;
  createdAt?: string;
  __typename?: string;
  token?: Token;
}
interface DividendPool {
  balance?: number;
  dateTime?: string;
  usdRate?: string;
  __typename?: string;
  tokenCode?: Token;
  displayName?: string;
}
interface Dividend {
  availableBalance?: number;
  dateTime?: string;
  __typename?: string;
  token?: Token;
}
interface DividendDashboard {
  availableBalance?: number;
  dateTime?: string;
  __typename?: string;
  token?: Token;
}
interface DividendCollected {
  totalCollected?: number;
  __typename?: string;
  token?: Token;
}
interface DividendsDistribution {
  amount?: string;
  dividendBatch?: {
    distributedAt?: string;
    totalStaked?: string;
  };
  __typename?: string;
  token?: Token;
}
interface MyDividendsDistribution {
  amount?: string;
  distributed_at?: string;
  staked_stars?: string;
  token_code?: string;
  display_name?: string;
  __typename?: string;
}

interface DropElemEstimate {
  title: string | number;
  src: string;
  balance: string | number;
  usdRate: string;
}

interface PoolEstimate {
  balance?: string;
  usdRate?: string;
  displayName?: string;
  tokenCode?: string;
}

export const prepareDividendsPool = (arrayData: DividendPool[]): DataElem[] => {
  const balanceData = [];

  arrayData.forEach((elem) => {
    const elemObj = {
      title: amount(elem.balance),
      src: elem.tokenCode,
      displayName: elem.displayName,
    };
    balanceData.push(elemObj);
  });
  return balanceData;
};

export const prepareDividendsPoolUSD = (arrayData: DropElemEstimate[]): DataElem[] => {
  const result = arrayData.reduce(
    (acc, curr) =>
      Big(acc).add(
        Big(removeComas(String(curr.balance)))
          .mul(curr.usdRate)
          .toFixed(),
      ) as any as string,
    '0',
  );

  return [{ src: 'USD', title: amount(result.valueOf()) }];
};

export const prepareDividendCollections = (arrayData: DividendCollections[]): TableElem[][] => {
  const tableData = arrayData.map((elem) => {
    const date = {
      title: getDate(elem.createdAt),
      columnClass: TABLE_CLASSNAMES.column.default,
      textClass: TABLE_CLASSNAMES.text.dateFormat,
    };
    const elemObj = {
      title: `${amount(elem.amount)} ${elem.token.displayName}`,
      src: getImgSrc(elem.token.displayName),
      columnClass: TABLE_CLASSNAMES.column.default,
      imageClass: TABLE_CLASSNAMES.images.currency,
      textClass: TABLE_CLASSNAMES.text.default,
    };
    return [date, elemObj];
  });

  return tableData;
};

export const prepareDividend = (arrayData: Dividend[]): DataElem[] => {
  const balanceData = [];

  arrayData.forEach((elem) => {
    const elemObj = {
      title: amount(elem.availableBalance),
      src: elem.token.tokenCode,
      displayName: elem.token.displayName,
    };
    balanceData.push(elemObj);
  });
  return balanceData;
};

export const prepareDividendDashboard = (arrayData: DividendDashboard[]): DataElem[] => {
  const balanceData = [];
  const emptyData = [{ title: 0, src: 'BTC' }];
  if (arrayData.length < 1) {
    return emptyData;
  }

  arrayData.forEach((elem) => {
    const elemObj = {
      title: elem.availableBalance,
      src: elem.token.tokenCode,
      displayName: elem.token.displayName,
    };
    balanceData.push(elemObj);
  });
  return balanceData;
};

export const prepareDividendsDistribution = (arrayData: DividendsDistribution[]): TableElem[][] => {
  const tableData = arrayData.map((elem) => {
    const date = {
      title: getDate(elem.dividendBatch.distributedAt),
      columnClass: TABLE_CLASSNAMES.column.default,
      textClass: TABLE_CLASSNAMES.text.dateFormat,
    };
    const elemObjStar = {
      title: `${amount(elem.dividendBatch.totalStaked)} `,
      src: getImgSrc('STAR'),
      columnClass: TABLE_CLASSNAMES.column.default,
      imageClass: TABLE_CLASSNAMES.images.currency,
      textClass: TABLE_CLASSNAMES.text.default,
    };
    const elemObjCurrency = {
      title: `${amount(elem.amount)} ${elem.token.displayName}`,
      src: getImgSrc(elem.token.displayName),
      columnClass: TABLE_CLASSNAMES.column.default,
      imageClass: TABLE_CLASSNAMES.images.currency,
      textClass: TABLE_CLASSNAMES.text.default,
    };
    return [date, elemObjStar, elemObjCurrency];
  });

  return tableData;
};

export const prepareMyDividendsDistribution = (arrayData: MyDividendsDistribution[]): TableElem[][] => {
  const tableData = arrayData.map((elem) => {
    const date = {
      title: getDate(elem.distributed_at),
      columnClass: TABLE_CLASSNAMES.column.default,
      textClass: TABLE_CLASSNAMES.text.dateFormat,
    };
    const elemObjStar = {
      title: `${amount(elem.staked_stars)} `,
      src: getImgSrc('STAR'),
      columnClass: TABLE_CLASSNAMES.column.default,
      imageClass: TABLE_CLASSNAMES.images.currency,
      textClass: TABLE_CLASSNAMES.text.default,
    };
    const elemObjCurrency = {
      title: `${amount(elem.amount)} ${elem.display_name}`,
      src: getImgSrc(elem.display_name),
      columnClass: TABLE_CLASSNAMES.column.default,
      imageClass: TABLE_CLASSNAMES.images.currency,
      textClass: TABLE_CLASSNAMES.text.default,
    };
    return [date, elemObjStar, elemObjCurrency];
  });

  return tableData;
};

export const prepareDividendTotalCollected = (arrayData: DividendCollected[]): DataElem[] => {
  const properData = arrayData.map((elem) => ({
    title: amount(elem.totalCollected),
    src: elem.token.tokenCode,
    displayName: elem.token.displayName,
  }));
  return properData;
};

export const getScheduleColorLine = (token: string): string => {
  switch (token) {
    case 'BTC':
      return '#F7931A';
    case 'BSC':
      return '#F3BA2F';
    case 'TRX':
      return '#EC0024';
    case 'ETH':
      return '#617DE8';
    case 'DOGE':
      return '#FFCF00';
    case 'LTC':
      return '#519BF0';
    case 'XRP':
      return '#221ead';
    case 'USDT':
      return '#26A17B';
    default:
      return '#0E9628';
  }
};

export const prepareGraphData = (
  array: Array<{ amount: string; date: string }>,
): Array<{ point: number; date: string; value: string }> => {
  if (!array) return [];

  if (array.length <= 13) {
    return array.map((elem) => ({
      date: dayjs(elem.date).format('MM.DD'),
      point: Number(elem.amount),
      value: elem.amount,
    }));
  }

  const beforePrevMonth = dayjs().startOf('month').subtract(2, 'months');
  const sortArray = array.filter((elem) => dayjs(elem.date).isAfter(beforePrevMonth));
  const properData = sortArray.map((elem) => ({
    date: dayjs(elem.date).format('MM.DD'),
    point: Number(elem.amount),
    value: elem.amount,
  }));
  return properData;
};

export const getTokenCodeAcceptStar = (token: string | null, tokenCode: string): string => {
  if (!token || tokenCode === ETokenCode.STAR) return ETokenCode.BTC;
  return tokenCode;
};

export const handleHelperBalanceEstimate = (percent: number, elem: PoolEstimate, num: number): DropElem => {
  const firstRes = BNDivide(+elem.balance, 50).valueOf();
  const secondRes = BNDivide(Number(firstRes) * percent * num, 100).valueOf();

  const balanceObg = {
    title: Number(secondRes) > 0 ? amount(String(secondRes)) : amount('0'),
    src: elem.tokenCode,
    balance: Number(secondRes) > 0 ? amount(String(secondRes)) : amount('0'),
    usdRate: elem.usdRate,
    displayName: elem.displayName,
  };
  return balanceObg;
};

export const handlePeriodCalculate = (
  percent: number,
  periodNum: number,
  estimateData: PoolEstimate[],
  setBalance: (el: DropElem[]) => void,
  setUsdData: (el: DataElem[]) => void,
): void => {
  const result = estimateData.map((elem) => handleHelperBalanceEstimate(percent, elem, periodNum));
  setBalance(result);
  const usdData = prepareDividendsPoolUSD(result);
  setUsdData(usdData);
};

export const getEstimateDropdownType = (index: number): EDividendsDropdown => {
  switch (index) {
    case 0:
      return EDividendsDropdown.daily;
    case 1:
      return EDividendsDropdown.monthly;
    case 2:
      return EDividendsDropdown.quarterly;
    default:
      return EDividendsDropdown.daily;
  }
};
