import { useLocalization } from 'components/Internationalization';

import InputCustom from 'components/Base/Input';

import { InputWithMaxButtonStyle } from './styles';
import { IInputWithMaxButton } from './types';

const InputWithMaxButton: React.FC<IInputWithMaxButton> = ({ value, onChangeInput, onMaxButton, onBlurInput }) => {
  const { translate } = useLocalization();

  return (
    <InputWithMaxButtonStyle>
      <InputCustom
        id="maxValue"
        name="maxValue"
        type="string"
        inputwidth="100%"
        value={value}
        onChange={onChangeInput}
        maxLength={10}
        onBlur={onBlurInput}
      />
      <button type="button" onClick={onMaxButton} className="max__amount--btn">
        {translate('popups.wallet.vault.buttons.max')}
      </button>
    </InputWithMaxButtonStyle>
  );
};

export default InputWithMaxButton;
