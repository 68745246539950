import clsx from 'clsx';
import { styled } from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { pathOr } from 'ramda';

import clickSound from 'assets/sounds/Spin_button_click.mp3';
import wheelSound from 'assets/sounds/weel-background.mp3';
import winSound from 'assets/sounds/win_short-wheel.mp3';

import { ContextPopUps } from 'context';
import { popUps } from 'components/constants/constants';
import { useAppSelector } from 'hooks/useAppSelector';
import { generalDataSettings } from 'store/generalData/generalDtata.selectors';
import { userProfile } from 'store/user/user.selectors';
import { WHEEL_REWARDS } from 'graphQl/query/wheel/wheel';
import { SPIN_WHEEL } from 'graphQl/mutations/wheel/wheel';
import { ESounds } from 'components/constants/pages';

import WheelOfFortuneBackground from './components/WheelOfFortuneBackground';
import WheelOfFortuneInfo from './components/WheelOfFortuneInfo';
import WheelOfFortuneRewards from './components/WheelOfFortuneRewards';
import WheelStatistics from './components/WheelStatistics';
import WheelOfFortuneWheel from './components/WheelOfFortuneWheel';
import WheelOfFortuneActions from './components/WheelOfFortuneActions';
import WheelOfFortuneMusic from './components/WheelOfFortuneMusic';

import styles from './styles.module.scss';
import { buildWheelUrl, getWheelSettings } from './utils';
import { IWheelOfFortuneReward, WheelSettings } from './types';
import { WHEEL_ANIMATION_TIME } from './constants';

const WheelOfFortune: React.FC = () => {
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [audioSubmit] = useState(new Audio(clickSound));
  const [audioWheel] = useState(new Audio(wheelSound));
  const [audioWin] = useState(new Audio(winSound));

  audioWheel.loop = true;

  const [wheelUrl, setWheelUrl] = useState('');
  const [wheelSettings, setWheelSettings] = useState<WheelSettings>();
  const [historyVisible, setHistoryVisible] = useState(false);
  const [reward, setReward] = useState<IWheelOfFortuneReward>();
  const [rewards, setRewards] = useState<IWheelOfFortuneReward[]>([]);
  const [sound, setSound] = useState(true);
  const [available, setAvailable] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const user = useAppSelector(userProfile);
  const settings = useAppSelector(generalDataSettings);

  const { data } = useQuery(WHEEL_REWARDS);
  const [spinWheel] = useMutation(SPIN_WHEEL);

  useEffect(() => {
    const isSoundEnabled = localStorage.getItem(ESounds.wheel);

    if (isSoundEnabled) {
      setSound(isSoundEnabled === 'true');
    }

    return () => {
      audioWheel.pause();
    };
  }, []);

  useEffect(() => {
    if (sound) {
      audioWheel.volume = 0.07;
      audioWheel.play();
      return;
    }

    audioWheel.pause();
  }, [sound]);

  useEffect(() => {
    const newWheelSettings = getWheelSettings(settings);

    setWheelSettings(newWheelSettings);
  }, [settings]);

  useEffect(() => {
    if (wheelSettings) {
      const newUrl = buildWheelUrl(wheelSettings.wheel_style);

      setWheelUrl(newUrl);
    }
  }, [wheelSettings]);

  useEffect(() => {
    if (data) {
      const userRank = user?.rank?.id || 1;

      const newRewards = pathOr<IWheelOfFortuneReward[]>([], ['wheelSettings'], data);
      const rankRewards = newRewards.filter((r) => r.rankId === userRank);
      const sortedRewards = rankRewards.sort((a, b) => a.weight - b.weight);

      setRewards(sortedRewards);
    }
  }, [data, user]);

  const handleChangeAvailable = (a: boolean) => {
    setAvailable(a);
  };

  const handleShowHistory = (v?: boolean) => {
    setHistoryVisible(typeof v === 'boolean' ? v : (hv) => !hv);
  };

  const handleChangeSound = (s: boolean) => {
    setSound(s);
    localStorage.setItem(ESounds.wheel, String(s));
  };

  const wheelSpin = async () => {
    if (!user) {
      setPopUpsOpen(popUps.login);
      return;
    }

    if (sound) audioSubmit.play();

    setDisabled(true);

    await spinWheel()
      .then((r) => {
        const rewardId = pathOr('', ['data', 'makeWheelSpin', 'id'], r);

        const newReward = rewards.find((r) => r.id === rewardId);

        setReward(newReward);

        setTimeout(() => {
          if (sound) audioWin.play();

          setDisabled(false);
        }, WHEEL_ANIMATION_TIME - 200);
      })
      .catch((e) => {
        console.log(e);
        setDisabled(false);
      });
  };

  const handleSpin = async () => {
    if (reward) {
      setReward(undefined);
    }

    setTimeout(async () => wheelSpin(), 0);
  };

  return (
    <StyledWrapper className={clsx(styles.wrapper, 'colored')} $bgColor={wheelSettings?.wheel_background_color}>
      <div className={styles.statistics}>
        <WheelStatistics visible={historyVisible} onShowHistory={handleShowHistory} />
      </div>
      <WheelOfFortuneMusic enabled={sound} onChange={handleChangeSound} />
      <WheelOfFortuneBackground wheelUrl={wheelUrl} />
      <WheelOfFortuneInfo className={styles.info} wheelUrl={wheelUrl} rewards={rewards} />
      <WheelOfFortuneActions
        className={styles.actions}
        reward={reward}
        wheelSettings={wheelSettings}
        available={available}
        disabled={disabled}
        onSpin={handleSpin}
        onChangeAvailable={handleChangeAvailable}
        onShowHistory={handleShowHistory}
      />
      <WheelOfFortuneRewards
        className={styles.rewards}
        wheelUrl={wheelUrl}
        rewards={rewards}
        wheelSettings={wheelSettings}
      />
      <WheelOfFortuneWheel
        className={styles.wheel}
        reward={reward}
        wheelUrl={wheelUrl}
        available={available}
        disabled={disabled}
        onSpin={handleSpin}
      />
    </StyledWrapper>
  );
};

export default WheelOfFortune;

const StyledWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !prop.includes('$'),
})<{ $bgColor: string }>`
  &.colored {
    ${({ $bgColor }) => ($bgColor ? `background: ${$bgColor} !important;` : '')}
  }
`;
