import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import { useAppSelector } from 'hooks/useAppSelector';
import { userToken } from 'store/user/user.selectors';
import clock from 'assets/img/DividendsPage/clock.svg';
import { GET_DIVIDENDS_POOL } from 'graphQl/query/dividends/dividendsPool';
import { ContextTokenCode } from 'context';
import { DIVIDENDS_QUERIES_AUTH } from 'graphQl/query/dividends/dividendsQueriesAuth';
import { prepareDividendsPool, prepareDividendsPoolUSD } from 'func/prepareDataDividends';
import { useLocalization } from 'components/Internationalization';

import HighCardTitle from 'components/Base/Cards/HighCardTitle';
import CurrencySliderRow from 'components/Base/CurrencySlider/components/CurrencySliderRow';
import CurrenciesTemplate from 'components/Base/CurrensiesTemplate';

import styles from './styles.module.scss';
import Timer from '../../Timer';
import { IDividendCard } from '../../types';

const FrontCard: React.FC<IDividendCard> = ({ isFlipped }) => {
  const token = useAppSelector(userToken);
  const { language, translate } = useLocalization();

  const { tokenWithNetworksList } = useContext(ContextTokenCode);
  const [balanceData, setBalanceData] = useState([]);
  const [tokenData, setTokenData] = useState(null);
  const [usdData, setUsdData] = useState(null);

  const [cardDescription, setCardDescription] = useState('');

  const [allDividends] = useLazyQuery(DIVIDENDS_QUERIES_AUTH);
  const {
    refetch: updateDividendPool,
    startPolling,
    stopPolling,
  } = useQuery(GET_DIVIDENDS_POOL, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data) {
        setBalanceData(prepareDividendsPool(data.dividendPool));
        setUsdData(prepareDividendsPoolUSD(data.dividendPool));
      }
    },
  });

  useEffect(() => {
    const description = translate('dividends.pool.description');
    const newDescription = description.replace(':fee', '2%').replace(':remaining', '98%');

    setCardDescription(newDescription);
  }, [language]);

  useEffect(() => {
    if (tokenWithNetworksList) {
      setTokenData(tokenWithNetworksList.tokenWithNetworks);
    }
  }, [tokenWithNetworksList]);

  useEffect(() => {
    startPolling(5000);
    return () => {
      stopPolling();
    };
  }, []);

  const handleOnTick = () => {
    if (token) {
      allDividends();
    }
    updateDividendPool();
  };

  if (!tokenData && balanceData.length < 1 && !usdData) return null;
  if (!tokenData) return null;

  return (
    <HighCardTitle subtitle={cardDescription} title={translate('dividends.pool.title')} offBlur>
      <div className={styles.dividendPoolFront}>
        <div className={styles.dividendPoolFrontElem2}>
          <div className={styles.dividendPoolFrontWatchWrapDescription}>
            <CurrencySliderRow tokenCode="USD" displayName="USD" data={usdData} />
          </div>
          <div className={styles.dividendPoolFrontWatch}>
            <div className={styles.dividendPoolFrontWatchWrap}>
              <img src={clock} alt="icon" />
              <Timer onTick={handleOnTick} />
            </div>
          </div>
        </div>
        <div className={styles.dividendPoolFrontElem1}>
          {!isFlipped ? <CurrenciesTemplate currencies={balanceData} /> : null}
        </div>
      </div>
    </HighCardTitle>
  );
};

export default FrontCard;
