import { gql } from '@apollo/client';

export const GET_SLIDERS_LIST = gql`
  query activeSliders {
    activeSliders {
      id
      deskImage
      tabImage
      mobImage
      url
      status
      weight
      isAuthenticated
    }
  }
`;
