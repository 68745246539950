import styled from 'styled-components';

export const MissionsStagePrizeStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 300px;
  padding-bottom: 30px;

  .mission__currency-icon {
    width: 46px;
    height: auto;
  }

  .mission__currency-icon-small {
    width: 32px;
    height: auto;
  }

  .missions__prize--images__wrap {
    background: radial-gradient(100% 100% at 50% 0%, rgba(235, 147, 44, 0.4) 0%, rgba(36, 44, 69, 0.24) 100%);
    margin-top: -20px;
    padding-top: 30px;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
  }
  .missions__prize--images__confetti {
    position: absolute;
    top: 0;
    left: 0;
    height: 400px;
    max-width: 100%;
    z-index: 1;
  }

  .missions__prize--images__multiplier {
    height: 150px;
    z-index: 2;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: 30px;
  }

  .missions__prize--images__prize {
    height: 200px;
    z-index: 3;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: 140px;
  }
  .missions__prize--images__multiplier--amount {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 70px;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #fffcec;
    z-index: 3;
  }
  .missions__prize--images__title {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: 23px;
    background: linear-gradient(180deg, #149bc2 0%, #34c3d6 100%);
    border-radius: 4px;
    transform: translateX(-50%);
    left: 50%;
    top: 290px;
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    margin: 0;
    z-index: 4;
  }

  .missions__prize--images__amount {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 45px;
    background: linear-gradient(180deg, #cb7323 0%, #d39c0e 100%);
    box-shadow: 0 0 22px rgba(232, 173, 23, 0.5);
    border-radius: 8px;
    transform: translateX(-50%);
    left: 50%;
    top: 305px;
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #ffffff;
    margin: 0;
    z-index: 3;
  }

  .missions__prize--reward {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    margin-bottom: 15px;
  }

  .missions__prize--reward__amount {
    display: flex;
    align-items: center;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: -0.5px;

    color: #ffffff;
    margin-bottom: 30px;
  }

  .missions__timer-wrapper {
    margin-bottom: 30px;
  }

  .missions__timer,
  .desc {
    display: flex;
    align-items: center;
    margin-bottom: 0px;

    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    color: #ffffff;
  }

  .missions__timer-divider {
    margin: 0 3px;
  }

  .missions__timer-description {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #888aa7;
  }

  @media screen and (max-width: 540px) {
  }
`;
