import React from 'react';
import LotteryTitleSquareStyle from './styles';

interface Props {
  width: string;
  height: string;
  title: number | string;
  subtitle: string;
}

const LotteryTitleSquare: React.FC<Props> = ({ width, height, title, subtitle }) => (
  <LotteryTitleSquareStyle width={width} height={height}>
    <h3>{title}</h3>
    <h4>{subtitle}</h4>
  </LotteryTitleSquareStyle>
);

export default LotteryTitleSquare;
