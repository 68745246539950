import React from 'react';

import { useMines } from 'context/contextMines/contextMines';
import diamondImage from 'assets/img/Mines/diamond.webp';
import { useLocalization } from 'components/Internationalization';

import MinesDiamondBombItem from './MinesDiamondBombItem';

const MinesDiamond: React.FC = () => {
  const { translate } = useLocalization();
  const [state] = useMines();
  const { minesSelected } = state;
  return (
    <MinesDiamondBombItem image={diamondImage} count={25 - minesSelected} title={translate('mines.diamond.text')} />
  );
};

export default MinesDiamond;
