import React from 'react';
import logo from 'assets/img/common/logo.webp';
import ModeStyle from './styles';

const MaintenanceMode: React.FC = () => (
  <ModeStyle>
    <div>
      <img src={logo} alt="logo" />
    </div>
    <div>
      We are down for scheduled maintenance.
      <br />
      Please check back soon.
    </div>
  </ModeStyle>
);

export default MaintenanceMode;
