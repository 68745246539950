import { gql } from '@apollo/client';

export const GET_MY_REFERRALS = gql`
  query myReferralBalances {
    myReferralBalances {
      availableBalance
      token {
        tokenCode
        displayName
      }
      totalCollected
    }
  }
`;

export const GET_MY_REFERRALS_BALANCE = gql`
  query myReferralBalance {
    myReferralBalances {
      availableBalance
      token {
        tokenCode
        displayName
      }
      totalCollected
    }
    convertedReferralAvailableCollectionBalance {
      availableBalance
      tokenCode
      displayName
    }
  }
`;
