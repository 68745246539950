import clsx from 'clsx';
import { useContext, useState } from 'react';

import { ReactComponent as SearchIcon } from 'assets/img/common/searchIcon.svg';
import { ReactComponent as CrossIcon } from 'assets/img/common/cross.svg';

import { useAnalyticsDispatch } from 'hooks/useAnalyticsDispatch';
import { DataLayerEvent } from 'types/dataLayerTypes';

import BlogContext from 'context/contextBlog/context';
import { BLOG_SORT_OPTIONS, BlogSort } from 'components/constants/blog';
import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile } from 'store/user/user.selectors';

import Select from 'components/Base/Select';

import styles from './styles.module.scss';

const BlogToolbar: React.FC = () => {
  const { loading, searched, onSearch, onChangeSort } = useContext(BlogContext);
  const dataLayerDispatch = useAnalyticsDispatch();
  const user = useAppSelector(userProfile);

  const [sort, setSort] = useState(BlogSort.date);
  const [search, setSearch] = useState('');

  const handleChangeSortBy = (newSort: string) => {
    const s = newSort as BlogSort;

    setSort(s);
    onChangeSort(s);
  };

  const handleChangeSearch = (v: string) => {
    setSearch(v);
    dataLayerDispatch({
      event: DataLayerEvent.viewSearchResult,
      user_id: user?.id,
      search_position: 'Blog',
      search_term: v,
    });
  };

  const handleSearch = () => {
    onSearch(search);
  };

  const handleClear = () => {
    setSearch('');
    onSearch('');
  };

  return (
    <nav className={styles.wrapper}>
      <div className={styles.sort}>
        <Select
          label="Sort By"
          labelClassName={styles.label}
          labelTextClassName={styles.labelText}
          selected={sort}
          options={BLOG_SORT_OPTIONS}
          onSelect={(s) => handleChangeSortBy(s)}
        />
      </div>
      <div className={clsx(styles.searchWrapper, searched ? styles.expanded : '')}>
        {searched ? (
          <button type="button" className={styles.reset} disabled={loading} onClick={handleClear}>
            <CrossIcon />
          </button>
        ) : null}
        <input
          className={styles.search}
          type="text"
          placeholder="Search"
          value={search}
          onChange={(ev) => handleChangeSearch(ev.target.value)}
          onKeyDown={(ev) => (ev.key === 'Enter' ? handleSearch() : null)}
        />
        <SearchIcon className={styles.searchIcon} onClick={handleSearch} />
      </div>
    </nav>
  );
};

export default BlogToolbar;
