import React, { useContext, useReducer } from 'react';
import { initialState } from './initialState';
import { kenoReducer } from './kenoReduser';
import { IAction } from './types';

const ContextKeno = React.createContext(null);

export const useKeno = (): [state: typeof initialState, dispatch: (action: IAction) => void] => useContext(ContextKeno);

export const KenoProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const value = useReducer(kenoReducer, initialState);

  return <ContextKeno.Provider value={value}>{children}</ContextKeno.Provider>;
};
