import { useState } from 'react';
import { useQuery } from '@apollo/client';

import { useLocalization } from 'components/Internationalization';
import { GET_GAME_SETTINGS } from 'graphQl/query/settings/gameSettings';
import { amount, getImgSrc } from 'func/common';

import styles from './styles.module.scss';
import { IGamesLimits } from './types';

const GamesLimits: React.FC<IGamesLimits> = ({ data }) => {
  const { translate } = useLocalization();
  const { gameId } = data;

  const [settingsData, setSettingsData] = useState(null);

  useQuery(GET_GAME_SETTINGS, {
    skip: !gameId,
    fetchPolicy: 'cache-first',
    variables: {
      input: gameId,
    },
    onCompleted: (dataSet) => {
      setSettingsData(dataSet.gameSettings);
    },
  });

  return (
    <div className={styles.rulesWrap}>
      <div className={styles.rulesTitle}>
        <h6 className={styles.rulesTitleItem}>{translate('popups.limits.title')}</h6>
      </div>
      <div className={styles.limitsWrap}>
        <div className={styles.limitsContent}>
          <h5>{translate('popups.limits.bet')}</h5>
          {settingsData?.map((elem) => (
            <div key={elem.token.displayName} className={styles.limitsElemWrap}>
              <img src={getImgSrc(elem.token.displayName)} alt="icon" />
              <h4>{`${amount(elem.maximumBet)} ${elem.token.displayName}`}</h4>
            </div>
          ))}
        </div>
        <div className={styles.limitsContent}>
          <h5>{translate('popups.limits.payout')}</h5>
          {settingsData?.map((elem) => (
            <div key={elem.token.displayName} className={styles.limitsElemWrap}>
              <img src={getImgSrc(elem.token.displayName)} alt="icon" />
              <h4>{`${amount(elem.maximumPayout)} ${elem.token.displayName}`}</h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GamesLimits;
