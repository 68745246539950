import { gql } from '@apollo/client';

export const GET_DIVIDENDS_COLLECTIONS = gql`
  query {
    dividendCollections {
      amount
      createdAt
      token {
        tokenCode
        displayName
      }
    }
  }
`;
