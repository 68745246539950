import React, { useEffect, useState } from 'react';
import { popUps } from 'components/constants/constants';

import EmptyTable from 'components/Base/EmptyTable';
import MyBetsTableWrap from 'components/Games/base/MyBetsTable';

import { ITableCoin } from '../../types';

const MyCoinBets: React.FC<ITableCoin> = ({ history }) => {
  const [dataHistory, setDataHistory] = useState([]);

  useEffect(() => {
    if (history) {
      setDataHistory(
        history.map((item, index) => ({
          index,
          ...item,
        })),
      );
    }
  }, [history]);

  if (!history) {
    return (
      <div className="table__body">
        <EmptyTable lines={2} marginTop="30px" />
      </div>
    );
  }

  return (
    <div className="table__body">
      <div className="table__body1">
        <MyBetsTableWrap history={dataHistory} popUpsWindow={popUps.coinBetDetails} />
      </div>
    </div>
  );
};

export default MyCoinBets;
