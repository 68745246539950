import React from 'react';

import { IWithdrawAddressItem } from './types';

const WithdrawAddressItem: React.FC<IWithdrawAddressItem> = ({ title, index, src, name, memo, onClickWallet }) => {
  return (
    <div className="with__drop__body-item" tabIndex={index} onClick={() => onClickWallet(title, memo)}>
      <img src={src} alt="icon" className="with__drop__body-icon" />
      <h3 className="with__drop__body-title">{name}</h3>
      <h3 className="with__drop__body-title-2">{title}</h3>
    </div>
  );
};

export default WithdrawAddressItem;
