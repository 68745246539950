import React from 'react';

import { LimboProvider } from 'context/contextLimbo/contextLimbo';
import { DOCUMENT_TITLE_SETTINGS } from 'components/constants/constants';

import DocumentTitle from 'components/Base/DocumentTitle';

import Limbo from './Limbo';

const LimboGame: React.FC = () => {
  return (
    <LimboProvider>
      <DocumentTitle data={DOCUMENT_TITLE_SETTINGS.limbo} />
      <Limbo />
    </LimboProvider>
  );
};

export default LimboGame;
