import { generate as Id } from 'shortid';

import { FastModeGames, HotKeys, initialAutoState } from 'components/constants/games';
import { MINES_CONDITION } from 'components/constants/constants';

import {
  getAllHistory,
  getAutoSquareOnEndAnimation,
  getAutoSquareResult,
  getDefaultSquaresData,
  getWinInfo,
  onAddAutoPickItem,
  onChangeMineSetItems,
  onChangeMineSetSquare,
  setActiveMinesSquare,
  setAllHistory,
  setAutoSelectMineSquare,
  setCollectMinesSquares,
  setLoseMinesSquares,
  setSessionMinesSquare,
  setWinGameMinesSquare,
  setWinMinesSquare,
} from 'func/prepareDataMines';
import { getGameSettings } from 'func/prepareDataKeno';
import { amount } from 'func/common';
import { processAutoBetGetValuesForReducer } from 'func/commonGames';
import { bigThen } from 'func/prepareDataDice';

import { initialState } from './initialState';
import { EBetResult, IAction } from './types';

export const Types = {
  FAST_MODE: 'FAST_MODE',
  HOTKEYS: 'HOTKEYS',
  IS_AUTO: 'IS_AUTO',
  GAME_ID: 'GAME_ID',
  SET_BET_AMOUNT: 'SET_BET_AMOUNT',
  SELECT_MINES: 'SELECT_MINES', // set up dropdown value
  SET_FIRST_HISTORY: 'SET_FIRST_HISTORY', // history on first load page
  CHANGE_AUTO_STATE: 'CHANGE_AUTO_STATE', // checkbox value change
  CHANGE_TOKEN_CODE: 'CHANGE_TOKEN_CODE',
  ON_PICK_SQUARE: 'ON_PICK_SQUARE', // request on session game
  ON_AUTO_PICK_SQUARE: 'ON_AUTO_PICK_SQUARE', // set up list autoPickItems (array of index squares to auto game)
  INIT_GAME: 'INIT_GAME', // init session
  BET_RESPONSE: 'BET_RESPONSE', // response on session game
  COLLECT_ROUND: 'COLLECT_ROUND',
  SET_SESSION: 'SET_SESSION', // to check if you have session on current tokenCode and set up
  STOP_AUTO_BET: 'STOP_AUTO_BET',
  START_AUTO_BET: 'START_AUTO_BET',
  SET_AUTO_BET_START_CONDITION: 'SET_AUTO_BET_START_CONDITION', // set up squares to condition every star auto bet
  SET_AUTO_BET_RESPONSE: 'SET_AUTO_BET_RESPONSE',
  CHANGE_GAME_CONDITION: 'CHANGE_GAME_CONDITION', // set up gameCondition if needed
  SET_ERROR_COUNT: 'SET_ERROR_COUNT', // if more than 5 stop game
};

export const minesReducer = (state = initialState, action: IAction): typeof initialState => {
  switch (action.type) {
    case Types.FAST_MODE:
      return setFastMode(state);
    case Types.IS_AUTO:
      return setIsAuto(state);
    case Types.HOTKEYS:
      return setHotkeys(state);
    case Types.INIT_GAME:
      return initGame(state, action);
    case Types.STOP_AUTO_BET:
      return stopAutoBet(state);
    case Types.START_AUTO_BET:
      return startAutoBet(state);
    case Types.SET_ERROR_COUNT:
      return setErrorCount(state);
    case Types.SET_AUTO_BET_START_CONDITION:
      return setAutoBetStartValue(state);
    case Types.GAME_ID:
      return setGameId(state, action);
    case Types.SET_FIRST_HISTORY:
      return setFirstHistory(state, action);
    case Types.SET_BET_AMOUNT:
      return setBetAmountValue(state, action);
    case Types.SELECT_MINES:
      return setSelectMines(state, action);
    case Types.CHANGE_AUTO_STATE:
      return setAutoState(state, action);
    case Types.CHANGE_TOKEN_CODE:
      return changeTokenCode(state, action);
    case Types.ON_PICK_SQUARE:
      return setPickItem(state, action);
    case Types.ON_AUTO_PICK_SQUARE:
      return setAutoPickItem(state, action);
    case Types.BET_RESPONSE:
      return setBetResponse(state, action);
    case Types.COLLECT_ROUND:
      return collectRound(state, action);
    case Types.SET_SESSION:
      return setSession(state, action);
    case Types.CHANGE_GAME_CONDITION:
      return setGameCondition(state, action);
    case Types.SET_AUTO_BET_RESPONSE:
      return setAutoBetResponse(state, action);
    default:
      return state;
  }
};

function setFastMode(state) {
  const isFastMode = !state.fastMode;
  localStorage.setItem(FastModeGames.mines, isFastMode.toString());
  return {
    ...state,
    fastMode: isFastMode,
  };
}

function setHotkeys(state) {
  const isHotkeysMode = !state.isHotkeys;
  localStorage.setItem(HotKeys.mines, isHotkeysMode.toString());
  return {
    ...state,
    isHotkeys: isHotkeysMode,
  };
}

function setIsAuto(state) {
  const isAutoMode = state.isAuto;
  return {
    ...state,
    isAuto: !isAutoMode,
    gameCondition: !isAutoMode ? MINES_CONDITION.autoNotStarted : MINES_CONDITION.notStarted,
    squaresData: getDefaultSquaresData(),
    currentSliderNumber: 0,
    autoPickItems: [],
    winInfo: null,
  };
}

function setBetAmountValue(state, action) {
  return {
    ...state,
    betAmountValue: action.payload.betAmount,
  };
}

function setSelectMines(state, action) {
  const isUpdateItems = state.isAuto && state.autoPickItems.length > 25 - action.payload.selected;
  return {
    ...state,
    minesSelected: action.payload.selected,
    autoPickItems: isUpdateItems
      ? onChangeMineSetItems(state.autoPickItems, action.payload.selected)
      : state.autoPickItems,
    squaresData: isUpdateItems
      ? onChangeMineSetSquare(state.squaresData, action.payload.selected, state.autoPickItems)
      : state.squaresData,
  };
}

function setAutoState(state, action) {
  const name = action.payload.name;
  const value = action.payload.value;
  return {
    ...state,
    autoState: {
      ...state.autoState,
      [name]: value,
    },
  };
}

function setPickItem(state, action) {
  return {
    ...state,
    squaresData: setActiveMinesSquare(state.squaresData, action.payload.item),
  };
}

function setAutoPickItem(state, action) {
  return {
    ...state,
    squaresData: setAutoSelectMineSquare(state.squaresData, action.payload.item),
    autoPickItems: onAddAutoPickItem(state.autoPickItems, action.payload.item),
  };
}

function startAutoBet(state) {
  return {
    ...state,
    gameCondition: MINES_CONDITION.autoStarted,
    winInfo: null,
  };
}

function setAutoBetStartValue(state) {
  return {
    ...state,
    squaresData: getAutoSquareOnEndAnimation(state.squaresData, state.autoPickItems),
    winInfo: null,
  };
}

function setGameCondition(state, action) {
  return {
    ...state,
    gameCondition: action.payload.gameCondition,
  };
}

function stopAutoBet(state) {
  return {
    ...state,
    gameCondition: MINES_CONDITION.autoNotStarted,
    winInfo: null,
    autoState: {
      ...state.autoState,
      firstSpin: true,
    },
  };
}

function setErrorCount(state) {
  const isStop = +state.errorsCount + 1 >= 5;
  return {
    ...state,
    errorCount: +state.errorsCount + 1,
    gameCondition: isStop ? MINES_CONDITION.notStarted : state.gameCondition,
    squaresData: isStop ? getDefaultSquaresData() : state.squaresData,
    isAuto: isStop ? false : state.isAuto,
    requestId: isStop ? state.requestId : Id(),
    winInfo: isStop ? null : state.winInfo,
  };
}

// function response from DB for game

function setGameId(state, action) {
  return {
    ...state,
    gameId: action.payload.gameId,
  };
}

function setFirstHistory(state, action) {
  return {
    ...state,
    historyList: getAllHistory(action.payload.historyList),
  };
}

function changeTokenCode(state, action) {
  return {
    ...state,
    gameSettings: { ...getGameSettings(action.payload.gameSettings) },
    gameCondition: MINES_CONDITION.notStarted,
    betAmountValue: amount(action.payload.gameSettings.betAmount),
    isAuto: false,
    squaresData: getDefaultSquaresData(),
    minesSelected: 4,
    currentSliderNumber: 0,
    autoState: initialAutoState,
    requestId: '',
  };
}

function initGame(state, action) {
  return {
    ...state,
    squaresData: getDefaultSquaresData(),
    currentSliderNumber: 0,
    gameCondition: MINES_CONDITION.getStarted,
    winInfo: null,
    hash: action.payload.hash,
  };
}

function setBetResponse(state, action) {
  const bet = action.payload.bet;
  const item = action.payload.item;
  const tokenCode = action.payload.tokenCode;
  if (bet.option) {
    return {
      ...state,
      squaresData: setWinMinesSquare(state.squaresData, item),
      gameCondition: MINES_CONDITION.getStarted,
      currentSliderNumber: +state.currentSliderNumber + 1,
    };
  }
  if (bet?.prediction?.option === EBetResult.diamond) {
    return {
      ...state,
      squaresData: setWinGameMinesSquare(state.squaresData, item, bet),
      historyList: setAllHistory(state.historyList, bet, tokenCode, state.betAmountValue),
      winInfo: getWinInfo(bet.payout, bet.multiplier, state.gameSettings.maxPay),
      gameCondition: MINES_CONDITION.finished,
      currentSliderNumber: 0,
      hash: '',
    };
  }
  return {
    ...state,
    squaresData: setLoseMinesSquares(state.squaresData, item, bet),
    historyList: setAllHistory(state.historyList, bet, tokenCode, state.betAmountValue),
    gameCondition: MINES_CONDITION.finished,
    currentSliderNumber: 0,
    winInfo: null,
    hash: '',
  };
}

function collectRound(state, action) {
  const bet = action.payload.bet;
  const tokenCode = action.payload.tokenCode;
  return {
    ...state,
    squaresData: setCollectMinesSquares(state.squaresData, bet),
    historyList: setAllHistory(state.historyList, bet, tokenCode, state.betAmountValue),
    gameCondition: MINES_CONDITION.finished,
    currentSliderNumber: 0,
    winInfo: getWinInfo(bet.payout, bet.multiplier, state.gameSettings.maxPay),
    hash: '',
  };
}

function setSession(state, action) {
  const bet = action.payload.bet;
  return {
    ...state,
    gameCondition: MINES_CONDITION.getStarted,
    betAmountValue: String(amount(bet.wager)),
    minesSelected: bet.complexity,
    currentSliderNumber: bet.prediction.length,
    squaresData: setSessionMinesSquare(state.squaresData, bet.prediction),
    gameSettings: { ...getGameSettings(action.payload.settings) },
    isAuto: false,
    requestId: '',
    autoState: initialAutoState,
    autoPickItems: [],
  };
}

function setAutoBetResponse(state, action) {
  const bet = action.payload.bet;
  const [newAutoState, newBetAmount] = processAutoBetGetValuesForReducer(
    {
      ...state.autoState,
      firstSpin: action.payload.firstSpin,
    },
    action.payload.startBetAmount,
    action.payload.bet.payout,
    state.betAmountValue,
    state.gameSettings.maxBet,
    state.gameSettings.minBet,
  );
  return {
    ...state,
    requestId: Id(),
    gameCondition: state.gameCondition,
    autoState: newAutoState,
    betAmountValue: String(amount(newBetAmount)),
    squaresData: getAutoSquareResult(state.squaresData, bet.mines, state.autoPickItems),
    historyList: setAllHistory(state.historyList, bet, action.payload.tokenCode, state.betAmountValue),
    winInfo: bigThen(bet.payout, '0') ? getWinInfo(bet.payout, bet.multiplier, state.gameSettings.maxPay) : null,
    errorCount: 0,
    hash: action.payload.hash,
  };
}
