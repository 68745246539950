import React, { useContext } from 'react';

import { useAppSelector } from 'hooks/useAppSelector';
import SocketContext from 'context/contextSocket/context';
import LiveStatsContext from 'context/contextLiveStats/context';
import { ContextPopUps, ContextTokenCode, ContextWallet } from 'context';
import { CoinBetMode, SocketActions } from 'types';
import { buttonsCoinFlipList, ECoinCondition } from 'components/constants/games';
import { popUps } from 'components/constants/constants';
import { useEventListener } from 'hooks/useEventListener';
import { lessThen } from 'func/prepareDataDice';
import { removeComas } from 'func/common';
import {
  checkMaxPay,
  getCoinAnimationName,
  getCoinHistory,
  getCoinTableHistory,
  getMultiplierSeries,
} from 'func/prepareDataCoinFlip';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';

import {
  ICoinBetResponse,
  IPlayCoinBet,
  IPlayCoinMultiplyBet,
  IPlayCoinMultiplyBetResponseLoss,
  IPlayCoinMultiplyBetResponseWin,
} from '../../types';
import CoinOptionButtonsStyle from './styles';
import { ICoinOptionsButtons } from './types';

const CoinOptionButtons: React.FC<ICoinOptionsButtons> = ({
  isMultiply,
  disable,
  profit,
  isHotKeys,
  betSettings,
  coinId,
  fastMode,
  betAmountValue,
  setGameState,
  setHistoryTable,
  setHistory,
  setMultiplierSeries,
  setRequest,
}) => {
  const { translate } = useLocalization();

  const { connected: socketConnected, socket } = useContext(SocketContext);
  const { enabled: liveStatsEnabled, onBet: recordLiveStats } = useContext(LiveStatsContext);
  const { tokenCode } = useContext(ContextTokenCode);
  const { walletUser } = useContext(ContextWallet);
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const token = useAppSelector(userToken);

  const playCoinBet = async (betData: IPlayCoinBet): Promise<ICoinBetResponse> => {
    return new Promise((res) => {
      socket.volatile.emit(SocketActions.playCoinBet, betData, (data: ICoinBetResponse) => {
        res(data);
      });
    });
  };

  const playCoinMultiplyBet = async (
    betData: IPlayCoinMultiplyBet,
  ): Promise<IPlayCoinMultiplyBetResponseWin | IPlayCoinMultiplyBetResponseLoss> => {
    return new Promise((res) => {
      socket.volatile.emit(
        SocketActions.playCoinMultiplyBet,
        betData,
        (data: IPlayCoinMultiplyBetResponseWin | IPlayCoinMultiplyBetResponseLoss) => {
          res(data);
        },
      );
    });
  };

  const handleSignUp = () => setPopUpsOpen(popUps.registration);

  const handleHelpersOnSubmit = (bet, speedMode, multiplyIs, betRes) => {
    const conditionResult = bet.outcome ? CoinBetMode.heads : CoinBetMode.tails;
    const animationName = getCoinAnimationName(speedMode, conditionResult);

    setHistory((prev) =>
      getCoinHistory(prev, bet, tokenCode.name, betAmountValue, conditionResult, animationName, betRes),
    );

    if (isMultiply) {
      if (betRes === 'lose') {
        setHistoryTable((prev) =>
          getCoinTableHistory(prev, bet, tokenCode.name, betAmountValue, conditionResult, animationName),
        );
        setMultiplierSeries({ series: 0, multiplier: '0' });
        setGameState(ECoinCondition.sessionNotStarted);
        setRequest(false);
        return;
      }
      setMultiplierSeries((prev) => getMultiplierSeries(prev));
    } else {
      setHistoryTable((prev) =>
        getCoinTableHistory(prev, bet, tokenCode.name, betAmountValue, conditionResult, animationName),
      );
    }

    setTimeout(
      () => {
        setGameState(multiplyIs ? ECoinCondition.sessionStarted : ECoinCondition.notStarted);
        setRequest(false);
      },
      speedMode ? 400 : 1500,
    );
  };

  const handleSubmit = async (condition) => {
    if (!token) {
      handleSignUp();
      return;
    }

    const currentBetAmount = removeComas(betAmountValue);
    const notEnoughMoney = lessThen(walletUser, betSettings.minBet) || lessThen(walletUser, currentBetAmount);

    if (lessThen(currentBetAmount, betSettings.minBet)) return;

    if (!isMultiply && notEnoughMoney) {
      setPopUpsOpen({
        modalOpen: popUps.walletNavigation,
        data: { config: popUps.deposit, active: popUps.deposit },
      });
      return;
    }
    setRequest(true);

    let bet;

    try {
      if (isMultiply) {
        bet = await playCoinMultiplyBet({
          gameId: coinId,
          token: tokenCode.token,
          prediction: condition === CoinBetMode.heads ? 1 : 0,
        });

        if (liveStatsEnabled && bet.outcome) {
          recordLiveStats(currentBetAmount, '0');
        }
      } else {
        bet = await playCoinBet({
          gameId: coinId,
          token: tokenCode.token,
          wager: currentBetAmount,
          prediction: condition === CoinBetMode.heads ? 1 : 0,
        });

        if (liveStatsEnabled) {
          recordLiveStats(currentBetAmount, bet.payout);
        }
      }

      const betMode = condition === CoinBetMode.heads ? 1 : 0;
      const betRes = bet.outcome === betMode ? 'win' : 'lose';

      handleHelpersOnSubmit(bet, fastMode, isMultiply, betRes);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('[PLAY_COIN_ERROR]: ', err);
    }
  };

  const handleKyePress = (event) => {
    if (isHotKeys) {
      if (disable) return;
      const key = event?.key?.toLowerCase();
      if (key === 'n') handleSubmit(CoinBetMode.heads);
      if (key === 'm') handleSubmit(CoinBetMode.tails);
    }
  };

  useEventListener('keyup', handleKyePress);

  return (
    <CoinOptionButtonsStyle>
      {buttonsCoinFlipList.map((elem) => (
        <button
          type="submit"
          className={elem.condition === isMultiply ? elem.class : elem.class + ' active'}
          onClick={async () => handleSubmit(elem.betCondition)}
          disabled={disable || (token && !socketConnected)}
          key={elem.id}
        >
          <img src={elem.src} alt="icon" className="coin__button--img" />
          <div className="coin__button--profit">
            <h4>{translate(elem.title)}</h4>
            <h5>
              <span>{translate('coinflip.button.profit')}:</span> {checkMaxPay(profit, betSettings.maxPayout)}
            </h5>
          </div>
        </button>
      ))}
    </CoinOptionButtonsStyle>
  );
};

export default CoinOptionButtons;
