import { useEffect, useState } from 'react';

import { handleChangeInputNumber } from 'func/prepareDataPopUps';
import { useLocalization } from 'components/Internationalization';

import BuyCryptoInputDropLayout from './buyCryptoInputDropLayout';
import { IBuyCryptoCurrency } from '../BuyCryptoContent/types';
import { IBuyCryptoInputDrop } from './types';

const BuyCryptoInputDrop: React.FC<IBuyCryptoInputDrop> = ({
  inputTitle,
  inputValue,
  setInputValue,
  data,
  dropTitle,
  disableInput,
  disable,
  onDropChange,
}) => {
  const { translate } = useLocalization();
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [currenciesList, setCurrenciesList] = useState<IBuyCryptoCurrency[]>([]);

  const handleOpen = () => setIsOpen(!isOpen);

  const handleBlur = (event) => {
    if (event.relatedTarget?.tabIndex >= 100000) return;
    setIsOpen(false);
  };

  const handleChange = (e) => {
    const newValue = handleChangeInputNumber(e);

    setInputValue(newValue);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);

    if (!value) {
      setCurrenciesList(data);
      return;
    }

    const lowerCaseName = value.toLowerCase();
    const filtered = data.filter((currency) => {
      const currencyLowerCaseName = currency.ticker.toLowerCase();

      return currencyLowerCaseName.includes(lowerCaseName);
    });
    setCurrenciesList(filtered);
  };

  useEffect(() => {
    setCurrenciesList(data);
  }, [data]);

  return (
    <BuyCryptoInputDropLayout
      data={currenciesList}
      inputTitle={inputTitle}
      inputValue={inputValue}
      searchValue={searchValue}
      disable={disable}
      isOpen={isOpen}
      dropTitle={dropTitle}
      disableInput={disableInput}
      onChange={handleChange}
      onBlur={handleBlur}
      onOpen={handleOpen}
      onSearch={handleSearch}
      onDropChange={onDropChange}
      searchText={translate('games.nav.search')}
      currencyText={translate('popups.wallet.buy.crypto.currency.text')}
    />
  );
};

export default BuyCryptoInputDrop;
