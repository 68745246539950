import clsx from 'clsx';
import { useEffect, useState, useContext } from 'react';
import { getTrackBackground, Range } from 'react-range';

import { DiceBetMode } from 'types';
import DiceGameContext from 'context/contextDiceGame/context';
import SocketContext from 'context/contextSocket/context';
import { DICE_RANGE_MAX_VALUE, DICE_RANGE_MIN_VALUE, DICE_RANGE_STEP } from 'components/constants/games';
import { getDiceOverNumber, getDiceUnderNumber } from 'func/games';

import styles from './styles.module.scss';

const ROLL_UNDER_COLORS = ['#7DB30A', '#D01111'];
const ROLL_OVER_COLORS = ['#D01111', '#7DB30A'];

const DiceGameSlider: React.FC = () => {
  const { connected: socketConnected } = useContext(SocketContext);
  const { autoStarted, betInProgress, mode, prediction, onChangePrediction } = useContext(DiceGameContext);

  const [backgroundColor, setBackgroundColor] = useState('');
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const newBackgroundColor = getTrackBackground({
      values: [prediction],
      colors: mode === DiceBetMode.under ? ROLL_UNDER_COLORS : ROLL_OVER_COLORS,
      min: DICE_RANGE_MIN_VALUE,
      max: DICE_RANGE_MAX_VALUE,
    });

    setBackgroundColor(newBackgroundColor);
  }, [prediction, mode]);

  useEffect(() => {
    if (mode === DiceBetMode.under && prediction > 95) {
      onChangePrediction(95);
    } else if (mode === DiceBetMode.over && prediction < 4) {
      onChangePrediction(4);
    }
  }, [mode]);

  useEffect(() => {
    setDisabled(!socketConnected || autoStarted || betInProgress);
  }, [socketConnected, autoStarted, betInProgress]);

  const handleChangeValues = (values: number[]) => {
    const newPredictedNumber = values[0];
    const formattedNumber =
      mode === DiceBetMode.under ? getDiceUnderNumber(newPredictedNumber) : getDiceOverNumber(newPredictedNumber);

    onChangePrediction(formattedNumber);
  };

  return (
    <div className={styles.wrapper}>
      <Range
        values={[prediction]}
        step={DICE_RANGE_STEP}
        min={DICE_RANGE_MIN_VALUE}
        max={DICE_RANGE_MAX_VALUE}
        disabled={disabled}
        onChange={handleChangeValues}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={props.style}
            className={styles.range}
          >
            <div
              ref={props.ref}
              style={{ background: backgroundColor, alignSelf: 'center' }}
              className={clsx(styles.rangeLine, disabled ? styles.disabled : '')}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div {...props} style={props.style} className={clsx(styles.rangeThumb, disabled ? styles.disabled : '')}>
            {prediction}
          </div>
        )}
      />
    </div>
  );
};

export default DiceGameSlider;
