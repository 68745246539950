import { useContext } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

import rakebackPig from 'assets/img/Rakeback/rakeback-box-small.webp';
import { ReactComponent as StarIcon } from 'assets/img/Rakeback/star.svg';

import { ContextPopUps } from 'context';
import { useAppSelector } from 'hooks/useAppSelector';
import { popUps } from 'components/constants/constants';
import useRakeback from 'hooks/useRakeback';
import { userToken } from 'store/user/user.selectors';

import styles from './styles.module.scss';

const Rakeback: React.FC = () => {
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const { matchRank, available, collectable, percentage, minutes, seconds } = useRakeback();

  const token = useAppSelector(userToken);

  const handleOpenRakeback = () => {
    if (!token) {
      setPopUpsOpen(popUps.registration);
      return;
    }

    setPopUpsOpen(popUps.rakebackCollect);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.rakebackWrapper}>
        <div className={styles.rakeback} onClick={handleOpenRakeback}>
          {collectable && available ? <StarIcon className={styles.collectable} /> : null}
          <img className={styles.icon} src={rakebackPig} alt="Rakeback Bonus" />
          <span className={styles.title}>Rake</span>
          <span className={styles.title}>Back</span>
        </div>
        {matchRank && !available ? (
          <div className={styles.timeWrapper} onClick={handleOpenRakeback}>
            <div className={styles.progress}>
              <CircularProgressbarWithChildren
                minValue={0}
                maxValue={100}
                value={percentage}
                styles={buildStyles({
                  pathColor: '#FCCD00',
                  trailColor: 'rgba(255, 255, 255, 0.2)',
                })}
              >
                <span className={styles.time}>
                  {minutes}:{`0${seconds}`.slice(-2)}
                </span>
              </CircularProgressbarWithChildren>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Rakeback;
