import styled from 'styled-components';

const SaveWalletAddressFormStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 40px 30px 30px;

  .save__title {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.12px;
    text-transform: uppercase;

    color: #ffffff;
    margin-bottom: 32px;
  }

  .save__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    margin-bottom: 20px;

    h6 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;

      color: #888aa7;
    }
  }

  .save__error {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 10px;
    color: #ff274b;
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 10px;
    min-height: 30px;
    margin: 15px 0;
  }

  .save__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #888aa7;
      margin: 0 0 0 10px;
    }
  }
  h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #888aa7;
    margin: 0;
  }

  .save__button {
    text-transform: uppercase;
  }

  @media screen and (max-width: 540px) {
    padding: 40px 20px 30px;
  }
`;

export default SaveWalletAddressFormStyle;
