import { useState } from 'react';

import { useLocalization } from 'components/Internationalization';

import TexasHoldemRulesNav from './components/TexasHoldemRulesNav';
import TexasHoldemMainRules from './components/TexasHoldemMainRules';
import TexasHoldemPokerHands from './components/TexasHoldemPokerHands';

import styles from './styles.module.scss';
import { TexasHoldemRules } from './types';

const TexasHoldemRulesComponent = () => {
  const { translate } = useLocalization();

  const [title, setTitle] = useState<TexasHoldemRules>(TexasHoldemRules.rules);

  const handleChangeTitle = (t: string) => {
    setTitle(t as TexasHoldemRules);
  };

  return (
    <div className={styles.wrapper}>
      <h6 className={styles.title}>{translate('popups.rules.title')}</h6>
      <TexasHoldemRulesNav title={title} onChange={handleChangeTitle} />
      <div className={styles.container}>
        {title === TexasHoldemRules.rules ? <TexasHoldemMainRules /> : null}
        {title === TexasHoldemRules.hands ? <TexasHoldemPokerHands /> : null}
      </div>
    </div>
  );
};

export default TexasHoldemRulesComponent;
