import { useMediaQuery } from 'react-responsive';

import LotteryMobileContainer from 'components/Games/Lottery/LotteryMobileContainer';

import BurgerMenu from './components/BurgerMenu';
import HeaderAuth from './components/HeaderAuth';
import HeaderLogo from './components/HeaderLogo';
import HeaderPreRegister from './components/HeaderPreRegister';
import styles from './styles.module.scss';

const Header: React.FC = () => {
  const mobileMenu = useMediaQuery({ query: '(max-width: 1024px)' });
  const lottery = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <div className={styles.headerWrap}>
      {mobileMenu ? <BurgerMenu /> : null}
      <HeaderLogo />
      {lottery ? <LotteryMobileContainer header /> : null}
      <HeaderPreRegister />
      <HeaderAuth />
    </div>
  );
};

export default Header;
