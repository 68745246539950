import React, { useContext, useEffect, useRef } from 'react';

import { useAppSelector } from 'hooks/useAppSelector';
import { useKeno } from 'context/contextKeno/contextKeno';
import { ContextPopUps, ContextTokenCode, ContextWallet } from 'context';
import { IKenoBetData, IKenoBetResponse } from 'context/contextKeno/types';
import useGameSettings from 'hooks/useGameSettings';
import { Types } from 'context/contextKeno/kenoReduser';
import SocketContext from 'context/contextSocket/context';
import { EDiceCondition, KenoCondition } from 'components/constants/games';
import useSocketRequest from 'hooks/useSocketRequest';
import { AUTO_BETTING_STATE, popUps } from 'components/constants/constants';
import { amount, removeComas } from 'func/common';
import { lessThen } from 'func/prepareDataDice';
import { isAutoBetCanRun } from 'func/commonGames';
import { disableKenoSubmitButtons, getKenoRisk, getSubmitButtonTitle } from 'func/prepareDataKeno';
import { SocketActions } from 'types';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';

import GameBetButton from 'components/Games/base/GameBetButton';
import LiveStatsContext from 'context/contextLiveStats/context';

import styles from './styles.module.scss';

let timer;

const KenoSubmitButton: React.FC = () => {
  const { translate } = useLocalization();
  const token = useAppSelector(userToken);

  const [state, dispatch] = useKeno();
  const { tokenCode } = useContext(ContextTokenCode);
  const { walletUser } = useContext(ContextWallet);
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { enabled: liveStatsEnabled, onBet: recordLiveStats } = useContext(LiveStatsContext);
  const { connected: socketConnected, inGame: socketInGame } = useContext(SocketContext);

  const {
    gameId,
    gameCondition,
    isAuto,
    betAmountValue,
    requestId,
    gameSettings,
    autoState,
    isHotkeys,
    disable,
    risk,
    selectedSquaresList,
  } = state;

  const gameConditionRef = useRef(gameCondition);
  const firstSpinRef = useRef(true);
  const startBetAmountRef = useRef('');

  const currentGameSettings = useGameSettings(gameId);

  const handleSignUp = () => setPopUpsOpen(popUps.registration);

  const playKenoBet = useSocketRequest<IKenoBetData, IKenoBetResponse>(SocketActions.playKenoBet);

  const handleChangeAutoState = (name: string, value: boolean | string) => {
    dispatch({ type: Types.CHANGE_AUTO_STATE, payload: { name, value } });
  };

  const handleRequestBet = async () => {
    dispatch({ type: Types.START_BET });

    const currentBetAmount = removeComas(String(amount(betAmountValue)));

    const bet = await playKenoBet({
      gameId,
      token: tokenCode.token,
      wager: currentBetAmount,
      auto: isAuto,
      risk: getKenoRisk(risk),
      prediction: selectedSquaresList,
    });

    if (liveStatsEnabled) {
      recordLiveStats(currentBetAmount, bet.payout);
    }

    dispatch({
      type: Types.RESPONSE_BET,
      payload: {
        bet: { ...bet, arr: bet.sequence },
        displayName: tokenCode.name,
        firstSpin: firstSpinRef.current,
        startBetAmount: startBetAmountRef.current,
      },
    });
    firstSpinRef.current = false;
  };

  const handleBet = async () => {
    if (!token) {
      handleSignUp();
      return;
    }
    if (lessThen(walletUser, gameSettings.minBet) || lessThen(walletUser, betAmountValue)) {
      setPopUpsOpen({
        modalOpen: popUps.walletNavigation,
        data: {
          config: popUps.deposit,
          active: popUps.deposit,
        },
      });
      return;
    }
    gameConditionRef.current = KenoCondition.getStarted;
    await handleRequestBet();
    gameConditionRef.current = KenoCondition.notStarted;
  };

  const handleAutoBet = async () => {
    if (lessThen(walletUser, gameSettings.minBet) || lessThen(walletUser, betAmountValue)) {
      setPopUpsOpen({
        modalOpen: popUps.walletNavigation,
        data: {
          config: popUps.deposit,
          active: popUps.deposit,
        },
      });
      dispatch({ type: Types.CHANGE_GAME_CONDITION, payload: { gameCondition: KenoCondition.autoNotStarted } });
      gameConditionRef.current = KenoCondition.autoNotStarted;
      firstSpinRef.current = true;
      return;
    }
    gameConditionRef.current = KenoCondition.autoStarted;

    if (autoState.firstSpin) {
      handleChangeAutoState(AUTO_BETTING_STATE.autoBetAmount, removeComas(betAmountValue));
    }

    const currentBetAmount = !firstSpinRef.current ? autoState.autoBetAmount : removeComas(betAmountValue);
    if (!isAutoBetCanRun(autoState, currentBetAmount, gameSettings.minBet, handleChangeAutoState)) {
      dispatch({ type: Types.CHANGE_GAME_CONDITION, payload: { gameCondition: KenoCondition.autoNotStarted } });
      gameConditionRef.current = KenoCondition.autoNotStarted;
      firstSpinRef.current = true;
      return;
    }

    await handleRequestBet();
  };

  const handleSubmit = () => {
    if (!token) {
      handleSignUp();
      return;
    }
    if (!selectedSquaresList.length) return;
    if (gameCondition === KenoCondition.notStarted) {
      handleBet();
    }
    if (gameCondition === KenoCondition.autoNotStarted) {
      dispatch({ type: Types.START_BET });
      gameConditionRef.current = KenoCondition.autoStarted;
      startBetAmountRef.current = betAmountValue;
      firstSpinRef.current = true;
      handleAutoBet();
    }
    if (gameCondition === KenoCondition.autoStarted) {
      dispatch({ type: Types.STOP_AUTO_BET });
      gameConditionRef.current = KenoCondition.autoNotStarted;
      clearTimeout(timer);
    }
  };

  useEffect(() => {
    if (requestId) {
      if (gameConditionRef.current === KenoCondition.autoStarted) {
        timer = setTimeout(async () => handleAutoBet(), 0);
      } else {
        gameConditionRef.current = KenoCondition.autoNotStarted;
        clearTimeout(timer);
      }
    }
  }, [requestId]);

  useEffect(() => {
    if (currentGameSettings.minBet) {
      dispatch({
        type: Types.CHANGE_TOKEN_CODE,
        payload: { gameSettings: currentGameSettings },
      });
      gameConditionRef.current = KenoCondition.notStarted;
      clearTimeout(timer);
    }
  }, [currentGameSettings]);

  useEffect(() => {
    if (gameCondition === EDiceCondition.autoStarted && socketConnected && socketInGame) {
      handleAutoBet();
    }
  }, [socketConnected, socketInGame]);

  return (
    <div className={styles.kenoSubmitButtonWrap}>
      <GameBetButton
        onClick={handleSubmit}
        disable={
          (token && !socketConnected) || disableKenoSubmitButtons(gameCondition, disable) || !selectedSquaresList.length
        }
        isHotkeys={isHotkeys}
      >
        <div className={styles.kenoSubmitButton}>{translate(getSubmitButtonTitle(gameCondition))}</div>
      </GameBetButton>
    </div>
  );
};

export default KenoSubmitButton;
