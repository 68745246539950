import { pathOr } from 'ramda';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { IUserNotification } from 'types';
import { NOTIFICATIONS } from 'graphQl/query/notifications';
import {
  READ_ALL_NOTIFICATIONS,
  READ_NOTIFICATION,
  REMOVE_ALL_NOTIFICATIONS,
  REMOVE_NOTIFICATION,
} from 'graphQl/mutations/notifications';
import SocketContext from 'context/contextSocket/context';
import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile } from 'store/user/user.selectors';

import NotificationContext from './context';

export const NOTIFICATION_DISPLAY_TIME = 5000; // ms
export const READ_NOTIFICATION_TIME = 1000; // ms

const NotificationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const user = useAppSelector(userProfile);

  const { notification } = useContext(SocketContext);

  const [notificationListOpen, setNotificationListOpen] = useState(false);
  // notifications to display popup
  const [pendingNotifications, setPendingNotifications] = useState<IUserNotification[]>([]);
  // all notifications
  const [notifications, setNotifications] = useState<IUserNotification[]>([]);

  const { data: notificationsData, error: notificationError } = useQuery(NOTIFICATIONS, {
    skip: !user,
    fetchPolicy: 'cache-only',
  });
  const [readNotification, { error: readNotificationError }] = useMutation(READ_NOTIFICATION);
  const [readAllNotifications, { error: readAllNotificationsError }] = useMutation(READ_ALL_NOTIFICATIONS);
  const [removeNotification, { error: removeNotificationError }] = useMutation(REMOVE_NOTIFICATION);
  const [removeAllNotifications, { error: removeAllNotificationsError }] = useMutation(REMOVE_ALL_NOTIFICATIONS);

  useEffect(() => {
    if (notification) {
      setPendingNotifications((n) => [notification, ...n]);
      setNotifications((n) => [notification, ...n]);
    }
  }, [notification]);

  useEffect(() => {
    if (notificationsData) {
      const newNotificationsData = pathOr<IUserNotification[]>([], ['userNotifications'], notificationsData);

      setNotifications(newNotificationsData);
    }
  }, [notificationsData]);

  useEffect(() => {
    const error =
      notificationError ||
      readNotificationError ||
      readAllNotificationsError ||
      removeNotificationError ||
      removeAllNotificationsError;

    if (error) {
      // eslint-disable-next-line no-console
      console.log('[Notification Error]: ', error);
    }
  }, [notificationError, readNotificationError, readAllNotificationsError]);

  const handlePushCustomNotification = (newNotification: IUserNotification) => {
    // create customType and layout to display custom notification
    setPendingNotifications((n) => [newNotification, ...n]);
  };

  const handleOpenNotificationsList = () => {
    setNotificationListOpen(!notificationListOpen);
  };

  const handleRead = async (id: string) => {
    await readNotification({ variables: { id } }).then(() => {
      setNotifications((cn) => cn.map((n) => (n.id === id ? { ...n, isRead: true } : n)));
    });
  };

  const handelReadAll = async () => {
    await readAllNotifications().then(() => {
      setNotifications((cn) => cn.map((n) => ({ ...n, isRead: true })));
    });
  };

  const handleRemoveNotification = async (id: string) => {
    await removeNotification({ variables: { id } }).then(() => {
      setNotifications((cn) => cn.filter((n) => n.id !== id));
    });
  };

  const handleRemoveAll = async () => {
    await removeAllNotifications().then(() => {
      setNotifications([]);
    });
  };

  const handleRemovePendingNotification = (id: string) => {
    setPendingNotifications((pn) => pn.filter((n) => n.id !== id));
  };

  return (
    <NotificationContext.Provider
      value={{
        notificationListOpen,
        pendingNotifications,
        notifications,
        onOpenNotificationList: handleOpenNotificationsList,
        onRead: handleRead,
        onReadAll: handelReadAll,
        onRemove: handleRemoveNotification,
        onRemoveAll: handleRemoveAll,
        onRemovePendingNotification: handleRemovePendingNotification,
        onPushCustomNotification: handlePushCustomNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
