import React from 'react';

import FAQIcon from 'assets/img/PageTitles/FAQ_Icon.svg';
import PageTitle from 'components/Base/PageTitle';

const Title: React.FC = () => (
  <div className="faq__title">
    <PageTitle icon={FAQIcon} title="FAQ" lineColor="" heightMob="130px" titleSize="big" largeImage />
  </div>
);

export default Title;
