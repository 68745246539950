import React from 'react';

import EventsTable from '../EventsTable';
import CardList from './components/CardList';

const StarWars: React.FC = () => {
  return (
    <>
      <div className="cards__events__wrap">
        <CardList />
      </div>
      <EventsTable />
    </>
  );
};

export default StarWars;
