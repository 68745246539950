import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { ReactComponent as CrossIcon } from 'assets/img/common/cross.svg';

import { useAppSelector } from 'hooks/useAppSelector';
import { WHEEL_TYPE_OPTIONS } from 'components/constants/wheel';

import WheelStatisticsMy from './components/WheelStatisticsMy';
import WheelStatisticsRecent from './components/WheelStatisticsRecent';
import { useLocalization } from 'components/Internationalization';

import { IWheelStatistics, WheelHistoryType } from './types';
import styles from './styles.module.scss';

const WheelStatistics: React.FC<IWheelStatistics> = ({ visible, onShowHistory }) => {
  const { translate, language } = useLocalization();

  const [type, setType] = useState('');
  const [historyType, setHistoryType] = useState('');
  const [selectActive, setSelectActive] = useState(false);
  const [wheelOption, setWheelOptions] = useState([]);

  const { token } = useAppSelector((state) => ({
    token: state.user.token,
  }));

  useEffect(() => {
    setType(token ? translate('popups.wheel.dropdown.option.my') : translate('popups.wheel.dropdown.option.recent'));
    setHistoryType(token ? WheelHistoryType.my : WheelHistoryType.recent);
  }, [token]);

  useEffect(() => {
    const options = WHEEL_TYPE_OPTIONS.map((o) => ({
      title: translate(`popups.wheel.dropdown.option.${o.toLowerCase()}`),
      id: o,
    }));

    setWheelOptions(options);
  }, [language]);

  const handleChangeType = (value: { title: string; id: WheelHistoryType }) => {
    setType(value.title);
    setHistoryType(value.id);
    setSelectActive(false);
  };

  const handleSelect = () => {
    setSelectActive((active) => !active);
  };

  const handleWrapperClick = (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (selectActive) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataset = ((ev.target as any).dataset || {}) as DOMStringMap;
      const values = Object.values(dataset);

      if (!values.length) {
        setSelectActive(false);
      }
    }
  };

  return (
    <aside className={clsx(styles.wrapper, visible ? styles.visible : '')} onClick={handleWrapperClick}>
      <button type="button" className={styles.close} onClick={() => onShowHistory(false)}>
        <CrossIcon className={styles.closeIcon} />
      </button>
      <div className={styles.header}>
        <h3 className={styles.title}>{token ? translate('popups.wheel.history') : translate('popups.wheel.recent')}</h3>
        {token ? (
          <div data-select className={styles.select} onClick={handleSelect}>
            <span data-select>{type}</span>
            <i data-select className={clsx(styles.selectIcon, selectActive ? styles.active : '')} />
          </div>
        ) : null}
        {selectActive ? (
          <ul data-select className={styles.historyTypeList}>
            {wheelOption.map((o) => (
              <li data-select key={o.title} className={styles.historyTypeItem} onClick={() => handleChangeType(o)}>
                {o.title}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
      <div className={styles.historyWrapper}>
        {historyType === WheelHistoryType.my ? <WheelStatisticsMy /> : null}
        {historyType === WheelHistoryType.recent ? <WheelStatisticsRecent /> : null}
      </div>
    </aside>
  );
};

export default WheelStatistics;
