import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { useAppSelector } from 'hooks/useAppSelector';
import { useMines } from 'context/contextMines/contextMines';
import useGame from 'hooks/useGame';
import { Types } from 'context/contextMines/minesReduser';
import { GET_BETS_HISTORY } from 'graphQl/query/betsPage/betsHistory';
import { EGamesSlugName } from 'components/constants/games';
import { userProfile, userToken } from 'store/user/user.selectors';

import AllGameHistoryNew from 'components/Games/base/AllGameHistoryNew';

const MinesHistory: React.FC = () => {
  const token = useAppSelector(userToken);
  const user = useAppSelector(userProfile);

  const [state, dispatch] = useMines();

  const { gameId, historyList } = state;
  const minesGame = useGame({ slug: EGamesSlugName.mines, providerName: 'In-House', userId: user?.id }, !token);

  useQuery(GET_BETS_HISTORY, {
    skip: !token || !gameId,
    variables: {
      input: {
        limit: 28,
        offset: 0,
        gameId,
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch({ type: Types.SET_FIRST_HISTORY, payload: { historyList: data.userBets.items } });
    },
  });

  useEffect(() => {
    if (minesGame) {
      dispatch({ type: Types.GAME_ID, payload: { gameId: minesGame.id } });
    }
  }, [minesGame]);

  return <AllGameHistoryNew data={historyList} />;
};

export default MinesHistory;
