export enum WalletActions {
  deposit = 'deposit',
  withdraw = 'withdraw',
  tips = 'tips',
  addressBook = 'addressBook',
  vault = 'vault',
  buy = 'buyCrypto',
}

export interface IWalletNavigation {
  data?: any;
}

export interface IWalletNav {
  title: string;
  action: WalletActions;
  active: string[];
  imageEnable: string;
  imageDisable: string;
}
