import { AutoBetSettings } from 'components/Games/base/AutoBetBlockNew/types';
import { ETokenCode } from 'components/constants/constants';
import { DiceBetMode, IBetResutl, IGameSettings } from 'types';

export enum DiceBetState {
  once = 'once',
  auto = 'auto',
  autoStarted = 'auto-started',
}

export interface IDiceBetResult extends IBetResutl {
  id: string;
  multiplier: string;
  amount: string;
  payout: string;
  token: string;
  createdAt: Date;
}

export type DiceAutoGameField = keyof AutoBetSettings;

export interface IDiceGameContext {
  animation: boolean;
  auto: boolean;
  autoStarted: boolean;
  autoSettings: AutoBetSettings;
  animationInProgress: boolean;
  betInProgress: boolean;
  diceId: string;
  firstBet: boolean;
  amount: string;
  mode: DiceBetMode;
  prediction: number;
  history: IDiceBetResult[];
  gameSettings?: IGameSettings;
  withHotKeys: boolean;
  onChangeAuto: VoidFunction;
  onChangeAmount: (newAmount: string) => void;
  onChangeMode: (newMode: DiceBetMode) => void;
  onChangePrediction: (newPrediction: number) => void;
  onChangeAutoSettings: (name: string, value: string | boolean) => void;
  onChangeAnimation: (newValue: boolean) => void;
  onChangeWithHotKeys: (newValue: boolean) => void;
  onToggleAnimationInProgress: (newValue: boolean) => void;
  onBet: VoidFunction;
  onAutoBet: VoidFunction;
}

export interface IPlayDiceBetData {
  stateId: string;
  gameId: string;
  seed: string;
  token: string;
  mode: string;
  wager: string;
  prediction: number;
  auto: boolean;
}

export interface IDiceRollPayload {
  gameId: string;
  token: ETokenCode;
  mode: DiceBetMode;
  wager: string;
  prediction: number;
  auto: boolean;
}

export interface IDiceRollResponse {
  id: string;
  luckyNumber: number;
  multiplier: string;
  payout: string;
  seed: string;
  hash: string;
}
