import dayjs from 'dayjs';
import { pathOr } from 'ramda';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ArticleObject } from 'intercom-client';

import { FAQSort } from 'components/constants/faq';
import { DataLayerEvent } from 'types/dataLayerTypes';
import { useAnalyticsDispatch } from 'hooks/useAnalyticsDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile } from 'store/user/user.selectors';
import IntercomContext from 'context/contextIntercom/context';

import FAQArticle from '../FAQArticle';

import styles from './styles.module.scss';
import { IFAQArticles } from './types';

const FAQArticles: React.FC<IFAQArticles> = ({ collectionId, search, sort }) => {
  const { state } = useLocation();
  const { articles: persistedArticles } = useContext(IntercomContext);
  const dataLayerDispatch = useAnalyticsDispatch();
  const user = useAppSelector(userProfile);

  const [allArticles, setAllArticles] = useState<ArticleObject[]>([]);
  const [articles, setArticles] = useState<ArticleObject[]>([]);
  const [open, setOpen] = useState<string[]>([]);

  useEffect(() => {
    const articleOnOpen = pathOr<string | undefined>(undefined, ['articleId'], state);

    if (articleOnOpen) {
      setOpen((o) => [...o, articleOnOpen]);
    }
  }, [state]);

  useEffect(() => {
    const newArticles = persistedArticles.filter((a) => a.parent_id === collectionId);

    if (sort === FAQSort.date) {
      newArticles.sort((a, b) => (dayjs(a.updated_at).isBefore(b.updated_at) ? 1 : -1));
    } else {
      newArticles.sort((a, b) => parseInt(a.parent_id) - parseInt(b.parent_id));
    }

    setAllArticles(newArticles);
    setArticles(newArticles);
  }, [collectionId, sort, persistedArticles]);

  useEffect(() => {
    if (search) {
      const searchValue = search.trim().toLowerCase();

      const newSectionArticles = allArticles.filter((a) => {
        const title = a.title.trim().toLowerCase();

        return title.includes(searchValue);
      });

      setArticles(newSectionArticles);
    } else {
      setArticles(allArticles);
    }
  }, [search, allArticles]);

  const handleOpen = (articleId: string, title: string) => {
    if (open.includes(articleId)) {
      setOpen((o) => o.filter((id) => id !== articleId));
      return;
    }

    setOpen((o) => [...o, articleId]);
    dataLayerDispatch({ event: DataLayerEvent.faq, user_id: user?.id, question: title });
  };

  return (
    <ul className={styles.wrapper}>
      {articles.map((a) => (
        <FAQArticle key={a.id} article={a} open={open.includes(a.id)} onOpen={() => handleOpen(a.id, a.title)} />
      ))}
    </ul>
  );
};

export default FAQArticles;
