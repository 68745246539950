import { gql } from '@apollo/client';

export const GET_USER = gql`
  query profile {
    profile {
      alias
      createdAt
      email
      id
      telegramId
      roles
      type
      preferences {
        promoEmail
        newsEmail
        accountActivityAlert
      }
      avatar {
        avatar
        id
        weight
      }
      rank {
        bonus
        cashback
        id
        wager
        vip
      }
      referralCode
      totalWager
      totalMined
      totalBets
    }
  }
`;
