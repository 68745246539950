import { generate as Id } from 'shortid';

import { getAllHistoryLimbo, getWinInfoLimbo, setAllHistoryLimbo } from 'func/prepareDataLimbo';
import { ELimboCondition, FastModeGames, HotKeys } from 'components/constants/games';
import { getGameSettings } from 'func/prepareDataKeno';
import { amount } from 'func/common';
import { processAutoBetGetValuesForReducer } from 'func/commonGames';

import { initialState } from './initialState';
import { IAction } from './types';

export const Types = {
  FAST_MODE: 'FAST_MODE',
  HOTKEYS: 'HOTKEYS',
  IS_AUTO: 'IS_AUTO',
  GAME_ID: 'GAME_ID',
  SET_BET_AMOUNT: 'SET_BET_AMOUNT',
  SET_FIRST_HISTORY: 'SET_FIRST_HISTORY', // history on first load page
  CHANGE_AUTO_STATE: 'CHANGE_AUTO_STATE', // change values in auto component
  CHANGE_TOKEN_CODE: 'CHANGE_TOKEN_CODE',
  SET_INP_OPTION_VALUE: 'SET_INP_OPTION_VALUE', // set value for option input
  START_BET: 'START_BET',
  START_AUTO_BET: 'START_AUTO_BET',
  STOP_AUTO_BET: 'STOP_AUTO_BET',
  BET_RESPONSE: 'BET_RESPONSE',
  CHANGE_GAME_CONDITION: 'CHANGE_GAME_CONDITION',
  BEFORE_EVERY_AUTO_BET: 'BEFORE_EVERY_AUTO_BET',
  ERROR: 'ERROR',
};

export const limboReducer = (state = initialState, action: IAction): typeof initialState => {
  switch (action.type) {
    case Types.FAST_MODE:
      return setFastMode(state);
    case Types.HOTKEYS:
      return setHotkeys(state);
    case Types.IS_AUTO:
      return setIsAuto(state);
    case Types.START_BET:
      return setStartBet(state);
    case Types.START_AUTO_BET:
      return setStartAutoBet(state);
    case Types.STOP_AUTO_BET:
      return setStopAutoBet(state);
    case Types.BEFORE_EVERY_AUTO_BET:
      return setBeforeEveryAutoBet(state);
    case Types.ERROR:
      return setError(state);
    case Types.GAME_ID:
      return setGameId(state, action);
    case Types.CHANGE_GAME_CONDITION:
      return setGameCondition(state, action);
    case Types.SET_FIRST_HISTORY:
      return setFirstHistory(state, action);
    case Types.SET_BET_AMOUNT:
      return setBetAmountValue(state, action);
    case Types.CHANGE_AUTO_STATE:
      return setAutoState(state, action);
    case Types.CHANGE_TOKEN_CODE:
      return changeTokenCode(state, action);
    case Types.SET_INP_OPTION_VALUE:
      return setInputOptionValue(state, action);
    case Types.BET_RESPONSE:
      return setBetResponse(state, action);
    default:
      return state;
  }
};

function setFastMode(state) {
  const isFastMode = !state.fastMode;
  localStorage.setItem(FastModeGames.limbo, isFastMode.toString());
  return {
    ...state,
    fastMode: isFastMode,
  };
}

function setHotkeys(state) {
  const isHotkeysMode = !state.isHotkeys;
  localStorage.setItem(HotKeys.limbo, isHotkeysMode.toString());
  return {
    ...state,
    isHotkeys: isHotkeysMode,
  };
}

function setIsAuto(state) {
  const isAutoMode = state.isAuto;
  return {
    ...state,
    isAuto: !isAutoMode,
    gameCondition: !isAutoMode ? ELimboCondition.autoNotStarted : ELimboCondition.notStarted,
    switchShip: false,
  };
}

function setBetAmountValue(state, action) {
  return {
    ...state,
    betAmountValue: action.payload.betAmount,
  };
}

function setAutoState(state, action) {
  const name = action.payload.name;
  const value = action.payload.value;
  return {
    ...state,
    autoState: {
      ...state.autoState,
      [name]: value,
    },
  };
}

function setInputOptionValue(state, action) {
  return {
    ...state,
    optionInputValue: action.payload.value,
  };
}

function setStartBet(state) {
  return {
    ...state,
    gameCondition: ELimboCondition.getStarted,
    switchShip: true,
  };
}

function setStartAutoBet(state) {
  return {
    ...state,
    gameCondition: ELimboCondition.autoStarted,
  };
}

function setBeforeEveryAutoBet(state) {
  return {
    ...state,
    switchShip: true,
  };
}

function setStopAutoBet(state) {
  return {
    ...state,
    gameCondition: ELimboCondition.autoNotStarted,
    requestId: '',
    autoState: {
      ...state.autoState,
      firstSpin: true,
    },
    switchShip: false,
  };
}

function setGameCondition(state, action) {
  return {
    ...state,
    gameCondition: action.payload.gameCondition,
    switchShip: false,
  };
}

function setError(state) {
  const isCanRun = state.errorsCount < 5;
  return {
    ...state,
    errorCount: +state.errorsCount + 1,
    gameCondition: isCanRun ? state.gameCondition : ELimboCondition.notStarted,
    isAuto: false,
    switchShip: isCanRun ? state.switchShip : false,
    requestId: state.isAuto && isCanRun ? Id() : '',
  };
}

// function response from DB for game

function changeTokenCode(state, action) {
  return {
    ...state,
    gameSettings: { ...getGameSettings(action.payload.gameSettings) },
    gameCondition: ELimboCondition.notStarted,
    betAmountValue: amount(action.payload.gameSettings.betAmount),
    isAuto: false,
    switchShip: false,
  };
}

function setGameId(state, action) {
  return {
    ...state,
    gameId: action.payload.gameId,
  };
}

function setFirstHistory(state, action) {
  return {
    ...state,
    historyList: getAllHistoryLimbo(action.payload.historyList),
  };
}

function setBetResponse(state, action) {
  const bet = action.payload.bet;
  const tokenCode = action.payload.tokenCode;
  let currentAutoState;
  let currentBetAmount;
  if (state.isAuto) {
    const [newAutoState, newBetAmount] = processAutoBetGetValuesForReducer(
      {
        ...state.autoState,
        firstSpin: action.payload.firstSpin,
      }, // first spin value has to be always correct
      action.payload.startBetAmount,
      action.payload.bet.payout,
      state.betAmountValue,
      state.gameSettings.maxBet,
      state.gameSettings.minBet,
    );
    currentBetAmount = newBetAmount;
    currentAutoState = newAutoState;
  }

  return {
    ...state,
    gameCondition: state.isAuto ? state.gameCondition : ELimboCondition.notStarted,
    historyList: setAllHistoryLimbo(state.historyList, bet, tokenCode, state.betAmountValue),
    winInfo: getWinInfoLimbo(String(bet.payout), bet.coefficient),
    requestId: state.isAuto ? Id() : '',
    betAmountValue: state.isAuto ? amount(currentBetAmount) : amount(state.betAmountValue),
    autoState: state.isAuto ? currentAutoState : state.autoState,
    switchShip: false,
    errorCount: 0,
  };
}
