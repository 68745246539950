import React, { useContext } from 'react';
import clsx from 'clsx';

import { useAppSelector } from 'hooks/useAppSelector';
import { ContextPopUps } from 'context';
import { popUps } from 'components/constants/constants';
import lockIcon from 'assets/img/common/lockWithSircle.webp';

import CardWrapper from 'components/Base/CardWrapper';
import Button from 'components/Base/Button';
import { ButtonType } from 'components/Base/Button/types';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';

import { IHighCardTitle } from './types';
import styles from './styles.module.scss';

const HighCardTitle: React.FC<IHighCardTitle> = ({
  children,
  subtitle,
  title,
  additionalInfo,
  additionalInfoClassList,
  overflow,
  titleMob,
  offBlur,
  blurIfNotAuth,
  height,
}) => {
  const { translate } = useLocalization();
  const token = useAppSelector(userToken);

  const { setPopUpsOpen } = useContext(ContextPopUps);

  const handleLogIn = () => setPopUpsOpen(popUps.login);

  return (
    <CardWrapper height={height} overflow={overflow} offBlur={offBlur}>
      <div className={styles.highCardWrap}>
        <div className={styles.highCardTitleDesktop}>
          <div className={styles.highCardTitleElem1}>
            <h2>{title}</h2>
            {additionalInfo ? (
              <p
                className={clsx(
                  styles.highCardAdditionalElem,
                  additionalInfoClassList ? styles[additionalInfoClassList] : '',
                )}
              >
                {additionalInfo}
              </p>
            ) : null}
          </div>
          <div className={styles.highCardTitleElem2}>
            <h5>{subtitle}</h5>
          </div>
        </div>
        <div className={styles.highCardTitleMob}>
          <div className={styles.highCardTitleElem1}>
            <h2>{titleMob || title}</h2>
          </div>
          <div className={styles.highCardTitleElem2}>
            <h5>{subtitle}</h5>
          </div>
        </div>
        <div className={styles.highCardChildrenWrap}>
          {children}
          {!token && blurIfNotAuth && (
            <div className={styles.highCardBlur}>
              <div className={styles.highCardContent}>
                <img src={lockIcon} alt="lock" />
                <h6>{translate('cards.not.auth')}</h6>
                <Button
                  variant={ButtonType.primary}
                  title={translate('cards.not.auth.button')}
                  width="130px"
                  onclick={handleLogIn}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </CardWrapper>
  );
};

export default HighCardTitle;
