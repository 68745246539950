import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { useAppSelector } from 'hooks/useAppSelector';
import { ContextPopUps } from 'context';
import { prepareCashbackBalance, prepareCashbackTotalCollected } from 'func/prepareDataCashback';
import { getCommonSettingsValue, removeComas } from 'func/common';
import { handleDisabledCollectButton, prepareAffiliateBalance } from 'func/prepareDataAffiliates';
import { ETokenCode, popUps, SettingName } from 'components/constants/constants';
import { GET_MY_CASHBACKS_BALANCE } from 'graphQl/query/cashback/myCashbacks';
import { COLLECT_CASHBACK } from 'graphQl/mutations/cashback/cashbackToWallet';
import { GET_CASHBACK_COLLECTION } from 'graphQl/query/cashback/cashbackCollections';
import { GET_COMMON_SETTINGS } from 'graphQl/query/settings/bonusSettings';
import cashbackIcon from 'assets/img/HomePage/cashback.webp';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';

import BalanceCardSlide from 'components/Base/Cards/BalsnceCardSlide';
import { bigOrEqual } from 'func/prepareDataDice';

import { IBalanceCardCashback } from './types';

const BalanceCardCashback: React.FC<IBalanceCardCashback> = () => {
  const token = useAppSelector(userToken);
  const { translate, language } = useLocalization();

  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [balanceData, setBalanceData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [titleState, setTitleState] = useState(false);
  const [collectedData, setCollectedData] = useState([]);

  const [collectToken, setCollectToken] = useState(null);
  const [convertedBalance, setConvertedBalance] = useState([]);
  const [fee, setFee] = useState('0');
  const [description, setDescription] = useState('');

  const [collectCashback] = useMutation(COLLECT_CASHBACK, {
    refetchQueries: [GET_CASHBACK_COLLECTION, GET_MY_CASHBACKS_BALANCE],
  });

  const { data: dataSettings } = useQuery(GET_COMMON_SETTINGS, {
    fetchPolicy: 'cache-only',
  });

  const { data: cashbackBalanceResponse } = useQuery(GET_MY_CASHBACKS_BALANCE, {
    fetchPolicy: 'cache-and-network',
    skip: !token,
  });

  const handleCollect = async () => {
    setDisabled(true);
    try {
      await collectCashback({
        variables: {
          input: {
            tokenCode: collectToken,
          },
        },
      });
      const starBalance = balanceData.find((el) => el.src === ETokenCode.STAR);
      const popUpData = convertedBalance.find((el) => el.src === collectToken);
      const isDisplayStar = bigOrEqual(removeComas(starBalance.title), '0.00000001');
      const specificList = isDisplayStar ? [popUpData, starBalance] : [popUpData];
      setPopUpsOpen({
        modalOpen: popUps.collect,
        data: collectToken ? specificList : balanceData,
      });
      setCollectToken(null);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('[COLLECT_CASHBACK_ERROR]: ', error);
    }
  };

  useEffect(() => {
    if (cashbackBalanceResponse) {
      const cashbackBalance = prepareCashbackBalance(cashbackBalanceResponse.myCashbacks);
      setBalanceData(cashbackBalance);

      setCollectedData(prepareCashbackTotalCollected(cashbackBalanceResponse.myCashbacks));

      const converted = prepareAffiliateBalance(cashbackBalanceResponse.convertedCashbackAvailableCollectionBalance);
      setConvertedBalance(converted);
    }
  }, [cashbackBalanceResponse]);

  useEffect(() => {
    if (dataSettings) {
      setFee(getCommonSettingsValue(dataSettings.getSettings, SettingName.conversionFee));
    }
  }, [dataSettings]);

  useEffect(() => {
    if (!collectToken && convertedBalance) {
      const starBalance = balanceData.find((el) => el.src === ETokenCode.STAR);
      handleDisabledCollectButton(convertedBalance, setDisabled);
      if (starBalance?.title) {
        const isMoney = bigOrEqual(starBalance.title, '0.00000001');
        if (isMoney) {
          setDisabled(false);
        }
      }
    }
    if (collectToken && convertedBalance) {
      handleDisabledCollectButton(convertedBalance, setDisabled, collectToken);
    }
  }, [convertedBalance, collectToken, balanceData]);

  useEffect(() => {
    const subtitle = translate('cashback.balance.description');
    const newSubtitle = subtitle.replace(':fee', `${fee}%`);

    setDescription(newSubtitle);
  }, [language, fee]);

  return (
    <BalanceCardSlide
      height="auto"
      subtitle={description}
      heightMob="100%"
      title={translate('cashback.balance.title')}
      titleState={titleState}
      setTitleState={setTitleState}
      disabled={disabled}
      image={cashbackIcon}
      balanceData={balanceData}
      collectedData={collectedData}
      convertedBalance={convertedBalance}
      handleCollect={handleCollect}
      setCollectToken={setCollectToken}
      zIndex="1"
      blurIfNotAuth
      isStarToken
    />
  );
};

export default BalanceCardCashback;
