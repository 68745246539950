import { useState } from 'react';
import { useQuery } from '@apollo/client';

import { IGame } from 'types';
import { IGetGameParams } from 'types/requestTypes';
import { GET_GAME } from 'graphQl/query/games/gameQueries';

const useGame = (requestParams: IGetGameParams, skipFlag?: boolean): IGame | null => {
  const [game, setGame] = useState<IGame>(null);

  useQuery(GET_GAME, {
    fetchPolicy: 'no-cache',
    skip: skipFlag,
    variables: {
      gameParams: {
        ...requestParams,
      },
    },
    onCompleted: (data) => {
      setGame(data.game);
    },
  });

  return game;
};

export default useGame;
