import { gql } from '@apollo/client';

export const GET_WALLET = gql`
  query wallet($input: String!) {
    wallet(tokenCode: $input) {
      availableBalance
      totalWager
      token {
        tokenCode
        displayName
      }
    }
  }
`;

export const GET_WALLET_GAMES = gql`
  query wallet($input: String!) {
    wallet(tokenCode: $input) {
      availableBalance
      token {
        tokenCode
        displayName
      }
    }
  }
`;

export const GET_ALL_WALLETS = gql`
  query wallets {
    wallets {
      availableBalance
      totalWager
      token {
        name
        tokenCode
        displayName
      }
    }
  }
`;
