import { useQuery } from '@apollo/client';

import { GET_EVENTS } from 'graphQl/query/events/events';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setEventsList } from 'store/generalData';
import { generalDataEventsList } from 'store/generalData/generalDtata.selectors';

import Loader from 'components/Base/Loader';
import EventCard from 'components/Pages/EventsPage/components/base/EventCard';

const CardList: React.FC = () => {
  const dispatch = useAppDispatch();

  const eventsList = useAppSelector(generalDataEventsList);

  const { refetch: updateEvents } = useQuery(GET_EVENTS, {
    onCompleted: (data) => {
      dispatch(setEventsList(data.events));
    },
  });

  const handleUpdateEvents = async () => {
    const response = await updateEvents();

    dispatch(setEventsList(response?.data?.events));
  };

  if (!eventsList) return <Loader pages />;

  return (
    <>
      {eventsList.map((card) => (
        <EventCard
          key={card.id}
          image={card.image}
          type={card.type}
          title={card.name}
          description={card.description}
          eventPrizeToken={card.prizeTokenCodeDisplayName}
          prize={card.prize}
          startAt={card.startAt}
          endAt={card.endAt}
          rulesArr={card.rules}
          id={card.id}
          totalWagerTokenCode={card.totalWagerTokenCode}
          onTick={handleUpdateEvents}
        />
      ))}
    </>
  );
};

export default CardList;
