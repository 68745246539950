import { useQuery } from '@apollo/client';

import { GET_HOME_QUERIES } from 'graphQl/query/homePage/homePage';

import HomeTable from 'components/Pages/HomePage/components/HomeTables';
import DocumentTitle from 'components/Base/DocumentTitle';
import { DOCUMENT_TITLE_SETTINGS } from 'components/constants/constants';

import WelcomeBlock from './components/WelcomeBlock';
import Games from './components/Games';
import Statistics from './components/Statistics';
import SeoText from './components/SeoText';
import styles from './styles.module.scss';

const HomePage: React.FC = () => {
  useQuery(GET_HOME_QUERIES, { fetchPolicy: 'cache-and-network' });

  return (
    <div className={styles.homePage}>
      <DocumentTitle data={DOCUMENT_TITLE_SETTINGS.home} />
      <WelcomeBlock />
      <Games />
      <HomeTable />
      <SeoText />
      <Statistics />
    </div>
  );
};

export default HomePage;
