import React from 'react';

import { IIntercomContext } from './types';

const IntercomContext = React.createContext<IIntercomContext>({
  articles: [],
  collections: [],
  loading: false,
  launcherHidden: false,
  setLauncherHidden: () => {},
});

export default IntercomContext;
