import { gql } from '@apollo/client';

export const GET_DEPOSIT_ADDRESS = gql`
  query depositAddress($networkCode: NetworkCode!, $tokenCode: TokenCode!) {
    depositAddress(networkCode: $networkCode, tokenCode: $tokenCode) {
      address
      memo
    }
  }
`;
