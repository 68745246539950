import {
  ELimboCondition,
  FastModeGames,
  HotKeys,
  initialAutoState,
  initialGameSettings,
} from 'components/constants/games';

import { IInitialState } from './types';

export const initialState: IInitialState = {
  fastMode:
    JSON.parse(localStorage.getItem(FastModeGames.limbo)) !== null
      ? JSON.parse(localStorage.getItem(FastModeGames.limbo))
      : true,
  isHotkeys: JSON.parse(localStorage.getItem(HotKeys.limbo)) || false,
  isAuto: false,
  gameId: '',
  betAmountValue: '0.00000000',
  gameCondition: ELimboCondition.notStarted,
  autoState: initialAutoState,
  gameSettings: initialGameSettings,
  historyList: [],
  requestId: '',
  winInfo: null,
  errorsCount: 0,
  optionInputValue: '2',
  switchShip: false,
};
