import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';

import timerIcon from 'assets/img/Lottery/lotteryTimer.webp';

import LotteryDesktopStyle from './styles';
import { ILotteryTimer } from './types';

const LotteryTimer: React.FC<ILotteryTimer> = ({ dateStart, dateEnd, size, onExpire }) => {
  const { hours, minutes, seconds, restart } = useTimer({ expiryTimestamp: new Date(), onExpire: handleExpire });

  const [isEventStarted, setIsEventStarted] = useState(false);

  useEffect(() => {
    if (dateEnd && dateStart) {
      const now = dayjs();
      const newIsEventStarted = dayjs(dateStart).isBefore(now);

      setIsEventStarted(newIsEventStarted);
    }
  }, [dateStart, dateEnd]);

  useEffect(() => {
    if (dateEnd && dateStart) {
      const newCountDownDate = isEventStarted ? dateEnd : dateStart;

      if (newCountDownDate) {
        const newDate = dayjs(newCountDownDate).toDate();

        restart(newDate, true);
      }
    }
  }, [isEventStarted, dateEnd, dateStart]);

  function handleExpire() {
    setTimeout(() => onExpire(), 5000);
  }

  return (
    <LotteryDesktopStyle size={size}>
      <img alt="icon" src={timerIcon} />
      <p>
        {`0${hours}`.slice(-2)}:{`0${minutes}`.slice(-2)}:{`0${seconds}`.slice(-2)}
      </p>
    </LotteryDesktopStyle>
  );
};

export default LotteryTimer;
