import React from 'react';

import monsterImage from 'assets/img/common/monster.webp';

import { IBlockedScreen } from './types';

const BlockedScreen: React.FC<IBlockedScreen> = ({ onClick }) => (
  <div className="game__blocked">
    <div className="error__title">
      <h3>Dear StarBetter</h3>
      <p>Due to legal reasons, this game is not available for players residing in this jurisdiction.</p>
      <p>We apologize for any inconvenience.</p>
    </div>
    <div>
      <img alt="icon" src={monsterImage} className="error_image" />
    </div>
    <div className="game-screen-close-mob" onClick={onClick}>
      <span>CLOSE</span>
    </div>
  </div>
);

export default BlockedScreen;
