import clsx from 'clsx';

import { ReactComponent as SearchIcon } from 'assets/img/common/searchIcon.svg';
import { ReactComponent as CrossIcon } from 'assets/img/common/cross.svg';

import { FAQ_SORT_OPTIONS } from 'components/constants/faq';

import Select from 'components/Base/Select';

import styles from './styles.module.scss';
import { IFAQToolbar } from './types';

const FAQToolbar: React.FC<IFAQToolbar> = ({ sort, search, onChangeSort, onChangeSearch }) => {
  return (
    <nav className={styles.wrapper}>
      <div className={styles.sort}>
        <Select
          label="Sort By"
          labelClassName={styles.label}
          labelTextClassName={styles.labelText}
          selected={sort}
          options={FAQ_SORT_OPTIONS}
          onSelect={(s) => onChangeSort(s)}
        />
      </div>
      <div className={clsx(styles.searchWrapper, search ? styles.expanded : '')}>
        {search ? (
          <button type="button" className={styles.reset} onClick={() => onChangeSearch('')}>
            <CrossIcon />
          </button>
        ) : null}
        <input
          className={styles.search}
          type="text"
          placeholder="Search"
          value={search}
          onChange={(ev) => onChangeSearch(ev.target.value)}
        />
        <SearchIcon className={styles.searchIcon} />
      </div>
    </nav>
  );
};

export default FAQToolbar;
