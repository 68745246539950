import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { pathOr } from 'ramda';

import { ITransaction } from 'types';
import { USER_WHEEL_HISTORY } from 'graphQl/query/wheel/wheel';
import { useLocalization } from 'components/Internationalization';

import WheelStatisticsItem from '../WheelStatisticsItem';

import styles from './styles.module.scss';
import { WheelHistoryType } from '../../types';

const WheelStatisticsMy: React.FC = () => {
  const { translate } = useLocalization();

  const [transactions, setTransactions] = useState<ITransaction[]>([]);

  const { data } = useQuery(USER_WHEEL_HISTORY, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (data) {
      const newHistory = pathOr([], ['userWheelHistory'], data);

      setTransactions(newHistory);
    }
  }, [data]);

  return (
    <ul className={styles.wrapper}>
      <li className={styles.header}>
        <span className={styles.date}>{translate('popups.wheel.statistic.date')}</span>
        <span className={styles.prize}>{translate('popups.wheel.recent.prize')}</span>
      </li>
      {transactions.map((t) => (
        <WheelStatisticsItem key={t.id} type={WheelHistoryType.my} transaction={t} />
      ))}
      {!transactions.length ? (
        <li className={styles.noWins}>{translate('popups.wheel.statistic.description')}</li>
      ) : null}
    </ul>
  );
};

export default WheelStatisticsMy;
