import youtubeIcon from 'assets/img/common/YoutubeLogo.svg';
import { useLocalization } from 'components/Internationalization';

import ButtonLink from 'components/common/ButtonLink';

import styles from './styles.module.scss';

const DividendsButtonLink: React.FC = () => {
  const { translate } = useLocalization();
  return (
    <div className={styles.wrapper}>
      <ButtonLink
        icon={youtubeIcon}
        link="https://youtu.be/P-DamfMjcIg"
        title={translate('dividends.link.button.title')}
        subtitle={translate('dividends.link.button.subtitle')}
      />
    </div>
  );
};

export default DividendsButtonLink;
