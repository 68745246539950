import React from 'react';
import { useQuery } from '@apollo/client';

import { useAppSelector } from 'hooks/useAppSelector';
import { CASHBACK_QUERIES_AUTH } from 'graphQl/query/cashback/cashbackQueriesAuth';
import { DOCUMENT_TITLE_SETTINGS } from 'components/constants/constants';

import DocumentTitle from 'components/Base/DocumentTitle';
import PageTitleShadow from 'components/Base/PageTitleShadow';
import { PageShadow } from 'components/constants/pages';
import { userToken } from 'store/user/user.selectors';

import Title from './components/Title';
import CardBlock from './components/CardBlock';
import CardBlockSecond from './components/CardBlockSecond';
import CashbackTable from './components/CashbackTable';
import styles from './styles.module.scss';

const CashbackPage: React.FC = () => {
  const token = useAppSelector(userToken);

  useQuery(CASHBACK_QUERIES_AUTH, {
    fetchPolicy: 'cache-and-network',
    skip: !token,
  });

  return (
    <div className={styles.cashbackWrap}>
      <DocumentTitle data={DOCUMENT_TITLE_SETTINGS.cashback} />
      <Title />
      <PageTitleShadow background={PageShadow.cashback} />
      <CardBlock />
      <CardBlockSecond />
      <CashbackTable />
    </div>
  );
};

export default React.memo(CashbackPage);
