import React from 'react';

import { useAppSelector } from 'hooks/useAppSelector';
import { generalDataRanksList } from 'store/generalData/generalDtata.selectors';

import RankSystem from './components/RankSystem';
import MyRank from './components/MyRank';
import styles from './styles.module.scss';

const CardBlockSecond: React.FC = () => {
  const ranksList = useAppSelector(generalDataRanksList);

  if (!ranksList) return null;

  return (
    <div className={styles.cashbackCardBlockSecondWrap}>
      <MyRank rankData={ranksList} />
      <RankSystem rankList={ranksList} />
    </div>
  );
};

export default CardBlockSecond;
