import { IProgressBarCircle } from './types';
import { CircleStyle } from './style';
import styles from './styles.module.scss';

const ProgressBarCircle: React.FC<IProgressBarCircle> = ({ progresswidth, start, top, right, left, bottom }) => (
  <CircleStyle
    progresswidth={progresswidth}
    start={start}
    left={left}
    right={right}
    top={top}
    bottom={bottom}
    className={styles.progressBarCircle}
  >
    <div />
  </CircleStyle>
);

export default ProgressBarCircle;
