import React from 'react';

import { DOCUMENT_TITLE_SETTINGS } from 'components/constants/constants';
import { MinesProvider } from 'context/contextMines/contextMines';

import DocumentTitle from 'components/Base/DocumentTitle';

import MinesGame from './MinesGame';

const Mines: React.FC = () => {
  return (
    <MinesProvider>
      <DocumentTitle data={DOCUMENT_TITLE_SETTINGS.mines} />
      <MinesGame />
    </MinesProvider>
  );
};

export default React.memo(Mines);
