import previewImage from 'assets/img/Missions/mission_info_image.webp';
import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';

const MissionsInfo: React.FC = () => {
  const { translate } = useLocalization();
  return (
    <article className={styles.wrapper}>
      <div className={styles.imageWrapper}>
        <img className={styles.image} src={previewImage} alt="preview" />
      </div>
      <h5 className={styles.title}>{translate('popups.missions.info.title')}</h5>
      <p className={styles.description}>{translate('popups.missions.description.top')}</p>
      <p className={styles.description}>{translate('popups.missions.description.middle')}</p>
      <p className={styles.description}>{translate('popups.missions.description.bottom')}</p>
    </article>
  );
};

export default MissionsInfo;
