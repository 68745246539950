import i18n from 'i18next';
import backend from 'i18next-http-backend';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { initReactI18next, I18nextProvider, useTranslation } from 'react-i18next';

import config from 'config';
import { getCurrentLanguageFromPath } from 'func/localization';

import Loader from 'components/Base/Loader';

import { Language } from './types';

const { i18nDebug } = config;

const LANGUAGES = Object.values(Language);

const InternationalizationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [initialized, setInitialized] = useState(false);

  const initTranslation = async () => {
    const currentLanguage = getCurrentLanguageFromPath(LANGUAGES);

    i18n
      .use(initReactI18next)
      .use(backend)
      .init({
        debug: i18nDebug,
        partialBundledLanguages: true,
        resources: {},
        fallbackLng: Language.english,
        supportedLngs: LANGUAGES,
        backend: {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      })
      .then(() => {
        const language = currentLanguage ? currentLanguage.replace('/', '') : Language.english;

        i18n.changeLanguage(language);

        setInitialized(true);
      })
      .catch(() => setInitialized(false));
  };

  useEffect(() => {
    initTranslation();
  }, []);

  return initialized ? <I18nextProvider i18n={i18n}>{children}</I18nextProvider> : <Loader pages />;
};

export const useLocalization = () => {
  const { i18n, t } = useTranslation();
  const { pathname } = useLocation();

  const [language, setLanguage] = useState(Language.english);

  useEffect(() => {
    if (i18n.language !== language) {
      setLanguage(i18n.language as Language);
    }
  }, [i18n.language]);

  const handleTranlsate = (key: string): string => {
    try {
      return t(key);
    } catch {
      return key;
    }
  };

  const handleTranslateCustomNamespace = (key: string, ns: string): Promise<string> | string => {
    try {
      const loaded = i18n.hasLoadedNamespace(ns);

      if (!loaded) {
        return i18n.loadNamespaces(ns).then(() => {
          return t(key, { ns });
        });
      }

      return t(key, { ns });
    } catch {
      return key;
    }
  };

  const changeLanguage = (newLanguage: string, newUrl: string) => {
    i18n.changeLanguage(newLanguage);
    window.location.replace(newUrl);
  };

  const handleChangeLanguage = async (newLanguage: string) => {
    const currentLanguage = getCurrentLanguageFromPath(LANGUAGES);

    if (newLanguage === Language.english) {
      const newUrl = currentLanguage ? pathname.replace(currentLanguage, '') : pathname;

      changeLanguage(newLanguage, newUrl);
      return;
    }

    const urlLanguage = `/${newLanguage}`;
    const newUrl = currentLanguage ? pathname.replace(currentLanguage, urlLanguage) : urlLanguage + pathname;

    changeLanguage(newLanguage, newUrl);
  };

  return {
    language,
    translate: handleTranlsate,
    translateCustomNamespace: handleTranslateCustomNamespace,
    onChangeLanguage: handleChangeLanguage,
  };
};

export default InternationalizationProvider;
