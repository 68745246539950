import React from 'react';

import { useMines } from 'context/contextMines/contextMines';
import bombImage from 'assets/img/Mines/bomb.webp';
import { useLocalization } from 'components/Internationalization';

import MinesDiamondBombItem from './MinesDiamondBombItem';

const MinesBomb: React.FC = () => {
  const { translate } = useLocalization();
  const [state] = useMines();
  const { minesSelected } = state;
  return <MinesDiamondBombItem image={bombImage} count={minesSelected} title={translate('mines.bomb.text')} />;
};

export default MinesBomb;
