import { useState } from 'react';

import config from 'config';
import { ButtonType } from 'components/Base/Button/types';

import Button from 'components/Base/Button';

import PasswordStyle from './styles';
import { IPasswordScreen } from './types';

const { devPassword } = config;

const Password: React.FC<IPasswordScreen> = ({ setPassword }) => {
  const [pass, setPass] = useState('');

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setPass(ev.target.value);
  };

  const handleSubmit = () => {
    if (pass.toLowerCase() === devPassword) {
      sessionStorage.setItem('password', devPassword);

      setPassword(devPassword);
    }
  };

  return (
    <PasswordStyle>
      <div>
        <input
          type="text"
          value={pass}
          placeholder="password"
          onChange={handleChange}
          onKeyDown={(ev) => (ev.key === 'Enter' ? handleSubmit() : null)}
        />
      </div>
      <div>
        <Button variant={ButtonType.primary} type="submit" title="SUBMIT" width="144px" onclick={handleSubmit} />
      </div>
    </PasswordStyle>
  );
};

export default Password;
