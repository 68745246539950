import { useLocalization } from 'components/Internationalization';

import warningIcon from 'assets/img/common/warning-icon.webp';

import { IWarningBlock } from './types';
import styles from './styles.module.scss';

const WarningBlock: React.FC<IWarningBlock> = ({ text }) => {
  const { translate } = useLocalization();

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <img className={styles.icon} src={warningIcon} alt="icon" />
        <h5 className={styles.title}>{translate('popups.wallet.withdraw.memo.warning.title')}</h5>
      </div>
      <p className={styles.desc}>{translate(text)}</p>
    </div>
  );
};

export default WarningBlock;
