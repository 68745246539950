import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';
import { IWheelOfFortuneInfo } from './types';
import {
  WHEEL_MAIN_IMAGE_BG_NAME,
  WHEEL_MAIN_IMAGE_MOB_NAME,
  WHEEL_MAIN_IMAGE_NAME,
  WHEEL_REWARD_IMAGE_BG_NAME,
} from '../../constants';

const WheelOfFortuneInfo: React.FC<IWheelOfFortuneInfo> = ({ className, wheelUrl, rewards }) => {
  const { translate } = useLocalization();

  const [maxReward, setMaxReward] = useState({ token: 'STAR', amount: '0' });
  const [mainImage, setMainImage] = useState('');
  const [mainBgImage, setMainBgImage] = useState('');
  const [rewardBgImage, setRewardBgImage] = useState('');

  const mobile = useMediaQuery({ query: '(max-width: 1100px)' });

  useEffect(() => {
    if (wheelUrl) {
      const newMainImage = `${wheelUrl}${!mobile ? WHEEL_MAIN_IMAGE_NAME : WHEEL_MAIN_IMAGE_MOB_NAME}`;
      const newMainImageBg = `${wheelUrl}${WHEEL_MAIN_IMAGE_BG_NAME}`;
      const newRewardImageBg = `${wheelUrl}${WHEEL_REWARD_IMAGE_BG_NAME}`;

      setMainImage(newMainImage);
      setMainBgImage(newMainImageBg);
      setRewardBgImage(newRewardImageBg);
    }
  }, [wheelUrl, mobile]);

  useEffect(() => {
    const newMaxReward = rewards[rewards.length - 1];

    if (newMaxReward) {
      setMaxReward({ token: newMaxReward.displayName, amount: newMaxReward.prize });
    }
  }, [rewards]);

  return (
    <div className={clsx(styles.wrapper, className)}>
      <StyledMainImage className={styles.mainImage} $bgImage={mainBgImage}>
        {mainImage ? <img className={styles.image} src={mainImage} /> : null}
      </StyledMainImage>
      <StyledRewardImage className={styles.winInfo} $bgImage={rewardBgImage}>
        <span className={styles.text}>{translate('popups.wheel.spin.and.win')}</span>
        <p className={styles.amount}>
          {maxReward.amount} {maxReward.token}
        </p>
      </StyledRewardImage>
    </div>
  );
};

export default WheelOfFortuneInfo;

const StyledMainImage = styled.div.withConfig({
  shouldForwardProp: (prop) => !prop.includes('$'),
})<{ $bgImage: string }>`
  ${({ $bgImage }) => ($bgImage ? `background-image: url(${$bgImage});` : '')}
`;

const StyledRewardImage = styled.div.withConfig({
  shouldForwardProp: (prop) => !prop.includes('$'),
})<{ $bgImage: string }>`
  ${({ $bgImage }) => ($bgImage ? `background-image: url(${$bgImage});` : '')}
`;
