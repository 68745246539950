import { useState } from 'react';
import { useMutation } from '@apollo/client';

import vaultConfirmIcon from 'assets/img/PopUps/ConfirmationIcon/vaultConfirm.webp';

import { EVault } from 'components/constants/popUps';
import { popUps } from 'components/constants/constants';
import { amount, getImgSrc } from 'func/common';
import { DEPOSIT_VAULT, WITHDRAW_VAULT } from 'graphQl/mutations/vault/vault';
import { GET_ALL_WALLETS } from 'graphQl/query/wallet/wallet';
import { GET_VAULTS_LIST } from 'graphQl/query/vault/vault';
import { useLocalization } from 'components/Internationalization';
import { ButtonType } from 'components/Base/Button/types';

import Button from 'components/Base/Button';

import styles from './styles.module.scss';
import { IVaultConfirm } from './types';

const VaultConfirm: React.FC<IVaultConfirm> = ({ data, setActivePopUp, setDataPopUp }) => {
  const { translate } = useLocalization();

  const [errorMessage, setErrorMessage] = useState('');
  const [disable, setDisable] = useState(false);

  const [depositVault] = useMutation(DEPOSIT_VAULT, { refetchQueries: [GET_ALL_WALLETS, GET_VAULTS_LIST] });
  const [withdrawVault] = useMutation(WITHDRAW_VAULT, { refetchQueries: [GET_ALL_WALLETS, GET_VAULTS_LIST] });

  const handleSubmit = async () => {
    setDisable(true);
    setErrorMessage('');
    try {
      const vaultType = data.type === EVault.add ? EVault.add : EVault.withdraw;

      if (data.type === EVault.add) {
        await depositVault({
          variables: {
            input: {
              tokenCode: data.currentToken.token,
              amount: data.balance,
            },
          },
        });
      } else {
        await withdrawVault({
          variables: {
            input: {
              tokenCode: data.currentToken.token,
              amount: data.balance,
            },
          },
        });
      }

      setDataPopUp({ vaultType });
      setActivePopUp(popUps.vault);
      setDisable(false);
    } catch (e) {
      setErrorMessage(e.message);
      setDisable(false);
    }
  };

  return (
    <>
      <div className={styles.vaultConfirmTitle}>{translate('popups.wallet.vault')}</div>
      <img src={vaultConfirmIcon} alt="icon" className={styles.vaultConfirmationImage} />
      <div className={styles.vaultConfirmContent}>
        <div className={styles.vaultConfirmItem}>
          <h4>
            {data.type === EVault.add
              ? `${translate('popups.wallet.vault.deposit')}:`
              : `${translate('popups.wallet.vault.withdraw')}:`}
          </h4>
        </div>
        <div className={styles.vaultConfirmItem}>
          <h4>{translate('popups.wallet.vault.amount')}:</h4>
          <p>
            <img src={getImgSrc(data?.currentToken?.name)} alt="icon" />
            {amount(data.balance)}
          </p>
        </div>
      </div>
      {errorMessage && <h3 className={styles.h3}>{errorMessage}</h3>}
      <Button
        className={styles.button}
        variant={ButtonType.primary}
        title={
          data.type === EVault.add
            ? translate('popups.wallet.vault.buttons.add')
            : translate('popups.wallet.vault.withdraw')
        }
        onclick={handleSubmit}
        disabled={disable}
      />
    </>
  );
};

export default VaultConfirm;
