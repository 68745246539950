export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
  secondaryGreen = 'secondaryGreen',
  secondaryRed = 'secondaryRed',
  secondaryTiny = 'secondaryTiny',
  secondaryBold = 'secondaryBold',
  primaryTiny = 'primaryTiny',
}

export interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  variant: ButtonType;
  title: string;
  width?: string;
  disabled?: boolean;
  onclick?: (values?: any) => void | Promise<void>;
}
