import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_REFERRAL_COLLECTION } from 'graphQl/query/referral/referralCollection';
import { prepareReferralCollections } from 'func/prepareDataReferral';
import TableWithoutPagination from 'components/Base/Table/TableWithoutPagination/TableWithoutPagination';
import { TABLE_CLASSNAMES } from 'components/constants/constants';
import { useLocalization } from 'components/Internationalization';

const CollectionTable: React.FC = () => {
  const { translate, language } = useLocalization();

  const [tableTitles, setTableTitles] = useState([]);

  const { data } = useQuery(GET_REFERRAL_COLLECTION, {
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    const newTitles = [
      { title: translate('dividends.tabs.all.dividends.date'), columnClass: TABLE_CLASSNAMES.column.default },
      { title: translate('dividends.tabs.all.dividends.amount'), columnClass: TABLE_CLASSNAMES.column.default },
    ];

    setTableTitles(newTitles);
  }, [language]);

  return (
    <TableWithoutPagination
      thWidth="50%"
      titles={tableTitles}
      prepareDataTable={prepareReferralCollections}
      data={data?.referralCollections}
      cutCurrency
    />
  );
};

export default CollectionTable;
