import { AUTO_BETTING_STATE } from 'components/constants/constants';
import { amount, BigPlus, BNPlus, prepareAmount, removeComas } from 'func/common';
import Big from 'big.js';
import { bigMinus, bigOrEqual, lessOrEqual, lessThen } from 'func/prepareDataDice';

import statisticsIcon from 'assets/img/Dice/statistics.webp';
import statisticsIconActive from 'assets/img/Dice/statistics-active.webp';
import verifyIcon from 'assets/img/Dice/shield-check.webp';
import rocketStart from 'assets/img/Dice/rocket-launch.webp';
import rulesLimits from 'assets/img/Dice/balance.webp';
import keyBoard from 'assets/img/Dice/keyboard-outline.webp';
import rulesIcon from 'assets/img/Dice/games-rules-icon.webp';
import rocketStartActive from 'assets/img/Dice/rocket-launch-active.webp';
import keyBoardActive from 'assets/img/Dice/keyboard-outline-active.webp';
import { EGamesButtonsBar } from '../components/constants/games';

export interface AutoState {
  betsNumbers: string;
  maxBetAmount: string;
  onLossIncrease: string;
  isOnLossIncrease: boolean;
  onWinIncrease: string;
  isOnWinIncrease: boolean;
  stopOnLossAmount: string;
  stopOnLossAmountDisplay: string;
  stopOnWinAmount: string;
  stopOnWinAmountDisplay: string;
  firstSpin: boolean;
  autoBetAmount: string;
}

export const isAutoBetCanRun = (
  state: AutoState,
  betValue: string,
  minBet: string,
  setter: (name: string, value: string | boolean) => void,
): boolean => {
  const currentBetsNumber = state.betsNumbers ? Number(state.betsNumbers) : -1;
  const currentBetValue = removeComas(betValue);

  const setSettingsFirstSpin = () => {
    setter(AUTO_BETTING_STATE.firstSpin, true);
    setter(AUTO_BETTING_STATE.stopOnWinAmount, state.stopOnWinAmountDisplay);
    setter(AUTO_BETTING_STATE.stopOnLossAmount, state.stopOnLossAmountDisplay);
  };

  if (!currentBetsNumber) {
    setSettingsFirstSpin();
    setter(AUTO_BETTING_STATE.betsNumbers, null);
    return false;
  }
  if (lessThen(currentBetValue, minBet)) {
    setSettingsFirstSpin();
    return false;
  }
  if (state.stopOnLossAmount && lessThen(state.stopOnLossAmount, currentBetValue) && !state.firstSpin) {
    setSettingsFirstSpin();
    return false;
  }
  if (state.stopOnWinAmount && lessOrEqual(state.stopOnWinAmount || '0', 0) && !state.firstSpin) {
    setSettingsFirstSpin();
    return false;
  }
  return true;
};

export const processAutoBetGetValuesForReducer = (
  state: AutoState,
  startBet: string,
  payout: string,
  betAmount: string,
  maxBet: string,
  minBet: string,
): [state: AutoState, betAmount: string] => {
  const currentBetsNumber = state.betsNumbers ? Number(state.betsNumbers) : -1;
  const currentBetValue = removeComas(betAmount, 12);
  const currentMaxBetAmount = removeComas(state.maxBetAmount);
  const responseMinusCurrentBet = bigMinus(payout, currentBetValue);
  const maxBetGlobal = removeComas(maxBet);
  const lostBet = lessThen(payout, currentBetValue);
  const winBet = bigOrEqual(payout, currentBetValue);

  const newAutoState = { ...state };
  let newBetAmount = currentBetValue;

  if (currentBetsNumber) {
    newAutoState[AUTO_BETTING_STATE.betsNumbers] = String(currentBetsNumber - 1);
  }

  if (state.stopOnLossAmountDisplay) {
    const stateValue = !state.stopOnLossAmount.length ? state.stopOnLossAmountDisplay : state.stopOnLossAmount;

    const formattedStopAmount = prepareAmount(stateValue);
    const formattedTotalAmount = prepareAmount(responseMinusCurrentBet);

    if (lostBet) {
      newAutoState[AUTO_BETTING_STATE.stopOnLossAmount] = bigMinus(formattedStopAmount, formattedTotalAmount);
    } else {
      newAutoState[AUTO_BETTING_STATE.stopOnLossAmount] = BigPlus(formattedStopAmount, responseMinusCurrentBet);
    }
  }

  if (state.stopOnWinAmountDisplay) {
    const stateValue = !state.stopOnWinAmount.length ? state.stopOnWinAmountDisplay : state.stopOnWinAmount;

    const formattedStopAmount = prepareAmount(stateValue);
    const formattedTotalAmount = prepareAmount(responseMinusCurrentBet);

    if (lostBet) {
      newAutoState[AUTO_BETTING_STATE.stopOnWinAmount] = BigPlus(formattedStopAmount, formattedTotalAmount);
    } else {
      newAutoState[AUTO_BETTING_STATE.stopOnWinAmount] = bigMinus(formattedStopAmount, formattedTotalAmount);
    }
  }

  if (lostBet && state.isOnLossIncrease && state.onLossIncrease) {
    const setBetAmountNew = () => {
      const currentPrevBet = removeComas(state.firstSpin ? currentBetValue : state.autoBetAmount, 12);
      const percent = Big(state.onLossIncrease).div(100).toFixed(9);
      const increasedOn = Big(currentPrevBet).mul(percent).toFixed(12);
      const increasedValue = BNPlus(currentPrevBet, increasedOn, 12);

      if (
        (state.maxBetAmount && bigOrEqual(increasedValue, currentMaxBetAmount)) ||
        bigOrEqual(increasedValue, maxBetGlobal)
      ) {
        if (
          state.maxBetAmount &&
          lessOrEqual(currentMaxBetAmount, maxBetGlobal) &&
          bigOrEqual(currentMaxBetAmount, minBet)
        ) {
          newAutoState[AUTO_BETTING_STATE.autoBetAmount] = currentMaxBetAmount;
          return String(amount(currentMaxBetAmount));
        }
        newAutoState[AUTO_BETTING_STATE.autoBetAmount] = maxBetGlobal;
        return String(amount(maxBetGlobal));
      }
      newAutoState[AUTO_BETTING_STATE.autoBetAmount] = increasedValue;
      return String(amount(increasedValue));
    };
    newBetAmount = setBetAmountNew();
  } else if (bigOrEqual(payout, 0) && state.isOnLossIncrease && !state.isOnWinIncrease) {
    newAutoState[AUTO_BETTING_STATE.autoBetAmount] = startBet;
    newBetAmount = String(amount(startBet));
  }

  if (winBet && state.isOnWinIncrease && state.onWinIncrease) {
    const setBetAmountNew = () => {
      const currentPrevBet = removeComas(state.firstSpin ? currentBetValue : state.autoBetAmount, 12);
      const percent = Big(state.onWinIncrease).div(100).toFixed(9);
      const increaseOn = Big(currentPrevBet).mul(percent).toFixed(12);
      const increasedValue = BNPlus(currentPrevBet, increaseOn, 12);

      if (
        (state.maxBetAmount && bigOrEqual(increasedValue, currentMaxBetAmount)) ||
        bigOrEqual(increasedValue, maxBetGlobal)
      ) {
        if (state.maxBetAmount && bigOrEqual(currentMaxBetAmount, maxBetGlobal)) {
          newAutoState[AUTO_BETTING_STATE.autoBetAmount] = maxBetGlobal;
          return String(amount(maxBetGlobal));
        }
        if (
          state.maxBetAmount &&
          lessOrEqual(currentMaxBetAmount, maxBet) &&
          bigOrEqual(+currentMaxBetAmount, minBet)
        ) {
          newAutoState[AUTO_BETTING_STATE.autoBetAmount] = currentMaxBetAmount;
          return String(amount(currentMaxBetAmount));
        }
        if (
          state.maxBetAmount &&
          lessOrEqual(currentMaxBetAmount, maxBet) &&
          lessOrEqual(currentMaxBetAmount, minBet)
        ) {
          newAutoState[AUTO_BETTING_STATE.autoBetAmount] = minBet;
          return String(amount(minBet));
        }
        newAutoState[AUTO_BETTING_STATE.autoBetAmount] = maxBetGlobal;
        return String(amount(maxBetGlobal));
      }
      newAutoState[AUTO_BETTING_STATE.autoBetAmount] = increasedValue;
      return String(amount(increasedValue));
    };
    newBetAmount = setBetAmountNew();
  } else if (bigOrEqual(payout, 0) && state.isOnWinIncrease && !state.isOnLossIncrease) {
    newAutoState[AUTO_BETTING_STATE.autoBetAmount] = startBet;
    newBetAmount = String(amount(startBet));
  }
  if (state.firstSpin) {
    newAutoState[AUTO_BETTING_STATE.firstSpin] = false;
  }

  return [newAutoState, prepareAmount(newBetAmount)];
};

export const getButtonsBarImage = (active: boolean, name: string): string => {
  if (active) {
    switch (name) {
      case EGamesButtonsBar.statistic:
        return statisticsIconActive;
      case EGamesButtonsBar.speed:
        return rocketStartActive;
      case EGamesButtonsBar.keys:
        return keyBoardActive;
      case EGamesButtonsBar.verify:
        return verifyIcon;
      case EGamesButtonsBar.limits:
        return rulesLimits;
      case EGamesButtonsBar.rules:
        return rulesIcon;
      default:
        return '';
    }
  }
  switch (name) {
    case EGamesButtonsBar.statistic:
      return statisticsIcon;
    case EGamesButtonsBar.speed:
      return rocketStart;
    case EGamesButtonsBar.keys:
      return keyBoard;
    case EGamesButtonsBar.verify:
      return verifyIcon;
    case EGamesButtonsBar.limits:
      return rulesLimits;
    case EGamesButtonsBar.rules:
      return rulesIcon;
    default:
      return '';
  }
};
