import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

interface Props {
  arrow?: string;
}

export const SecurityCodeContainer = styled.div<Props>`
  font-family: 'Montserrat';
  padding: 25px 0;
  .switch2faOn {
    margin: auto;
    color: #fff;
    max-width: 400px;
  }
  .sc-bdVaJa.ivAnOY,
  .sc-bdVaJa.ivAnOY.first-box {
    background: #00000033;
    width: 40px;
    height: 40px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    border: 2px solid #313e57;
    uotline: none;
    &:focus {
      border: 2px solid #442a81;
      outline: none;
    }
    &:hover {
      border: 2px solid #442a81;
      outline: none;
    }
    input {
      outline: none;
    }
  }
  .hide__input {
    overflow: hidden;
    max-height: 0px;
  }
  .switch2faOff {
    margin: auto;
    color: #fff;
    max-width: 300px;
  }
  .switch2fa__title {
    margin-bottom: 35px;
    font-weight: 800;
    font-size: 36px;
  }
  .switch2fa__inputContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .input-2fa-style {
    background: #00000033;
    width: 40px;
    height: 40px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    border: 2px solid #313e57;
    uotline: none;
    border-radius: 9px;
    margin: 0 5px;
    text-align: center;
    &:focus {
      border: 2px solid #442a81;
      outline: none;
    }
    &:hover {
      border: 2px solid #442a81;
      outline: none;
    }
    input {
      outline: none;
    }
  }
  .switch2fa__link {
    color: inherit;
    font-weight: bold;
    text-decoration: none;
  }
  .switch2fa__submit {
    margin-top: 30px;
  }
  .switch2faOn__subtitle {
    margin-bottom: 20px;
    font-weight: 300;
    font-size: 14px;
    text-align: left;
  }
  .switch2faOff__subtitle {
    margin-bottom: 20px;
    max-width: 288px;
    font-weight: 300;
    font-size: 13px;
    text-align: center;
  }
  .switch2fa__qrImage {
    width: 130px !important;
    height: 130px !important;
    margin-left: 10px;
  }
  .switch2fa__error {
    color: #f00;
    position: absolute;
    right: 15px;
    bottom: 10px;
    text-align: left;
    font-size: 12px;
    margin-bottom: 0;
  }
  .link-body-button-style-tooltip {
    position: absolute;
    left: 18.7%;
    width: 288px;
    height: 67px;
    border-radius: 19px;
    padding: 0 15px;
    background: #0e9628;
    visibility: hidden;
    transform: translateY(-50%);
    transition: all 0.1s ease-in-out;
    transition-duration: 100ms;
    transition-delay: 100ms;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 11px;
    color: #ffff;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .link-body-button-style-tooltip.open {
    visibility: visible;
    transform: translateY(-100%);
  }
  .switch2fa__errorContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    color: #f02f41;
  }
  .switch2fa__queryError {
    color: #f00;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
  }
  .switch2fa__qrContainer {
    display: flex;
    margin: 15px 0 0 0;
  }
  .link__body-copy {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 38px;
    border-radius: 19px;
    background-color: #00000033;
    position: relative;
    margin-bottom: 10px;
  }
  .link__body-copy-dkt {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 210px;
    width: 100%;
    height: 38px;
    color: #fff;
    padding-left: 5%;
    background-color: transparent;
    position: relative;
    border: none;
    outline: none;
    font-size: 14px;
  }
  .link__body-copy-mob {
    display: none;
  }
  .link__body-button {
    position: absolute;
    right: 0;
    img {
      position: absolute;
      right: 73px;
      top: 12px;
    }
  }
  .link-body-button-style {
    position: absolute;
    right: 0;
    width: 37px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 19px;
    background: linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
    box-shadow: -7.9602e-16px 13px 16px rgba(0, 0, 0, 0.2);
    cursor: url(${point}), auto;
    img {
      position: absolute;
      right: 13px;
      top: 13px;
      width: 12px;
      height: 12px;
    }
    &:active {
      border: 4px solid;
    }
  }
  .switch2fa__checkBox {
    font-size: 14px;
    input {
      margin-right: 10px;
    }
  }
  .switch2fa__subtitleContainer {
    displat: flex;
    text-align: left;
    flex-direction: column;
    align-item: flex-start;
    margin-bottom: 10px;
    & > p {
      margin-bottom: 10px;
      font-weight: 300;
      font-size: 14px;
      text-align: left;
    }
  }
  .switch2faOff__support {
    font-weight: 300;
    font-size: 12px;
    max-width: 270px;
    margin: 30px auto 10px auto;
    text-align: center;
    span {
      color: inherit;
      font-size: 12px;
    }
  }
  .qr-code-checkbox {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 10px 0;
  }
  @media (max-width: 1024px) {
    .switch2fa__title {
      margin-bottom: 15px;
      font-weight: 700;
      font-size: 28px;
    }
    .switch2faOn__subtitle {
      margin-bottom: 10px;
      font-size: 12px;
    }
    .switch2fa__subtitleContainer > p {
      font-size: 11px;
    }
    .switch2fa__qrContainer {
      margin: 15px 0 0 0;
    }
    .qr-code-checkbox {
      margin: 0 0 5px 0;
    }
  }
  @media (max-width: 640px) {
    .link-body-button-style-tooltip {
      left: 10%;
    }
  }
  @media screen and (max-width: 540px) {
    padding: 25px;
  }
`;
