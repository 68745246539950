import { gql } from '@apollo/client';

export const GET_TRANSACTIONS = gql`
  query paginatedTransactions($input: PaginationParams!) {
    paginatedTransactions(paginationData: $input) {
      count
      items {
        amount
        createdAt
        info
        status
        token {
          tokenCode
          displayName
        }
        type
        network {
          explorer
        }
      }
    }
  }
`;

export const GET_TRANSACTIONS_TYPES = gql`
  query transactionTypes {
    transactionTypes
  }
`;
