/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducers from './user';
import dataReducers from './data';
import generalDataReducer from './generalData';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['data', 'user'],
};

const rootReducer = combineReducers({
  user: userReducers,
  data: dataReducers,
  generalData: generalDataReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
  const persistor = persistStore(store);

  return { store, persistor };
};
