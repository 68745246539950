import React from 'react';

import { useLimbo } from 'context/contextLimbo/contextLimbo';

import styles from './styles.module.scss';

const LimboWinInfo: React.FC = () => {
  const [state] = useLimbo();

  const { winInfo } = state;

  return <div className={winInfo?.vin ? styles.limboWinInfoWin : styles.limboWinInfoLose}>{winInfo?.value}</div>;
};

export default LimboWinInfo;
