export const WHEEL_TRANSACTIONS = {
  input: {
    dateFrom: null,
    dateTo: null,
    limit: 1,
    offset: 0,
    order: {
      createdAt: 'DESC',
    },
    type: 'Wheel',
  },
};
