import { useMediaQuery } from 'react-responsive';
import { useContext, useEffect } from 'react';

import NotificationContext from 'context/contextNotifications/context';

import NotificationListDesktop from './components/NotificationListDesktop';
import NotificationListMobile from './components/NotificationListMobile';

const NotificationList: React.FC = () => {
  const { notificationListOpen } = useContext(NotificationContext);

  const mobile = useMediaQuery({ query: '(max-width: 1024px)' });

  useEffect(() => {
    const disableScroll = notificationListOpen && mobile;

    document.body.style.setProperty('overflow', disableScroll ? 'hidden' : 'unset');
  }, [notificationListOpen, mobile]);

  if (!notificationListOpen) return null;

  return !mobile ? <NotificationListDesktop /> : <NotificationListMobile />;
};

export default NotificationList;
