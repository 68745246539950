import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_GAME_SETTINGS } from 'graphQl/query/settings/gameSettings';
import { GET_WALLET_GAMES } from 'graphQl/query/wallet/wallet';

import { useAppSelector } from 'hooks/useAppSelector';
import { ContextTokenCode, ContextWallet } from 'context';
import { bigOrEqual, lessThen } from 'func/prepareDataDice';
import { amount } from 'func/common';
import { userProfile } from 'store/user/user.selectors';

const useGameSettings = (gameId: string): { minBet: string; maxBet: string; maxPay: string; betAmount: string } => {
  const profile = useAppSelector(userProfile);

  const { tokenCode } = useContext(ContextTokenCode);
  const { walletUser } = useContext(ContextWallet);

  const [gameSettings, setGameSettings] = useState({
    minBet: '0',
    maxBet: '0',
    maxPay: '0',
    betAmount: '0',
  });
  const [walletAmount, setWalletAmount] = useState(String(walletUser || '0'));

  const { data: settingsData } = useQuery(GET_GAME_SETTINGS, {
    skip: !gameId,
    fetchPolicy: 'cache-and-network',
    variables: {
      input: gameId,
    },
  });

  const { data: walletData, refetch: refetchWallet } = useQuery(GET_WALLET_GAMES, {
    skip: !gameId,
    variables: { input: tokenCode.token },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (tokenCode.token && gameId && profile) {
      setWalletAmount(null);
      refetchWallet();
    }
  }, [tokenCode.token, gameId, profile]);

  useEffect(() => {
    if (walletData) {
      setWalletAmount(walletData?.wallet?.availableBalance);
    }
  }, [walletData]);

  useEffect(() => {
    if (settingsData && walletAmount) {
      const settings = settingsData.gameSettings.find((el) => el.token.tokenCode === tokenCode.token);
      setGameSettings({
        minBet: String(amount(settings.minimumBet)),
        maxBet: settings.maximumBet,
        maxPay: settings.maximumPayout,
        betAmount: '0',
      });
      if (bigOrEqual(walletAmount, settings.defaultBet)) {
        setGameSettings((prev) => ({ ...prev, betAmount: settings.defaultBet }));
      } else if (lessThen(walletAmount, settings.defaultBet) && bigOrEqual(walletAmount, settings.minimumBet)) {
        setGameSettings((prev) => ({ ...prev, betAmount: settings.minimumBet }));
      }
    }
  }, [settingsData, tokenCode, walletAmount]);

  return gameSettings;
};

export default useGameSettings;
