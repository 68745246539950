import styled from 'styled-components';

const StyledEmptyTable = styled.table`
  border: none;
  border-collapse: collapse;
  width: 93%;
  height: 100%;
  margin: 38px 45px 33px 45px;
  caption-side: bottom;
  table-layout: fixed;
  td,
  th {
    border: none;
    height: 50px;
  }
  td {
    padding: 5px 0 5px 31px;
    height: 50px;
    box-sizing: border-box;
  }

  tbody tr {
    height: 50px;
    &:nth-of-type(even) {
      background-color: #00000033;
    }
  }
  thead > tr {
    background-color: #6e70753b;
    border-radius: 19px;
  }
  thead > tr > td {
    width: 100%;
    &:nth-of-type(1) {
      border-bottom-left-radius: 19px;
      border-top-left-radius: 19px;
      background-color: #6e70753b;
    }
    &:nth-last-of-type(1) {
      border-bottom-right-radius: 19px;
      border-top-right-radius: 19px;
      margin: 0;
    }
  }
  tbody > tr > td {
    padding: 5px 0 5px 31px;
    &:nth-last-of-type(1) {
      border-bottom-right-radius: 19px;
      border-top-right-radius: 19px;
      margin: 0;
      padding: 0;
    }
    &:nth-of-type(1) {
      border-bottom-left-radius: 19px;
      border-top-left-radius: 19px;
    }
  }

  @-moz-document url-prefix('') {
    thead > tr {
      background-color: transparent;
    }
    thead > tr > th {
      background-color: #6e70753b;
    }
    tbody tr {
      &:nth-of-type(even) {
        td {
          background-color: #00000033;
        }
        background-color: transparent;
      }
    }
  }
`;

export default StyledEmptyTable;
