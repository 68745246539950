import React from 'react';
import CurrencyRowStyle from 'components/Base/Cards/CurrencyRow/styles';
import { getImgSrc } from 'func/common';

interface Props {
  tokenCode: string;
  displayName: string;
  balanceData: any; // eslint-disable-line
}

const CurrencyRow: React.FC<Props> = ({ tokenCode, balanceData, displayName }) => (
  <CurrencyRowStyle>
    <img src={getImgSrc(displayName)} alt="icon" />
    <h6>{String(balanceData.find((item) => item.src === tokenCode)?.title || String) + ' ' + displayName}</h6>
  </CurrencyRowStyle>
);

export default CurrencyRow;
