import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import Select, { MultiValue, Props } from 'react-select';

import { IOption, ISelect } from './types';
import styles from './styles.module.scss';

const SelectComponent: React.FC<ISelect> = ({ loading, selected, placeholder, options, onSelect }) => {
  const props: Partial<Props<IOption>> = useMemo(
    () => ({
      className: styles.select,
      isSearchable: true,
      menuShouldBlockScroll: true,
      controlShouldRenderValue: true,
      classNames: {
        control: ({ isFocused }) => clsx(styles.control, isFocused ? styles.active : ''),
        input: () => styles.value,
        singleValue: () => styles.value,
        placeholder: () => styles.placeholder,
        menu: () => styles.menu,
        option: ({ isSelected }) => clsx(styles.option, isSelected ? styles.selected : ''),
        indicatorSeparator: () => 'visually-hidden',
      },
    }),
    [],
  );

  const [value, setValue] = useState<IOption>();

  useEffect(() => {
    const option = options.find((o) => o.value === selected);

    setValue(option);
  }, [selected, options]);

  const handleChange = (v: IOption | MultiValue<IOption>) => {
    if (Array.isArray(v)) {
      // pass
      return;
    }

    const newValue = v as IOption;

    setValue(newValue);
    onSelect(newValue.value);
  };

  return (
    <Select
      {...props}
      isLoading={loading}
      value={value}
      placeholder={placeholder}
      options={options}
      onChange={handleChange}
    />
  );
};

export default SelectComponent;
