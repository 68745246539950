import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import { REFERRAL_PAGE_TABLE_TITLES, ReferralPageTablesTitles } from 'components/constants/table';
import { GET_USER } from 'graphQl/query/auth/profile';

import TablesTitles from 'components/common/TablesTitles';
import ActivityTable from './components/ActivityTable';
import CollectionTable from './components/CollectionTable';

import styles from './styles.module.scss';

const ReferralTable: React.FC = () => {
  const [table, setTable] = useState(ReferralPageTablesTitles.activity);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [showDate, setShowDate] = useState(null);
  const [resetIsActive, setResetIsActive] = useState(false);

  const { data: user } = useQuery(GET_USER, { fetchPolicy: 'cache-only' });

  useEffect(() => {
    setDefaultDates();
  }, [user]);

  const handleTitle = (title) => setTable(title);

  const handleChangeDateFrom = (date: unknown) => {
    setResetIsActive(true);
    setDateFrom(date);
  };

  const handleChangeDateTo = (date: unknown) => {
    setResetIsActive(true);
    setDateTo(date);
  };

  function setDefaultDates() {
    if (user) {
      const createdAt = pathOr(undefined, ['profile', 'createdAt'], user);

      if (createdAt) {
        const newDateFrom = dayjs(createdAt).format('DD MMM YYYY');
        const newDateTo = dayjs().format('DD MMM YYYY');

        setDateFrom(newDateFrom);
        setDateTo(newDateTo);
        setResetIsActive(false);

        return [newDateFrom, newDateTo];
      }
    } else {
      const today = dayjs();
      const newDateTo = today.format('DD MMM YYYY');
      const newDateFrom = today.subtract(90, 'days').format('DD MMM YYYY');

      setDateFrom(newDateFrom);
      setDateTo(newDateTo);
      setResetIsActive(false);

      return [newDateFrom, newDateTo];
    }
  }

  return (
    <div className={styles.tableWrapper}>
      <TablesTitles
        currentTitle={table}
        titles={REFERRAL_PAGE_TABLE_TITLES}
        dateFrom={dateFrom}
        dateTo={dateTo}
        showDate={showDate}
        resetIsActive={resetIsActive}
        setDateFrom={handleChangeDateFrom}
        setDateTo={handleChangeDateTo}
        setShowDate={setShowDate}
        onTitle={handleTitle}
        onReset={setDefaultDates}
      />
      <div className={styles.tableWrapperItem}>
        {table === ReferralPageTablesTitles.activity ? <ActivityTable dateFrom={dateFrom} dateTo={dateTo} /> : null}
        {table === ReferralPageTablesTitles.collection ? <CollectionTable /> : null}
      </div>
    </div>
  );
};

export default ReferralTable;
