import ReactPaginate from 'react-paginate';
import { animateScroll as scroll } from 'react-scroll';

import { ReactComponent as ArrowIcon } from 'assets/img/common/arrow.svg';

import { IBlogPagination } from './types';
import styles from './styles.module.scss';

const BlogPagination: React.FC<IBlogPagination> = ({ page, pages, onChangePage }) => {
  const handleChangePage = (currentPage: number) => {
    scroll.scrollTo(0, { duration: 0, delay: 0 });

    onChangePage(currentPage + 1);
  };

  return pages > 1 ? (
    <ReactPaginate
      containerClassName={styles.wrapper}
      breakClassName={styles.break}
      pageClassName={styles.page}
      pageLinkClassName={styles.pageLink}
      activeClassName={styles.active}
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      forcePage={page}
      pageCount={pages}
      onPageChange={({ selected }) => handleChangePage(selected)}
      previousLabel={page !== 0 ? <ArrowIcon className={styles.previous} /> : null}
      nextLabel={page !== pages - 1 ? <ArrowIcon className={styles.next} /> : null}
    />
  ) : null;
};

export default BlogPagination;
