import ticketIcon from 'assets/img/Lottery/ticketIcon.webp';

import { getImgSrc } from 'func/common';
import { getIsDisplayLotteryHeader } from 'func/prepareDataLottery';
import { useLocalization } from 'components/Internationalization';

import LotteryTimer from 'components/Games/Lottery/base/LotteryTimer';

import styles from './styles.module.scss';
import { ILotteryHeader } from './types';

const LotteryHeaderLayout: React.FC<ILotteryHeader> = ({
  lotteryRoundDisplayName,
  lotteryRoundPrize,
  dateEnd,
  dateStart,
  timerSize,
  onClick,
  onExpire,
  path,
}) => {
  const { translate } = useLocalization();

  if (getIsDisplayLotteryHeader(path)) return null;

  return (
    <div className={styles.lotteryHeader}>
      <img src={ticketIcon} alt="icon" className={styles.lotteryHeaderImg} />
      <LotteryTimer dateStart={dateStart} dateEnd={dateEnd} size={timerSize} onExpire={onExpire} />
      <div className={styles.lotteryHeaderPrize}>
        {lotteryRoundDisplayName ? <img src={getImgSrc(lotteryRoundDisplayName)} alt="icon" /> : null}
        <p>{lotteryRoundPrize}</p>
      </div>
      <div className={styles.lotteryHeaderButton}>
        <button type="button" onClick={onClick}>
          {translate('header.lottery')}
        </button>
      </div>
    </div>
  );
};

export default LotteryHeaderLayout;
