import { HistoryElem } from 'types';

export interface IInitialState {
  fastMode: boolean;
  isAuto: boolean;
  isHotkeys: boolean;
  gameId: string;
  betAmountValue: string;
  minesSelected: number;
  currentSliderNumber: number;
  historyList: HistoryElem[];
  gameSettings: IGameSettings;
  autoState: IMinesAutoState;
  gameCondition: string;
  sliderData: { 1: number[] };
  errorsCount: number;
  squaresData: ISquaresData[];
  autoPickItems: number[];
  requestId: string;
  winInfo: IWinInfoMines;
  hash: string;
}

export interface IAction {
  type: string;
  payload?: any;
}

export interface IGameSettings {
  minBet: string;
  maxBet: string;
  maxPay: string;
  betAmount?: string;
}

export interface IMinesAutoState {
  betsNumbers: string;
  maxBetAmount: string;
  onLossIncrease: string;
  isOnLossIncrease: boolean;
  onWinIncrease: string;
  isOnWinIncrease: boolean;
  stopOnLossAmount: string;
  stopOnLossAmountDisplay: string;
  stopOnWinAmount: string;
  stopOnWinAmountDisplay: string;
  firstSpin: boolean;
  autoBetAmount: string;
}

export interface ISquaresData {
  id: string;
  item: number;
  condition: string;
}

export interface IWinInfoMines {
  amount: string;
  multiplier: string;
  bigWin: boolean;
}

export enum EBetResult {
  diamond = 'diamond',
}
