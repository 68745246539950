import React from 'react';

import { useMines } from 'context/contextMines/contextMines';
import { Types } from 'context/contextMines/minesReduser';
import { getDisableCheckbox } from 'func/prepareDataMines';

import AutoSwitch from 'components/Games/base/AutoSwitch';

import styles from './styles.module.scss';

const MinesAutoSwitch: React.FC = () => {
  const [state, dispatch] = useMines();

  const { isAuto, gameCondition } = state;

  const handleIsAutoMode = () => dispatch({ type: Types.IS_AUTO });

  return (
    <div className={styles.minesAutoSwitchWrap}>
      <AutoSwitch checked={isAuto} onCheck={handleIsAutoMode} disabled={getDisableCheckbox(gameCondition)} />
    </div>
  );
};

export default MinesAutoSwitch;
