import { useEffect, useState } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';

import { useLocalization } from 'components/Internationalization';
import { getPathWithLanguage } from 'func/localization';

import { ILangNavLink } from './types';

const NavLink: React.FC<ILangNavLink> = ({ className = '', to, children, ...props }) => {
  const { language } = useLocalization();

  const [path, setPath] = useState(to);

  useEffect(() => {
    if (typeof to === 'string') {
      const newPath = getPathWithLanguage(to, language);

      setPath(newPath);
      return;
    }

    const currentPathname = to.pathname;
    const newPath = getPathWithLanguage(currentPathname, language);
    const newTo = { ...to, pathname: newPath };

    setPath(newTo);
  }, [to, language]);

  return (
    <RouterNavLink className={className} to={path} {...props}>
      {children}
    </RouterNavLink>
  );
};

export default NavLink;
