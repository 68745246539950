import { useEffect } from 'react';

import Button from 'components/Base/Button';

import { IButtonSubmit } from './types';

const ButtonSubmit: React.FC<IButtonSubmit> = ({
  className = '',
  handleButton,
  disabled,
  variant,
  width,
  title,
  type,
  color,
  dependencies,
}) => {
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleButton();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [dependencies]);

  return (
    <Button
      className={className}
      variant={variant}
      width={width}
      title={title}
      onclick={handleButton}
      color={color}
      type={type || 'submit'}
      disabled={disabled}
    />
  );
};

export default ButtonSubmit;
