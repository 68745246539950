import { useContext, useEffect, useState } from 'react';

import giftIcon from 'assets/img/common/giftHand.webp';

import { useAppSelector } from 'hooks/useAppSelector';
import { ContextPopUps } from 'context';
import { IUserBonus } from 'components/Pages/BoonusPage/components/UserBonus/types';
import { amount, getImgSrc } from 'func/common';
import { ButtonType } from 'components/Base/Button/types';
import { dataUserBonuses } from 'store/data/data.selectors';
import { useLocalization } from 'components/Internationalization';

import Button from 'components/Base/Button';

import styles from './styles.module.scss';
import { IBonusCollect } from './types';

const BonusCollect: React.FC<IBonusCollect> = ({ data }) => {
  const { translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [currentBonus, setCurrentBonus] = useState<IUserBonus>(null);

  const bonusList = useAppSelector(dataUserBonuses);

  useEffect(() => {
    if (bonusList) {
      setCurrentBonus(bonusList.find((el) => el.id === data));
    }
  }, [bonusList]);

  const handleClose = () => setPopUpsOpen(null);

  if (!currentBonus) return null;

  return (
    <div className={styles.bonusCollectWrap}>
      <img src={giftIcon} alt="gift" className={styles.bonusCollectImg} />
      <h2>{translate('popups.bonus.collect.title')}</h2>
      <h3>
        {currentBonus.bonus.name} {translate('popups.bonus.collect.desc')}
      </h3>
      <h4>
        <img src={getImgSrc(currentBonus.displayName)} alt={currentBonus.displayName} />
        {`${amount(currentBonus.amount)} ${currentBonus.displayName}`}
      </h4>
      <Button
        className={styles.button}
        variant={ButtonType.primary}
        title={translate('general.buttons.close')}
        width="288px"
        onclick={handleClose}
      />
    </div>
  );
};

export default BonusCollect;
