import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { useAppSelector } from 'hooks/useAppSelector';
import { useLimbo } from 'context/contextLimbo/contextLimbo';
import useGame from 'hooks/useGame';
import { Types } from 'context/contextLimbo/limboReduser';
import { GET_BETS_HISTORY } from 'graphQl/query/betsPage/betsHistory';
import { EGamesSlugName } from 'components/constants/games';
import { userProfile, userToken } from 'store/user/user.selectors';

import AllGameHistoryNew from 'components/Games/base/AllGameHistoryNew';

const LimboHistory: React.FC = () => {
  const token = useAppSelector(userToken);
  const user = useAppSelector(userProfile);

  const [state, dispatch] = useLimbo();

  const { gameId, historyList } = state;
  const limboGame = useGame({ slug: EGamesSlugName.limbo, providerName: 'In-House', userId: user?.id || null }, !token);

  const { data: gameHistoryResponse } = useQuery(GET_BETS_HISTORY, {
    skip: !token || !gameId,
    variables: {
      input: {
        limit: 28,
        offset: 0,
        gameId,
      },
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (gameHistoryResponse) {
      dispatch({ type: Types.SET_FIRST_HISTORY, payload: { historyList: gameHistoryResponse.userBets.items } });
    }
  }, [gameHistoryResponse]);

  useEffect(() => {
    if (limboGame) {
      dispatch({ type: Types.GAME_ID, payload: { gameId: limboGame.id } });
    }
  }, [limboGame]);

  return <AllGameHistoryNew data={historyList} />;
};

export default LimboHistory;
