import { TABLE_CLASSNAMES } from 'components/constants/constants';
import bonusBanner from 'assets/img/BonusPage/bonus-banner.webp';
import bonusBannerFreeSpin from 'assets/img/BonusPage/bonus-banner-free-spin.webp';
import {
  BonusNotAuth,
  BonusType,
  IFreeSpinsSettings,
  IUserBonus,
  UserBonusStatus,
} from 'components/Pages/BoonusPage/components/UserBonus/types';
import { TableElem } from 'types/requestTypes';
import { amount, getDate, getImgSrc } from 'func/common';
import { ButtonType } from 'components/Base/Button/types';
import { TranslationType } from '../types';

export const getUserBonusCardButtonVariant = (status: string): ButtonType => {
  switch (status) {
    case UserBonusStatus.active:
      return ButtonType.secondaryGreen;
    default:
      return ButtonType.primary;
  }
};

export const getUserBonusCardButtonTitle = (status: string, type: string, currentSpins: number): string => {
  if (currentSpins === 0 && status !== UserBonusStatus.active) return 'popups.wallet.nav.deposit';
  if (type === BonusType.freeSpins && status !== UserBonusStatus.active)
    return 'bonus.card.front.button.activate.spins';
  switch (status) {
    case UserBonusStatus.active:
      return 'popups.mission.prize.button';
    case UserBonusStatus.collected:
      return 'popups.collect.collected';
    default:
      return 'popups.wallet.nav.deposit';
  }
};

const getTextClass = (status: string): string => {
  switch (status) {
    case UserBonusStatus.expired:
      return TABLE_CLASSNAMES.text.statusExpired;
    case UserBonusStatus.active:
      return TABLE_CLASSNAMES.text.statusExpired;
    case UserBonusStatus.cancelled:
      return TABLE_CLASSNAMES.text.statusCanceled;
    default:
      return TABLE_CLASSNAMES.text.statusCollected;
  }
};

const getTranslateTitleBonusTable = (status: string): string => {
  switch (status) {
    case UserBonusStatus.collected:
      return 'bonus.table.status.collected';
    case UserBonusStatus.cancelled:
      return 'bonus.table.status.canceled';
    default:
      return 'bonus.table.status.expired';
  }
};

export const prepareDataUserBonusHistory = (array: IUserBonus[], t: TranslationType): TableElem[][] => {
  const dateNow = new Date();
  const filtered = array.filter(
    (el) =>
      el.status === UserBonusStatus.cancelled ||
      el.status === UserBonusStatus.expired ||
      el.status === UserBonusStatus.collected ||
      (el.status === UserBonusStatus.active &&
        (el.endAt ? Date.parse(el.endAt.toString()) < Date.parse(dateNow.toString()) : el.endAt)),
  );
  const properData = [];
  filtered.forEach((elem) => {
    const bonusElem = [];

    const date = {
      title: getDate(elem.startAt),
      columnClass: TABLE_CLASSNAMES.column.default,
      textClass: TABLE_CLASSNAMES.text.dateFormat,
    };
    bonusElem.push(date);

    const name = {
      title: elem?.bonus?.name,
      columnClass: TABLE_CLASSNAMES.column.hideMobile,
      textClass: TABLE_CLASSNAMES.text.cutText,
    };
    bonusElem.push(name);

    const amountBonus = {
      title: elem.amount ? `${amount(elem.amount)} ${elem.displayName || ''}` : '-',
      src: getImgSrc(elem.displayName),
      columnClass: TABLE_CLASSNAMES.column.default,
      textClass: TABLE_CLASSNAMES.text.default,
      imageClass: TABLE_CLASSNAMES.images.currency,
    };
    bonusElem.push(amountBonus);

    bonusElem.push({
      title: t(getTranslateTitleBonusTable(elem.status)),
      columnClass: TABLE_CLASSNAMES.column.default,
      textClass: getTextClass(elem.status),
    });
    properData.push(bonusElem);
  });
  return properData;
};

export const getMyBonusList = (list: IUserBonus[]): IUserBonus[] => {
  const dateNow = new Date();
  const filteredByStatus = list.filter(
    (el) => el.status !== UserBonusStatus.expired && el.status !== UserBonusStatus.cancelled,
  );
  const filteredByDate = filteredByStatus.filter(
    (el) => el.endAt === null || Date.parse(el.endAt.toString()) > Date.parse(dateNow.toString()),
  );
  return filteredByDate;
};

export const getMyBonusCount = (list: IUserBonus[]): number => {
  const dateNow = new Date();
  const filteredByStatus = list.filter(
    (el) =>
      el.status !== UserBonusStatus.expired &&
      el.status !== UserBonusStatus.cancelled &&
      el.status !== UserBonusStatus.collected,
  );
  const filteredByDate = filteredByStatus.filter(
    (el) => el.endAt === null || Date.parse(el.endAt.toString()) > Date.parse(dateNow.toString()),
  );
  return filteredByDate.length;
};

export const getMyBonusCardsList = (list: IUserBonus[]): IUserBonus[] => {
  const dateNow = new Date();
  const filteredByStatus = list.filter(
    (el) =>
      el.status !== UserBonusStatus.expired &&
      el.status !== UserBonusStatus.cancelled &&
      el.status !== UserBonusStatus.collected,
  );
  const filteredByDate = filteredByStatus.filter(
    (el) => el.endAt === null || Date.parse(el.endAt.toString()) > Date.parse(dateNow.toString()),
  );
  return filteredByDate;
};

export const getBonusList = (list: BonusNotAuth[]): BonusNotAuth[] => {
  return list.filter((el) => el.status === UserBonusStatus.active);
};

export const getAvailableBonus = (list: IUserBonus[]): IUserBonus[] => {
  const dateNow = new Date();
  const available = list.filter(
    (el) => el.status === UserBonusStatus.available && Date.parse(el.endAt.toString()) > Date.parse(dateNow.toString()),
  );
  return available || null;
};

export const getAvailableActiveBonuses = (list: IUserBonus[]): IUserBonus[] => {
  const dateNow = new Date();
  const available = list.filter(
    (el) =>
      (el.status === UserBonusStatus.available && Date.parse(el.endAt.toString()) > Date.parse(dateNow.toString())) ||
      (el.status === UserBonusStatus.active &&
        (el.endAt ? Date.parse(el.endAt.toString()) > Date.parse(dateNow.toString()) : !el.endAt)),
  );
  return available || null;
};

export const getMyActiveBonuses = (list: IUserBonus[]): IUserBonus[] => {
  const dateNow = new Date();
  const activeBonuses = list.filter((el) => {
    return (
      el.status === UserBonusStatus.active &&
      el.endAt &&
      Date.parse(el.endAt.toString()) > Date.parse(dateNow.toString())
    );
  });

  return activeBonuses.length ? activeBonuses : null;
};

export const getActiveBonus = (list: IUserBonus[], token: string): IUserBonus => {
  const dateNow = new Date();
  const bonus = list.find(
    (el) =>
      el.status === UserBonusStatus.active &&
      el.tokenCode === token &&
      Date.parse(el.endAt.toString()) > Date.parse(dateNow.toString()),
  );
  return bonus;
};

export const getBonusesDeposit = (bonuses: IUserBonus[]): IUserBonus[] => {
  const dateNow = new Date();
  const filterByDate = bonuses.filter(
    (el) => el.endAt === null || Date.parse(el.endAt.toString()) > Date.parse(dateNow.toString()),
  );
  const res = filterByDate.filter(
    (el) =>
      (el?.bonus?.type === BonusType.depositMatch && el?.status === UserBonusStatus.available) ||
      (el?.bonus?.type === BonusType.freeSpins &&
        el?.userFreespin?.numberOfSpins === 0 &&
        el?.status !== UserBonusStatus.active &&
        el?.status !== UserBonusStatus.collected),
  );
  return res;
};

export const getBonusBanner = (type: string): string => {
  switch (type) {
    case BonusType.freeSpins:
      return bonusBannerFreeSpin;
    case BonusType.depositMatch:
      return bonusBanner;
    default:
      return bonusBanner;
  }
};

export const isDisplayCardIngo = (type: string, status: string, token: string): boolean => {
  if (type === BonusType.freeSpins && !token) return false;
  if (type === BonusType.freeSpins && (status === UserBonusStatus.available || status === UserBonusStatus.selected))
    return false;
  return true;
};

export const getFreeSpinsSettings = (list: IUserBonus[]): IFreeSpinsSettings => {
  const freeSpinsBonus = list.find(
    (el) => el?.bonus?.type === BonusType.freeSpins && el?.status === UserBonusStatus.available,
  );
  if (freeSpinsBonus?.userFreespin?.numberOfSpins > 0) {
    return {
      ...freeSpinsBonus.bonus.freeSpinSetting,
      numberOfSpins: freeSpinsBonus.userFreespin.numberOfSpins,
    };
  }
  return null;
};

export const checkIsActiveMyBonus = (list: IUserBonus[]): boolean => {
  const dateNow = new Date();
  const bonus = list.find(
    (el) =>
      el.status === UserBonusStatus.active &&
      (el.endAt ? Date.parse(el.endAt.toString()) > Date.parse(dateNow.toString()) : !el.endAt),
  );
  return Boolean(bonus);
};

export const getActiveBonusTokens = (list: IUserBonus[]): string[] => {
  const dateNow = new Date();
  const bonuses = list.filter(
    (el) =>
      el.status === UserBonusStatus.active &&
      (el.endAt ? Date.parse(el.endAt.toString()) > Date.parse(dateNow.toString()) : !el.endAt),
  );
  return bonuses?.length ? bonuses.map((b) => b.tokenCode) : [];
};
