import { ILiveStatisticsBetData } from 'context/contextLiveStats/types';

export const getAverageLiveStatsRecords = (records: ILiveStatisticsBetData[]): ILiveStatisticsBetData[] => {
  const averagedRecords: ILiveStatisticsBetData[] = [];

  for (let i = 0; i < records.length; i += 2) {
    const intervalRecords = records.slice(i, i + 2);

    const averageTimestamp = intervalRecords.reduce((sum, record) => sum + record.uv, 0) / intervalRecords.length;
    const averageValue = intervalRecords.reduce((sum, record) => sum + record.pv, 0) / intervalRecords.length;

    averagedRecords.push({
      uv: averageTimestamp,
      pv: averageValue,
    });
  }

  return averagedRecords;
};
