import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useLocalization } from 'components/Internationalization';
import { getPathWithLanguage } from 'func/localization';

import { ILink } from './types';

const Link: React.FC<ILink> = ({ className = '', to, children, ...props }) => {
  const { language } = useLocalization();

  const [path, setPath] = useState(to);

  useEffect(() => {
    if (typeof to === 'string') {
      const newPath = getPathWithLanguage(to, language);

      setPath(newPath);
      return;
    }

    const currentPathname = to.pathname;
    const newPath = getPathWithLanguage(currentPathname, language);
    const newTo = { ...to, pathname: newPath };

    setPath(newTo);
  }, [to, language]);

  return (
    <RouterLink className={className} to={path} {...props}>
      {children}
    </RouterLink>
  );
};

export default Link;
