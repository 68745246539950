import { useEffect, useState } from 'react';
import { pathOr } from 'ramda';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { sha3_256 } from 'js-sha3';

import config from 'config';
import { GET_BET } from 'graphQl/query/bet/bet';
import { GET_USER } from 'graphQl/query/auth/profile';
import { amount, getImgSrc } from 'func/common';
import { getCoinsResultsListPopUp } from 'func/prepareDataCoinFlip';
import { useLocalization } from 'components/Internationalization';
import { ButtonType } from 'components/Base/Button/types';

import Loader from 'components/Base/Loader';
import Button from 'components/Base/Button';
import CopyButton from 'components/Base/CopyButton';

import LimboPopUpStyle from './styles';
import { IBetResponse } from '../types';
import { ICoinDetails } from './types';

const { sha256ValidatorUrl } = config;

const CoinBetDetails: React.FC<ICoinDetails> = ({ data }) => {
  const { translate } = useLocalization();

  const [bet, setBet] = useState<IBetResponse>();
  const [coinsResults, setCoinsResults] = useState(null);

  const { data: betData, loading } = useQuery(GET_BET, {
    variables: { betId: data.id },
  });

  const { data: user } = useQuery(GET_USER, {
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    const newBet = pathOr(undefined, ['bet'], betData);

    if (newBet) {
      setBet(newBet);
      setCoinsResults(getCoinsResultsListPopUp(newBet?.payout, newBet?.betOn?.prediction));
    }
  }, [betData]);

  const getDate = (string: string): string => dayjs(string).format('MM/DD/YYYY  hh:mm:ss A');

  const handleVerify = () => {
    window.open(sha256ValidatorUrl, '_blank');
  };

  return loading || !bet ? (
    <Loader pages />
  ) : (
    <LimboPopUpStyle>
      <div className="title">{translate('popups.bets.details.title')}</div>
      <div className="subtitle">
        <div className="subtitle-item1">
          <p>{translate('popups.bets.details.player')}:</p>
          <p>{translate('popups.bets.details.date')}:</p>
          <p>{translate('popups.bets.details.id')}:</p>
        </div>
        <div className="subtitle-item2">
          <h3>{user.profile.alias}</h3>
          <h3>{getDate(bet?.createdAt)}</h3>
          <h3>{bet?.id}</h3>
        </div>
      </div>
      <div className="amount-block">
        <div className="amount">
          <p className="amount-title">{translate('popups.bets.details.amount')}</p>
          <div className="amount-subtitle">
            <img alt="icon" src={getImgSrc(bet?.token.displayName)} className="token-icon" />
            <span className="amount-result">{amount(bet?.amount)}</span>
          </div>
        </div>
        <div className="multiplier">
          <p className="amount-title">{translate('popups.bets.details.multiplier')}</p>
          <div className="amount-subtitle">
            <span className="amount-result">{bet?.multiplier}</span>
          </div>
        </div>
        <div className="payout">
          <p className="amount-title">{translate('popups.bets.details.payout')}</p>
          <div className="amount-subtitle">
            <img src={getImgSrc(bet?.token.displayName)} alt="icon" className="token-icon" />
            <span className="amount-result">{amount(bet?.payout)}</span>
          </div>
        </div>
      </div>
      <div className="screen-coin">
        <div className="screen-coin--content">
          {coinsResults
            ? coinsResults.map((item) => (
                <div
                  className={
                    coinsResults?.length > 1 ? 'screen-coin--content-item' : 'screen-coin--content-item single'
                  }
                  key={item.id}
                >
                  <p className={item.payout ? 'coin__para--win' : 'coin__para--lose'}>{item.result}</p>
                  <img src={item.src} alt="coin" className={item.payout ? 'coin__img--win' : 'coin__img--lose'} />
                </div>
              ))
            : null}
        </div>
        {/* <div className="subtitle seeds"> */}
        <div className="subtitle seeds">
          <div className="subtitle-item1">
            <p>{translate('popups.bets.details.server-seed')}:</p>
          </div>
          <div className="subtitle-item2">
            <div className="seeds-text">
              <p>{bet?.serverSeed}</p>
              <CopyButton copyContent={bet?.serverSeed} />
            </div>
          </div>
        </div>
        <div className="subtitle seeds">
          <div className="subtitle-item1">
            <p>{translate('popups.bets.details.hash')}:</p>
          </div>
          <div className="subtitle-item2">
            <div className="seeds-text">
              <p>{sha3_256(bet?.serverSeed || '')}</p>
              <CopyButton copyContent={sha3_256(bet?.serverSeed || '')} />
            </div>
          </div>
        </div>
        <div className="verify-button-wrapper">
          <Button
            className="verify-button"
            title={translate('popups.bets.details.verify')}
            variant={ButtonType.primary}
            onclick={handleVerify}
            width="100px"
          />
        </div>
      </div>
    </LimboPopUpStyle>
  );
};

export default CoinBetDetails;
