import React from 'react';
import monsterImage from 'assets/img/common/monster.webp';
import ErrorPageStyle from './styles';

const ErrorPage: React.FC = () => (
  <ErrorPageStyle>
    <div className="error__title">
      <p>Dear StarBetter,</p>
      <p>Due to legal reasons, this game is not available for players residing in this jurisdiction.</p>
      <p>We apologize for any inconvenience.</p>
    </div>
    <div>
      <img alt="icon" src={monsterImage} className="error_image" />
    </div>
  </ErrorPageStyle>
);

export default ErrorPage;
