import { gql } from '@apollo/client';

export const GET_STAR_TOKENS = gql`
  query starToken {
    starToken {
      maxSupply
      totalBurned
      totalMined
      totalStaked
    }
  }
`;

export const GET_STAR_POOL_HISTORY = gql`
  query starTokenBurn {
    starTokenBurn {
      id
      token {
        tokenCode
        displayName
      }
      amount
      createdAt
    }
  }
`;

export const GET_STAR_POOL = gql`
  query starTokenPool {
    starTokenPool
  }
`;
