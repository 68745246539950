import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

export const InputWithMaxButtonStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;

  font-family: 'Montserrat';
  color: #ffffff;

  input {
    width: 100% !important;
  }

  .max__amount--btn {
    position: absolute;
    top: 10px;
    right: 20px;
    border: none;
    box-shadow: none;
    background: transparent;
    cursor: url(${point}), auto !important;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #ffffff;

    &:active,
    &:focus,
    &:disabled {
      box-shadow: none;
      outline: none;
      border: none;
    }
  }

  @media screen and (max-width: 540px) {
  }
`;
