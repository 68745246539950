import { SOCIAL_ITEMS, SocialType } from 'components/constants/footer';

import FooterTelegram from './components/FooterTelegram';
import FooterItem from './components/FooterItem';
import styles from './styles.module.scss';

const SocialBlock: React.FC = () => {
  return (
    <div className={styles.footerSocialWrap}>
      {SOCIAL_ITEMS.map((s) => {
        if (s.title === SocialType.telegram) {
          return <FooterTelegram key={s.title} />;
        }
        return <FooterItem key={s.title} link={s.link} icon={s.icon} title={s.title} />;
      })}
    </div>
  );
};

export default SocialBlock;
