import React from 'react';
import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';

const ProvablyFair: React.FC = () => {
  const { translate } = useLocalization();
  return (
    <div className={styles.provablyFairWrap}>
      <div className={styles.provablyFairTitle}>{translate('popups.provably.fair.title')}</div>
      <div className={styles.provablyFairBody}>
        <p>{translate('popups.provably.fait.subscription.top')}</p>
        <p>{translate('popups.provably.fair.subscription.middle')}</p>
        <p>{translate('popups.provably.fair.subscription.bottom')}</p>
      </div>
    </div>
  );
};

export default ProvablyFair;
