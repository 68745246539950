import React, { useContext, useEffect, useRef } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import { useAppSelector } from 'hooks/useAppSelector';
import { useMines } from 'context/contextMines/contextMines';
import useGameSettings from 'hooks/useGameSettings';
import { Types } from 'context/contextMines/minesReduser';
import { ContextPopUps, ContextTokenCode, ContextWallet } from 'context';
import LiveStatsContext from 'context/contextLiveStats/context';
import { GET_MINES_ROUND_HASH, GET_MINES_SESSION, MINES_AUTO_BET, MINES_COLLECT } from 'graphQl/mutations/mines/mines';
import { AUTO_BETTING_STATE, MINES_CONDITION, popUps } from 'components/constants/constants';
import { lessThen } from 'func/prepareDataDice';
import { prepareAmount, removeComas } from 'func/common';
import { disableSubmitButton, getCollectResult, getSubmitTitle } from 'func/prepareDataMines';
import { isAutoBetCanRun } from 'func/commonGames';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';

import GameBetButton from 'components/Games/base/GameBetButton';

import styles from './styles.module.scss';

let timer;

const MinesSubmitButton: React.FC = () => {
  const { translate } = useLocalization();
  const token = useAppSelector(userToken);

  const [state, dispatch] = useMines();

  const {
    isHotkeys,
    gameId,
    gameCondition,
    betAmountValue,
    gameSettings,
    minesSelected,
    currentSliderNumber,
    autoPickItems,
    isAuto,
    sliderData,
    autoState,
    requestId,
    fastMode,
  } = state;

  const { tokenCode } = useContext(ContextTokenCode);
  const { walletUser } = useContext(ContextWallet);
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { enabled: liveStatsEnabled, onBet: recordLiveStats } = useContext(LiveStatsContext);

  const [minesRound] = useMutation(GET_MINES_ROUND_HASH);
  const [minesCollect] = useMutation(MINES_COLLECT);
  const [minesAutoBet] = useMutation(MINES_AUTO_BET);
  const [minesSession] = useLazyQuery(GET_MINES_SESSION, {
    variables: { token: tokenCode.token },
    fetchPolicy: 'no-cache',
  });

  const gameConditionRef = useRef(gameCondition);
  const firstSpinRef = useRef(true);
  const startBetAmountRef = useRef('');

  const currentGameSettings = useGameSettings(gameId);

  const handleSignUp = () => setPopUpsOpen(popUps.registration);

  const handleChangeAutoState = (name: string, value: boolean | string) => {
    dispatch({ type: Types.CHANGE_AUTO_STATE, payload: { name, value } });
  };

  const handleInitGame = async () => {
    const formattedWallet = prepareAmount(walletUser);
    const formattedMinBet = prepareAmount(gameSettings.minBet);
    const formattedAmount = prepareAmount(betAmountValue);

    if (lessThen(formattedWallet, formattedMinBet) || lessThen(formattedWallet, formattedAmount)) {
      setPopUpsOpen({
        modalOpen: popUps.walletNavigation,
        data: {
          config: popUps.deposit,
          active: popUps.deposit,
        },
      });
      dispatch({ type: Types.CHANGE_GAME_CONDITION, payload: { gameCondition: MINES_CONDITION.notStarted } });
      gameConditionRef.current = MINES_CONDITION.notStarted;
      return;
    }
    if (lessThen(betAmountValue, gameSettings.minBet)) return;
    gameConditionRef.current = MINES_CONDITION.getStarted;
    try {
      const response = await minesRound({
        variables: {
          minesBet: {
            complexity: minesSelected,
            gameId,
            token: tokenCode.token,
            wager: removeComas(betAmountValue),
          },
        },
      });
      const hash = response?.data?.minesRound?.hash;

      dispatch({ type: Types.INIT_GAME, payload: { hash } });
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleAutoBet = async () => {
    if (lessThen(walletUser, gameSettings.minBet) || lessThen(walletUser, betAmountValue)) {
      setPopUpsOpen({
        modalOpen: popUps.walletNavigation,
        data: {
          config: popUps.deposit,
          active: popUps.deposit,
        },
      });
      dispatch({ type: Types.CHANGE_GAME_CONDITION, payload: { gameCondition: MINES_CONDITION.autoNotStarted } });
      gameConditionRef.current = MINES_CONDITION.autoNotStarted;
      firstSpinRef.current = true;
      return;
    }

    if (firstSpinRef.current) {
      handleChangeAutoState(AUTO_BETTING_STATE.autoBetAmount, removeComas(betAmountValue));
    }

    const currentBetAmount = !firstSpinRef.current ? autoState.autoBetAmount : removeComas(betAmountValue);

    if (!isAutoBetCanRun(autoState, currentBetAmount, gameSettings.minBet, handleChangeAutoState)) {
      dispatch({ type: Types.CHANGE_GAME_CONDITION, payload: { gameCondition: MINES_CONDITION.autoNotStarted } });
      gameConditionRef.current = MINES_CONDITION.autoNotStarted;
      return;
    }

    try {
      const hashResponse = await minesRound({
        // init game session
        variables: {
          minesBet: {
            complexity: minesSelected,
            gameId,
            token: tokenCode.token,
            wager: removeComas(betAmountValue),
          },
        },
      }); // need to set hash
      const response = await minesAutoBet({
        variables: {
          predictions: autoPickItems,
          token: tokenCode.token,
          gameId,
        },
      });
      const hash = hashResponse?.data?.minesRound?.hash;
      const bet = response.data.minesAutoBet;

      if (liveStatsEnabled) {
        recordLiveStats(removeComas(betAmountValue), bet.payout);
      }

      dispatch({
        type: Types.SET_AUTO_BET_RESPONSE,
        payload: {
          bet,
          tokenCode,
          firstSpin: firstSpinRef.current,
          startBetAmount: startBetAmountRef.current,
          hash,
        },
      });
      firstSpinRef.current = false;
    } catch (e) {
      console.log(e.message);
      dispatch({ type: Types.SET_ERROR_COUNT });
    }
  };

  const handleCollect = async () => {
    try {
      const response = await minesCollect({
        variables: {
          token: tokenCode.token,
          gameId,
        },
      });
      const bet = response.data.minesCollect;

      if (liveStatsEnabled) {
        recordLiveStats(removeComas(betAmountValue), bet.payout);
      }

      dispatch({ type: Types.COLLECT_ROUND, payload: { bet, tokenCode } });
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleSession = async (settings) => {
    if (!token) return;
    const session = await minesSession();
    if (session?.data?.minesSession) {
      const bet = session?.data?.minesSession;
      dispatch({ type: Types.SET_SESSION, payload: { bet, settings } });
      firstSpinRef.current = true;
      gameConditionRef.current = MINES_CONDITION.getStarted;
    } else {
      gameConditionRef.current = MINES_CONDITION.notStarted;
      firstSpinRef.current = true;
      dispatch({
        type: Types.CHANGE_TOKEN_CODE,
        payload: { gameSettings: currentGameSettings },
      });
    }
  };

  const handleSubmit = () => {
    if (!token) {
      handleSignUp();
      return;
    }
    if (gameCondition === MINES_CONDITION.getStarted) {
      gameConditionRef.current = MINES_CONDITION.notStarted;
      handleCollect();
      return;
    }
    if (gameCondition === MINES_CONDITION.autoNotStarted) {
      dispatch({ type: Types.START_AUTO_BET });
      gameConditionRef.current = MINES_CONDITION.autoStarted;
      startBetAmountRef.current = betAmountValue;
      firstSpinRef.current = true;
      handleAutoBet();
      return;
    }
    if (gameCondition === MINES_CONDITION.autoStarted) {
      gameConditionRef.current = MINES_CONDITION.autoNotStarted;
      dispatch({ type: Types.STOP_AUTO_BET });
      dispatch({ type: Types.SET_AUTO_BET_START_CONDITION });
      clearTimeout(timer);
      return;
    }
    handleInitGame();
    gameConditionRef.current = MINES_CONDITION.selection;
  };

  useEffect(() => {
    if (currentGameSettings.minBet) {
      handleSession(currentGameSettings);
    }
  }, [currentGameSettings]);

  useEffect(() => {
    if (requestId) {
      const delay = document.hidden ? 0 : fastMode ? 300 : 1000;
      if (gameConditionRef.current === MINES_CONDITION.autoStarted) {
        timer = setTimeout(() => {
          dispatch({ type: Types.SET_AUTO_BET_START_CONDITION });
          handleAutoBet();
        }, delay);
      } else {
        gameConditionRef.current = MINES_CONDITION.autoNotStarted;
        dispatch({ type: Types.SET_AUTO_BET_START_CONDITION });
        clearTimeout(timer);
      }
    }
  }, [requestId]);

  return (
    <div className={styles.minesSubmitButtonWrap}>
      <GameBetButton
        onClick={handleSubmit}
        disable={disableSubmitButton(gameCondition, currentSliderNumber, autoPickItems)}
        isHotkeys={isHotkeys}
      >
        <div className={styles.minesSubmitButton}>
          {getSubmitTitle(gameCondition, currentSliderNumber) ? (
            <h5>
              {gameCondition === MINES_CONDITION.getStarted
                ? translate('mines.button.pick.text')
                : translate('common.games.bet.button')}
            </h5>
          ) : isAuto ? (
            <h5>
              {gameCondition === MINES_CONDITION.autoNotStarted
                ? translate('common.games.start.auto.bet')
                : translate('common.games.stop.auto.bet')}
            </h5>
          ) : (
            <>
              <h5>{translate('common.games.bet.cashout')}</h5>
              <h6>
                {getCollectResult(sliderData, minesSelected, currentSliderNumber, betAmountValue, gameSettings.maxPay)}
              </h6>
            </>
          )}
        </div>
      </GameBetButton>
    </div>
  );
};

export default MinesSubmitButton;
