import { generate as Id } from 'shortid';

import { BlackJackActionTypes, HandResultType, HandType } from 'context/ContextBlackJack/types';

import hotkeys1 from 'assets/img/BlackJack/Hotkeys/hotKeys-1.webp';
import hotkeys2 from 'assets/img/BlackJack/Hotkeys/hotKeys-2.webp';
import hotkeys3 from 'assets/img/BlackJack/Hotkeys/hotKeys-3.webp';

import chip_01 from 'assets/img/Cards/Chips/0.1.webp';
import chip_05 from 'assets/img/Cards/Chips/0.5.webp';
import chip_10 from 'assets/img/Cards/Chips/10.webp';
import chip_10_vip from 'assets/img/Cards/Chips/10_vip.webp';
import chip_25 from 'assets/img/Cards/Chips/2.5.webp';
import chip_50 from 'assets/img/Cards/Chips/50_vip.webp';
import chip_250 from 'assets/img/Cards/Chips/250_vip.webp';
import chip_1000 from 'assets/img/Cards/Chips/1000_vip.webp';

export enum EChipAmountLow {
  min = 0.1,
  middleMin = 0.5,
  middleMax = 2.5,
  max = 10,
}

export enum EChipAmountHigh {
  min = 10,
  middleMin = 50,
  middleMax = 250,
  max = 1000,
}

export const LOW_CHIPS = [
  { amount: EChipAmountLow.min, icon: chip_01, id: Id() },
  { amount: EChipAmountLow.middleMin, icon: chip_05, id: Id() },
  { amount: EChipAmountLow.middleMax, icon: chip_25, id: Id() },
  { amount: EChipAmountLow.max, icon: chip_10, id: Id() },
];

export const HIGH_CHIPS = [
  { amount: EChipAmountHigh.min, icon: chip_10_vip, id: Id() },
  { amount: EChipAmountHigh.middleMin, icon: chip_50, id: Id() },
  { amount: EChipAmountHigh.middleMax, icon: chip_250, id: Id() },
  { amount: EChipAmountHigh.max, icon: chip_1000, id: Id() },
];

export const BLACKJACK_BUTTON_BACKGROUND = {
  deal: 'linear-gradient(180deg, #4AA22E 14.29%, #022E00 109.18%)',
  hit: 'linear-gradient(180deg, #2B8DC5 14.29%, #0132AE 109.18%)',
  stand: 'linear-gradient(180deg, #4AA22E 14.29%, #022E00 109.18%)',
  split: 'linear-gradient(180deg, #CFAF07 14.29%, #C48100 109.18%)',
  back: 'linear-gradient(180deg, #F0A81C 14.29%, #C54E1C 109.18%)',
  clear: 'linear-gradient(180deg, #DB1752 14.29%, #710522 109.18%)',
  reBet: 'linear-gradient(180deg, #F0A81C 14.29%, #C54E1C 109.18%)',
  newBet: 'linear-gradient(180deg, #4AA22E 14.29%, #022E00 109.18%)',
  betDeal: 'linear-gradient(180deg, #2B8DC5 14.29%, #0132AE 109.18%)',
  double: 'linear-gradient(180deg, #F0A81C 14.29%, #C54E1C 109.18%)',
  reBetDefault: 'linear-gradient(180deg, #2B8DC5 14.29%, #0132AE 109.18%)',
};

export const INITIAL_ACTIONS = [BlackJackActionTypes.deal];

export const ANIMATION_DURATION = {
  slow: 1100,
  fast: 400,
};

export const ANIMATION_FLIP = {
  slow: 800,
  fast: 100,
};

export const INITIAL_PLAYER_HAND = {
  cards: [],
  isSplit: false,
  firstHand: {
    status: HandType.open,
    isDouble: false,
    betAmount: '0',
    result: HandResultType.default,
  },
  secondHand: {
    status: HandType.notActive,
    isDouble: false,
    betAmount: '0',
    result: HandResultType.default,
  },
  cardsCount: 1,
};

export const PLAYER_LEFT = 50;

export const DEFAULT_LOW_CHIP = LOW_CHIPS[0];

export const DEFAULT_HIGH_CHIP = HIGH_CHIPS[0];

export enum EBlackJackHotkeysTopic {
  betOption = 'Bet Options',
  inGame = 'In-Game',
  endOfGame = 'End of Game',
}

export const BLACK_JACK_HOT_KEYS_BET_OPTION = {
  icon: hotkeys1,
  data: [
    { key: '1', description: 'popups.hotkeys.blackjack.bet.1' },
    { key: 'X', description: 'blackjack.action.clear' },
    { key: '2', description: 'popups.hotkeys.blackjack.bet.2' },
    { key: 'Z', description: 'blackjack.action.back' },
    { key: '3', description: 'popups.hotkeys.blackjack.bet.3' },
    { key: 'D', description: 'blackjack.action.bet.deal' },
    { key: '4', description: 'popups.hotkeys.blackjack.bet.4' },
  ],
};
export const BLACK_JACK_HOT_KEYS_BET_OPTION_VIP = {
  icon: hotkeys1,
  data: [
    { key: '1', description: 'popups.hotkeys.blackjack.bet.1.vip' },
    { key: 'X', description: 'blackjack.action.clear' },
    { key: '2', description: 'popups.hotkeys.blackjack.bet.2.vip' },
    { key: 'Z', description: 'blackjack.action.back' },
    { key: '3', description: 'popups.hotkeys.blackjack.bet.3.vip' },
    { key: '4', description: 'popups.hotkeys.blackjack.bet.4.vip' },
    { key: 'D', description: 'blackjack.action.bet.deal' },
  ],
};

export const BLACK_JACK_HOT_KEYS_IN_GAME = {
  icon: hotkeys2,
  data: [
    { key: 'H', description: 'popups.hotkeys.blackjack.game.hit' },
    { key: 'J', description: 'popups.hotkeys.blackjack.game.stand' },
    { key: 'K', description: 'popups.hotkeys.blackjack.game.double' },
    { key: 'L', description: 'popups.hotkeys.blackjack.game.split' },
  ],
};

export const BLACK_JACK_HOT_KEYS_END_OF_GAME = {
  icon: hotkeys3,
  data: [
    { key: 'B', description: 'popups.hotkeys.blackjack.new.double' },
    { key: 'N', description: 'popups.hotkeys.blackjack.new.bet' },
    { key: 'M', description: 'popups.hotkeys.blackjack.new.rebet' },
  ],
};

export const BLACK_JACK_HOT_KEYS_TOPICS = [
  EBlackJackHotkeysTopic.betOption,
  EBlackJackHotkeysTopic.inGame,
  EBlackJackHotkeysTopic.endOfGame,
];
