import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

interface Props {
  arrowleft?: number;
  popuptable?: boolean;
}

const PaginationStyle = styled.div.withConfig({
  shouldForwardProp: (prop) => !['arrowleft'].includes(prop),
})<Props>`
  margin: 10px;
  display: flex;
  width: 100%;
  margin-top: ${(props) => (props.popuptable ? '20px' : '50px')};
  justify-content: center;
  align-items: center;
  a:focus {
    outline: none;
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    ul {
      padding: 0;
    }
    li {
      list-style: none;
    }
  }

  .pagination__link {
    width: 36px;
    height: 36px;
    background-color: transparent;
    text-decoration: none;
    margin: 0 5px;
    cursor: url(${point}), auto !important;
    list-style: none;
    a {
      font-family: 'Montserrat';
      font-weight: 700;
      font-size: 14px;
      color: #696c80;
      display: inline-block;
      padding-top: 8px;
      text-align: center;
    }
  }
  .pagination__doth {
    width: 36px;
    height: 36px;
    background-color: transparent;
    text-decoration: none;
    margin: 0 5px;
    cursor: url(${point}), auto !important;
    a {
      font-family: 'Montserrat';
      font-weight: 700;
      font-size: 14px;
      color: #696c80;
      display: inline-block;
      padding-top: 8px;
      text-align: center;
    }
  }
  .pagination__link-active {
    width: 36px;
    height: 36px;
    background-color: transparent;
    border: 2px solid #ffffff;
    border-radius: 50%;
    text-decoration: none;
    margin: 0 5px;
    cursor: url(${point}), auto !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      font-family: 'Montserrat';
      font-weight: 700;
      font-size: 14px;
      color: #ffffff;
      display: inline-block;
      padding-top: 8px;
      align-items: center;
    }
  }

  .pagination__arrow-left {
    width: 13px;
    height: 13px;
    transform: rotate(180deg);
  }
  .pagination__arrow-right {
    width: 13px;
    height: 13px;
  }
  .pagination__item {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    opacity: 0.5;
    cursor: url(${point}), auto !important;
  }
  .pagination__item-left {
    width: 36px;
    height: 36px;
    visibility: ${(props) => (props.arrowleft < 1 ? 'hidden' : 'visible')};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    opacity: 0.5;
    cursor: url(${point}), auto !important;
  }

  @media (max-width: 1200px) {
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 900px) {
  }

  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 580px) {
    margin-left: 0;
    margin-top: 30px;
  }
  @media (max-width: 500px) {
    .pagination__doth {
      display: none;
    }
  }
  @media (max-width: 400px) {
  }
`;

export default PaginationStyle;
