import styled from 'styled-components';

const PasswordStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;

  input {
    width: 288px;
    height: 38px;
    border-radius: 20px;
    border: 2px solid #6c5dd3;
    line-height: 20px;
    box-sizing: border-box;
    background: #00000033;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    padding-left: 35px;
    margin-bottom: 30px;
    text-transform: lowercase;
  }

  input:focus {
    outline: none;
    border: 2px solid #6c5dd3;
    border-radius: 19px;
    background-color: #00000033;
    color: #ffffff;
    box-shadow: none;
  }

  input::placeholder {
    color: #3c516a;
  }
`;

export default PasswordStyle;
