import React, { useEffect, useState } from 'react';

import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile } from 'store/user/user.selectors';

import HighCardTitle from 'components/Base/Cards/HighCardTitle';
import CashbackProgressBar from 'components/Pages/CashbackPage/components/CardBlockSecond/components/MyRank/components/CashbackProgressBar';
import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';
import { IMyRank } from './types';

const MyRank: React.FC<IMyRank> = ({ rankData }) => {
  const user = useAppSelector(userProfile);
  const { translate } = useLocalization();

  const [progressWidth, setProgressWidth] = useState(0);
  const [rankValue, setRankValue] = useState(null);

  useEffect(() => {
    if (rankData?.length > 1 && user) {
      setRankValue(rankData.find((elem) => elem.id === user.rank.id + 1));
    }
    if (rankValue && user) {
      const value = Number((Number(user.totalWager) * 100) / Number(rankValue.wager)).toFixed(9);
      setProgressWidth(Number(value));
    }
  }, [rankData, user, rankValue]);

  return (
    <HighCardTitle
      title={translate('cashback.myRank.title')}
      subtitle={translate('cashback.myRank.description')}
      height="auto"
      blurIfNotAuth
    >
      <div className={styles.cashbackMyRankWrap}>
        <>
          <div className={styles.cashbackMyRankBody}>
            <code>{translate('cashback.myRank.card.text')}</code>
            <div />
            <code>{user ? user.rank.id : '1'}</code>
          </div>
          <CashbackProgressBar
            progressWidth={progressWidth}
            currentWager={user?.totalWager || '0'}
            wager={rankValue?.wager || '0'}
            rank={user?.rank?.id || 1}
            percent={rankValue?.cashback || '0'}
            currentPercent={user?.rank?.cashback || '0'}
          />
        </>
      </div>
    </HighCardTitle>
  );
};

export default MyRank;
