import React from 'react';

import { ISoketContext } from './types';

const SocketContext = React.createContext<ISoketContext>({
  socket: null,
  connected: false,
  inGame: false,
  exception: null,
  disconnectReason: '',
  notification: null,
  joinGameRoom: () => null,
  leaveGameRoom: () => null,
});

export default SocketContext;
