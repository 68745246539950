import { PostOrPage } from '@tryghost/content-api';
import { useContext, useEffect, useState } from 'react';

import BlogContext from 'context/contextBlog/context';

import BlogArticlePreview from '../BlogArticlePreview';

import styles from './styles.module.scss';
import { IBlogLastArticles } from './types';

const BlogLastArticles: React.FC<IBlogLastArticles> = ({ slug }) => {
  const { allArticles } = useContext(BlogContext);

  const [lastArticles, setLastArticles] = useState<PostOrPage[]>([]);

  useEffect(() => {
    const filteredArticles = allArticles.filter((a) => a.slug !== slug);
    const newArticles = filteredArticles.slice(0, 3);

    setLastArticles(newArticles);
  }, [allArticles, slug]);

  return lastArticles.length ? (
    <section className={styles.content}>
      <h3 className={styles.title}>Latest Articles</h3>
      <ul className={styles.articles}>
        {lastArticles.map((a, i) => (
          <BlogArticlePreview key={`latest-${a.id}`} article={a} index={i} />
        ))}
      </ul>
    </section>
  ) : null;
};

export default BlogLastArticles;
