import { useEffect, useRef } from 'react';

export const useEventListener = (type: string, handler: EventListener, el = window): void => {
  const savedHandler = useRef<EventListener>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const listener = (ev: Event) => savedHandler?.current(ev);

    el.addEventListener(type, listener);

    return () => {
      el.removeEventListener(type, listener);
    };
  }, [type, el]);
};
