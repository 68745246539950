import VipBanner from './components/VipBanner';
import VipBenefits from './components/VipBenefits';
import VipBonuses from './components/VipBonuses';

import styles from './styles.module.scss';

const VipPage: React.FC = () => {
  return (
    <main className={styles.wrapper}>
      <VipBanner />
      <VipBenefits />
      <VipBonuses />
    </main>
  );
};

export default VipPage;
