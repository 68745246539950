import React, { useState } from 'react';

import { SQUARE_CLASSES } from 'components/constants/constants';
import { getIsDisabledSquareItem, getSquareImage } from 'func/prepareDataMines';

import { IMinesSquare } from './types';
import MinesSquareItemStyle from './styles';

const SquareItem: React.FC<IMinesSquare> = ({
  className,
  handlePick,
  item,
  gameCondition,
  autoPickItems,
  minesSelected,
}) => {
  const [active, setActive] = useState('');

  const handleChooseItem = (ind: number) => {
    setActive(SQUARE_CLASSES.active);
    handlePick(ind);
    setActive('');
  };

  return (
    <MinesSquareItemStyle
      type="button"
      onClick={() => handleChooseItem(item)}
      disabled={getIsDisabledSquareItem(gameCondition, className, autoPickItems, minesSelected)}
    >
      <div className={active || className}>
        <img src={getSquareImage(className)} alt="icon" />
      </div>
    </MinesSquareItemStyle>
  );
};

export default SquareItem;
