import { styled } from 'styled-components';
import { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { IWheelOfFortuneBackground } from './types';
import { WHEEL_BG_NAME } from '../../constants';

const WheelOfFortuneBackground: React.FC<IWheelOfFortuneBackground> = ({ wheelUrl }) => {
  const [image, setImage] = useState('');

  useEffect(() => {
    if (wheelUrl) {
      const newImage = wheelUrl + WHEEL_BG_NAME;

      setImage(newImage);
    }
  }, [wheelUrl]);

  return image ? <StyledBackground className={styles.wrapper} $bgImage={image} /> : null;
};

export default WheelOfFortuneBackground;

const StyledBackground = styled.div.withConfig({
  shouldForwardProp: (prop) => !prop.includes('$'),
})<{ $bgImage: string }>`
  ${({ $bgImage }) => ($bgImage ? `background: url(${$bgImage});` : '')}
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
`;
