import { useLazyQuery } from '@apollo/client';
import { useContext } from 'react';

import { CHANGELLY_CREATE_ORDER } from 'graphQl/query/buyCrypto';
import { ContextPopUps } from 'context';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';
import { getChangeLlyToken } from 'func/prepareDataPopUps';

import Button from 'components/Base/Button';

import { ChangllyPaymentMethod } from '../../types';
import { IBuyCryptoSubmit } from './types';
import styles from './styles.module.scss';

const BuyCryptoSubmit: React.FC<IBuyCryptoSubmit> = ({
  disable,
  setDisable,
  providerCode,
  currencyFrom,
  currencyTo,
  amountFrom,
}) => {
  const { translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [changellyCreateOrder] = useLazyQuery(CHANGELLY_CREATE_ORDER, { fetchPolicy: 'no-cache' });
  const handleSubmit = async () => {
    setDisable(true);
    const properCurrencyTo = getChangeLlyToken(currencyTo);

    const response = await changellyCreateOrder({
      variables: {
        providerCode,
        currencyFrom,
        currencyTo: properCurrencyTo,
        amountFrom,
        paymentMethod: ChangllyPaymentMethod.card,
      },
    });

    if (response.data) {
      setTimeout(() => {
        window.open(response.data.changellyCreateOrder.redirectUrl, '_blank');
      });
      setPopUpsOpen(null);
    }

    setDisable(false);
  };
  return (
    <div className={styles.wrapper}>
      <Button
        variant={ButtonType.primary}
        title={translate('popups.wallet.buy.crypto.button')}
        onclick={handleSubmit}
        width="130px"
        disabled={disable}
      />
    </div>
  );
};

export default BuyCryptoSubmit;
