import React, { useContext } from 'react';

import TournamentContext from 'context/contextTournaments/context';

import Loader from 'components/Base/Loader';

import styles from './styles.module.scss';
import TournamentsCardsList from '../TournamentsCardsList';
import TournamentsEmptyPage from '../TournamentsEmptyPage';

const TournamentsContent: React.FC = () => {
  const { tournamentsList, isLoadingTournaments } = useContext(TournamentContext);

  if (isLoadingTournaments) return <Loader pages />;

  return (
    <>
      {tournamentsList.length ? (
        <div className={styles.tournamentsContentWrap}>
          <div className={styles.tournamentCardsWrap}>
            <TournamentsCardsList />
          </div>
        </div>
      ) : (
        <TournamentsEmptyPage />
      )}
    </>
  );
};

export default TournamentsContent;
