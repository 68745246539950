import account from 'assets/img/HomePage/account.webp';
import dividends from 'assets/img/HomePage/stacking.webp';
import cashback from 'assets/img/HomePage/cashbackSchedule.webp';
import referral from 'assets/img/HomePage/turnOver.webp';

import { TranslationType } from 'types';

interface IStats {
  numberOfAccounts: number;
  totalCashbacks: string;
  totalDividends: string;
  totalReferrals: string;
}

export const getStatisticsTitles = (t: TranslationType, stats: IStats) => {
  return [
    {
      value: 'accounts',
      title: t('homepage.statistics.accounts'),
      amount: stats.numberOfAccounts || '0',
      src: account,
    },
    {
      value: 'dividends',
      title: t('homepage.statistics.dividends'),
      amount: (stats.totalDividends || '0').slice(0, 5),
      src: dividends,
    },
    {
      value: 'cashback',
      title: t('homepage.statistics.cashback'),
      amount: (stats.totalCashbacks || '0').slice(0, 5),
      src: cashback,
    },
    {
      value: 'referral',
      title: t('homepage.statistics.referral'),
      amount: (stats.totalReferrals || '0').slice(0, 5),
      src: referral,
    },
  ];
};
