import { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import LiveStatsContext from 'context/contextLiveStats/context';

import LiveStatistics from 'components/Base/LiveStatistics';
import LotteryDesktop from 'components/Games/Lottery/LotteryDesktop/LotteryDesktop';

import styles from './styles.module.scss';
import MinesHistory from './components/MinesHistory';
import MinesNavigationButtons from './components/MinesNavigationButtons';
import MinesTables from './components/MinesTables';
import MinesSubmitButton from './components/MinesSubmitButton';
import MinesBetAmountBlock from './components/MinesBetAmountBlock';
import MinesDropdownAuto from './components/MinesDropdownAuto';
import MinesAutoSettings from './components/MinesAutoSettings';
import MinesSlider from './components/MinesSlider';
import MinesDiamond from './components/MinesDiamondBombBlock/MinesDiamond';
import MinesBomb from './components/MinesDiamondBombBlock/MinesBomb';
import MinesSquares from './components/MinesSquares';
import MinesWinAnimation from './components/MinesWinAnimation';
import MinesAutoSwitch from './components/MinesAutoSwitch';

const MinesGame: React.FC = () => {
  const { enabled, visible } = useContext(LiveStatsContext);

  const isMobile = useMediaQuery({ query: '(max-width: 1260px)' });

  return (
    <div className={styles.minesGameWrap}>
      <div className={styles.minesGameContentWrap}>
        <div className={styles.minesGameContent}>
          <div className={styles.minesGameContentHeader}>
            <MinesHistory />
            <MinesNavigationButtons />
          </div>
          <div className={styles.minesGameContentMain}>
            <MinesDiamond />
            <MinesSquares />
            <MinesBomb />
            <MinesWinAnimation />
            {isMobile ? enabled && visible ? <LiveStatistics mobile={isMobile} /> : null : null}
          </div>
          <div className={styles.minesGameContentSlider}>
            <MinesSlider />
          </div>
          <div className={styles.minesGameContentNavigation}>
            <MinesDropdownAuto />
            <MinesBetAmountBlock />
            <div className={styles.minesGameContentNavigationSubmitWrap}>
              <MinesSubmitButton />
              <MinesAutoSwitch />
            </div>
          </div>
        </div>
        <LotteryDesktop />
      </div>
      <div className={styles.minesGameAutoSettings}>
        <MinesAutoSettings />
      </div>
      <div className={styles.minesGameTableWrap}>
        <MinesTables />
      </div>
    </div>
  );
};

export default MinesGame;
