import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

interface Props {
  arrow?: string;
}

const SecurityCodeContainer = styled.div<Props>`
  padding: 25px 0;
  color: #fff;
  margin: auto;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  align-items: center;
  .userSecurity__inputContainer {
    position: relative;
    width: 100%;

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .input-2fa-style {
    background: #00000033;
    width: 40px;
    height: 40px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    border: 2px solid #313e57;
    uotline: none;
    border-radius: 9px;
    margin: 0 5px;
    text-align: center;
    &:focus {
      border: 2px solid #442a81;
      outline: none;
    }
    &:hover {
      border: 2px solid #442a81;
      outline: none;
    }
    input {
      outline: none;
    }
  }
  .userSecurity__title {
    margin-bottom: 35px;
    font-size: 36px;
    font-weight: 800;
    text-align: center;
  }
  .userSecurity__errorContainer {
    display: flex;
    align-items: center;
    min-height: 40px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    color: #f02f41;
    margin: 7px 0;
  }

  .userSecurity__resend-wrap {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #888aa7;
    position: relative;
    margin-top: 30px;
  }

  .userSecurity__resend-wrap-btn {
    color: #888aa7;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    background: transparent;
    border: none;
    border-bottom: 1px solid #888aa7;
    //border-bottom: 1px solid #ffb22d;
    margin-left: 5px;
    &:hover {
      cursor: url(${point}), auto !important;
    }
  }
  .link--tooltip {
    position: absolute;
    left: -3%;
    width: 288px;
    height: 67px;
    border-radius: 19px;
    padding: 0 15px;
    background: black;
    visibility: hidden;
    transform: translateY(-100%);
    transition: all 0.1s ease-in-out;
    transition-duration: 100ms;
    transition-delay: 100ms;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 11px;
    color: #ffff;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .link--tooltip.open {
    visibility: visible;
    transform: translateY(-150%);
  }
  .userSecurity__subtitle {
    font-weight: 300;
    font-size: 14px;
    max-width: 270px;
    margin-bottom: 30px;
    text-align: center;
  }
  .userSecurity__support {
    font-weight: 300;
    font-size: 12px;
    max-width: 270px;
    margin: 30px 0px 10px 0;
    text-align: center;
    span {
      color: inherit;
      font-size: 12px;
    }
  }
`;

export default SecurityCodeContainer;
