import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import activeSearchIcon from 'assets/img/GamesFiler/active-search.webp';

import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';
import { IDropDownSearch } from './types';

const sampleContainer = {
  maxHeight: '250px',
  padding: '0 15px',
  background: 'transparent',
};

const DropdownSearch: React.FC<IDropDownSearch> = ({ data, disable }) => {
  const { translate } = useLocalization();

  const [open, setOpen] = useState(false);
  const [currentList, setCurrentList] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const handleOpen = () => setOpen(!open);

  const handleBlur = (event) => {
    if (event.relatedTarget?.tabIndex >= 100000) return;
    setOpen(false);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    const filtered = data.filter((el) => el.title.toLowerCase().includes(value.toLowerCase()));
    if (filtered.length) {
      setCurrentList(filtered);
    } else {
      setCurrentList(data);
    }
  };

  useEffect(() => {
    if (data?.length) {
      setCurrentList(data);
    }
  }, [data]);

  if (!currentList?.length || !data?.length) return null;

  return (
    <div className={disable ? `${styles.dropSearchWrap} ${styles.disable}` : styles.dropSearchWrap}>
      <div tabIndex={0} className={styles.dropSearchHeader} onClick={handleOpen} onBlur={handleBlur}>
        <p className={styles.dropSearchHeaderPara}>{data[0]?.title}</p>
        <i className={`${styles.dropSearchI} ${open ? styles.iUp : styles.iDown}`} />
      </div>
      <div className={`${styles.dropSearchBody} ${open && styles.dropSearchBodyOpen}`}>
        <div className={styles.dropSearchBodyInputWrap}>
          <input
            tabIndex={100000}
            type="text"
            value={searchValue}
            onChange={handleSearch}
            className={styles.dropSearchBodyInput}
            placeholder={translate('games.nav.search')}
            onBlur={handleBlur}
          />
          <img src={activeSearchIcon} alt="search" />
        </div>
        <PerfectScrollbar style={sampleContainer} options={{ suppressScrollY: false }} key={0}>
          {currentList.map((el, index: number) => (
            <p
              className={styles.dropSearchBodyPara}
              key={el.title}
              tabIndex={index + 100000}
              onClick={() => {
                setOpen(false);
                setSearchValue('');
                el.func();
              }}
            >
              {el.title}
            </p>
          ))}
        </PerfectScrollbar>
      </div>
    </div>
  );
};

export default DropdownSearch;
