import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';

import emptyNotificationIcon from 'assets/img/Notification/empty-notification.webp';
import colorNotificationIcon from 'assets/img/Notification/notification.webp';

import NotificationContext from 'context/contextNotifications/context';

import styles from './styles.module.scss';
import { IBurgerNotification } from './types';

const BurgerNotification: React.FC<IBurgerNotification> = ({ onSelectMenu }) => {
  const { notifications, onOpenNotificationList } = useContext(NotificationContext);

  const [unread, setUnread] = useState(0);
  const [showDot, setShowDot] = useState(false);

  useEffect(() => {
    const unreadMessages = notifications.reduce((acc, n) => {
      return !n.isRead ? acc + 1 : acc;
    }, 0);

    if (unreadMessages) {
      setTimeout(() => {
        setShowDot(true);
      }, 2000);
    } else {
      setShowDot(false);
    }

    setUnread(unreadMessages);
  }, [notifications]);

  const handleClick = () => {
    onSelectMenu();
    onOpenNotificationList();
  };

  return (
    <button type="button" className={styles.wrapper} onClick={handleClick}>
      <img
        className={clsx(styles.icon, unread ? styles.animation : '')}
        src={unread ? colorNotificationIcon : emptyNotificationIcon}
        alt="notification"
      />
      {unread && showDot ? <div className={styles.dot} /> : null}
    </button>
  );
};

export default BurgerNotification;
