/* eslint-disable @typescript-eslint/no-explicit-any */

import { useContext, useEffect, useRef, useState } from 'react';

import { ReactComponent as CheckIcon } from 'assets/img/Notification/check-cicle.svg';
import { ReactComponent as CrossIcon } from 'assets/img/Notification/cross.svg';

import NotificationContext from 'context/contextNotifications/context';
import { useLocalization } from 'components/Internationalization';

import NotificationItem from '../NotificationItem';
import styles from './styles.module.scss';

const NotificationListMobile: React.FC = () => {
  const { translate } = useLocalization();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { notifications, onRemoveAll, onReadAll, onOpenNotificationList } = useContext(NotificationContext);

  const [unread, setUnread] = useState(false);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    const hasUnread = notifications.find((n) => !n.isRead);

    setUnread(Boolean(hasUnread));
  }, [notifications]);

  function handleClickOutside(ev: MouseEvent) {
    const notificationsBlock = wrapperRef.current;

    if (notificationsBlock && !notificationsBlock.contains(ev.target as any)) {
      onOpenNotificationList();
    }
  }

  return (
    <div ref={wrapperRef}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h6 className={styles.title}>{translate('notifications.title')}</h6>
          <button className={styles.button} type="button" onClick={onOpenNotificationList}>
            <CrossIcon className={styles.buttonIcon} />
          </button>
        </div>
        <div className={styles.buttonsWrapper}>
          <button
            type="button"
            className={styles.button}
            disabled={!notifications.length || !unread}
            onClick={onReadAll}
          >
            <CheckIcon />
            <span className={styles.buttonText}>{translate('notifications.mark')}</span>
          </button>
          <button className={styles.button} type="button" onClick={onRemoveAll}>
            <span className={styles.buttonText}>{translate('notifications.clear')}</span>
          </button>
        </div>
        <div className={styles.notificationsContainer}>
          <ul className={styles.notifications}>
            {notifications.map((n) => (
              <NotificationItem key={n.id} notification={n} />
            ))}
          </ul>
          {!notifications.length ? <p className={styles.empty}>{translate('notifications.empty.text')}</p> : null}
        </div>
      </div>
    </div>
  );
};

export default NotificationListMobile;
