import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';
import Big from 'big.js';
import { IExchangeRates, IUserSecurity, MFATypes } from 'types';

import React from 'react';
import btcIcon from 'assets/img/currencyIcons/btcIcon.webp';
import etherIcon from 'assets/img/currencyIcons/ltcIcon.webp';
import liteCoinIcon from 'assets/img/currencyIcons/LTCcoin.webp';
import tronIcon from 'assets/img/currencyIcons/tron.webp';
import startIcon from 'assets/img/currencyIcons/starcoin.webp';
import bnbIcon from 'assets/img/currencyIcons/bnb-coin.webp';
import usdtIcon from 'assets/img/currencyIcons/USDTcoin.webp';
import usdIcon from 'assets/img/currencyIcons/usdIcon.webp';
import xrpIcon from 'assets/img/currencyIcons/XRPcoin.webp';
import dogeIcon from 'assets/img/currencyIcons/DOGEcoin.webp';

import btcCoinIcon from 'assets/img/CoinICons/coinBTCIcon.webp';
import trxCoinIcon from 'assets/img/CoinICons/coinTRXIcon.webp';
import bnbCoinIcon from 'assets/img/CoinICons/coinBNBIcon.webp';
import starCoinIcon from 'assets/img/CoinICons/coinStarCoin.webp';
import ethCoinIcon from 'assets/img/CoinICons/coinEthIcon.webp';
import xrpCoinIcon from 'assets/img/CoinICons/XRPCoinIcon.webp';
import ltcCoinIcon from 'assets/img/CoinICons/LTCCoinIcon.webp';
import dogeCoinIcon from 'assets/img/CoinICons/DOGECoinIcon.webp';
import usdtCoinIcon from 'assets/img/CoinICons/USDTCoinIcon.webp';

import { media } from 'config';
import { SettingName } from 'components/constants/constants';

export function debounce(func: VoidFunction, ms = 500): VoidFunction {
  let timeout: NodeJS.Timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, ms);
  };
}

export const getDate = (date: string | number | Date, withTime = false): string => {
  return dayjs(date).format(withTime ? 'MMM DD, h:mm:ss A' : 'MMM DD, YYYY');
};

export const getImgSrc = (string: string): string => {
  switch (string) {
    case 'BTC':
      return btcIcon;
    case 'STAR':
      return startIcon;
    case 'ETH':
      return etherIcon;
    case 'LTC':
      return liteCoinIcon;
    case 'TRX':
      return tronIcon;
    case 'BNB':
      return bnbIcon;
    case 'BSC':
      return bnbIcon;
    case 'USDT':
      return usdtIcon;
    case 'XRP':
      return xrpIcon;
    case 'DOGE':
      return dogeIcon;
    case 'USD':
      return usdIcon;
    default:
      return null;
  }
};
export const getCoinSrc = (string: string): string => {
  switch (string) {
    case 'BTC':
      return btcCoinIcon;
    case 'TRX':
      return trxCoinIcon;
    case 'BNB':
      return bnbCoinIcon;
    case 'STAR':
      return starCoinIcon;
    case 'XRP':
      return xrpCoinIcon;
    case 'LTC':
      return ltcCoinIcon;
    case 'USDT':
      return usdtCoinIcon;
    case 'DOGE':
      return dogeCoinIcon;
    case 'ETH':
      return ethCoinIcon;
    default:
      return null;
  }
};

export function numberWithCommas(str: string): string {
  const parts = str.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

const cutDothEnd = (balance: string | number) => {
  if (typeof balance === 'number') {
    return balance;
  }
  if (typeof balance === 'string') {
    const dataBalance = balance.split('');
    if (dataBalance[dataBalance.length - 1] === '.') {
      dataBalance.pop();
      const result = dataBalance.join('');
      return result;
    } else {
      return balance;
    }
  }
};

export const amount = (balance: string | number, comas = true, decimalLength = 8): string | number => {
  if (!balance) return;

  const length = balance.toString().length;
  const additionalString = Array(decimalLength).fill('0').join('');

  if (length <= 9) {
    const doth = balance.toString().search('\\.');
    const isFirstMinus = balance.toString().match('-');

    if (doth !== -1) {
      const result = String(balance) + additionalString.slice(0, (isFirstMinus ? 11 : 10) - length);

      if (comas) {
        const result2 = numberWithCommas(result);

        return cutDothEnd(result2);
      }

      return cutDothEnd(result);
    }

    const result = String(balance) + '.' + additionalString.slice(0, (isFirstMinus ? 10 : 9) - length);

    if (comas) {
      const result2 = numberWithCommas(result);

      return cutDothEnd(result2);
    } else {
      return cutDothEnd(result);
    }
  }

  const cutEnd = cutDothEnd(balance);
  const isFirstMinus = cutEnd.toString().match('-');
  const withComas = numberWithCommas(cutEnd.toString().slice(0, isFirstMinus ? 11 : 10));
  const withoutComas = cutEnd.toString().slice(0, isFirstMinus ? 11 : 10);
  return comas ? cutDothEnd(withComas) : cutDothEnd(withoutComas);
};

export const fillArray = (length: number): string[] => {
  const array = [];
  for (let i = 0; i < length; i++) {
    array[i] = i;
  }
  return array;
};
export const BN = (value1: string | number, fixed: number): unknown => {
  const BN1 = new BigNumber(value1).multipliedBy(1);
  return BN1.decimalPlaces(fixed);
};

export const BNMinus = (value1: string | number, value2: string | number): string => {
  const BN1 = new BigNumber(value1).toFixed(9);
  const BN2 = new BigNumber(value2).toFixed(9);
  const BN3 = new BigNumber(+BN1 - +BN2);
  return BN3.toFixed(9).valueOf();
};

export const BNDivide = (value1: string | number, divide: number): unknown => {
  const BN1 = new BigNumber(value1);
  return BN1.dividedBy(divide).toFixed(9);
};

export const BNMultiply = (value1: string | number, divide: number | string): string => {
  const BN1 = new BigNumber(value1);
  return BN1.multipliedBy(divide).toFixed(9);
};

export const BNPlus = (value1: string | number, value2: string | number, decimals = 9): string => {
  const BN1 = new BigNumber(value1).toFixed(decimals);
  const BN2 = new BigNumber(value2).toFixed(decimals);
  const BN3 = new BigNumber(+BN1 + +BN2);
  return BN3.toFixed(decimals);
};
export const BigPlus = (value1: string | number, value2: string | number): string => {
  const BN1 = Big(value1).plus(value2).toFixed(9);

  return BN1.valueOf();
};

export const removeComas = (currentAmount: string, decimals = 9): string => {
  if (!currentAmount || currentAmount.length < 1) {
    return '';
  }

  const comasRegex = /,/g;
  const formattedValue = currentAmount.replace(comasRegex, '');
  const BN1 = new BigNumber(formattedValue).toFixed(decimals);

  return BN1.valueOf();
};

export const parseErrorMessage = (message: string): any => {
  try {
    return JSON.parse(message);
  } catch {
    return undefined;
  }
};

export const getCommonSettingsValue = (array: Array<{ name: string; value: string }>, settingName: string): string => {
  const res = array.find((el) => el.name === settingName);
  return res?.value;
};

export const getArrayFromString = (str: string): Array<{ title: string }> => {
  const splitStr = str.split('#');
  const reg = '\\n';
  return splitStr.filter((item) => item).map((el) => ({ title: el.replace(reg, '') }));
};

export const getCurrentMfaMethod = (securityData?: IUserSecurity): MFATypes | '' => {
  if (securityData?.google_2fa) {
    return MFATypes.google;
  }

  if (securityData?.email_2fa) {
    return MFATypes.email;
  }

  return '';
};

export const getBgImage = (settings: Array<{ name: string; value: string }>): string | null => {
  const image = getCommonSettingsValue(settings, SettingName.background);
  if (image) {
    return `${media.background}${image}`;
  }
  return null;
};

export const handleSelectInputValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
  setTimeout(() => e.target.select(), 0);
};

export const getExchangeRates = (list: Array<{ token: { tokenCode: string }; usdValue: string }>): IExchangeRates => {
  const rates = {};
  list.forEach((rate) => {
    rates[rate.token.tokenCode] = rate.usdValue;
  });
  return rates;
};

export const getAmountWithoutZeroEnd = (balance: string): string => {
  const formattedBalance = String(amount(balance));
  let newBalance = formattedBalance.replace(/0*$/, '');
  if (newBalance[newBalance.length - 1] === '.') {
    newBalance = newBalance.slice(0, -1);
  }
  return newBalance;
};

export const prepareAmount = (value: string | number | Big, decimals = 9): string => {
  const formatDigitRegex = /[^\d.]/gim;

  if (!value || Number.isNaN(value)) {
    const newAmount = '0';

    return Big(newAmount).toFixed(decimals);
  }

  const newAmount = String(value).replace(formatDigitRegex, '');

  return Big(newAmount).toFixed(decimals);
};

export const getCurrencySymbol = (currency: string): string => {
  if (currency === 'USD') {
    return '$';
  }

  if (currency === 'EUR') {
    return '€';
  }

  return '';
};

export const convertCryptoToUSD = (rate: string, amountValue: string | number): string => {
  if (!rate || !amountValue) {
    return '0.00';
  }
  const regexpression = /^(\d+\.\d{2})\d+$/;
  const counted = Big(amountValue).mul(rate).toFixed();
  const value = counted.match(regexpression);
  return value ? value[1] : Big(counted).toFixed(2);
};

export const convertUsdToCrypto = (rate: string, amountValue: string | number): string => {
  if (!rate || !amountValue) {
    return '0.00';
  }

  return Big(amountValue).mul(rate).toFixed(3);
};

export const convertUSDToCrypto = (rate: string, cryptoAmount: string): string => {
  if (!rate || !cryptoAmount) {
    return '0.00';
  }

  const oneUSD = Big(1).div(rate).toFixed();
  const result = Big(oneUSD).mul(cryptoAmount).toFixed();

  return String(amount(result));
};

export const createFirstLetterCapital = (str: string): string => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const checkIsVip = (vipRank: number, userRank = 1) => {
  return userRank >= vipRank;
};
