import clsx from 'clsx';

import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';
import { IAllGamesHistoryNew } from './types';

const AllGameHistoryNew: React.FC<IAllGamesHistoryNew> = ({ data }) => {
  const { translate } = useLocalization();

  return (
    <div className={styles.allGamesHistoryWrap}>
      <div className={styles.allGamesHistoryTitle}>{translate('inHouse.games.history')}</div>
      <div className={styles.allGamesHistoryContent}>
        {data?.length > 0 &&
          data.map((b) => {
            const betResult = b.result.toLowerCase();

            return (
              <div key={b.id} className={styles.allGamesHistoryContentElem}>
                <p
                  className={clsx(
                    styles.result,
                    betResult === 'win' ? styles.win : '',
                    betResult === 'lose' || betResult === 'loss' ? styles.loss : '',
                    betResult === 'tie' ? styles.tie : '',
                  )}
                >
                  {b.num}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AllGameHistoryNew;
