import firstMission from 'assets/img/Missions/Stages/EmptyStages/1.0.webp';
import firstMissionOneHalf from 'assets/img/Missions/Stages/Stage1/1.0.5.webp';
import firstMissionOneFull from 'assets/img/Missions/Stages/Stage1/1.1.webp';
import firstMissionTwoHalf from 'assets/img/Missions/Stages/Stage1/1.1.5.webp';
import firstMissionTwoFull from 'assets/img/Missions/Stages/Stage1/1.2.webp';
import firstMissionThreeHalf from 'assets/img/Missions/Stages/Stage1/1.2.5.webp';
import firstMissionThreeFull from 'assets/img/Missions/Stages/Stage1/1.3.webp';

import secondMission from 'assets/img/Missions/Stages/EmptyStages/2.0.webp';
import secondMissionOneHalf from 'assets/img/Missions/Stages/Stage2/2.0.5.webp';
import secondMissionOneFull from 'assets/img/Missions/Stages/Stage2/2.1.webp';
import secondMissionTwoHalf from 'assets/img/Missions/Stages/Stage2/2.1.5.webp';
import secondMissionTwoFull from 'assets/img/Missions/Stages/Stage2/2.2.webp';
import secondMissionThreeHalf from 'assets/img/Missions/Stages/Stage2/2.2.5.webp';
import secondMissionThreeFull from 'assets/img/Missions/Stages/Stage2/2.3.webp';

import thirdMission from 'assets/img/Missions/Stages/EmptyStages/3.0.webp';
import thirdMissionOneHalf from 'assets/img/Missions/Stages/Stage3/3.0.5.webp';
import thirdMissionOneFull from 'assets/img/Missions/Stages/Stage3/3.1.webp';
import thirdMissionTwoHalf from 'assets/img/Missions/Stages/Stage3/3.1.5.webp';
import thirdMissionTwoFull from 'assets/img/Missions/Stages/Stage3/3.2.webp';
import thirdMissionThreeHalf from 'assets/img/Missions/Stages/Stage3/3.2.5.webp';
import thirdMissionThreeFull from 'assets/img/Missions/Stages/Stage3/3.3.webp';

import fourthMission from 'assets/img/Missions/Stages/EmptyStages/4.0.webp';
import fourthMissionOneHalf from 'assets/img/Missions/Stages/Stage4/4.0.5.webp';
import fourthMissionOneFull from 'assets/img/Missions/Stages/Stage4/4.1.webp';
import fourthMissionTwoHalf from 'assets/img/Missions/Stages/Stage4/4.1.5.webp';
import fourthMissionTwoFull from 'assets/img/Missions/Stages/Stage4/4.2.webp';
import fourthMissionThreeHalf from 'assets/img/Missions/Stages/Stage4/4.2.5.webp';
import fourthMissionThreeFull from 'assets/img/Missions/Stages/Stage4/4.3.webp';

import fifthMission from 'assets/img/Missions/Stages/EmptyStages/5.0.webp';
import fifthMissionOneHalf from 'assets/img/Missions/Stages/Stage5/5.0.5.webp';
import fifthMissionOneFull from 'assets/img/Missions/Stages/Stage5/5.1.webp';
import fifthMissionTwoHalf from 'assets/img/Missions/Stages/Stage5/5.1.5.webp';
import fifthMissionTwoFull from 'assets/img/Missions/Stages/Stage5/5.2.webp';
import fifthMissionThreeHalf from 'assets/img/Missions/Stages/Stage5/5.2.5.webp';
import fifthMissionThreeFull from 'assets/img/Missions/Stages/Stage5/5.3.webp';

import popUpEmptyStars from 'assets/img/Missions/Stars/0.webp';
import popUpOneHalfStar from 'assets/img/Missions/Stars/05.webp';
import popUpOneStar from 'assets/img/Missions/Stars/1.webp';
import popUpTwoHalfStar from 'assets/img/Missions/Stars/15.webp';
import popUpTwoStar from 'assets/img/Missions/Stars/2.webp';
import popUpThreeHalfStar from 'assets/img/Missions/Stars/25.webp';
import popUpThreeStar from 'assets/img/Missions/Stars/3.webp';

import dicePopUpImage from 'assets/img/Missions/PopUpsImages/Dice.webp';
import minesPopUpImage from 'assets/img/Missions/PopUpsImages/Mines.webp';
import limboPopUpImage from 'assets/img/Missions/PopUpsImages/Limbo.webp';
import coinPopUpImage from 'assets/img/Missions/PopUpsImages/Coinflip.webp';
import slotsPopUpImage from 'assets/img/Missions/PopUpsImages/Slots.webp';
import kenoPopUpImage from 'assets/img/Missions/PopUpsImages/Keno.webp';

import { MissionPositions, MissionStars } from 'types';

type TMissionImagesWithProgress = {
  [key in MissionStars]: [string, string, string];
};

type TMissionImagesWithProgressByPosition = {
  [key in MissionPositions]: TMissionImagesWithProgress;
};

export const MISSION_IMAGE_BY_POSITION = [firstMission, secondMission, thirdMission, fourthMission, fifthMission];

export const PERCENTAGE_OF_COMPLETED_MISSION = 100; // 100%
export const PERCENTAGE_OF_HALF_COMPLETED_MISSION = 50; // 50%

export const MISSION_IMAGES_WITH_PROGRESS_BY_POSTION: TMissionImagesWithProgressByPosition = {
  1: {
    one: [firstMission, firstMissionOneHalf, firstMissionOneFull],
    two: [firstMissionOneFull, firstMissionTwoHalf, firstMissionTwoFull],
    three: [firstMissionTwoFull, firstMissionThreeHalf, firstMissionThreeFull],
  },
  2: {
    one: [secondMission, secondMissionOneHalf, secondMissionOneFull],
    two: [secondMissionOneFull, secondMissionTwoHalf, secondMissionTwoFull],
    three: [secondMissionTwoFull, secondMissionThreeHalf, secondMissionThreeFull],
  },
  3: {
    one: [thirdMission, thirdMissionOneHalf, thirdMissionOneFull],
    two: [thirdMissionOneFull, thirdMissionTwoHalf, thirdMissionTwoFull],
    three: [thirdMissionTwoFull, thirdMissionThreeHalf, thirdMissionThreeFull],
  },
  4: {
    one: [fourthMission, fourthMissionOneHalf, fourthMissionOneFull],
    two: [fourthMissionOneFull, fourthMissionTwoHalf, fourthMissionTwoFull],
    three: [fourthMissionTwoFull, fourthMissionThreeHalf, fourthMissionThreeFull],
  },
  5: {
    one: [fifthMission, fifthMissionOneHalf, fifthMissionOneFull],
    two: [fifthMissionOneFull, fifthMissionTwoHalf, fifthMissionTwoFull],
    three: [fifthMissionTwoFull, fifthMissionThreeHalf, fifthMissionThreeFull],
  },
};

export const MISSION_POPUPS_IMAGES_WITH_PROGRESS: TMissionImagesWithProgress = {
  one: [popUpEmptyStars, popUpOneHalfStar, popUpOneStar],
  two: [popUpOneStar, popUpTwoHalfStar, popUpTwoStar],
  three: [popUpTwoStar, popUpThreeHalfStar, popUpThreeStar],
};

export const MISSION_ICONS = {
  dice: dicePopUpImage,
  mines: minesPopUpImage,
  limbo: limboPopUpImage,
  coinflip: coinPopUpImage,
  slots: slotsPopUpImage,
  keno: kenoPopUpImage,
};
