import { gql } from '@apollo/client';

export const GET_GAME_LIST = gql`
  query GameList($userId: String) {
    gameList(userId: $userId) {
      id
      gameId
      name
      image
      category
      isBlocked
      new
      isFavorite
      popular
      isBonusGame
      providerName
      currencies
    }
  }
`;

export const GET_PROVIDER_LIST = gql`
  query getProviders {
    getProviders {
      name
      logo
    }
  }
`;

export const INIT_GAME_SESSION = gql`
  query initGamePlay($slug: String!, $currency: String!, $sessionCurrency: String!) {
    initGame(slug: $slug, currency: $currency, sessionCurrency: $sessionCurrency) {
      url
      error {
        message
        name
        status
      }
    }
  }
`;

export const GET_GAME = gql`
  query game($gameParams: GameParams!) {
    game(gameParams: $gameParams) {
      id
      gameId
      name
      image
      category
      new
      popular
      slug
      currencies
      providerName
      isFavorite
      isBlocked
      isBonusGame
      isDropsAndWins
      vip
    }
  }
`;

export const GET_GAMES = gql`
  query gameList($gameListParams: GameListParams!) {
    gameList(gameListParams: $gameListParams) {
      items {
        id
        gameId
        name
        image
        category
        new
        popular
        slug
        currencies
        providerName
        isFavorite
        isBlocked
        isBonusGame
        isDropsAndWins
        isBlocked
        vip
      }
      subcategories
      count
    }
  }
`;

export const GET_GAMES_SLIDER = gql`
  query categoryGameList($categoryGameListParams: CategoryGameListParams!) {
    categoryGameList(categoryGameListParams: $categoryGameListParams) {
      InHouse {
        id
        gameId
        name
        image
        category
        new
        popular
        slug
        currencies
        providerName
        isFavorite
        isBlocked
        isBonusGame
        isDropsAndWins
        vip
      }
      LiveCasino {
        id
        gameId
        name
        image
        category
        new
        popular
        slug
        currencies
        providerName
        isFavorite
        isBlocked
        isBonusGame
        isDropsAndWins
        vip
      }
      Slots {
        id
        gameId
        name
        image
        category
        new
        popular
        slug
        currencies
        providerName
        isFavorite
        isBlocked
        isBonusGame
        isDropsAndWins
        vip
      }
      TableGames {
        id
        gameId
        name
        image
        category
        new
        popular
        slug
        currencies
        providerName
        isFavorite
        isBlocked
        isBonusGame
        isDropsAndWins
        vip
      }
    }
  }
`;
