import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ContextPopUps } from 'context';
import LiveStatsContext from 'context/contextLiveStats/context';
import { useMines } from 'context/contextMines/contextMines';
import { Types } from 'context/contextMines/minesReduser';
import { popUps } from 'components/constants/constants';
import { EGamesButtonsBar, EGamesNames } from 'components/constants/games';
import { useLocalization } from 'components/Internationalization';

import GameOptionsButtonsNew from 'components/Games/base/GameOptionsButtonsNew';

const MinesNavigationButtons: React.FC = () => {
  const { translate } = useLocalization();

  const [state, dispatch] = useMines();

  const { enabled, onToggleStatistics } = useContext(LiveStatsContext);
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const isMobile = useMediaQuery({ query: '(max-width: 1260px)' });

  const { fastMode, isHotkeys, gameId, hash } = state;

  const handleToggleStats = () => {
    onToggleStatistics(!enabled, isMobile ? false : undefined);
  };

  const handleFastMode = () => dispatch({ type: Types.FAST_MODE });

  const handleHotKey = () => dispatch({ type: Types.HOTKEYS });

  const handleSwitchHotKey = () =>
    setPopUpsOpen({ modalOpen: popUps.hotKeys, data: { onSwitch: handleHotKey, isHotKeys: isHotkeys } });

  const handleLimits = () => setPopUpsOpen({ modalOpen: popUps.gamesLimits, data: { gameId } });

  const handleRules = () => setPopUpsOpen({ modalOpen: popUps.gamesRules, data: { gameName: EGamesNames.mines } });

  const handleVerify = () => setPopUpsOpen({ modalOpen: popUps.minesVerify, data: { hash } });

  const dataButtons = [
    {
      toolTipText: translate('inHouse.tooltip.live'),
      activeImg: enabled,
      func: handleToggleStats,
      id: EGamesButtonsBar.statistic,
    },
    {
      toolTipText: translate('inHouse.tooltip.speed'),
      activeImg: fastMode,
      func: handleFastMode,
      id: EGamesButtonsBar.speed,
    },
    {
      toolTipText: translate('inHouse.tooltip.verify'),
      activeImg: false,
      func: handleVerify,
      id: EGamesButtonsBar.verify,
    },
    {
      toolTipText: translate('inHouse.tooltip.limits'),
      activeImg: false,
      func: handleLimits,
      id: EGamesButtonsBar.limits,
    },
    {
      toolTipText: translate('inHouse.tooltip.rules'),
      activeImg: false,
      func: handleRules,
      id: EGamesButtonsBar.rules,
    },
    {
      toolTipText: translate('inHouse.tooltip.keys'),
      activeImg: isHotkeys,
      func: handleSwitchHotKey,
      id: EGamesButtonsBar.keys,
    },
  ];

  return <GameOptionsButtonsNew dataButtons={dataButtons} />;
};

export default MinesNavigationButtons;
