import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import LiveStatsContext from 'context/contextLiveStats/context';

import LiveStatistics from 'components/Base/LiveStatistics';
import LotteryDesktop from 'components/Games/Lottery/LotteryDesktop/LotteryDesktop';

import styles from './styles.module.scss';
import LimboHistory from './components/LimboHistory';
import LimboNavigationButtons from './components/LimboNavigationButtons';
import LimboTables from './components/LimboTables';
import LimboAutoSettings from './components/LimboAutoSettings';
import LimboBetAmountBlock from './components/LimboBetamountBlock';
import LimboSubmitButton from './components/LimboSubmitButton';
import LimboOptionInput from './components/LimboOptionInput';
import LimboScreen from './components/LimboScreen';
import LimboWinInfo from './components/LimboWinInfo';
import LimboSwitchAuto from './components/LimboSwitchAuto';

const Limbo: React.FC = () => {
  const { enabled, visible } = useContext(LiveStatsContext);

  const isMobile = useMediaQuery({ query: '(max-width: 1260px)' });

  return (
    <div className={styles.limboGameWrap}>
      <div className={styles.limboGameContentWrap}>
        <div className={styles.limboGameContent}>
          <div className={styles.limboGameContentHeader}>
            <LimboHistory />
            <LimboNavigationButtons />
          </div>
          <div className={styles.limboGameContentMain}>
            <LimboScreen />
            <LimboWinInfo />
            {isMobile ? (
              enabled && visible ? (
                <LiveStatistics mobile={isMobile} className={styles.statistics} />
              ) : null
            ) : null}
          </div>
          <div className={styles.limboGameContentNavigation}>
            <LimboBetAmountBlock />
            <LimboOptionInput />
            <div className={styles.limboGameContentNavigationSubmitWrap}>
              <LimboSubmitButton />
              <LimboSwitchAuto />
            </div>
          </div>
        </div>
        <LotteryDesktop />
      </div>
      <div className={styles.limboGameAutoSettings}>
        <LimboAutoSettings />
      </div>
      <div className={styles.limboGameTableWrap}>
        <LimboTables />
      </div>
    </div>
  );
};

export default React.memo(Limbo);
