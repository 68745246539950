import clsx from 'clsx';
import { useEffect, useState } from 'react';

import peopleIcon from 'assets/img/HomePage/NvigationImages/referral1.webp';
import usdIcon from 'assets/img/HomePage/NvigationImages/referral2.webp';

import { OutLinks } from 'components/constants/constants';
import { getCoinSrc } from 'func/common';
import { useLocalization } from 'components/Internationalization';

import PromoBlock from '../PromoBlock';

import styles from './styles.module.scss';

const AffiliateNavigationItem: React.FC = () => {
  const { language, translate } = useLocalization();

  const [title, setTitle] = useState('Earn');
  const [subtitle, setSubtitle] = useState('Referral');

  useEffect(() => {
    const newText = translate('homepage.welcome.referral');

    const [newTitle, ...newSubtitle] = newText.split(' ');

    setTitle(newTitle);
    setSubtitle(newSubtitle.join(' '));
  }, [language]);

  return (
    <PromoBlock
      className={styles.wrapper}
      bgClassName={clsx(styles.bg, styles.referralBg)}
      title={title}
      subtitle={subtitle}
      link={OutLinks.affiliateApp}
      externalLink
    >
      <img src={usdIcon} alt="icon" className={styles.referralUsd} />
      <img src={getCoinSrc('STAR')} alt="icon" className={styles.referralStar} />
      <img src={peopleIcon} alt="icon" className={styles.referralPeople} />
    </PromoBlock>
  );
};

export default AffiliateNavigationItem;
