import clsx from 'clsx';
import { useContext } from 'react';

import { ReactComponent as RefreshIcon } from 'assets/img/common/refresh.svg';

import LiveStatsContext from 'context/contextLiveStats/context';

import ButtonTooltip from 'components/Base/ButtonTooltip';
import { useLocalization } from 'components/Internationalization';

import LiveStatisticsData from './components/LiveStatisticsData';
import LiveStatisticsChart from './components/LiveStatisticsChart';

import styles from './styles.module.scss';
import { ILiveStatisticsComponent } from './types';

const LiveStatistics: React.FC<ILiveStatisticsComponent> = ({ mobile, className = '', chartClassName = '' }) => {
  const { onReset } = useContext(LiveStatsContext);
  const { translate } = useLocalization();

  return (
    <article className={clsx(styles.wrapper, className, mobile ? styles.mobile : '')}>
      <div className={styles.titleWrapper}>
        <h6 className={styles.title}>{translate('life.statistics.title')}</h6>
        <div className={styles.buttons}>
          <ButtonTooltip className={styles.tooltip} text="Reset">
            <button className={styles.button} type="button" onClick={onReset}>
              <RefreshIcon className={styles.icon} />
            </button>
          </ButtonTooltip>
        </div>
      </div>
      <LiveStatisticsData />
      <LiveStatisticsChart className={chartClassName} />
    </article>
  );
};

export default LiveStatistics;
