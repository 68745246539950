import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';

import { amount } from 'func/common';
import { useLocalization } from 'components/Internationalization';

import InfoBetsDicePopUpStyle from './styles';

interface Props {
  inputRangeValue: number[];
  rollUnderValue: boolean;
  checkboxValue: boolean;
  inputBetValue: string | number;
  chance: null | number;
  multiplayer: null | string;
  tokenCode: string;
}

const InfoBetsDicePopUp: React.FC<Props> = ({
  rollUnderValue,
  inputRangeValue,
  inputBetValue,
  checkboxValue,
  chance,
  multiplayer,
  tokenCode,
}) => {
  const { translate } = useLocalization();

  const [payoutUnder, setPayoutUnder] = useState('');
  const [payoutOver, setPayoutOver] = useState('');

  useEffect(() => {
    if (rollUnderValue) {
      const BN = new BigNumber(+inputBetValue * +multiplayer).toFixed();
      setPayoutUnder(String(amount(BN.valueOf())));
      return;
    }

    const BN = new BigNumber(+inputBetValue * +multiplayer).toFixed();

    setPayoutOver(String(amount(BN.valueOf())));
  }, [rollUnderValue, inputRangeValue, inputBetValue]);

  return (
    <InfoBetsDicePopUpStyle checkboxValue={checkboxValue}>
      <div className="info__bets-elem1">
        <p>{translate('popups.bets.dice.chance')}:</p>
        <h3>{chance} %</h3>
      </div>
      <div className="info__bets-elem2">
        <p>{translate('popups.bets.dice.multiplier')}: </p>
        <h3>
          {' X '}
          {multiplayer}
        </h3>
      </div>
      <div className="info__bets-elem3">
        <p>{translate('popups.bets.dice.payout')}: </p>
        <h3>
          {rollUnderValue ? payoutUnder : payoutOver} {` ${tokenCode}`}
        </h3>
      </div>
      <div className="info__bets-elem4">
        <p>{translate('popups.bets.dice.auto')}</p>
        <div className="info__bets-checkbox-wrap1">
          <input id="checkbox" type="checkbox" checked={checkboxValue} disabled onChange={() => {}} />
          <label htmlFor="checkbox" className="info__bets-checkbox-label" />
        </div>
      </div>
    </InfoBetsDicePopUpStyle>
  );
};

export default InfoBetsDicePopUp;
