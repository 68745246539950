import React, { useContext, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import { useMines } from 'context/contextMines/contextMines';
import { ContextTokenCode } from 'context';
import { detectSliderChange } from 'func/prepareDataMines';
import { useLocalization } from 'components/Internationalization';

import MinesSliderDataStyle from './styles';

const MinesSlider: React.FC = () => {
  const { translate } = useLocalization();
  const [state] = useMines();
  const { tokenCode } = useContext(ContextTokenCode);

  const { minesSelected, currentSliderNumber, sliderData, gameCondition } = state;

  const [slidesShow, setSlidesShow] = useState(9);
  const [sliderSessionUpdate, setSliderSessionUpdate] = useState({ token: null, slide: 0 });

  const carouselRef = useRef(null);
  const fullScreen = 6;
  const daskTop = 4;
  const tablet = 5;
  const mobile = 4;
  const sliders = sliderData[minesSelected];

  useEffect(() => {
    if (tokenCode.token && currentSliderNumber) {
      if (currentSliderNumber !== sliderSessionUpdate.slide) {
        setSliderSessionUpdate((prev) => ({ ...prev, slide: currentSliderNumber }));
        if (tokenCode.token !== sliderSessionUpdate.token) {
          setSliderSessionUpdate((prev) => ({ ...prev, token: tokenCode.token }));
          carouselRef.current.slickGoTo(currentSliderNumber - 1);
        }
      }
    }
  }, [currentSliderNumber, slidesShow, tokenCode.token, sliderSessionUpdate]);

  useEffect(() => {
    if (carouselRef.current?.slickGoTo) {
      if (detectSliderChange(currentSliderNumber, slidesShow)) {
        carouselRef.current.slickGoTo(currentSliderNumber - 1);
      }
    }
  }, [currentSliderNumber, slidesShow]);

  useEffect(() => {
    // if (gameCondition === MINES_CONDITION.finished) {
    //   carouselRef.current.slickGoTo(0);
    // }
    if (!currentSliderNumber) {
      carouselRef.current.slickGoTo(0);
    }
  }, [gameCondition, currentSliderNumber]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    className: 'mines-slider',
    slidesToShow: sliders.length > slidesShow ? slidesShow : sliders.length,
    slidesToScroll: sliders.length > slidesShow ? slidesShow : sliders.length,
    initialSlide: 0,
    arrows: true,
    autoplay: false,
    onReInit: () => setSlidesShow(fullScreen),
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          onReInit: () => setSlidesShow(daskTop),
        },
      },
      {
        breakpoint: 1100,
        settings: {
          onReInit: () => setSlidesShow(tablet),
        },
      },
      {
        breakpoint: 768,
        settings: {
          onReInit: () => setSlidesShow(tablet),
        },
      },
      {
        breakpoint: 480,
        settings: {
          onReInit: () => setSlidesShow(mobile),
        },
      },
    ],
  };

  return (
    <MinesSliderDataStyle>
      <Slider {...settings} ref={carouselRef}>
        {sliders.map((slide, index: number) => (
          <div key={slide} className={currentSliderNumber === index + 1 ? 'slide active' : 'slide'}>
            <h6>{slide}x</h6>
            <h5>
              {index + 1} {translate('mines.hits.text')}
            </h5>
          </div>
        ))}
      </Slider>
    </MinesSliderDataStyle>
  );
};

export default MinesSlider;
