import depositIconEnable from 'assets/img/PopUps/Wallets/deposti-enable.webp';
import depositIconDisable from 'assets/img/PopUps/Wallets/deposit-disable.webp';
import withdrawIconEnable from 'assets/img/PopUps/Wallets/withdraw-enable.webp';
import withdrawIconDisable from 'assets/img/PopUps/Wallets/withdraw-disable.webp';
import tipsIconEnable from 'assets/img/PopUps/Wallets/tips-enable.webp';
import tipsIconDisable from 'assets/img/PopUps/Wallets/tips-disable.webp';
import vaultIconEnable from 'assets/img/PopUps/Wallets/vault-enable.webp';
import vaultIconDisable from 'assets/img/PopUps/Wallets/vault-disable.webp';
import buyIconEnable from 'assets/img/PopUps/Wallets/buy-cripto-enable.webp';
import buyIconDisable from 'assets/img/PopUps/Wallets/buy-crypto-disable.webp';

import { TranslationType } from 'types';
import { IWalletNav, WalletActions } from 'components/Base/PopUps/components/base/WalletNavigation/types';
import { popUps } from './constants';

export enum EVault {
  withdraw = 'withdrawal',
  add = 'add',
}

export const getWalletNav = (t: TranslationType): IWalletNav[] => {
  return [
    {
      title: t('popups.wallet.nav.deposit'),
      action: WalletActions.deposit,
      active: [popUps.deposit],
      imageEnable: depositIconEnable,
      imageDisable: depositIconDisable,
    },
    {
      title: t('popups.wallet.nav.withdraw'),
      action: WalletActions.withdraw,
      active: [popUps.withdraw, popUps.withdrawSuccess, popUps.addressBook, popUps.saveWalletAddressForm],
      imageEnable: withdrawIconEnable,
      imageDisable: withdrawIconDisable,
    },
    {
      title: t('popups.wallet.nav.buy.crypto'),
      action: WalletActions.buy,
      active: [popUps.buyCrypto],
      imageEnable: buyIconEnable,
      imageDisable: buyIconDisable,
    },
    {
      title: t('popups.wallet.nav.tips'),
      action: WalletActions.tips,
      active: [popUps.tipsRequest, popUps.tipsConfirm],
      imageEnable: tipsIconEnable,
      imageDisable: tipsIconDisable,
    },
    {
      title: t('popups.wallet.nav.vault'),
      action: WalletActions.vault,
      active: [popUps.vault, popUps.vaultConfirm],
      imageEnable: vaultIconEnable,
      imageDisable: vaultIconDisable,
    },
  ];
};
