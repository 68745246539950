import React, { useState } from 'react';

export type PopupsCallback = (elem: string | { modalOpen: string; data: any }) => void;

export const ContextPopUps = React.createContext({
  popUpsOpen: null,
  setPopUpsOpen: (elem: string | { modalOpen: string; data: any }) => {},
});

export const PopUpsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [popUpsOpen, setPopUpsOpen] = useState(null);

  return (
    <ContextPopUps.Provider
      value={{
        popUpsOpen,
        setPopUpsOpen,
      }}
    >
      {children}
    </ContextPopUps.Provider>
  );
};
