import React from 'react';

import { getCoinSrc } from 'func/common';

import { IMinesWinAnimation } from './types';
import styles from './styles.module.scss';

const WinMinesAnimation: React.FC<IMinesWinAnimation> = ({ multiplier, tokenCode, amount }) => (
  <div className={styles.minesWinAnimationContent}>
    <div className={styles.minesWinAnimationContentItem1}>
      <p>{`x ${multiplier}`}</p>
    </div>
    <div className={styles.minesWinAnimationContentItem2}>WIN</div>
    <div className={styles.minesWinAnimationContentItem3}>
      <img src={getCoinSrc(tokenCode)} className={styles.minesWinAnimationContentImageSmall} alt="icon" />
      <p className={styles.minesWinAnimationContentWinAmount}>{`${amount} ${tokenCode}`}</p>
    </div>
    <img src={getCoinSrc(tokenCode)} className={styles.minesWinAnimationContentImageBig} alt="icon" />
    <img src={getCoinSrc(tokenCode)} className={styles.minesWinAnimationContentImageMiddle1} alt="icon" />
    <img src={getCoinSrc(tokenCode)} className={styles.minesWinAnimationContentImageMiddle2} alt="icon" />
    <img src={getCoinSrc(tokenCode)} className={styles.minesWinAnimationContentImageMiddle3} alt="icon" />
    <img src={getCoinSrc(tokenCode)} className={styles.minesWinAnimationContentImageSmall1} alt="icon" />
    <img src={getCoinSrc(tokenCode)} className={styles.minesWinAnimationContentImageSmall2} alt="icon" />
    <img src={getCoinSrc(tokenCode)} className={styles.minesWinAnimationContentImageSmall3} alt="icon" />
    <div className={styles.minesWinAnimationContentShadow} />
  </div>
);

export default WinMinesAnimation;
