import { useContext, useState, useEffect } from 'react';
import Big from 'big.js';

import { useAppSelector } from 'hooks/useAppSelector';
import DiceGameContext from 'context/contextDiceGame/context';
import SocketContext from 'context/contextSocket/context';
import { removeComas } from 'func/common';
import { ContextPopUps } from 'context';
import { popUps } from 'components/constants/constants';

import BetAmountBlock from 'components/Games/base/BetAmountBlock';
import BetButton from 'components/common/BetButton';
import { userToken } from 'store/user/user.selectors';

import styles from './styles.module.scss';
import DiceGameSwitchAuto from '../DiceGameSwitchAuto';

const DiceGameControls: React.FC = () => {
  const token = useAppSelector(userToken);

  const {
    auto,
    autoStarted,
    animationInProgress,
    betInProgress,
    amount: betAmount,
    withHotKeys,
    gameSettings,
    onChangeAmount,
    onBet,
    onAutoBet,
    diceId,
  } = useContext(DiceGameContext);
  const { connected: socketConnected } = useContext(SocketContext);
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [lessThenMinBet, setLessThenMinBet] = useState(false);

  const handleSignUp = () => setPopUpsOpen(popUps.registration);

  useEffect(() => {
    const result = Big(gameSettings?.minimumBet || '0').gt(removeComas(betAmount || '0'));

    setLessThenMinBet(result);
  }, [gameSettings, betAmount]);

  return (
    <article className={styles.wrapper}>
      <div className={styles.betAmount}>
        <BetAmountBlock
          minimumBet={gameSettings?.minimumBet}
          maxBet={gameSettings?.maximumBet}
          betAmountValue={betAmount}
          disable={autoStarted || betInProgress || !socketConnected}
          isHotKeys={withHotKeys}
          setBetAmountValue={onChangeAmount}
          gameId={diceId}
        />
      </div>
      <div className={styles.diceSubmitAutoWrap}>
        <BetButton
          auto={auto}
          autoStarted={autoStarted}
          disabled={
            (token && lessThenMinBet) || (!auto && animationInProgress) || betInProgress || (token && !socketConnected)
          }
          onBet={token ? onBet : handleSignUp}
          onAutoBet={token ? onAutoBet : handleSignUp}
        />
        <DiceGameSwitchAuto />
      </div>
    </article>
  );
};

export default DiceGameControls;
