/* eslint-disable no-useless-return */

import { useContext, useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';

import { IExchangeRates, ILotteryState, IUserNotification, SocketActions } from 'types';
import { popUps } from 'components/constants/constants';
import { ContextPopUps } from 'context/contextPopups';
import { useAppSelector } from 'hooks/useAppSelector';
import { createSocket } from 'context/contextSocket/socket';
import { useSession } from 'context/contextSessionManagement/context';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setExchangeRates, setLotteryState } from 'store/generalData';
import { userToken } from 'store/user/user.selectors';
import { GraphqlErrors } from 'components/constants';

import SocketContext from './context';
import { ISocketException, ISocketRankUpdate } from './types';

const SocketProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const dispatch = useAppDispatch();
  const { refreshTokens } = useSession();

  const [socket, setSocket] = useState<Socket | null>(null);
  const [connected, setConnected] = useState(false);
  const [inGame, setInGame] = useState(false);
  const [notification, setNotification] = useState(null);
  const [exception, setException] = useState(null);
  const [disconnectReason, setDisconnectReason] = useState(null);

  const token = useAppSelector(userToken);

  useEffect(() => {
    if (token && !socket) {
      const newSocket = createSocket(token);

      newSocket.on(SocketActions.connect, () => {
        handleConnect(newSocket);
      });
      newSocket.on(SocketActions.exceptions, handleSocketException);
      newSocket.on(SocketActions.disconnect, handleDisconnect);
      newSocket.on(SocketActions.rank, handleRankUpdate);
      newSocket.on(SocketActions.userNotification, handleNotification);
      newSocket.on(SocketActions.rates, handleRatesUpdate);
      newSocket.on(SocketActions.lottery, handleLotteryUpdate);

      console.log('[Socket]: connect'); // eslint-disable-line
      newSocket.connect();

      setSocket(newSocket);
    }

    return () => {
      socket?.offAny();
    };
  }, [token, socket]);

  // refresh auth token
  useEffect(() => {
    if (socket) {
      socket.disconnect();

      socket.auth = { token };

      socket.connect();
    }
  }, [token]);

  function handleConnect(newSocket: Socket) {
    console.log('[Socket]: connected'); // eslint-disable-line

    newSocket.emit(SocketActions.userNotifications);

    setConnected(true);
    setDisconnectReason(null);
  }

  function handleRankUpdate(rank: ISocketRankUpdate) {
    setPopUpsOpen({
      modalOpen: popUps.levelUp,
      data: {
        data: rank,
        config: popUps.levelUp,
      },
    });
  }

  function handleRatesUpdate(rates: IExchangeRates) {
    dispatch(setExchangeRates(rates));
  }

  function handleLotteryUpdate(lottery: ILotteryState) {
    dispatch(setLotteryState(lottery));
  }

  function handleNotification(n: IUserNotification) {
    setNotification(n);
  }

  function joinGameRoom() {
    if (connected) {
      socket.emit(SocketActions.joinGame, {}, () => {
        setInGame(true);
      });
    }
  }

  function leaveGameRoom() {
    if (connected) {
      socket.emit(SocketActions.leaveGame, {}, () => {
        setInGame(false);
      });
    }
  }

  async function handleSocketException(error: ISocketException) {
    const message = error.message.toLowerCase();

    if (message === GraphqlErrors.unauthorized || message === GraphqlErrors.unauthenticated) {
      await refreshTokens();
    }

    console.log('[Soket Error]:', error); // eslint-disable-line

    setException(error);
  }

  function handleDisconnect(reason: string) {
    console.log('[Socket]: disconnected, reason: ', reason); // eslint-disable-line

    setConnected(false);
    setInGame(false);
    setDisconnectReason(reason);
  }

  return (
    <SocketContext.Provider
      value={{
        socket,
        connected,
        inGame,
        exception,
        disconnectReason,
        notification,
        joinGameRoom,
        leaveGameRoom,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
