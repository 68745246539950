import React from 'react';
import { Indicator, Input, Label } from './styles';

interface Props {
  value?: string;
  checked: boolean;
  onChange?: (event: { target: HTMLInputElement }) => void;
  name?: string;
  id?: string;
  label?: string;
  disabled?: boolean;
  border?: boolean;
  topArrow?: boolean;
  shadow?: boolean;
  currencyStyle?: boolean;
  config?: string;
  arrowWidth?: string;
}

const Checkbox: React.FC<Props> = ({
  value,
  checked,
  onChange,
  name,
  id,
  label,
  disabled,
  border,
  topArrow,
  shadow,
  currencyStyle,
  config,
  arrowWidth,
}) => (
  <Label htmlFor={id} disabled={disabled ? 'true' : ''} currencystyle={currencyStyle ? 'true' : ''} config={config}>
    {label}
    <Input
      id={id}
      type="checkbox"
      name={name}
      value={value}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
    />
    <Indicator
      border={border ? 'true' : ''}
      toparrow={topArrow ? 'true' : ''}
      shadow={shadow ? 'true' : ''}
      currencystyle={currencyStyle ? 'true' : ''}
      config={config}
      arrowwidth={arrowWidth}
    />
  </Label>
);

export default Checkbox;
