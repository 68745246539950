import { useContext, useEffect, useState } from 'react';

import { useLocalization } from 'components/Internationalization';
import { popUps } from 'components/constants/constants';
import { ContextTokenCode } from 'context';
import { getWalletNav } from 'components/constants/popUps';

import Deposit from '../../Deposit';
import TipsRequest from '../../TipsRequest';
import Withdraw from '../../Withdraw';
import AddressBook from '../../AddressBook';
import WithdrawSuccess from '../../WithdrawSuccess';
import SaveWalletAddressForm from '../../SaveWalletAddressForm';
import Vault from '../../Vault';
import VaultConfirm from '../../VaultConfirm';
import TipsConfirm from '../../TipsConfirm';
import BuyCrypto from '../../BuyCrypto';

import WalletNavigationStyle from './styles';
import { IWalletNav, IWalletNavigation, WalletActions } from './types';

const WalletNavigation: React.FC<IWalletNavigation> = ({ data }) => {
  const { tokenCode } = useContext(ContextTokenCode);
  const { translate, language } = useLocalization();

  const [activePopUp, setActivePopUp] = useState(data?.active);
  const [nav, setNav] = useState<IWalletNav[]>([]);
  const [withdrawBalance, setWithdraw] = useState('0');
  const [textareaValue, setTextareaValue] = useState('');
  const [selectedToken, setSelectedToken] = useState(data?.token || tokenCode);
  const [selectedNetwork, setSelectedNetwork] = useState(data?.network || null);
  const [memo, setMemo] = useState('');
  const [dataPopUp, setDataPopUp] = useState(data);

  useEffect(() => {
    const newNav = getWalletNav(translate);

    setNav(newNav);
  }, [language]);

  const handleAction = (a: WalletActions) => {
    switch (a) {
      case WalletActions.deposit: {
        setActivePopUp(popUps.deposit);
        return;
      }
      case WalletActions.withdraw: {
        setActivePopUp(popUps.withdraw);
        return;
      }
      case WalletActions.tips: {
        setDataPopUp({ data: { alias: '' } });
        setActivePopUp(popUps.tipsRequest);
        return;
      }
      case WalletActions.buy: {
        setActivePopUp(popUps.buyCrypto);
        return;
      }
      case WalletActions.vault: {
        setActivePopUp(popUps.vault);
      }
    }
  };

  return (
    <WalletNavigationStyle>
      <div className="wallet__nav">
        {nav.map((n) => (
          <div
            className={n.active.includes(activePopUp) ? 'wallet__nav--title active' : 'wallet__nav--title'}
            onClick={() => handleAction(n.action)}
            key={n.title}
          >
            <img alt="icon" src={n.active.includes(activePopUp) ? n.imageEnable : n.imageDisable} />
            <h5>{n.title}</h5>
          </div>
        ))}
      </div>
      <div className="wallet__content">
        {activePopUp === popUps.deposit ? (
          <Deposit
            selectedToken={selectedToken}
            setSelectedToken={setSelectedToken}
            selectedNetwork={selectedNetwork}
            setSelectedNetwork={setSelectedNetwork}
          />
        ) : null}
        {activePopUp === popUps.withdraw ? (
          <Withdraw
            selectedToken={selectedToken}
            selectedNetwork={selectedNetwork}
            memo={memo}
            setMemo={setMemo}
            setWithdraw={setWithdraw}
            setTextareaValue={setTextareaValue}
            setWithdrawalToken={setSelectedToken}
            setActivePopUp={setActivePopUp}
            setDataPopUp={setDataPopUp}
            setSelectedNetwork={setSelectedNetwork}
          />
        ) : null}
        {activePopUp === popUps.withdrawSuccess ? (
          <WithdrawSuccess
            memo={memo}
            withdrawBalance={withdrawBalance}
            textareaValue={textareaValue}
            withdrawalToken={selectedToken}
            data={dataPopUp}
            selectedNetwork={selectedNetwork}
          />
        ) : null}
        {activePopUp === popUps.tipsRequest ? (
          <TipsRequest
            data={dataPopUp}
            setActivePopUp={setActivePopUp}
            setDataPopUp={setDataPopUp}
            selectedToken={selectedToken}
            setSelectedToken={setSelectedToken}
            setSelectedNetwork={setSelectedNetwork}
          />
        ) : null}
        {activePopUp === popUps.tipsConfirm ? <TipsConfirm data={dataPopUp} /> : null}
        {activePopUp === popUps.addressBook ? (
          <AddressBook
            setActivePopUp={setActivePopUp}
            setDataPopUp={setDataPopUp}
            selectedToken={selectedToken}
            setSelectedToken={setSelectedToken}
            selectedNetwork={selectedNetwork}
            setSelectedNetwork={setSelectedNetwork}
          />
        ) : null}
        {activePopUp === popUps.saveWalletAddressForm ? (
          <SaveWalletAddressForm
            data={dataPopUp}
            selectedToken={selectedToken}
            setSelectedToken={setSelectedToken}
            selectedNetwork={selectedNetwork}
            setSelectedNetwork={setSelectedNetwork}
            setActivePopUp={setActivePopUp}
          />
        ) : null}
        {activePopUp === popUps.vault ? (
          <Vault
            data={dataPopUp}
            setDataPopUp={setDataPopUp}
            setActivePopUp={setActivePopUp}
            selectedToken={selectedToken}
            setSelectedToken={setSelectedToken}
            setSelectedNetwork={setSelectedNetwork}
          />
        ) : null}
        {activePopUp === popUps.vaultConfirm ? (
          <VaultConfirm data={dataPopUp} setActivePopUp={setActivePopUp} setDataPopUp={setDataPopUp} />
        ) : null}
        {activePopUp === popUps.buyCrypto ? <BuyCrypto /> : null}
      </div>
    </WalletNavigationStyle>
  );
};

export default WalletNavigation;
