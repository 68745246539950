import { generate as Id } from 'shortid';

import { FastModeGames, HotKeys } from 'components/constants/games';
import {
  DEFAULT_HIGH_CHIP,
  DEFAULT_LOW_CHIP,
  HIGH_CHIPS,
  INITIAL_PLAYER_HAND,
  LOW_CHIPS,
} from 'components/constants/blackJack';
import { BNMinus, BNMultiply, BNPlus } from 'func/common';
import {
  getActionsOnResult,
  getAllHistoryBlackJack,
  getBetAmountMultiplier,
  getChipIcon,
  getDealerCards,
  getHistoryBlackJackElem,
  getPlayerCards,
  getSessionChips,
  getSplit,
} from 'func/blackJack';
import { bigThen } from 'func/prepareDataDice';
import { CardGameStake } from 'types';

import { blackJackInitialState } from './initialState';
import {
  BlackJackActionTypes,
  HandType,
  IAction,
  IBlackJackHand,
  IBlackJackInitialState,
  IGetSessionResponse,
  IResponseActive,
  IResponseClose,
} from './types';
import { ETokenCode } from '../../components/constants/constants';

export const BlackJackTypes = {
  FAST_MODE: 'FAST_MODE',
  HOTKEYS: 'HOTKEYS',
  HASH: 'HASH',
  GAME_ID: 'GAME_ID',
  SET_WALLET_USD: 'SET_WALLET_USD',
  SET_IS_LOW_MODE: 'SET_IS_LOW_MODE',
  SET_BET_AMOUNT: 'SET_BET_AMOUNT',
  SET_PLAYER_FIRST_HAND: 'SET_PLAYER_FIRST_HAND',
  SET_PLAYER_SECOND_HAND: 'SET_PLAYER_SECOND_HAND',
  SET_DEALER_HAND: 'SET_DEALER_HAND',
  FLIP_DEALER_CARD: 'FLIP_DEALER_CARD',
  SPLIT: 'SPLIT',
  RESPONSE_DISPATCH: 'RESPONSE_DISPATCH',
  BET_AND_DEAL: 'BET_AND_DEAL',
  DEAL: 'DEAL',
  CLEAR: 'CLEAR',
  HIT: 'HIT',
  HIT_ON_SPLIT: 'HIT_ON_SPLIT',
  STAND: 'STAND',
  NEW_BET: 'NEW_BET',
  RE_BET: 'RE_BET',
  BACK: 'BACK',
  DOUBLE: 'DOUBLE',
  SET_FIRST_HISTORY: 'SET_FIRST_HISTORY',
  GET_SESSION: 'GET_SESSION',
  EMPTY_BET_AMOUNT: 'EMPTY_BET_AMOUNT',
};

export const blackJackReducer = (
  state: IBlackJackInitialState = blackJackInitialState,
  action: IAction,
): IBlackJackInitialState => {
  switch (action.type) {
    case BlackJackTypes.FAST_MODE:
      return setFastMode(state);
    case BlackJackTypes.HOTKEYS:
      return setHotkeys(state);
    case BlackJackTypes.HASH:
      return setHash(state, action);
    case BlackJackTypes.SET_IS_LOW_MODE:
      return setIsHighMode(state);
    case BlackJackTypes.EMPTY_BET_AMOUNT:
      return setEmptyBetAmount(state);
    case BlackJackTypes.GAME_ID:
      return setGameId(state, action);
    case BlackJackTypes.SET_WALLET_USD:
      return setWalletUsd(state, action);
    case BlackJackTypes.SET_BET_AMOUNT:
      return setBetAmount(state, action);
    case BlackJackTypes.SET_PLAYER_FIRST_HAND:
      return setPlayerFirstHand(state, action);
    case BlackJackTypes.SET_DEALER_HAND:
      return setDealerHand(state, action);
    case BlackJackTypes.FLIP_DEALER_CARD:
      return flipDealerCard(state, action);
    case BlackJackTypes.SET_FIRST_HISTORY:
      return setFirstHistory(state, action);
    case BlackJackTypes.HIT:
      return hitCard(state, action);
    case BlackJackTypes.HIT_ON_SPLIT:
      return hitOnSplitCard(state, action);
    case BlackJackTypes.STAND:
      return setStand(state, action);
    case BlackJackTypes.RESPONSE_DISPATCH:
      return setSplitResponse(state, action);
    case BlackJackTypes.GET_SESSION:
      return getSession(state, action);
    case BlackJackTypes.SPLIT:
      return setSplit(state);
    case BlackJackTypes.DOUBLE:
      return setDouble(state);
    case BlackJackTypes.BET_AND_DEAL:
      return setBetAndDeal(state);
    case BlackJackTypes.DEAL:
      return setDeal(state);
    case BlackJackTypes.CLEAR:
      return setClear(state);
    case BlackJackTypes.NEW_BET:
      return setNewBet(state);
    case BlackJackTypes.RE_BET:
      return setReBet(state);
    case BlackJackTypes.BACK:
      return setBack(state);
    default:
      return state;
  }
};

function setFastMode(state) {
  const isFastMode = !state.fastMode;
  localStorage.setItem(FastModeGames.blackJack, isFastMode.toString());
  return { ...state, fastMode: isFastMode };
}

function setHotkeys(state) {
  const isHotkeysMode = !state.isHotkeys;
  localStorage.setItem(HotKeys.blackJack, isHotkeysMode.toString());
  return { ...state, isHotkeys: isHotkeysMode };
}

function setHash(state, action) {
  return { ...state, hash: action.payload };
}

function setEmptyBetAmount(state) {
  return { ...state, emptyBetAmount: Id() };
}

function setIsHighMode(state) {
  const chipsStake = state.isHighMode === CardGameStake.high ? HIGH_CHIPS : LOW_CHIPS;
  return {
    ...state,
    hash: '',
    isHighMode: state.isHighMode,
    chips: chipsStake,
    betAmount: '0',
    chipsHistory: [],
    minBet: chipsStake[0].amount,
    maxBet: chipsStake[chipsStake.length - 1].amount,
    playerHand: INITIAL_PLAYER_HAND,
    actions: [BlackJackActionTypes.deal],
    dealerHand: [],
    payout: '0',
  };
}

function setWalletUsd(state, action) {
  const actions = bigThen(action.payload.amount, '0') ? state.actions : [];
  return { ...state, walletUSD: action.payload.amount, actions, payout: '0' };
}

function setBetAmount(state, action) {
  const amountValue = action.payload.amount;
  const newChip = { amount: amountValue, icon: getChipIcon(amountValue, state.isHighMode), id: Id() };
  return {
    ...state,
    betAmount: BNPlus(state.betAmount, amountValue),
    chipsHistory: [...state.chipsHistory, newChip],
    actions: [BlackJackActionTypes.betDeal, BlackJackActionTypes.clear, BlackJackActionTypes.back],
    dealerHand: [],
    playerHand: INITIAL_PLAYER_HAND,
    emptyBetAmount: '',
  };
}

function setPlayerFirstHand(state, action) {
  const newCard = { ...action.payload.hand };
  const prevHand = state.playerHand.cards;

  const playerFistHand = prevHand.length ? [...prevHand, newCard] : [newCard];
  return {
    ...state,
    emptyBetAmount: '',
    playerHand: {
      ...state.playerHand,
      cards: playerFistHand,
      firstHand: {
        ...state.playerHand.firstHand,
        result: action.payload?.outcome,
      },
    },
    actions: [],
  };
}

function setDealerHand(state, action) {
  const newCard = { ...action.payload.hand };
  const prevHand = state.dealerHand;
  const payout = action.payload?.payout;
  const betId = action.payload?.betId;
  const multiplier = action.payload?.multiplier;
  const tokenCode = action.payload.tokenCode;
  const displayName = action.payload.displayName;
  const rate = action.payload.rate;
  const currentBetAmount = BNMultiply(
    state.betAmount,
    getBetAmountMultiplier(
      state.playerHand.firstHand.isDouble,
      state.playerHand.secondHand.isDouble,
      state.playerHand.isSplit,
    ),
  );

  let newActions = [];

  const newHistoryList = getHistoryBlackJackElem(
    betId,
    multiplier,
    payout,
    currentBetAmount,
    displayName,
    rate,
    state.historyList,
  );

  const result = { payout, betId, multiplier, tokenCode, displayName };

  if (payout) {
    newActions = getActionsOnResult(result, state.walletUSD, state.betAmount, state.maxBet);
  }

  const hand = prevHand ? [...prevHand, newCard] : [newCard];
  return {
    ...state,
    dealerHand: hand,
    actions: action.payload?.newActions || newActions,
    playerHand: {
      ...state.playerHand,
      firstHand: {
        ...state.playerHand.firstHand,
        result: action.payload?.playerOutcome,
        status: action.payload?.playerOutcome ? HandType.closed : state.playerHand.firstHand.status,
      },
    },
    historyList: newHistoryList,
    isSessionStarted: Boolean(!payout),
    payout: payout || '0',
    emptyBetAmount: '',
  };
}

function flipDealerCard(state, action) {
  const isFlipSecondCard = action.payload.flip;
  const result = action.payload?.result;
  const newActions = getActionsOnResult(result, state.walletUSD, state.betAmount, state.maxBet);
  const firstOutcome = action.payload.firstOutcome;
  const secondOutcome = action.payload.secondOutcome;
  const currentBetAmount = BNMultiply(
    state.betAmount,
    getBetAmountMultiplier(
      state.playerHand.firstHand.isDouble,
      state.playerHand.secondHand.isDouble,
      state.playerHand.isSplit,
    ),
  );

  const newHistoryList = getHistoryBlackJackElem(
    result?.betId,
    result?.multiplier,
    result?.payout,
    currentBetAmount,
    result?.displayName,
    result?.rate,
    state.historyList,
  );

  if (isFlipSecondCard) {
    const newCard = { ...action.payload.hand };
    const prevHand = state.dealerHand;

    const hand = prevHand ? [...prevHand, newCard] : [newCard];
    return {
      ...state,
      dealerHand: hand,
      actions: newActions,
      playerHand: {
        ...state.playerHand,
        firstHand: {
          ...state.playerHand.firstHand,
          result: firstOutcome,
          status: firstOutcome ? HandType.closed : state.playerHand.firstHand.status,
        },
        secondHand: {
          ...state.playerHand.secondHand,
          result: secondOutcome,
          status: secondOutcome ? HandType.closed : state.playerHand.secondHand.status,
        },
      },
      historyList: newHistoryList,
      isSessionStarted: Boolean(!result?.payout),
      payout: result?.payout ? result.payout : '0',
    };
  }

  const dealerCards = state.dealerHand.map((h, ind) => {
    if (ind) {
      return { ...action.payload.hand, id: h.id };
    }
    return { ...h };
  });

  return {
    ...state,
    dealerHand: dealerCards,
    actions: newActions,
    prevBetAmount: state.betAmount,
    prevChipsHistory: state.chipsHistory,
    playerHand: {
      ...state.playerHand,
      firstHand: {
        ...state.playerHand.firstHand,
        result: firstOutcome,
      },
      secondHand: {
        ...state.playerHand.secondHand,
        result: secondOutcome,
      },
    },
    historyList: newHistoryList,
    isSessionStarted: Boolean(!result?.payout),
    payout: result?.payout ? result.payout : '0',
  };
}

// common responses

function setGameId(state, action) {
  const stake = action.payload.stake ? CardGameStake.high : CardGameStake.low;
  const chipsStake = action.payload.stake ? HIGH_CHIPS : LOW_CHIPS;
  return {
    ...state,
    gameId: action.payload.gameId,
    isHighMode: stake,
    chips: chipsStake,
    minBet: chipsStake[0].amount,
    maxBet: chipsStake[chipsStake.length - 1].amount,
  };
}

function setFirstHistory(state, action) {
  return { ...state, historyList: getAllHistoryBlackJack(action.payload.historyList) };
}

// actions
function setSplit(state) {
  const splitHand = getSplit(state.playerHand);

  return { ...state, playerHand: splitHand };
}

function setSplitResponse(state, action) {
  const res = action.payload.response;

  const isTablet = action.payload.tablet;
  const cardsFirstHand = res.playerHands.leftSplitHand.cards;
  const cardsSecondHand = res.playerHands.rightSplitHand.cards;
  const isSplitAces = cardsSecondHand.length > 1;
  const lastLeft = cardsFirstHand[cardsFirstHand.length - 1];
  const lastRight = cardsSecondHand[cardsSecondHand.length - 1];

  const playerHand: IBlackJackHand = getPlayerCards([lastLeft], state.playerHand, state.fastMode, isTablet);
  const newActions =
    res.playerHands.leftSplitHand?.availableActions || res.playerHands.rightSplitHand?.availableActions;

  if (isSplitAces) {
    const hand = { ...playerHand, firstHand: { ...playerHand.firstHand, status: HandType.closed }, cardsCount: 1 };
    const playerHandOnAces = getPlayerCards([lastRight], hand, state.fastMode, isTablet);
    return {
      ...state,
      playerHand: playerHandOnAces,
      actions: newActions || [BlackJackActionTypes.betDeal],
    };
  }

  return {
    ...state,
    actions: newActions || [BlackJackActionTypes.betDeal],
    playerHand,
  };
}

function hitOnSplitCard(state, action) {
  const response: IResponseActive & IResponseClose = action.payload.response;
  const isTablet = action.payload.tablet;

  const newFirstHand = response?.playerHands?.leftSplitHand;
  const newSecondHand = response?.playerHands?.rightSplitHand;

  const firsHandResult = newFirstHand?.outcome;
  const secondHandResult = newSecondHand?.outcome;
  const newLeftHandCard = newFirstHand?.cards[newFirstHand?.cards?.length - 1];
  const isFirstHandOpen = state.playerHand.firstHand.status === HandType.open;
  const isFirstHandClose = response?.playerHands?.leftSplitHand?.isClosed;
  const isSecondHandClose = response?.playerHands?.rightSplitHand?.isClosed;

  const isFirstHandDouble = newFirstHand?.isDouble;
  const isSecondHandDouble = newSecondHand?.isDouble;

  const playerHand: IBlackJackHand = getPlayerCards([newLeftHandCard], state.playerHand, state.fastMode, isTablet);

  if (isFirstHandOpen && !firsHandResult) {
    // hit on first hand
    if (isFirstHandDouble || (isFirstHandClose && !firsHandResult)) {
      const newRightCard = newSecondHand.cards[newSecondHand.cards.length - 1];
      const newPlayerHand = {
        ...playerHand,
        firstHand: { ...playerHand.firstHand, status: HandType.closed },
        cardsCount: 1,
      };
      const newCards = getPlayerCards([newRightCard], newPlayerHand, state.fastMode, isTablet);
      return {
        ...state,
        playerHand: {
          ...newPlayerHand,
          firstHand: {
            ...newPlayerHand.firstHand,
            isDouble: isFirstHandDouble,
            status: HandType.closed,
          },
          secondHand: {
            ...state.playerHand.secondHand,
            status: isSecondHandDouble ? HandType.closed : HandType.open,
            isDouble: isSecondHandDouble,
          },
          cards: [...newCards.cards],
          cardsCount: 2,
        },
        actions: newSecondHand.availableActions,
      };
    }

    return {
      ...state,
      playerHand: {
        ...playerHand,
        firstHand: {
          ...playerHand.firstHand,
          isDouble: isFirstHandDouble,
          status: isFirstHandDouble ? HandType.closed : HandType.open,
        },
      },
      actions: newFirstHand.availableActions,
    };
  }

  if (firsHandResult && isFirstHandOpen) {
    // bust on first hand
    const firstHandBust = {
      ...playerHand,
      firstHand: {
        ...playerHand.firstHand,
        status: HandType.closed,
      },
      secondHand: {
        ...playerHand.secondHand,
        status: HandType.open,
      },
      cardsCount: 1,
    };
    const newRightCard = newSecondHand.cards[newSecondHand.cards.length - 1];
    const newCards = getPlayerCards([newRightCard], firstHandBust, state.fastMode, isTablet);
    return {
      ...state,
      playerHand: {
        ...firstHandBust,
        firstHand: {
          ...firstHandBust.firstHand,
          isDouble: isFirstHandDouble,
        },
        secondHand: {
          ...firstHandBust.secondHand,
          status: newSecondHand?.outcome ? HandType.closed : HandType.open,
          isDouble: isSecondHandDouble,
        },
        cards: [...newCards.cards],
        cardsCount: newCards.cardsCount,
      },
      actions: newSecondHand?.availableActions || [BlackJackActionTypes.newBet],
    };
  }

  if (secondHandResult) {
    // bust on second hand
    const newRightCard = newSecondHand.cards[newSecondHand.cards.length - 1];
    const newCards = getPlayerCards([newRightCard], state.playerHand, state.fastMode, isTablet);
    return {
      ...state,
      playerHand: {
        ...state.playerHand,
        secondHand: {
          ...state.playerHand.secondHand,
          // result: secondHandResult,
          status: HandType.closed,
          isDouble: isSecondHandDouble,
        },
        cards: [...newCards.cards],
      },
    };
  }

  if (isFirstHandClose && !isSecondHandClose && !secondHandResult) {
    // hit on second hand and not bust
    const newRightCard = newSecondHand.cards[newSecondHand.cards.length - 1];
    const newCards = getPlayerCards([newRightCard], state.playerHand, state.fastMode, isTablet);
    return {
      ...state,
      playerHand: {
        ...state.playerHand,
        cards: [...newCards.cards],
        cardsCount: newCards.cardsCount,
      },
      actions: newSecondHand?.availableActions,
    };
  }
  return {
    ...state,
    playerHand,
    actions: newFirstHand.availableActions,
  };
}

function getSession(state, action) {
  const session: IGetSessionResponse = action.payload.response?.session;
  const tablet = action.payload.tablet;
  const token = action.payload?.token;

  if (!session) {
    return {
      ...state,
      actions: token === ETokenCode.STAR ? [] : [BlackJackActionTypes.betDeal],
      betAmount: '0',
      chipsHistory: [],
      dealerHand: [],
      playerHand: INITIAL_PLAYER_HAND,
      isSessionStarted: false,
      payout: '0',
      emptyBetAmount: '',
      isStarToken: token === ETokenCode.STAR,
    };
  }

  const chipsStake = session.stake === CardGameStake.high ? HIGH_CHIPS : LOW_CHIPS;
  const dealerCards = getDealerCards([...session.dealerCards, ''], state.fastMode);
  const sessionChips = getSessionChips(session.chips, session.stake);
  let chipAmount = 0;
  sessionChips.forEach((c) => {
    chipAmount += c.amount;
  });
  const commonSessionData = {
    isSessionStarted: true,
    betAmount: String(chipAmount),
    chips: chipsStake,
    minBet: chipsStake[0].amount,
    maxBet: chipsStake[chipsStake.length - 1].amount,
    dealerHand: dealerCards,
    isHighMode: session.stake,
    chipsHistory: sessionChips,
    emptyBetAmount: '',
    isStarToken: token === ETokenCode.STAR,
  };

  if (session.playerHands?.leftSplitHand) {
    const isFirsthandClose = session.playerHands.leftSplitHand?.isClosed;
    const actions =
      session.playerHands.leftSplitHand?.availableActions || session.playerHands.rightSplitHand?.availableActions;
    const playerFirstHand = {
      ...INITIAL_PLAYER_HAND,
      isSplit: true,
      firstHand: {
        ...INITIAL_PLAYER_HAND.firstHand,
        isDouble: session.playerHands.leftSplitHand?.isDouble,
        status: isFirsthandClose ? HandType.closed : HandType.open,
      },
      cardsCount: 0,
    };
    const playerFirstHandCards = getPlayerCards(
      session.playerHands.leftSplitHand.cards,
      playerFirstHand,
      state.fastMode,
      tablet,
    );
    const playerSecondHandCards = getPlayerCards(
      session.playerHands.rightSplitHand.cards,
      {
        ...playerFirstHand,
        firstHand: {
          ...playerFirstHand.firstHand,
          status: HandType.closed,
        },
        secondHand: {
          ...playerFirstHand.secondHand,
          status: HandType.open,
        },
        cardsCount: 0,
      },
      state.fastMode,
      tablet,
    );

    const rightCards = playerSecondHandCards.cards.map((c) => ({ ...c, isFirst: false }));
    const leftCards = playerFirstHandCards.cards.map((c) => ({ ...c, isFirst: true }));

    return {
      ...state,
      ...commonSessionData,
      actions,
      payout: '0',
      playerHand: {
        ...playerFirstHand,
        firstHand: {
          ...playerFirstHand.firstHand,
          isDouble: session.playerHands.leftSplitHand?.isDouble,
          status: isFirsthandClose ? HandType.closed : HandType.open,
        },
        secondHand: {
          ...INITIAL_PLAYER_HAND.secondHand,
          status: isFirsthandClose ? HandType.open : HandType.notActive,
        },
        cards: [...leftCards, ...rightCards],
        cardsCount: isFirsthandClose ? rightCards.length : leftCards.length,
      },
    };
  }

  const actions = session.playerHands.availableActions;
  const playerHand = getPlayerCards(session.playerHands.cards, INITIAL_PLAYER_HAND, state.fastMode, tablet);

  return {
    ...state,
    ...commonSessionData,
    actions,
    playerHand,
  };
}

function setBetAndDeal(state) {
  const chips = state.isHighMode === CardGameStake.high ? DEFAULT_HIGH_CHIP : DEFAULT_LOW_CHIP;
  return {
    ...state,
    actions: [],
    playerHand: INITIAL_PLAYER_HAND,
    betAmount: bigThen(state.betAmount, '0') ? state.betAmount : state.minBet,
    dealerHand: [],
    isSessionStarted: true,
    chipsHistory: bigThen(state.betAmount, '0') ? state.chipsHistory : [chips],
    payout: '0',
    emptyBetAmount: '',
  };
}

function setDeal(state) {
  const chips = state.isHighMode === CardGameStake.high ? DEFAULT_HIGH_CHIP : DEFAULT_LOW_CHIP;
  return {
    ...state,
    actions: [],
    betAmount: bigThen(state.betAmount, '0') ? state.betAmount : state.minBet,
    playerHand: INITIAL_PLAYER_HAND,
    dealerHand: [],
    isSessionStarted: true,
    chipsHistory: bigThen(state.betAmount, '0') ? state.chipsHistory : [chips],
    payout: '0',
    emptyBetAmount: '',
  };
}

function setBack(state) {
  const lastEl = state.chipsHistory[state.chipsHistory.length - 1];
  const newHistoryChips = state.chipsHistory.filter((el, ind) => ind < state.chipsHistory.length - 1);
  const newActions = newHistoryChips.length ? [...state.actions] : [BlackJackActionTypes.deal];
  return {
    ...state,
    hash: '',
    chipsHistory: newHistoryChips,
    actions: newActions,
    betAmount: BNMinus(state.betAmount, lastEl.amount),
    payout: '0',
    emptyBetAmount: '',
  };
}

function setClear(state) {
  return {
    ...state,
    hash: '',
    actions: [BlackJackActionTypes.betDeal],
    betAmount: '0',
    chipsHistory: [],
    dealerHand: [],
    playerHand: INITIAL_PLAYER_HAND,
    payout: '0',
    emptyBetAmount: '',
  };
}

function hitCard(state, action) {
  const newActions = action.payload?.newActions ? action.payload.newActions : [...state.actions];
  return {
    ...state,
    playerHand: {
      ...action.payload.hand,
    },
    actions: newActions,
  };
}

function setStand(state, action) {
  const response: IResponseActive & IResponseClose = action.payload.standResponse;
  const isTablet = action.payload.tablet;
  const isFirstHandOpen = state.playerHand.firstHand.status === HandType.open;

  if (isFirstHandOpen) {
    const newSecondHand = response.playerHands.rightSplitHand;
    const secondHandCard = newSecondHand.cards[newSecondHand.cards.length - 1];
    const newFirstHand = {
      ...state.playerHand,
      firstHand: {
        ...state.playerHand.firstHand,
        status: HandType.closed,
      },
      secondHand: {
        ...state.playerHand.secondHand,
        status: HandType.open,
      },
      cardsCount: 1,
    };
    const newCards = getPlayerCards([secondHandCard], newFirstHand, state.fastMode, isTablet);

    return {
      ...state,
      playerHand: {
        ...state.playerHand,
        firstHand: {
          ...state.playerHand.firstHand,
          status: HandType.closed,
        },
        cards: [...newCards.cards],
        cardsCount: 2,
      },
      actions: newSecondHand?.availableActions,
    };
  }

  return {
    ...state,
  };
}

function setNewBet(state) {
  return {
    ...state,
    hash: '',
    actions: [],
    betAmount: '0',
    chipsHistory: [],
    prevChipsHistory: [],
    playerHand: INITIAL_PLAYER_HAND,
    dealerHand: [],
    payout: '0',
    emptyBetAmount: '',
  };
}

function setReBet(state) {
  return {
    ...state,
    hash: '',
    actions: [],
    betAmount: BNMultiply(state.betAmount, 2),
    chipsHistory: [...state.prevChipsHistory, ...state.prevChipsHistory],
    playerHand: INITIAL_PLAYER_HAND,
    dealerHand: [],
    isSessionStarted: true,
    payout: '0',
    emptyBetAmount: '',
  };
}

function setDouble(state) {
  const isSplit = state.playerHand.isSplit;
  if (isSplit) {
    const isFirstHandOpen = state.playerHand.firstHand.status !== HandType.closed;
    return {
      ...state,
      playerHand: {
        ...state.playerHand,
        firstHand: {
          ...state.playerHand.firstHand,
          isDouble: isFirstHandOpen ? true : state.playerHand.firstHand.isDouble,
        },
        secondHand: {
          ...state.playerHand.secondHand,
          isDouble: isFirstHandOpen ? state.playerHand.secondHand.isDouble : true,
        },
      },
    };
  }
  return {
    ...state,
    playerHand: {
      ...state.playerHand,
      firstHand: {
        ...state.playerHand.firstHand,
        isDouble: true,
      },
    },
  };
}
