import React from 'react';

import { useKeno } from 'context/contextKeno/contextKeno';
import { Types } from 'context/contextKeno/kenoReduser';
import { disableKenoSquare } from 'func/prepareDataKeno';

import styles from './styles.module.scss';
import KenoSquareItem from './KenoSquareItem';

const KenoSquares: React.FC = () => {
  const [state, dispatch] = useKeno();

  const { squaresList, gameCondition, selectedSquaresList, fastMode } = state;

  const handleSelect = (id) => dispatch({ type: Types.SELECT_SQUARE, payload: { itemId: id } });

  return (
    <div className={styles.kenoSquaresWrap}>
      <div className={styles.kenoSquaresContent}>
        {squaresList.map((item) => (
          <KenoSquareItem
            key={item.id}
            ind={item.index + 1}
            className={item.className}
            disable={disableKenoSquare(gameCondition, item, selectedSquaresList.length)}
            onClick={() => handleSelect(item.index)}
            fastMode={fastMode}
          />
        ))}
      </div>
    </div>
  );
};

export default KenoSquares;
