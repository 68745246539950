import { useLocalization } from 'components/Internationalization';

import Link from 'components/Base/Links/Link';

import styles from './styles.module.scss';

const DateBlock: React.FC = () => {
  const { translate } = useLocalization();
  const date = new Date();

  return (
    <div className={styles.footerDate}>
      <p>
        {`${translate('footer.copyright.title')} © ${date.getFullYear()}`}
        <Link to="/" onClick={() => window.scrollTo(0, 0)}>
          Starbets.io
        </Link>
      </p>
      <p className={styles.rights}>{translate('footer.copyright.rights')}</p>
    </div>
  );
};

export default DateBlock;
