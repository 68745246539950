import { useState, useContext, useEffect } from 'react';

import DiceGameContext from 'context/contextDiceGame/context';
import { popUps } from 'components/constants/constants';
import { IN_HOUSE_TABLE_TITLES, InHouseGamesPageTablesTitles } from 'components/constants/table';

import TablesTitles from 'components/common/TablesTitles';
import MyBetsTableWrap from 'components/Games/base/MyBetsTable';

import styles from './styles.module.scss';

const DiceGameBetsTable: React.FC = () => {
  const { history } = useContext(DiceGameContext);

  const [title, setTitle] = useState<string>(InHouseGamesPageTablesTitles.myBets);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const newRows = history.slice(0, 20).map((b) => ({
      ...b,
      displayName: b.token,
    }));

    setRows(newRows);
  }, [history]);

  const handleChangeTitle = (newTitle: string) => {
    setTitle(newTitle);
  };

  return (
    <div className={styles.wrapper}>
      <TablesTitles currentTitle={title} titles={IN_HOUSE_TABLE_TITLES} onTitle={handleChangeTitle} />
      <div className={styles.table}>
        <MyBetsTableWrap history={rows} popUpsWindow={popUps.diceBetDetails} />
      </div>
    </div>
  );
};

export default DiceGameBetsTable;
