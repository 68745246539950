import React, { useState } from 'react';

import { ReactComponent as CopyIcon } from 'assets/img/common/copy-alt.svg';
import { useLocalization } from 'components/Internationalization';

import { ICopyButton } from './types';
import { StyledButton, StyledButtonWrapper } from './styles';

const CopyButton: React.FC<ICopyButton> = ({ copyContent }) => {
  const { translate } = useLocalization();

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(copyContent);
    setOpenTooltip(true);
    setTimeout(() => {
      setOpenTooltip(false);
    }, 1000);
  };

  return (
    <StyledButtonWrapper>
      <StyledButton type="button" onClick={handleCopy}>
        <CopyIcon width={16} height={16} />
      </StyledButton>
      <div className={openTooltip ? 'btn__copy-tooltip open' : 'btn__copy-tooltip'}>
        {translate('general.buttons.copy')}
      </div>
    </StyledButtonWrapper>
  );
};

export default CopyButton;
