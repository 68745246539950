import { IRouterElement, IRouterRedirectElement } from './types';

export const LANGUAGE_PATH = '/:lang?';

export const updatePathWithLanguageRoute = (path: string) => {
  return LANGUAGE_PATH + path;
};

export const prepareRoutes = (routes: IRouterElement[]) => {
  return routes.map((r) => ({ ...r, path: updatePathWithLanguageRoute(r.path) }));
};

export const prepareRedirectRoutes = (routes: IRouterRedirectElement[]) => {
  return routes.map((r) => ({
    ...r,
    path: updatePathWithLanguageRoute(r.path),
    redirectsTo: updatePathWithLanguageRoute(r.redirectsTo),
  }));
};
