import { useState } from 'react';
import ReactCardFlip from 'react-card-flip';

import PriceForStar from './components/PriceForStar';
import TokenDetails from './components/TokenDetails';
import styles from './styles.module.scss';

const MiningPriceTokenDetails: React.FC = () => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal" containerClassName={styles.miningWrap}>
      <PriceForStar setIsFlipped={setIsFlipped} isFlipped={isFlipped} />
      <TokenDetails setIsFlipped={setIsFlipped} isFlipped={isFlipped} />
    </ReactCardFlip>
  );
};

export default MiningPriceTokenDetails;
