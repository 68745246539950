import { gql } from '@apollo/client';

export const GET_COMMON_SETTINGS = gql`
  query getSettings {
    getSettings {
      name
      value
    }
  }
`;
