import { gql } from '@apollo/client';

export const NOTIFICATIONS = gql`
  query {
    userNotifications {
      id
      type
      title
      content
      isRead
      createdAt
    }
  }
`;
