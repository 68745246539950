import { gql } from '@apollo/client';

export const DEPOSIT_VAULT = gql`
  mutation Mutation($input: VaultDto!) {
    depositVault(vaultDto: $input)
  }
`;

export const WITHDRAW_VAULT = gql`
  mutation Mutation($input: VaultDto!) {
    withdrawVault(vaultDto: $input)
  }
`;
