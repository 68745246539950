import chip_01 from 'assets/img/Cards/Chips/0.1.webp';
import chip_05 from 'assets/img/Cards/Chips/0.5.webp';
import chip_1 from 'assets/img/Cards/Chips/1.webp';
import chip_5 from 'assets/img/Cards/Chips/5.webp';
import chip_10 from 'assets/img/Cards/Chips/10.webp';

import chip_10_vip from 'assets/img/Cards/Chips/10_vip.webp';
import chip_50_vip from 'assets/img/Cards/Chips/50_vip.webp';
import chip_100_vip from 'assets/img/Cards/Chips/100_vip.webp';
import chip_500_vip from 'assets/img/Cards/Chips/500_vip.webp';
import chip_1000_vip from 'assets/img/Cards/Chips/1000_vip.webp';

import { ETokenCode } from 'components/constants/constants';
import { CardGameStake } from 'types';
import { ITexasHoldemChip, ITexasHoldemHand, ITexasHoldemLimits, TexasHoldemGameState } from './types';

export const TEXAS_HOLDEM_DEFAULT_ANIMATION = 1000;
export const TEXAS_HOLDEM_FAST_ANIMATION = 500;

export const TEXAS_HOLDEM_DEFAULT_CHIPS: ITexasHoldemChip[] = [
  { icon: chip_01, value: '0.1', multiplier: 5, color: '#bc163e' },
  { icon: chip_05, value: '0.5', multiplier: 2, color: '#c3179e' },
  { icon: chip_1, value: '1', multiplier: 5, color: '#b41740' },
  { icon: chip_5, value: '5', multiplier: 2, color: '#c21aa3' },
  { icon: chip_10, value: '10', multiplier: 0, color: '#6d0cb9' },
];

export const TEXAS_HOLDEM_VIP_CHIPS: ITexasHoldemChip[] = [
  { icon: chip_10_vip, value: '10', multiplier: 5, color: '#dba347' },
  { icon: chip_50_vip, value: '50', multiplier: 2, color: '#dba347' },
  { icon: chip_100_vip, value: '100', multiplier: 5, color: '#dba347' },
  { icon: chip_500_vip, value: '500', multiplier: 2, color: '#dba347' },
  { icon: chip_1000_vip, value: '1000', multiplier: 0, color: '#dba347' },
];

export const TEXAS_HOLDEM_DEFAULT_SUM = '0.0';
export const TEXAS_HOLDEM_DEFAULT_STAKE = CardGameStake.low;
export const TEXAS_HOLDEM_DEFAULT_TOKEN_CODE = ETokenCode.BTC;
export const TEXAS_HOLDEM_DEFAULT_GAME_STATE: TexasHoldemGameState = TexasHoldemGameState.notStarted;
export const TEXAS_HOLDEM_DEFAULT_FAST_MODE = false;
export const TEXAS_HOLDEM_DEFAULT_HOT_KEYS = false;
export const TEXAS_HOLDEM_DEFAULT_HAND: ITexasHoldemHand = {
  combination: null,
  combinationCards: null,
  kickerCard: null,
  cards: [],
};
export const TEXAS_HOLDEM_DEFAULT_LIMITS: ITexasHoldemLimits = { min: 0.1, max: 1000 };

export const TEXAS_HOLDEM_CLEAR_STATE = [
  TexasHoldemGameState.notStarted,
  TexasHoldemGameState.finished,
  TexasHoldemGameState.multiply,
];
export const TEXAS_HOLDEM_DEAL_STATE = [TexasHoldemGameState.notStarted, TexasHoldemGameState.rebet];
export const TEXAS_HOLDEM_CHECK_STATE = [TexasHoldemGameState.preFlop, TexasHoldemGameState.flop];
export const TEXAS_HOLDEM_END_GAME_STATE = [TexasHoldemGameState.finished, TexasHoldemGameState.multiply];
