import defImage from 'assets/img/Events/starWars.webp';

import { media } from 'config';

import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import Button from 'components/Base/Button';
import LazyLoadImg from 'components/Base/LazyLoadImg/LazyLoadImg';
import TournamentCardTimer from './TournamentCardTimer';

import styles from './styles.module.scss';
import { ITournamentCard } from './types';

const TournamentCard: React.FC<ITournamentCard> = ({
  date,
  prize,
  name,
  isActive,
  onShowMore,
  image,
  startAt,
  endAt,
}) => {
  const { translate } = useLocalization();

  return (
    <div className={styles.tournamentCardWrap}>
      <div className={styles.tournamentCardImageWrap}>
        <LazyLoadImg
          src={image ? `${media.slider}${image}` : defImage}
          alt="icon"
          className={isActive ? styles.tournamentCardImage : styles.tournamentCardImageInActive}
        />
      </div>
      <div className={styles.tournamentCardTitleWrap}>
        <h4>{translate(name)}</h4>
        <TournamentCardTimer startAt={startAt} endAt={endAt} />
      </div>
      <div className={styles.tournamentCardContentWrap}>
        <div className={styles.tournamentCardContent}>
          <div className={styles.tournamentCardContentItem}>
            <h4 className={styles.tournamentCardContentItemH4}>{prize}</h4>
            <h5 className={styles.tournamentCardContentItemH5}>{translate('events.tournaments.pool')}</h5>
          </div>
          <div className={styles.tournamentCardContentItem}>
            <h4 className={styles.tournamentCardContentItemH4}>{date}</h4>
            <h5 className={styles.tournamentCardContentItemH5}>{translate('events.tournaments.dates')}</h5>
          </div>
        </div>
        <Button
          variant={ButtonType.secondary}
          title={translate('events.tournaments.buttons.more')}
          width="100%"
          onclick={onShowMore}
          color="#202D4A"
        />
      </div>
    </div>
  );
};

export default TournamentCard;
