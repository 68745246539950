import { useQuery } from '@apollo/client';

import img from 'assets/img/Events/starWars.webp';

import { media } from 'config';
import { amount, getImgSrc } from 'func/common';
import { getEventTypeTitle } from 'func/prepareDataEvents';
import { GET_USER_EVENT_INFO } from 'graphQl/query/events/events';
import { useAppSelector } from 'hooks/useAppSelector';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';

import LazyLoadImg from 'components/Base/LazyLoadImg/LazyLoadImg';

import styles from './styles.module.scss';
import { IEventsBack } from './types';

const BackCard: React.FC<IEventsBack> = ({
  onClick,
  type,
  title,
  description,
  eventPrizeToken,
  rulesArr,
  image,
  id,
  totalWagerTokenCode,
}) => {
  const { translate } = useLocalization();

  const token = useAppSelector(userToken);

  const { data } = useQuery(GET_USER_EVENT_INFO, {
    fetchPolicy: 'cache-and-network',
    skip: !token,
    variables: {
      id,
    },
  });

  return (
    <div className={styles.eventsBackWrap}>
      <LazyLoadImg src={image ? `${media.events}${image}` : img} className={styles.eventsBackImg} />
      <div className={styles.eventsBackContent}>
        <div className={styles.eventsBackBtnWrap}>
          <div className={styles.eventsBackBtnType}>{type || ''}</div>
          <button type="button" onClick={onClick} className={styles.eventsBackBtnFlip}>
            {translate('events.buttons.more-info')}
          </button>
        </div>
        <h2 className={styles.eventsBackTitle}>{translate(title)}</h2>
        <div className={styles.eventsBackDescription}>{translate(description)}</div>
        <div className={styles.eventsBackUser}>
          <div className={styles.eventsBackUserItem}>
            <h2>{translate('events.starwars.place')}</h2>
            <h3>{data?.currentUserEventResult?.place ? data?.currentUserEventResult?.place : '-'}</h3>
          </div>
          <div className={styles.eventsBackUserItem}>
            <h2>{getEventTypeTitle(type)}</h2>
            <h3>
              <img src={getImgSrc(totalWagerTokenCode)} alt="icon" />
              {data?.currentUserEventResult?.totalWager
                ? amount(data?.currentUserEventResult?.totalWager)
                : amount('0')}
            </h3>
          </div>
          <div className={styles.eventsBackUserItem}>
            <h2>{translate('events.starwars.prize')}</h2>
            <h3>
              <img src={getImgSrc(eventPrizeToken)} alt="icon" />
              {data?.currentUserEventResult?.prize ? data?.currentUserEventResult?.prize : '0'}
            </h3>
          </div>
        </div>
        {rulesArr && (
          <div className={styles.eventsBackRules}>
            <ul>
              {rulesArr.map((str) => (
                <li key={str}>{str}</li>
              ))}
              <li>(Remember that you will also get regular automatic referral bonuses from these users.)</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default BackCard;
