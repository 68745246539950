import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

interface Props {
  top: string;
  width: string;
  textpadding?: string;
  hidefirstelem?: string;
}

const DropDownStyle = styled.div.withConfig({
  shouldForwardProp: (prop) => !['top'].includes(prop),
})<Props>`
  display: flex;
  flex-direction: column;
  //position: absolute;---------------
  position: relative;
  top: ${(props) => props.top};
  /* z-index: 5; */
  width: ${(props) => (props.width ? props.width : '100%')};

  .drop__body {
    visibility: hidden;
    flex-direction: column;
    width: ${(props) => (props.width ? props.width : '288px')};
    height: 0;
    background-color: #000000;
    transform: translateY(-100%);
    transition: all 0.1s ease-in-out;
    transition-duration: 000ms;
    transition-delay: 000ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    margin-top: 10px;
    border-radius: 24px;
    opacity: 0.9;
    overflow: hidden;

    position: absolute;
    top: 50px;
  }

  .drop__body_top {
    transform: translateY(200%);
  }

  .drop__body_top.open {
    top: unset;
    bottom: 50px;
  }

  .drop__body.open {
    visibility: visible;
    transform: translateY(0%);
    transition: all 0.1s ease-in-out;
    transition-duration: 100ms;
    transition-delay: 100ms;
    z-index: 20;
    padding: 10px 15px 10px 0;
    //height: 100%;

    height: auto;
  }
  .drop__body-icon,
  .drop__body-icon1 {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 5px;
  }
  .collect-all {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 13px;
    top: -1px;
  }

  .drop__body-item {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    margin: 10px 0;
    position: relative;
    cursor: url(${point}), auto !important;
    p {
      :nth-child(1) {
        padding-left: ${(props) => props.hidefirstelem && props.hidefirstelem};
      }
      margin: 0;
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      padding-left: ${(props) => (props.textpadding ? props.textpadding : '20px')};
    }
    &:hover {
      transition: 0.2s ease-in-out;
      p {
        color: #ffa608;
        font-weight: 600;
      }
      .drop__body-icon,
      .collect-all {
        transform: scale(1.03);
      }
    }
  }
  .drop__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: ${(props) => (props.width ? props.width : '288px')};
    height: 38px;
    background: #00000033;
    border-radius: 19px;
    cursor: url(${point}), auto !important;
    border: none;
    box-shadow: none !important;
    outline: none !important;
    &:hover {
      transition: 0.2s ease-in-out;
      border: 2px solid #6c5dd3;
      p {
        color: #ffffff;
        font-weight: 400;
      }
    }
  }
  i {
    border: 1px solid #888aa7;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    height: 3px;
    margin-right: 30px;
    position: absolute;
    top: 12px;
    right: -8px;
    cursor: url(${point}), auto !important;
  }

  .up {
    margin-top: 3px;
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
  }
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border: 1px solid #888aa7;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    cursor: url(${point}), auto !important;
    margin-bottom: 3px;
  }

  .dropScroll {
    padding: 0 5px;
    background: transparent;
    overflow-y: auto;

    border: 5px solid transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px;
      background: #000;
    }

    &::-webkit-scrollbar-thumb {
      background: #50566a;
      -webkit-border-radius: 1ex;
      -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    }

    &::-webkit-scrollbar-corner {
      background: #000;
    }
  }

  @media (max-width: 1024px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 540px) {
  }
  @media (max-width: 420px) {
  }
`;

export default DropDownStyle;
