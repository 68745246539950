import { pathOr } from 'ramda';
import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import clsx from 'clsx';

import { popUps } from 'components/constants/constants';
import { USER_SECURITY } from 'graphQl/query/auth/auth';
import { DELETE_ADDRESS_BOOK_RECORD } from 'graphQl/mutations/withdraw/withdraw';
import { GET_ADDRESS_BOOK } from 'graphQl/query/withdraw/withdraw';
import { ContextPopUps } from 'context';
import { getImgSrc } from 'func/common';
import { cutWalletAddressWithdraw } from 'func/prepareDataPopUps';

import deleteIcon from 'assets/img/common/close-circle-outline.webp';
import editIcon from 'assets/img/common/note-edit-outline.webp';
import copyIcon from 'assets/img/common/content-copy.webp';

import styles from './styles.module.scss';
import { IAddressBookItem } from './types';

const AddressBookItem: React.FC<IAddressBookItem> = ({
  walletId,
  name,
  title,
  tokenCode,
  isWhitelisted,
  displayName,
  memo,
  onError,
  setDataPopUp,
  setActivePopUp,
  selectedNetwork,
}) => {
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [openTooltip, setOpenTooltip] = useState(false);

  const { data: userSecurity } = useQuery(USER_SECURITY, { fetchPolicy: 'cache-and-network' });
  const [deleteAddressBookRecord] = useMutation(DELETE_ADDRESS_BOOK_RECORD, {
    refetchQueries: [GET_ADDRESS_BOOK],
  });

  const handleCopy = () => {
    navigator.clipboard.writeText(title);
    setOpenTooltip(true);
    setTimeout(() => {
      setOpenTooltip(false);
    }, 1000);
  };

  const handleDeleteOnTwoFA = async (errorCodeSetter, code) => {
    try {
      await deleteAddressBookRecord({
        variables: {
          formData: {
            id: walletId,
          },
          code,
        },
      });
      setPopUpsOpen({
        modalOpen: popUps.walletNavigation,
        data: {
          config: popUps.deposit,
          active: popUps.addressBook,
        },
      });
    } catch (errorMess) {
      errorCodeSetter(errorMess);
      onError(errorMess);
    }
  };

  const handleDeleteWallet = async () => {
    const security = pathOr<Record<string, boolean>>({}, ['userSecurity'], userSecurity);
    const { email_2fa, google_2fa } = security;

    if (!email_2fa && !google_2fa) {
      await deleteAddressBookRecord({
        variables: {
          formData: {
            id: walletId,
          },
        },
      });
      setActivePopUp(popUps.addressBook);
      return;
    }

    setPopUpsOpen({
      modalOpen: popUps.verifySecurityCode,
      data: {
        onSubmit: handleDeleteOnTwoFA,
        autoSendOtp: true,
        title: google_2fa ? 'Google' : 'Email',
      },
    });
  };

  const handleUpdateWallet = () => {
    setDataPopUp({
      config: popUps.deposit,
      active: popUps.addressBook,
      walletId,
      name,
      address: title,
      tokenCode,
      displayName,
      isWhitelisted,
      selectedNetwork,
      memo,
    });
    setActivePopUp(popUps.saveWalletAddressForm);
  };

  return (
    <div className={styles.addressItemWrap}>
      <img src={getImgSrc(displayName)} alt="icon" className={styles.addressItemImg} />
      <div className={styles.addressItemName}>{name}</div>
      <div className={styles.addressItemTitle}>{cutWalletAddressWithdraw(title)}</div>
      <div className={styles.addressItemButtonWrap}>
        <button type="button" className={styles.addressItemButton} onClick={handleCopy}>
          <img alt="icon" src={copyIcon} />
          <span className={clsx(styles.addressBtnCopyTooltip, openTooltip ? styles.open : '')}>copied</span>
        </button>
        <button type="button" className={styles.addressItemButton} onClick={handleUpdateWallet}>
          <img alt="icon" src={editIcon} />
        </button>
        <button type="button" className={styles.addressItemButton} onClick={handleDeleteWallet}>
          <img alt="icon" src={deleteIcon} />
        </button>
      </div>
    </div>
  );
};

export default AddressBookItem;
