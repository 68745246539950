import { gql } from '@apollo/client';

export const READ_NOTIFICATION = gql`
  mutation ($id: String!) {
    readUserNotification(id: $id)
  }
`;

export const READ_ALL_NOTIFICATIONS = gql`
  mutation {
    readAllUserNotifications
  }
`;

export const REMOVE_NOTIFICATION = gql`
  mutation ($id: String!) {
    removeUserNotification(id: $id)
  }
`;

export const REMOVE_ALL_NOTIFICATIONS = gql`
  mutation {
    removeAllUserNotifications
  }
`;
