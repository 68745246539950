/* eslint-disable no-useless-escape */
import React, { useContext } from 'react';
import BigNumber from 'bignumber.js';

import { useAppSelector } from 'hooks/useAppSelector';
import { ContextTokenCode, ContextWallet } from 'context';
import MainButton from 'components/common/MainButton';
import { useEventListener } from 'hooks/useEventListener';
import { amount, BNMultiply, getImgSrc, handleSelectInputValue, prepareAmount, removeComas } from 'func/common';
import { bigOrEqual, bigThen, lessOrEqual, lessThen } from 'func/prepareDataDice';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';
import { IBetAmountBlock } from './types';
import BonusAmountInfo from './components/BonusAmountInfo';

const BetAmountBlock: React.FC<IBetAmountBlock> = ({
  minimumBet,
  maxBet,
  betAmountValue,
  disable,
  isHotKeys,
  setBetAmountValue,
  gameId,
}) => {
  const token = useAppSelector(userToken);
  const { translate } = useLocalization();

  const { walletUser } = useContext(ContextWallet);
  const { tokenCode } = useContext(ContextTokenCode);

  const handleMin = () => {
    const formattedWalletBalance = prepareAmount(walletUser);
    const formattedMinBet = prepareAmount(minimumBet);

    if (!token) return;
    if (bigOrEqual(formattedWalletBalance, formattedMinBet)) {
      setBetAmountValue(String(amount(minimumBet)));
    }
  };

  const handleMax = () => {
    if (!token) return;
    const currentMaxBet = removeComas(maxBet);
    if (bigOrEqual(walletUser, currentMaxBet)) {
      setBetAmountValue(String(amount(maxBet)));
    }
    if (lessThen(walletUser, currentMaxBet)) {
      setBetAmountValue(String(amount(walletUser)));
    }
    if (lessThen(walletUser, minimumBet)) {
      setBetAmountValue('0.00000000');
    }
  };

  const handleHalf = () => {
    if (!token) return;
    const currencyAmount = Number(removeComas(String(betAmountValue)));
    if (bigOrEqual(currencyAmount / 2, minimumBet)) {
      const BNHalf = new BigNumber(currencyAmount).div(2).toFixed();
      const halfBet = amount(BNHalf);
      setBetAmountValue(String(halfBet));
    }
  };

  const handleDouble = () => {
    if (!token) return;
    const currencyAmount = removeComas(String(betAmountValue));
    const currentMaxBet = removeComas(maxBet);
    const doubleBet = BNMultiply(currencyAmount, 2);

    if (bigOrEqual(doubleBet, walletUser)) {
      if (bigOrEqual(doubleBet, walletUser) && lessOrEqual(doubleBet, currentMaxBet)) {
        setBetAmountValue(String(amount(walletUser)));
        return;
      }
      if (bigOrEqual(doubleBet, walletUser) && lessOrEqual(walletUser, currentMaxBet)) {
        setBetAmountValue(String(amount(walletUser)));
        return;
      }
      if (lessOrEqual(doubleBet, currentMaxBet)) {
        setBetAmountValue(String(amount(walletUser)));
        return;
      }
      if (bigOrEqual(doubleBet, currentMaxBet)) {
        setBetAmountValue(String(amount(maxBet)));
        return;
      }
      return;
    }
    if (bigOrEqual(doubleBet, currentMaxBet)) {
      setBetAmountValue(String(amount(maxBet)));
      return;
    }
    if (+currencyAmount > 0) {
      const BNDouble = new BigNumber(currencyAmount).multipliedBy(2).toFixed();
      const halfBet = amount(BNDouble);
      setBetAmountValue(String(halfBet));
    }
  };

  const handleChangeInput = (e) => {
    let value = '';
    const currentMaxBet = removeComas(maxBet);
    value = e.target.value;
    const valid = /^\-?\d+\.\d*$|^\-?[\d]*$/;
    const number = /\-\d+\.\d*|\-[\d]*|[\d]+\.[\d]*|[\d]+/;
    if (lessThen(walletUser, minimumBet)) {
      if (e.nativeEvent.inputType === 'deleteContentBackward') {
        setBetAmountValue(e.target.value);
        return;
      }
      setBetAmountValue('0.00000000');
      return;
    }
    if (value === '0.0000000' && e.nativeEvent.inputType !== 'deleteContentBackward') {
      if (bigOrEqual(walletUser, minimumBet)) {
        setBetAmountValue(String(minimumBet));
        return;
      }
    }
    if (value === '00') {
      setBetAmountValue('0.0');
      return;
    }
    if (!valid.test(value)) {
      const n = value.match(number);
      value = n ? n[0] : '';
    }
    if (Number(value) > Number(currentMaxBet) && bigOrEqual(walletUser, currentMaxBet)) {
      setBetAmountValue(String(amount(maxBet)));
      return;
    }
    if (Number(value) > Number(walletUser)) {
      setBetAmountValue(String(amount(walletUser, false)));
      return;
    }
    setBetAmountValue(value);
  };

  const handleKyePress = (event) => {
    if (isHotKeys) {
      if (disable) return null;
      const key = event?.key?.toLowerCase();
      switch (key) {
        case 'l':
          return handleDouble();
        case 'j':
          return handleMin();
        case 'k':
          return handleHalf();
        // case 'm': return handleMax();
        default:
          return null;
      }
    }
    return null;
  };

  const handleBlurInput = () => {
    const currencyAmount = prepareAmount(betAmountValue);

    if (bigThen(currencyAmount, 0)) {
      const result = amount(currencyAmount);
      setBetAmountValue(String(result));
    } else {
      setBetAmountValue('0.00000000');
    }
  };

  useEventListener('keyup', handleKyePress);

  return (
    <div className={styles.betAmountWrap}>
      <div className={styles.betAmountButtonWrap}>
        <MainButton
          title={translate('inHouse.games.min.button')}
          variant="secondary"
          classList={styles.button}
          onClick={handleMin}
          disabled={disable}
        />
        <MainButton
          title={translate('inHouse.games.max.button')}
          variant="secondary"
          classList={styles.button}
          onClick={handleMax}
          disabled={disable}
        />
      </div>
      <div className={styles.betAmountInputWrap}>
        <p>{translate('inHouse.bet.amount')}</p>
        <div className={styles.betAmountInputContent}>
          <img src={getImgSrc(tokenCode.name)} alt="icon" />
          <input
            type="text"
            maxLength={10}
            step="0.00000001"
            value={betAmountValue}
            onChange={handleChangeInput}
            onBlur={handleBlurInput}
            disabled={disable}
            onFocus={handleSelectInputValue}
          />
        </div>
        <BonusAmountInfo gameId={gameId} betAmount={removeComas(betAmountValue)} />
      </div>
      <div className={styles.betAmountButtonWrap}>
        <MainButton title="1/2" variant="secondary" classList={styles.button} onClick={handleHalf} disabled={disable} />
        <MainButton
          title="x2"
          variant="secondary"
          classList={styles.button}
          onClick={handleDouble}
          disabled={disable}
        />
      </div>
    </div>
  );
};

export default BetAmountBlock;
