import { pathOr } from 'ramda';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ArticleObject, CollectionObject } from 'intercom-client';

import useIntercomInit from 'hooks/useIntercomInit';
import { GET_FAQ_ARTICLES, GET_FAQ_COLLECTIONS } from 'graphQl/query/faq/articles';

import IntercomContext from './context';
import { mapIntercomCollectionsToKeys } from './utils';
import { IIntercomCollection } from './types';

const IntercomProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [articles, setArticles] = useState<ArticleObject[]>([]);
  const [collections, setCollections] = useState<IIntercomCollection[]>([]);
  const [launcherHidden, setLauncherHidden] = useState(false);

  const { data: articleData, loading: articleLoading } = useQuery(GET_FAQ_ARTICLES);
  const { data: collectionData, loading: collectionLoading } = useQuery(GET_FAQ_COLLECTIONS);

  useIntercomInit();

  useEffect(() => {
    if (articleData) {
      const newArticles = pathOr<ArticleObject[]>([], ['faqArticles'], articleData);

      setArticles(newArticles);
    }
  }, [articleData]);

  useEffect(() => {
    if (collectionData) {
      const newCollections = pathOr<CollectionObject[]>([], ['faqCollections'], collectionData);
      const collectionsWithKeys = mapIntercomCollectionsToKeys(newCollections);

      setCollections(collectionsWithKeys);
    }
  }, [collectionData]);

  return (
    <IntercomContext.Provider
      value={{ articles, collections, loading: articleLoading || collectionLoading, launcherHidden, setLauncherHidden }}
    >
      {children}
    </IntercomContext.Provider>
  );
};

export default IntercomProvider;
