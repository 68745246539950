export const minesSliderDataInit = {
  1: [
    1.01, 1.05, 1.1, 1.15, 1.21, 1.28, 1.35, 1.43, 1.52, 1.62, 1.73, 1.87, 2.02, 2.2, 2.42, 2.69, 3.03, 3.46, 4.04,
    4.85, 6.06, 8.08, 12.12, 24.25,
  ],
  2: [
    1.05, 1.15, 1.26, 1.39, 1.53, 1.7, 1.9, 2.14, 2.42, 2.77, 3.2, 3.73, 4.41, 5.29, 6.47, 8.08, 10.39, 13.86, 19.4,
    29.1, 48.5, 97.0, 291.0,
  ],
  3: [
    1.1, 1.26, 1.45, 1.68, 1.96, 2.3, 2.73, 3.28, 3.98, 4.9, 6.13, 7.8, 10.14, 13.52, 18.59, 26.56, 39.84, 63.74,
    111.55, 223.1, 557.75, 2231.0,
  ],
  4: [
    1.15, 1.39, 1.68, 2.05, 2.53, 3.17, 4.01, 5.16, 6.74, 8.99, 12.26, 17.16, 24.79, 37.18, 58.43, 97.38, 175.29,
    350.59, 818.03, 2454.1, 12270.5,
  ],
  5: [
    1.21, 1.53, 1.96, 2.53, 3.32, 4.43, 6.01, 8.33, 11.8, 17.16, 25.74, 40.04, 65.07, 111.55, 204.51, 409.02, 920.29,
    2454.1, 8589.35, 51536.1,
  ],
  6: [
    1.28, 1.7, 2.3, 3.17, 4.43, 6.33, 9.25, 13.88, 21.45, 34.32, 57.21, 100.11, 185.92, 371.83, 818.03, 2045.08,
    6135.25, 24541.0, 171787.0,
  ],
  7: [
    1.35, 1.9, 2.73, 4.01, 6.01, 9.25, 14.65, 23.98, 40.76, 72.46, 135.86, 271.72, 588.74, 1412.97, 3885.66, 12952.19,
    58284.88, 466279.0,
  ],
  8: [
    1.43, 2.14, 3.28, 5.16, 8.33, 13.88, 23.98, 43.16, 81.52, 163.03, 349.36, 815.17, 2119.45, 6358.35, 23313.95,
    116569.75, 1049127.75,
  ],
  9: [
    1.52, 2.42, 3.98, 6.74, 11.8, 21.45, 40.76, 81.52, 173.22, 395.94, 989.85, 2771.59, 9007.66, 36030.65, 198168.58,
    1981685.75,
  ],
  10: [
    1.62, 2.77, 4.9, 8.99, 17.16, 34.32, 72.46, 163.03, 395.94, 1055.84, 3167.53, 11086.35, 48040.87, 288245.2,
    3170697.2,
  ],
  11: [1.73, 3.2, 6.13, 12.26, 25.74, 57.21, 135.86, 349.36, 989.85, 3167.53, 11878.24, 55431.77, 360306.5, 4323678.0],
  12: [1.87, 3.73, 7.8, 17.16, 40.04, 100.11, 271.72, 815.17, 2771.59, 11086.35, 55431.77, 388022.38, 5044291.0],
  13: [2.02, 4.41, 10.14, 24.79, 65.07, 185.92, 588.74, 2119.45, 9007.66, 48040.87, 360306.5, 5044291.0],
  14: [2.2, 5.29, 13.52, 37.18, 111.55, 371.83, 1412.97, 6358.35, 36030.65, 288245.2, 4323678.0],
  15: [2.42, 6.47, 18.59, 58.43, 204.51, 818.03, 3885.66, 23313.95, 198168.58, 3170697.2],
  16: [2.69, 8.08, 26.56, 97.38, 409.02, 2045.08, 12952.19, 116569.75, 1981685.75],
  17: [3.03, 10.39, 39.84, 175.29, 920.29, 6135.25, 58284.87, 1049127.75],
  18: [3.46, 13.86, 63.74, 350.59, 2454.1, 24541.0, 466279.0],
  19: [4.04, 19.4, 111.55, 818.03, 8589.35, 171787.0],
  20: [4.85, 29.1, 223.1, 2454.1, 51536.1],
  21: [6.06, 48.5, 557.75, 12270.5],
  22: [8.08, 97.0, 2231.0],
  23: [12.13, 291.0],
  24: [24.25],
};
