import { useContext, useEffect, useState } from 'react';

import { useAppSelector } from 'hooks/useAppSelector';
import { userToken } from 'store/user/user.selectors';
import { ContextPopUps, ContextTokenCode } from 'context';
import { popUps } from 'components/constants/constants';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import Button from 'components/Base/Button';

import styles from './styles.module.scss';

const HeaderPreRegister: React.FC = () => {
  const { translate } = useLocalization();
  const { popUpsOpen, setPopUpsOpen } = useContext(ContextPopUps);
  const { blockedIp } = useContext(ContextTokenCode);

  const [disable, setDisable] = useState(false);

  const token = useAppSelector(userToken);

  useEffect(() => {
    document.body.style.setProperty('overflow', popUpsOpen ? 'hidden' : 'unset');
  }, [popUpsOpen]);

  const handleLogIn = () => {
    // if (blockedIp) {
    //   setDisable(true);
    //   setPopUpsOpen(popUps.blockedIp);
    //   return;
    // }

    setPopUpsOpen(popUps.login);
  };

  const handleRegistration = () => {
    if (blockedIp) {
      setDisable(true);
      setPopUpsOpen(popUps.blockedIp);
      return;
    }

    setPopUpsOpen(popUps.registration);
  };

  return (
    <>
      {!token && (
        <div className={styles.preRegisterWrap}>
          <div>
            <Button
              className={styles.button}
              title={translate('header.login')}
              variant={ButtonType.secondary}
              onclick={handleLogIn}
              width="116px"
            />
          </div>
          <div>
            <Button
              className={styles.button}
              title={translate('header.signup')}
              variant={ButtonType.primary}
              onclick={handleRegistration}
              width="131px"
              disabled={disable}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderPreRegister;
