import { CoinAnimationNames, CoinMultiplier, ECoinCondition } from 'components/constants/games';
import { CoinBetMode } from 'types';
import { generate as Id } from 'shortid';
import Big from 'big.js';

import { amount, BNMultiply, removeComas } from './common';
import { bigThen, lessOrEqual } from './prepareDataDice';
import {
  HistoryEl,
  ICoinBetResponse,
  ICollectCoinRoundResponse,
  IPlayCoinMultiplyBetResponseLoss,
  IPlayCoinMultiplyBetResponseWin,
} from '../components/Games/CoinFlip/components/CoinContent/types';
import goldCoinSide from '../assets/img/CoinFlip/goldSide.webp';
import silverCoinSide from '../assets/img/CoinFlip/silverSide.webp';

export const getCoinAnimationName = (fastMode: boolean, condition: string): string => {
  if (fastMode) {
    if (condition === CoinBetMode.heads) return CoinAnimationNames.headsFast;
    if (condition === CoinBetMode.tails) return CoinAnimationNames.tailsFast;
  }
  if (condition === CoinBetMode.heads) return CoinAnimationNames.heads;
  if (condition === CoinBetMode.tails) return CoinAnimationNames.tails;
};

export const isDisableCoinButton = (gameState: string): boolean => {
  switch (gameState) {
    case ECoinCondition.getStarted:
      return true;
    case ECoinCondition.sessionInit:
      return true;
    case ECoinCondition.sessionStarted:
      return true;
    default:
      return false;
  }
};

export const isDisableCoinCheckbox = (gameState: string): boolean => {
  switch (gameState) {
    case ECoinCondition.getStarted:
      return true;
    case ECoinCondition.sessionInit:
      return true;
    case ECoinCondition.sessionStarted:
      return true;
    default:
      return false;
  }
};

export const isDisableCoinOptionsButton = (gameState: string): boolean => {
  switch (gameState) {
    case ECoinCondition.getStarted:
      return true;
    case ECoinCondition.sessionNotStarted:
      return true;
    default:
      return false;
  }
};

export const isDisableCoinInitButton = (gameState: string, series: number): boolean => {
  if (!series && gameState === ECoinCondition.sessionInit) return true;
  switch (gameState) {
    case ECoinCondition.getStarted:
      return true;
    default:
      return false;
  }
};

export const getCountMultiplier = (multiplier: number, num: number): string => {
  if (!Number(multiplier)) return String(CoinMultiplier);
  if (!num) return '0';
  const result = Number(multiplier) ** num;
  return Big(result).toFixed(2, Big.roundDown);
};

export const checkMaxPay = (betAmount: string, maxPay: string): string => {
  const current = removeComas(betAmount);
  const currentBet = Number(current) > 0 ? current : '0';
  if (lessOrEqual(currentBet, maxPay)) return String(amount(currentBet));
  return String(amount(maxPay));
};

export const getBetProfit = (betAmount: string, series: number, maxPay: string): string => {
  const currentBetAmount = removeComas(betAmount);
  const multiplier = getCountMultiplier(CoinMultiplier, series + 1);
  const profit = BNMultiply(currentBetAmount, Number(multiplier));
  return checkMaxPay(profit, maxPay);
};

export const getBetCashOut = (betAmount: string, multiplier: string, maxPay: string): string => {
  const currentBetAmount = removeComas(betAmount);
  const profit = BNMultiply(currentBetAmount, Number(multiplier));
  return checkMaxPay(profit, maxPay);
};

export const getCoinTableHistory = (
  state: HistoryEl[],
  bet: ICoinBetResponse,
  displayName: string,
  betAmountValue: string,
  conditionResult: string,
  animationName: string,
): HistoryEl[] => {
  if (!state) return null;
  const date = new Date();
  const isBlack = !state[0]?.isBlack;
  const object = {
    createdAt: date,
    id: bet.id,
    amount: betAmountValue,
    multiplier: `${bet.multiplier}x`,
    payout: bet.payout,
    displayName,
    isBlack,
    result: bigThen(bet.payout, '0') ? 'win' : 'lose',
    animationName,
    condition: conditionResult,
  };
  return [object, ...state.slice(0, 30)];
};

export const getCoinHistory = (
  state: HistoryEl[],
  bet: ICoinBetResponse | IPlayCoinMultiplyBetResponseLoss | IPlayCoinMultiplyBetResponseWin,
  displayName: string,
  betAmountValue: string,
  conditionResult: string,
  animationName: string,
  result: string,
): HistoryEl[] => {
  if (!state) return null;
  const date = new Date();
  const isBlack = !state[0]?.isBlack;
  const object = {
    createdAt: date,
    id: Id(),
    amount: betAmountValue,
    multiplier: '',
    payout: '',
    displayName,
    isBlack,
    result,
    animationName,
    condition: conditionResult,
  };
  return [object, ...state.slice(0, 30)];
};

export const getCoinHistoryTableCollect = (
  state: HistoryEl[],
  bet: ICollectCoinRoundResponse,
  displayName: string,
  betAmountValue: string,
): HistoryEl[] => {
  if (!state) return null;
  const date = new Date();
  const isBlack = !state[0]?.isBlack;
  const object = {
    createdAt: date,
    id: bet.id,
    amount: betAmountValue,
    multiplier: `${bet.multiplier}x`,
    payout: bet.payout,
    displayName,
    isBlack,
    result: bigThen(bet.payout, '0') ? 'win' : 'lose',
    animationName: 'heads',
    condition: '',
  };
  return [object, ...state.slice(0, 30)];
};

export const getCoinsResultsListPopUp = (
  payout: string,
  prediction: number[] | number,
): Array<{ result: string; src: string; id: string; payout: boolean }> => {
  if (typeof prediction === 'number') {
    return [
      {
        id: Id(),
        src: prediction ? goldCoinSide : silverCoinSide,
        result: bigThen(payout, '0') ? 'Win' : 'Lose',
        payout: bigThen(payout, '0'),
      },
    ];
  }

  const result = prediction.map((num, index) => ({
    id: Id(),
    src: num ? goldCoinSide : silverCoinSide,
    result: getCountMultiplier(CoinMultiplier, index + 1),
    payout: true,
  }));

  const popped = result.pop();
  const lastEl = {
    id: Id(),
    src: popped.src,
    result: bigThen(payout, '0') ? popped.result : '0.00',
    payout: bigThen(payout, '0'),
  };
  return [...result, lastEl];
};

export const getMultiplierSeries = (state: {
  series: number;
  multiplier: string;
}): { series: number; multiplier: string } => ({
  series: state.series + 1,
  multiplier: getCountMultiplier(CoinMultiplier, state.series + 1),
});

export const getButtonTitleCoin = (gameState: string): string => {
  switch (gameState) {
    case ECoinCondition.sessionNotStarted:
      return 'common.games.bet.button';
    default:
      return 'common.games.bet.cashout';
  }
};

export const isDisplayCoinCashOut = (gameState: string, isMultiplier: boolean): boolean => {
  if (isMultiplier) {
    switch (gameState) {
      case ECoinCondition.sessionNotStarted:
        return false;
      default:
        return true;
    }
  }

  return false;
};

export const getCoinHistoryRound = (state: number[]): HistoryEl[] => {
  if (!state) return null;
  const date = new Date();
  const isBlack = true;
  const result = state.map((el) => ({
    createdAt: date,
    id: Id(),
    amount: '',
    multiplier: '',
    payout: '',
    displayName: '',
    isBlack,
    result: 'win',
    animationName: el ? CoinAnimationNames.headsFast : CoinAnimationNames.tailsFast,
    condition: el ? 'heads' : 'tales',
  }));
  return result;
};
