import firstPlace from 'assets/img/Events/firstPlace.webp';
import secondPlace from 'assets/img/Events/secondPlace.webp';
import thirdPlace from 'assets/img/Events/thirdPlace.webp';

import { User } from 'types/requestTypes';
import { amount } from 'func/common';
import { Links } from 'components/constants';

interface LotteryHistory {
  id: string;
  lotteryRoundId: number;
  place: number;
  prize: string;
  user: User;
}

interface LotteryRound {
  id: number;
  displayName: string;
  lotteryHistory: LotteryHistory[];
}

interface PreparedLotteryData {
  amount: string;
  avatar: string;
  displayName: string;
  rank: number;
  alias?: string;
  id?: string;
}

export const getPlaceImage = (index: number): string | null => {
  switch (index) {
    case 0:
      return firstPlace;
    case 1:
      return secondPlace;
    case 2:
      return thirdPlace;
    default:
      return null;
  }
};

export const disableSliderArrow = (direction: string, active: number, length: number): boolean => {
  if (direction === 'right') {
    if (active === length) {
      return true;
    }
    if (active === 9) {
      return true;
    }
  }
  if (direction === 'left') {
    if (active === 0) {
      return true;
    }
  }
  return false;
};

export const prepareDataLotteryTable = (lotteryRounds: LotteryRound[]): PreparedLotteryData[][] => {
  if (!lotteryRounds?.length) {
    return [];
  }

  const rounds = [...lotteryRounds.slice(0, 10)];

  const tableArray = rounds.reduce((acc, round) => {
    const tokenCode = round.displayName;

    const tableElements = round.lotteryHistory.slice(0, 5).map((history) => ({
      amount: amount(history.prize),
      avatar: history.user?.avatar?.avatar,
      displayName: tokenCode,
      alias: history.user.alias,
      rank: history.user.rank?.id,
      id: history.id,
    }));

    acc.push(tableElements);

    return acc;
  }, []);

  return tableArray.reverse();
};

export const prepareDataSlider = (data: LotteryHistory[]): Array<{ id: string }> => {
  if (!data || data.length < 1) {
    return [{ id: '1' }];
  }
  const roundsHistory = [...data.slice(0, 10)];
  return roundsHistory.map((el) => ({ id: String(el.id) })).reverse();
};

export const getIsDisplayLotteryHeader = (path: string): boolean => {
  switch (path) {
    case Links.dice:
      return true;
    case Links.mines:
      return true;
    case Links.limbo:
      return true;
    case Links.coinflip:
      return true;
    case Links.keno:
      return true;
    case Links.blackJack:
      return true;
    case Links.blackJackVip:
      return true;
    case Links.inHouseCoinflip:
      return true;
    case Links.diceSeo:
      return true;
    case Links.inHouseKeno:
      return true;
    case Links.inHouseLimbo:
      return true;
    case Links.inHouseMines:
      return true;
    default:
      return false;
  }
};
export const getIsDisplayLotteryMobile = (path: string): boolean => {
  if (path.match('slots')) return true;
  if (path.match(Links.landing)) return true;

  switch (path) {
    case Links.terms:
      return true;
    case Links.whitepaper:
      return true;
    default:
      return false;
  }
};
