import React from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getScheduleColorLine, prepareGraphData } from 'func/prepareDataDividends';
import { amount } from 'func/common';

import styles from './styles.module.scss';
import { ICustomTooltipSchedulePool, IPoolSchedule } from './types';

const CustomTooltip: React.FC<ICustomTooltipSchedulePool> = ({ payload }) => (
  <div>
    <p className={styles.poolScheduleLabel}>{payload && amount(payload[0]?.value)}</p>
    <p className={styles.poolScheduleLabel}>{payload?.[0]?.payload?.date}</p>
  </div>
);

const PoolSchedule: React.FC<IPoolSchedule> = ({ requestToken, dataSchedule }) => {
  const data = prepareGraphData(dataSchedule);
  return (
    <div className={styles.poolSchedule}>
      <ResponsiveContainer>
        <AreaChart
          data={data}
          margin={{
            top: 10,
            right: 20,
            left: 25,
            bottom: 0,
          }}
        >
          <XAxis
            dataKey="date"
            tick={{ stroke: '#888AA7', strokeWidth: 0, fontSize: 10 }}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            type="number"
            domain={['dataMin', 'dataMax']}
            tickLine={false}
            axisLine={false}
            tick={false}
            tickSize={0}
            width={0}
          />
          <CartesianGrid horizontal={false} stroke="rgba(136, 138, 167, 0.15)" />
          <Tooltip content={<CustomTooltip payload={data} />} />
          <Area
            // type="temperature"
            dataKey="point"
            stroke={getScheduleColorLine(requestToken)}
            fill="transparent"
            strokeWidth={5}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PoolSchedule;
