import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';
import { generate as Id } from 'shortid';
import { sha3_256 } from 'js-sha3';

import config from 'config';
import { GET_BET } from 'graphQl/query/bet/bet';
import { IBet } from 'components/Base/PopUps/components/base/BetDetailsWrap/types';
import { getPlayerHandBetDetails } from 'func/blackJack';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import BetDetailsWrap from 'components/Base/PopUps/components/base/BetDetailsWrap';
import Loader from 'components/Base/Loader/Loader';
import Button from 'components/Base/Button';
import CopyButton from 'components/Base/CopyButton';
import BetDetailsCard from './components/BetDetailsCard';

import { IBlackJackBetDetails, IBlackJackDetailsHand } from './types';
import styles from './styles.module.scss';

const { sha256ValidatorUrl } = config;

const BlackJackBetDetails: React.FC<IBlackJackBetDetails> = ({ data }) => {
  const { translate } = useLocalization();

  const [bet, setBet] = useState<IBet>();
  const [gameHand, setGameHand] = useState<IBlackJackDetailsHand>(null);

  const { data: betData, loading } = useQuery(GET_BET, {
    variables: { betId: data.id },
  });

  useEffect(() => {
    const newBet = pathOr<IBet>(undefined, ['bet'], betData);

    if (newBet) {
      setBet(newBet);
      setGameHand(getPlayerHandBetDetails(newBet.betOn.playerHands, newBet.result.dealerCards));
    }
  }, [betData]);

  const handleVerify = () => {
    window.open(sha256ValidatorUrl, '_blank');
  };

  if (loading || !bet) return <Loader pages />;

  return (
    <BetDetailsWrap bet={bet}>
      <div className={styles.blackJackBet}>
        <div className={styles.blackJackBetItem}>
          <div className={styles.blackJackBetResult}>
            <h3>{translate('popups.bets.details.player')}:</h3>
            <h4>
              {gameHand.firstHand.cardsTotal.flex
                ? gameHand.firstHand.cardsTotal.value + 10
                : gameHand.firstHand.cardsTotal.value}
              {gameHand.secondHand.outcome ? ',' : ''}
            </h4>
            {gameHand.secondHand.outcome ? (
              <h4>
                {gameHand.secondHand.cardsTotal.flex
                  ? gameHand.secondHand.cardsTotal.value + 10
                  : gameHand.secondHand.cardsTotal.value}
              </h4>
            ) : null}
          </div>
          {gameHand.firstHand.cards.map((c, index) => (
            <BetDetailsCard icon={c} top={20} left={index * 11} key={Id()} />
          ))}
          {gameHand.secondHand.cards.map((c, index) => (
            <BetDetailsCard icon={c} top={35} left={index * 11} key={Id()} />
          ))}
        </div>
        <div className={styles.blackJackBetItem}>
          <div className={styles.blackJackBetResult}>
            <h3>{translate('popups.bets.blackjack.dealer')}:</h3>
            <h4>
              {gameHand.dealerHand.cardsTotal.flex
                ? gameHand.dealerHand.cardsTotal.value + 10
                : gameHand.dealerHand.cardsTotal.value}
            </h4>
          </div>
          {gameHand.dealerHand.cards.map((c, index) => (
            <BetDetailsCard icon={c} top={20} left={index * 11} key={Id()} />
          ))}
        </div>
      </div>
      <div className={styles.seeds}>
        <div className={styles.subtitleItem}>
          <p>{translate('popups.bets.details.player-seed')}:</p>
        </div>
        <div className={styles.subtitleItem}>
          <div className={styles.seedsText}>
            <p>{bet.serverSeed}</p>
            <CopyButton copyContent={bet.serverSeed} />
          </div>
        </div>
      </div>
      <div className={styles.seeds}>
        <div className={styles.subtitleItem}>
          <p>{translate('popups.bets.details.hash')}:</p>
        </div>
        <div className={styles.subtitleItem}>
          <div className={styles.seedsText}>
            <p>{sha3_256(bet.serverSeed || '')}</p>
            <CopyButton copyContent={sha3_256(bet.serverSeed || '')} />
          </div>
        </div>
      </div>
      <div className={styles.verifyButtonWrapper}>
        <Button
          className={styles.verifyButton}
          title={translate('popups.bets.details.verify')}
          variant={ButtonType.primary}
          onclick={handleVerify}
          width="100px"
        />
      </div>
    </BetDetailsWrap>
  );
};

export default BlackJackBetDetails;
