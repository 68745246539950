import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';

import copyIcon from 'assets/img/common/copyIcon.svg';
import { Links } from 'components/constants';
import { GET_REFERRAL_CODE } from 'graphQl/query/referral/referralCode';
import { useAppSelector } from 'hooks/useAppSelector';
import { PLATFORM_URL } from 'components/constants/constants';
import { GET_REFERRAL_ACTIVITY } from 'graphQl/query/referral/referralActivity';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';
import { useNavigate } from 'hooks/useNavigate';
import { ButtonType } from 'components/Base/Button/types';

import Button from 'components/Base/Button';
import HighCardTitle from 'components/Base/Cards/HighCardTitle';

import styles from './styles.module.scss';

const referralRef = '/?ref=';

const BalanceCardReferral: React.FC = () => {
  const navigate = useNavigate();
  const { translate } = useLocalization();

  const [referralCode, setReferralCode] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [referrals, setReferrals] = useState(0);

  const token = useAppSelector(userToken);

  useQuery(GET_REFERRAL_ACTIVITY, {
    variables: {
      input: {
        limit: 5,
      },
    },
    onCompleted: (dataActivity) => {
      setReferrals(dataActivity.referralActivity.count);
    },
    skip: !token,
  });

  const { data: daraCode } = useQuery(GET_REFERRAL_CODE, {
    fetchPolicy: 'cache-only',
  });

  const referralCodDkt = PLATFORM_URL + referralRef + referralCode;

  const handleCopy = () => {
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };

  const handlePromo = () => navigate(Links.promoMaterials);

  useEffect(() => {
    if (daraCode) {
      setReferralCode(daraCode.profile.referralCode);
    }
  }, [daraCode]);

  return (
    <HighCardTitle
      height="auto"
      subtitle={translate('referral.link.description')}
      title={translate('referral.link.title')}
      blurIfNotAuth
    >
      <div className={styles.linkBody}>
        <div className={styles.linkBodyElem}>
          <CopyToClipboard text={referralCodDkt}>
            <div className={styles.linkBodyCopy}>
              <div className={styles.linkBodyCopyDkt}>
                <span className={styles.ref}>{referralCodDkt}</span>
              </div>
              <div className={styles.linkBodyCopyMob}>
                <span className={styles.ref}>https://starbets.io/</span>
                <span className={styles.ref}>{referralRef + referralCode}</span>
              </div>
              <div className={clsx(styles.linkBodyTooltip, showTooltip ? styles.open : '')}>
                {translate('referral.link.tooltip')}
              </div>
              <div className={styles.linkBodyButton} onClick={handleCopy}>
                <p>{translate('referral.link.button.copy.text')}</p>
                <img src={copyIcon} alt="icon" />
              </div>
            </div>
          </CopyToClipboard>
        </div>
        <div className={styles.linkBodyReferrals}>
          <code>{translate('referral.link.card.text')}</code>
          <div />
          <code>{referrals}</code>
        </div>
        <Button
          variant={ButtonType.secondary}
          width="231px"
          title={translate('referral.link.buttton.promo.text')}
          onclick={handlePromo}
        />
      </div>
    </HighCardTitle>
  );
};

export default BalanceCardReferral;
