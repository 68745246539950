import React, { useEffect, useState } from 'react';

import { useKeno } from 'context/contextKeno/contextKeno';
import { getOddsList } from 'func/prepareDataKeno';
import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';
import KenoOddsItem from './KenoOddsItem';
import { IKenoOddsItem } from './types';

const KenoOdds: React.FC = () => {
  const { translate } = useLocalization();
  const [state] = useKeno();
  const { risk, selectedSquaresList, winInfo } = state;

  const [oddsArr, setOddsArr] = useState<IKenoOddsItem[]>([]);

  useEffect(() => {
    if (selectedSquaresList && risk) {
      setOddsArr(getOddsList(selectedSquaresList.length, risk));
    }
  }, [selectedSquaresList, risk]);

  return (
    <div className={styles.kenoOddsWrap}>
      {oddsArr.length ? (
        oddsArr.map((el, index) => (
          <KenoOddsItem
            key={el.id}
            hits={el.hits}
            ods={el.ods}
            isActive={index === winInfo.oddsNum}
            hitsText={translate('keno.hits.text')}
          />
        ))
      ) : (
        <div className={styles.kenoOddsEmpty}>
          <h4>{translate('keno.description.text')}</h4>
          <h5>1</h5>-<h6>10</h6>
        </div>
      )}
    </div>
  );
};

export default KenoOdds;
