import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { useMines } from 'context/contextMines/contextMines';
import { ContextTokenCode } from 'context';
import LiveStatsContext from 'context/contextLiveStats/context';
import { Types } from 'context/contextMines/minesReduser';
import { MINES_BET } from 'graphQl/mutations/mines/mines';
import { checkAutoPick, getIsAutoPick } from 'func/prepareDataMines';
import { removeComas } from 'func/common';

import styles from './styles.module.scss';
import MinesSquare from './MinesSquare';

const MinesSquares: React.FC = () => {
  const [state, dispatch] = useMines();

  const { enabled: liveStatsEnabled, onBet: recordLiveStats } = useContext(LiveStatsContext);
  const { tokenCode } = useContext(ContextTokenCode);

  const { squaresData, minesSelected, gameCondition, autoPickItems, gameId, betAmountValue } = state;

  const [minesBet] = useMutation(MINES_BET);

  const handlePick = async (item: number) => {
    dispatch({ type: Types.ON_PICK_SQUARE, payload: { item } });
    try {
      const response = await minesBet({
        variables: {
          prediction: item,
          token: tokenCode.token,
          gameId,
        },
      });
      const bet = response.data.minesBet;

      if (liveStatsEnabled && bet?.payout) {
        recordLiveStats(removeComas(betAmountValue), bet.payout);
      }

      dispatch({ type: Types.BET_RESPONSE, payload: { bet, item, tokenCode } });
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleOnAutoPick = (item) => {
    if (checkAutoPick(squaresData, item, minesSelected, autoPickItems)) return;
    dispatch({ type: Types.ON_AUTO_PICK_SQUARE, payload: { item } });
  };

  return (
    <div className={styles.minesSquaresWrap}>
      {squaresData.map((item) => (
        <MinesSquare
          key={item.id}
          className={item.condition}
          handlePick={getIsAutoPick(gameCondition) ? handleOnAutoPick : handlePick}
          item={item.item}
          minesSelected={minesSelected}
          gameCondition={gameCondition}
          autoPickItems={autoPickItems}
        />
      ))}
    </div>
  );
};

export default MinesSquares;
