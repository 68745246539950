import { gql } from '@apollo/client';

export const GET_ADDRESS_BOOK = gql`
  query addressBook {
    addressBook {
      id
      address
      isWhitelisted
      name
      token {
        tokenCode
        displayName
      }
      network {
        code
      }
      memo
    }
  }
`;
