import { gql } from '@apollo/client';

export const GET_FAQ_ARTICLES = gql`
  query faqArticles {
    faqArticles {
      id
      title
      body
      parent_id
      updated_at
    }
  }
`;

export const GET_FAQ_COLLECTIONS = gql`
  query faqCollections {
    faqCollections {
      id
      name
    }
  }
`;
