import React from 'react';

import { IMinesDiamondBombItem } from './types';
import styles from './styles.module.scss';

const MinesDiamondBombItem: React.FC<IMinesDiamondBombItem> = ({ image, count, title }) => (
  <div className={styles.minesDiamondBombItemWrap}>
    <img src={image} alt="imag" className={styles.minesDiamondBombItemImage} />
    <div className={styles.minesDiamondBombItemDescription}>
      <h5>{title}</h5>
      <h6>{count}</h6>
    </div>
  </div>
);

export default MinesDiamondBombItem;
