import React, { useContext, useEffect, useState } from 'react';

import { ContextTokenCode } from 'context';
import { ETokenCode } from 'components/constants/constants';
import { createCurrenciesAllTokensData, getDefaultCurrenciesTemplate } from 'func/prepareDataAffiliates';

import { ICurrencyItem, ICurrencyTemplate } from './types';
import CurrenciesTemplate from './currenciesTemplate';

const CurrenciesTemplateContainer: React.FC<ICurrencyTemplate> = ({ currencies, isStarToken, size }) => {
  const { tokenWithNetworksList } = useContext(ContextTokenCode);
  const [tokenData, setTokenData] = useState<ICurrencyItem[]>(null);

  useEffect(() => {
    if (tokenWithNetworksList) {
      const currenciesData = getDefaultCurrenciesTemplate(tokenWithNetworksList?.tokenWithNetworks);
      if (currencies?.length) {
        const allTokens = createCurrenciesAllTokensData(currenciesData, currencies);
        const sortedCurrencies = !isStarToken
          ? allTokens.filter((el) => el.displayName !== ETokenCode.STAR)
          : allTokens;
        setTokenData(sortedCurrencies);
      } else {
        const sortedCurrencies = !isStarToken
          ? currenciesData.filter((el) => el.displayName !== ETokenCode.STAR)
          : currenciesData;
        setTokenData(sortedCurrencies);
      }
    }
  }, [tokenWithNetworksList, isStarToken, currencies]);

  if (!tokenData) return null;

  return <CurrenciesTemplate currencies={tokenData} size={size} />;
};

export default CurrenciesTemplateContainer;
