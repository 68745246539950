import React, { useContext } from 'react';

import { useEventListener } from 'hooks/useEventListener';
import { useKeno } from 'context/contextKeno/contextKeno';
import { Types } from 'context/contextKeno/kenoReduser';
import SocketContext from 'context/contextSocket/context';
import { disableKenoButtons } from 'func/prepareDataKeno';
import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';

const KenoAdditionalButtons: React.FC = () => {
  const { translate } = useLocalization();
  const [state, dispatch] = useKeno();
  const { connected: socketConnected } = useContext(SocketContext);

  const { gameCondition, disable, isHotkeys } = state;

  const handleAutoPick = () => dispatch({ type: Types.AUTO_PICK });

  const handleClearTable = () => dispatch({ type: Types.CLEAR_TABLE });

  const handleKyePress = (event) => {
    if (isHotkeys) {
      if (disableKenoButtons(gameCondition, disable) || !socketConnected) return null;
      const key = event?.key?.toLowerCase();
      switch (key) {
        case 'a':
          return handleAutoPick();
        case 'x':
          return handleClearTable();
        default:
          return null;
      }
    }
    return null;
  };

  useEventListener('keyup', handleKyePress);

  return (
    <div className={styles.kenoAdditionalButtonsWrap}>
      <button
        onClick={handleAutoPick}
        className={styles.kenoAdditionalButtonPick}
        disabled={disableKenoButtons(gameCondition, disable) || !socketConnected}
        type="button"
      >
        {translate('keno.auto.pick.text')}
      </button>
      <button
        onClick={handleClearTable}
        className={styles.kenoAdditionalButtonTable}
        disabled={disableKenoButtons(gameCondition, disable) || !socketConnected}
        type="button"
      >
        {translate('keno.clear.table.text')}
      </button>
    </div>
  );
};

export default KenoAdditionalButtons;
