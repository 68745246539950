import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { SocketActions } from 'types';
import { ContextTokenCode } from 'context';
import { GET_WALLET } from 'graphQl/query/wallet/wallet';
import { useAppSelector } from 'hooks/useAppSelector';
import { userToken } from 'store/user/user.selectors';
import { generalDataExchangeRates } from 'store/generalData/generalDtata.selectors';
import { ETokenCode } from 'components/constants/constants';
import { convertCryptoToUSD } from 'func/common';

import { ISocketBalanceUpdate } from './contextSocket/types';
import SocketContext from './contextSocket/context';

export const ContextWallet = React.createContext({
  walletUser: 0,
  walletUserStar: null,
  usdBalance: '0',
  tokenDetails: null,
});

export const WalletProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { tokenCode } = useContext(ContextTokenCode);
  const { socket, connected: socketConnected } = useContext(SocketContext);

  const [walletUser, setWalletUser] = useState(null);
  const [walletUserStar, setWalletUserStar] = useState(null);
  const [usdBalance, setUsdBalance] = useState('0');
  const [tokenDetails, setTokenDetails] = useState(null);
  const [updatedSocketState, setUpdatedSocketState] = useState<ISocketBalanceUpdate>({});

  const token = useAppSelector(userToken);
  const exchangeRates = useAppSelector(generalDataExchangeRates);

  useQuery(GET_WALLET, {
    skip: !token,
    variables: { input: tokenCode.token },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setWalletUser(data?.wallet?.availableBalance);
      setTokenDetails(data?.wallet.token);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log('[WALLET_ERROR]: ', error);
    },
  });

  const { data: dataWallet } = useQuery(GET_WALLET, {
    skip: !token,
    variables: { input: 'STAR' },
    fetchPolicy: 'cache-only',
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log('[STAR_WALLET_ERROR]: ', error);
    },
  });

  function handleSocketBalanceUpdate(data: ISocketBalanceUpdate) {
    setUpdatedSocketState(data);
  }

  useEffect(() => {
    if (dataWallet) {
      setWalletUserStar(dataWallet?.wallet?.availableBalance);
    }
  }, [dataWallet]);

  useEffect(() => {
    if (socketConnected) {
      socket.on(SocketActions.balance, handleSocketBalanceUpdate);
    }
  }, [socketConnected]);

  useEffect(() => {
    const currentToken = tokenCode?.token;

    if (!currentToken || currentToken === ETokenCode.STAR || !walletUser) {
      setUsdBalance('0');
      return;
    }

    if (Object.values(exchangeRates).length) {
      const exchangeRate = exchangeRates[currentToken];

      if (exchangeRate) {
        const newBalance = convertCryptoToUSD(exchangeRate, walletUser);

        setUsdBalance(newBalance);
        return;
      }

      setUsdBalance('0');
    }
  }, [walletUser, exchangeRates]);

  useEffect(() => {
    if (Object.keys(updatedSocketState).length) {
      const updatedStarBalance = updatedSocketState.STAR;
      const updatedMainWalletBalance = tokenDetails ? updatedSocketState[tokenDetails.tokenCode] : null;

      if (updatedStarBalance) {
        setWalletUserStar(updatedStarBalance);
      }

      if (updatedMainWalletBalance) {
        setWalletUser(updatedMainWalletBalance);
      }
    }
  }, [updatedSocketState]);

  return (
    <ContextWallet.Provider
      value={{
        walletUser,
        walletUserStar,
        usdBalance,
        tokenDetails,
      }}
    >
      {children}
    </ContextWallet.Provider>
  );
};
