import { gql } from '@apollo/client';

export const ADD_FAVORITE_GAME = gql`
  mutation addFavoriteGame($gameId: String!) {
    addFavoriteGame(gameId: $gameId) {
      gameId
    }
  }
`;

export const DELETE_FAVORITE_GAME = gql`
  mutation deleteFavoriteGame($gameId: String!) {
    deleteFavoriteGame(gameId: $gameId)
  }
`;
