import dayjs from 'dayjs';
import { TOURNAMENT_TIMER_STATE } from 'components/constants/pages';

export const getTournamentDate = (startAt: Date, endAt: Date): string => {
  if (!startAt || !endAt) return '';

  const startDate = dayjs(startAt).format('D MMM');
  const endData = dayjs(endAt).format('D MMM');

  return `${startDate} - ${endData}`;
};

export const getTournamentTimerState = (finish: boolean, start: boolean): string => {
  if (!finish) {
    return TOURNAMENT_TIMER_STATE.finished;
  }
  if (!start) {
    return TOURNAMENT_TIMER_STATE.notStarted;
  }
  return TOURNAMENT_TIMER_STATE.started;
};
