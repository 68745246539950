import DividendBalance from './DividendsBalance';
import DividendPool from './components/DividendPool';
import styles from './styles.module.scss';
import { StyledFirstBlock } from './styles';

const CardBlockFirst: React.FC = () => (
  <StyledFirstBlock className={styles.dividendsCardsBlockFirst}>
    <DividendPool />
    <DividendBalance />
  </StyledFirstBlock>
);

export default CardBlockFirst;
