import { useContext } from 'react';

import btnIcon from 'assets/img/common/circle-multiple.webp';

import { useAppSelector } from 'hooks/useAppSelector';
import { getRankImg } from 'func/prepareDataCashback';
import { ContextPopUps } from 'context';
import { popUps } from 'components/constants/constants';
import { ButtonType } from 'components/Base/Button/types';
import { generalDataVipRank } from 'store/generalData/generalDtata.selectors';
import { checkIsVip } from 'func/common';
import { useLocalization } from 'components/Internationalization';

import Avatar from 'components/Base/Avatar';
import Button from 'components/Base/Button';

import styles from './styles.module.scss';
import { ITipsInfo } from './types';

const TipsInfo: React.FC<ITipsInfo> = ({ data: { alias, rank, avatar } }) => {
  const { translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const vipRank = useAppSelector(generalDataVipRank);

  const handleClick = () => {
    setPopUpsOpen({
      modalOpen: popUps.walletNavigation,
      data: {
        alias,
        config: popUps.deposit,
        active: popUps.tipsRequest,
        avatar,
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <Avatar className={styles.avatar} userAvatar={avatar} vip={checkIsVip(vipRank, rank)} />
      <h6 className={styles.alias}>{alias}</h6>
      <div className={styles.rank}>
        <img className={styles.rankIcon} src={getRankImg(Number(rank) || 1)} alt="rank" />
        <h5 className={styles.rankDesc}>{`${translate('popups.tips.inform.rank')} ${rank || 1}`}</h5>
      </div>
      <div className={styles.buttons}>
        <img className={styles.buttonIcon} alt="icon" src={btnIcon} />
        <Button
          className={styles.button}
          variant={ButtonType.secondary}
          type="button"
          width="159px"
          title={translate('popups.tips.inform.button')}
          color="#182D58"
          onclick={handleClick}
        />
      </div>
    </div>
  );
};

export default TipsInfo;
