import { gql } from '@apollo/client';

export const CHANGELLY_AVAILABLE_COUNTRIES = gql`
  query changellyAvailableCountries {
    changellyAvailableCountries {
      isAvailable
      availableCountries {
        code
        name
      }
    }
  }
`;

export const CHANGELLY_CURRENCIES = gql`
  query changellyFiatCurrencies($providerCode: ChangellyFiatProvider!) {
    changellyFiatCurrencies(providerCode: $providerCode) {
      ticker
      name
      type
      extraIdName
      iconUrl
      precision
    }
  }
`;

export const CHANGELLY_OFFERS = gql`
  query changellyFiatOffers(
    $providerCode: ChangellyFiatProvider!
    $currencyFrom: String!
    $currencyTo: ChangellyTokenCode!
    $amountFrom: String!
  ) {
    changellyFiatOffers(
      providerCode: $providerCode
      currencyFrom: $currencyFrom
      currencyTo: $currencyTo
      amountFrom: $amountFrom
    ) {
      rate
      invertedRate
      fee
      amountFrom
      amountExpectedTo
      errorType
      errorMessage
      errorDetails {
        cause
        value
      }
      providerCode
    }
  }
`;

export const CHANGELLY_CREATE_ORDER = gql`
  query changellyCreateOrder(
    $providerCode: ChangellyFiatProvider!
    $currencyFrom: String!
    $currencyTo: ChangellyTokenCode!
    $amountFrom: String!
    $paymentMethod: String!
  ) {
    changellyCreateOrder(
      providerCode: $providerCode
      currencyFrom: $currencyFrom
      currencyTo: $currencyTo
      amountFrom: $amountFrom
      paymentMethod: $paymentMethod
    ) {
      redirectUrl
    }
  }
`;
