export const WHEEL_REWARD_NAME = 'item-:num.webp';
export const WHEEL_BG_NAME = 'wheelBg.webp';
export const WHEEL_MAIN_IMAGE_NAME = 'mainImage.webp';
export const WHEEL_MAIN_IMAGE_MOB_NAME = 'mainImageMob.webp';
export const WHEEL_MAIN_IMAGE_BG_NAME = 'bright.webp';
export const WHEEL_REWARD_IMAGE_BG_NAME = 'winInfo.webp';
export const WHEEL_BG_IMAGE_NAME = 'wheelBgCircle.webp';
export const WHEEL_IMAGE_NAME = 'wheel.webp';
export const WHEEL_POINTER_IMAGE_NAME = 'wheelPointer.webp';
export const WHEEL_BUTTON_IMAGE_NAME = 'spinButton.webp';
export const WHEEL_WIN_IMAGE_NAME = 'winImage.webp';
export const WHEEL_ANIMATION_TIME = 6000; // ms
