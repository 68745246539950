import cashbackIcon from 'assets/img/PageTitles/Cashback_Icon.svg';
import PageTitle from 'components/Base/PageTitle';
import { useLocalization } from 'components/Internationalization';

const Title: React.FC = () => {
  const { translate } = useLocalization();
  return (
    <>
      <PageTitle
        icon={cashbackIcon}
        title={translate('cashback.title')}
        lineColor="#1C69CA"
        titleSize="small"
        largeImage
      />
    </>
  );
};

export default Title;
