import { generate as Id } from 'shortid';
import { Dispatch } from 'react';

import blackJackIcon from 'assets/img/BlackJack/Results/Blackjack.webp';
import winIcon from 'assets/img/BlackJack/Results/Win.webp';
import pushIcon from 'assets/img/BlackJack/Results/push.webp';
import bustIcon from 'assets/img/BlackJack/Results/Bust.webp';
import lossIcon from 'assets/img/BlackJack/Results/Lose.webp';

import chip_01 from 'assets/img/Cards/Chips/0.1.webp';
import chip_05 from 'assets/img/Cards/Chips/0.5.webp';
import chip_10 from 'assets/img/Cards/Chips/10.webp';
import chip_10_vip from 'assets/img/Cards/Chips/10_vip.webp';
import chip_50 from 'assets/img/Cards/Chips/50_vip.webp';
import chip_25 from 'assets/img/Cards/Chips/2.5.webp';
import chip_250 from 'assets/img/Cards/Chips/250_vip.webp';
import chip_1000 from 'assets/img/Cards/Chips/1000_vip.webp';

import { BNMultiply, BNPlus, convertUSDToCrypto, removeComas } from 'func/common';
import { bigOrEqual, bigThen, lessOrEqual, lessThen } from 'func/prepareDataDice';
import {
  BlackJackActionTypes,
  BlackJackCardsType,
  BlackjackChipsRequest,
  BlackJackChipType,
  BlackjackPlayerHandResponseType,
  HandResultType,
  HandType,
  IActionSteps,
  IBlackJackHand,
  IBlackJackResult,
  IResponseClose,
  IResponseSessionActive,
} from 'context/ContextBlackJack/types';
import {
  ANIMATION_DURATION,
  ANIMATION_FLIP,
  EChipAmountHigh,
  EChipAmountLow,
  PLAYER_LEFT,
} from 'components/constants/blackJack';
import { CardGameStake, HistoryElem } from 'types';
import { IBlackJackDetailsHand } from 'components/Base/PopUps/components/BlackJackBetDetails/types';
import { BlackJackTypes } from 'context/ContextBlackJack/blackJackReduser';

interface DrawCardsPayload {
  cards: BlackJackCardsType[];
  gameMode: boolean;
  payout: string;
  betId: string;
  multiplier: number;
  firstOutcome: string;
  secondOutcome: string;
  tokenCode: {
    token: string;
    name: string;
  };
  rate: string;
}

interface IBetBlackJackDetails {
  newActions: string[];
  outcome: string;
  payout: string;
  betId: string;
  multiplier: number;
  playerOutcome: string;
}

export const getChipIcon = (amountChip: number, isHighMode: CardGameStake): string => {
  if (isHighMode === CardGameStake.high) {
    switch (amountChip) {
      case EChipAmountHigh.min:
        return chip_10_vip;
      case EChipAmountHigh.middleMin:
        return chip_50;
      case EChipAmountHigh.middleMax:
        return chip_250;
      case EChipAmountHigh.max:
        return chip_1000;
      default:
        return '';
    }
  }
  switch (amountChip) {
    case EChipAmountLow.min:
      return chip_01;
    case EChipAmountLow.middleMin:
      return chip_05;
    case EChipAmountLow.middleMax:
      return chip_25;
    case EChipAmountLow.max:
      return chip_10;
    default:
      return '';
  }
};

export const getBlackJackResultIcon = (result: HandResultType): string => {
  switch (result) {
    case HandResultType.blackJack:
      return blackJackIcon;
    case HandResultType.win:
      return winIcon;
    case HandResultType.push:
      return pushIcon;
    case HandResultType.bust:
      return bustIcon;
    case HandResultType.loss:
      return lossIcon;
    default:
      return '';
  }
};

export const getCardCount = (card: string): number => {
  if (!card) return 0;
  const [, value] = card.split('_');
  switch (value) {
    case 'A':
      return 1;
    case 'J':
      return 10;
    case 'Q':
      return 10;
    case 'K':
      return 10;
    default:
      return Number(value);
  }
};

export const getTotalCards = (
  cards: BlackJackCardsType[] | Array<{ cardValue: string }>,
): { value: number; flex: boolean } => {
  const hand = { value: 0, flex: false };

  cards.forEach((card) => {
    const num = getCardCount(card.cardValue);
    const nexTotal = hand.value + num;

    if (num === 1) {
      hand.flex = true;
    }
    hand.value = nexTotal;
  });

  if (hand.flex && hand.value > 11) {
    hand.flex = false;
  }

  return {
    ...hand,
    value: hand.value === 11 && hand.flex ? 21 : hand.value,
    flex: hand.value === 11 && hand.flex ? false : hand.flex,
  };
};

export const isDisableChip = (
  minBet: number,
  maxBet: number,
  walletUsd: string,
  betAmount: string,
  newAmount: number,
): boolean => {
  const nextBetAmount = BNPlus(betAmount, newAmount);

  if (lessThen(walletUsd, newAmount)) return true;
  if (lessThen(walletUsd, minBet)) return true;
  if (bigThen(nextBetAmount, maxBet)) return true;
  if (bigThen(nextBetAmount, walletUsd)) return true;
  if (bigOrEqual(betAmount, maxBet)) return true;
  return false;
};

export const getLeftPositionChip = (index: number): number => {
  if (index === 0) return 50;
  return index % 2 === 0 ? 50 - 5 : 50 + 5;
};

export const getCurrentSteps = (actions: BlackJackActionTypes[], steps: IActionSteps[]): IActionSteps[] => {
  if (!actions?.length) return [];
  return steps.filter((s) => actions.includes(s.type));
};

export const getSplit = (hand: IBlackJackHand): IBlackJackHand => {
  const newHand = {
    ...hand,
    isSplit: true,
    secondHand: { ...hand.secondHand, status: HandType.open },
  };
  const newCards = hand.cards.map((h, ind) => {
    if (!ind) {
      return { ...h, leftBase: PLAYER_LEFT - 18 };
    }
    return { ...h, leftBase: PLAYER_LEFT + 18, isFirst: false };
  });
  return { ...newHand, cards: newCards };
};

export const getDealerCards = (
  cards: string[],
  fastMode: boolean,
  action?: BlackJackActionTypes,
): BlackJackCardsType[] => {
  return cards.map((c, index) => ({
    cardValue: c,
    flip: action === BlackJackActionTypes.stand ? true : index !== 1,
    zIndex: action === BlackJackActionTypes.stand ? 1 : index !== 1 ? 1 : 0,
    flipDelay: fastMode ? ANIMATION_FLIP.fast : ANIMATION_FLIP.slow,
    top: 17,
    id: Id(),
    leftBase: 50,
    isFirst: true,
  }));
};

const getPlayerLeft = (
  isSplit: boolean,
  secondHandActive: string,
  firstHandActive: boolean,
  margin: number,
  isTablet: boolean,
): number => {
  if (isSplit) {
    if (secondHandActive && !firstHandActive) {
      return PLAYER_LEFT + 18 + margin * (isTablet ? 3.7 : 3);
    }
    return PLAYER_LEFT - 18 + margin * (isTablet ? 3.7 : 3);
  }
  return PLAYER_LEFT;
};

export const getHandLeftPositionToDraw = (
  isSplit: boolean,
  mobileBig: boolean,
  tablet: boolean,
  desk: boolean,
): number => {
  if (isSplit) return 25.5;
  if (mobileBig) return 42;
  if (tablet) return 43.5;
  if (desk) return 44.5;
  return 43.5;
};

export const getLeftCardNextPosition = (isSplit: boolean, leftBase: number, tablet: boolean, index: number): number => {
  if (isSplit) return leftBase;
  const nextMargin = index * (tablet ? 4.5 : 3);
  return leftBase + nextMargin;
};

export const getPlayerCards = (
  cards: string[],
  hand: IBlackJackHand,
  fastMode: boolean,
  isTablet: boolean,
): IBlackJackHand => {
  if (!cards?.length) return hand;
  const isSplit = hand.isSplit;
  const secondHandActive = hand.secondHand.status;
  const firstHandActive = hand.firstHand.status === HandType.open;
  const nextLeftMargin = hand.cardsCount;

  const nextCards = cards.map((c, index) => ({
    cardValue: c,
    flip: true,
    zIndex: 0,
    flipDelay: fastMode ? ANIMATION_FLIP.fast : ANIMATION_FLIP.slow,
    // leftBase: getPlayerLeft(isSplit, secondHandActive, firstHandActive, nextLeftMargin, isTablet),
    leftBase: getPlayerLeft(isSplit, secondHandActive, firstHandActive, index + nextLeftMargin, isTablet),
    id: Id(),
    top: 58,
    isFirst: hand.firstHand.status === HandType.open,
  }));

  return {
    ...hand,
    cards: [...hand.cards, ...nextCards],
    cardsCount: isSplit ? hand.cardsCount + 1 : hand.cardsCount,
  };
};

export const getTopPlayerCardsPosition = (
  mobile: boolean,
  mobileBig: boolean,
  tablet: boolean,
  base: number,
): number => {
  if (mobile) return 69;
  if (mobileBig) return 68;
  if (tablet) return 60;
  return base;
};

export const getDealerTotalScore = (cards: BlackJackCardsType[]): string => {
  const sore = getTotalCards(cards);

  if (sore.flex) {
    if (sore.value > 6) {
      return `${sore.value + 10}`;
    }
    return `${sore.value}/${sore.value + 10}`;
  }

  return `${sore.value}`;
};

export const getAllHistoryBlackJack = (state: HistoryElem[]): HistoryElem[] =>
  state.map((el) => ({
    num: lessThen(el.payout, '0.00000001') ? '0x' : `${el.multiplier}x`,
    result: Number(el.payout) > 0 ? 'win' : 'lose',
    createdAt: el.createdAt,
    id: el.id,
    amount: el.amount,
    isAuto: el.isAuto,
    multiplier: lessThen(el.payout, '0.00000001') ? '0x' : `${el.multiplier}x`,
    payout: el.payout,
    tokenCode: el.tokenCode,
    displayName: el.displayName,
    isBlack: el.isBlack,
    betId: el.id,
  }));

export const getChipsRequest = (
  chips: BlackJackChipType[],
  betAmount: string,
  minBet: number,
): BlackjackChipsRequest | Record<string, number> => {
  const result = {};
  if (lessOrEqual(betAmount, '0')) {
    result[String(minBet)] = 1;
    return result;
  }
  chips.forEach((c) => {
    result[String(c.amount)] = Number(result[c.amount]) + 1 || 1;
  });
  return result;
};

export const getPlayerHandOnHit = (
  playerHand: IBlackJackHand,
  handResponse: BlackjackPlayerHandResponseType,
): IBlackJackHand => {
  const isSplitHand = handResponse?.leftSplitHand;
  const firstHandStatus = handResponse?.leftSplitHand?.isClosed ? HandType.closed : HandType.open;
  const firstHandResult = handResponse?.leftSplitHand?.outcome
    ? handResponse?.leftSplitHand?.outcome
    : HandResultType.default;
  const secondHandResult = handResponse?.rightSplitHand?.outcome
    ? handResponse?.leftSplitHand?.outcome
    : HandResultType.default;

  if (isSplitHand) {
    return {
      ...playerHand,
      firstHand: {
        ...playerHand.firstHand,
        status: firstHandStatus,
        result: firstHandResult,
      },
      secondHand: {
        ...playerHand.secondHand,
        result: secondHandResult,
      },
    };
  }

  const outcome = handResponse?.outcome;
  return {
    ...playerHand,
    firstHand: {
      ...playerHand.firstHand,
      result: outcome || HandResultType.default,
    },
  };
};

export const getDealerCardsOnHit = (
  dealerCardsResponse: string[],
  dealerHand: BlackJackCardsType[],
  fastMode: boolean,
): BlackJackCardsType[] => {
  if (!dealerCardsResponse) return null;

  let newCards = dealerCardsResponse;
  newCards = newCards.splice(1);

  return newCards.map((c) => ({
    cardValue: c,
    flip: true,
    zIndex: 1,
    flipDelay: fastMode ? ANIMATION_FLIP.fast : ANIMATION_FLIP.slow,
    top: 17,
    id: Id(),
    leftBase: 50,
    isFirst: true,
  }));
};

export const getActionsOnResult = (
  result: IBlackJackResult,
  walletAmount: string,
  betAmount: string,
  maxBet: number,
): BlackJackActionTypes[] => {
  if (!result) return null;

  const isWinHand = result.multiplier > 0;
  const isEnoughOnDouble = bigThen(walletAmount, BNMultiply(betAmount, 2));
  const notMoreThanMaxBet = lessOrEqual(BNMultiply(betAmount, 2), maxBet);
  if (isWinHand) {
    return [BlackJackActionTypes.reBetDefault, BlackJackActionTypes.newBet];
  }
  if (isEnoughOnDouble && notMoreThanMaxBet) {
    return [BlackJackActionTypes.reBetDefault, BlackJackActionTypes.newBet, BlackJackActionTypes.reBet];
  }
  if (isEnoughOnDouble && !notMoreThanMaxBet) {
    return [BlackJackActionTypes.reBetDefault, BlackJackActionTypes.newBet];
  }
  return [BlackJackActionTypes.reBetDefault, BlackJackActionTypes.newBet];
};

export const getHistoryBlackJackElem = (
  betId: string,
  multiplier: number,
  payout: string,
  betAmountValue: string,
  displayName: string,
  rate: string,
  list: HistoryElem[],
): HistoryElem[] => {
  if (!payout) return list;
  const date = new Date();
  const newElem = {
    createdAt: date,
    id: betId,
    betId,
    amount: convertUSDToCrypto(rate, betAmountValue),
    multiplier: `${multiplier}x`,
    payout,
    displayName,
    result: bigThen(payout, '0') ? 'win' : 'lose',
    num: lessThen(payout, '0.00000001') ? '0x' : `${multiplier}x`,
  };
  const neList = list.filter((_, index) => index < 20);
  list.slice(0, 20);
  return [newElem, ...neList];
};

export const getSessionChips = (resChips: BlackjackChipsRequest, isHighMode: CardGameStake): BlackJackChipType[] => {
  const chipsHistory = [];
  Object.entries(resChips).forEach(([key, value]) => {
    const chips = Array(value)
      .fill(null)
      .map(() => ({ amount: Number(key), icon: getChipIcon(Number(key), isHighMode), id: Id() }));
    chipsHistory.push(chips);
  });
  return chipsHistory.flat();
};

export const getHotKeysDescription = (chips: BlackJackChipType[]): Array<{ key: string; description: string }> => {
  return chips.map((c, index) => ({
    key: `${index + 1}`,
    description: `Bet with $${c.amount}/ ${c.amount} chip`,
  }));
};

export const getPlayerHandBetDetails = (
  hand: BlackjackPlayerHandResponseType,
  dealerCards: string[],
): IBlackJackDetailsHand => {
  const cardsDealerAmount = dealerCards.map((c) => ({ cardValue: c }));
  const dealerHand = {
    cards: dealerCards,
    cardsTotal: getTotalCards(cardsDealerAmount),
  };
  const { leftSplitHand, rightSplitHand } = hand;

  if (leftSplitHand) {
    const firstCardsAmount = leftSplitHand.cards.map((c) => ({ cardValue: c }));
    const secondCardsAmount = rightSplitHand.cards.map((c) => ({ cardValue: c }));
    return {
      dealerHand: {
        ...dealerHand,
      },
      firstHand: {
        outcome: leftSplitHand.outcome,
        cards: leftSplitHand.cards,
        cardsTotal: getTotalCards(firstCardsAmount),
      },
      secondHand: {
        outcome: rightSplitHand.outcome,
        cards: rightSplitHand.cards,
        cardsTotal: getTotalCards(secondCardsAmount),
      },
    };
  }

  const cardsAmounts = hand.cards.map((c) => ({ cardValue: c }));
  return {
    firstHand: {
      cards: hand.cards,
      outcome: hand.outcome,
      cardsTotal: getTotalCards(cardsAmounts),
    },
    secondHand: {
      outcome: '',
      cards: [],
      cardsTotal: null,
    },
    dealerHand: {
      ...dealerHand,
    },
  };
};

export const drawDealersCards = (payload: DrawCardsPayload, dispatch: Dispatch<any>) => {
  const { cards, gameMode, payout, betId, multiplier, firstOutcome, secondOutcome, tokenCode, rate } = payload;
  const delay = gameMode ? ANIMATION_DURATION.fast : ANIMATION_DURATION.slow;
  cards.forEach((el, ind) => {
    if (ind === cards.length - 1) {
      const result = { payout, betId, multiplier, tokenCode: tokenCode.token, displayName: tokenCode.name, rate };
      setTimeout(() => {
        dispatch({
          type: BlackJackTypes.FLIP_DEALER_CARD,
          payload: { hand: el, flip: ind, result, firstOutcome, secondOutcome },
        });
      }, ind * delay);
      return;
    }
    setTimeout(() => {
      dispatch({ type: BlackJackTypes.FLIP_DEALER_CARD, payload: { hand: el, flip: ind } });
    }, ind * delay);
  });
};

export const getBetResponseDetails = (bet: IResponseSessionActive & IResponseClose): IBetBlackJackDetails => {
  return {
    newActions: bet.playerHands?.availableActions,
    outcome: bet.playerHands?.outcome,
    payout: bet?.payout,
    betId: bet?.betId,
    multiplier: bet?.multiplier,
    playerOutcome: bet?.playerHands?.outcome,
  };
};

export const getPayloadForDealer = (
  response: IResponseSessionActive & IResponseClose,
  cards: BlackJackCardsType[],
  gameMode: boolean,
  tokenCode: { name: string; token: string },
  rate: string,
): DrawCardsPayload => {
  return {
    cards,
    tokenCode,
    gameMode,
    payout: response?.payout,
    betId: response?.betId,
    multiplier: response?.multiplier,
    firstOutcome: response?.playerHands?.leftSplitHand
      ? response.playerHands.leftSplitHand.outcome
      : response.playerHands.outcome,
    secondOutcome: response?.playerHands?.rightSplitHand?.outcome,
    rate,
  };
};

export const getHandCardScore = (isClose: boolean, totalScore: { value: number; flex: boolean }): string => {
  const { flex, value } = totalScore;
  if (isClose && flex) return `${value + 10}`;
  if (isClose && !flex) return `${value}`;
  if (flex) return `${value}/${value + 10}`;
  return `${value}`;
};

export const getHandChipsHistory = (isDouble: boolean, chipsHistory: BlackJackChipType[]): BlackJackChipType[] => {
  if (isDouble) return [...chipsHistory, ...chipsHistory.map((c, ind) => ({ ...c, id: `${c.id}${ind}` }))];
  return chipsHistory;
};

export const getPlayerPayoutForHand = (betAmount: string, outcome: string): string | null => {
  if (!betAmount || !outcome) {
    return null;
  }
  const currentBetAmount = removeComas(betAmount);
  if (outcome === HandResultType.loss || outcome === HandResultType.bust) {
    return null;
  }

  if (outcome === HandResultType.blackJack) {
    return String(BNPlus(currentBetAmount, BNMultiply(currentBetAmount, 1.5)));
  }
  if (outcome === HandResultType.push) {
    return currentBetAmount;
  }
  if (outcome === HandResultType.win) {
    return String(BNMultiply(currentBetAmount, 2));
  }

  return null;
};

export const getBetAmountMultiplier = (isFirstDouble: boolean, isSecondDouble: boolean, isSplit: boolean): number => {
  let multiplier = 1;

  if (isFirstDouble) {
    multiplier += 1;
  }

  if (isSecondDouble) {
    multiplier += 1;
  }

  if (isSplit) {
    multiplier += 1;
  }

  return multiplier;
};
