import { useEffect, useState } from 'react';

import { useLocalization } from 'components/Internationalization';
import { getContactsInfo } from 'components/constants/contacts';

import styles from './styles.module.scss';
import { IContactInfo } from './types';

const Contacts: React.FC = () => {
  const { translate, language } = useLocalization();

  const [contacts, setContacts] = useState<IContactInfo[]>([]);

  useEffect(() => {
    const newContacts = getContactsInfo(translate);

    setContacts(newContacts);
  }, [language]);

  return (
    <div className={styles.contactsWrap}>
      <div className={styles.contactContentTitleWrap}>
        <div className={styles.contactContentTitle}>{translate('popups.contacts.title')}</div>
      </div>
      <div className={styles.contactContent}>
        {contacts.map((elem) => (
          <div className={styles.contactContentItem} key={elem.email}>
            <img src={elem.icon} alt={`${elem.title} icon`} />
            <p>{elem.title}</p>
            <span>{elem.email}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Contacts;
