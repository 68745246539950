import { pathOr } from 'ramda';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { popUps } from 'components/constants/constants';
import { ContextPopUps } from 'context';
import { GET_FOOTER_DATA } from 'graphQl/query/footer';
import { getCategoryFooterList, getFooterLinksList } from 'func/prepareDataFooter';
import { useLocalization } from 'components/Internationalization';

import InfoBlockItem from './InfoBlockItem';

import styles from './styles.module.scss';
import { IFooterItem } from './types';

const InfoBlock: React.FC = () => {
  const { language, translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [categories, setCategories] = useState([]);
  const [links, setLinks] = useState([]);

  const { data } = useQuery(GET_FOOTER_DATA, {
    fetchPolicy: 'cache-only',
  });

  const handlePopUps = (link: string) => {
    setPopUpsOpen(popUps[link]);
  };

  useEffect(() => {
    if (data) {
      const footerItems = pathOr<IFooterItem[]>([], ['footer'], data);

      const newCategoires = getCategoryFooterList(footerItems, translate);
      const newLinks = getFooterLinksList(footerItems, translate, handlePopUps);

      setCategories(newCategoires);
      setLinks(newLinks);
    }
  }, [data, language]);

  if (!categories.length || !links.length) return null;

  return (
    <div className={styles.footerInfo}>
      {categories.map((category) => (
        <InfoBlockItem key={category} title={category} array={links.filter((el) => el.category === category)} />
      ))}
    </div>
  );
};

export default InfoBlock;
