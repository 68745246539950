import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

const PickerStyle = styled.div`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 11px;
  z-index: 3;

  .example-custom-input {
    width: 288px;
    height: 38px;
    border-radius: 20px;
    border: none;
    line-height: 20px;
    box-sizing: border-box;
    background-color: #00000033;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    cursor: url(${point}), auto !important;
    text-align: left;
    padding-left: 20px;
    position: relative;
    img {
      position: absolute;
      right: 20px;
      top: 12px;
      width: 15px;
      height: 15px;
    }
  }
  .react-datepicker {
    font-family: 'Montserrat', 'Helvetica Neue', helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #1b305a;
    color: #ffffff;
    border-radius: 16px;
    display: inline-block;
    position: relative;
    z-index: 3;
  }
  .react-datepicker__header {
    text-align: center;
    background-color: #1b305a;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 8px 0;
    position: relative;
  }
  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    cursor: url(${point}), auto !important;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #ffffff;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 0.5rem;
    background-color: black;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-top: 0;
    color: #aeaeae;
    font-weight: bold;
    font-size: 0.944rem;
  }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: #485c83;
  }
  [type='button']:not(:disabled),
  [type='reset']:not(:disabled),
  [type='submit']:not(:disabled),
  button:not(:disabled) {
    cursor: url(${point}), auto !important;
  }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    opacity: 0.2;
  }

  .react-datepicker__navigation-icon {
    top: 5px;
  }
`;

export default PickerStyle;
