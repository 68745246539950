import { useContext } from 'react';

import { ContextPopUps } from 'context';
import { Links } from 'components/constants';
import { useNavigate } from 'hooks/useNavigate';
import { DataLayerEvent } from 'types/dataLayerTypes';
import { useAnalyticsDispatch } from 'hooks/useAnalyticsDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile } from 'store/user/user.selectors';

import BonusItem from './bonusItem';
import BonusSlider from './bonusSlider';

import styles from './styles.module.scss';
import { IBonusLogin } from './types';

const BonusLogin: React.FC<IBonusLogin> = ({ data }) => {
  const navigate = useNavigate();
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const user = useAppSelector(userProfile);
  const dataLayerDispatch = useAnalyticsDispatch();

  const handleBonus = () => {
    navigate(Links.bonus);
    setPopUpsOpen(null);
    dataLayerDispatch({ event: DataLayerEvent.bonusSelected, user_id: user?.id });
  };

  return (
    <div className={styles.bonusLoginWrap}>
      {data.data.length > 1 ? (
        <BonusSlider data={data.data} handleBonus={handleBonus} />
      ) : (
        <BonusItem
          name={data.data[0].bonus.name}
          type={data.data[0].bonus.type}
          startAt={data.data[0].startAt}
          endAt={data.data[0].endAt}
          handleBonus={handleBonus}
        />
      )}
    </div>
  );
};

export default BonusLogin;
