import MyCashbackPercent from 'components/Pages/CashbackPage/components/CardBlock/components';
import BalanceCardCashback from 'components/Pages/CashbackPage/components/BalanceCardCashback';
import styles from './styles.module.scss';

const CardBlock: React.FC = () => (
  <div className={styles.cashbackCardBlockFirstWrap}>
    <MyCashbackPercent />
    <BalanceCardCashback />
  </div>
);

export default CardBlock;
