import { useEffect, useState } from 'react';
import { pathOr } from 'ramda';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { sha3_256 } from 'js-sha3';

import config from 'config';
import { GET_BET } from 'graphQl/query/bet/bet';
import { GET_USER } from 'graphQl/query/auth/profile';
import { amount, getImgSrc } from 'func/common';
import { getDefaultSquaresData, getSquareImage, setPopUpMinesAuto, setPopUpMinesSquares } from 'func/prepareDataMines';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import Loader from 'components/Base/Loader';
import MinesPopUpStyle from 'components/Base/PopUps/components/MinesBetDetails/styles';
import AutoCheckbox from 'components/Games/base/AutoCheckbox';
import CopyButton from 'components/Base/CopyButton';
import Button from 'components/Base/Button';

import { IBetResponse } from '../types';

interface Props {
  data?: any;
}

const { sha256ValidatorUrl } = config;

const MinesBetDetails: React.FC<Props> = ({ data }) => {
  const { translate } = useLocalization();

  const [bet, setBet] = useState<IBetResponse>();
  const [squareData, setSquareData] = useState(getDefaultSquaresData());

  const { data: betData, loading } = useQuery(GET_BET, {
    variables: { betId: data.id },
  });

  const { data: user } = useQuery(GET_USER, {
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    const newBet = pathOr(undefined, ['bet'], betData);

    if (newBet) {
      const { isAuto, betOn, result } = newBet;

      setBet(newBet);
      setSquareData((prev) =>
        !isAuto
          ? setPopUpMinesSquares(prev, betOn.prediction, result)
          : setPopUpMinesAuto(prev, betOn.prediction, result.sequence),
      );
    }
  }, [betData]);

  const getDate = (string: string): string => dayjs(string).format('MM/DD/YYYY  hh:mm:ss A');

  const handleVerify = () => {
    window.open(sha256ValidatorUrl, '_blank');
  };

  return loading ? (
    <Loader pages />
  ) : (
    <MinesPopUpStyle payout={data?.payout}>
      <div className="title">{translate('popups.bets.details.title')}</div>
      <div className="subtitle">
        <div className="subtitle-item1">
          <p>{translate('popups.bets.details.player')}:</p>
          <p>{translate('popups.bets.details.date')}:</p>
          <p>{translate('popups.bets.details.id')}:</p>
        </div>
        <div className="subtitle-item2">
          <h3>{user.profile.alias}</h3>
          <h3>{getDate(bet?.createdAt)}</h3>
          <h3>{bet?.id}</h3>
        </div>
      </div>
      <div className="amount-block">
        <div className="amount">
          <p className="amount-title">{translate('popups.bets.details.amount')}</p>
          <div className="amount-subtitle">
            <img alt="icon" src={getImgSrc(bet?.token.displayName)} className="token-icon" />
            <span className="amount-result">{amount(bet?.amount)}</span>
          </div>
        </div>
        <div className="payout">
          <p className="amount-title">{translate('popups.bets.details.payout')}</p>
          <div className="amount-subtitle">
            <img src={getImgSrc(bet?.token.displayName)} alt="icon" className="token-icon" />
            <span className="amount-result">{amount(bet?.payout)}</span>
          </div>
        </div>
      </div>
      <div className="screen-mines">
        <div className="screen-item-square">
          {squareData.map((el) => (
            <div className="screen-square" key={el.id}>
              <div className={el.condition}>
                <img src={getSquareImage(el.condition)} alt="icon" />
              </div>
            </div>
          ))}
        </div>
        <div className="screen__mines-detail-wrap">
          <AutoCheckbox value={bet?.isAuto} setValue={() => null} disable />
          <div className="screen-item-drop">
            {translate('popups.bets.mines.mines')} {bet?.result.sequence.length}
            <i />
          </div>
        </div>
        <div className="subtitle seeds">
          <div className="subtitle-item1">
            <p>{translate('popups.bets.details.player-seed')}:</p>
          </div>
          <div className="subtitle-item2">
            <div className="seeds-text">
              <p>{bet?.serverSeed}</p>
              <CopyButton copyContent={bet?.serverSeed} />
            </div>
          </div>
        </div>
        <div className="subtitle seeds">
          <div className="subtitle-item1">
            <p>{translate('popups.bets.details.hash')}:</p>
          </div>
          <div className="subtitle-item2">
            <div className="seeds-text">
              <p>{sha3_256(bet?.serverSeed || '')}</p>
              <CopyButton copyContent={sha3_256(bet?.serverSeed || '')} />
            </div>
          </div>
        </div>
        <div className="verify-button-wrapper">
          <Button
            className="verify-button"
            title={translate('popups.bets.details.verify')}
            variant={ButtonType.primary}
            onclick={handleVerify}
            width="100px"
          />
        </div>
      </div>
    </MinesPopUpStyle>
  );
};

export default MinesBetDetails;
