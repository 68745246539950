import { useContext } from 'react';

import verifyIcon from 'assets/img/Dice/shield-check.webp';
import rocketStart from 'assets/img/Dice/rocket-launch.webp';
import statisticsIcon from 'assets/img/Dice/statistics.webp';
import rulesLimits from 'assets/img/Dice/balance.webp';
import keyBoard from 'assets/img/Dice/keyboard-outline.webp';
import rulesIcon from 'assets/img/Dice/games-rules-icon.webp';

import DiceGameContext from 'context/contextDiceGame/context';
import LiveStatsContext from 'context/contextLiveStats/context';
import { ContextPopUps } from 'context';
import { popUps } from 'components/constants/constants';
import { EGamesButtonsBar, EGamesNames } from 'components/constants/games';
import { useLocalization } from 'components/Internationalization';

import GameOptionsButtonsNew from 'components/Games/base/GameOptionsButtonsNew';
import { useMediaQuery } from 'react-responsive';

const DiceGameNavigation: React.FC = () => {
  const { translate } = useLocalization();

  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { enabled, onToggleStatistics } = useContext(LiveStatsContext);
  const { animation, diceId, withHotKeys, onChangeAnimation, onChangeWithHotKeys } = useContext(DiceGameContext);

  const isMobile = useMediaQuery({ query: '(max-width: 1260px)' });

  const handleToggleStats = () => {
    onToggleStatistics(!enabled, isMobile ? false : undefined);
  };

  const handleToggleAnimation = () => {
    onChangeAnimation(!animation);
  };

  const handleVerify = () => {
    setPopUpsOpen(popUps.diceVerify);
  };

  const handleLimits = () => {
    setPopUpsOpen({
      modalOpen: popUps.gamesLimits,
      data: { gameId: diceId },
    });
  };

  const handleRules = () => {
    setPopUpsOpen({
      modalOpen: popUps.gamesRules,
      data: { gameName: EGamesNames.dice },
    });
  };

  const handleHotKeys = () => {
    setPopUpsOpen({
      modalOpen: popUps.hotKeys,
      data: { onSwitch: onChangeWithHotKeys, isHotKeys: withHotKeys },
    });
  };

  const dataButtons = [
    {
      toolTipText: translate('inHouse.tooltip.live'),
      image: statisticsIcon,
      activeImg: enabled,
      func: handleToggleStats,
      id: EGamesButtonsBar.statistic,
    },
    {
      toolTipText: translate('inHouse.tooltip.speed'),
      image: rocketStart,
      activeImg: !animation,
      func: handleToggleAnimation,
      id: EGamesButtonsBar.speed,
    },
    {
      toolTipText: translate('inHouse.tooltip.verify'),
      image: verifyIcon,
      activeImg: false,
      func: handleVerify,
      id: EGamesButtonsBar.verify,
    },
    {
      toolTipText: translate('inHouse.tooltip.limits'),
      image: rulesLimits,
      activeImg: false,
      func: handleLimits,
      id: EGamesButtonsBar.limits,
    },
    {
      toolTipText: translate('inHouse.tooltip.rules'),
      image: rulesIcon,
      activeImg: false,
      func: handleRules,
      id: EGamesButtonsBar.rules,
    },
    {
      toolTipText: translate('inHouse.tooltip.keys'),
      image: keyBoard,
      activeImg: withHotKeys,
      func: handleHotKeys,
      id: EGamesButtonsBar.keys,
    },
  ];

  return <GameOptionsButtonsNew dataButtons={dataButtons} />;
};

export default DiceGameNavigation;
