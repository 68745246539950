import React from 'react';
import RangeStyle from './styles';

interface Props {
  inputValue: string | number;
  handleSetValue?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  rankShow?: boolean;
  percentShow?: boolean;
  inputWidth?: string;
  inputHeight?: string;
  height?: string;
  rankId?: string;
  // right?: string;
  // left?: string;
  // top?: string;
}

const InputRangeCustom: React.FC<Props> = ({
  inputValue,
  handleSetValue,
  inputHeight,
  height,
  inputWidth,
  percentShow,
  rankShow,
  rankId,
}) => (
  <RangeStyle inputwdth={inputWidth} height={height} inputheight={inputHeight}>
    {rankShow && (
      <label className="range__label1">
        <span>{'Rank ' + rankId}</span>
        {/* <code></code> */}
      </label>
    )}
    {percentShow && (
      <label className="range__label2">
        <span>{inputValue}</span>
        <code>/100%</code>
      </label>
    )}
    <input type="range" min="0" max="100" value={inputValue} onChange={handleSetValue} />
  </RangeStyle>
);

export default InputRangeCustom;
