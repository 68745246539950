import { useContext } from 'react';

import warningIcon from 'assets/img/BonusPage/bonus-warning-icon.webp';

import { ContextPopUps } from 'context';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import Button from 'components/Base/Button';

import { IBonusCancel } from './types';
import styles from './styles.module.scss';

const BonusCancel: React.FC<IBonusCancel> = ({ data }) => {
  const { translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const handleLeave = () => setPopUpsOpen(null);

  return (
    <div className={styles.bonusCancelWrap}>
      <img src={warningIcon} alt="gift" className={styles.bonusCancelImg} />
      <h2 className={styles.bonusCancelTitle}>{translate('popups.bonus.cancel.title')}</h2>
      <h3 className={styles.bonusCancelDescription}>{translate('popups.bonus.cancel.desc')}</h3>
      <h3 className={styles.bonusCancelDescription}>{translate('popups.bonus.cancel.subdesc')}</h3>
      <div className={styles.bonusCancelBtn}>
        <div className={styles.bonusCancelBtnWrap}>
          <Button
            className={styles.button}
            variant={ButtonType.primary}
            title={translate('general.buttons.cancel')}
            width="100%"
            onclick={data.handleCancel}
            color="transparent"
          />
        </div>
        <div className={styles.bonusCancelBtnWrap}>
          <Button
            className={styles.button}
            variant={ButtonType.secondary}
            title={translate('general.buttons.close')}
            width="100%"
            onclick={handleLeave}
            color="#202D4A"
          />
        </div>
      </div>
    </div>
  );
};

export default BonusCancel;
