import { useLocalization } from 'components/Internationalization';

import DropDown from 'components/Base/DropDown';
import { amount } from 'func/common';
import InputWithMaxButton from '../InputWithMaxButton';

import styles from './styles.module.scss';
import { IVaultContentItem } from './types';

const VaultContentItem: React.FC<IVaultContentItem> = ({
  dropData,
  inpValue,
  onChangeInput,
  maxAmount,
  onMaxButton,
  onBlurInput,
}) => {
  const { translate } = useLocalization();

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{translate('popups.wallet.vault.currency')}:</p>
      <DropDown data={dropData} width="100%" textPadding="50px" hideFirstElem="36%" />
      <p className={styles.title}>{translate('popups.wallet.vault.amount')}:</p>
      <InputWithMaxButton
        value={inpValue}
        onChangeInput={onChangeInput}
        onMaxButton={onMaxButton}
        onBlurInput={onBlurInput}
      />
      <span className={styles.amount} key={maxAmount}>
        {translate('popups.wallet.vault.balance')}: {amount(maxAmount)}
      </span>
    </div>
  );
};

export default VaultContentItem;
