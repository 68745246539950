import Big from 'big.js';
import { useContext, useEffect, useState } from 'react';

import bonusIcon from 'assets/img/ranks/bonus-rank.webp';
import cashbackIcon from 'assets/img/ranks/cash-back.svg';
import bankIcon from 'assets/img/ranks/piggy-bank.webp';

import { ContextPopUps } from 'context';
import { useAppSelector } from 'hooks/useAppSelector';
import { RankItem } from 'types/requestTypes';
import { getRankImg, getVipRankNum } from 'func/prepareDataCashback';
import { getAmountWithoutZeroEnd } from 'func/common';
import { ranksRowList } from 'components/constants/pages';
import { generalDataRanksList } from 'store/generalData/generalDtata.selectors';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import ButtonSubmit from 'components/Base/PopUps/components/ButtonSubmit';
import RankDetailsRow from 'components/Pages/CashbackPage/components/CardBlockSecond/components/RankSystem/components/RankDetailsRow';

import styles from './styles.module.scss';
import { ILevelUp } from './types';

const LevelUp: React.FC<ILevelUp> = ({ data }) => {
  const { translate } = useLocalization();
  const ranksList = useAppSelector(generalDataRanksList);
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [newRank, setNewRank] = useState<RankItem>(null);
  const [rakeback, setRakeback] = useState(false);

  const handleClose = () => setPopUpsOpen(null);

  useEffect(() => {
    if (data && ranksList) {
      const rank = ranksList.find((el) => el.id === data.data.id);

      setNewRank(rank);

      if (rank) {
        const hasRakeback = Big(rank.rakeback).gt(0);

        setRakeback(hasRakeback);
      }
    }
  }, [data, ranksList]);

  return (
    <div className={styles.levelWrap}>
      <div className={styles.levelUpTitle}>{translate('popups.rank.title')}</div>
      <p className={styles.levelSubtitle}>
        {translate('popups.rank.desc')} {newRank?.id}
      </p>
      <p className={styles.levelSubtitle}>{translate('popups.rank.reward')}</p>
      <div className={styles.levelContent}>
        <div className={styles.levelImageWrap}>
          <img src={getRankImg(newRank?.id)} alt="icon" className={styles.levelImage} />
          <div className={styles.levelImageRankWrap}>
            <h2>
              {translate('popups.rank.tooltip')} {newRank?.id}
            </h2>
            {getVipRankNum(ranksList) <= newRank?.id ? <h3>VIP</h3> : null}
          </div>
        </div>
        <div className={styles.levelDescriptionWrap}>
          <h2>{translate('popups.rank.benefits')}:</h2>
          <div className={styles.levelDescription1}>
            <img src={cashbackIcon} alt="icon" />
            <h4>{translate('popups.rank.cashback')}</h4>
            <h5>{`${newRank?.cashback} %`}</h5>
          </div>
          {rakeback ? (
            <div className={styles.levelDescription1}>
              <img src={bankIcon} alt="icon" />
              <h4>{translate('popups.rank.rakeback')}</h4>
              <h5>{`${newRank?.rakeback} %`}</h5>
            </div>
          ) : null}
          <div className={styles.levelDescription1}>
            <img src={bonusIcon} alt="icon" />
            <h4>{translate('popups.rank.bonus')}</h4>
            <h5>{`${getAmountWithoutZeroEnd(newRank?.bonus)} ${newRank?.displayName || 'BTC'}`}</h5>
          </div>
          <div className={styles.levelDescription2}>
            {ranksRowList.map((el, index) => (
              <RankDetailsRow
                key={el.title}
                isReached={newRank ? newRank[el.id] : false}
                title={el.title}
                image={el.image}
                index={index}
                popUp
              />
            ))}
          </div>
        </div>
      </div>
      <ButtonSubmit
        className={styles.button}
        handleButton={handleClose}
        variant={ButtonType.primary}
        width="288px"
        title={translate('general.buttons.continue')}
        color="#242c45"
      />
    </div>
  );
};

export default LevelUp;
