import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { useAppSelector } from 'hooks/useAppSelector';
import { userToken } from 'store/user/user.selectors';
import { ContextPopUps } from 'context';
import { GET_DIVIDENDS_BALANCE_DATA } from 'graphQl/query/dividends/dividend';
import { popUps, SettingName } from 'components/constants/constants';
import { DIVIDENDS_TO_WALLET } from 'graphQl/mutations/dividends/dividendsToWallet';
import { GET_DIVIDENDS_COLLECTIONS } from 'graphQl/query/dividends/dividendsCillections';
import { GET_COMMON_SETTINGS } from 'graphQl/query/settings/bonusSettings';
import { prepareDividend, prepareDividendTotalCollected } from 'func/prepareDataDividends';
import { getCommonSettingsValue } from 'func/common';
import { handleDisabledCollectButton, prepareAffiliateBalance } from 'func/prepareDataAffiliates';
import referralIcon from 'assets/img/HomePage/referral.webp';
import { useLocalization } from 'components/Internationalization';

import BalanceCardSlide from 'components/Base/Cards/BalsnceCardSlide';

import { IDividendBalance } from './types';

const DividendBalance: React.FC<IDividendBalance> = ({ marginBottom }) => {
  const token = useAppSelector(userToken);
  const { language, translate } = useLocalization();

  const { setPopUpsOpen } = useContext(ContextPopUps);
  const [titleState, setTitleState] = useState(false);
  const [balanceData, setBalanceData] = useState([]);
  const [collectedData, setCollectedData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [collectToken, setCollectToken] = useState(null);
  const [convertedBalance, setConvertedBalance] = useState([]);
  const [fee, setFee] = useState('0');
  const [description, setDescription] = useState('');

  const { data: dataSettings } = useQuery(GET_COMMON_SETTINGS, {
    fetchPolicy: 'cache-only',
  });

  const { data: dividendsBalanceData } = useQuery(GET_DIVIDENDS_BALANCE_DATA, {
    fetchPolicy: 'cache-and-network',
    skip: !token,
  });

  const [collectDividend] = useMutation(DIVIDENDS_TO_WALLET, {
    refetchQueries: [GET_DIVIDENDS_BALANCE_DATA, GET_DIVIDENDS_COLLECTIONS],
  });

  useEffect(() => {
    if (dataSettings) {
      setFee(getCommonSettingsValue(dataSettings.getSettings, SettingName.conversionFee));
    }
  }, [dataSettings]);

  useEffect(() => {
    if (dividendsBalanceData) {
      const { dividend, totalDividendCollection, convertedDividendAvailableCollectionBalance } = dividendsBalanceData;

      setBalanceData(prepareDividend(dividend));
      setCollectedData(prepareDividendTotalCollected(totalDividendCollection));
      setConvertedBalance(prepareAffiliateBalance(convertedDividendAvailableCollectionBalance));
    }
  }, [dividendsBalanceData]);

  useEffect(() => {
    if (!collectToken && convertedBalance) {
      handleDisabledCollectButton(convertedBalance, setDisabled);
    }
    if (collectToken && convertedBalance) {
      handleDisabledCollectButton(convertedBalance, setDisabled, collectToken);
    }
  }, [convertedBalance, collectToken]);

  useEffect(() => {
    const subtitle = translate('dividends.balance.description');
    const newSubtitle = subtitle.replace(':fee', `${fee}%`);

    setDescription(newSubtitle);
  }, [fee, language]);

  const handleCollect = async () => {
    setDisabled(true);
    try {
      await collectDividend({
        variables: {
          input: {
            tokenCode: collectToken,
          },
        },
      });
      const popUpData = convertedBalance.find((el) => el.src === collectToken);
      setPopUpsOpen({
        modalOpen: popUps.collect,
        data: collectToken ? [popUpData] : balanceData,
      });
      setCollectToken(null);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('[COLLECT_DIVIDEND_ERROR]: ', error);
    }
  };

  return (
    <BalanceCardSlide
      height="100%"
      subtitle={description}
      heightMob="100%"
      title={translate('dividends.balance.title')}
      titleState={titleState}
      setTitleState={setTitleState}
      disabled={disabled}
      image={referralIcon}
      balanceData={balanceData}
      collectedData={collectedData}
      convertedBalance={convertedBalance}
      handleCollect={handleCollect}
      setCollectToken={setCollectToken}
      blurIfNotAuth
      zIndex="2"
      marginBottom={marginBottom}
    />
  );
};

export default DividendBalance;
