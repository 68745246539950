import React, { useEffect, useState } from 'react';
import sanitize from 'sanitize-html';

import { useLocalization } from 'components/Internationalization';

import SeoWrap from 'components/common/SeoWrap';

const SeoText: React.FC = () => {
  const { translateCustomNamespace, language } = useLocalization();

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  const handleTranslate = async () => {
    const newTitle = await translateCustomNamespace('home.title', 'seo-home');
    const newBody = await translateCustomNamespace('home.body', 'seo-home');

    setTitle(newTitle);
    setBody(newBody);
  };

  useEffect(() => {
    handleTranslate();
  }, [language]);
  return (
    <SeoWrap defaultHeight="200px" title={sanitize(title)}>
      <div dangerouslySetInnerHTML={{ __html: sanitize(body) }} />
    </SeoWrap>
  );
};

export default SeoText;
