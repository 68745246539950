import styled from 'styled-components';
// import point from 'assets/img/common/cursor-pointer.webp';

interface Props {
  MIN: number;
}

const DiceRangePopUpStyle = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -15px;

  .dice__range-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .dice__range-line-wrap {
    height: 30px;
    border-radius: 20px;
    width: 100%;
    display: flex;
    cursor: auto !important;
  }
  .dice__range-line {
    height: 7px;
    border-radius: 20px;
    width: 100%;
    display: flex;
    cursor: auto !important;
  }
  .dice__range-thumb {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    cursor: auto !important;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    border: 2px solid #344876 !important;
    box-shadow: none !important;
    outline: none !important;
    &:focus{
      border: 2px solid #344876 !important;
      box-shadow: none !important;
      outline: none !important;
    }
    &:active{
      border: 2px solid #344876 !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }

  @media (max-width: 1300px) {
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 580px) {
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 400px) {
    .dice__range-line-wrap {
  }
`;

export default DiceRangePopUpStyle;
