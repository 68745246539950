import { PostOrPage, Tag } from '@tryghost/content-api';

export const getArticlesTitle = (count?: number): string => {
  return count === 1 ? 'Article' : 'Articles';
};

interface PopularArticles {
  popular: PostOrPage[];
  other: PostOrPage[];
}

const getPopularArticles = (posts: PostOrPage[]) => {
  return posts.reduce<PopularArticles>(
    (acc, article) => {
      const tags = article.tags || [];
      const isPopular = tags.find((tag) => tag.name?.toLowerCase() === 'popular');

      if (isPopular) {
        acc.popular.push(article);
        return acc;
      }

      acc.other.push(article);
      return acc;
    },
    { popular: [], other: [] },
  );
};

export const sortArticlesByPopular = (posts: PostOrPage[]): PostOrPage[] => {
  const articles = getPopularArticles(posts);
  const { popular, other } = articles;

  popular.sort((a, b) => Date.parse(b.published_at) - Date.parse(a.published_at));
  other.sort((a, b) => Date.parse(b.published_at) - Date.parse(a.published_at));

  return popular.concat(other);
};

export const searchArticles = (posts: PostOrPage[], search: string): PostOrPage[] => {
  return posts.filter((p) => {
    const searchValue = search.toLowerCase();
    const title = (p.title || '').toLowerCase();

    return title.includes(searchValue);
  });
};

export const filterArticleTags = (tags: Tag[]): Tag[] => {
  return tags.filter((t) => t.name?.toLowerCase() !== 'popular');
};
