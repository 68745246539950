import { ETokenCode } from '../components/constants/constants';

export const isDisplayBonus = (endAt: string): boolean => {
  const date = Date.now();
  const dateEnd = Date.parse(endAt);
  return date < dateEnd;
};

export const getDisplayName = (token: string): string => {
  switch (token) {
    case ETokenCode.BSC:
      return ETokenCode.BNB;
    default:
      return token;
  }
};

export const getTokenCode = (displayName: string): string => {
  switch (displayName) {
    case ETokenCode.BNB:
      return ETokenCode.BSC;
    default:
      return displayName;
  }
};
