import clsx from 'clsx';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { IActivity } from './types';

const Activity: React.FC<IActivity> = ({ title, color, icon, customStyle, count, onClick }) => {
  const [firstWord, setFirstWord] = useState('');
  const [secondWord, setSecondWord] = useState('');

  useEffect(() => {
    if (title) {
      const [newFirstWord, newSecondWord] = title.split(' ');

      setFirstWord(newFirstWord);
      setSecondWord(newSecondWord);
    }
  }, [title]);

  return (
    <div className={styles.wrapper} onClick={onClick}>
      <div>
        <StyledTitle className={styles.title} color={color}>
          {firstWord}
        </StyledTitle>
        <p className={styles.title}>{secondWord}</p>
      </div>
      <StyledIconWrapper className={styles.iconWrapper} color={color}>
        <img className={clsx(styles.icon, customStyle ? styles.customWheel : '')} src={icon} alt={title} />
      </StyledIconWrapper>
      {count ? <span className={styles.count}>{count}</span> : null}
    </div>
  );
};

export default Activity;

const StyledTitle = styled.p<{ color: string }>`
  color: ${({ color }) => color} !important;
`;

const StyledIconWrapper = styled.div<{ color: string }>`
  background: rgb(32, 45, 74);
  background: linear-gradient(90deg, rgba(32, 45, 74, 1) 0%, ${({ color }) => hexToRgba(color, 0.2)} 100%);
`;
