import styled from 'styled-components';

interface Props {
  pages?: string;
}

const LoaderStyle = styled.div.withConfig({
  shouldForwardProp: (prop) => !['pages'].includes(prop),
})<Props>`
  position: ${(props) => (props.pages ? 'unset' : 'fixed')};
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.pages ? '0' : '200')};
  width: 100%;
  height: 100%;
  min-height: 100vh;
  text-align: center;
  justify-content: center;
  background: ${(props) => (props.pages ? 'transparent' : '#161D31')};
  align-items: center;

  @media (max-width: 768px) {
    left: 0;
    .loader_img {
      width: 300px;
      height: 300px;
    }
  }
`;

export default LoaderStyle;
