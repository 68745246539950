import { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { pathOr } from 'ramda';
import { useLocation } from 'react-router-dom';

import { ILotteryState } from 'types';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { ContextPopUps } from 'context';
import { popUps } from 'components/constants/constants';
import { GET_LOTTERY_ROUND_INFO } from 'graphQl/query/lottery/lottery';
import { amount } from 'func/common';
import { TimerSize } from 'components/Games/Lottery/base/LotteryTimer/types';
import { setLotteryState } from 'store/generalData';
import { userToken } from 'store/user/user.selectors';
import { generalDataLotteryState } from 'store/generalData/generalDtata.selectors';

import LotteryMobileLayout from 'components/Games/Lottery/base/LotteryMobileLayout';
import LotteryHeaderLayout from 'components/Header/components/LotteryHeader';

import { ILotteryMobileContainer } from './types';

const LotteryMobileContainer: React.FC<ILotteryMobileContainer> = ({ header }) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [lotteryDataInitial, setLotteryDataInitial] = useState({ displayName: '', prize: '0', startAt: '', endAt: '' });

  const token = useAppSelector(userToken);
  const lotteryState = useAppSelector(generalDataLotteryState);

  const [roundInfo, { data }] = useLazyQuery(GET_LOTTERY_ROUND_INFO, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (!lotteryState) {
      roundInfo();
      return;
    }

    updateState(lotteryState);
  }, [lotteryState]);

  useEffect(() => {
    if (data) {
      const round = pathOr<ILotteryState | null>(null, ['currentLotteryRound'], data);

      if (round) {
        dispatch(setLotteryState(round));
      }
    }
  }, [data]);

  useEffect(() => {
    roundInfo();
  }, []);

  function updateState(state: ILotteryState) {
    const { prize, displayName, startAt, endAt } = state;

    setLotteryDataInitial({
      displayName,
      prize,
      startAt,
      endAt,
    });
  }

  const handlePopUp = () => {
    if (token) {
      setPopUpsOpen(popUps.lottery);
    } else {
      setPopUpsOpen(popUps.login);
    }
  };

  return (
    <>
      {header ? (
        <LotteryHeaderLayout
          lotteryRoundDisplayName={lotteryDataInitial.displayName}
          lotteryRoundPrize={amount(lotteryDataInitial.prize)}
          dateStart={lotteryDataInitial.startAt}
          dateEnd={lotteryDataInitial.endAt}
          timerSize={TimerSize.HEADER}
          onExpire={roundInfo}
          onClick={handlePopUp}
          path={pathname}
        />
      ) : (
        <LotteryMobileLayout
          lotteryRoundDisplayName={lotteryDataInitial.displayName}
          lotteryRoundPrize={amount(lotteryDataInitial.prize)}
          dateStart={lotteryDataInitial.startAt}
          dateEnd={lotteryDataInitial.endAt}
          timerSize={TimerSize.MOBILE}
          onExpire={roundInfo}
          onClick={handlePopUp}
          path={pathname}
        />
      )}
    </>
  );
};

export default LotteryMobileContainer;
