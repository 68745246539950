import BN from 'big.js';

import { EDiceCondition } from 'components/constants/games';

import { prepareAmount, removeComas } from './common';

export const lessThen = (first: string | number, second: string | number): boolean => {
  const num1 = removeComas(String(first));
  const num2 = removeComas(String(second));

  return BN(num1).lt(num2);
};

export const bigThen = (first: string | number, second: string | number): boolean => {
  const num1 = prepareAmount(first);
  const num2 = prepareAmount(second);

  return BN(num1).gt(num2);
};

export const lessOrEqual = (first: string | number, second: string | number): boolean => {
  const num1 = removeComas(String(first));
  const num2 = removeComas(String(second));
  return BN(num1).lte(num2);
};

export const bigOrEqual = (first: string | number, second: string | number): boolean => {
  const num1 = removeComas(String(first));
  const num2 = removeComas(String(second));
  return BN(num1).gte(num2);
};

export const bigMinus = (first: string | number, second: string | number): string => {
  const num1 = removeComas(String(first));
  const num2 = removeComas(String(second));
  const result = BN(num1).minus(num2);
  return BN(result).toFixed();
};

export const isDisableButton = (gameState: string): boolean => {
  switch (gameState) {
    case EDiceCondition.notStarted:
      return false;
    case EDiceCondition.autoNotStarted:
      return false;
    default:
      return true;
  }
};

export const getButtonTitle = (gameState: string): string => {
  switch (gameState) {
    case EDiceCondition.autoNotStarted:
      return 'START AUTO BET';
    case EDiceCondition.autoStarted:
      return 'STOP AUTO BET';
    default:
      return 'BET';
  }
};
