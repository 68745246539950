import clsx from 'clsx';

import Link from '../Link';

import styles from './styles.module.scss';
import { ILink } from './types';

const StyledLink: React.FC<ILink> = ({ to, className = '', children, ...props }) => {
  return (
    <Link className={clsx(styles.link, className)} to={to} {...props}>
      <span>{children}</span>
    </Link>
  );
};

export default StyledLink;
