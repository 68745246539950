import { amount, getDate, getImgSrc } from 'func/common';

import Avatar from 'components/Base/Avatar';

import { WheelHistoryType } from '../../types';
import { IWheelStatisticsItem } from './types';
import styles from './styles.module.scss';

const WheelStatisticsItem: React.FC<IWheelStatisticsItem> = ({ type, transaction }) => {
  return (
    <li className={styles.wrapper}>
      {type === WheelHistoryType.my ? (
        <span className={styles.date}>{getDate(transaction?.createdAt)}</span>
      ) : (
        <span className={styles.alias}>
          <Avatar className={styles.avatar} userAvatar={transaction?.user?.avatar?.avatar} />
          <span>{transaction?.user?.alias}</span>
        </span>
      )}
      <div className={styles.prize}>
        <img
          className={styles.prizeIcon}
          src={getImgSrc(transaction?.token?.tokenCode)}
          alt={transaction?.token?.displayName}
        />
        <span className={styles.prizeAmount}>
          <span>{amount(transaction?.amount, true)}</span>
          <span> {transaction?.token?.displayName}</span>
        </span>
      </div>
    </li>
  );
};

export default WheelStatisticsItem;
