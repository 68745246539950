import { pathOr } from 'ramda';

import { amount, BNMultiply, BNPlus } from 'func/common';
import { Avatars, Token } from 'types/requestTypes';
import { EAvatarsMode } from 'components/Base/PopUps/components/Avatars/Avatars';
import { AvatarType } from 'components/constants/pages';
import { ICurrencyItem } from 'components/Base/CurrensiesTemplate/types';
import { IGraphData } from 'components/Pages/MyAccountPage/components/AccountCollections/types';
import { IMyPreferencesDefault } from 'components/Pages/MyAccountPage/components/MyPreferences/types';
import { getPreferencesItems } from 'components/constants/account';
import { IPreferences, TranslationType } from 'types';

interface DataElem {
  src: string;
  id: string;
}

export interface TotalWagers {
  totalWager?: number;
  __typename?: string;
  token?: Token;
  totalCollected?: string;
}

interface ITotalCollected {
  myCashbacks: TotalWagers[];
  myReferralBalances: TotalWagers[];
  totalDividendCollection: TotalWagers[];
}

interface ITotalCollectedBTC {
  totalCashbackCollectionInBTCBalance: string;
  totalDividendCollectionInBTCBalance: string;
  totalReferralCollectionInBTCBalance: string;
}

export const getDefaultAvatars = (array: Avatars[]): DataElem[] => {
  const properData = [];
  array.forEach((elem) => {
    if (elem.type === AvatarType.default) {
      properData.push({
        src: elem.avatar,
        id: elem.id,
      });
    }
  });
  return properData;
};

export const getPremiumAvatars = (array: Avatars[]): DataElem[] => {
  const properData = [];
  array.forEach((elem) => {
    if (elem.type === AvatarType.premium) {
      properData.push({
        src: elem.avatar,
        id: elem.id,
      });
    }
  });
  return properData;
};

export const isDisplayPremium = (rank: number, mode: string, vipStatus: number): boolean => {
  if (mode === EAvatarsMode.default) {
    return true;
  }
  if (mode === EAvatarsMode.premium && rank >= vipStatus) {
    return true;
  }
  return false;
};

export const isDisableScroll = (rank: number, mode: string, vipStatus: number): boolean => {
  if (mode === EAvatarsMode.premium && rank < vipStatus) {
    return true;
  }
  return false;
};

export const prepareAccountWagers = (arrayData: TotalWagers[]): Array<string & DataElem> | DataElem[] => {
  const balanceData = [];

  arrayData.forEach((elem) => {
    const elemObj = {
      title: amount(elem.totalWager),
      src: elem.token.displayName,
      displayName: elem.token.displayName,
    };
    balanceData.push(elemObj);
  });
  return balanceData;
};

export const isDisplayShadow = (shadow: boolean, listLength: number): boolean => {
  if (!shadow) return false;
  if (shadow && listLength < 30) return false;
  return true;
};

export const getCollectedAmounts = (data: ITotalCollected): ICurrencyItem[] => {
  const balances = pathOr([], ['myReferralBalances'], data);

  const totalCollected = balances.map((el) => ({
    title: el.totalCollected,
    src: el.token.displayName,
    displayName: el.token.displayName,
  }));

  return totalCollected.map((item) => {
    const displayName = item.displayName;
    const dividendsCollection = pathOr([], ['totalDividendCollection'], data);
    const cashbackCollection = pathOr([], ['myCashbacks'], data);

    const dividendsCurrency = dividendsCollection.find((el) => el.token.displayName === displayName);
    const cashbackCurrency = cashbackCollection.find((el) => el.token.displayName === displayName);

    let newAmount = item.title;

    if (dividendsCurrency) {
      newAmount = BNPlus(newAmount, dividendsCurrency.totalCollected);
    }

    if (cashbackCurrency) {
      newAmount = BNPlus(newAmount, cashbackCurrency.totalCollected);
    }

    return {
      ...item,
      title: String(amount(newAmount)),
    };
  });
};

export const getTotalUSD = (data: ITotalCollectedBTC, rate: string): string => {
  if (!data || !rate) return '0';
  let totalBTC = '0';

  if (data.totalCashbackCollectionInBTCBalance) {
    totalBTC = BNPlus(totalBTC, data.totalCashbackCollectionInBTCBalance);
  }

  if (data.totalDividendCollectionInBTCBalance) {
    totalBTC = BNPlus(totalBTC, data.totalDividendCollectionInBTCBalance);
  }

  if (data.totalReferralCollectionInBTCBalance) {
    totalBTC = BNPlus(totalBTC, data.totalReferralCollectionInBTCBalance);
  }

  const usdAmount = BNMultiply(totalBTC, Number(rate));

  return usdAmount;
};

export const getGraphData = (data: ITotalCollectedBTC, rate: string, t: TranslationType): IGraphData[] => {
  const dividends = {
    name: t('account.collection.tooltip.dividends'),
    color: '#99D290',
    value: Number(amount(BNMultiply(data.totalDividendCollectionInBTCBalance, Number(rate)), false)),
    shadow: true,
  };
  const cashback = {
    name: t('account.collection.tooltip.cashback'),
    color: '#3856DD',
    value: Number(amount(BNMultiply(data.totalCashbackCollectionInBTCBalance, Number(rate)), false)),
  };
  const referral = {
    name: t('account.collection.tooltip.referral'),
    color: '#EE4D6A',
    value: Number(amount(BNMultiply(data.totalReferralCollectionInBTCBalance, Number(rate)), false)),
  };
  return [dividends, cashback, referral];
};

export const getMyPreferences = (preferences: IPreferences, t: TranslationType): IMyPreferencesDefault[] => {
  const newPreferences = getPreferencesItems(t);
  if (!preferences) return newPreferences;

  return newPreferences.map((p) => {
    const currentPreferenceValue = preferences[p.type];
    return {
      ...p,
      value: currentPreferenceValue,
    };
  });
};

export const getPreferenceByKey = (preferences: IMyPreferencesDefault[], key: string): boolean => {
  return preferences.find((p) => p.type === key)?.value;
};

export const isDisplayGraph = (graphData: IGraphData[]): boolean => {
  let usdAmount = 0;
  graphData.forEach((g) => {
    usdAmount += g.value;
  });
  return usdAmount > 0;
};
