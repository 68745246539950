import styled from 'styled-components';

const CoinPopUpStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;

  .title {
    font-family: 'Montserrat';
    font-weight: 800;
    font-size: 36px;
    color: #ffffff;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 10px 0 10px;
  }

  .subtitle {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .subtitle-item1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 25px;
    p {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #888aa7;
      margin: 5px 0 0 0;
      padding: 0;
    }
  }

  .subtitle-item2 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    h3 {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      margin: 5px 0 0 0;
      padding: 0;
    }
  }

  .seeds {
    margin: 1rem 0 0;
  }

  .seeds .subtitle-item1 {
    margin-right: 1rem;
    min-width: 100px;
  }

  .seeds .subtitle-item1 p {
    margin-top: 8px;
    padding: 0.3rem 0;
  }

  .seeds .subtitle-item2 {
    flex-grow: 1;
    width: 50%;
  }

  div.seeds-text {
    box-sizing: border-box;
    display: flex;
    align-items: center;

    padding: 0.3rem 0.6rem;
    margin: 5px 0 0 0;
    width: 100%;

    border: 2px solid #3757dd;
    border-radius: 14px;
  }

  div.seeds-text p {
    margin: 0;

    color: #ffffff;
    font-size: 0.8rem;
    font-family: 'Montserrat';
    font-weight: 600;
    line-height: 17px;
    text-align: left;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  div.seeds-text div {
    margin-left: auto;
  }

  .verify-button-wrapper {
    margin-top: 1rem;
  }

  .verify-button {
    text-transform: uppercase;
  }

  .amount-block {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
  }
  .amount {
    width: 150px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 16px;
    background: #2f374f;
    border: 2px solid rgba(136, 138, 167, 0.29);
    padding: 0 0 0 17px;
  }
  .payout {
    width: 150px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 16px;
    background: #2f374f;
    border: 2px solid rgba(136, 138, 167, 0.29);
    padding: 0 0 0 17px;
  }
  .multiplier {
    width: auto;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    background: #2f374f;
    border: 2px solid rgba(136, 138, 167, 0.29);
    padding: 5px 10px;
    margin: 0 10px;
  }
  .amount-title {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.09px;
    color: #888aa7;
    margin: 0;
    padding: 0;
  }
  .amount-subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 -5px;
  }
  .token-icon {
    width: 30px;
    height: 30px;
  }
  .amount-result {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.09px;
    color: #ffffff;
  }

  .screen-coin {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 0;
  }
  .screen-coin--content {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    width: 100%;
    scrollbar-width: thin;
  }

  .screen-coin--content-item.single {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .screen-coin--content::-webkit-scrollbar {
    height: 10px;
  }
  .screen-coin--content::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .screen-coin--content::-webkit-scrollbar-thumb {
    background: rgba(136, 138, 167, 0.29);
    border-radius: 10px;
  }

  .screen-coin--content-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100px;
    margin: 0 15px 10px 0;
  }

  .coin__img--win,
  .coin__img--lose {
    display: inline-block;
    width: 100px;
  }

  .coin__img--win {
    opacity: 1;
  }
  .coin__img--lose {
    opacity: 0.4;
  }

  .coin__para--win,
  .coin__para--lose {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.09px;
    margin: 0 0 10px;
  }

  .coin__para--win {
    color: #0e9628;
  }

  .coin__para--lose {
    color: #e01f48;
  }

  @media (max-width: 1024px) {
  }
  @media (max-width: 863px) {
  }
  @media (max-width: 540px) {
    .title {
      font-size: 24px;
    }
    .subtitle-item1 {
      margin-right: 15px;
      width: 50px;
      p {
        font-size: 12px;
      }
    }
    .subtitle-item2 {
      h3 {
        font-size: 10px;
        word-break: break-all;
      }
    }
    .amount-block {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .payout,
    .amount {
      align-items: center;
      padding: 0;
      img {
        margin-left: -3px;
      }
    }
    .multiplier {
      width: 150px;
      height: 60px;
      padding: 5px 10px;
      margin: 10px 0;
    }
    .amount-title {
      font-size: 12px;
    }
    .token-icon {
      width: 25px;
      height: 25px;
    }
    .amount-result {
      font-size: 12px;
    }
  }
  @media (max-width: 480px) {
  }
  @media (max-width: 370px) {
  }
`;

export default CoinPopUpStyle;
