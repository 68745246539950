import React from 'react';

import CardWrapper from 'components/Base/CardWrapper';

import FAQContentItem from './FAQContentItem';
import { IFAQContent } from './types';

const FAQContent: React.FC<IFAQContent> = ({ sectionArticles }) => (
  <CardWrapper width="100%" height="">
    <div className="faq__content--wrap">
      {sectionArticles.map((a) => (
        <FAQContentItem key={a.id} title={a.title} description={a.body} />
      ))}
    </div>
  </CardWrapper>
);

export default FAQContent;
