import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { setGames } from 'store/data';
import { DEFAULT_PARAMS, GAMES_REQUEST_STEP, GameSubcategory } from 'components/constants/games';
import { ContextPopUps } from 'context';
import { Links } from 'components/constants';
import { EGamesTabs, GAMES_QUALITY, popUps } from 'components/constants/constants';
import { GET_GAMES, GET_PROVIDER_LIST } from 'graphQl/query/games/gameQueries';
import { GET_MY_BONUSES } from 'graphQl/query/bonus/indes';
import {
  getCategoryName,
  getCategoryToRequest,
  getFormattedGames,
  getProviderRequest,
  getProvidersDropdown,
  isDisabledProviders,
  setAdditionalSearchParam,
} from 'func/prepareDataGamesPage';
import searchIcon from 'assets/img/common/searchIcon.svg';
import { tabsListGamesPage } from 'components/constants/pages';
import PragmaticDGAProvider from 'context/contextPragmatic';
import { userProfile, userToken } from 'store/user/user.selectors';
import { dataFreeSpinsSettings, dataGames, dataVipStatus } from 'store/data/data.selectors';
import { useLocalization } from 'components/Internationalization';
import { useNavigate } from 'hooks/useNavigate';
import { useAnalyticsDispatch } from 'hooks/useAnalyticsDispatch';
import { DataLayerEvent } from 'types/dataLayerTypes';

import GamesCards from 'components/Base/GamesCards';
import DropdownSearch from 'components/common/DropdownSearch';
import GamesNavigation from 'components/common/GamesNavigation';
import GamesTabs from './components/GamesTabs';

import styles from './styles.module.scss';

const Games: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search: locationSearch } = useLocation();
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { translate, language } = useLocalization();
  const dataLayerDispatch = useAnalyticsDispatch();

  const [categoryUrl, setCategoryUrl] = useState(DEFAULT_PARAMS);
  const [providerUrl, setProviderUrl] = useState(DEFAULT_PARAMS);
  const [tabUrl, setTabUrl] = useState(DEFAULT_PARAMS);
  const [inputValue, setInputValue] = useState('');
  const [formattedGames, setFormattedGames] = useState(null);
  const [dropdownProviders, setDropdownProviders] = useState(null);
  const [offSet, setOffset] = useState(0);
  const [countLoad, setCountLoad] = useState(1);
  const [itemsCount, setItemsCount] = useState(0);
  const [queryTimeout, setQueryTimeout] = useState<NodeJS.Timeout>();
  const [subCategories, setSubCategories] = useState<GameSubcategory[]>([]);

  const user = useAppSelector(userProfile);
  const token = useAppSelector(userToken);
  const gamesList = useAppSelector(dataGames);
  const freeSpinsSettings = useAppSelector(dataFreeSpinsSettings);
  const vipStatus = useAppSelector(dataVipStatus);

  const { data: providersList } = useQuery(GET_PROVIDER_LIST, { fetchPolicy: 'cache-only' });
  useQuery(GET_MY_BONUSES, {
    fetchPolicy: 'cache-and-network',
    skip: !token || !freeSpinsSettings,
  });

  const [getGames] = useLazyQuery(GET_GAMES, { fetchPolicy: 'no-cache' });

  const handleTabs = (urlTitle) => {
    setAdditionalSearchParam(navigate, locationSearch, GAMES_QUALITY.tabs.id, urlTitle);
  };

  const handleSearch = async (event) => {
    if (queryTimeout) clearTimeout(queryTimeout);

    const value = event.target.value;
    setInputValue(value);
    dataLayerDispatch({
      event: DataLayerEvent.viewSearchResult,
      user_id: user?.id,
      search_position: 'Games',
      search_term: value,
    });

    const newQueryTimeout = setTimeout(() => {
      if (value.length >= 3) {
        const gameListParams = {
          offset: 0,
          limit: 100,
          name: `${value}`,
        };

        getGames({ variables: { gameListParams } }).then((res) => {
          const newGames = pathOr([], ['data', 'gameList', 'items'], res);
          const games = getFormattedGames(newGames, navigate, setPopUpsOpen, translate);

          setFormattedGames(games);
        });
      }

      if (!value) {
        setFormattedGames(gamesList.filter((_, index) => index < countLoad * GAMES_REQUEST_STEP));
      }
    }, 300);

    setQueryTimeout(newQueryTimeout);
  };

  const handleGetGames = async (
    categoryName: string,
    providerName: string,
    tabName: string,
    offset: number,
    limit: number,
  ) => {
    const gameListParams = {
      offset,
      limit,
      new: tabName === EGamesTabs.new ? true : null,
      popular: tabName === EGamesTabs.popular ? true : null,
      category: getCategoryToRequest(categoryName),
      isFavorite: tabName === EGamesTabs.favorite ? true : null,
      isDropsAndWins: tabName === EGamesTabs.dropsAndWins ? true : null,
      providerName: getProviderRequest(providerName),
      bonus: tabName === EGamesTabs.bonus ? true : null,
      vip: tabName === EGamesTabs.vip ? true : null,
      userId: user?.id,
    };
    const response = await getGames({ variables: { gameListParams } });
    const newGames = pathOr([], ['data', 'gameList', 'items'], response);
    const newSubCategories = pathOr([], ['data', 'gameList', 'subcategories'], response);
    const games = getFormattedGames(newGames, navigate, setPopUpsOpen, translate);
    const prevGames = offset ? gamesList : [];

    dispatch(setGames([...prevGames, ...games]));
    setSubCategories(newSubCategories);

    const count = pathOr(0, ['data', 'gameList', 'count'], response);
    setItemsCount(count);
  };

  const handleLoadMoreGames = () => {
    const categoryName = getCategoryName(categoryUrl);

    setFormattedGames(gamesList.filter((_, index) => index < offSet));
    handleGetGames(categoryName, providerUrl, tabUrl, offSet, GAMES_REQUEST_STEP);

    setOffset(offSet + GAMES_REQUEST_STEP);
    setCountLoad(countLoad + 1);
  };

  useEffect(() => {
    const params = new URLSearchParams(locationSearch);
    const category = params.get(GAMES_QUALITY.categories.id);
    const provider = params.get(GAMES_QUALITY.providers.id);
    const tab = params.get(GAMES_QUALITY.tabs.id);
    setCategoryUrl(category || '');
    setProviderUrl(provider || '');
    setTabUrl(tab || '');

    const deposit = params.get('deposit');
    if (deposit) {
      setPopUpsOpen({
        modalOpen: popUps.walletNavigation,
        data: { config: popUps.deposit, active: popUps.deposit },
      });
      navigate(Links.games);
    }
  }, [locationSearch]);

  useEffect(() => {
    const categoryName = getCategoryName(categoryUrl);
    const updatedParams = categoryUrl !== DEFAULT_PARAMS && providerUrl !== DEFAULT_PARAMS && tabUrl !== DEFAULT_PARAMS;
    if (token && user && updatedParams) {
      dispatch(setGames([]));
      setCountLoad(1);

      handleGetGames(categoryName, providerUrl, tabUrl, 0, GAMES_REQUEST_STEP * 2);

      setOffset(GAMES_REQUEST_STEP * 2);
    }
    if (!token) {
      dispatch(setGames([]));
      setCountLoad(1);

      handleGetGames(categoryName, providerUrl, tabUrl, 0, GAMES_REQUEST_STEP * 2);

      setOffset(GAMES_REQUEST_STEP * 2);
    }
  }, [categoryUrl, providerUrl, tabUrl, user]);

  useEffect(() => {
    if (providersList) {
      const providersData = getProvidersDropdown(
        providersList.getProviders,
        navigate,
        locationSearch,
        providerUrl,
        translate,
      );
      setDropdownProviders(providersData);
      setInputValue('');
    }
  }, [providerUrl, providersList, locationSearch, language]);

  useEffect(() => {
    if (gamesList && countLoad === 1) {
      setFormattedGames(gamesList.filter((_, index) => index < GAMES_REQUEST_STEP));
    }
  }, [gamesList, countLoad]);

  useEffect(() => {
    if (tabUrl === EGamesTabs.favorite && gamesList?.length && !inputValue) {
      setFormattedGames(gamesList.filter((_, index) => index < countLoad * GAMES_REQUEST_STEP));
    }
  }, [gamesList, inputValue, countLoad, tabUrl]);

  if (!formattedGames) return null;

  return (
    <PragmaticDGAProvider>
      <div className={styles.contentGamesPage}>
        <GamesNavigation onSearch={handleSearch} setInputValue={setInputValue} inputValue={inputValue} />
        <div className={styles.contentGamesPageFilter}>
          <div className={styles.contentGamesPageTabs}>
            <GamesTabs
              currentTitle={tabUrl}
              titlesList={tabsListGamesPage}
              onTitle={handleTabs}
              vipStatus={vipStatus}
              subCategoriesList={subCategories}
            />
          </div>
          <div className={styles.contentGamesPageItem}>
            <img src={searchIcon} alt="icon" className={styles.contentGamesPageItemLabel} />
            <input
              type="text"
              value={inputValue}
              onChange={handleSearch}
              placeholder={translate('games.nav.search')}
              className={styles.contentGamesPageItemInput}
              autoComplete="off"
              autoCorrect="off"
              spellCheck="false"
            />
          </div>
          <div className={styles.contentGamesPageItem}>
            <DropdownSearch data={dropdownProviders} disable={isDisabledProviders(categoryUrl, tabUrl)} />
          </div>
        </div>
        {formattedGames?.length > 0 ? (
          <GamesCards
            data={formattedGames}
            onLoad={handleLoadMoreGames}
            isDisableButton={formattedGames?.length >= itemsCount}
            hideButton={Boolean(inputValue)}
          />
        ) : (
          <div className={styles.contentGamesPageResult} />
        )}
      </div>
    </PragmaticDGAProvider>
  );
};

export default Games;
