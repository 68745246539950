import styled from 'styled-components';

interface Props {
  ispopup?: string;
}

const LotteryDesktopStyle = styled.div.withConfig({
  shouldForwardProp: (prop) => !['ispopup'].includes(prop),
})<Props>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  min-height: 625px;
  width: ${(props) => (props.ispopup ? '100%' : '345px')};
  padding: ${(props) => (props.ispopup ? '0 30px 30px 30px' : '10px')};
  min-width: 345px;
  //margin: 15px 30px 0 0;
  margin: ${(props) => (props.ispopup ? '40px 0 0 0' : '15px 30px 0 0')};
  //padding: 10px;

  //background: rgba(136, 138, 167, 0.15);
  background: ${(props) => (props.ispopup ? 'transparent' : 'rgba(136, 138, 167, 0.15)')};
  mix-blend-mode: normal;

  //box-shadow: 0 20px 40px 10px rgba(0, 0, 0, 0.25);
  box-shadow: ${(props) => (props.ispopup ? 'none' : '0 20px 40px 10px rgba(0, 0, 0, 0.25)')};
  backdrop-filter: blur(10px);

  border-radius: 20px;

  @media (max-width: 1300px) {
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1260px) {
    display: ${(props) => (props.ispopup ? 'flex' : 'none')};
    width: 100%;
    background: transparent;
    box-shadow: none;
    margin-top: 40px;
    padding: 0 30px 30px 30px;
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 580px) {
  }
  @media (max-width: 540px) {
    height: auto;
    padding: 0 10px 70px 10px;
    margin-top: 4px;
  }
  @media (max-width: 400px) {
  }
`;

export default LotteryDesktopStyle;
