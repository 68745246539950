import { useContext, useEffect, useState } from 'react';

import DiceGameContext from 'context/contextDiceGame/context';
import { HistoryNewEl } from 'components/Games/base/AllGameHistoryNew/types';
import { DICE_BET_ANIMATION_DURATION } from 'components/constants/games';

import AllGameHistoryNew from 'components/Games/base/AllGameHistoryNew';

const DiceGameHistory: React.FC = () => {
  const { animation, history } = useContext(DiceGameContext);

  const [results, setResults] = useState<HistoryNewEl[]>([]);

  useEffect(() => {
    if (history[0]?.id) {
      setTimeout(
        () => {
          const newResults = history.slice(0, 16).map<HistoryNewEl>((b) => ({
            id: b.id,
            num: String(b.luckyNumber),
            result: b.outcome.toLowerCase(),
          }));
          setResults(newResults);
        },
        animation ? DICE_BET_ANIMATION_DURATION : 100,
      );
    }
  }, [history]);

  return <AllGameHistoryNew data={results} />;
};

export default DiceGameHistory;
