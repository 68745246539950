import styled from 'styled-components';

const LotteryContentStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0;

  .lot__content-title-wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 1300px) {
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1100px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 580px) {
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 400px) {
  }
`;

export default LotteryContentStyle;
