import { gql } from '@apollo/client';

export const GET_DIVIDEND = gql`
  query dividend {
    dividend {
      availableBalance
      token {
        tokenCode
        displayName
      }
    }
  }
`;

export const GET_DIVIDENDS_BALANCE_DATA = gql`
  query dividendBalanceData {
    dividend {
      availableBalance
      token {
        tokenCode
        displayName
      }
    }
    totalDividendCollection {
      token {
        tokenCode
        displayName
      }
      totalCollected
    }
    convertedDividendAvailableCollectionBalance {
      availableBalance
      tokenCode
      displayName
    }
  }
`;
