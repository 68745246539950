import { RootState } from 'index';

export const generalDataVipRank = (state: RootState) => state.generalData.vipRank;
export const generalDataRanksList = (state: RootState) => state.generalData.ranksList;
export const generalDataExchangeRates = (state: RootState) => state.generalData.exchangeRates;
export const generalDataEventsList = (state: RootState) => state.generalData.eventsList;
export const generalDataTournamentsList = (state: RootState) => state.generalData.tournamentsList;
export const generalDataLotteryState = (state: RootState) => state.generalData.lotteryState;
export const generalDataSettings = (state: RootState) => state.generalData.settings;
export const generalDataActiveTokens = (state: RootState) => state.generalData.activeTokens;
