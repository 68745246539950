import styled from 'styled-components';

import SlotsCard from 'components/Base/GamesCards/components/SlotsCard';

import styles from './styles.module.scss';
import { IGamesSlide } from './types';

const GamesSlide: React.FC<IGamesSlide> = ({ slidesToShow, bonusIsActive, games, onLike }) => {
  return games.length ? (
    <StyledSlide className={styles.slide} $slides={slidesToShow}>
      {games.map((g, i) => (
        <div key={g.gameId} className={styles.card}>
          <SlotsCard {...(g as any)} index={i} bonusIsActive={bonusIsActive} onLike={onLike} />
        </div>
      ))}
    </StyledSlide>
  ) : null;
};

export default GamesSlide;

const StyledSlide = styled.div<{ $slides: number }>`
  display: grid;
  ${({ $slides }) => ($slides ? `grid-template-columns: repeat(${$slides}, 1fr);` : '')}
`;
