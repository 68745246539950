import React, { useContext } from 'react';

import SocketContext from 'context/contextSocket/context';
import { useAppSelector } from 'hooks/useAppSelector';
import { CoinMultiplier, ECoinCondition } from 'components/constants/games';
import { useEventListener } from 'hooks/useEventListener';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';

import CoinMultiplyCheckboxStyle from './styles';
import { ICoinMultiplyCheckbox } from './types';

const CoinMultiplyCheckbox: React.FC<ICoinMultiplyCheckbox> = ({
  value,
  setValue,
  disable,
  setGameState,
  setMultiplierSeries,
  setHistory,
  isHotkeys,
}) => {
  const { translate } = useLocalization();
  const token = useAppSelector(userToken);

  const { connected: socketConnected } = useContext(SocketContext);
  const handleClick = () => {
    setValue(!value);
    if (value) {
      setGameState(ECoinCondition.notStarted);
      setMultiplierSeries({ series: 0, multiplier: String(CoinMultiplier) });
    } else {
      setGameState(ECoinCondition.sessionNotStarted);
      setMultiplierSeries({ series: 0, multiplier: '0' });
    }
    setHistory([]);
  };

  const handleKyePress = (event) => {
    if (isHotkeys) {
      if (disable) return;
      const key = event?.key;
      if (key === 'b') handleClick();
    }
  };

  useEventListener('keyup', handleKyePress);

  return (
    <CoinMultiplyCheckboxStyle>
      <p>{value ? translate('coinflip.multiply.text') : translate('coinflip.instant.text')}</p>
      <div className="auto__checkbox-wrap">
        <input
          id="checkbox"
          type="checkbox"
          checked={value}
          onChange={handleClick}
          disabled={disable || (token && !socketConnected)}
        />
        <label htmlFor="checkbox" className="auto__checkbox-label" />
      </div>
    </CoinMultiplyCheckboxStyle>
  );
};

export default CoinMultiplyCheckbox;
