import {
  EKenoRisk,
  FastModeGames,
  HotKeys,
  initialAutoState,
  initialGameSettings,
  initialKenoWinInfo,
  KenoCondition,
} from 'components/constants/games';
import { getKenoSquares } from 'func/prepareDataKeno';

import { IInitialState } from './types';

export const initialState: IInitialState = {
  fastMode:
    JSON.parse(localStorage.getItem(FastModeGames.keno)) !== null
      ? JSON.parse(localStorage.getItem(FastModeGames.keno))
      : true,
  isAuto: false,
  isHotkeys: JSON.parse(localStorage.getItem(HotKeys.keno)) || false,
  gameId: '',
  gameCondition: KenoCondition.notStarted,
  betAmountValue: '0.00000000',
  gameSettings: initialGameSettings,
  risk: EKenoRisk.classic,
  historyList: [],
  squaresList: getKenoSquares(),
  selectedSquaresList: [],
  winInfo: initialKenoWinInfo,
  requestId: '',
  autoState: initialAutoState,
  disable: false,
};
