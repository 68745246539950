import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { useAppSelector } from 'hooks/useAppSelector';
import { generalDataActiveTokens } from 'store/generalData/generalDtata.selectors';
import { TOKEN_CODES } from 'components/constants/constants';
import { getTokenCode } from 'func/prepareDataHeader';

import DropDownStyle from './styles';
import { IDropDown, IncomeArray } from './types';

const DropDown: React.FC<IDropDown> = ({
  data,
  top,
  width,
  textPadding,
  errorFilter,
  hideFirstElem,
  position = 'bottom',
  maxHeight,
  tokens,
}) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(null);
  const [sortedData, setSortedData] = useState<IncomeArray[]>([]);

  const activeTokens = useAppSelector(generalDataActiveTokens);

  useEffect(() => {
    if (tokens) {
      const newSortedData = [...data].sort((a, b) => {
        if (TOKEN_CODES.includes(a.tokenCode) && TOKEN_CODES.includes(b.tokenCode)) {
          const aToken = activeTokens.find((t) => t.tokenCode === getTokenCode(a.tokenCode));
          const bToken = activeTokens.find((t) => t.tokenCode === getTokenCode(b.tokenCode));

          return aToken.weight > bToken.weight ? 1 : -1;
        }

        return 1;
      });

      setSortedData(newSortedData);
      return;
    }

    setSortedData(data);
  }, [data, tokens, activeTokens]);

  useEffect(() => {
    setTitle(sortedData[0]);
  }, [sortedData]);

  const handleOpen = () => setOpen(!open);

  const handleBlur = (event) => {
    if (event.relatedTarget?.tabIndex >= 1000) return;
    setOpen(false);
  };

  const handleClick = (elem) => {
    setTitle(elem);
    setOpen(!open);
  };

  useEffect(() => {
    if (errorFilter) {
      handleClick(sortedData[0]);
      setOpen(false);
    }
  }, [errorFilter]);

  if (!title) return null;

  return (
    <DropDownStyle top={top} width={width} textpadding={textPadding} hidefirstelem={hideFirstElem}>
      <div tabIndex={0} className="drop__header" onClick={handleOpen} onBlur={handleBlur}>
        <div className="drop__body-item" key={title.tokenCode ? title.tokenCode : title.title}>
          {title.img && (
            <img src={title.img} alt="icon" className={title.className ? title.className : 'drop__body-icon1'} />
          )}
          <p className="drop__body-title">{title.title ? title.title : title.tokenCode}</p>
        </div>
        <i className={open ? 'up' : 'down'} />
      </div>
      <div className={open ? clsx('drop__body open', position === 'top' ? 'drop__body_top' : '') : 'drop__body'}>
        <div className="dropScroll" style={{ maxHeight: maxHeight || '300px' }}>
          {sortedData.map((elem, index) => {
            if (elem.hideFirstElem) return;

            return (
              <div
                tabIndex={index + 1000}
                className="drop__body-item"
                key={elem.tokenCode ? elem.tokenCode : elem.title}
                onClick={() => {
                  if (elem.func) elem.func();
                  handleClick(elem);
                }}
              >
                {elem.img && (
                  <img src={elem.img} alt="icon" className={elem.className ? elem.className : 'drop__body-icon'} />
                )}
                <p className="drop__body-title">{elem.title ? elem.title : elem.tokenCode}</p>
              </div>
            );
          })}
        </div>
      </div>
    </DropDownStyle>
  );
};

export default DropDown;
