import monster from 'assets/img/common/monster.webp';

import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';

const EmptyWithdrawPage: React.FC = () => {
  const { translate } = useLocalization();
  return (
    <div className={styles.wrapper}>
      <p className={styles.para}>{translate('popups.wallet.withdraw.empty.title')}</p>
      <img src={monster} alt="monster" className={styles.monster} />
    </div>
  );
};

export default EmptyWithdrawPage;
