import styled from 'styled-components';

import { IBlackJackBetDetailsCardImage } from './types';

export const BetDetailsCardImage = styled.img<IBlackJackBetDetailsCardImage>`
  display: inline-block;
  width: 40%;
  position: absolute;
  top: ${(props) => `${props.top}% !important`};
  left: ${(props) => `${props.left}% !important`};
`;
