import * as yup from 'yup';

import { TranslationType } from 'types';

export enum ValidationErrors {
  uppercaseLetter = 'The password must contain at least one uppercase letter.',
  lowercaseLetter = 'The password must contain at least one lowercase letter.',
  number = 'The password must contain at least one number.',
  passLength = 'The password must be at least 8 characters long.',
  passwordMatch = 'Passwords must match',
  forbiddenCharacters = "The password contains prohibited characters. Allowed characters: a-z, A-Z, 0-9 and special characters(such as the: *.!@$%^`'&(){}[]:;<>,.?\\/~_+-=| )",
}

export const getLoginValidationSchema = (t: TranslationType) => {
  const invalidEmailError = t('popups.login.errors.invalid-email');
  const requiredEmailError = t('popups.login.errors.email-required');
  const requiredPasswordError = t('popups.login.errors.password-required');

  return yup.object({
    email: yup.string().email(invalidEmailError).max(255).required(requiredEmailError),
    password: yup.string().max(255).required(requiredPasswordError),
  });
};

export const validationEmail = yup.object({
  email: yup.string().email('Must be a valid email').max(255).required('Email is required'),
});

export const validationPassword = yup.object({
  password: yup
    .string()
    .required('weak')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
    ),
  confirmPassword: yup
    .string()
    .required('match')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const getValidationChangePassword = (t: TranslationType) => {
  const uppercaseLetterError = t('security.password.error.uppercaseLetter');
  const lowercaseLetterError = t('security.password.error.lowercaseLetter');
  const numberError = t('security.password.error.number');
  const passLengthError = t('security.password.error.passLength');
  const passwordMatchError = t('security.password.error.passwordMatch');
  const forbiddenCharactersError = t('security.password.error.forbiddenCharacters');

  return yup.object({
    currentPassword: yup.string().required('Required field'),
    newPassword: yup
      .string()
      .test('contain-uppercase', uppercaseLetterError, (pass) => {
        const UPPERCASE_REGEX = /[A-Z]/gm;
        const hasUpperCaseLetter = UPPERCASE_REGEX.test(pass);

        return hasUpperCaseLetter;
      })
      .test('contain-lowercase', lowercaseLetterError, (pass) => {
        const LOWERCASE_REGEX = /[a-z]/gm;
        const hasLowerCaseLetter = LOWERCASE_REGEX.test(pass);

        return hasLowerCaseLetter;
      })
      .test('contain-number', numberError, (pass) => {
        const NUMBER_REGEX = /[0-9]/gm;
        const hasNumber = NUMBER_REGEX.test(pass);

        return hasNumber;
      })
      .test('min-length', passLengthError, (pass) => {
        return pass?.length >= 8;
      })
      .test('only-default-sybmols', forbiddenCharactersError, (pass) => {
        const ONLY_DEFAULT_SYMBOLS_REGEX = /^[a-zA-Z0-9*.!@$%^`'&(){}[\]:;<>,.?\\/~_+-=|]+$/gm;
        const hasOnlyDefaultSybmols = ONLY_DEFAULT_SYMBOLS_REGEX.test(pass);

        return hasOnlyDefaultSybmols;
      })
      .required('Password is required'),
    confirmNewPassword: yup
      .string()
      .required('Required field')
      .oneOf([yup.ref('newPassword'), null], passwordMatchError),
  });
};

export const validationPasswordConfirm = yup.object({
  prevPassword: yup.string().max(255).required('Password is required'),
  newPassword: yup.string().max(255).required('Password is required'),
});
