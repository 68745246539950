import React from 'react';

import { DiceBetMode } from 'types';
import { AutoState } from 'func/commonGames';

import { IDiceGameContext } from './types';

export const initialDiceAutoSettings: AutoState = {
  firstSpin: true,
  autoBetAmount: '',
  betsNumbers: '',
  maxBetAmount: '',
  onLossIncrease: '',
  isOnLossIncrease: false,
  onWinIncrease: '',
  isOnWinIncrease: false,
  stopOnLossAmount: '',
  stopOnLossAmountDisplay: '',
  stopOnWinAmount: '',
  stopOnWinAmountDisplay: '',
};

const DiceGameContext = React.createContext<IDiceGameContext>({
  animation: true,
  auto: false,
  autoStarted: false,
  autoSettings: initialDiceAutoSettings,
  animationInProgress: false,
  betInProgress: false,
  diceId: '',
  firstBet: true,
  amount: '',
  mode: DiceBetMode.under,
  prediction: 50,
  gameSettings: undefined,
  history: [],
  withHotKeys: false,
  onChangeAuto: () => {},
  onChangeAmount: () => {},
  onChangeMode: () => {},
  onChangePrediction: () => {},
  onChangeAutoSettings: () => {},
  onChangeAnimation: () => {},
  onChangeWithHotKeys: () => {},
  onToggleAnimationInProgress: () => {},
  onBet: () => {},
  onAutoBet: () => {},
});

export default DiceGameContext;
