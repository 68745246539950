import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { ReactComponent as TelegramIcon } from 'assets/img/Vip/telegram.svg';

import { useLocalization } from 'components/Internationalization';
import { useAppSelector } from 'hooks/useAppSelector';
import { generalDataVipRank } from 'store/generalData/generalDtata.selectors';
import { userProfile } from 'store/user/user.selectors';

import styles from './styles.module.scss';
import { IVipBonusList, VipBenefitsCategory } from './types';
import { VIP_BENEFITS, VIP_BENEFITS_CATEGORIES } from './constants';

const VipBenefits: React.FC = () => {
  const { translate } = useLocalization();

  const [category, setCategory] = useState(VipBenefitsCategory.bonus);
  const [bonuses, setBonuses] = useState<IVipBonusList[]>([]);
  const [isVip, setIsVip] = useState(false);

  const vipRank = useAppSelector(generalDataVipRank);
  const user = useAppSelector(userProfile);

  useEffect(() => {
    const newBonuses = VIP_BENEFITS[category];

    setBonuses(newBonuses);
  }, [category]);

  useEffect(() => {
    if (vipRank && user) {
      const userRank = user.rank.id;

      setIsVip(userRank >= vipRank);
      return;
    }

    setIsVip(false);
  }, [vipRank, user]);

  const handleChangeCategory = (c: VipBenefitsCategory) => {
    setCategory(c);
  };

  const handleAction = (link: string) => {
    window.open(link, '__blank');
  };

  return (
    <section className={styles.wrapper}>
      <h2 className={styles.title}>{translate('vip.benefits.title')}</h2>
      <nav className={styles.nav}>
        <ul className={styles.categories}>
          {VIP_BENEFITS_CATEGORIES.map((c) => (
            <li
              key={c.value}
              className={clsx(styles.category, category === c.value ? styles.active : '')}
              onClick={() => handleChangeCategory(c.value)}
            >
              <img className={styles.icon} src={c.icon} alt={c.value} />
              <span className={styles.text}>{translate(c.title)}</span>
            </li>
          ))}
        </ul>
      </nav>
      <ul key={category} className={clsx(styles.bonuses, 'animate__animated animate__fadeInUp')}>
        {bonuses.map((b) => (
          <li key={b.title} className={styles.bonus}>
            <div className={styles.bright} />
            <div className={styles.content}>
              <img className={styles.bonusIcon} src={b.icon} alt={b.title} />
              <h5 className={styles.bonusTitle}>{translate(b.title)}</h5>
              <p className={styles.desc}>{translate(b.description)}</p>
              {b.action && isVip ? (
                <button className={styles.actionButton} type="button" onClick={() => handleAction(b.action.link)}>
                  <TelegramIcon className={styles.actionIcon} />
                  <span>{translate(b.action.title)}</span>
                </button>
              ) : null}
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default VipBenefits;
