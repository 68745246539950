import loader1 from 'assets/img/loader/green-alien-wear-sunglasses-loader.webp';
import loader2 from 'assets/img/loader/hypnotize-alien-loader.webp';
import loader3 from 'assets/img/loader/red-alien-loader.webp';
import loader4 from 'assets/img/loader/yellowMonster.webp';

import { IBigWin, ISlider } from 'types';
import { TableElem } from 'types/requestTypes';
import { TABLE_CLASSNAMES } from 'components/constants/constants';

import UserName from 'components/Base/Table/components/UserName';

import { amount, getDate, getImgSrc } from './common';

interface TopPlayers {
  alias: string;
  avatar: string | null;
  id: string;
  totalWager: string;
  rank: string;
  __typename: string;
}

export const prepareTopPlayers = (arrayData: TopPlayers[]): TableElem[][] => {
  const tableData = arrayData.map((item, index): unknown[] => {
    const place = {
      title: String(index + 1),
      columnClass: TABLE_CLASSNAMES.column.default,
      textClass: TABLE_CLASSNAMES.text.default,
    };

    const jsxElem = <UserName src={item.avatar} userName={item.alias} rank={item.rank} />;

    const titleElem = {
      title: `${amount(item.totalWager)} BTC`,
      src: getImgSrc('BTC'),
      columnClass: TABLE_CLASSNAMES.column.default,
      imageClass: TABLE_CLASSNAMES.images.currency,
      textClass: TABLE_CLASSNAMES.text.default,
    };

    return [place, jsxElem, titleElem];
  });

  return tableData;
};

export const prepareBigWins = (bigWins: IBigWin[]): TableElem[][] => {
  const tableData = bigWins.map((win, i): unknown[] => {
    const { name: gameName } = win.game;
    const { avatar, alias, rank } = win.user;

    const userAvatar = avatar ? avatar.avatar : null;

    const place = {
      title: String(i + 1),
      columnClass: TABLE_CLASSNAMES.column.default,
      textClass: TABLE_CLASSNAMES.text.default,
    };

    const user = <UserName src={userAvatar} userName={alias} rank={rank.id} />;

    const date = {
      title: getDate(win.createdAt),
      columnClass: TABLE_CLASSNAMES.column.hide700,
      textClass: TABLE_CLASSNAMES.text.default,
    };

    const game = {
      title: gameName,
      columnClass: TABLE_CLASSNAMES.column.hideTablet,
      textClass: TABLE_CLASSNAMES.text.dateFormat,
    };

    const payout = {
      title: `${amount(win.payout)} ${win.tokenCode}`,
      src: getImgSrc(win.tokenCode),
      columnClass: TABLE_CLASSNAMES.column.default,
      imageClass: TABLE_CLASSNAMES.images.currency,
      textClass: TABLE_CLASSNAMES.text.default,
    };

    return [place, user, date, game, payout];
  });

  return tableData;
};

export const filterSlider = (array: ISlider[], activeToken: boolean): ISlider[] => {
  return array.filter((s) => {
    const { isAuthenticated } = s;

    if (isAuthenticated === null) {
      return true;
    }

    return activeToken ? s.isAuthenticated : !s.isAuthenticated;
  });
};

export const getLoaderPages = (): string => {
  const number = Math.floor(Math.random() * (4 - 1 + 1)) + 1;
  switch (number) {
    case 1:
      return loader1;
    case 2:
      return loader2;
    case 3:
      return loader3;
    case 4:
      return loader4;
    default:
      return loader1;
  }
};
