import { gql } from '@apollo/client';

export const UPDATE_USER_AVATAR = gql`
  mutation updateUserAvatar($userAvatarData: UserAvatarDto!) {
    updateUserAvatar(formData: $userAvatarData) {
      avatar {
        id
        avatar
      }
    }
  }
`;
