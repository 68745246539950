import clsx from 'clsx';
import { animateScroll as scroll } from 'react-scroll';

import imagePlaceholder from 'assets/img/Blog/image-placeholder.webp';

import { Links } from 'components/constants';

import Link from 'components/Base/Links/Link';

import styles from './styles.module.scss';
import { IBlogArticlePreview } from './types';

const BlogArticlePreview: React.FC<IBlogArticlePreview> = ({ article, index }) => {
  const link = Links.blogPost.replace(':slug', article.slug);

  const handleLinkClick = () => {
    scroll.scrollTo(0, { duration: 0, delay: 0 });
  };

  return (
    <li className={clsx(styles.wrapper, index === 2 ? styles.last : '', 'animate__animated animate__fadeInLeft')}>
      <Link to={link} className={styles.imageWrapper} onClick={handleLinkClick}>
        <img
          className={styles.image}
          src={article.feature_image || imagePlaceholder}
          alt={article.feature_image_alt || article.feature_image_caption || 'placeholder'}
        />
      </Link>
      <div className={styles.contentWrapper}>
        <h4 className={styles.title}>
          <Link to={link} className={styles.titleLink} onClick={handleLinkClick}>
            {article.title}
          </Link>
        </h4>
        <p className={styles.desc}>{article.feature_image_caption}</p>
        <Link to={link} className={styles.link} onClick={handleLinkClick}>
          <span>Read more</span>
        </Link>
      </div>
    </li>
  );
};

export default BlogArticlePreview;
