import affiliateIcon from 'assets/img/PopUps/affiliateSuport.webp';
import marketingIcon from 'assets/img/PopUps/marketingSuport.webp';
import otherIcon from 'assets/img/PopUps/suportIcon.webp';

import { TranslationType } from 'types';
import { IContactInfo } from 'components/Base/PopUps/components/Contacts/types';

export const getContactsInfo = (t: TranslationType): IContactInfo[] => {
  return [
    { title: t('popups.contacts.support'), email: 'support@starbets.io', icon: otherIcon },
    { title: t('popups.contacts.affiliates'), email: 'affiliate@starbets.io', icon: affiliateIcon },
    { title: t('popups.contacts.marketing'), email: 'marketing@starbets.io', icon: marketingIcon },
  ];
};
