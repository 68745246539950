import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { CHANGELLY_CURRENCIES } from 'graphQl/query/buyCrypto';
import { formatBuyCryptoCurrencies } from 'func/prepareDataPopUps';
import { amount } from 'func/common';
import { useLocalization } from 'components/Internationalization';

import BuyCryptoSubmit from '../BuyCryptoSubmit';
import BuyCryptoInputDrop from '../BuyCryptoInputDrop';
import BuyCryptoRate from '../BuyCryptoRate';
import { BuyCryptoType } from '../../types';
import { IBuyCryptoContent, IBuyCryptoCurrency } from './types';
import styles from './styles.module.scss';

const BuyCryptoContent: React.FC<IBuyCryptoContent> = ({
  provider,
  sendAmount,
  selectedFiat,
  selectedCrypto,
  setSelectedCrypto,
  setSelectedFiat,
  setSendAmount,
}) => {
  const { translate } = useLocalization();

  const [fiatCurrencies, setFiatCurrencies] = useState<IBuyCryptoCurrency[]>([]);
  const [cryptoCurrencies, setCryptoCurrencies] = useState<IBuyCryptoCurrency[]>([]);
  const [getAmountValue, setGetAmountValue] = useState('0');
  const [disableContent, setDisableContent] = useState(false);

  const [changellyFiatCurrencies, { data }] = useLazyQuery(CHANGELLY_CURRENCIES, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (data) {
      const currencies = data.changellyFiatCurrencies;
      const fiat = formatBuyCryptoCurrencies(currencies, BuyCryptoType.fiat, selectedFiat);
      const crypto = formatBuyCryptoCurrencies(currencies, BuyCryptoType.crypto, selectedCrypto);
      const [fiatCurrency] = fiat;
      const [cryptoCurrency] = crypto;

      setFiatCurrencies(fiat);
      setCryptoCurrencies(crypto);
      if (fiatCurrency) setSelectedFiat(fiatCurrency.ticker);
      if (cryptoCurrency) setSelectedCrypto(cryptoCurrency.ticker);
    }
  }, [data]);

  useEffect(() => {
    changellyFiatCurrencies({ variables: { providerCode: provider } });
  }, [provider]);

  const handleChange = (ticker: string, type: BuyCryptoType) => {
    if (type === BuyCryptoType.fiat) {
      setSelectedFiat(ticker);
      return;
    }

    setSelectedCrypto(ticker);
  };

  return (
    <div className={styles.wrapper}>
      <BuyCryptoInputDrop
        inputTitle={translate('popups.wallet.buy.crypto.fiat.title')}
        inputValue={sendAmount}
        data={fiatCurrencies}
        dropTitle={selectedFiat}
        disableInput={false}
        disable={disableContent}
        setInputValue={setSendAmount}
        onDropChange={handleChange}
      />
      <BuyCryptoRate
        selectedCrypto={selectedCrypto}
        selectedFiat={selectedFiat}
        provider={provider}
        sendAmount={sendAmount}
        setGetAmountValue={setGetAmountValue}
      />
      <BuyCryptoInputDrop
        inputTitle={translate('popups.wallet.buy.crypto.crypto.title')}
        inputValue={String(amount(getAmountValue))}
        data={cryptoCurrencies}
        dropTitle={selectedCrypto}
        disableInput={true}
        disable={disableContent}
        setInputValue={setGetAmountValue}
        onDropChange={handleChange}
      />
      <BuyCryptoSubmit
        providerCode={provider}
        amountFrom={sendAmount}
        currencyFrom={selectedFiat}
        currencyTo={selectedCrypto}
        disable={disableContent}
        setDisable={setDisableContent}
      />
    </div>
  );
};

export default BuyCryptoContent;
