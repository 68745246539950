import clsx from 'clsx';

import { ReactComponent as Emoji } from 'assets/img/ErrorPage/emoji.svg';
import planet from 'assets/img/ErrorPage/planet.webp';
import air from 'assets/img/ErrorPage/air.webp';
import rocket from 'assets/img/ErrorPage/rocket.webp';

import { Links } from 'components/constants';

import Button from 'components/Base/Button/Button';
import { ButtonType } from 'components/Base/Button/types';

import styles from './styles.module.scss';

const CatchErrorPage: React.FC = () => {
  const handleMain = () => {
    window.open(Links.home, '_self');
  };

  const handleClearCache = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };

  return (
    <main className={styles.wrapper}>
      <div className={styles.textWrapper}>
        <h1 className={styles.title}>Oops!</h1>
        <p className={styles.desc}>Something went wrong</p>
      </div>
      <div className={styles.imageWrapper}>
        <div className={styles.planetWrapper}>
          <img className={styles.rocket} src={rocket} alt="Rocket" />
        </div>
        <img className={styles.planet} src={planet} alt="Planet" />
        <Emoji className={styles.emoji} />
        <img className={styles.air} src={air} alt="Air" />
      </div>
      <div className={styles.buttons}>
        <div className={styles.buttonsWrapper}>
          <Button
            variant={ButtonType.primary}
            width="141px"
            color="#2A3A5D"
            title="GO TO MAIN PAGE"
            onclick={handleMain}
          />
          <div className={styles.button}>
            <span className={clsx(styles.tooltip, 'animate__animated animate__fadeInUp')}>
              In some cases, the error may be related to the cache. You can clear the cache and try again.
              <br />
              Note: account will be logged out
            </span>
            <Button
              variant={ButtonType.secondary}
              width="141px"
              color="#2A3A5D"
              title="CLEAR CACHE"
              onclick={handleClearCache}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default CatchErrorPage;
