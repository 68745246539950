import clsx from 'clsx';
import { useContext } from 'react';

import statisticsIcon from 'assets/img/Dice/statistics-active.webp';

import LiveStatsContext from 'context/contextLiveStats/context';

import styles from './styles.module.scss';

const LiveStatisticsButton: React.FC = () => {
  const { enabled, visible, onToggleStatistics } = useContext(LiveStatsContext);

  const handleClick = () => {
    onToggleStatistics(true, !visible);
  };

  return enabled ? (
    <button type="button" className={clsx(styles.wrapper, visible ? styles.active : '')} onClick={handleClick}>
      <img className={styles.icon} src={statisticsIcon} alt="Statistics" />
    </button>
  ) : null;
};

export default LiveStatisticsButton;
