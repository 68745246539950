import { useContext } from 'react';

import { ContextPopUps } from 'context';
import { popUps } from 'components/constants/constants';
import { useAppSelector } from 'hooks/useAppSelector';
import { generalDataVipRank } from 'store/generalData/generalDtata.selectors';
import { checkIsVip } from 'func/common';

import Avatar from 'components/Base/Avatar';

import styles from './styles.module.scss';
import { ITableUserName } from './types';

const UserName: React.FC<ITableUserName> = ({ src, userName, rank }) => {
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const data = {
    alias: userName,
    avatar: src,
    rank,
  };

  const vipRank = useAppSelector(generalDataVipRank);

  const handleClick = () => {
    setPopUpsOpen({ modalOpen: popUps.tipsInfo, data });
  };

  return (
    <div className={styles.wrapper} onClick={handleClick}>
      <Avatar className={styles.avatar} userAvatar={src} withBorder={false} vip={checkIsVip(vipRank, Number(rank))} />
      <p className={styles.alias}>{userName}</p>
    </div>
  );
};

export default UserName;
