import styled from 'styled-components';

interface Props {
  ismultiplyvalue: string;
}

const CoinAnimationStyle = styled.div.withConfig({
  shouldForwardProp: (prop) => !['ismultiplyvalue'].includes(prop),
})<Props>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  font-family: 'Montserrat';

  .coin__animation--sides {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 24px;
    min-width: 190px;
    min-height: 120px;
    h4 {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.09px;
      //color: #888AA7;
      color: #ffffff;
      margin: 0;
    }
    h5 {
      font-weight: 600;
      font-size: 48px;
      line-height: 59px;
      letter-spacing: 0.09px;
      color: #ffffff;
      margin: 0;
    }
  }

  #coin {
    position: relative;
    width: 150px;
    height: 150px;
  }
  #coin div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .side-a {
    color: white;
    text-align: center;
  }
  .side-b {
    color: white;
    text-align: center;
  }

  #coin {
    transition: -webkit-transform 1s ease-in;
    -webkit-transform-style: preserve-3d;
  }
  #coin div {
    position: absolute;
    -webkit-backface-visibility: hidden;
  }
  .side-a {
  }
  .side-b {
    -webkit-transform: rotateX(-180deg);
  }

  #coin.tails {
    -webkit-animation: flipHeads 1.5s ease-out forwards;
    -moz-animation: flipHeads 1.5s ease-out forwards;
    -o-animation: flipHeads 1.5s ease-out forwards;
    animation: flipHeads 1.5s ease-out forwards;
    -webkit-backface-visibility: hidden;
  }
  #coin.heads {
    -webkit-animation: flipTails 1.5s ease-out forwards;
    -moz-animation: flipTails 1.5s ease-out forwards;
    -o-animation: flipTails 1.5s ease-out forwards;
    animation: flipTails 1.5s ease-out forwards;
  }

  #coin.tails-fast {
    -webkit-animation: flipHeadsFast 0.4s ease-out forwards;
    -moz-animation: flipHeadsFast 0.4s ease-out forwards;
    -o-animation: flipHeadsFast 0.4s ease-out forwards;
    animation: flipHeadsFast 0.4s ease-out forwards;
  }
  #coin.heads-fast {
    -webkit-animation: flipTailsFast 0.4s ease-out forwards;
    -moz-animation: flipTailsFast 0.4s ease-out forwards;
    -o-animation: flipTailsFast 0.4s ease-out forwards;
    animation: flipTailsFast 0.4s ease-out forwards;
  }

  @keyframes flipHeads {
    0% {
      -webkit-transform: rotateX(0);
      -moz-transform: rotateX(0);
      transform: rotateX(0) scale(1);
    }
    20% {
      transform: rotateX(360deg) scale(1.4);
    }
    70% {
      transform: rotateX(720deg) scale(1.2);
    }
    100% {
      -webkit-transform: rotateX(900deg) scale(1);
      -moz-transform: rotateX(900deg) scale(1);
      transform: rotateX(900deg) scale(1);
    }
  }
  @keyframes flipTails {
    0% {
      -webkit-transform: rotateX(0) scale(1);
      -moz-transform: rotateX(0) scale(1);
      transform: rotateX(0) scale(1);
    }
    20% {
      transform: rotateX(360deg) scale(1.4);
    }
    70% {
      transform: rotateX(900deg) scale(1.2);
    }
    100% {
      -webkit-transform: rotateX(1080deg) scale(1);
      -moz-transform: rotateX(1080deg) scale(1);
      transform: rotateX(1080deg) scale(1);
    }
  }
  @keyframes flipHeadsFast {
    0% {
      -webkit-transform: rotateX(0) scale(1);
      -moz-transform: rotateX(0) scale(1);
      transform: rotateX(0) scale(1);
    }
    100% {
      -webkit-transform: rotateX(540deg) scale(1);
      -moz-transform: rotateX(540deg) scale(1);
      transform: rotateX(540deg) scale(1);
    }
  }
  @keyframes flipTailsFast {
    0% {
      -webkit-transform: rotateX(0);
      -moz-transform: rotateX(0);
      transform: rotateX(0);
    }
    100% {
      -webkit-transform: rotateX(360deg);
      -moz-transform: rotateX(360deg);
      transform: rotateX(360deg);
    }
  }

  .coin__pump {
    width: 150px;
    height: 150px;
  }

  @media (max-width: 1400px) {
  }
  @media (max-width: 1200px) {
    justify-content: space-between;
    padding: 0 5%;
    .coin__animation--sides {
      min-width: 160px;
      min-height: 100px;
      h4 {
        font-size: 14px;
        line-height: 18px;
      }
      h5 {
        font-size: 38px;
        line-height: 42px;
      }
    }
  }
  @media (max-width: 1100px) {
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
    padding: 0 5%;
    .coin__animation--sides {
      min-width: 110px;
      min-height: 80px;
      h4 {
        font-size: 12px;
        line-height: 16px;
      }
      h5 {
        font-size: 24px;
        line-height: 32px;
      }
    }
    #coin {
      position: relative;
      width: 120px;
      height: 120px;
    }
    .coin__pump {
      width: 120px;
      height: 120px;
    }
  }
  @media (max-width: 680px) {
  }
  @media (max-width: 640px) {
    .coin__animation--sides {
      min-width: 80px;
      min-height: auto;
      background: none;
      h4 {
        font-size: 12px;
        line-height: 16px;
      }
      h5 {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
  @media (max-width: 580px) {
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 400px) {
  }
`;

export default CoinAnimationStyle;
