/* eslint-disable react/no-array-index-key */
import clsx from 'clsx';
import { useContext } from 'react';

import { ReactComponent as CrossIcon } from 'assets/img/common/cross.svg';

import BlogContext from 'context/contextBlog/context';
import { getArticlesTitle } from 'func/blog';

import Loader from 'components/Base/Loader';
import BlogItem from '../BlogItem';

import styles from './styles.module.scss';

const BlogList: React.FC = () => {
  const { articles, tag, loading, pagination, onGetArticlesByTag } = useContext(BlogContext);

  const handleClear = () => {
    onGetArticlesByTag(undefined);
  };

  return (
    <section className={styles.wrapper}>
      {loading ? (
        <div className={styles.loader}>
          <Loader pages />
        </div>
      ) : (
        <>
          <div className={styles.totalWrapper}>
            <p className={styles.total}>
              {pagination?.total || 0}
              {tag?.name ? ` ${tag.name}` : ''} {getArticlesTitle(pagination?.total)}
            </p>
            {tag ? (
              <button type="button" className={styles.reset} disabled={loading} onClick={handleClear}>
                <span className={clsx(styles.tooltip, 'animate__animated animate__fadeInUp')}>Reset Filter</span>
                <CrossIcon />
              </button>
            ) : null}
          </div>
          <div className={styles.articles}>
            {articles.map((a) => (
              <BlogItem key={a.id} article={a} />
            ))}
          </div>
        </>
      )}
    </section>
  );
};

export default BlogList;
