import { InMemoryCache, makeVar } from '@apollo/client';

const cashInitialVal = {
  isSlotsRun: false,
  isDisplayBonus: false,
};

export const settingVar = makeVar(cashInitialVal);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        setting: {
          read() {
            return settingVar();
          },
        },
      },
    },
  },
});
