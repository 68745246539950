import { useLocalization } from 'components/Internationalization';

import CopyButton from 'components/Base/CopyButton';

import styles from './styles.module.scss';
import { ITexasHoldemVerify } from './types';

const TexasHoldemVerify: React.FC<ITexasHoldemVerify> = ({ data }) => {
  const { translate } = useLocalization();
  const { hash } = data;

  return (
    <div className={styles.wrapper}>
      <h3>{translate('popups.bets.verify.title')}</h3>
      <p>{translate('popups.bets.verify.cards.fairness')}</p>
      <p>{translate('popups.bets.verify.start-round')}</p>
      <p>{translate('popups.bets.verify.end-round')}</p>
      <p className={styles.lastParagraph}>{translate('popups.bets.verify.compare')}</p>
      {hash ? (
        <div className={styles.hash}>
          <div className={styles.hashTitle}>
            <p className={styles.bold}>{translate('popups.bets.verify.hash')}:</p>
          </div>
          <div className={styles.hashContent}>
            <div className={styles.hashText}>
              <p>{hash}</p>
              <CopyButton copyContent={hash} />
            </div>
          </div>
        </div>
      ) : (
        <p className={styles.bold}>{translate('popups.bets.verify.cta')}</p>
      )}
    </div>
  );
};

export default TexasHoldemVerify;
