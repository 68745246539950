import { gql } from '@apollo/client';

export const REFERRAL_QUERIES_AUTH = gql`
  query allReferrals {
    referralCollections {
      amount
      createdAt
      token {
        tokenCode
        displayName
      }
    }
    myReferralBalances {
      availableBalance
      token {
        tokenCode
        displayName
      }
      totalCollected
    }
  }
`;
