import ReCAPTCHA from 'react-google-recaptcha';
import { useContext, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useLazyQuery } from '@apollo/client';

import emailIcon from 'assets/img/common/emailIcon.webp';

import config from 'config';
import { ContextPopUps } from 'context';
import { RESET_PASSWORD } from 'graphQl/query/auth/resetPassword';
import { popUps } from 'components/constants/constants';
import { validationEmail } from 'components/Base/Input/validation';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import InputCustom from 'components/Base/Input/InputCustom';
import ButtonSubmit from 'components/Base/PopUps/components/ButtonSubmit';

import styles from './styles.module.scss';

const { recaptchaKey } = config;

const ForgotPassword: React.FC = () => {
  const { translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [errorMessage, setErrorMessage] = useState(null);
  const [resetPassword, { loading }] = useLazyQuery(RESET_PASSWORD);

  const handleSubmit = async (values) => {
    setErrorMessage('');
    const tokenCaptcha = await recaptchaRef.current?.executeAsync();

    try {
      await resetPassword({
        variables: {
          captcha: tokenCaptcha,
          userEmail: values.email.toLowerCase(),
        },
      });
      setPopUpsOpen(popUps.passwordSuccess);
    } catch (e) {
      setErrorMessage(e.message);

      recaptchaRef.current?.reset();
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationEmail,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleCancel = () => {
    setPopUpsOpen(popUps.login);
  };

  return (
    <div className={styles.forgotWrap}>
      <div className={styles.forgotTitle}>{translate('popups.forgot-pass.title')}</div>
      <div className={styles.forgotForm}>
        <p>Email:</p>
        <InputCustom
          id="email"
          name="email"
          placeholder="Email"
          type="text"
          icon={emailIcon}
          iconClassName={styles.forgotFormEmailIcon}
          onChange={formik.handleChange}
          value={formik.values.email}
          touched={formik.touched.email}
          errors={formik.errors.email}
        />
      </div>
      <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={recaptchaKey} />
      <ButtonSubmit
        className={styles.button}
        handleButton={formik.handleSubmit}
        variant={ButtonType.secondary}
        width="288px"
        title={translate('popups.forgot-pass.action')}
        disabled={loading}
        color="#242c45"
      />
      <div className={styles.forgotCheckBoxError}>{errorMessage}</div>
      <div className={styles.forgotBtn}>
        <button type="button" className={styles.forgotBtn1} onClick={handleCancel}>
          {translate('general.buttons.cancel')}
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;
