import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

const WelcomeWrapStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;

  font-family: 'Montserrat';

  .slick-slider .slick-list {
    width: 100%;
  }

  .slick-dots {
    bottom: 25px !important;
  }

  .slick-dots > li > button {
    cursor: url(${point}), auto !important;
    width: 10px !important;
    height: 10px !important;
  }

  .slick-dots > li > button::before {
    font-size: 8px !important;
    color: #ffffff !important;
    cursor: url(${point}), auto !important;
  }

  .slick-dots > li.slick-active > button:before {
    cursor: url(${point}), auto !important;
  }

  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    width: 40px !important;
    height: 40px !important;
    cursor: url(${point}), auto !important;
    color: #ffffff !important;
    border: none;
    outline: 0;
    background: rgba(255, 255, 255, 0.24) !important;
    border-radius: 8px !important;
    &:hover {
      background: rgba(255, 255, 255, 0.4) !important;
    }
  }

  .slick-next {
    right: 25px !important;
  }

  .slick-prev {
    left: 25px !important;
    z-index: 2 !important;
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: 1;
    color: red !important;
  }
  .slick-next:before {
    content: '' !important;
    border: 1px solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    height: 0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-right: 5px;
  }
  .slick-prev:before {
    content: '' !important;
    border: 1px solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    height: 0;
    transform: rotate(-225deg);
    -webkit-transform: rotate(-225deg);
    margin-left: 5px;
  }

  @media (max-width: 1390px) {
  }
  @media (max-width: 1280px) {
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1150px) {
  }
  @media (max-width: 1080px) {
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 952px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 840px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 700px) {
    .slick-next,
    .slick-prev {
      display: none !important;
    }
    .slick-dots {
      bottom: 5px !important;
    }
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 616px) {
  }
  @media (max-width: 580px) {
  }
  @media (max-width: 530px) {
  }
  @media (max-width: 500px) {
  }
`;

export default WelcomeWrapStyles;
