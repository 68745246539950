import { ISetting } from 'types';
import { getCommonSettingsValue } from 'func/common';
import { media } from 'config';

import { WheelSetting, WheelSettings } from './types';

const ADDITIONAL_ROTATES = 1440;

export const buildWheelUrl = (style: string): string => {
  const mediaUrl = media.wheel;

  return mediaUrl + style + '/';
};

export const getWheelSettings = (settings: ISetting[]): WheelSettings => {
  const bgColor = getCommonSettingsValue(settings, WheelSetting.bgColor);
  const buttonColor = getCommonSettingsValue(settings, WheelSetting.buttonColor);
  const textColor = getCommonSettingsValue(settings, WheelSetting.textColor);
  const duration = getCommonSettingsValue(settings, WheelSetting.duration);
  const status = getCommonSettingsValue(settings, WheelSetting.status);
  const style = getCommonSettingsValue(settings, WheelSetting.style);

  return {
    wheel_background_color: bgColor,
    wheel_button_color: buttonColor,
    wheel_text_color: textColor,
    wheel_duration: duration,
    wheel_status: status,
    wheel_style: style,
  };
};

export const getWheelRotate = (weight: number) => {
  switch (weight) {
    case 8:
      return ADDITIONAL_ROTATES + 0;
    case 7:
      return ADDITIONAL_ROTATES + 315;
    case 6:
      return ADDITIONAL_ROTATES + 270;
    case 5:
      return ADDITIONAL_ROTATES + 225;
    case 4:
      return ADDITIONAL_ROTATES + 180;
    case 3:
      return ADDITIONAL_ROTATES + 135;
    case 2:
      return ADDITIONAL_ROTATES + 90;
    case 1:
      return ADDITIONAL_ROTATES + 45;
    default:
      return 0;
  }
};
