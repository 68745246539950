import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';

import prodIcon from 'assets/default/token.png';
import stageIcon from 'assets/default/token-2.png';
import developIcon from 'assets/default/token-1.png';

import config from 'config';

const { env } = config;

const DefaultMeta: React.FC = () => {
  const [icon, setIcon] = useState(prodIcon);

  useEffect(() => {
    if (env === 'stage') {
      setIcon(stageIcon);
    }

    if (env === 'develop') {
      setIcon(developIcon);
    }
  }, [env]);

  return (
    <Helmet>
      <link rel="icon" type="image/png" href={icon} />
      <link rel="apple-touch-icon" href={icon} />
      <meta property="og:image" itemProp="image" content={icon} />
      <link itemProp="thumbnailUrl" href={icon} />
      <span itemProp="thumbnail" itemScope itemType="http://schema.org/ImageObject">
        <link itemProp="url" href={icon} />
      </span>
      <meta name="msapplication-TileImage" content={icon} />
      {env !== 'develop' ? <script async src="https://dga.pragmaticplaylive.net/dgaAPI.js" /> : null}
    </Helmet>
  );
};

export default DefaultMeta;
