import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';
import { popUps } from 'components/constants/constants';

interface Props {
  fullwidth: string;
  config: string;
}

const PopUpsStyle = styled.div.withConfig({
  shouldForwardProp: (prop) => !['config'].includes(prop),
})<Props>`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  text-align: ${(props) => !props.fullwidth && 'center'};
  z-index: 200;
  justify-content: center;
  align-items: center;
  padding: 0 30px;

  animation: open 0.6s;
  transform-origin: center;

  .popUps__content {
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: ${(props) => {
      if (props.fullwidth) return '100%';
      if (props.config === popUps.deposit) return '760px';
      if (props.config === popUps.levelUp) return '760px';
      if (props.config === popUps.wheelFortune) return '780px';
      if (props.config === popUps.missionsInfo) return '540px';
      if (props.config === popUps.login) return '800px';
      if (props.config === popUps.registration) return '800px';
      if (props.config === popUps.texasHoldemRules) return '320px';
      if (props.config === popUps.depositChanged) return '360px';
      return '460px';
    }};
    max-width: 1400px;
    padding: ${(props) => {
      if (props.config === popUps.deposit) return '0';
      if (props.config === popUps.wheelFortune) return '0';
      if (props.config === popUps.contacts) return '0';
      if (props.config === popUps.supportInfo) return '0';
      if (props.config === popUps.missionsInfo) return '0';
      if (props.config === popUps.levelUp) return '0';
      if (props.config === popUps.bonusLogin) return '0';
      if (props.config === popUps.bonusCancel) return '0';
      if (props.config === popUps.bonusCollect) return '0';
      if (props.config === popUps.login) return '0';
      if (props.config === popUps.registration) return '0';
      if (props.config === popUps.rakebackCollect) return '0';
      if (props.config === popUps.rakebackInfo) return '0';
      if (props.config === popUps.depositChanged) return '0';

      return '20px 0 20px 0';
    }};
    background: ${(props) => {
      if (props.config === popUps.deposit) return 'transparent';
      if (props.config === popUps.wheelFortune) return 'transparent';
      if (props.config === popUps.contacts) return 'transparent';
      if (props.config === popUps.supportInfo) return 'transparent';
      if (props.config === popUps.levelUp) return 'transparent';
      if (props.config === popUps.bonusLogin) return 'linear-gradient(180deg, #0E1C3D 0%, #0A142D 100%)';
      if (props.config === popUps.bonusCancel) return 'transparent';
      return '#242c45';
    }};
    border-radius: 20px;
    z-index: 300;
    position: relative;
    display: block;
    overflow-y: ${(props) => {
      if (props.config === popUps.wheelFortune) return 'visible';
      return 'auto';
    }};
    -ms-overflow-style: none;
    margin: 10px 0;

    height: ${(props) => {
      if (props.config === popUps.texasHoldemRules) return '80%';
      return 'auto';
    }};
  }

  @keyframes open {
    0% {
      transform: scale(0, 0);
      height: 100%;
    }

    100% {
      transform: scale(1, 1);
    }
  }
  .popUps__content::-webkit-scrollbar {
    width: 0;
  }
  @-moz-document url-prefix() {
    /* Disable scrollbar Firefox */
    .popUps__content {
      scrollbar-width: none;
    }
  }

  .popUps__btn {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 8;
    filter: brightness(2);
  }
  .popUps__close {
    width: 12px;
    height: 12px;
    cursor: url(${point}), auto !important;
  }
  svg.popUps__close {
    margin-top: 3px;
    width: 24px;
    height: auto;
    color: white;
    opacity: 20%;
  }
  .popUps__line {
    width: ${(props) => {
      if (props.fullwidth) return '95%';
      if (props.config === popUps.deposit) return '0%';
      if (props.config === popUps.wheelFortune) return '0%';
      return '86%';
    }};
    position: absolute;
    top: 0;
    left: 30px;
    height: 3.5px;
    border-radius: 2.5px;
    background: ${(props) =>
      props.config === popUps.deposit ? 'transparent' : 'linear-gradient(90deg, #7a2cff 0%, #3757dd 100%)'};
  }

  .popUps__blur {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }

  @media (max-width: 1100px) {
    .popUps__content {
      width: ${(props) => {
        if (props.fullwidth) return '100%';
        if (props.config === popUps.deposit) return '760px';
        if (props.config === popUps.wheelFortune) return '460px';
        return '460px';
      }};
      overflow-y: auto;
    }
  }

  @media (max-width: 800px) {
    .popUps__content {
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      width: ${(props) => {
        if (props.fullwidth) return '100%';
        if (props.config === popUps.deposit) return '460px';
        return '460px';
      }};
    }
  }

  @media (max-width: 640px) {
    justify-content: flex-start;
  }
  @media (max-width: 540px) {
    padding: 0;
    .popUps__content {
      margin-top: ${(props) => (props.fullwidth ? '10px' : '20px')};
      width: 350px;
    }
    .popUps__line {
      width: ${(props) => {
        if (props.config === popUps.deposit) return '0%';
        if (props.config === popUps.wheelFortune) return '0%';
        return '288px';
      }};
    }
  }
`;

export default PopUpsStyle;
