import styles from './styles.module.scss';
import { IBuyCryptoRateLayout } from './types';

const BuyCryptoRateLayout: React.FC<IBuyCryptoRateLayout> = ({ selectedCrypto, selectedFiat, rate, errorMessage }) => {
  return (
    <div className={styles.wrapper}>
      {errorMessage ? <h6>{errorMessage}</h6> : <p>{`1 ${selectedCrypto} ~ ${rate} ${selectedFiat}`}</p>}
    </div>
  );
};

export default BuyCryptoRateLayout;
