import styled from 'styled-components';

export const SecurityCodeSuccessContainer = styled.div`
  font-family: 'Montserrat';
  padding: 25px 0;
  color: #fff;
  margin: auto;
  max-width: 288px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .securityCode__title {
    margin-bottom: 35px;
    font-weight: 800;
    font-size: 36px;
  }
  .securityCode__img {
    max-width: 300px;
    max-height: 300px;
  }
  .securityCode__subTitle {
    margin-bottom: 30px;
    max-width: 270px;
    font-weight: 300;
    font-size: 14px;
    text-align: center;
  }
  @media screen and (max-width: 540px) {
    padding: 25px 25px 15px 25px;
  }
`;
