import { useContext, useEffect, useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import clsx from 'clsx';

import SocketContext from 'context/contextSocket/context';
import { IUserWallet, SocketActions } from 'types';
import { settingVar } from 'cache';
import { amount, getImgSrc } from 'func/common';
import { getDisplayName } from 'func/prepareDataHeader';
import { GET_ALL_WALLETS } from 'graphQl/query/wallet/wallet';
import { ContextTokenCode, ContextWallet } from 'context';
import { ISocketBalanceUpdate } from 'context/contextSocket/types';
import { getWalletTitle } from 'func/wallet';

import styles from './styles.module.scss';

const CurrencyTooltip: React.FC = () => {
  const { tokenCode, setTokenCode } = useContext(ContextTokenCode);
  const { walletUser } = useContext(ContextWallet);
  const { socket, connected: socketConnected } = useContext(SocketContext);

  const [open, setOpen] = useState(false);
  const [dropDownInfo, setDropDownInfo] = useState(null);
  const [title, setTitle] = useState(null);

  const setting = useReactiveVar(settingVar);

  const getWalletListWithTitle = (wallets) => {
    const updatedWallets = [...wallets].sort((a, b) => (a.tokenCode === tokenCode.token ? -1 : 1));
    setDropDownInfo(updatedWallets);
    setTitle(updatedWallets[0]);
  };

  const { data: walletsResponse, error: walletsError } = useQuery(GET_ALL_WALLETS, { fetchPolicy: 'cache-only' });

  useEffect(() => {
    if (walletsResponse?.wallets) {
      const prepareData = walletsResponse.wallets.map((wallet: IUserWallet) =>
        prepareDropDownItem(
          wallet.availableBalance,
          wallet.token.tokenCode,
          wallet.token.displayName,
          wallet.token.name,
        ),
      );
      getWalletListWithTitle(prepareData);
    }
    if (walletsError) {
      // eslint-disable-next-line no-console
      console.log('[GET_ALL_WALLETS_ERROR]: ', walletsError);
    }
  }, [walletsResponse, walletsError]);

  useEffect(() => {
    if (socketConnected) {
      socket?.on(SocketActions.balance, (data) => handleSocketBalanceUpdate(data));
    }
  }, [socketConnected]);

  useEffect(() => {
    if (dropDownInfo) {
      getWalletListWithTitle(dropDownInfo);
    }
  }, [tokenCode.token]);

  const updateBalance = (wallets) => {
    setDropDownInfo((prev) =>
      prev.map((value) => {
        const updatedWallet = wallets.find((wallet) => {
          return wallet.tokenCode === value.tokenCode;
        });
        return updatedWallet
          ? {
              ...updatedWallet,
              displayName: value.displayName,
              subTitle: value.subTitle,
            }
          : value;
      }),
    );
  };

  function handleSocketBalanceUpdate(data: ISocketBalanceUpdate) {
    const balance = Object.entries(data);
    const preparedData = balance.map(([walletToken, walletAmount]) => {
      return prepareDropDownItem(walletAmount, walletToken);
    });

    if (preparedData?.length) {
      updateBalance(preparedData);
    }
  }

  function prepareDropDownItem(balance: string, walletTokenCode: string, displayName?: string, walletName?: string) {
    return {
      title: amount(balance),
      subTitle: walletName,
      img: getImgSrc(displayName || getDisplayName(walletTokenCode)),
      tokenCode: walletTokenCode,
      displayName: displayName || getDisplayName(walletTokenCode),
      checked: tokenCode.token === walletTokenCode,
      func: () => {
        setTokenCode({
          token: walletTokenCode,
          name: displayName || getDisplayName(walletTokenCode),
        });
        localStorage.setItem('tokenCode', walletTokenCode);
        localStorage.setItem('displayName', displayName || getDisplayName(walletTokenCode));
      },
    };
  }

  function handleOpen() {
    setOpen(!open);
  }

  const handleBlur = (event) => {
    if (event.relatedTarget?.tabIndex >= 10000) return;
    setOpen(false);
  };

  const handleClick = (elem) => {
    setTitle(elem);
    setOpen(false);
  };

  if (!dropDownInfo) {
    return null;
  }

  return (
    <div className={styles.currencyWrap}>
      <div tabIndex={0} className={styles.currencyHeader} onClick={handleOpen} onBlur={handleBlur}>
        <div className={styles.currencyBodyItemTitle} key={title?.title}>
          <img src={title?.img} alt="icon" className={styles.currencyBodyIconTitle} />
          <p key={walletUser}>{getWalletTitle(setting.isSlotsRun, title?.tokenCode, tokenCode.token, walletUser)}</p>
        </div>
        <i className={clsx(styles.currencyArrow, open ? styles.up__currency : styles.down__currency)} />
      </div>
      <div className={clsx(styles.currencyBody, open ? styles.open : '')}>
        {dropDownInfo.map((elem, index: number) => (
          <div
            tabIndex={index + 10000}
            className={styles.currencyBodyItem}
            key={`${elem.title}${elem.tokenCode}`}
            onClick={() => {
              elem?.func();
              handleClick(elem);
            }}
          >
            <div className={styles.currencyBodyItemLeftWrap}>
              <img src={elem?.img} alt="icon" className={styles.currencyBodyIcon} />
              <div className={styles.currencyBodyItemLeftAmount}>
                <p className={styles.currencyBodyItemAmount}>{elem.displayName}</p>
                <p className={styles.currencyBodyItemAmountName}>{elem.subTitle}</p>
              </div>
            </div>
            <div className={styles.currencyBodyItemRightWrap}>
              <p className={styles.currencyBodyItemRightTitle}>{elem.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CurrencyTooltip;
