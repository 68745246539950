import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { useAppSelector } from 'hooks/useAppSelector';
import { ContextTokenCode } from 'context';
import { SettingName } from 'components/constants/constants';
import { amount, getCommonSettingsValue } from 'func/common';
import { GET_STAR_TOKENS } from 'graphQl/query/dividends/starTokens';
import { GET_USER } from 'graphQl/query/auth/profile';
import { GET_MINING_STAGE, GET_TOKEN_MINING_PRICES } from 'graphQl/query/dividends/miningStage';
import { GET_COMMON_SETTINGS } from 'graphQl/query/settings/bonusSettings';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';

import HighCardTitle from 'components/Base/Cards/HighCardTitle';
import InputRangeCustom from 'components/Base/Input/InputRangeCustom';
import { getTokenCodeAcceptStar } from 'func/prepareDataDividends';

import { IPriceForStar } from './types';
import styles from './styles.module.scss';

const PriceForStar: React.FC<IPriceForStar> = ({ isFlipped, setIsFlipped }) => {
  const token = useAppSelector(userToken);
  const { translate, language } = useLocalization();

  const { tokenCode } = useContext(ContextTokenCode);

  const [starToken, setStarToken] = useState(null);
  const [stage, setStage] = useState(null);
  const [tokenPriceData, setTokenPriceData] = useState(null);
  const [miningPrice, setMiningPrice] = useState('0');
  const [inputValue, setInputValue] = useState('');
  const [nextStageAmount, setNextStageAmount] = useState(0);
  const [displayDiscount, setDisplayDiscount] = useState('');

  const [description, setDescription] = useState('');

  const { data: dataMiningStage } = useQuery(GET_MINING_STAGE, {
    fetchPolicy: 'cache-only',
  });

  const { data: dataStarTokens } = useQuery(GET_STAR_TOKENS, {
    fetchPolicy: 'cache-only',
  });

  const { data: dataPrice } = useQuery(GET_TOKEN_MINING_PRICES, {
    fetchPolicy: 'cache-only',
  });

  const { data: dataSettings } = useQuery(GET_COMMON_SETTINGS, {
    fetchPolicy: 'cache-only',
  });

  const { data: user } = useQuery(GET_USER, { fetchPolicy: 'cache-only' });

  const handleChange = () => setInputValue(inputValue);

  const handleFlip = () => setIsFlipped(!isFlipped);

  useEffect(() => {
    if (dataMiningStage) {
      setStage(dataMiningStage.miningStage);
    }
    if (dataStarTokens) {
      setStarToken(dataStarTokens.starToken);
    }
    if (dataPrice) {
      setTokenPriceData(dataPrice.tokenMiningPrices);
    }
    if (dataSettings) {
      const discount = getCommonSettingsValue(dataSettings.getSettings, SettingName.miningDiscount);
      setDisplayDiscount(discount);
    }
  }, [dataMiningStage, dataStarTokens, dataPrice, dataSettings]);

  useEffect(() => {
    if (tokenPriceData && tokenCode) {
      const mining = tokenPriceData.find((elem) => elem.tokenCode === getTokenCodeAcceptStar(token, tokenCode.token));
      setMiningPrice(mining?.miningPrice || '0');
    }
  }, [tokenCode, token, tokenPriceData]);

  useEffect(() => {
    setInputValue(String(((Number(starToken?.totalMined) - Number(stage?.rangeStart)) / 100000000) * 100));
    if (starToken && stage?.rangeStart) {
      setNextStageAmount(starToken?.totalMined - stage?.rangeStart);
    }
  }, [starToken, user, stage]);

  useEffect(() => {
    const descriptionText = translate('dividends.mining.description');
    const newDescription = descriptionText.replace(':token', getTokenCodeAcceptStar(token, tokenCode.name));

    setDescription(newDescription);
  }, [language, tokenCode, token]);

  return (
    <HighCardTitle title={translate('dividends.mining.title')} subtitle={description} offBlur>
      <button className={styles.miningButton} type="button" onClick={handleFlip}>
        {translate('dividends.mining.flip.button.front')}
      </button>
      <div className={styles.miningFrontBody}>
        {displayDiscount && Number(displayDiscount) > 0 && (
          <div className={styles.miningDiscount}>
            <div className={styles.miningDiscountContent}>
              <p>{`${displayDiscount}% Off`}</p>
            </div>
          </div>
        )}
        <div className={styles.miningBodyElem}>
          <code>{translate('dividends.mining.table.title')}</code>
          <div />
          <code>{String(amount(Number(miningPrice) * 2)) + ` ${getTokenCodeAcceptStar(token, tokenCode.name)}`}</code>
        </div>
        <div className={styles.miningBodyElem}>
          <code>{translate('dividends.mining.games.title')}</code>
          <div />
          <code>{String(amount(miningPrice)) + ` ${getTokenCodeAcceptStar(token, tokenCode.name)}`}</code>
        </div>
        <div className={styles.miningBodyElem1}>
          <div className={styles.miningBodyItem}>
            <p>{translate('dividends.mining.next')}</p>
          </div>
          <div className={styles.miningBodyItem2}>
            <InputRangeCustom inputValue={inputValue} inputWidth="100%" handleSetValue={handleChange} />
          </div>
          <div className={styles.miningBodyItem}>
            <h6>{starToken?.totalMined && Math.trunc(Number(nextStageAmount)).toLocaleString()}</h6>
            <h5> / 100,000,000</h5>
          </div>
        </div>
      </div>
    </HighCardTitle>
  );
};

export default PriceForStar;
