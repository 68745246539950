import { useContext } from 'react';
import dayjs from 'dayjs';
import { animateScroll as scroll } from 'react-scroll';

import TournamentContext from 'context/contextTournaments/context';
import { getTournamentDate } from 'func/prepareDataTournaments';
import { setGameName } from 'func/prepareDataGamesPage';
import { useNavigate } from 'hooks/useNavigate';

import TournamentCard from '../TournamentCard';

const TournamentsCardsList: React.FC = () => {
  const navigate = useNavigate();
  const { tournamentsList } = useContext(TournamentContext);

  const handleShowMore = (name) => {
    navigate(`/tournament/${setGameName(name)}`);
    scroll.scrollTo(0, { duration: 0, delay: 0 });
  };

  return (
    <>
      {tournamentsList.map((card) => (
        <TournamentCard
          key={card.id}
          prize={card.prize}
          name={card.name}
          date={getTournamentDate(card.startAt, card.endAt)}
          isActive={dayjs().isBefore(card.endAt)}
          onShowMore={() => handleShowMore(card.name)}
          image={card.mobBanner}
          startAt={card.startAt}
          endAt={card.endAt}
        />
      ))}
    </>
  );
};

export default TournamentsCardsList;
