import clsx from 'clsx';

import notAvailableIcon from 'assets/img/PopUps/BuyCrypto/no-country.webp';

import { IBuyCryptoNotAvailableLayout } from './types';
import styles from './styles.module.scss';

const BuyCryptoNotAvailableLayout: React.FC<IBuyCryptoNotAvailableLayout> = ({
  countries,
  isOpen,
  onOpen,
  onBlur,
  titleText,
  subtitleText,
  dropText,
}) => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{titleText}</p>
      <p className={styles.title}>{subtitleText}</p>

      <div className={styles.dropWrap}>
        <div tabIndex={0} className={styles.dropHeader} onClick={onOpen} onBlur={onBlur}>
          {dropText}
        </div>
        <div className={clsx(styles.dropBody, isOpen ? styles.dropBodyOpen : '')}>
          <div className={styles.dropBodyContent}>
            {countries.map((c) => (
              <div key={c.name} className={styles.dropBodyItem}>
                {c.name}
              </div>
            ))}
          </div>
        </div>
      </div>

      <img src={notAvailableIcon} alt="country" className={styles.bodyImage} />
    </div>
  );
};

export default BuyCryptoNotAvailableLayout;
