import { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import diceRoll from 'assets/img/Dice/dice.webp';

import LiveStatsContext from 'context/contextLiveStats/context';

import LiveStatistics from 'components/Base/LiveStatistics';
import DiceGameLuckyNumber from '../DiceGameLuckyNumber';
import DiceGameMode from '../DiceGameMode';
import DiceGameSlider from '../DiceGameSlider';

import styles from './styles.module.scss';

const DiceGamePrediction: React.FC = () => {
  const { enabled, visible } = useContext(LiveStatsContext);

  const isMobile = useMediaQuery({ query: '(max-width: 1260px)' });

  return (
    <section className={styles.wrapper}>
      <div className={styles.info}>
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={diceRoll} alt="Dice" />
        </div>
        <DiceGameLuckyNumber />
      </div>
      <DiceGameSlider />
      <DiceGameMode />
      {isMobile ? enabled && visible ? <LiveStatistics mobile={isMobile} /> : null : null}
    </section>
  );
};

export default DiceGamePrediction;
