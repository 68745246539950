import { pathOr } from 'ramda';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { ISlider } from 'types';
import { GET_SLIDERS_LIST } from 'graphQl/query/homePage/sliders';
import { filterSlider } from 'func/prepareDataHomePage';
import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile } from 'store/user/user.selectors';
import { UserType } from 'components/constants/constants';
import { DataLayerEvent } from 'types/dataLayerTypes';
import { useAnalyticsDispatch } from 'hooks/useAnalyticsDispatch';

import Slider from 'components/Base/Slider';
import WelcomeSlide from '../WelcomeSlide';
import GamesNavigationItem from './components/NavigationIcons/GamesNavigationItem';
import DividendsNavigationItem from './components/NavigationIcons/DividendsNavigationItem';
import CashbackNavigationItem from './components/NavigationIcons/CashbackNavigationItem';
import ReferralNavigationItem from './components/NavigationIcons/ReferralNavigationItem';
import AffiliateNavigationItem from './components/NavigationIcons/AffiliateNavigationItem';

import WelcomeWrapStyles from './styles';

import styles from './styles.module.scss';

const WelcomeBlock: React.FC = () => {
  const [slides, setSlides] = useState<ISlider[]>([]);

  const user = useAppSelector(userProfile);
  const dataLayerDispatch = useAnalyticsDispatch();

  const { data: dataSliders } = useQuery(GET_SLIDERS_LIST, {
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    if (dataSliders) {
      const slidesData = pathOr<ISlider[]>([], ['activeSliders'], dataSliders);
      const newSlides = filterSlider(slidesData, Boolean(user));

      setSlides(newSlides);
    }
  }, [dataSliders]);

  const handleViewSlide = (slide: number) => {
    const currentSlide = slides.find((_, i) => i === slide);

    if (currentSlide) {
      dataLayerDispatch({
        event: DataLayerEvent.promotionViewed,
        user_id: user?.id,
        content_type: currentSlide.deskImage,
        content_type_id: currentSlide.id,
      });
    }
  };

  const handleClickSlide = (id: string, image: string) => {
    dataLayerDispatch({
      event: DataLayerEvent.promotionClicked,
      user_id: user?.id,
      content_type: image,
      content_type_id: id,
    });
  };

  return (
    <WelcomeWrapStyles>
      {slides.length ? (
        <Slider className={styles.slider} autoPlay showIndicators showArrows stopOnHover onChange={handleViewSlide}>
          {slides.map((s) => (
            <WelcomeSlide key={s.id} slide={s} onClick={handleClickSlide} />
          ))}
        </Slider>
      ) : null}
      <div className={styles.welcomeNavWrap}>
        <GamesNavigationItem />
        <DividendsNavigationItem />
        <CashbackNavigationItem />
        {user?.type === UserType.affiliate ? <AffiliateNavigationItem /> : <ReferralNavigationItem />}
      </div>
    </WelcomeWrapStyles>
  );
};

export default WelcomeBlock;
