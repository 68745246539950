import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { useQuery } from '@apollo/client';

import burgerLogo from 'assets/img/common/logo.webp';
import logOutIcon from 'assets/img/common/logoutMobile.svg';

import { useAppSelector } from 'hooks/useAppSelector';
import { GET_COMMON_SETTINGS } from 'graphQl/query/settings/bonusSettings';
import { GET_MISSION_ROUND } from 'graphQl/query/missions';
import { getBurgerMenuData, getBurgerMenuDataAuth } from 'components/constants/nav';
import { Links } from 'components/constants';
import { SettingName } from 'components/constants/constants';
import { getCommonSettingsValue } from 'func/common';
import { media } from 'config';
import { userProfile, userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';
import { useSession } from 'context/contextSessionManagement/context';

import SelectLanguage from 'components/Sidebar/components/Language';
import NavLink from 'components/Base/Links/NavLink';
import BurgerNavElem from './components/BurgerNavElem';
import BurgerInfoUser from './components/BurgerInfoUser';
import BurgerActivities from './components/BurgerActivities';

import styles from './styles.module.scss';

const BurgerMenu: React.FC = () => {
  const { translate, language } = useLocalization();
  const { logout } = useSession();

  const [menuOpen, setMenuOpen] = useState(false);
  const [logoIcon, setLogoIcon] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [menuItemsAuth, setMenuItemsAuth] = useState([]);

  const token = useAppSelector(userToken);
  const user = useAppSelector(userProfile);

  const { data: missionRoundData } = useQuery(GET_MISSION_ROUND, {
    fetchPolicy: 'cache-only',
  });

  const { data: settingsData } = useQuery(GET_COMMON_SETTINGS, {
    fetchPolicy: 'cache-only',
  });

  const handleNav = () => setMenuOpen(!menuOpen);

  const checkMenuCondition = (link: Links | string) => {
    if (link === Links.missions) {
      return Boolean(missionRoundData);
    }

    return true;
  };

  const handleMenu = () => {
    setMenuOpen(!menuOpen);
    scroll.scrollTo(0, { duration: 0, delay: 0 });
  };

  useEffect(() => {
    const items = getBurgerMenuData(translate);
    const itemsAuth = getBurgerMenuDataAuth(translate);

    setMenuItems(items);
    setMenuItemsAuth(itemsAuth);
  }, [language]);

  useEffect(() => {
    if (settingsData) {
      const logoDB = getCommonSettingsValue(settingsData.getSettings, SettingName.logo);
      setLogoIcon(logoDB ? `${media.logo}${logoDB}` : burgerLogo);
    }
  }, [settingsData]);

  useEffect(() => {
    document.body.style.setProperty('overflow', menuOpen ? 'hidden' : 'unset');
  }, [menuOpen]);

  return (
    <div className={styles.burgerWrap}>
      <nav>
        <div className={styles.burgerBtn} onClick={handleNav} />
      </nav>
      <div className={clsx(styles.burgerMenu, menuOpen ? styles.open : '')}>
        <div className={styles.burgerMenuLogoWrap}>
          <NavLink to={Links.home}>
            <img src={logoIcon} alt="logo" className={styles.burgerMenuLogo} onClick={handleMenu} />
          </NavLink>
          <div className={styles.burgerBtnOpen} onClick={handleNav} />
        </div>
        <BurgerInfoUser user={user} onSelectMenu={handleMenu} />
        <BurgerActivities menuOpen={menuOpen} onOpenMenu={setMenuOpen} />
        <div className={styles.burgerMenuContent}>
          {menuItems.map((elem) => {
            let passCondition = !elem.withCondition;

            if (!passCondition) {
              passCondition = checkMenuCondition(elem.link);
            }

            return passCondition ? (
              <BurgerNavElem
                title={elem.title}
                link={elem.link}
                src={elem.src}
                onClick={handleMenu}
                user={user}
                key={elem.title}
              />
            ) : null;
          })}
        </div>
        {token && (
          <>
            <div className={styles.burgerMenuContentAuth}>
              {menuItemsAuth.map((elem) => (
                <BurgerNavElem
                  title={elem.title}
                  link={elem.link}
                  src={elem.src}
                  onClick={handleMenu}
                  key={elem.title}
                />
              ))}
              <div className={styles.burgerMenuElem} onClick={() => logout()} key="log-out">
                <img src={logOutIcon} alt="icon" className={styles.burgerMenuLogOut} />
                <h3 className={styles.burgerMenuTitle}>{translate('nav.logout')}</h3>
              </div>
            </div>
          </>
        )}
        <div className={clsx(styles.languageWrap, token ? styles.auth : '')}>
          <SelectLanguage />
        </div>
      </div>
    </div>
  );
};

export default BurgerMenu;
