import { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import hotkeysIcon from 'assets/img/Keno/hotkeys-keno.webp';

import { ContextPopUps } from 'context';
import LiveStatsContext from 'context/contextLiveStats/context';
import { useKeno } from 'context/contextKeno/contextKeno';
import { Types } from 'context/contextKeno/kenoReduser';
import { controlDataKeysKeno, EGamesButtonsBar, EGamesNames } from 'components/constants/games';
import { popUps } from 'components/constants/constants';
import { useLocalization } from 'components/Internationalization';

import GameOptionsButtonsNew from 'components/Games/base/GameOptionsButtonsNew/GemeOptionsButtonsNew';

const KenoNavigationButtons: React.FC = () => {
  const { translate } = useLocalization();

  const [state, dispatch] = useKeno();

  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { enabled, onToggleStatistics } = useContext(LiveStatsContext);

  const isMobile = useMediaQuery({ query: '(max-width: 1260px)' });

  const { fastMode, isHotkeys, gameId } = state;

  const handleToggleStats = () => {
    onToggleStatistics(!enabled, isMobile ? false : undefined);
  };

  const handleFastMode = () => dispatch({ type: Types.FAST_MODE });

  const handleHotKey = () => dispatch({ type: Types.HOTKEYS });

  const handleSwitchHotKey = () =>
    setPopUpsOpen({
      modalOpen: popUps.hotKeys,
      data: {
        onSwitch: handleHotKey,
        isHotKeys: isHotkeys,
        controlKeyData: controlDataKeysKeno,
        keysImage: hotkeysIcon,
      },
    });

  const handleLimits = () => setPopUpsOpen({ modalOpen: popUps.gamesLimits, data: { gameId } });

  const handleRules = () => setPopUpsOpen({ modalOpen: popUps.gamesRules, data: { gameName: EGamesNames.keno } });

  const handleVerify = () => {
    setPopUpsOpen(popUps.kenoVerify);
  };

  const dataButtons = [
    {
      toolTipText: translate('inHouse.tooltip.live'),
      activeImg: enabled,
      func: handleToggleStats,
      id: EGamesButtonsBar.statistic,
    },
    {
      toolTipText: translate('inHouse.tooltip.speed'),
      activeImg: fastMode,
      func: handleFastMode,
      id: EGamesButtonsBar.speed,
    },
    {
      toolTipText: translate('inHouse.tooltip.verify'),
      activeImg: false,
      func: handleVerify,
      id: EGamesButtonsBar.verify,
    },
    {
      toolTipText: translate('inHouse.tooltip.limits'),
      activeImg: false,
      func: handleLimits,
      id: EGamesButtonsBar.limits,
    },
    {
      toolTipText: translate('inHouse.tooltip.rules'),
      activeImg: false,
      func: handleRules,
      id: EGamesButtonsBar.rules,
    },
    {
      toolTipText: translate('inHouse.tooltip.keys'),
      activeImg: isHotkeys,
      func: handleSwitchHotKey,
      id: EGamesButtonsBar.keys,
    },
  ];

  return <GameOptionsButtonsNew dataButtons={dataButtons} />;
};

export default KenoNavigationButtons;
