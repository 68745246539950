import { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { pathOr } from 'ramda';

import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { userProfile, userToken } from 'store/user/user.selectors';
import { dataGames } from 'store/data/data.selectors';
import { generalDataTournamentsList } from 'store/generalData/generalDtata.selectors';
import { setGames } from 'store/data';
import { GET_GAMES } from 'graphQl/query/games/gameQueries';
import { TournamentType } from 'components/constants/pages';
import { getFormattedGames, getGameName } from 'func/prepareDataGamesPage';
import { GAMES_REQUEST_STEP } from 'components/constants/games';
import { ITournament } from 'types/requestTypes';
import { useLocalization } from 'components/Internationalization';
import { useNavigate } from 'hooks/useNavigate';
import TournamentContext from './context';
import { ContextPopUps } from '../contextPopups';

const TournamentProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams<{ tournamentId: string } & { eventName: string }>();
  const { translate } = useLocalization();

  const [tournament, setTournament] = useState<ITournament>(null);
  const [fullList, setFullList] = useState<ITournament[]>(null);
  const [tournaments, setTournaments] = useState<ITournament[]>([]);

  const [tournamentGames, setTournamentGames] = useState([]);
  const [offSet, setOffset] = useState(0);
  const [countLoad, setCountLoad] = useState(1);
  const [itemsCount, setItemsCount] = useState(0);

  const user = useAppSelector(userProfile);
  const token = useAppSelector(userToken);
  const gamesList = useAppSelector(dataGames);
  const tournamentsList = useAppSelector(generalDataTournamentsList);

  const [getGames] = useLazyQuery(GET_GAMES, { fetchPolicy: 'no-cache' });

  const handleGetGames = async (offset: number, limit: number, tournamentId: string) => {
    const gameListParams = {
      offset,
      limit,
      tournamentId,
      userId: user?.id,
    };
    const response = await getGames({ variables: { gameListParams } });
    const newGames = pathOr([], ['data', 'gameList', 'items'], response);
    const games = getFormattedGames(newGames, navigate, setPopUpsOpen, translate);
    const prevGames = offset ? gamesList : [];

    dispatch(setGames([...prevGames, ...games]));

    const count = pathOr(0, ['data', 'gameList', 'count'], response);
    setItemsCount(count);
  };

  const handleLoadMoreGames = () => {
    setTournamentGames(gamesList.filter((_, index) => index < offSet));
    handleGetGames(offSet, GAMES_REQUEST_STEP, tournament.id);

    setOffset(offSet + GAMES_REQUEST_STEP);
    setCountLoad(countLoad + 1);
  };

  useEffect(() => {
    if (tournamentsList) {
      setFullList(tournamentsList);
      setTournaments(tournamentsList.filter((el) => el.type === TournamentType.tournament));
    }
  }, [tournamentsList]);

  useEffect(() => {
    if (params?.tournamentId) {
      const currentName = getGameName(params.tournamentId);
      const currentTournament = tournaments.find((el) => getGameName(el.name.toLowerCase()) === currentName);
      setTournament(currentTournament);
    }
    if (params?.eventName && fullList) {
      const currentName = getGameName(params.eventName);
      const specialEv = fullList.find((el) => getGameName(el.name.toLowerCase()) === currentName);
      setTournament(specialEv);
    }
  }, [params, tournaments, fullList]);

  useEffect(() => {
    if (tournament && token && user) {
      dispatch(setGames([]));
      setCountLoad(1);
      handleGetGames(0, GAMES_REQUEST_STEP * 2, tournament.id);
      setOffset(GAMES_REQUEST_STEP * 2);
    }
    if (tournament && !token) {
      dispatch(setGames([]));
      setCountLoad(1);
      handleGetGames(0, GAMES_REQUEST_STEP * 2, tournament.id);
      setOffset(GAMES_REQUEST_STEP * 2);
    }
  }, [tournament, token, user]);

  useEffect(() => {
    if (gamesList?.length && countLoad === 1) {
      setTournamentGames(gamesList.filter((_, index) => index < GAMES_REQUEST_STEP));
    }
  }, [gamesList, countLoad]);

  return (
    <TournamentContext.Provider
      value={{
        tournamentGames,
        tournament,
        tournamentsList: tournaments,
        isLoadingTournaments: false,
        itemsCount,
        onLoadMoreGames: handleLoadMoreGames,
      }}
    >
      {children}
    </TournamentContext.Provider>
  );
};

export default TournamentProvider;
