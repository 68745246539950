import { useContext } from 'react';
import clsx from 'clsx';

import { DiceBetMode } from 'types';
import DiceGameContext from 'context/contextDiceGame/context';
import SocketContext from 'context/contextSocket/context';
import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';

const DiceGameMode: React.FC = () => {
  const { translate } = useLocalization();
  const { connected: socketConnected } = useContext(SocketContext);
  const { autoStarted, betInProgress, mode, onChangeMode } = useContext(DiceGameContext);

  return (
    <div className={styles.wrapper}>
      <button
        type="button"
        disabled={autoStarted || betInProgress || !socketConnected}
        className={clsx(styles.button, mode === DiceBetMode.under ? styles.active : '')}
        onClick={() => onChangeMode(DiceBetMode.under)}
      >
        {translate('dice.roll.under.text')}
      </button>
      <button
        type="button"
        disabled={autoStarted || betInProgress || !socketConnected}
        className={clsx(styles.button, mode === DiceBetMode.over ? styles.active : '')}
        onClick={() => onChangeMode(DiceBetMode.over)}
      >
        {translate('dice.roll.over.text')}
      </button>
    </div>
  );
};

export default DiceGameMode;
