import { useContext } from 'react';

import startIcon from 'assets/img/header/starCoin.webp';

import { ContextWallet } from 'context';
import { amount, checkIsVip } from 'func/common';
import { useAppSelector } from 'hooks/useAppSelector';
import { generalDataVipRank } from 'store/generalData/generalDtata.selectors';

import Avatar from 'components/Base/Avatar';
import BurgerNotification from '../BurgerNotification';

import { IBurgerInfoUser } from './types';
import styles from './styles.module.scss';

const BurgerInfoUser: React.FC<IBurgerInfoUser> = ({ user, onSelectMenu }) => {
  const { walletUserStar } = useContext(ContextWallet);

  const vipRank = useAppSelector(generalDataVipRank);

  if (!user) return null;

  return (
    <div className={styles.burgerUserWrap}>
      <div className={styles.burgerUserBg}>
        <div className={styles.burgerUserFirstBlock}>
          <div className={styles.burgerUserAvaWrap}>
            <Avatar
              className={styles.burgerUserAvaWrap}
              userAvatar={user?.avatar?.avatar}
              vip={checkIsVip(vipRank, user.rank?.id)}
            />
          </div>
          <div className={styles.burgerUserInfo}>
            <h4>{user?.alias}</h4>
            <div className={styles.burgerUserWallet}>
              <img src={startIcon} alt="icon" />
              <h5>{amount(walletUserStar)}</h5>
            </div>
          </div>
        </div>
        <div className={styles.burgerUserAdditionalBlock}>
          <BurgerNotification onSelectMenu={onSelectMenu} />
        </div>
      </div>
    </div>
  );
};

export default BurgerInfoUser;
