export interface IUserBonus {
  amount: string;
  startAt: Date;
  endAt: Date;
  tokenCode: string;
  displayName: string;
  id: string;
  requiredWager: string;
  currentWager: string;
  status: string;
  userFreespin: IUserFreeSpins;
  bonus?: {
    name: string;
    rules: string;
    img: string;
    maxBonus: string;
    minDeposit: string;
    wagerMultiplier: string;
    minBet: string;
    maxBet: string;
    type: string;
    freeSpinSetting: IFreeSpinsSettings;
  };
}

export interface IUserFreeSpins {
  numberOfSpins: number;
}

export interface BonusNotAuth {
  id: string;
  name: string;
  rules: string;
  maxBonus: string;
  minDeposit: string;
  minBet: string;
  maxBet: string;
  status: string;
  depositDuration: number;
  type: string;
  freeSpinSetting: IFreeSpinsSettings;
}

export enum UserBonusStatus {
  available = 'Available',
  selected = 'Selected',
  active = 'Active',
  inactive = 'Inactive',
  collected = 'Collected',
  expired = 'Expired',
  cancelled = 'Cancelled',
}

export enum BonusType {
  freeSpins = 'freespins',
  depositMatch = 'deposit',
  bonus = 'bonus',
}

export interface IFreeSpinsSettings {
  gameId: string;
  numberOfSpins: number;
  currency: string;
  game: {
    name: string;
    image: string;
    slug: string;
  };
}
