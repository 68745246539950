import clsx from 'clsx';
import { useEffect, useState } from 'react';

import defaultAvatar from 'assets/img/Avatars/ToyFaces_Colored_BG_120.webp';
import badgeIcon from 'assets/img/Vip/badge.svg';

import { media } from 'config';

import styles from './styles.module.scss';
import { IAvatar } from './types';

const Avatar: React.FC<IAvatar> = ({
  wrapperRef,
  imageRef,
  className = '',
  userAvatar,
  withBorder = true,
  vip = false,
  onClick,
}) => {
  const [avatar, setAvatar] = useState<string>(defaultAvatar);

  useEffect(() => {
    const avatarUrl = userAvatar ? media.avatars + userAvatar : defaultAvatar;

    setAvatar(avatarUrl);
  }, [userAvatar]);

  return (
    <div
      ref={wrapperRef}
      className={clsx(styles.wrapper, withBorder ? styles.border : '', onClick ? styles.pointer : '', className)}
      onClick={onClick}
    >
      <img ref={imageRef} className={styles.avatar} src={avatar} alt="Avatar" />
      {vip ? <img className={styles.badge} src={badgeIcon} alt="Badge" /> : null}
    </div>
  );
};

export default Avatar;
