import { CollectionObject } from 'intercom-client';

import { intercomCollectionKeys } from './constants';
import { IIntercomCollection } from './types';

export const mapIntercomCollectionsToKeys = (collections: CollectionObject[]): IIntercomCollection[] => {
  const collectionIds = new Set(Object.values(intercomCollectionKeys));
  const filteredCollections = collections.filter((c) => collectionIds.has(c.id));

  return filteredCollections.map((c) => {
    const keyPair = Object.entries(intercomCollectionKeys).find(([_key, id]) => id === c.id);

    return { ...c, key: keyPair[0] };
  });
};
