import Big from 'big.js';
import { IDiceBetResult, IDiceRollResponse } from 'context/contextDiceGame/types';
import { amount, removeComas } from './common';

export const getDiceUnderNumber = (number: number): number => {
  return number < 1 ? 1 : number > 95 ? 95 : number;
};

export const getDiceOverNumber = (number: number): number => {
  return number < 4 ? 4 : number > 98 ? 98 : number;
};

export const getDiceBetResult = (bet: IDiceRollResponse, betAmount: string, token: string): IDiceBetResult => {
  const win = Big(bet.payout).gt('0');

  return {
    id: bet.id,
    outcome: win ? 'WIN' : 'LOSS',
    luckyNumber: bet.luckyNumber,
    multiplier: bet.multiplier,
    payout: bet.payout,
    amount: betAmount,
    token,
    createdAt: new Date(),
  };
};

export const getDiceMultiplier = (chanseToWin: number): string => {
  return String(100 / chanseToWin - ((100 / chanseToWin) * 2) / 100).slice(0, 6);
};

export const getDicePayoutOnWin = (betAmount: string, multiplier: string): string => {
  const newPayout = Big(removeComas(betAmount)).mul(multiplier).toFixed();

  return String(amount(newPayout));
};
