import { gql } from '@apollo/client';

export const WITHDRAW = gql`
  mutation withdraw($payload: WalletWithdrawRequest!) {
    withdraw(payload: $payload)
  }
`;

export const ADDRESS_BOOK_RECORD = gql`
  mutation ($formData: CreateAddressBookRecordDto!, $code: String) {
    addAddressBookRecord(formData: $formData, code: $code) {
      id
    }
  }
`;

export const UPDATE_ADDRESS_BOOK_RECORD = gql`
  mutation updateWalletAddress($formData: UpdateAddressBookRecordDto!, $code: String) {
    updateAddressBookRecord(formData: $formData, code: $code) {
      id
    }
  }
`;

export const DELETE_ADDRESS_BOOK_RECORD = gql`
  mutation deleteAddressBookRecord($formData: DeleteAddressBookRecordDto!, $code: String) {
    deleteAddressBookRecord(formData: $formData, code: $code)
  }
`;
