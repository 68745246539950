import { useContext, useEffect, useState } from 'react';

import LiveStatsContext from 'context/contextLiveStats/context';
import { ContextWallet } from 'context';
import { amount, getImgSrc } from 'func/common';
import { ETokenCode } from 'components/constants/constants';
import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';

const LiveStatisticsData: React.FC = () => {
  const { tokenDetails } = useContext(ContextWallet);
  const { profit, wagered, wins, losses } = useContext(LiveStatsContext);
  const { translate } = useLocalization();

  const [tokenCode, setTokenCode] = useState(ETokenCode.STAR);

  useEffect(() => {
    if (tokenDetails) {
      setTokenCode(tokenDetails.tokenCode);
    }
  }, [tokenDetails]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.col}>
          <span className={styles.title}>{translate('life.statistics.profit')}:</span>
          <span className={styles.data}>
            <img className={styles.coin} src={getImgSrc(tokenCode)} alt={tokenCode} />
            <span>{amount(profit)}</span>
          </span>
        </div>
        <div className={styles.col}>
          <span className={styles.title}>{translate('life.statistics.wins')}:</span>
          <span className={styles.data}>{wins}</span>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <span className={styles.title}>{translate('life.statistics.wager')}:</span>
          <span className={styles.data}>
            <img className={styles.coin} src={getImgSrc(tokenCode)} alt={tokenCode} />
            <span>{amount(wagered)}</span>
          </span>
        </div>
        <div className={styles.col}>
          <span className={styles.title}>{translate('life.statistics.losses')}:</span>
          <span className={styles.data}>{losses}</span>
        </div>
      </div>
    </div>
  );
};

export default LiveStatisticsData;
