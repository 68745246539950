import React from 'react';

import { useLimbo } from 'context/contextLimbo/contextLimbo';
import { popUps } from 'components/constants/constants';

import MyBetsTableWrap from 'components/Games/base/MyBetsTable';

import styles from '../../styles.module.scss';

const MyLimboBets: React.FC = () => {
  const [state] = useLimbo();
  const { historyList } = state;

  return (
    <div className={styles.limboTableMyBetsWrap}>
      <MyBetsTableWrap history={historyList} popUpsWindow={popUps.limboBetDetails} />
    </div>
  );
};

export default MyLimboBets;
