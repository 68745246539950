import styled from 'styled-components';

const EventsStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  max-width: 1400px;
  padding: 0 30px 30px;

  .title__shadow {
    position: absolute;
    left: -27vw;
    top: -18vh;
    height: 69vh;
    width: 52vw;
    z-index: 1;
    background: linear-gradient(134.02deg, rgba(255, 189, 0, 0.3) 25.51%, rgba(255, 189, 0, 0) 70.06%);
  }

  .table__events-body {
    display: flex;
    max-width: 1340px;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin-top: 15px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .table__events-body-style {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .events__navigation--wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    z-index: 2;
  }

  .table__events-no-response {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 36px;
    color: #ffffff;
    padding: 0 30px;
    width: 100%;
    height: 100%;
  }
  .cards__events__wrap {
    display: grid;
    width: 100%;

    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 30px;
    margin-bottom: 30px;
    z-index: 2;
  }

  @media (max-width: 1350px) {
    .events__title {
      padding-left: 10px;
    }
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1024px) {
    .cards__events__wrap {
      justify-content: center;
      flex-direction: row;
    }
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
    .cards__events__wrap {
      grid-template-columns: 1fr;
    }
    .title__shadow {
      height: 48vh;
      width: 62vw;
    }
  }
  @media (max-width: 640px) {
    padding: 0 15px 30px;
    .table__events-no-response {
      padding-left: 36px;
      font-size: 26px;
      padding-right: 30px;
    }
  }
  @media (max-width: 580px) {
    .table__events-body {
      margin-top: 0;
    }
    .events__title {
      height: 220px;
      align-items: center;
      justify-content: center;
    }
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 400px) {
  }
`;

export default EventsStyle;
