import sanitize from 'sanitize-html';
import { useEffect, useState } from 'react';

import cardImage from 'assets/img/TexasHoldem/rules_cards.webp';

import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';

const TexasHoldemMainRules: React.FC = () => {
  const { translate, language } = useLocalization();

  const [description, setDescription] = useState('');
  const [gameProcess, setGameProcess] = useState('');

  useEffect(() => {
    const newDescription = translate('popups.rules.texas-holdem.rules.desc');
    const newGameProcess = translate('popups.rules.texas-holdem.rules.game-process');

    setDescription(newDescription);
    setGameProcess(newGameProcess);
  }, [language]);

  return (
    <>
      <img className={styles.image} src={cardImage} alt="Card" />
      <p className={styles.title}>{translate('popups.rules.texas-holdem.rules.title')}</p>
      <p className={styles.desc} dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
      <p className={styles.title}>{translate('popups.rules.texas-holdem.rules.subtitle')}</p>
      <div className={styles.game} dangerouslySetInnerHTML={{ __html: sanitize(gameProcess) }} />
    </>
  );
};

export default TexasHoldemMainRules;
