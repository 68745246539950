import { Navigate, generatePath, useParams } from 'react-router-dom';

import { IRedirectWithParams } from './types';

const RedirectWithParams: React.FC<IRedirectWithParams> = ({ param, redirectsTo, replace }) => {
  const params = useParams();
  const matchedParam = params[param];
  const pathWithParams = generatePath(redirectsTo, { [param]: matchedParam });

  return <Navigate to={pathWithParams} replace={replace} />;
};

export default RedirectWithParams;
