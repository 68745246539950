import { useQuery } from '@apollo/client';

import { prepareCashbackCollections } from 'func/prepareDataCashback';
import { useAppSelector } from 'hooks/useAppSelector';
import { GET_CASHBACK_COLLECTION } from 'graphQl/query/cashback/cashbackCollections';
import {
  CASHBACK_PAGE_TABLE_TITLES,
  CASHBACK_TABLE_TITLES,
  CashbackPageTablesTitles,
} from 'components/constants/table';
import { userToken } from 'store/user/user.selectors';

import TablesTitles from 'components/common/TablesTitles';
import TableWithoutPagination from 'components/Base/Table/TableWithoutPagination/TableWithoutPagination';

import styles from './styles.module.scss';

const CashbackTable: React.FC = () => {
  const token = useAppSelector(userToken);

  const { data } = useQuery(GET_CASHBACK_COLLECTION, {
    skip: !token,
    fetchPolicy: 'cache-only',
  });

  return (
    <div className={styles.cashbackTableWrap}>
      <TablesTitles
        currentTitle={CashbackPageTablesTitles.collection}
        titles={CASHBACK_TABLE_TITLES}
        onTitle={() => {}}
      />
      <div className={styles.cashbackTableBody}>
        <TableWithoutPagination
          thWidth="50%"
          titles={CASHBACK_PAGE_TABLE_TITLES}
          prepareDataTable={prepareCashbackCollections}
          data={data?.cashbackCollections}
        />
      </div>
    </div>
  );
};

export default CashbackTable;
