import styled from 'styled-components';
// import point from 'assets/img/common/cursor-pointer.webp';

const ErrorPageStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100%;
  max-width: 1400px;
  padding-top: 5%;

  .error__title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 550px;
    p {
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 20px;
      margin: 0;
      color: #ffffff;
    }
  }
  .error_image {
    height: 300px;
  }

  @media (max-width: 640px) {
    .error__title {
      max-width: 300px;
      p {
        font-size: 14px;
      }
    }
    .error_image {
      height: 200px;
    }
  }
`;

export default ErrorPageStyle;
