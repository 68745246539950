import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { useKeno } from 'context/contextKeno/contextKeno';
import useGame from 'hooks/useGame';
import { useAppSelector } from 'hooks/useAppSelector';
import { Types } from 'context/contextKeno/kenoReduser';
import { userProfile, userToken } from 'store/user/user.selectors';

import { GET_BETS_HISTORY } from 'graphQl/query/betsPage/betsHistory';
import { EGamesSlugName } from 'components/constants/games';

import AllGameHistoryNew from 'components/Games/base/AllGameHistoryNew';

const KenoHistory: React.FC = () => {
  const token = useAppSelector(userToken);
  const user = useAppSelector(userProfile);

  const [state, dispatch] = useKeno();

  const { gameId, historyList } = state;
  const kenoGame = useGame({ slug: EGamesSlugName.keno, providerName: 'In-House', userId: user?.id }, !token);

  useQuery(GET_BETS_HISTORY, {
    skip: !token || !gameId,
    variables: {
      input: {
        limit: 28,
        offset: 0,
        gameId,
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch({ type: Types.SET_FIRST_HISTORY, payload: { historyList: data.userBets.items } });
    },
  });

  useEffect(() => {
    if (kenoGame) {
      dispatch({ type: Types.GAME_ID, payload: { gameId: kenoGame.id } });
    }
  }, [kenoGame]);

  return <AllGameHistoryNew data={historyList} />;
};

export default KenoHistory;
