import * as yup from 'yup';

import { NUMBER_MEMO_NETWORKS, memoWithNumbersValidationSchema } from 'components/constants/withdraw';

export const getMemoValidationSchema = (networkCode: string): yup.AnySchema => {
  if (NUMBER_MEMO_NETWORKS.includes(networkCode)) {
    return memoWithNumbersValidationSchema;
  }

  return null;
};
