import d_2 from 'assets/img/Cards/Diamonds/2.webp';
import d_3 from 'assets/img/Cards/Diamonds/3.webp';
import d_4 from 'assets/img/Cards/Diamonds/4.webp';
import d_5 from 'assets/img/Cards/Diamonds/5.webp';
import d_6 from 'assets/img/Cards/Diamonds/6.webp';
import d_7 from 'assets/img/Cards/Diamonds/7.webp';
import d_8 from 'assets/img/Cards/Diamonds/8.webp';
import d_9 from 'assets/img/Cards/Diamonds/9.webp';
import d_10 from 'assets/img/Cards/Diamonds/10.webp';
import d_J from 'assets/img/Cards/Diamonds/Jack.webp';
import d_Q from 'assets/img/Cards/Diamonds/Queen.webp';
import d_K from 'assets/img/Cards/Diamonds/King.webp';
import d_A from 'assets/img/Cards/Diamonds/Ace.webp';

import h_2 from 'assets/img/Cards/Hearts/2.webp';
import h_3 from 'assets/img/Cards/Hearts/3.webp';
import h_4 from 'assets/img/Cards/Hearts/4.webp';
import h_5 from 'assets/img/Cards/Hearts/5.webp';
import h_6 from 'assets/img/Cards/Hearts/6.webp';
import h_7 from 'assets/img/Cards/Hearts/7.webp';
import h_8 from 'assets/img/Cards/Hearts/8.webp';
import h_9 from 'assets/img/Cards/Hearts/9.webp';
import h_10 from 'assets/img/Cards/Hearts/10.webp';
import h_J from 'assets/img/Cards/Hearts/Jack.webp';
import h_Q from 'assets/img/Cards/Hearts/Queen.webp';
import h_K from 'assets/img/Cards/Hearts/King.webp';
import h_A from 'assets/img/Cards/Hearts/Ace.webp';

import s_2 from 'assets/img/Cards/Spades/2.webp';
import s_3 from 'assets/img/Cards/Spades/3.webp';
import s_4 from 'assets/img/Cards/Spades/4.webp';
import s_5 from 'assets/img/Cards/Spades/5.webp';
import s_6 from 'assets/img/Cards/Spades/6.webp';
import s_7 from 'assets/img/Cards/Spades/7.webp';
import s_8 from 'assets/img/Cards/Spades/8.webp';
import s_9 from 'assets/img/Cards/Spades/9.webp';
import s_10 from 'assets/img/Cards/Spades/10.webp';
import s_J from 'assets/img/Cards/Spades/Jack.webp';
import s_Q from 'assets/img/Cards/Spades/Queen.webp';
import s_K from 'assets/img/Cards/Spades/King.webp';
import s_A from 'assets/img/Cards/Spades/Ace.webp';

import c_2 from 'assets/img/Cards/Clubs/2.webp';
import c_3 from 'assets/img/Cards/Clubs/3.webp';
import c_4 from 'assets/img/Cards/Clubs/4.webp';
import c_5 from 'assets/img/Cards/Clubs/5.webp';
import c_6 from 'assets/img/Cards/Clubs/6.webp';
import c_7 from 'assets/img/Cards/Clubs/7.webp';
import c_8 from 'assets/img/Cards/Clubs/8.webp';
import c_9 from 'assets/img/Cards/Clubs/9.webp';
import c_10 from 'assets/img/Cards/Clubs/10.webp';
import c_J from 'assets/img/Cards/Clubs/Jack.webp';
import c_Q from 'assets/img/Cards/Clubs/Queen.webp';
import c_K from 'assets/img/Cards/Clubs/King.webp';
import c_A from 'assets/img/Cards/Clubs/Ace.webp';

export const cardIcon = (card: string): string => {
  switch (card) {
    case 'd_2':
      return d_2;
    case 'd_3':
      return d_3;
    case 'd_4':
      return d_4;
    case 'd_5':
      return d_5;
    case 'd_6':
      return d_6;
    case 'd_7':
      return d_7;
    case 'd_8':
      return d_8;
    case 'd_9':
      return d_9;
    case 'd_10':
      return d_10;
    case 'd_J':
      return d_J;
    case 'd_Q':
      return d_Q;
    case 'd_K':
      return d_K;
    case 'd_A':
      return d_A;
    case 'h_2':
      return h_2;
    case 'h_3':
      return h_3;
    case 'h_4':
      return h_4;
    case 'h_5':
      return h_5;
    case 'h_6':
      return h_6;
    case 'h_7':
      return h_7;
    case 'h_8':
      return h_8;
    case 'h_9':
      return h_9;
    case 'h_10':
      return h_10;
    case 'h_J':
      return h_J;
    case 'h_Q':
      return h_Q;
    case 'h_K':
      return h_K;
    case 'h_A':
      return h_A;
    case 's_2':
      return s_2;
    case 's_3':
      return s_3;
    case 's_4':
      return s_4;
    case 's_5':
      return s_5;
    case 's_6':
      return s_6;
    case 's_7':
      return s_7;
    case 's_8':
      return s_8;
    case 's_9':
      return s_9;
    case 's_10':
      return s_10;
    case 's_J':
      return s_J;
    case 's_Q':
      return s_Q;
    case 's_K':
      return s_K;
    case 's_A':
      return s_A;
    case 'c_2':
      return c_2;
    case 'c_3':
      return c_3;
    case 'c_4':
      return c_4;
    case 'c_5':
      return c_5;
    case 'c_6':
      return c_6;
    case 'c_7':
      return c_7;
    case 'c_8':
      return c_8;
    case 'c_9':
      return c_9;
    case 'c_10':
      return c_10;
    case 'c_J':
      return c_J;
    case 'c_Q':
      return c_Q;
    case 'c_K':
      return c_K;
    case 'c_A':
      return c_A;
    default:
      return '';
  }
};
