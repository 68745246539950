import { IOption } from 'components/Base/Select/types';

export enum FAQSort {
  date = 'date',
  popular = 'popular',
}

export const FAQ_SORT_OPTIONS: IOption[] = [
  { value: FAQSort.date, label: 'Date' },
  { value: FAQSort.popular, label: 'Popular' },
];
