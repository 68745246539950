import { gql } from '@apollo/client';

export const GET_MINING_STAGE = gql`
  query miningStage {
    miningStage {
      price
      rangeEnd
      rangeStart
    }
  }
`;

export const GET_TOKEN_MINING_PRICES = gql`
  query tokenMiningPrices {
    tokenMiningPrices {
      miningPrice
      tokenCode
    }
  }
`;
