import clsx from 'clsx';
import { useContext, useState } from 'react';

import { ReactComponent as CrossIcon } from 'assets/img/Notification/cross.svg';

import NotificationContext from 'context/contextNotifications/context';
import { READ_NOTIFICATION_TIME } from 'context/contextNotifications';
import { getNotificationIconByType, getNotificationsContent, getNotificationTime } from 'func/notifications';
import { NotificationType } from 'types';

import styles from './styles.module.scss';
import { INotificationItem } from './types';

const NotificationItem: React.FC<INotificationItem> = ({ notification }) => {
  const { onRead, onRemove } = useContext(NotificationContext);

  const [readTimeout, setReadTimeout] = useState<NodeJS.Timeout | undefined>(undefined);

  const handleClick = () => {
    if (!notification.isRead) {
      onRead(notification.id);
    }
  };

  const handleMouseEnter = () => {
    if (!notification.isRead) {
      const timeout = setTimeout(() => {
        onRead(notification.id);

        // clear timeout state
        setReadTimeout(undefined);
      }, READ_NOTIFICATION_TIME);

      setReadTimeout(timeout);
    }
  };

  const handleMouseLeave = () => {
    if (readTimeout) clearTimeout(readTimeout);
  };

  const handleRemove = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.stopPropagation();

    onRemove(notification.id);
  };

  return (
    <li
      className={clsx(styles.wrapper, !notification.isRead ? styles.background : '')}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.imageWrapper}>
        <img className={styles.image} src={getNotificationIconByType(notification.type)} alt={notification.type} />
      </div>
      <div>
        <p
          className={clsx(
            styles.title,
            notification.type.toLowerCase() === NotificationType.withdraw ? styles.red : '',
          )}
        >
          {notification.title}
        </p>
        <p className={styles.description}>{getNotificationsContent(notification.content)}</p>
        <p className={styles.date}>{getNotificationTime(notification.createdAt)}</p>
      </div>
      {!notification.isRead ? <span className={styles.new}>New</span> : null}
      <button className={styles.removeButton} type="button" onClick={handleRemove}>
        <CrossIcon className={styles.removeIcon} />
      </button>
    </li>
  );
};

export default NotificationItem;
