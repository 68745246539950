import clsx from 'clsx';
import { useEffect, useState } from 'react';

import chartIcon from 'assets/img/HomePage/NvigationImages/dividends1.webp';
import percentIcon from 'assets/img/HomePage/NvigationImages/dividends2.webp';

import { Links } from 'components/constants';
import { useLocalization } from 'components/Internationalization';

import PromoBlock from '../PromoBlock';

import styles from './styles.module.scss';

const DividendsNavigationItem: React.FC = () => {
  const { language, translate } = useLocalization();

  const [title, setTitle] = useState('Earn');
  const [subtitle, setSubtitle] = useState('Dividends');

  useEffect(() => {
    const newText = translate('homepage.welcome.dividends');

    const [newTitle, newSubtitle] = newText.split(' ');

    setTitle(newTitle);
    setSubtitle(newSubtitle);
  }, [language]);

  return (
    <PromoBlock
      className={styles.wrapper}
      bgClassName={clsx(styles.bg, styles.dividendsBg)}
      title={title}
      subtitle={subtitle}
      link={Links.dividends}
    >
      <img src={percentIcon} alt="icon" className={styles.dividendPercent1} />
      <img src={percentIcon} alt="icon" className={styles.dividendPercent2} />
      <img src={percentIcon} alt="icon" className={styles.dividendPercent3} />
      <img src={chartIcon} alt="icon" className={styles.dividendChart} />
    </PromoBlock>
  );
};

export default DividendsNavigationItem;
