import React from 'react';

import { EKenoSquareClass } from 'components/constants/games';
import kenoSlotIcon from 'assets/img/Keno/keno-slot.webp';

import { IKenoSquareItem } from './types';
import styles from './styles.module.scss';

const KenoSquareItem: React.FC<IKenoSquareItem> = ({ ind, className, disable, onClick, fastMode }) => {
  return (
    <button
      className={`${fastMode ? styles.kenoSquareItemFast : styles.kenoSquareItem} ${
        className ? styles[className] : ''
      }`}
      type="button"
      disabled={disable}
      onClick={onClick}
    >
      <p>{ind}</p>
      {className === EKenoSquareClass.win || className === EKenoSquareClass.fastWin ? (
        <img src={kenoSlotIcon} alt="icon" />
      ) : null}
    </button>
  );
};

export default KenoSquareItem;
