import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { forwardRef, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import calendar from 'assets/img/common/calendar.webp';

import PickerStyle from './styles';
import { ICalendar } from './types';

const Calendar: React.FC<ICalendar> = ({
  calendarRef,
  startDate,
  endDate,
  showDate,
  minMaxDateRange,
  disabled,
  setStartDate,
  setEndDate,
  setShowDate,
  handleCalendarClose,
}) => {
  const date = new Date();

  const [startDateCalendar, setStartDateCalendar] = useState(null);
  const [endDateCalendar, setEndDateCalendar] = useState(null);
  const [minDate, setMinDate] = useState(undefined);
  const [maxDate, setMaxDate] = useState(undefined);

  useEffect(() => {
    if ((startDate || endDate) && !startDateCalendar && !endDateCalendar) {
      const newStartDate = new Date(startDate);
      const newEndDate = new Date(endDate);

      setStartDateCalendar(newStartDate);
      setEndDateCalendar(newEndDate);
      setShowDate(newStartDate);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (minMaxDateRange) {
      const newMinDate = date.setDate(date.getDate() - minMaxDateRange);
      const newMaxDate = date.setDate(date.getDate() + minMaxDateRange);

      setMinDate(newMinDate);
      setMaxDate(newMaxDate);
    }
  }, [minMaxDateRange]);

  useEffect(() => {
    if (calendarRef) {
      calendarRef.current = { onReset: handleReset };
    }
  }, [calendarRef]);

  const onChange = (dates) => {
    const [start, end] = dates;

    const startUTS = dayjs(start).format('ddd MMM D YYYY HH:MM:SS');
    const endUTS = dayjs(end).format('ddd MMM D YYYY HH:MM:SS');

    if (start) {
      setStartDate(startUTS);
    }

    if (end) {
      setEndDate(endUTS);
    }

    setStartDateCalendar(start);
    setEndDateCalendar(end);
    setShowDate(start);
  };

  function handleReset(newStartDate: string, newEndDate: string) {
    const formattedStartDate = dayjs(newStartDate).toDate();
    const formattedEndDate = dayjs(newEndDate).toDate();

    setStartDateCalendar(formattedStartDate);
    setEndDateCalendar(formattedEndDate);
  }

  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <button type="button" className="example-custom-input" onClick={onClick} ref={ref}>
      {showDate
        ? value
        : dayjs(date.setDate(date.getDate() - minMaxDateRange)).format('MM/DD/YY') +
          ' - ' +
          dayjs(date.setDate(date.getDate() + minMaxDateRange)).format('MM/DD/YY')}
      <img src={calendar} alt="icon" />
    </button>
  ));

  return (
    <PickerStyle>
      <DatePicker
        dateFormat="MM/dd/y"
        startDate={startDateCalendar}
        endDate={endDateCalendar}
        customInput={<CustomInput />}
        selectsRange
        disabled={disabled}
        onChange={onChange}
        onCalendarClose={handleCalendarClose}
        minDate={minDate}
        maxDate={maxDate}
        popperModifiers={[
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom'],
              allowedAutoPlacements: ['bottom'],
            },
          },
        ]}
      />
    </PickerStyle>
  );
};

export default Calendar;
