import { TABLE_CLASSNAMES } from 'components/constants/constants';
import { Links } from '.';

export const EVENT_HISTORY_TABLE_TITLES = [
  { title: 'events.history.date', columnClass: TABLE_CLASSNAMES.column.default },
  { title: 'events.history.name', columnClass: TABLE_CLASSNAMES.column.default },
  { title: 'events.history.prize', columnClass: TABLE_CLASSNAMES.column.hide700 },
  { title: 'events.history.result', columnClass: TABLE_CLASSNAMES.column.default },
];

export const MISSIONS_TABLE_TITLES = [
  { title: 'missions.table.titles.date', columnClass: TABLE_CLASSNAMES.column.default },
  { title: 'missions.table.titles.prize', columnClass: TABLE_CLASSNAMES.column.hideDesktop },
  { title: 'missions.table.titles.multiplier', columnClass: TABLE_CLASSNAMES.column.hideTablet },
  { title: 'missions.table.titles.reward', columnClass: TABLE_CLASSNAMES.column.default },
  { title: '', columnClass: TABLE_CLASSNAMES.column.default },
];

export enum HomePageTablesTitles {
  topPlayers = 'TOP PLAYERS',
  bigWins = 'BIG WINS',
}

export const HOMEPAGE_TABLE_TITLES = [
  { title: 'homepage.table.top-players', link: HomePageTablesTitles.topPlayers },
  { title: 'homepage.table.big-wins', link: HomePageTablesTitles.bigWins },
];

export const TOP_PLAYERS_TABLE_TITLES = [
  { title: 'homepage.table.top-players.place', columnClass: TABLE_CLASSNAMES.column.default },
  { title: 'homepage.table.top-players.player', columnClass: TABLE_CLASSNAMES.column.default },
  { title: 'homepage.table.top-players.wager', columnClass: TABLE_CLASSNAMES.column.default },
];

export const BIG_WINS_TABLE_TITLS = [
  { title: 'homepage.table.big-wins.place', columnClass: TABLE_CLASSNAMES.column.default },
  { title: 'homepage.table.big-wins.player', columnClass: TABLE_CLASSNAMES.column.default },
  { title: 'homepage.table.big-wins.date', columnClass: TABLE_CLASSNAMES.column.hide700 },
  { title: 'homepage.table.big-wins.game', columnClass: TABLE_CLASSNAMES.column.hideTablet },
  { title: 'homepage.table.big-wins.payout', columnClass: TABLE_CLASSNAMES.column.default },
];

export enum EventsPageTablesTitles {
  history = 'PAST EVENTS',
}
export const EVENTS_TABLE_TITLES = [{ title: 'events.table.past-events', link: EventsPageTablesTitles.history }];

export enum MissionsPageTablesTitles {
  history = 'HISTORY',
}
export const MISSIONS_PAGE_TABLE_TITLES = [
  { title: 'missions.table.titles.history', link: MissionsPageTablesTitles.history },
];

export enum MyAccountPageTablesTitles {
  history = 'TOTAL COLLECTIONS',
}

export const EVENTS_TITLES = [
  { title: 'events.starwars.title', link: Links.starwars },
  { title: 'events.tournaments.title', link: Links.tournaments },
];

export enum DividendsPageTablesTitles {
  activity = 'ALL DIVIDENDS',
  collection = 'MY COLLECTIONS',
  dividends = 'MY DIVIDENDS',
}
export const DIVIDENDS_TABLE_TITLES = [
  { title: 'dividends.tabs.all.dividends', link: DividendsPageTablesTitles.activity },
  { title: 'dividends.tabs.dividends', link: DividendsPageTablesTitles.dividends },
  { title: 'dividends.tabs.collection', link: DividendsPageTablesTitles.collection },
];
export const DIVIDENDS_TABLE_TITLES_UNAUTH = [
  { title: 'dividends.tabs.all.dividends', link: DividendsPageTablesTitles.activity },
];

export enum CashbackPageTablesTitles {
  collection = 'MY COLLECTIONS',
}
export const CASHBACK_TABLE_TITLES = [
  { title: 'dividends.tabs.collection', link: CashbackPageTablesTitles.collection },
];

export enum ReferralPageTablesTitles {
  activity = 'REFERRAL ACTIVITY',
  collection = 'MY COLLECTIONS',
}
export const REFERRAL_PAGE_TABLE_TITLES = [
  { title: 'referral.tabs.activity', link: ReferralPageTablesTitles.activity },
  { title: 'dividends.tabs.collection', link: ReferralPageTablesTitles.collection },
];

export enum SecurityPageTablesTitles {
  activity = 'LOGIN ACTIVITY',
}
export const SECURITY_PAGE_TABLE_TITLES = [{ title: 'security.tabs', link: SecurityPageTablesTitles.activity }];
export const SECURITY_TABLE_TITLES = [
  { title: 'security.table.date', columnClass: TABLE_CLASSNAMES.column.default },
  { title: 'security.table.activity', columnClass: TABLE_CLASSNAMES.column.default },
  { title: 'security.table.device', columnClass: TABLE_CLASSNAMES.column.hideDesktop },
  { title: 'security.table.address', columnClass: TABLE_CLASSNAMES.column.hideMobile },
  { title: 'security.table.status', columnClass: TABLE_CLASSNAMES.column.default },
];

export enum InHouseGamesPageTablesTitles {
  myBets = 'MY BETS',
}
export const IN_HOUSE_TABLE_TITLES = [{ title: 'inHouse.tabs.bets', link: InHouseGamesPageTablesTitles.myBets }];

export const getEventsPopupTableTitles = (type: string) => [
  { title: 'popups.table.titles.place', columnClass: TABLE_CLASSNAMES.column.default },
  { title: 'popups.table.titles.player', columnClass: TABLE_CLASSNAMES.column.default },
  {
    title: 'popups.table.titles.total',
    additional: type,
    columnClass: TABLE_CLASSNAMES.column.hideMobile,
  },
  { title: 'popups.table.titles.prize', columnClass: TABLE_CLASSNAMES.column.default },
];

export const CASHBACK_PAGE_TABLE_TITLES = [
  { title: 'dividends.tabs.all.dividends.date', columnClass: TABLE_CLASSNAMES.column.default },
  { title: 'dividends.tabs.all.dividends.amount', columnClass: TABLE_CLASSNAMES.column.default },
];

export const TRANSACTIONS_PAGE_TABLE_TITLES = [
  { title: 'security.table.date', columnClass: TABLE_CLASSNAMES.column.default },
  { title: 'security.table.title.type', columnClass: TABLE_CLASSNAMES.column.default },
  { title: 'dividends.tabs.all.dividends.amount', columnClass: TABLE_CLASSNAMES.column.default },
  { title: 'security.table.title.status', columnClass: TABLE_CLASSNAMES.column.default },
  { title: 'security.table.title.info', columnClass: TABLE_CLASSNAMES.column.hide700 },
];

export const BONUS_PAGE_TABLE_TITLES = [
  { title: 'security.table.date', columnClass: TABLE_CLASSNAMES.column.default },
  { title: 'bonus.table.name', columnClass: TABLE_CLASSNAMES.column.hideMobile },
  { title: 'dividends.tabs.all.dividends.amount', columnClass: TABLE_CLASSNAMES.column.default },
  { title: 'security.table.status', columnClass: TABLE_CLASSNAMES.column.default },
];

export enum UserBonusPageTableTitles {
  history = 'HISTORY',
}
export const USER_BONUS_TABLE_TITLES = [
  { title: 'missions.table.titles.history', link: UserBonusPageTableTitles.history },
];
