import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';

import { GET_REFERRAL_ACTIVITY } from 'graphQl/query/referral/referralActivity';
import { prepareReferralActivity } from 'func/prepareDataReferral';
import { TABLE_CLASSNAMES } from 'components/constants/constants';
import { useLocalization } from 'components/Internationalization';

import EmptyTable from 'components/Base/EmptyTable';
import Table from 'components/Base/Table';
import Pagination from 'components/Base/Pagination';

import { IActivityTable } from './types';

const ActivityTable: React.FC<IActivityTable> = ({ dateFrom, dateTo }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 581px)' });
  const { translate, language } = useLocalization();

  const [dataTable, setDataTable] = useState([]);
  const [perPage, setPerPage] = useState(20);
  const [pageNumber, setPageNumber] = useState(0);
  const [countPages, setCountPages] = useState(0);
  const [tableTitles, setTableTitles] = useState([]);

  const { data, refetch } = useQuery(GET_REFERRAL_ACTIVITY, {
    variables: {
      input: {
        limit: perPage,
        offset: pageNumber,
        dateFrom,
        dateTo,
      },
    },
  });

  const onPageChanged = (elem: number) => {
    if (elem === 1) elem = 0;
    setPageNumber(elem);
  };

  useEffect(() => {
    if (isMobile) {
      setPerPage(10);
    } else {
      setPerPage(20);
    }
  }, [isMobile]);

  useEffect(() => {
    if (data) {
      setCountPages(data?.referralActivity?.count);
      const result = prepareReferralActivity(data?.referralActivity?.items || []);
      setDataTable(result);
    }
  }, [data, perPage]);

  useEffect(() => {
    refetch();
  }, [pageNumber, dateFrom, dateTo]);

  useEffect(() => {
    const titles = [
      { title: translate('referral.table.activity.player.text'), columnClass: TABLE_CLASSNAMES.column.default },
      { title: translate('referral.table.activity.date.text'), columnClass: TABLE_CLASSNAMES.column.default },
      { title: translate('referral.table.activity.wager.text'), columnClass: TABLE_CLASSNAMES.column.default },
    ];

    setTableTitles(titles);
  }, [language]);

  if (!dataTable.length) {
    return <EmptyTable lines={2} marginTop="30px" />;
  }

  return (
    <>
      <Table data={dataTable} titles={tableTitles} thWidth="25%" config="referralActivity" cutCurrency />
      {countPages > perPage && (
        <div className="table__pagination">
          <Pagination perPage={perPage} itemsLength={countPages} onPageChanged={onPageChanged} pageDisplayed={4} />
        </div>
      )}
    </>
  );
};

export default ActivityTable;
