export const VIP_WEEKLY_BONUSES = [
  {
    wager: '100,000',
    bonus: '10K',
    background: 'rgb(98,42,17)',
    gradient: 'linear-gradient(180deg, rgba(98,42,17,1) 0%, rgba(32,45,74,1) 100%)',
  },
  {
    wager: '500,000',
    bonus: '40K',
    background: 'rgb(69,88,117)',
    gradient: 'linear-gradient(180deg, rgba(69,88,117,1) 0%, rgba(32,45,74,1) 100%)',
  },
  {
    wager: '1,000,000',
    bonus: '70K',
    background: 'rgb(117,98,69)',
    gradient: 'linear-gradient(180deg, rgba(117,98,69,1) 0%, rgba(32,45,74,1) 100%)',
  },
];
