import Link from 'components/Base/Links/Link';

import styles from './styles.module.scss';
import { IBreadCrumbs } from './types';

const BreadCrumbs: React.FC<IBreadCrumbs> = ({ items, onLinkClick }) => {
  return (
    <ul className={styles.wrapper}>
      {items.map((i) =>
        i.link ? (
          <li key={i.title} className={styles.item}>
            <Link className={styles.link} to={i.link} onClick={onLinkClick}>
              <span className={styles.title}>{i.title}</span>
            </Link>
          </li>
        ) : (
          <li key={i.title} className={styles.item}>
            <span className={styles.title}>{i.title}</span>
          </li>
        ),
      )}
    </ul>
  );
};

export default BreadCrumbs;
