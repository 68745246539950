import { gql } from '@apollo/client';

export const GET_DIVIDENDS_POOL = gql`
  query dividendPool {
    dividendPool {
      tokenCode
      displayName
      balance
      usdRate
    }
  }
`;

export const GET_DIVIDENDS_POOL_CHART = gql`
  query dividendPoolChart($input: String!) {
    dividendPoolChart(tokenCode: $input) {
      date
      amount
    }
  }
`;
