import { useContext, useEffect, useState } from 'react';

import keyboardIcon from 'assets/img/Dice/ketboardGrup.webp';

import { useLocalization } from 'components/Internationalization';
import { ContextPopUps } from 'context';
import { ButtonType } from 'components/Base/Button/types';
import { DEFAULT_HOTKEYS } from 'components/constants/games';

import ButtonSubmit from '../ButtonSubmit';

import styles from './styles.module.scss';
import { IHotKeys, IHotkey } from './types';

const HotKeys: React.FC<IHotKeys> = ({ data }) => {
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { translate, language } = useLocalization();

  const { onSwitch, isHotKeys } = data;

  const [checked, setChecked] = useState(isHotKeys);
  const [hotkeys, setHotkeys] = useState<IHotkey[]>([]);

  useEffect(() => {
    const hotkeysData: IHotkey[] = data?.controlKeyData ? data.controlKeyData : DEFAULT_HOTKEYS;
    const newHotkeys = hotkeysData.map<IHotkey>((h) => ({ ...h, description: translate(h.description) }));

    setHotkeys(newHotkeys);
  }, [language, data]);

  const handleClose = () => {
    setPopUpsOpen(null);
  };

  const handleClick = () => {
    setChecked(!checked);
    onSwitch(!checked);
  };

  return (
    <div className={styles.hotWrap}>
      <h2 className={styles.hotTitle}>{translate('popups.hotkeys.title')}</h2>
      <img src={data?.keysImage ? data?.keysImage : keyboardIcon} alt="icon" className={styles.hotImg} />
      <div className={styles.hotContent}>
        {hotkeys.map((elem) => (
          <div key={elem.description} className={styles.hotContentItem}>
            <h6>{elem.key}</h6>
            <h5>{elem.description}</h5>
          </div>
        ))}
      </div>
      <div className={styles.hotFooter}>
        <p>{checked ? translate('popups.hotkeys.enabled') : translate('popups.hotkeys.disabled')}</p>
        <div className={styles.hotCheckboxWrap}>
          <input id="checkbox__hot" type="checkbox" checked={checked} onChange={handleClick} />
          <label htmlFor="checkbox__hot" className={styles.hotCheckboxLabel} />
        </div>
      </div>
      <div className={styles.hotClose}>
        <ButtonSubmit
          className={styles.button}
          handleButton={handleClose}
          variant={ButtonType.primary}
          width="148px"
          title={translate('general.buttons.close')}
          color="#242c45"
        />
      </div>
    </div>
  );
};

export default HotKeys;
