import decode from 'jwt-decode';
import { Tokens } from 'components/constants';

export const getAuthToken = (): string | null => {
  return sessionStorage.getItem(Tokens.authToken);
};

export const setAuthToken = (token: string): void => {
  sessionStorage.setItem(Tokens.authToken, token);
};

export const getRefreshToken = (): string | null => {
  const token = localStorage.getItem(Tokens.refreshToken);

  return token ? token.replace(/"/gim, '') : null;
};

export const setRefreshToken = (token: string): void => {
  localStorage.setItem(Tokens.refreshToken, token);
};

export const validateJWT = (token: string): boolean => {
  try {
    decode(token);
    return true;
  } catch {
    return false;
  }
};
