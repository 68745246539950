import styled from 'styled-components';

export const SupportInfoStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 300px;
  padding: 0 30px 30px;
  background: radial-gradient(100% 100% at 50% 0%, #34109a 0%, rgba(36, 44, 69, 1) 100%);

  .support__info--title-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .support__info--title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-weight: 700;
    font-size: 24px;
    text-transform: uppercase;
    color: #ffffff;
  }

  .support__info--content {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .contact__content--item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0 10px;
    img {
      display: inline-block;
      height: 116px;
    }
    h5 {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #888aa7;
      margin: -20px 0 0 0;
    }
    h4 {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #ffffff;
      margin: 0 0 30px;
    }
  }

  @media (max-width: 768px) {
    .support__info--title {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 540px) {
    padding: 0 15px 30px;
    .contact__content--item {
      margin: 0 10px;

      img {
        height: 96px;
      }
      h5 {
        font-size: 14px;
        line-height: 18px;
      }
      h4 {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
`;
