import firstPlace from 'assets/img/Events/firstPlace.webp';
import secondPlace from 'assets/img/Events/secondPlace.webp';
import thirdPlace from 'assets/img/Events/thirdPlace.webp';

import { EVENT_STATE, TABLE_CLASSNAMES } from 'components/constants/constants';
import { ITournament, TableElem } from 'types/requestTypes';

import EventsResultLink from 'components/Pages/EventsPage/components/EventsTable/components/EventsResultLink';

import { amount, getDate, getImgSrc } from './common';
import UserName from '../components/Base/Table/components/UserName';
import { TournamentType } from '../components/constants/pages';
import { setGameName } from './prepareDataGamesPage';

interface ReferralCollections {
  place?: string;
  alias?: string;
  totalWager?: string;
  prize?: string;
  avatar?: string;
  rank?: number;
  __typename?: string;
}

interface HistoryResults {
  id: string;
  name: string;
  prizeTokenCode: string;
  prize: string;
  startAt: string;
  endAt: string;
  prizeTokenCodeDisplayName: string;
  totalWagerTokenCode: string;
  type?: string;
}

const getPlaceImage = (index) => {
  switch (index) {
    case 1:
      return firstPlace;
    case 2:
      return secondPlace;
    case 3:
      return thirdPlace;
    default:
      return null;
  }
};

export const prepareCompetitionStandings = (
  arrayData: ReferralCollections[],
  currency: string,
  total: string,
): Array<Array<TableElem | React.ReactNode>> => {
  if (!arrayData) return [];
  const tableData = arrayData.map((elem, index) => {
    const place = {
      title: index > 2 && (index + 1).toString(),
      src: getPlaceImage(index + 1),
      imageClass: TABLE_CLASSNAMES.images.place,
      columnClass: TABLE_CLASSNAMES.column.default,
      textClass: TABLE_CLASSNAMES.text.default,
    };
    const jsxElem = <UserName src={elem.avatar} userName={elem.alias} rank={elem.rank} />;
    const referralNum = {
      src: getImgSrc(total),
      title: `${amount(elem.totalWager)}`,
      columnClass: TABLE_CLASSNAMES.column.hideMobile,
      imageClass: TABLE_CLASSNAMES.images.currency,
      textClass: TABLE_CLASSNAMES.text.default,
    };
    const elemObj = {
      title: ` ${elem.prize} ${currency}`,
      src: getImgSrc(currency),
      columnClass: TABLE_CLASSNAMES.column.default,
      imageClass: TABLE_CLASSNAMES.images.currency,
      textClass: TABLE_CLASSNAMES.text.default,
    };
    return [place, jsxElem, referralNum, elemObj];
  });

  return tableData;
};

export const getEventState = (finish: boolean, start: boolean): string => {
  if (!finish) {
    return EVENT_STATE.finished;
  }
  if (!start) {
    return EVENT_STATE.notStarted;
  }
  return EVENT_STATE.started;
};

export const prepareHistoryResults = (arrayData: HistoryResults[]): Array<Array<TableElem | JSX.Element>> => {
  if (!arrayData) return [];
  const tableData = arrayData.map((elem) => {
    const date = {
      title: getDate(elem.startAt),
      columnClass: TABLE_CLASSNAMES.column.default,
      textClass: TABLE_CLASSNAMES.text.dateFormat,
    };
    const name = {
      columnClass: TABLE_CLASSNAMES.column.default,
      title: elem.name,
      textClass: TABLE_CLASSNAMES.text.cutText,
    };
    const prize = {
      src: getImgSrc(elem.prizeTokenCodeDisplayName),
      title: `${amount(elem.prize)} ${elem.prizeTokenCodeDisplayName}`,
      columnClass: TABLE_CLASSNAMES.column.hide700,
      imageClass: TABLE_CLASSNAMES.images.currency,
      textClass: TABLE_CLASSNAMES.text.default,
    };
    const jsxElem = (
      <EventsResultLink
        id={elem.id}
        eventPrizeToken={elem.prizeTokenCodeDisplayName}
        totalWagerTokenCode={elem.totalWagerTokenCode}
        type={elem.type}
      />
    );

    return [date, name, prize, jsxElem];
  });
  return tableData;
};

export const getEventTimerName = (state: string): string => {
  if (state === EVENT_STATE.started) {
    return 'events.state.time-left';
  }

  return state;
};

export const getEventTypeTitle = (type: string): string => {
  const properType = type.toLowerCase();
  switch (properType) {
    case 'wager':
      return 'Wager';
    case 'mining':
      return 'Mined';
    case 'referral':
      return 'Wager';
    case 'payout':
      return 'Payout';
    default:
      return 'Wager';
  }
};

export const isValidDate = (endAt) => {
  const dateNow = new Date();
  const isValid = Date.parse(endAt.toString()) > Date.parse(dateNow.toString());
  return isValid || null;
};

export const getSpecialEvents = (tournaments: ITournament[]): Array<{ title: string; link: string }> | null => {
  const filtered = tournaments.filter((t) => t.type === TournamentType.specialEvent && isValidDate(t.endAt));
  if (filtered.length) {
    return filtered.map((t) => ({
      title: t.name,
      link: `/special-event/${setGameName(t.name)}`,
    }));
  }
  return null;
};
