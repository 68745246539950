import React, { useContext } from 'react';
import clsx from 'clsx';

import { useMines } from 'context/contextMines/contextMines';
import { ContextTokenCode } from 'context';

import styles from './styles.module.scss';
import WinMinesAnimation from './WinMinesAnimation';
import BigWinMinesAnimation from './BigWinMinesAnimation';

const MinesWinAnimation: React.FC = () => {
  const [state] = useMines();
  const { tokenCode } = useContext(ContextTokenCode);

  const { winInfo, fastMode } = state;

  return (
    <div className={clsx(styles.minesWinAnimation, winInfo && !fastMode && styles.minesWinAnimationActive)}>
      {winInfo?.bigWin ? (
        <BigWinMinesAnimation multiplier={winInfo?.multiplier} tokenCode={tokenCode.name} />
      ) : (
        <WinMinesAnimation multiplier={winInfo?.multiplier} tokenCode={tokenCode.name} amount={winInfo?.amount} />
      )}
    </div>
  );
};

export default MinesWinAnimation;
