import { useContext } from 'react';

import securityIcon from 'assets/img/common/securityIcon.svg';

import { ContextPopUps } from 'context';
import { SecurityCodeSuccessContainer } from './styles';
import { ButtonType } from 'components/Base/Button/types';

import ButtonSubmit from 'components/Base/PopUps/components/ButtonSubmit';
import { useLocalization } from 'components/Internationalization';

interface Props {
  data: {
    enabled: boolean;
  };
}

const SecurityCodeSuccess: React.FC<Props> = ({ data: { enabled } }) => {
  const { translate } = useLocalization();

  const { setPopUpsOpen } = useContext(ContextPopUps);

  return (
    <SecurityCodeSuccessContainer>
      {' '}
      <h2 className="securityCode__title">{translate('popups.security.success.title')}</h2>
      <p className="securityCode__subTitle">
        {translate('popups.security.success.description')}{' '}
        {enabled ? translate('popups.security.success.enabled') : translate('popups.security.success.disabled')}.
      </p>
      <img className="securityCode__img" src={securityIcon} width="300px" height="300px" alt="security Icon" />
      <ButtonSubmit
        handleButton={() => setPopUpsOpen(null)}
        variant={ButtonType.secondary}
        width="100%"
        title={translate('general.buttons.close')}
        color="#242c45"
      />
    </SecurityCodeSuccessContainer>
  );
};
export default SecurityCodeSuccess;
