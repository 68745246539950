import { amount } from 'func/common';
import { bigThen } from 'func/prepareDataDice';
import { Token } from 'types/requestTypes';
import { ICurrencyItem } from 'components/Base/CurrensiesTemplate/types';

interface AffiliateBalance {
  availableBalance?: number;
  __typename?: string;
  tokenCode: string;
  totalCollected?: string;
  displayName?: string;
}

interface DataElem {
  title: string;
  src: string;
  displayName: string;
}

export const getDataForCurrencySlider = (tokenData: Token[]): Token[][] => {
  const arraySize = 6;
  const slicedArray = [];

  for (let i = 0; i < tokenData.length; i += arraySize) {
    slicedArray.push(tokenData.slice(i, i + arraySize));
  }

  return slicedArray;
};

export const getDefaultCurrenciesTemplate = (tokenData: Token[]): ICurrencyItem[] => {
  return tokenData.map((el) => ({
    title: String(amount('0')),
    src: el.displayName,
    displayName: el.displayName,
  }));
};

export const createCurrenciesAllTokensData = (
  tokenData: ICurrencyItem[],
  currencies: ICurrencyItem[],
): ICurrencyItem[] => {
  return tokenData.map((el) => {
    const currentToken = currencies.find((item) => item.displayName === el.displayName);
    return {
      title: currentToken ? String(amount(currentToken.title)) : el.title,
      displayName: currentToken ? currentToken.displayName : el.displayName,
      src: currentToken ? currentToken.src : el.src,
    };
  });
};

export const getDataForCurrencySliderByData = (tokenData: ICurrencyItem[], displayCurrencies: number): DataElem[][] => {
  const arraySize = displayCurrencies;
  const slicedArray = [];

  for (let i = 0; i < tokenData.length; i += arraySize) {
    slicedArray.push(tokenData.slice(i, i + arraySize));
  }

  return slicedArray;
};

export const prepareAffiliateBalance = (arrayData: AffiliateBalance[]): Array<string & DataElem> | DataElem[] => {
  const affiliate = arrayData || [];
  const balanceData = [];

  affiliate.forEach((elem) => {
    const elemObj = {
      title: amount(elem.availableBalance || '0'),
      src: elem.tokenCode,
      displayName: elem.displayName,
    };
    balanceData.push(elemObj);
  });
  return balanceData;
};

export const handleDisabledCollectButton = (
  data: DataElem[],
  setter: (el: boolean) => void,
  token: string | boolean = false,
): void => {
  if (!token) {
    const disable = data.find((el) => bigThen(el.title, '0.00000000'));
    setter(!disable);
  } else {
    const disable = data.find((el) => el.src === token);
    setter(!bigThen(disable.title, '0.00000000'));
  }
};
