import React from 'react';

import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';

const RankSystemHeader: React.FC = () => {
  const { translate } = useLocalization();
  return (
    <div className={styles.rankHeaderWrap}>
      <h2>{translate('cashback.ranks.title')}</h2>
    </div>
  );
};

export default RankSystemHeader;
