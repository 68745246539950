import eventsIcon from 'assets/img/PageTitles/Events_Icon.svg';

import { useLocalization } from 'components/Internationalization';

import PageTitle from 'components/Base/PageTitle';

const Title: React.FC = () => {
  const { translate } = useLocalization();

  return (
    <>
      <PageTitle icon={eventsIcon} title={translate('events.title')} lineColor="#FFBD00" titleSize="small" largeImage />
      <div className="title__shadow" />
    </>
  );
};

export default Title;
