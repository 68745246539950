import bonusIcon from 'assets/img/BonusPage/bonus-pop-up.webp';
import bonusIconSpins from 'assets/img/BonusPage/bonus-pop-up-spin.webp';
import blueBlur from 'assets/img/BonusPage/bonus-blur-blue.webp';
import braunBlur from 'assets/img/BonusPage/bonus-blur-braun.webp';

import { BonusType } from 'components/Pages/BoonusPage/components/UserBonus/types';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import Button from 'components/Base/Button';
import UserBonusCardFrontTimer from 'components/Pages/BoonusPage/components/UserBonus/components/UserBonusCards/components/UserBonusFrontCard/components/UserBonusCardFrontTimer';

import styles from './styles.module.scss';
import { IBonusItem } from './types';

const BonusItem: React.FC<IBonusItem> = ({ name, type, startAt, endAt, handleBonus }) => {
  const { translate } = useLocalization();

  return (
    <div className={styles.bonusSlide}>
      <h2 className={styles.bonusLoginMainTitle}>{translate('popups.bonus.login.title')}</h2>
      <div className={styles.bonusLoginTitleWrap}>
        <img
          src={type === BonusType.freeSpins ? braunBlur : blueBlur}
          alt="blur"
          className={styles.bonusLoginTitleBlurImg}
        />
        <img
          src={type === BonusType.freeSpins ? bonusIconSpins : bonusIcon}
          alt="bonus"
          className={styles.bonusLoginTitleImg}
        />
        <p className={styles.bonusLoginTitle}>{name}</p>
      </div>
      <div className={styles.bonusLoginTimerWrap}>
        {endAt && startAt ? <UserBonusCardFrontTimer startAt={startAt || new Date()} endAt={endAt} /> : null}
      </div>
      <div className={styles.bonusLoginBtn}>
        <Button
          className={styles.viewAll}
          variant={ButtonType.primary}
          title={translate('popups.bonus.login.buttons.view')}
          width="217px"
          onclick={handleBonus}
        />
      </div>
    </div>
  );
};

export default BonusItem;
