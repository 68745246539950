import usFlag from 'assets/img/Language/us.webp';
import esFlag from 'assets/img/Language/es.webp';
import ruFlag from 'assets/img/Language/ru.webp';

import { Language } from 'components/Internationalization/types';
import { ILanguageOption } from 'components/Sidebar/components/Language/types';

export const LANGUAGE_OPTIONS: ILanguageOption[] = [
  { icon: usFlag, label: 'EN', value: Language.english },
  { icon: esFlag, label: 'ES', value: Language.spanish },
  { icon: ruFlag, label: 'RU', value: Language.russian },
];
