import { gql } from '@apollo/client';

export const FIND_USER_BY_ALIAS = gql`
  query findUsersByExactAlias($alias: String!) {
    findUsersByExactAlias(alias: $alias) {
      alias
      avatar
    }
  }
`;
