import { useContext } from 'react';

import iconCashback from 'assets/img/common/iconCashback.webp';
import iconCollect from 'assets/img/PopUps/Collect/collectedIcon.webp';

import { ContextPopUps } from 'context';
import { getImgSrc } from 'func/common';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import ButtonSubmit from 'components/Base/PopUps/components/ButtonSubmit';

import { ICollect } from './types';
import styles from './styles.module.scss';

const Collect: React.FC<ICollect> = ({ data }) => {
  const { translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const handleClose = () => setPopUpsOpen(null);

  if (!data) return null;

  return (
    <div className={styles.collectWrap}>
      <img src={iconCashback} alt="icon" className={styles.collectImg} />
      <h2 className={styles.collectTitle}>{translate('popups.collect.title')}</h2>
      <div className={styles.collectSlider}>
        <div className={styles.collectSliderScroll}>
          {data?.map((currency) => (
            <div key={currency.displayName} className={styles.collectSliderItem}>
              <div className={styles.collectSliderItemCurrency}>
                <img src={getImgSrc(currency.src)} alt={currency.displayName} />
                <h4>{`${currency.title} ${currency.displayName}`}</h4>
              </div>
              <div className={styles.collectSliderItemFlag}>
                <img src={iconCollect} alt="collected" />
                <p>{translate('popups.collect.collected')}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <p className={styles.collectPara}>{translate('popups.collect.desc')}</p>
      <ButtonSubmit
        className={styles.button}
        handleButton={handleClose}
        variant={ButtonType.primary}
        width="288px"
        title={translate('general.buttons.continue')}
        color="#242c45"
      />
    </div>
  );
};

export default Collect;
