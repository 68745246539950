import styled from 'styled-components';

const CoinHistoryStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  height: 54px;
  padding: 0 15px;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 40px 50px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  overflow: hidden;

  .coin__history__title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;

    display: flex;
    align-items: center;
    color: #ffffff;
    margin-right: 25px;
  }

  .coin__history__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row-reverse;
    width: 100%;
    overflow: hidden;
  }

  .coin__history__content-elem {
    min-width: 40px;
    height: 45px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    mix-blend-mode: normal;
    margin: 0 5px;

    text-align: center;
  }
  .coin__history__content-elem.first {
    -moz-animation: newNumber 0.8s ease-in-out;
    -webkit-animation: newNumber 0.8s ease-in-out;
    -ms-animation: newNumber 0.8s ease-in-out;
    -o-animation: newNumber 0.8s ease-in-out;
    animation: newNumber 0.8s ease-in-out;
    transform-origin: right;
    overflow: hidden;
  }

  .coin__history--img__win {
    display: inline-block;
    width: 40px;
  }
  .coin__history--img__lose {
    display: inline-block;
    width: 40px;
    opacity: 0.5;
  }

  @keyframes newNumber {
    0% {
      -moz-transform: translateX(125%);
      -webkit-transform: translateX(125%);
      -ms-transform: translateX(125%);
      -o-transform: translateX(125%);
      transform: translateX(125%);
    }
    80% {
      -moz-transform: translateX(125%);
      -webkit-transform: translateX(125%);
      -ms-transform: translateX(125%);
      -o-transform: translateX(125%);
      transform: translateX(125%);
    }
    100% {
      -moz-transform: translateX(0);
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @media (max-width: 1300px) {
  }
  @media (max-width: 1250px) {
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 680px) {
  }
  @media (max-width: 580px) {
    .coin__history__title {
      display: none;
    }
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 400px) {
  }
`;

export default CoinHistoryStyle;
