import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_STAR_POOL } from 'graphQl/query/dividends/starTokens';
import planetIcon from 'assets/img/DividendsPage/Planet.svg';
import starsIcon from 'assets/img/DividendsPage/stars.svg';
import { amount, getImgSrc } from 'func/common';
import { useLocalization } from 'components/Internationalization';

import HighCardTitle from 'components/Base/Cards/HighCardTitle';

import { IStarPoolFront } from './types';
import styles from './styles.module.scss';

const StarPoolFront: React.FC<IStarPoolFront> = ({ isFlipped, setIsFlipped }) => {
  const { translate } = useLocalization();
  const [poolAmount, setPoolAmount] = useState('0');

  const { data: poolData, startPolling, stopPolling } = useQuery(GET_STAR_POOL, { fetchPolicy: 'cache-and-network' });

  const handleFlip = () => setIsFlipped(!isFlipped);

  useEffect(() => {
    if (poolData) {
      setPoolAmount(poolData.starTokenPool);
    }
  }, [poolData]);

  useEffect(() => {
    startPolling(60000);
    return () => {
      stopPolling();
    };
  }, []);

  return (
    <HighCardTitle
      title={translate('dividends.star.pool.title')}
      subtitle={translate('dividends.star.pool.description')}
      offBlur
    >
      <button className={styles.poolBtn} type="button" onClick={handleFlip}>
        {translate('dividends.star.history.title')}
      </button>
      <div className={styles.poolCardFront}>
        <div className={styles.poolCardFrontAmount}>
          <img src={getImgSrc('STAR')} alt="icon" />
          <p>{`${amount(poolAmount)} STARs`}</p>
        </div>
        <>
          <img src={planetIcon} alt="icon" className={styles.poolCardFrontPlanet} />
          <img src={starsIcon} alt="icon" className={styles.poolCardFrontStars} />
        </>
      </div>
    </HighCardTitle>
  );
};

export default StarPoolFront;
