import styled from 'styled-components';

interface Props {
  width: string;
  height: string;
}

const LotteryTitleSquareStyle = styled.div.withConfig({
  shouldForwardProp: (prop) => !['width'].includes(prop),
})<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height};
  width: ${(props) => props.width};

  background: rgba(136, 138, 167, 0.15);
  mix-blend-mode: normal;
  border-radius: 8px;

  h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;

    color: #ffffff;
  }

  h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;

    color: #ffffff;
    margin: 0;
  }

  @media (max-width: 1300px) {
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1100px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 580px) {
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 400px) {
  }
`;

export default LotteryTitleSquareStyle;
