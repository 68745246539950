import Big from 'big.js';
import { pathOr } from 'ramda';
import { useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';

import { ReactComponent as ArrowIcon } from 'assets/img/common/arrow-left.svg';

import { IRank } from 'types';
import { ContextPopUps } from 'context';
import { popUps } from 'components/constants/constants';
import { GET_RANKS } from 'graphQl/query/cashback/ranks';
import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';
import { IRakebackInfo } from './types';

const RakebackInfo: React.FC<IRakebackInfo> = ({ data }) => {
  const { translate, language } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [rakebackByRank, setRakebackByRank] = useState<Record<string, number[]>>({});
  const [secondPara, setSecondPara] = useState('');
  const [thirdPara, setThirdPara] = useState('');

  const { data: ranksData } = useQuery(GET_RANKS, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (ranksData) {
      const ranks = pathOr<IRank[]>([], ['ranks'], ranksData);
      const sortedRanks = [...ranks].sort((a, b) => a.id - b.id);
      const withRakeback = sortedRanks.filter((r) => Big(r.rakeback).gt(0));

      const rakebackPercentByRanks = withRakeback.reduce<Record<string, number[]>>((acc, r) => {
        if (acc[r.rakeback]) {
          acc[r.rakeback].push(r.id);
          return acc;
        }

        acc[r.rakeback] = [r.id];
        return acc;
      }, {});

      setRakebackByRank(rakebackPercentByRanks);
    }
  }, [ranksData]);

  const handleGoBack = () => {
    setPopUpsOpen(popUps.rakebackCollect);
  };

  useEffect(() => {
    const textFirst = translate('popups.rakeback.inform.para.middle');
    const textSecond = translate('popups.rakeback.inform.para.bottom');
    const newTextFirst = textFirst.replace(':minRank', data.minRank).replace(':dealy', data.dealy);
    const newTextSecond = textSecond.replace(':minWithdrawal', data.minWithdrawal);

    setSecondPara(newTextFirst);
    setThirdPara(newTextSecond);
  }, [language, data]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.gradient} />
      <ArrowIcon className={styles.back} onClick={handleGoBack} />
      <div className={styles.content}>
        <h3 className={styles.title}>{translate('popups.rakeback.inform.title')}</h3>
        <p className={styles.desc}>{translate('popups.rakeback.inform.para.top')}</p>
        <p className={styles.desc}>{secondPara} </p>
        <p className={styles.desc}>{thirdPara}</p>
      </div>
      <div className={styles.tableWrapper}>
        <p className={styles.tableTitle}>{translate('popups.rakeback.inform.persantage')}:</p>
        <ul className={styles.ranks}>
          {Object.entries(rakebackByRank).map(([rakebackPercentage, ranks]) => (
            <li key={rakebackPercentage} className={styles.rank}>
              <span className={styles.percentage}>{rakebackPercentage}%</span>
              <span>
                {ranks[0]}
                {ranks[0] !== ranks[ranks.length - 1] ? ` - ${ranks[ranks.length - 1]}` : ''}
              </span>
              <span className={styles.text}>{translate('popups.rakeback.inform.rank')}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RakebackInfo;
