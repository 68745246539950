import * as yup from 'yup';

import { ISignUpValues } from 'components/Base/PopUps/components/SignUp/types';
import { ILoginValues } from 'components/Base/PopUps/components/LogIn/types';
import { TranslationType } from 'types';

export enum AuthErrors {
  passwordIsNotValid = 'Password must contain 1 Uppercase, 1 Lowercase, 1 number minimum and 8 characters long.',
  promocodeIsNotValid = 'The promo code must contain 10 characters.',
  unavailable = 'Unfortunately, StarBets.io is not available in your region',
  notVerified = 'Email is not verified',
  expiredSLT = 'Invalid or expired short-lived token.',
  tooManyRequests = 'Too Many Requests',
  accountDeleted = 'This account has been deleted',
  wrongCredentials = 'Wrong credentials',
  notFound = 'User not found',
  excluded = 'This account has been excluded',
}

export const initialSignUpState: ISignUpValues = {
  email: '',
  password: '',
  confirmPassword: '',
  isAdult: false,
  referrerCode: '',
  promoCode: '',
  marketing: false,
};

export const initialLoginState: ILoginValues = {
  email: '',
  password: '',
};

export const getSignupValidationSchema = (t: TranslationType) => {
  const invalidEmailError = t('popups.signup.errors.invalid-email');
  const requiredEmailError = t('popups.signup.errors.email-required');
  const requiredPasswordError = t('popups.signup.errors.password-required');
  const passwordUppercaseError = t('popups.signup.errors.password-uppercase');
  const passwordLowercaseError = t('popups.signup.errors.password-lowercase');
  const passwordNumbersError = t('popups.signup.errors.password-numbers');
  const passwordLengthError = t('popups.signup.errors.password-length');
  const passwordCharactersError = t('popups.signup.errors.password-characters');
  const passwordMatchError = t('popups.signup.errors.password-match');
  const requiredConfirmPasswordError = t('popups.signup.errors.confirm-required');
  const adultError = t('popups.signup.errors.adult');

  return yup.object({
    email: yup.string().email(invalidEmailError).max(255).required(requiredEmailError),
    password: yup
      .string()
      .test('contain-uppercase', passwordUppercaseError, (pass) => {
        const UPPERCASE_REGEX = /[A-Z]/gm;
        const hasUpperCaseLetter = UPPERCASE_REGEX.test(pass);

        return hasUpperCaseLetter;
      })
      .test('contain-lowercase', passwordLowercaseError, (pass) => {
        const LOWERCASE_REGEX = /[a-z]/gm;
        const hasLowerCaseLetter = LOWERCASE_REGEX.test(pass);

        return hasLowerCaseLetter;
      })
      .test('contain-number', passwordNumbersError, (pass) => {
        const NUMBER_REGEX = /[0-9]/gm;
        const hasNumber = NUMBER_REGEX.test(pass);

        return hasNumber;
      })
      .test('min-length', passwordLengthError, (pass) => {
        return pass?.length >= 8;
      })
      .test('only-default-sybmols', passwordCharactersError, (pass) => {
        const ONLY_DEFAULT_SYMBOLS_REGEX = /^[a-zA-Z0-9*.!@$%^`'&(){}[\]:;<>,.?\\/~_+-=|]+$/gm;
        const hasOnlyDefaultSybmols = ONLY_DEFAULT_SYMBOLS_REGEX.test(pass);

        return hasOnlyDefaultSybmols;
      })
      .required(requiredPasswordError),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], passwordMatchError)
      .required(requiredConfirmPasswordError),
    promoCode: yup.string(),
    isAdult: yup.boolean().oneOf([true], adultError),
  });
};
