import React from 'react';

import otherIcon from 'assets/img/PopUps/suportIcon.webp';
import { useLocalization } from 'components/Internationalization';

import { SupportInfoStyle } from './styles';

const SupportInfo: React.FC = () => {
  const { translate } = useLocalization();
  return (
    <SupportInfoStyle>
      <div className="support__info--title-wrap">
        <div className="support__info--title">{translate('popups.support.title')}</div>
      </div>
      <div className="support__info--content">
        <div className="contact__content--item">
          <img src={otherIcon} alt="icon" />
          <h5>{translate('popups.support.description')}</h5>
          <h4>support@starbets.io</h4>
        </div>
      </div>
    </SupportInfoStyle>
  );
};

export default SupportInfo;
