/* eslint-disable react/destructuring-assignment */
import clsx from 'clsx';
import { components } from 'react-select';

import styles from '../../styles.module.scss';
import { TSelectOption } from './types';

const { Option } = components;

const SelectOption: React.FC<TSelectOption> = (props) => {
  const { label, icon } = props.data;

  return (
    <Option {...props} className={clsx(props.className, '!relative !mb-1 !flex !items-center !py-2')}>
      {icon ? (
        <div className={styles.iconWrapper}>
          <img className={styles.icon} src={icon} alt={label} />
        </div>
      ) : null}
      <span>{label}</span>
    </Option>
  );
};

export default SelectOption;
