import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';

import diceIcon from 'assets/img/games/dice-small.webp';
import minesIcon from 'assets/img/games/mines-small.webp';
import limboIcon from 'assets/img/games/limbo-small.webp';
import coinflipIcon from 'assets/img/games/coinflip-small.webp';
import kenoIcon from 'assets/img/games/keno-small.webp';

import { GameCategory } from 'types';

import Link from 'components/Base/Links/Link';

import styles from './styles.module.scss';
import { IGamesAdditionalItem } from './types';

const smallIcons = {
  Dice: diceIcon,
  Mines: minesIcon,
  Limbo: limboIcon,
  Coinflip: coinflipIcon,
  Keno: kenoIcon,
};

const GamesAdditionalItem: React.FC<IGamesAdditionalItem> = ({ game, hoverGameName, onChangeHoverGameName }) => {
  const [link, setLink] = useState('');
  const [icon, setIcon] = useState('');

  useEffect(() => {
    const { name, category, slug } = game;

    if (category === GameCategory.InHouse) {
      const newLink = `/in-house/${slug}`;
      const newIcon = smallIcons[name];
      setIcon(newIcon);

      setLink(newLink);
    } else {
      const newLink = `/slots/${slug}`;

      setLink(newLink);
    }
  }, [game]);

  const handleLinkClick = () => {
    scroll.scrollTo(0, { duration: 0, delay: 0 });
  };

  return (
    <li
      className={clsx(styles.wrapper, hoverGameName === game.name ? styles.hovered : '')}
      onMouseEnter={() => onChangeHoverGameName(game.name)}
      onMouseLeave={() => onChangeHoverGameName('')}
    >
      <Link
        className={clsx(styles.link, hoverGameName && hoverGameName !== game.name ? styles.hidden : '')}
        to={link}
        onClick={handleLinkClick}
      >
        <img className={styles.image} src={icon || game.src} alt={game.name} />
      </Link>
    </li>
  );
};

export default GamesAdditionalItem;
