import React from 'react';

import { IBlogContext } from './types';

const BlogContext = React.createContext<IBlogContext>({
  articles: [],
  allArticles: [],
  tag: undefined,
  pagination: undefined,
  loading: true,
  searched: false,
  onChangePage: () => null,
  onSearch: () => null,
  onGetArticlesByTag: () => null,
  onChangeSort: () => null,
});

export default BlogContext;
