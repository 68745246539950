import { useContext, useState } from 'react';
import { pathOr } from 'ramda';
import { useQuery } from '@apollo/client';

import gamesIcon from 'assets/img/GamesFiler/All-active.webp';
import { useAppSelector } from 'hooks/useAppSelector';
import { Links } from 'components/constants';
import { ContextPopUps } from 'context';
import { GET_GAMES } from 'graphQl/query/games/gameQueries';
import { getFormattedGames } from 'func/prepareDataGamesPage';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setFavoriteGamesList } from 'store/data';
import { userProfile } from 'store/user/user.selectors';
import { dataFavoriteGamesList } from 'store/data/data.selectors';
import { useLocalization } from 'components/Internationalization';
import { useNavigate } from 'hooks/useNavigate';

import GamesAdditionalItem from './components/GamesAdditionalItem';
import Link from 'components/Base/Links/Link';

import styles from './styles.module.scss';

const GamesAdditional: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { translate } = useLocalization();

  const [hoveredGame, setHoveredGame] = useState('');

  const user = useAppSelector(userProfile);
  const favoriteGamesList = useAppSelector(dataFavoriteGamesList);

  useQuery(GET_GAMES, {
    fetchPolicy: 'no-cache',
    skip: !user,
    variables: {
      gameListParams: { offset: 0, limit: 20, isFavorite: true, userId: user?.id },
    },
    onCompleted: (data) => {
      const newGames = pathOr([], ['gameList', 'items'], data);
      const formattedGames = getFormattedGames(newGames, navigate, setPopUpsOpen, translate);
      dispatch(setFavoriteGamesList(formattedGames));
    },
  });

  const handleChangeHoverGameName = (name: string) => {
    setHoveredGame(name);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <img src={gamesIcon} alt="Games icon" />
        <p className={styles.title}>Favorite games</p>
      </div>
      <ul className={styles.games}>
        {favoriteGamesList.map((g) => (
          <GamesAdditionalItem
            key={g.gameId}
            game={g}
            hoverGameName={hoveredGame}
            onChangeHoverGameName={handleChangeHoverGameName}
          />
        ))}
      </ul>
      {!favoriteGamesList.length ? (
        <div className={styles.notFound}>
          <p>You didn&apos;t add any game</p>
          <Link to={Links.games}>Open Games</Link>
        </div>
      ) : null}
    </div>
  );
};

export default GamesAdditional;
