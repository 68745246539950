import styled from 'styled-components';
import { ICoinWinAnimationStyle } from './types';

const CoinWinAnimationStyle = styled.div.withConfig({
  shouldForwardProp: (prop) => !['fastmode'].includes(prop),
})<ICoinWinAnimationStyle>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 3;

  .coin__win--animation {
    position: relative;
  }

  .coin__win--img,
  .coin__lose--img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    opacity: 0;
    width: 200px;
  }

  .coin__win--div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    opacity: 0;
    width: 200px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
    border-radius: 16px;

    img {
      display: inline-block;
      width: 35px;
      margin: 0 3px 0 0;
    }
    h4 {
      color: #ffffff;
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .coin__win--img.active {
    animation: ${(props) =>
      props.fastmode ? 'appearCoinWinInfo 0.3s ease-in-out' : 'appearCoinWinInfo 0.8s ease-in-out'};
    visibility: visible;
    animation-delay: ${(props) => (props.fastmode ? '0.3s' : '1.3s')};
  }
  .coin__lose--img.active {
    animation: ${(props) =>
      props.fastmode ? 'appearCoinWinInfoLoose 0.3s ease-in-out' : 'appearCoinWinInfoLoose 0.8s ease-in-out'};
    visibility: visible;
    animation-delay: ${(props) => (props.fastmode ? '0.3s' : '1.3s')};
  }
  .coin__win--div.active {
    animation: ${(props) =>
      props.fastmode ? 'appearCoinWinInfoMobile 0.7s ease-in-out' : 'appearCoinWinInfoMobile 1.1s ease-in-out'};
    visibility: visible;
  }

  @keyframes appearCoinWinInfo {
    0% {
      transform: translate(-50%, -50%);
      opacity: 0;
    }
    30% {
      opacity: 1;
      transform: translate(-50%, -250%);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -250%);
    }
  }

  @keyframes appearCoinWinInfoLoose {
    0% {
      transform: translate(-50%, -50%);
      opacity: 0;
    }
    20% {
      opacity: 1;
      transform: translate(-50%, 150%);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, 150%);
    }
  }

  @keyframes appearCoinWinInfoMobile {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 1300px) {
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1024px) {
    .coin__win--img,
    .coin__lose--img {
      transform: translate(-50%, -50%);
      width: 190px;
    }
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
    .coin__win--img,
    .coin__lose--img {
      transform: translate(-50%, -50%);
      width: 160px;
    }
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 580px) {
    .coin__win--img,
    .coin__lose--img {
      transform: translate(-50%, -50%);
      width: 160px;
      visibility: visible;
    }
    .coin__win--img.active,
    .coin__lose--img.active {
      animation: ${(props) =>
        props.fastmode ? 'appearCoinWinInfoMobile 0.3s ease-in-out' : 'appearCoinWinInfoMobile 0.4s ease-in-out'};
      animation-delay: ${(props) => (props.fastmode ? '0.3s' : '1.3s')};
    }
    .coin__win--div {
      width: 150px;
      height: 60px;
      transform: translate(-50%, -50%);

      img {
        width: 30px;
      }
      h4 {
        font-size: 14px;
      }
    }
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 460px) {
  }
  @media (max-width: 360px) {
  }
`;

export default CoinWinAnimationStyle;
