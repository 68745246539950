/* eslint-disable @typescript-eslint/no-explicit-any */
import { IUserState } from './user.state';
import { ISetToken, ISetUserData } from './user.types';

const userReducers = {
  setUser: (state: IUserState, action: ISetUserData): void => {
    state.profile = action.payload;
  },
  setToken: (state: IUserState, action: ISetToken): void => {
    state.token = action.payload;
  },
};

export default userReducers;
