import { useState } from 'react';

import { EventsPageTablesTitles, EVENTS_TABLE_TITLES } from 'components/constants/table';
import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile } from 'store/user/user.selectors';

import TablesTitles from 'components/common/TablesTitles';

import HistoryEventsTable from './components/HistoryEventsTable';
import EventsTablesStyle from './stylex';

const EventsTable: React.FC = () => {
  const user = useAppSelector(userProfile);
  const [table, setTable] = useState<string>(EventsPageTablesTitles.history);

  const handleTitle = (title: string) => {
    setTable(title);
  };

  if (!user) {
    return null;
  }

  return (
    <EventsTablesStyle>
      <TablesTitles currentTitle={table} titles={EVENTS_TABLE_TITLES} onTitle={handleTitle} />
      {table === EventsPageTablesTitles.history ? <HistoryEventsTable /> : null}
    </EventsTablesStyle>
  );
};

export default EventsTable;
