import { useState } from 'react';

import { IN_HOUSE_TABLE_TITLES, InHouseGamesPageTablesTitles } from 'components/constants/table';

import TablesTitles from 'components/common/TablesTitles';
import MyCoinBets from './components/MyCoinBets';

import TablesStyleCoin from './styles';
import { ITableCoin } from './types';

const TableCoin: React.FC<ITableCoin> = ({ history }) => {
  const [table, setTable] = useState(InHouseGamesPageTablesTitles.myBets);

  const handleTitle = (title) => {
    setTable(title);
  };

  return (
    <TablesStyleCoin>
      <TablesTitles currentTitle={table} titles={IN_HOUSE_TABLE_TITLES} onTitle={handleTitle} />
      {table === InHouseGamesPageTablesTitles.myBets ? <MyCoinBets history={history} /> : null}
    </TablesStyleCoin>
  );
};

export default TableCoin;
