import { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { pathOr } from 'ramda';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { setGames } from 'store/data';
import { GAMES_REQUEST_STEP } from 'components/constants/games';
import { ContextPopUps } from 'context';
import { CATEGORY_NAMES, EGamesTabs, GAMES_QUALITY } from 'components/constants/constants';
import GamesNavigation from 'components/common/GamesNavigation';
import {
  getCategoryName,
  getCategoryToRequest,
  getFormattedGames,
  getProviderRequest,
} from 'func/prepareDataGamesPage';
import { GET_GAMES } from 'graphQl/query/games/gameQueries';
import { GET_MY_BONUSES } from 'graphQl/query/bonus/indes';
import { userProfile, userToken } from 'store/user/user.selectors';
import { dataFreeSpinsSettings, dataGames } from 'store/data/data.selectors';
import { useLocalization } from 'components/Internationalization';
import { useNavigate } from 'hooks/useNavigate';
import { useAnalyticsDispatch } from 'hooks/useAnalyticsDispatch';
import { DataLayerEvent } from 'types/dataLayerTypes';

import GamesCards from 'components/Base/GamesCards';
import GamesCardsSlider from 'components/common/GamesCardsSlider';
import PragmaticDGAProvider from 'context/contextPragmatic';
import searchIcon from 'assets/img/common/searchIcon.svg';
import styles from './styles.module.scss';

const Games: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { translate } = useLocalization();
  const { search: locationSearch } = useLocation();
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const dataLayerDispatch = useAnalyticsDispatch();

  const [categoryUrl, setCategoryUrl] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [formattedGames, setFormattedGames] = useState([]);
  const [offSet, setOffset] = useState(0);
  const [countLoad, setCountLoad] = useState(1);
  const [itemsCount, setItemsCount] = useState(0);
  const [queryTimeout, setQueryTimeout] = useState<NodeJS.Timeout>();

  const user = useAppSelector(userProfile);
  const token = useAppSelector(userToken);
  const gamesList = useAppSelector(dataGames);
  const freeSpinsSettings = useAppSelector(dataFreeSpinsSettings);

  const [getGames] = useLazyQuery(GET_GAMES, { fetchPolicy: 'no-cache' });
  useQuery(GET_MY_BONUSES, {
    fetchPolicy: 'cache-and-network',
    skip: !token || !freeSpinsSettings,
  });

  const handleChangeSearchName = async (event) => {
    if (queryTimeout) clearTimeout(queryTimeout);

    const value = event.target.value;
    setInputValue(value);
    dataLayerDispatch({
      event: DataLayerEvent.viewSearchResult,
      user_id: user?.id,
      search_position: 'Home',
      search_term: value,
    });

    const newQueryTimeout = setTimeout(() => {
      if (value.length >= 3) {
        const gameListParams = {
          offset: 0,
          limit: 100,
          name: `${value}`,
        };

        getGames({ variables: { gameListParams } }).then((res) => {
          const newGames = pathOr([], ['data', 'gameList', 'items'], res);
          const games = getFormattedGames(newGames, navigate, setPopUpsOpen, translate);

          setFormattedGames(games);
        });
      }

      if (!value) {
        setFormattedGames(gamesList.filter((_, index) => index < countLoad * GAMES_REQUEST_STEP));
      }
    }, 300);

    setQueryTimeout(newQueryTimeout);
  };

  const handleGetGames = async (categoryName: string, providerName: string, offset: number, limit: number) => {
    const gameListParams = {
      offset,
      limit,
      new: categoryName === EGamesTabs.new ? true : null,
      popular: categoryName === EGamesTabs.popular ? true : null,
      category: getCategoryToRequest(categoryName),
      isFavorite: categoryName === EGamesTabs.favorite ? true : null,
      isDropsAndWins: categoryName === EGamesTabs.dropsAndWins ? true : null,
      providerName: getProviderRequest(providerName),
      userId: user?.id,
    };
    const response = await getGames({ variables: { gameListParams } });
    const newGames = pathOr([], ['data', 'gameList', 'items'], response);
    const games = getFormattedGames(newGames, navigate, setPopUpsOpen, translate);
    const prevGames = offset ? gamesList : [];

    dispatch(setGames([...prevGames, ...games]));

    const count = pathOr(0, ['data', 'gameList', 'count'], response);
    setItemsCount(count);
  };

  const handleLoadMoreGames = () => {
    const categoryName = getCategoryName(categoryUrl);

    setFormattedGames(gamesList.filter((_, index) => index < offSet));
    handleGetGames(categoryName, '', offSet, GAMES_REQUEST_STEP);

    setOffset(offSet + GAMES_REQUEST_STEP);
    setCountLoad(countLoad + 1);
  };

  useEffect(() => {
    const params = new URLSearchParams(locationSearch);
    const category = params.get(GAMES_QUALITY.categories.id);
    setCategoryUrl(category);
  }, [locationSearch]);

  useEffect(() => {
    const categoryName = getCategoryName(categoryUrl);
    if (token && user && categoryName) {
      dispatch(setGames([]));
      setCountLoad(1);

      handleGetGames(categoryName, '', 0, GAMES_REQUEST_STEP * 2);

      setOffset(GAMES_REQUEST_STEP * 2);
    }
    if (!token && categoryName) {
      dispatch(setGames([]));
      setCountLoad(1);

      handleGetGames(categoryName, '', 0, GAMES_REQUEST_STEP * 2);

      setOffset(GAMES_REQUEST_STEP * 2);
    }
  }, [categoryUrl, user]);

  useEffect(() => {
    if (gamesList?.length && countLoad === 1) {
      setFormattedGames(gamesList.filter((_, index) => index < GAMES_REQUEST_STEP));
    }
  }, [gamesList, countLoad]);

  return (
    <PragmaticDGAProvider>
      <h2 className={styles.homeGamesTitle}>{translate('homepage.games.title')}</h2>
      <GamesNavigation inputValue={inputValue} setInputValue={setInputValue} onSearch={handleChangeSearchName} />
      <div className={styles.homeGamesCategories}>
        <img src={searchIcon} alt="icon" />
        <input
          type="text"
          value={inputValue}
          onChange={handleChangeSearchName}
          placeholder="Search"
          className={styles.homeGamesInput}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
        />
      </div>
      {(categoryUrl === CATEGORY_NAMES.allGames.urlTitle || !categoryUrl) && !inputValue ? (
        <div className={styles.homeGamesSliders}>
          <GamesCardsSlider />
        </div>
      ) : (
        <GamesCards
          data={formattedGames}
          onLoad={handleLoadMoreGames}
          isDisableButton={formattedGames?.length >= itemsCount}
          hideButton={Boolean(inputValue)}
        />
      )}
    </PragmaticDGAProvider>
  );
};

export default Games;
