import React, { useContext } from 'react';

import { useKeno } from 'context/contextKeno/contextKeno';
import { Types } from 'context/contextKeno/kenoReduser';
import SocketContext from 'context/contextSocket/context';

import { disableKenoButtons } from 'func/prepareDataKeno';
import KenoCheckboxAuto from './KenoCheckboxAuto';

const KenoSwitchAuto: React.FC = () => {
  const [state, dispatch] = useKeno();
  const { connected: socketConnected } = useContext(SocketContext);

  const { isAuto, gameCondition, disable } = state;

  const handleIsAutoMode = () => dispatch({ type: Types.IS_AUTO });

  return (
    <KenoCheckboxAuto
      value={isAuto}
      onCheck={handleIsAutoMode}
      disable={disableKenoButtons(gameCondition, disable) || !socketConnected}
    />
  );
};

export default KenoSwitchAuto;
