import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

interface Props {
  toggle?: boolean;
}

const SidebarStyle = styled.aside<Props>`
  z-index: 5;
  display: ${(props) => (!props.toggle ? 'flex' : 'none')};
  flex-direction: column;
  width: 100px;
  background: linear-gradient(0deg, #0f1522, #0f1522), rgba(0, 0, 0, 0.3);

  justify-content: space-between;

  position: sticky;
  top: 75px;
  height: calc(100vh - 75px);

  .sidebar__sticky {
    display: ${(props) => (!props.toggle ? 'flex' : 'none')};
    flex-direction: column;
    width: 100px;

    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
  }
  .sidebar__sticky::-webkit-scrollbar {
    width: 0;
  }

  a {
    text-decoration: none;
    cursor: url(${point}), auto;
  }
  .link-events {
    pointer-events: auto;
  }

  .sidebar__item {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: 0.15s ease-in-out;
    -webkit-transform: scale(1);
    transform: scale(1);

    min-height: 70px;
    height: 70px;

    &:hover {
      background: #161e31;

      .sidebar__icon {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
  }

  .sidebar__icon {
    width: 28px;
    height: 28px;
    opacity: 1;
    cursor: url(${point}), auto;
    transition: 0.15s ease-in-out;
  }
  .sidebar__item-title {
    margin: 10px 0 0 0;
    padding: 0 10px;

    font-family: 'OpenSans';
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: white;

    transition: 0.15s ease-in-out;
  }
  .sidebar__item-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .sidebar__item-content.events {
    pointer-events: auto;
  }
  h4 {
    font-family: 'OpenSans';
    font-weight: 600;
    font-size: 13px;
    color: white;
    cursor: url(${point}), auto;
    padding-left: 20px;
    margin-right: -40px;
  }

  .sidebar__item-content-hover {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    color: #ffffff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      color: #ffffff;
      margin: 0;
      z-index: 1;
      opacity: 1;
      font-family: 'Montserrat';
      font-weight: 800;
      font-size: 11px;
    }
  }

  .sidebar__language--wrap {
    display: flex;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 30px;
  }

  @media (max-width: 1024px) {
    display: ${(props) => (!props.toggle ? 'none' : 'flex')};
    flex-wrap: wrap;
    width: 100%;
    padding-top: 0;

    .sidebar__sticky {
      display: ${(props) => (!props.toggle ? 'none' : 'flex')};
      flex-wrap: wrap;
      width: 100%;
      padding-top: 0;
      height: 225px;
    }
    .sidebar__item {
      width: 50%;
      height: 75px;
      &:hover {
        .sidebar__item-content {
          background-color: transparent;
        }
      }
    }
    .sidebar__icon {
      width: 75px;
      height: 75px;
      //margin-right: 20px;
    }
    .sidebar__icon-events {
      width: 75px;
      height: 75px;
      //margin-right: 20px;
    }

    .sidebar__item-content {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      p {
        margin: 0;
      }
      h3 {
        padding-right: 18px;
        margin: 0;
      }
      h4 {
        margin-bottom: 0;
        line-height: 30px;
      }
      .sidebar__icon-events-text {
        padding-right: 10px;
      }
    }
    .sidebar__user-icon {
      margin-left: -17px;
    }
    background: linear-gradient(360deg, #161e31 0%, rgba(22, 30, 49, 0.75) 100%);
  }
`;

export default SidebarStyle;
