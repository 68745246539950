import React from 'react';

import { IContextTournament } from './types';

const TournamentContext = React.createContext<IContextTournament>({
  tournamentGames: [],
  tournament: null,
  tournamentsList: [],
  isLoadingTournaments: true,
  itemsCount: 0,
  onLoadMoreGames: () => {},
});

export default TournamentContext;
