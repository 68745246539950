import CardWrapperStyle from './styles';
import { ICardWrapper } from './types';

const CardBlock: React.FC<ICardWrapper> = ({
  children,
  height,
  overflow,
  onTouchMove,
  marginTop,
  content,
  marginBottom,
  tablePopUp,
  offBlur,
  zIndex,
}) => (
  <CardWrapperStyle
    $height={height}
    $overflow={overflow}
    onTouchMove={onTouchMove}
    $marginTop={marginTop}
    content={content}
    $marginBottom={marginBottom}
    $tablePopUp={tablePopUp}
    $offBlur={offBlur}
    $zIndex={zIndex}
  >
    {children}
  </CardWrapperStyle>
);

export default CardBlock;
