import { useContext, useState } from 'react';

import faqIcon from 'assets/img/PageTitles/FAQ_Icon.svg';

import { DOCUMENT_TITLE_SETTINGS } from 'components/constants/constants';
import { FAQSort } from 'components/constants/faq';
import IntercomContext from 'context/contextIntercom/context';

import DocumentTitle from 'components/Base/DocumentTitle';
import PageTitle from 'components/Base/PageTitle';
import Loader from 'components/Base/Loader';

import FAQToolbar from './components/FAQToolbar';
import FAQCategories from './components/FAQCategories';

import styles from './styles.module.scss';

const FAQPage: React.FC = () => {
  const { collections, loading } = useContext(IntercomContext);

  const [sortBy, setSortBy] = useState(FAQSort.date);
  const [search, setSearch] = useState('');

  const handleChangeSort = (sort: string) => {
    setSortBy(sort as FAQSort);
  };

  const handleChangeSearch = (value: string) => {
    setSearch(value);
  };

  if (loading) {
    return <Loader pages />;
  }

  return (
    <main className={styles.wrapper}>
      <DocumentTitle data={DOCUMENT_TITLE_SETTINGS.faq} />
      <PageTitle icon={faqIcon} title="faq" lineColor="#C22328" titleSize="big" largeImage />
      <FAQToolbar sort={sortBy} search={search} onChangeSort={handleChangeSort} onChangeSearch={handleChangeSearch} />
      <FAQCategories sort={sortBy} search={search} collections={collections} />
    </main>
  );
};

export default FAQPage;
