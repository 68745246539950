import { useEffect, useState } from 'react';

import ticketIcon from 'assets/img/Lottery/ticketIcon.webp';

import { amount, getImgSrc } from 'func/common';
import { TimerSize } from 'components/Games/Lottery/base/LotteryTimer/types';
import { useLocalization } from 'components/Internationalization';

import LotteryTimer from 'components/Games/Lottery/base/LotteryTimer';

import LotteryDesktopHeaderStyle from './styles';
import { ILotteryHeader } from './types';

const LotteryHeader: React.FC<ILotteryHeader> = ({ round, onExpire }) => {
  const { translate } = useLocalization();

  const [startAt, setStartAt] = useState('');
  const [endAt, setEndAt] = useState('');

  useEffect(() => {
    if (round) {
      const { startAt: newStartAt, endAt: newEndAt } = round;

      setStartAt(newStartAt);
      setEndAt(newEndAt);
      return;
    }

    setStartAt('');
    setEndAt('');
  }, [round]);

  return (
    <LotteryDesktopHeaderStyle>
      <div className="lot__header-title">
        <h3>{translate('popups.lottery.title')}</h3>
        <div className="lot__header-round">
          <img src={ticketIcon} alt="icon" />
          <p>
            {translate('popups.lottery.round')} {round?.id ? round.id : ''}
          </p>
        </div>
      </div>
      <div className="lot__header--content">
        <h3>{translate('popups.lottery.desc')}</h3>
        <div className="lot__header--content-wrap">
          <div className="lot__header--content-prize">
            {round?.displayName ? <img src={getImgSrc(round?.displayName)} alt="icon" /> : null}
            <p>{round?.prize ? amount(round?.prize) : ''}</p>
          </div>
          <LotteryTimer dateStart={startAt} dateEnd={endAt} size={TimerSize.DESKTOP} onExpire={onExpire} />
        </div>
      </div>
    </LotteryDesktopHeaderStyle>
  );
};

export default LotteryHeader;
