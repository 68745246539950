import React from 'react';

import cup from 'assets/img/Tournaments/empty-cup.webp';

import styles from './styles.module.scss';

const TournamentsEmptyPage: React.FC = () => {
  return (
    <div className={styles.tournamentsEmptyWrap}>
      <div className={styles.tournamentsEmptyContent}>
        <img src={cup} alt="cup" className={styles.tournamentsEmptyContentImg} />
        <div className={styles.tournamentsEmptyContentText}>
          <h4>NO ACTIVE TOURNAMENTS</h4>
          <h5>
            There are no active tournaments at this time currently. Please check back later or sign up for our
            newsletter to stay updated on upcoming events. Thank you for your understanding.
          </h5>
        </div>
      </div>
    </div>
  );
};

export default TournamentsEmptyPage;
