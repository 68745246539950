import { cardIcon } from 'func/cards';

import { BetDetailsCardImage } from './styles';
import { IBlackJackBetDetailsCard } from './types';

const BetDetailsCard: React.FC<IBlackJackBetDetailsCard> = ({ icon, top, left }) => {
  return <BetDetailsCardImage src={cardIcon(icon)} alt="card" top={top} left={left} />;
};

export default BetDetailsCard;
