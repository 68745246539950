import React from 'react';
import ReactTooltip from 'react-tooltip';
import clsx from 'clsx';

import { getButtonsBarImage } from 'func/commonGames';

import { IGameOptionsButtonsItemNew } from './types';
import styles from './styles.module.scss';

const GameOptionsButtonsItem: React.FC<IGameOptionsButtonsItemNew> = ({ toolTipText, activeImg, func, index, id }) => {
  const handleClick = () => {
    if (func) func();
  };

  return (
    <>
      <div
        className={clsx(styles.gamesOptionsBtnElem, activeImg && styles.gamesOptionsBtnElemActive)}
        onClick={handleClick}
        data-tip
        data-for={toolTipText}
        tabIndex={index}
      >
        <img alt="icon" src={getButtonsBarImage(activeImg, id)} className={styles.gamesOptionsBtnElemImg} />
      </div>
      <ReactTooltip id={toolTipText} data-event="hover" globalEventOff="click" place="bottom" effect="solid">
        <span>{toolTipText}</span>
      </ReactTooltip>
    </>
  );
};

export default GameOptionsButtonsItem;
