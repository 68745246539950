import clsx from 'clsx';
import Select, { PropsValue } from 'react-select';
import { useEffect, useState } from 'react';

import { LANGUAGE_OPTIONS } from 'components/constants/language';
import { Language } from 'components/Internationalization/types';
import { useLocalization } from 'components/Internationalization';

import SelectControl from './components/SelectControl';
import SelectOption from './components/SelectOption';

import styles from './styles.module.scss';
import { ILanguageOption } from './types';

const SelectLanguage = () => {
  const { language: currentLanguage, onChangeLanguage } = useLocalization();

  const [value, setValue] = useState<PropsValue<ILanguageOption>>();
  const [language, setLanguage] = useState<Language>(Language.english);

  useEffect(() => {
    if (language !== currentLanguage) {
      setLanguage(currentLanguage);
    }
  }, [currentLanguage]);

  useEffect(() => {
    const newValue = LANGUAGE_OPTIONS.find((o) => o.value === language);

    setValue(newValue);
  }, [language]);

  const handleChange = (v: ILanguageOption) => {
    setLanguage(v.value);
    onChangeLanguage(v.value);
  };

  return (
    <div className={styles.wrapper}>
      <Select
        className={styles.select}
        classNames={{
          control: ({ isFocused }) => clsx(styles.control, isFocused ? styles.active : ''),
          input: () => styles.value,
          singleValue: () => styles.value,
          placeholder: () => styles.placeholder,
          menu: () => styles.menu,
          option: ({ isSelected }) => clsx(styles.option, isSelected ? styles.selected : ''),
          indicatorSeparator: () => 'visually-hidden',
          dropdownIndicator: () => styles.indicatorsContainer,
        }}
        components={{
          Option: SelectOption as any,
          Control: SelectControl as any,
        }}
        isSearchable={false}
        menuShouldBlockScroll
        controlShouldRenderValue
        value={value}
        options={LANGUAGE_OPTIONS}
        onChange={handleChange}
        menuPlacement="top"
      />
    </div>
  );
};

export default SelectLanguage;
