import { useContext, useState, useEffect } from 'react';

import { DiceBetMode } from 'types';
import { ContextTokenCode } from 'context';
import DiceGameContext from 'context/contextDiceGame/context';
import { getDiceMultiplier, getDicePayoutOnWin } from 'func/games';
import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';

const DiceGameInfo: React.FC = () => {
  const { translate } = useLocalization();
  const { amount, mode, prediction } = useContext(DiceGameContext);
  const { tokenCode } = useContext(ContextTokenCode);

  const [chanceToWin, setChanceToWin] = useState(50);
  const [multiplier, setMultiplier] = useState('0.0000');
  const [payoutOnWin, setPayoutOnWin] = useState('0.00000000');

  useEffect(() => {
    const newChanseToWin = mode === DiceBetMode.under ? prediction : 99 - prediction;
    const newMultiplier = getDiceMultiplier(newChanseToWin);
    const formattedMultiplier = (newMultiplier.includes('.') ? `${newMultiplier}0000` : `${newMultiplier}.0000`).slice(
      0,
      6,
    );
    const newPayoutOnWin = getDicePayoutOnWin(amount || '0', newMultiplier);

    setChanceToWin(newChanseToWin);
    setMultiplier(formattedMultiplier);
    setPayoutOnWin(newPayoutOnWin);
  }, [mode, prediction, amount]);

  return (
    <article className={styles.wrapper}>
      <div>
        <span>{translate('dice.chance.text')}:</span>
        <p>{chanceToWin}%</p>
      </div>
      <i className={styles.div} />
      <div>
        <span>{translate('dice.multiplier.text')}:</span>
        <p>x{multiplier}</p>
      </div>
      <i className={styles.div} />
      <div className={styles.payout}>
        <span>{translate('dice.payout.text')}:</span>
        <p>
          {payoutOnWin} {tokenCode.name}
        </p>
      </div>
    </article>
  );
};

export default DiceGameInfo;
