import { useState, useEffect } from 'react';
import { components } from 'react-select';

import { TSelectControl } from './types';
import styles from '../../styles.module.scss';

const { Control } = components;

const SelectControl: React.FC<TSelectControl> = ({ children, ...props }) => {
  const [icon, setIcon] = useState('');
  const [label, setLabel] = useState('');

  useEffect(() => {
    const options = props.getValue();

    if (options.length) {
      const option = options[0];

      setIcon(option?.icon || '');
      setLabel(option?.label || '');
    } else {
      setIcon('');
      setLabel('');
    }
  }, [props]);

  return (
    <Control {...props}>
      {icon ? (
        <div className={styles.iconWrapper}>
          <img className={styles.icon} src={icon} alt={label} />
        </div>
      ) : null}
      {children}
    </Control>
  );
};

export default SelectControl;
