import React from 'react';

import { useLimbo } from 'context/contextLimbo/contextLimbo';
import { Types } from 'context/contextLimbo/limboReduser';
import { isDisabledButtonLimbo } from 'func/prepareDataLimbo';

import AutoSwitch from 'components/Games/base/AutoSwitch';

import styles from './styles.module.scss';

const LimboSwitchAuto: React.FC = () => {
  const [state, dispatch] = useLimbo();

  const { isAuto, gameCondition } = state;

  const handleCheckbox = () => {
    dispatch({ type: Types.IS_AUTO });
  };

  return (
    <div className={styles.limboSwitchAuto}>
      <AutoSwitch checked={isAuto} onCheck={handleCheckbox} disabled={isDisabledButtonLimbo(gameCondition)} />
    </div>
  );
};

export default LimboSwitchAuto;
