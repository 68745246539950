import { useLocalization } from 'components/Internationalization';

import AutoCheckboxStyle from 'components/Games/base/AutoCheckbox/styles';

interface Props {
  value: boolean;
  setValue: (el: boolean) => void;
  disable: boolean;
  config?: string;
}

const AutoCheckbox: React.FC<Props> = ({ value, setValue, disable, config }) => {
  const { translate } = useLocalization();

  const handleClick = () => {
    setValue(!value);
  };

  return (
    <AutoCheckboxStyle checkboxValue={value} config={config}>
      <p>{translate('popups.bets.dice.auto')}</p>
      <div className="auto__checkbox-wrap">
        <input id="checkbox" type="checkbox" checked={value} onChange={handleClick} disabled={disable} />
        <label htmlFor="checkbox" className="auto__checkbox-label" />
      </div>
    </AutoCheckboxStyle>
  );
};

export default AutoCheckbox;
