import { useQuery } from '@apollo/client';

import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile, userToken } from 'store/user/user.selectors';
import { REFERRAL_QUERIES_AUTH } from 'graphQl/query/referral/referralQueriesAuth';
import { DOCUMENT_TITLE_SETTINGS, UserType } from 'components/constants/constants';
import { PageShadow } from 'components/constants/pages';
import { Links } from 'components/constants';
import { useNavigate } from 'hooks/useNavigate';

import DocumentTitle from 'components/Base/DocumentTitle';
import PageTitleShadow from 'components/Base/PageTitleShadow';
import ReferralTable from './components/ReferralTable';
import Title from './components/Title';
import LinkCardReferral from './components/LinkCardReferral';
import ReferralBalance from './components/ReferralBalance';
import ReferralButtonLink from './components/ReferralButtonLink';

import styles from './styles.module.scss';

const ReferralPage: React.FC = () => {
  const navigate = useNavigate();

  const token = useAppSelector(userToken);
  const profile = useAppSelector(userProfile);

  useQuery(REFERRAL_QUERIES_AUTH, {
    fetchPolicy: 'cache-and-network',
    skip: !token,
  });

  if (profile?.type === UserType.affiliate) {
    navigate(Links.notFound);
    return;
  }

  return (
    <div className={styles.wrapper}>
      <DocumentTitle data={DOCUMENT_TITLE_SETTINGS.referral} />
      <Title />
      <PageTitleShadow background={PageShadow.referral} />
      <div className={styles.pageWrapper}>
        <ReferralButtonLink />
        <div className={styles.cardWrapper}>
          <LinkCardReferral />
          <ReferralBalance />
        </div>
        <ReferralTable />
      </div>
    </div>
  );
};

export default ReferralPage;
