import bonusCategoryIcon from 'assets/img/Vip/bonus-category.webp';
import supportCategoryIcon from 'assets/img/Vip/support-category.webp';
import extraCategoryIcon from 'assets/img/Vip/extra-category.webp';

import bonusesBonusIcon from 'assets/img/Vip/bonuses-bonus.webp';
import bonusesPrizeIcon from 'assets/img/Vip/bonuses-prize.webp';
import bonusesRewardsIcon from 'assets/img/Vip/bonuses-rewards.webp';
import bonusesWheelIcon from 'assets/img/Vip/bonuses-wheel.webp';

import supportManagerIcon from 'assets/img/Vip/support-manager.webp';
import supportTelegramIcon from 'assets/img/Vip/support-telegram.webp';

import extraSuggestionIcon from 'assets/img/Vip/extra-suggestion.webp';
import extraEmailIcon from 'assets/img/Vip/extra-email.webp';
import extraAccessIcon from 'assets/img/Vip/extra-access.webp';
import extraStakesIcon from 'assets/img/Vip/extra-stakes.webp';

import config from 'config';

import { IVipBonusList, VipBenefitsCategory } from './types';

const { vipTelegramGroup, vipTelegramManager } = config;

export const VIP_BENEFITS_CATEGORIES = [
  { icon: bonusCategoryIcon, title: 'vip.benefits.categories.bonuses', value: VipBenefitsCategory.bonus },
  { icon: supportCategoryIcon, title: 'vip.benefits.categories.support', value: VipBenefitsCategory.support },
  { icon: extraCategoryIcon, title: 'vip.benefits.categories.extra', value: VipBenefitsCategory.extra },
];

const VIP_BONUS_BENEFITS: IVipBonusList[] = [
  {
    icon: bonusesBonusIcon,
    title: 'vip.benefits.bonuses.loyalty.title',
    description: 'vip.benefits.bonuses.loyalty.desc',
  },
  {
    icon: bonusesPrizeIcon,
    title: 'vip.benefits.bonuses.special.title',
    description: 'vip.benefits.bonuses.special.desc',
  },
  {
    icon: bonusesRewardsIcon,
    title: 'vip.benefits.bonuses.higher-rewards.title',
    description: 'vip.benefits.bonuses.higher-rewards.desc',
  },
  {
    icon: bonusesWheelIcon,
    title: 'vip.benefits.bonuses.higher-prizes.title',
    description: 'vip.benefits.bonuses.higher-prizes.desc',
  },
];

const VIP_SUPPORT_BENEFITS: IVipBonusList[] = [
  {
    icon: supportManagerIcon,
    title: 'vip.benefits.support.managers.title',
    description: 'vip.benefits.support.managers.desc',
    action: { link: vipTelegramManager, title: 'vip.benefits.support.managers.action.title' },
  },
  {
    icon: supportTelegramIcon,
    title: 'vip.benefits.support.telegram.title',
    description: 'vip.benefits.support.telegram.desc',
    action: { link: vipTelegramGroup, title: 'vip.benefits.support.telegram.action.title' },
  },
];

const VIP_EXTRA_BENEFITS: IVipBonusList[] = [
  { icon: extraStakesIcon, title: 'vip.benefits.extra.stakes.title', description: 'vip.benefits.extra.stakes.desc' },
  { icon: extraAccessIcon, title: 'vip.benefits.extra.access.title', description: 'vip.benefits.extra.access.desc' },
  {
    icon: extraEmailIcon,
    title: 'vip.benefits.extra.announcements.title',
    description: 'vip.benefits.extra.announcements.desc',
  },
  {
    icon: extraSuggestionIcon,
    title: 'vip.benefits.extra.suggestions.title',
    description: 'vip.benefits.extra.suggestions.desc',
  },
];

export const VIP_BENEFITS: Record<VipBenefitsCategory, IVipBonusList[]> = {
  bonus: VIP_BONUS_BENEFITS,
  support: VIP_SUPPORT_BENEFITS,
  extra: VIP_EXTRA_BENEFITS,
};
