import dayjs from 'dayjs';
import { Cookies } from 'react-cookie';
import { useContext } from 'react';
import sanitize from 'sanitize-html';

import warning from 'assets/img/Deposit/warning.webp';

import { useLocalization } from 'components/Internationalization';
import { ButtonType } from 'components/Base/Button/types';
import { ContextPopUps } from 'context';
import { FIREBLOCKS_NOTIFICATION_COOKIE, popUps } from 'components/constants/constants';

import Button from 'components/Base/Button';

import styles from './styles.module.scss';

const DepositChanged: React.FC = () => {
  const { translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  function handleDeposit() {
    const cookies = new Cookies();
    const expires = dayjs().add(1, 'year').toDate();

    cookies.set(FIREBLOCKS_NOTIFICATION_COOKIE, true, { expires });

    setPopUpsOpen({
      modalOpen: popUps.walletNavigation,
      data: {
        config: popUps.deposit,
        active: popUps.deposit,
      },
    });
  }

  return (
    <div className={styles.wrapper}>
      <img className={styles.image} src={warning} alt="Warning Image" />
      <h5 className={styles.title}>{translate('popups.deposit-chaned.title')}</h5>
      <p
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: sanitize(translate('popups.deposit-chaned.description')),
        }}
      />
      <Button
        className={styles.action}
        variant={ButtonType.secondaryBold}
        title={translate('popups.deposit-chaned.action')}
        onClick={handleDeposit}
      />
    </div>
  );
};

export default DepositChanged;
