import React, { useEffect } from 'react';
import clsx from 'clsx';

import { useKeno } from 'context/contextKeno/contextKeno';
import { KenoCondition } from 'components/constants/games';
import { Types } from 'context/contextKeno/kenoReduser';
import { bigThen } from 'func/prepareDataDice';
import { amount, getImgSrc } from 'func/common';
import { checkMaxPayKeno } from 'func/prepareDataKeno';
import winImage from 'assets/img/Keno/keno-win-image.webp';

import styles from './styles.module.scss';

const KenoWinAnimation: React.FC = () => {
  const [state, dispatch] = useKeno();
  const { winInfo, gameCondition, fastMode, gameSettings } = state;

  useEffect(() => {
    if (winInfo.id) {
      if (gameCondition === KenoCondition.autoStarted) {
        setTimeout(() => dispatch({ type: Types.SET_REQUEST_ID }), document.hidden ? 0 : fastMode ? 400 : 1000);
      }
      if (gameCondition === KenoCondition.notStarted) {
        dispatch({ type: Types.DISABLE });
      }
    }
  }, [winInfo]);

  return (
    <div
      className={clsx(
        styles.kenoWinAnimation,
        bigThen(winInfo.payout, '0') &&
          !document.hidden &&
          (fastMode ? styles.kenoWinAnimationActiveFast : styles.kenoWinAnimationActive),
      )}
    >
      <div className={styles.kenoWinAnimationContentWrap}>
        <img src={winImage} alt="img" />
        <h3>{winInfo?.multiplier || 1.1}</h3>
        <div className={styles.kenoWinAnimationContentPayout}>
          <img src={getImgSrc(winInfo?.displayName)} alt="icon" />
          <h4>{amount(checkMaxPayKeno(winInfo?.payout, gameSettings.maxPay))}</h4>
        </div>
      </div>
    </div>
  );
};

export default KenoWinAnimation;
