import React, { useState } from 'react';
import { generate as id } from 'shortid';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import copyIcon from 'assets/img/common/copyIcon.svg';

import { useLocalization } from 'components/Internationalization';

import StyledTable, { StyledTableWrap } from './styles';
import { DataElem, ITable } from './types';

interface CopyProps {
  link: string;
}

const TableTooltipCopy: React.FC<CopyProps> = ({ link }) => {
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltip = () => {
    setOpenTooltip(true);
    setTimeout(() => {
      setOpenTooltip(false);
    }, 1000);
  };

  return (
    <CopyToClipboard text={link}>
      <div className="table__copy">
        <div className={openTooltip ? 'table__form-copy-tooltip open' : 'table__form-copy-tooltip'}>copied</div>
        <img className="table__copy-icon" src={copyIcon} alt="icon" onClick={handleTooltip} />
      </div>
    </CopyToClipboard>
  );
};

const Table: React.FC<ITable> = ({ data, titles, thWidth, config, thWidthMob, cutCurrency }) => {
  const { translate } = useLocalization();

  return (
    <StyledTableWrap>
      <StyledTable
        thwidth={thWidth}
        config={config}
        thwidthmob={thWidthMob}
        cutcurrency={cutCurrency ? 'true' : ''}
        tablepopup={''}
      >
        <thead>
          <tr>
            {titles.map((elem, i) => (
              <th key={id()} className={elem.columnClass}>
                <b>
                  {translate(elem.title)}
                  {elem.additional ? ` ${elem.additional}` : ''}
                </b>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={id()}>
              {item.map((elem: DataElem) => {
                if (typeof elem !== 'string' && elem?.link) {
                  const copiedElem = elem.linkTitle.match('Waiting');
                  return (
                    <td key={id()} className={elem.columnClass}>
                      {!copiedElem ? (
                        <>
                          <a target="_blanc" rel="noopener noreferrer" href={elem.link} className="table__link">
                            {elem.linkTitle}
                          </a>
                          <TableTooltipCopy link={elem.link} />
                        </>
                      ) : (
                        <small className={elem.textClass}>{elem.linkTitle}</small>
                      )}
                    </td>
                  );
                }
                if (React.isValidElement(elem)) {
                  return <td key={id()}>{elem}</td>;
                }
                return (
                  <td key={id()} className={elem.columnClass}>
                    {elem.src && <img className={elem.imageClass} src={elem.src} alt="icon" />}
                    <small className={elem.textClass}>{elem.title}</small>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </StyledTableWrap>
  );
};

export default Table;
