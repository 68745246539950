import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import HighCardTitle from 'components/Base/Cards/HighCardTitle';
import { GET_STAR_TOKENS } from 'graphQl/query/dividends/starTokens';
import { useLocalization } from 'components/Internationalization';

import { ITokenDetails } from './types';
import styles from './styles.module.scss';

const TokenDetails: React.FC<ITokenDetails> = ({ isFlipped, setIsFlipped }) => {
  const { translate } = useLocalization();
  const [starToken, setStarToken] = useState(null);
  const { data: dataStarTokens } = useQuery(GET_STAR_TOKENS, {
    fetchPolicy: 'cache-only',
  });

  const handleFlip = () => setIsFlipped(!isFlipped);

  useEffect(() => {
    if (dataStarTokens) {
      setStarToken(dataStarTokens.starToken);
    }
  }, [dataStarTokens]);

  return (
    <HighCardTitle
      title={translate('dividends.details.title')}
      subtitle={translate('dividends.details.description')}
      overflow="hidden"
      offBlur
    >
      <button className={styles.miningButton} type="button" onClick={handleFlip}>
        {translate('dividends.mining.flip.button.back')}
      </button>
      <div className={styles.miningDetails}>
        <div className={styles.miningBodyElem}>
          <p>{translate('dividends.details.supply')}</p>
          <div />
          <code>{starToken ? Number(starToken.maxSupply).toLocaleString() : '0'}</code>
        </div>
        <div className={styles.miningBodyElem}>
          <p>{translate('dividends.details.mined')}</p>
          <div />
          <code>{starToken ? Math.trunc(Number(starToken.totalMined)).toLocaleString() : '0'}</code>
        </div>
        <div className={styles.miningBodyElem}>
          <p>{translate('dividends.details.staked')}</p>
          <div />
          <code>{starToken ? Math.trunc(Number(starToken.totalStaked)).toLocaleString() : '0'}</code>
        </div>
        <div className={styles.miningBodyElem}>
          <p>{translate('dividends.details.burned')}</p>
          <div />
          <code>{starToken ? Number(starToken.totalBurned).toLocaleString() : '0'}</code>
        </div>
      </div>
    </HighCardTitle>
  );
};

export default TokenDetails;
