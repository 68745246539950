import { GAMES_TABS_NAMES } from 'components/constants/constants';
import { useLocalization } from 'components/Internationalization';
import { isDisplayGamesTab } from 'func/prepareDataGamesPage';

import GamesTabBonus from '../GamesTabBonus';

import styles from './styles.module.scss';
import { IGamesTabs } from './types';
import { GamesTabsWrap } from './styles';

const GamesTabs: React.FC<IGamesTabs> = ({ currentTitle, titlesList, onTitle, vipStatus, subCategoriesList }) => {
  const { translate } = useLocalization();

  return (
    <div className={styles.gameTitlesWrap}>
      {titlesList.map((elem) => {
        if (elem.idTitle === GAMES_TABS_NAMES.bonus.urlTitle) {
          return (
            <GamesTabBonus
              key={elem.title}
              isActive={currentTitle === elem.idTitle}
              onClick={onTitle}
              idTitle={elem.idTitle}
              isDisplay={isDisplayGamesTab(elem.id, subCategoriesList)}
            />
          );
        }
        if (elem.idTitle === GAMES_TABS_NAMES.vip.urlTitle && !vipStatus) {
          return;
        }
        return (
          <GamesTabsWrap
            display={isDisplayGamesTab(elem.id, subCategoriesList)}
            key={elem.title}
            className={currentTitle === elem.idTitle ? styles.gamesTitlesCurrent : styles.gamesTitles}
          >
            <p onClick={() => onTitle(elem.idTitle)}>{translate(elem.title)}</p>
          </GamesTabsWrap>
        );
      })}
    </div>
  );
};

export default GamesTabs;
