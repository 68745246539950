import React from 'react';
import { ILiveStatsContext } from './types';

const LiveStatsContext = React.createContext<ILiveStatsContext>({
  enabled: false,
  visible: false,
  profit: '0',
  wagered: '0',
  wins: 0,
  losses: 0,
  bets: [],
  onBet: () => null,
  onToggleStatistics: () => null,
  onReset: () => null,
});

export default LiveStatsContext;
