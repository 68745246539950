import React from 'react';
import clsx from 'clsx';

import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';
import { IAutoSwitch } from './types';

const AutoSwitch: React.FC<IAutoSwitch> = ({ checked, onCheck, disabled }) => {
  const { translate } = useLocalization();
  return (
    <button
      type="button"
      onClick={onCheck}
      disabled={disabled}
      className={clsx(styles.autoSwitchWrap, checked && styles.autoSwitchWrapActive)}
    >
      <div className={styles.autoSwitchCircle} />
      <h5>{translate('inHouse.auto.switch.checkbox.text')}</h5>
    </button>
  );
};

export default AutoSwitch;
