import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import plinkoBg from 'assets/img/Plinko/plinko_bet details_bg.webp';

import config from 'config';
import { GET_BET } from 'graphQl/query/bet/bet';
import { amount, getImgSrc } from 'func/common';
// import { setSquareListForBetDetails } from 'func/prepareDataKeno';
// import { EKenoSquareClass } from 'components/constants/games';

import Loader from 'components/Base/Loader/Loader';
import BetDetailsWrap from '../base/BetDetailsWrap';

import { IBet } from '../base/BetDetailsWrap/types';
import styles from './styles.module.scss';
import { IPlinkoBetDetails } from './types';

const { sha256ValidatorUrl } = config;

const PlinkoBetDetails: React.FC<IPlinkoBetDetails> = ({ data }) => {
  const [bet, setBet] = useState<IBet>();

  const { data: betData, loading } = useQuery(GET_BET, {
    variables: { betId: data.id },
  });

  useEffect(() => {
    const newBet = pathOr(undefined, ['bet'], betData);

    if (newBet) {
      setBet(newBet);
    }
  }, [betData]);

  if (loading || !bet) return <Loader pages />;

  const handleVerify = () => {
    window.open(sha256ValidatorUrl, '_blank');
  };

  return (
    <BetDetailsWrap bet={bet} withVerify onVerify={handleVerify}>
      <div className={styles.wrapper}>
        <img src={plinkoBg} alt="plinko" className={styles.bg} />
        <div className={styles.winWrap}>
          <h3>{bet?.multiplier}x</h3>
          <div className={styles.amountWrap}>
            <img src={getImgSrc(bet?.token?.tokenCode)} alt={bet?.token?.tokenCode} />
            <p>{amount(bet?.payout)}</p>
          </div>
        </div>
        <p className={styles.para}>
          Risk: <span>{bet?.betOn?.risk}</span>
        </p>
      </div>
    </BetDetailsWrap>
  );
};

export default PlinkoBetDetails;
