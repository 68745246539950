import { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { pathOr } from 'ramda';
import clsx from 'clsx';
import decode from 'jwt-decode';

import { ContextPopUps } from 'context';
import { ITokenPayload } from 'types/requestTypes';
import { SEND_EMAIL_VERIFICATION_OTP, VERIFY_EMAIL } from 'graphQl/query/auth/auth';
import { GET_MY_BONUSES } from 'graphQl/query/bonus/indes';
import { getAvailableActiveBonuses } from 'func/prepareDataBonus';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setToken } from 'store/user';
import { popUps } from 'components/constants/constants';
import { useAppSelector } from 'hooks/useAppSelector';
import { Links } from 'components/constants';
import { setAuthToken, setRefreshToken } from 'func/auth';
import { ButtonType } from 'components/Base/Button/types';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';
import { useNavigate } from 'hooks/useNavigate';
import { DataLayerEvent } from 'types/dataLayerTypes';
import { useAnalyticsDispatch } from 'hooks/useAnalyticsDispatch';

import Input2FA from 'components/Base/Input/Input2FA';
import { IDecodedToken } from 'components/LoginLayer/types';
import ButtonSubmit from '../ButtonSubmit';

import { ISignUpConfirm } from './types';

import styles from './styles.module.scss';

const SignUpConfirm: React.FC<ISignUpConfirm> = ({ data }) => {
  const { translate, language } = useLocalization();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const dataLayerDispatch = useAnalyticsDispatch();

  const [tooltip, setTooltip] = useState('');
  const [code, setCode] = useState('');
  const [description, setDescription] = useState('');

  const token = useAppSelector(userToken);

  const [sendEmailVerificationOtp] = useLazyQuery(SEND_EMAIL_VERIFICATION_OTP, { fetchPolicy: 'no-cache' });
  const [myBonuses] = useLazyQuery(GET_MY_BONUSES, { fetchPolicy: 'network-only' });
  const [verifyEmail] = useMutation(VERIFY_EMAIL);

  const handleEmail = async () => {
    const email = data?.email;

    if (email) {
      await sendEmailVerificationOtp({ variables: { email } })
        .then((res) => {
          const error = res.error;

          if (error) {
            setTooltip(error.message);
            setTimeout(() => setTooltip(''), 1000);
            return;
          }

          setTooltip(translate('popups.signup.confirm.tooltip.success'));
          setTimeout(() => setTooltip(''), 1000);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log('[SEND_OTP_ERROR]: ', e);
        });
    }
  };

  const handleChange = (value: string) => {
    setCode(value);
  };

  const handleClose = () => {
    navigate(data?.isLandingBonus ? Links.bonus : Links.security);
    setPopUpsOpen(null);
  };

  const handleCheckBonus = async () => {
    const response = await myBonuses();
    if (response?.data) {
      const bn = getAvailableActiveBonuses(response.data?.myBonuses);
      if (bn) {
        setPopUpsOpen({
          modalOpen: popUps.bonusLogin,
          data: {
            data: bn,
            config: popUps.bonusLogin,
          },
        });
      } else {
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  const handleSubmit = async () => {
    const variables = { email: data?.email, password: data?.password, code };

    await verifyEmail({ variables })
      .then((res) => {
        const tokens = pathOr<ITokenPayload | undefined>(undefined, ['data', 'verifyEmail'], res);

        if (tokens) {
          const { authToken, refreshToken } = tokens;
          const { id } = decode<IDecodedToken>(authToken);
          dataLayerDispatch({ event: DataLayerEvent.emailVerified, user_id: id });

          setAuthToken(authToken);
          setRefreshToken(refreshToken);
          dispatch(setToken(authToken));

          handleClose();
        } else {
          setTooltip(translate('popups.signup.confirm.tooltip'));
        }
      })
      .catch((err) => {
        const message = err.message;

        setTooltip(message || translate('popups.signup.confirm.tooltip'));

        // eslint-disable-next-line no-console
        console.log('[VERIFY_EMAIL_ERROR]: ', err);

        setTimeout(() => {
          setTooltip('');
        }, 1000);
      });
    if (!token) {
      await handleCheckBonus();
    }
  };

  useEffect(() => {
    const text = translate('popups.signup.confirm.description');
    const newText = text.replace(':email', data?.email);

    setDescription(newText);
  }, [data, language]);

  return (
    <div className={styles.signConfirmWrap}>
      <div className={styles.signConfirmTitle}>{translate('popups.signup.confirm.title')}</div>
      <div className={styles.signConfirmFormWrap}>
        <div className={styles.signConfirmForm}>
          <div className={styles.signConfirmFormText}>{description}</div>
          <div className={styles.signConfirmFormInputWrap}>
            <Input2FA
              allowedCharacters="numeric"
              onChange={handleChange}
              inputClassName={styles.signConfirmFormInput}
            />
          </div>
        </div>
        <ButtonSubmit
          handleButton={handleSubmit}
          variant={ButtonType.secondary}
          width="288px"
          title={translate('general.buttons.continue')}
          color="#242c45"
        />
        <div className={styles.signConfirmFooter}>
          <div className={clsx(styles.signConfirmFooterTooltip, tooltip ? styles.signConfirmFooterTooltipOpen : '')}>
            {tooltip}
          </div>
          <div className={styles.signConfirmBtnWrap}>
            {translate('popups.signup.confirm.button.title')}
            <button type="submit" className={styles.signConfirmBtn1} onClick={handleEmail}>
              {translate('popups.signup.confirm.button.text')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpConfirm;
