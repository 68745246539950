import React, { useContext, useReducer } from 'react';

import { initialState } from './initialState';
import { minesReducer } from './minesReduser';
import { IAction } from './types';

const ContextMines = React.createContext(null);

export const useMines = (): [state: typeof initialState, dispatch: (action: IAction) => void] =>
  useContext(ContextMines);

export const MinesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const value = useReducer(minesReducer, initialState);

  return <ContextMines.Provider value={value}>{children}</ContextMines.Provider>;
};
