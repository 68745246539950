import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { matchRoutes, useLocation } from 'react-router-dom';

import { ALL_ROUTES_WITH_LANGUAGE } from 'components/Router/constants';
import { LANGUAGE_PATH } from 'components/Router/utils';

import { IDocumentTitle } from './types';

const DocumentTitle: React.FC<IDocumentTitle> = ({ data }) => {
  const location = useLocation();
  const match = matchRoutes(ALL_ROUTES_WITH_LANGUAGE, location);

  const [canonical, setCanonical] = useState('');

  useEffect(() => {
    if (match) {
      const [{ route }] = match;

      const newPath = route.path.replace(LANGUAGE_PATH, '');
      const newCanonical = window.location.origin + newPath;

      setCanonical(newCanonical);
    }
  }, [match]);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{data.title}</title>
      <meta data-default-desk name="description" content={data.description} />
      <meta property="og:title" content={data.title} />
      <meta property="og:url" content={`${window.location.href}`} />
      {canonical ? <link rel="canonical" href={canonical} /> : null}
    </Helmet>
  );
};

export default DocumentTitle;
