import { Cookies } from 'react-cookie';
import ReCAPTCHA from 'react-google-recaptcha';
import { useContext, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';

import emailIcon from 'assets/img/common/emailIcon.webp';
import lockIcon from 'assets/img/common/lockIcon.webp';
import closePassword from 'assets/img/common/closePassword.webp';
import openPassword from 'assets/img/common/openPassword.webp';

import { ButtonType } from 'components/Base/Button/types';
import { ContextPopUps } from 'context';
import { useLocalization } from 'components/Internationalization';
import { AuthErrors, getSignupValidationSchema, initialSignUpState } from 'components/constants/auth';
import { CREATE_ACCOUNT } from 'graphQl/mutations/registration/registration';
import { popUps, ReferralsType } from 'components/constants/constants';
import { parseErrorMessage } from 'func/common';
import config from 'config';

import InputCustom from 'components/Base/Input';
import Checkbox from 'components/Base/CheckBox';
import ButtonSubmit from 'components/Base/PopUps/components/ButtonSubmit';
import { useAnalyticsDispatch } from 'hooks/useAnalyticsDispatch';
import { DataLayerEvent, DataLayerMethod } from 'types/dataLayerTypes';

import { IRegistrationResponse, ISignUpValues } from '../../types';
import { IRegistrationForm } from './types';
import styles from './styles.module.scss';

const { recaptchaKey } = config;

const RegistrationForm: React.FC<IRegistrationForm> = ({
  setCurrentEmail,
  buttonType,
  buttonText,
  isLandingBonus,
  isLandingSignupBonus,
  onBeforeSubmit,
}) => {
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { translate } = useLocalization();
  const dataLayerDispatch = useAnalyticsDispatch();

  const recaptchaRef = useRef<ReCAPTCHA>();

  const formik = useFormik<ISignUpValues>({
    initialValues: initialSignUpState,
    validationSchema: getSignupValidationSchema(translate),
    onSubmit: handleLogin,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [register, { loading }] = useMutation<IRegistrationResponse>(CREATE_ACCOUNT, { fetchPolicy: 'no-cache' });

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleValuesChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = ev.target;

    formik.handleChange(ev);
    formik.setTouched({ ...formik.touched, [name]: true });

    if (formik.errors) {
      setErrorMessage('');
      formik.setErrors({ ...formik.errors, [name]: '' });
    }
  };

  const getValidationErrors = async () => {
    let withError = false;

    await formik.validateForm(formik.values).then((errors) => {
      const error = Object.values(errors)?.[0];

      if (error) {
        withError = true;
      }

      setErrorMessage(error);
    });

    return withError;
  };

  const handleShowLoginModal = () => setPopUpsOpen({ modalOpen: popUps.login, data: { isLandingPage: true } });

  async function handleLogin(values: ISignUpValues) {
    setCurrentEmail(values.email);

    const cookies = new Cookies();

    const ref = cookies.get(ReferralsType.referral);
    const aff = cookies.get(ReferralsType.affiliate);

    const tokenCaptcha = await recaptchaRef?.current?.executeAsync();
    const registrationData = {
      ...values,
      email: values.email.toLowerCase(),
      captcha: tokenCaptcha,
      referrerCode: aff || ref,
      referrerType: aff ? 'Affiliate' : 'User',
    };

    await register({ variables: { data: registrationData } })
      .then((response) => {
        dataLayerDispatch({
          event: DataLayerEvent.signUp,
          user_id: response.data.createAccount,
          method: DataLayerMethod.email,
        });
        setPopUpsOpen({
          modalOpen: popUps.signUpConfirm,
          data: { email: values.email.toLowerCase(), password: values.password, isLandingBonus },
        });
      })
      .catch((err) => {
        const errorBody = parseErrorMessage(err.message);
        const requestErrorMessage = errorBody?.description || err.message;

        if (requestErrorMessage === AuthErrors.unavailable) {
          setPopUpsOpen(popUps.blockedIp);
        } else {
          setErrorMessage(requestErrorMessage);
        }

        recaptchaRef?.current?.reset();
      });
  }

  const handleSubmit = async () => {
    const error = await getValidationErrors();

    if (error) return;
    if (onBeforeSubmit) onBeforeSubmit();

    formik.handleSubmit();
  };

  return (
    <div className={styles.signupContent}>
      <h3>{translate('popups.signup.title')}</h3>
      <form className={styles.form}>
        <label htmlFor="user-email">{translate('popups.signup.email')}:</label>
        <InputCustom
          id="user-email"
          name="email"
          placeholder={translate('popups.signup.email')}
          type="text"
          icon={emailIcon}
          iconClassName={styles.signEmailIcon}
          onChange={handleValuesChange}
          value={formik.values.email}
          touched={formik.touched.email}
          errors={formik.errors.email}
        />
        <label htmlFor="user-password">{translate('popups.signup.password')}:</label>
        <InputCustom
          id="user-password"
          name="password"
          placeholder={translate('popups.signup.password')}
          type={!showPassword ? 'password' : 'text'}
          icon={lockIcon}
          iconClassName={styles.signLockIcon}
          additionalIconClassName={styles.signAdditionalIcon}
          additionalIcon={!showPassword ? closePassword : openPassword}
          onAdditionalIconClick={handleShowPassword}
          onChange={handleValuesChange}
          value={formik.values.password}
          touched={formik.touched.password}
          errors={formik.errors.password}
        />
        <label htmlFor="user-confirm-password">{translate('popups.signup.password-confirm')}:</label>
        <InputCustom
          id="user-confirm-password"
          name="confirmPassword"
          placeholder={translate('popups.signup.password-confirm')}
          type={!showPassword ? 'password' : 'text'}
          icon={lockIcon}
          iconClassName={styles.signLockIcon}
          additionalIconClassName={styles.signAdditionalIcon}
          additionalIcon={!showPassword ? closePassword : openPassword}
          onAdditionalIconClick={handleShowPassword}
          onChange={handleValuesChange}
          value={formik.values.confirmPassword}
          touched={formik.touched.confirmPassword}
          errors={formik.errors.confirmPassword}
        />
        <label htmlFor="user-promo-code">{translate('popups.signup.promocode')}:</label>
        <InputCustom
          id="user-promo-code"
          name="promoCode"
          placeholder={translate(isLandingSignupBonus ? 'SIGNUP1000' : 'popups.signup.placeholders.promocode')}
          type="text"
          onChange={handleValuesChange}
          value={formik.values.promoCode}
          touched={formik.touched.promoCode}
          errors={formik.errors.promoCode}
        />
        <div className={styles.checkboxes}>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              id="user-isAdult"
              name="isAdult"
              checked={formik.values.isAdult}
              onChange={handleValuesChange}
              border
            />
            <div className={styles.checkboxWrapperLabel}>
              <label htmlFor="user-isAdult">
                {translate('popups.signup.adult')}{' '}
                <a target="_blank" rel="noopener noreferrer" href={process.env.PUBLIC_URL + '/TermsOfService.pdf'}>
                  {translate('popups.signup.tos')}
                </a>
              </label>
            </div>
          </div>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              id="user-marketing"
              name="marketing"
              checked={formik.values.marketing}
              onChange={handleValuesChange}
              border
            />
            <div className={styles.checkboxWrapperLabel}>
              <label htmlFor="user-marketing">{translate('popups.signup.marketing')}</label>
            </div>
          </div>
        </div>
        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={recaptchaKey} />
        <ButtonSubmit
          className={styles.submitButton}
          handleButton={handleSubmit}
          variant={buttonType || ButtonType.secondary}
          width="288px"
          title={buttonText || translate('general.buttons.continue')}
          type="button"
          disabled={loading}
          color="#242c45"
        />
      </form>
      <div className={styles.errorContainer}>
        <p>{errorMessage}</p>
      </div>
      <div className={styles.loginButton}>
        <p>{translate('popups.signup.login')}</p>
        <button type="button" onClick={handleShowLoginModal}>
          {translate('popups.signup.buttons.login')}
        </button>
      </div>
    </div>
  );
};

export default RegistrationForm;
