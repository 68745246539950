import gamesIcon from 'assets/img/PageTitles/Games_Icon.svg';
import dividendsIcon from 'assets/img/PageTitles/Dividends_Icon.svg';
import cashbackIcon from 'assets/img/PageTitles/Cashback_Icon.svg';
import referralIcon from 'assets/img/PageTitles/Referral_Icon.svg';
import eventsIcon from 'assets/img/PageTitles/Events_Icon.svg';
import dashboardIcon from 'assets/img/PageTitles/Dashboard_Icon.svg';
import accountIcon from 'assets/img/PageTitles/Account_Icon.svg';
import betsIcon from 'assets/img/PageTitles/Bets_Icon.svg';
import securityIcon from 'assets/img/PageTitles/Security_Icon.svg';
import missionsIcon from 'assets/img/PageTitles/Missions_Icon.svg';

import { TranslationType } from 'types';
import { Links } from '.';

export interface INavLink {
  title: string;
  src: string;
  link: string;
  withCondition?: boolean;
  additionalRequiresAuth?: boolean;
}

export const getBurgerMenuData = (t: TranslationType): INavLink[] => [
  { title: t('nav.games'), src: gamesIcon, link: Links.games },
  { title: t('nav.dividends'), src: dividendsIcon, link: Links.dividends },
  { title: t('nav.cashback'), src: cashbackIcon, link: Links.cashback },
  { title: t('nav.referral'), src: referralIcon, link: Links.referral },
  { title: t('nav.events'), src: eventsIcon, link: `${Links.events}${Links.starwars}` },
  {
    title: t('nav.missions'),
    src: missionsIcon,
    link: Links.missions,
    withCondition: true,
  },
];

export const getBurgerMenuDataAuth = (t: TranslationType): INavLink[] => [
  { title: t('nav.dashboard'), src: dashboardIcon, link: Links.dashboard },
  { title: t('nav.account'), src: accountIcon, link: Links.account },
  { title: t('nav.transactions'), src: betsIcon, link: Links.transactions },
  { title: t('nav.security'), src: securityIcon, link: Links.security },
  { title: t('nav.bets'), src: betsIcon, link: Links.bets },
];

export const getSidebarNav = (t: TranslationType): INavLink[] => [
  { title: t('nav.dividends'), src: dividendsIcon, link: Links.dividends },
  { title: t('nav.cashback'), src: cashbackIcon, link: Links.cashback },
  { title: t('nav.referral'), src: referralIcon, link: Links.referral },
  { title: t('nav.events'), src: eventsIcon, link: `${Links.events}${Links.starwars}` },
  {
    title: t('nav.missions'),
    src: missionsIcon,
    link: Links.missions,
    withCondition: true,
  },
];

export const FAQCategories = {
  games: 'Games',
  dividends: 'Dividends',
  cashback: 'Cashback',
  referral: 'Referral',
  events: 'Events',
};
