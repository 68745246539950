import { IUser } from 'types';
import { getAuthToken } from 'func/auth';

export interface IUserState {
  profile: IUser | null;
  token: string | null;
}

export const userInitialState: IUserState = {
  profile: null,
  token: getAuthToken(),
};
