import clsx from 'clsx';

import { ReactComponent as ArrowIcon } from 'assets/img/common/arrow.svg';

import styles from './styles.module.scss';
import { IGamesSlideButtonPrev } from './types';

const GamesSlideButtonPrev: React.FC<IGamesSlideButtonPrev> = ({ hasPrev, onPrev }) => {
  return (
    <button className={clsx(styles.wrapper, styles.prev)} disabled={!hasPrev} onClick={onPrev}>
      <ArrowIcon className={clsx(styles.icon, styles.prevIcon)} />
    </button>
  );
};

export default GamesSlideButtonPrev;
