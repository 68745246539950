import sanitize from 'sanitize-html';
import { useEffect, useState } from 'react';

import SeoWrap from 'components/common/SeoWrap';
import { useLocalization } from 'components/Internationalization';

const KenoSeoText: React.FC = () => {
  const { translateCustomNamespace, language } = useLocalization();

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  const handleTranslate = async () => {
    const newTitle = await translateCustomNamespace('keno.title', 'seo-keno');
    const newBody = await translateCustomNamespace('keno.body', 'seo-keno');

    setTitle(newTitle);
    setBody(newBody);
  };

  useEffect(() => {
    handleTranslate();
  }, [language]);

  return (
    <SeoWrap defaultHeight="200px" title={sanitize(title)}>
      <div dangerouslySetInnerHTML={{ __html: sanitize(body) }} />
    </SeoWrap>
  );
};

export default KenoSeoText;
