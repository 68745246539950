import Big from 'big.js';
import dayjs from 'dayjs';

import chip_01 from 'assets/img/Cards/Chips/0.1.webp';
import chip_05 from 'assets/img/Cards/Chips/0.5.webp';
import chip_1 from 'assets/img/Cards/Chips/1.webp';
import chip_5 from 'assets/img/Cards/Chips/5.webp';
import chip_10 from 'assets/img/Cards/Chips/10.webp';
import chip_25 from 'assets/img/Cards/Chips/25.webp';

import chip_10_vip from 'assets/img/Cards/Chips/10_vip.webp';
import chip_50_vip from 'assets/img/Cards/Chips/50_vip.webp';
import chip_100_vip from 'assets/img/Cards/Chips/100_vip.webp';
import chip_500_vip from 'assets/img/Cards/Chips/500_vip.webp';
import chip_1000_vip from 'assets/img/Cards/Chips/1000_vip.webp';

import { TEXAS_HOLDEM_ACTIONS } from 'components/constants/texasHoldem';
import { ITexasHoldemLimits, TexasHoldemBetHistory, TexasHoldemGameState } from 'context/contextTexasHoldem/types';
import {
  ITexasHoldemAction,
  TexasHoldemActionConditions,
} from 'components/Games/TexasHoldem/components/TexasHoldemActions/types';
import { TEXAS_HOLDEM_DEFAULT_ANIMATION, TEXAS_HOLDEM_FAST_ANIMATION } from 'context/contextTexasHoldem/constants';
import { CardGameStake, HistoryElem } from 'types';

export const texasHoldemAnimationDelay = (fastMode: boolean) => {
  return fastMode ? TEXAS_HOLDEM_FAST_ANIMATION : TEXAS_HOLDEM_DEFAULT_ANIMATION;
};

export const chipsIconByAmountAndStake = (amount: string, stake: CardGameStake) => {
  switch (amount) {
    case '0.1': {
      return chip_01;
    }
    case '0.5': {
      return chip_05;
    }
    case '1': {
      return chip_1;
    }
    case '5': {
      return chip_5;
    }
    case '10': {
      if (stake === CardGameStake.low) {
        return chip_10;
      }

      return chip_10_vip;
    }
    case '25': {
      return chip_25;
    }
    case '50': {
      return chip_50_vip;
    }
    case '100': {
      return chip_100_vip;
    }
    case '500': {
      return chip_500_vip;
    }
    case '1000': {
      return chip_1000_vip;
    }
  }
};

const checkCondition = (condition: TexasHoldemActionConditions, mainSum: string, limits: ITexasHoldemLimits) => {
  if (condition === 'doubleGreaterThanMax') {
    if (Big(mainSum).div(2).eq(limits.max)) {
      return false;
    }

    return Big(mainSum).gt(limits.max);
  }

  if (condition === 'doubleLowerThanMax') {
    return Big(mainSum).lte(limits.max);
  }
};

export const texasHoldemActionsByState = (
  gameState: TexasHoldemGameState,
  amount = '0',
  mainSum: string,
  limits: ITexasHoldemLimits,
): ITexasHoldemAction[] => {
  if (gameState === TexasHoldemGameState.notStarted && Big(amount).lte(0)) {
    return [];
  }

  const newActions = TEXAS_HOLDEM_ACTIONS.filter((a) => {
    const matchState = a.state.includes(gameState);
    const matchCondition = !a.condition || checkCondition(a.condition, mainSum, limits);

    return matchState && matchCondition;
  });

  return newActions;
};

export const calcTexasHoldemCardAdditionalDelay = (
  gameState: TexasHoldemGameState,
  previousGameState: TexasHoldemGameState,
  delay: number,
  multiplier: number,
  dealerCards?: boolean,
): number => {
  if (gameState === TexasHoldemGameState.multiply) {
    if (previousGameState === TexasHoldemGameState.flop) {
      return dealerCards ? delay : 0;
    }

    return delay * multiplier;
  }

  return dealerCards ? delay : 0;
};

export const formattedCard = (card: string) => {
  const cardRegex = /[a-z]_/gim;

  return card.replace(cardRegex, '');
};

export const prepareTexasHoldemHistory = (bets: TexasHoldemBetHistory[]): HistoryElem[] => {
  return bets.map<HistoryElem>((b) => ({
    id: b.id,
    betId: b.id,
    amount: b.amount,
    tokenCode: b.tokenCode,
    displayName: b.displayName,
    isAuto: b.isAuto,
    multiplier: b.multiplier,
    payout: b.payout,
    createdAt: dayjs(b.createdAt).toDate(),
    result: b.result?.outcome?.toLowerCase(),
    num: b.multiplier,
  }));
};
