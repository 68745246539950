import React, { useEffect, useState } from 'react';

import { getRatesArray } from 'func/prepareDataFooter';
import { useAppSelector } from 'hooks/useAppSelector';
import { generalDataExchangeRates } from 'store/generalData/generalDtata.selectors';

import styles from './styles.module.scss';
import RatesSlider from './RatesSlider';

const BtcRate: React.FC = () => {
  const rates = useAppSelector(generalDataExchangeRates);

  const [ratesList, setRatesList] = useState(null);

  useEffect(() => {
    if (rates) {
      setRatesList(getRatesArray(rates));
    }
  }, [rates]);

  if (!ratesList) return null;

  return (
    <div className={styles.btcRatesSliderWrap}>
      <RatesSlider ratesList={ratesList} />
    </div>
  );
};

export default BtcRate;
