import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { getImgSrc } from 'func/common';

import WithdrawAddressItem from './WithdrawAddressItem';
import { IWithdrawAddressDrop } from './types';
import WithdrawDropDownStyle from './styles';

const WithdrawAddressesDrop: React.FC<IWithdrawAddressDrop> = ({ valueInput, onChangeInput, onChangeMemo, data }) => {
  const [open, setOpen] = useState(false);

  const sampleContainer = {
    maxHeight: '65px',
    padding: '0 15px',
    background: 'transparent',
  };

  const handleOpen = () => setOpen(!open);

  const handleBlur = (event) => {
    if (event.relatedTarget?.tabIndex >= 0) return;
    setOpen(false);
  };

  const handleClickWallet = (val, memo?: string) => {
    onChangeInput(val);
    onChangeMemo(memo);
    setOpen(false);
  };

  const handleChangeInput = (event) => {
    const value = event.currentTarget.value;
    onChangeInput(value);
  };

  return (
    <WithdrawDropDownStyle>
      <div className="with__drop__header" onClick={handleOpen} onBlur={handleBlur}>
        <input
          type="text"
          value={valueInput}
          onChange={handleChangeInput}
          placeholder="enter or select address"
          className="with__drop__body-inp"
        />
        <i className={open ? 'up' : 'down'} />
      </div>
      <div className={open ? 'with__drop__body open' : 'with__drop__body'}>
        <PerfectScrollbar style={sampleContainer} options={{ suppressScrollY: false }} key={0}>
          {data.map((elem, index) => (
            <WithdrawAddressItem
              key={elem.id}
              name={elem.name}
              title={elem.address}
              memo={elem.memo}
              index={index}
              src={getImgSrc(elem.token.displayName)}
              isWhitelisted={elem.isWhitelisted}
              id={elem.id}
              onClickWallet={handleClickWallet}
            />
          ))}
        </PerfectScrollbar>
      </div>
    </WithdrawDropDownStyle>
  );
};

export default WithdrawAddressesDrop;
