import bitcoinIcon from 'assets/img/footer/bitcoinFooter.svg';
import youtubeIcon from 'assets/img/footer/youtube.svg';
import facebookIcon from 'assets/img/footer/facebook.svg';
import instagramIcon from 'assets/img/footer/instagram.svg';
import twitterIcon from 'assets/img/footer/twitter.svg';

export enum SocialType {
  btc = 'BTC',
  telegram = 'Telegram',
  youtube = 'Youtube',
  facebook = 'Facebook',
  twitter = 'Twitter',
  instagram = 'Instagram',
}

export const SOCIAL_ITEMS = [
  { link: 'https://bitcointalk.org/index.php?topic=5389679.0', icon: bitcoinIcon, title: SocialType.btc },
  { link: '', icon: '', title: SocialType.telegram },
  {
    link: 'https://www.youtube.com/channel/UCDSxzCm85nSMaRnWUtHwLdQ/featured',
    icon: youtubeIcon,
    title: SocialType.youtube,
  },
  { link: 'https://www.facebook.com/StarBets.io', icon: facebookIcon, title: SocialType.facebook },
  { link: 'https://twitter.com/StarBets_io', icon: twitterIcon, title: SocialType.twitter },
  { link: 'https://www.instagram.com/starbets.official/', icon: instagramIcon, title: SocialType.instagram },
];
