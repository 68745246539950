import { amount } from './common';

export const getWalletTitle = (
  slotsRun: boolean,
  currentTokenCode: string,
  selectedTokenCode: string,
  userWallet: number,
): string => {
  if (slotsRun) {
    return 'In-Game';
  }

  return String(amount(currentTokenCode === selectedTokenCode ? userWallet : '0'));
};
