import { useQuery } from '@apollo/client';

import { GET_TOP_PLAYERS } from 'graphQl/query/homePage/topPlayers';
import { prepareTopPlayers } from 'func/prepareDataHomePage';
import { TOP_PLAYERS_TABLE_TITLES } from 'components/constants/table';

import TableWithoutPagination from 'components/Base/Table/TableWithoutPagination/TableWithoutPagination';

const TopPlayers: React.FC = () => {
  const { data } = useQuery(GET_TOP_PLAYERS, {
    fetchPolicy: 'cache-only',
  });

  return (
    <TableWithoutPagination
      thWidth="33%"
      titles={TOP_PLAYERS_TABLE_TITLES}
      prepareDataTable={prepareTopPlayers}
      data={data?.topPlayers}
      cutCurrency
      config="topPlayers"
    />
  );
};

export default TopPlayers;
