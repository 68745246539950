import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';
import { sha3_256 } from 'js-sha3';

import diceImage from 'assets/img/games/diceRoll.webp';
import monster from 'assets/img/common/monster.webp';

import config from 'config';
import { amount, getImgSrc } from 'func/common';
import { GET_BET } from 'graphQl/query/bet/bet';
import { GET_USER } from 'graphQl/query/auth/profile';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import DicePopUpStyle from 'components/Base/PopUps/components/DiceBetDetails/styles';
import RangeDicePopUp from 'components/Base/PopUps/components/DiceBetDetails/components/RangeDicePopUp';
import Loader from 'components/Base/Loader';
import CopyButton from 'components/Base/CopyButton';
import Button from 'components/Base/Button';
import InfoBetsDicePopUp from './components/InfoBetsDicePopUp/InfoBetsDicePopUp';

import { IBetResponse } from '../types';

interface Props {
  data?: any;
}

const { codesandboxUrl, sha256ValidatorUrl } = config;

const DiceBetDetails: React.FC<Props> = ({ data }) => {
  const { translate } = useLocalization();

  const [bet, setBet] = useState<IBetResponse>();
  const [isAutoBet, setIsAutoBet] = useState(false);
  const [errorResponse, setErrorResponse] = useState(null);

  const { data: betData, loading } = useQuery(GET_BET, {
    variables: { betId: data.id },
    onError: () => {
      setErrorResponse(true);
    },
  });
  const { data: user } = useQuery(GET_USER, {
    fetchPolicy: 'cache-only',
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log('[GET_USER_ERROR]: ', error);
    },
  });

  useEffect(() => {
    if (betData) {
      const newBet = pathOr<IBetResponse>(undefined, ['bet'], betData);

      if (newBet) {
        setBet(newBet);
        setIsAutoBet(newBet.isAuto);
      }
    }
  }, [betData]);

  const getDate = (string: string): string => dayjs(string).format('MM/DD/YYYY  hh:mm:ss A');

  const handleVerify = () => {
    window.open(bet?.playerSeed ? codesandboxUrl : sha256ValidatorUrl, '_blank');
  };

  return loading || !bet ? (
    <Loader pages />
  ) : (
    <DicePopUpStyle payout={data?.payout}>
      {errorResponse ? (
        <div className="error__wrap">
          <p>{translate('popups.bets.details.error')}</p>
          <div>
            <img alt="icon" src={monster} />
          </div>
        </div>
      ) : (
        <>
          <div className="title">{translate('popups.bets.details.title')}</div>
          <div className="subtitle">
            <div className="subtitle-item1">
              <p>{translate('popups.bets.details.player')}:</p>
              <p>{translate('popups.bets.details.date')}:</p>
              <p>{translate('popups.bets.details.id')}:</p>
            </div>
            <div className="subtitle-item2">
              <h3>{user.profile.alias}</h3>
              <h3>{getDate(bet?.createdAt)}</h3>
              <h3>{bet?.id}</h3>
            </div>
          </div>
          <div className="amount-block">
            <div className="amount">
              <p className="amount-title">{translate('popups.bets.details.amount')}</p>
              <div className="amount-subtitle">
                <img alt="icon" src={getImgSrc(bet?.token.displayName)} className="token-icon" />
                <span className="amount-result">{amount(bet?.amount)}</span>
              </div>
            </div>
            <div className="payout">
              <p className="amount-title">{translate('popups.bets.details.payout')}</p>
              <div className="amount-subtitle">
                <img src={getImgSrc(bet?.token.displayName)} alt="icon" className="token-icon" />
                <span className="amount-result">{amount(bet?.payout)}</span>
              </div>
            </div>
          </div>
          <div className="screen">
            <div className="screen-item1">
              <img src={diceImage} alt="icon" className="screen-image" />
            </div>
            <div className="screen-item2">
              <p>{bet?.result.luckyNumber}</p>
            </div>
          </div>
          <div className="range">
            <RangeDicePopUp
              inputRangeValue={bet ? [bet?.betOn.predictedNumber] : null}
              rollUnderValue={bet?.betOn.mode.toLowerCase() === 'under'}
            />
          </div>
          <div className="info-bets-pop-up-wrap">
            <InfoBetsDicePopUp
              inputRangeValue={[bet?.betOn.predictedNumber]}
              rollUnderValue={bet?.betOn.mode.toLowerCase() === 'under'}
              checkboxValue={isAutoBet}
              inputBetValue={bet?.amount}
              chance={
                bet?.betOn.mode.toLowerCase() === 'under'
                  ? bet?.betOn.predictedNumber
                  : 99 - Number(bet?.betOn.predictedNumber)
              }
              multiplayer={bet?.multiplier}
              tokenCode={bet?.token.displayName}
            />
          </div>
          <div className="subtitle seeds">
            <div className="subtitle-item1">
              {bet?.playerSeed ? <p>{translate('popups.bets.details.player-seed')}:</p> : null}
              <p>{translate('popups.bets.details.server-seed')}:</p>
              {!bet?.playerSeed ? <p>{translate('popups.bets.details.hash')}:</p> : null}
            </div>
            <div className="subtitle-item2">
              {bet?.playerSeed ? (
                <div className="seeds-text">
                  <p>{bet.playerSeed}</p>
                  <CopyButton copyContent={bet.playerSeed} />
                </div>
              ) : null}
              <div className="seeds-text">
                <p>{bet?.serverSeed}</p>
                <CopyButton copyContent={bet?.serverSeed} />
              </div>
              {!bet?.playerSeed ? (
                <div className="seeds-text">
                  <p>{sha3_256(bet?.serverSeed || '')}</p>
                  <CopyButton copyContent={sha3_256(bet?.serverSeed || '')} />
                </div>
              ) : null}
            </div>
          </div>
          <div className="verify-button-wrapper">
            <Button
              className="verify-button"
              title={translate('popups.bets.details.verify')}
              variant={ButtonType.primary}
              onclick={handleVerify}
              width="100px"
            />
          </div>
        </>
      )}
    </DicePopUpStyle>
  );
};

export default DiceBetDetails;
