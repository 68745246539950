import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';
import { sha3_256 } from 'js-sha3';

import config from 'config';
import { GET_BET } from 'graphQl/query/bet/bet';
import { useLocalization } from 'components/Internationalization';
import { ButtonType } from 'components/Base/Button/types';
import { ITexasHoldemHand } from 'context/contextTexasHoldem/types';
import { convertCryptoToUSD, getAmountWithoutZeroEnd } from 'func/common';
import { useAppSelector } from 'hooks/useAppSelector';
import { generalDataExchangeRates } from 'store/generalData/generalDtata.selectors';
import { ContextTokenCode } from 'context';

import BetDetailsWrap from 'components/Base/PopUps/components/base/BetDetailsWrap';
import Loader from 'components/Base/Loader';
import CopyButton from 'components/Base/CopyButton';
import Button from 'components/Base/Button';
import TexasHoldemCombination from './components/TexasHoldemCombination';

import styles from './styles.module.scss';
import { IBlackJackBetDetails, ITexasHoldemBet } from './types';

const { sha256ValidatorUrl } = config;

const TexasHoldemBetDetails: React.FC<IBlackJackBetDetails> = ({ data }) => {
  const { translate } = useLocalization();
  const { tokenCode } = useContext(ContextTokenCode);

  const [bet, setBet] = useState<ITexasHoldemBet>();
  const [dealerHand, setDealerHand] = useState<ITexasHoldemHand>();
  const [playerHand, setPlayerHand] = useState<ITexasHoldemHand>();

  const exchangeRates = useAppSelector(generalDataExchangeRates);

  const { data: betData, loading } = useQuery(GET_BET, { variables: { betId: data.id } });

  useEffect(() => {
    const newBet = pathOr<ITexasHoldemBet>(undefined, ['bet'], betData);

    if (newBet) {
      setBet(newBet);
      setDealerHand(newBet.result.dealerHand);
      setPlayerHand(newBet.result.playerHand);
    }
  }, [betData]);

  const getFormattedBalance = (amount: string) => {
    const rate = exchangeRates[tokenCode.token];

    if (!rate) {
      return getAmountWithoutZeroEnd('0');
    }

    const formattedAmount = convertCryptoToUSD(rate, amount);

    return getAmountWithoutZeroEnd(formattedAmount);
  };

  const handleVerify = () => {
    window.open(sha256ValidatorUrl, '_blank');
  };

  if (loading || !bet) return <Loader pages />;

  return (
    <BetDetailsWrap bet={bet as any}>
      <div className={styles.combinations}>
        {playerHand ? (
          <TexasHoldemCombination title={translate('popups.bets.details.texas-holdem.player')} hand={playerHand} />
        ) : null}
        {dealerHand ? (
          <TexasHoldemCombination title={translate('popups.bets.details.texas-holdem.dealer')} hand={dealerHand} />
        ) : null}
      </div>
      <div className={styles.payouts}>
        <p className={styles.payout}>
          Ante: <span className={styles.amount}>${getFormattedBalance(bet.result.payouts.ante)}</span>
        </p>
        <p className={styles.payout}>
          Blind: <span className={styles.amount}>${getFormattedBalance(bet.result.payouts.blind)}</span>
        </p>
        {bet.result.payouts.trips ? (
          <p className={styles.payout}>
            Trips: <span className={styles.amount}>${getFormattedBalance(bet.result.payouts.trips)}</span>
          </p>
        ) : null}
        {bet.result.payouts.play ? (
          <p className={styles.payout}>
            Play: <span className={styles.amount}>${getFormattedBalance(bet.result.payouts.play)}</span>
          </p>
        ) : null}
      </div>
      <div className={styles.seeds}>
        <div className={styles.subtitleItem}>
          <p>{translate('popups.bets.details.player-seed')}:</p>
        </div>
        <div className={styles.subtitleItem}>
          <div className={styles.seedsText}>
            <p>{bet.serverSeed}</p>
            <CopyButton copyContent={bet.serverSeed} />
          </div>
        </div>
      </div>
      <div className={styles.seeds}>
        <div className={styles.subtitleItem}>
          <p>{translate('popups.bets.details.hash')}:</p>
        </div>
        <div className={styles.subtitleItem}>
          <div className={styles.seedsText}>
            <p>{sha3_256(bet.serverSeed || '')}</p>
            <CopyButton copyContent={sha3_256(bet.serverSeed || '')} />
          </div>
        </div>
      </div>
      <div className={styles.verifyButtonWrapper}>
        <Button
          className={styles.verifyButton}
          title={translate('popups.bets.details.verify')}
          variant={ButtonType.primary}
          onclick={handleVerify}
          width="100px"
        />
      </div>
    </BetDetailsWrap>
  );
};

export default TexasHoldemBetDetails;
