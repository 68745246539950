import { gql } from '@apollo/client';

export const GET_AVATARS = gql`
  query avatars {
    avatars {
      avatar
      id
      rank
      type
    }
  }
`;
