import dayjs from 'dayjs';
import Big from 'big.js';
import { pathOr } from 'ramda';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';

import { IExchangeRate, IRakeback, IRank, ISetting } from 'types';
import { SettingName } from 'components/constants/constants';
import { RAKEBACK } from 'graphQl/query/rakeback';
import { EXCHANGE_RATES } from 'graphQl/query/exchangeRate/exchangeRate';
import { GET_COMMON_SETTINGS } from 'graphQl/query/settings/bonusSettings';
import { GET_USER } from 'graphQl/query/auth/profile';
import { GET_RANKS } from 'graphQl/query/cashback/ranks';
import { calcRakebackUsdAmount } from 'func/rakeback';
import { userToken } from 'store/user/user.selectors';

import { useAppSelector } from './useAppSelector';

const RAKEBACK_MIN_WITHDRAWAL = '0.000001';

interface IRakebackHook {
  matchRank: boolean;
  collectable: boolean;
  available: boolean;
  minutes: number;
  seconds: number;
  percentage: number;
  minWithdrawal: string;
  withdrawDelay: string;
}

const useRakeback = (): IRakebackHook => {
  const [rakebacks, setRakebacks] = useState<IRakeback[]>([]);
  const [exchangeRates, setExchangeRates] = useState<IExchangeRate[]>([]);
  const [minWithdrawal, setMinWithdrawal] = useState(RAKEBACK_MIN_WITHDRAWAL);
  const [withdrawDelay, setWithdrawDelay] = useState('0');
  const [matchRank, setMatchRank] = useState(false);
  const [collectable, setCollectable] = useState(false);
  const [available, setAvaialble] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const token = useAppSelector(userToken);

  const { minutes, seconds, restart } = useTimer({ expiryTimestamp: new Date(), onExpire: () => handleExpire(token) });

  const { data: userData } = useQuery(GET_USER, { skip: !token, fetchPolicy: 'cache-and-network' });
  const { data: rankData } = useQuery(GET_RANKS, { skip: !token, fetchPolicy: 'cache-and-network' });
  const { data, refetch: refetchRakeback } = useQuery(RAKEBACK, {
    skip: !token,
    fetchPolicy: 'cache-and-network',
  });
  const { data: exchangeRatesData } = useQuery(EXCHANGE_RATES, { fetchPolicy: 'cache-only' });
  const { data: settingsData } = useQuery(GET_COMMON_SETTINGS, { fetchPolicy: 'cache-only' });

  useEffect(() => {
    if (data) {
      const newRakebacks = pathOr<IRakeback[]>([], ['rakeback'], data);

      setRakebacks(newRakebacks);
    }
  }, [data]);

  useEffect(() => {
    if (exchangeRatesData) {
      const newExchangeRates = pathOr<IExchangeRate[]>([], ['exchangeRates'], exchangeRatesData);

      setExchangeRates(newExchangeRates);
    }
  }, [exchangeRatesData]);

  useEffect(() => {
    if (userData && rankData) {
      const userRank = pathOr(1, ['profile', 'rank', 'id'], userData);
      const ranks = pathOr<IRank[]>([], ['ranks'], rankData);
      const sortedRanks = [...ranks].sort((a, b) => a.id - b.id);

      const rank = sortedRanks.find((r) => r.id === userRank);
      const rakebackPercent = rank?.rakeback || '0';

      setMatchRank(Number(rakebackPercent) >= 1);
    }
  }, [userData, rankData]);

  useEffect(() => {
    if (settingsData) {
      const settings = pathOr<ISetting[]>([], ['getSettings'], settingsData);
      const rakebackSetting = settings.find((s) => s.name === SettingName.rakebackWithdrawalDelay);
      const rakebackMinWithdrawalSetting = settings.find((s) => s.name === SettingName.rakebackMinWithdrawal);

      const rakebackWithdrawalDelay = rakebackSetting?.value || '0';
      const rakebackMinWithdrawal = rakebackMinWithdrawalSetting?.value || RAKEBACK_MIN_WITHDRAWAL;

      setWithdrawDelay(rakebackWithdrawalDelay);
      setMinWithdrawal(rakebackMinWithdrawal);
    }
  }, [settingsData]);

  // check availability
  useEffect(() => {
    if (rakebacks.length) {
      const [rakeback] = rakebacks;
      const { withdrawalAt } = rakeback;

      const now = dayjs();
      const newWithdrawTime = dayjs(withdrawalAt).add(Number(withdrawDelay), 'minutes');

      if (now.isBefore(newWithdrawTime)) {
        setAvaialble(false);

        restart(newWithdrawTime.toDate(), true);
        return;
      }

      setAvaialble(true);
    }
  }, [rakebacks, withdrawDelay]);

  // check collectable
  useEffect(() => {
    if (rakebacks.length) {
      const usdAmount = calcRakebackUsdAmount(rakebacks, exchangeRates);
      const isLower = Big(usdAmount).gt(minWithdrawal);

      setCollectable(isLower);
    }
  }, [rakebacks, exchangeRates, minWithdrawal, withdrawDelay]);

  // update progress percentage
  useEffect(() => {
    if (rakebacks.length) {
      const [rakeback] = rakebacks;
      const { withdrawalAt } = rakeback;

      const now = dayjs();
      const newWithdrawTime = dayjs(withdrawalAt).add(Number(withdrawDelay), 'minutes');

      const secondsLeft = newWithdrawTime.diff(now, 'minutes');

      if (Big(withdrawDelay).lte(0)) {
        setPercentage(0);
        return;
      }

      const newPercentage = Big(secondsLeft).div(withdrawDelay).mul(100).toNumber();

      setPercentage(newPercentage >= 3 ? newPercentage : 3);
    }
  }, [rakebacks, minutes, withdrawDelay]);

  function handleExpire(authToken: string | null) {
    setAvaialble(true);
    if (authToken) refetchRakeback();
  }

  return { matchRank, collectable, available, percentage, minWithdrawal, withdrawDelay, minutes, seconds };
};

export default useRakeback;
