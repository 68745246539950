import clsx from 'clsx';

import { useLocalization } from 'components/Internationalization';
import { TEXAS_HOLDEM_RULES_TITLES } from 'components/constants/texasHoldem';

import styles from './styles.module.scss';
import { ITexasHoldemRulesNav } from './types';

const TexasHoldemRulesNav: React.FC<ITexasHoldemRulesNav> = ({ title, onChange }) => {
  const { translate } = useLocalization();

  return (
    <nav className={styles.wrapper}>
      <ul className={styles.list}>
        {TEXAS_HOLDEM_RULES_TITLES.map((t) => (
          <li key={t.value} className={styles.item}>
            <button
              className={clsx(styles.button, title === t.value ? styles.active : '')}
              type="button"
              onClick={() => onChange(t.value)}
            >
              {translate(t.title)}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default TexasHoldemRulesNav;
