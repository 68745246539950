import { useRef, useState } from 'react';
import Slider from 'react-slick';
import clsx from 'clsx';

import { RulesDescription } from 'components/constants/games';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import Button from 'components/Base/Button';

import { GamesRulesStyle } from './styles';
import { IGamesRules } from './types';
import styles from './styles.module.scss';

const GamesRules: React.FC<IGamesRules> = ({ data }) => {
  const { translate } = useLocalization();

  const sliderRef = useRef<Slider>(null);

  const { gameName } = data;

  const [active, setActive] = useState(0);

  const handleNext = () => {
    sliderRef?.current?.slickNext();
  };

  const currentGame = RulesDescription.find((rule) => rule.name === gameName);

  const settings = {
    ref: sliderRef,
    dots: true,
    infinite: true,
    speed: 200,
    className: styles.rulesSlider,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    beforeChange: (prev, next) => {
      setActive(next);
    },
  };

  return (
    <GamesRulesStyle className={styles.gamesRulesWrap}>
      <div className={styles.rulesTitle}>
        <h6 className={styles.rulesTitleItem}>{translate('popups.rules.title')}</h6>
      </div>
      <div className={styles.rulesWrap}>
        <Slider {...settings}>
          {currentGame.images.map((slide) => (
            <img src={slide} alt="icon" key={slide} />
          ))}
        </Slider>
      </div>
      <div className={styles.ruleDescription}>
        {currentGame.description.map((rule, ind) => (
          <p className={clsx(styles.ruleDescriptionTitle, active === ind ? styles.active : '')} key={rule}>
            {translate(rule)}
          </p>
        ))}
      </div>
      <Button
        className={styles.button}
        variant={ButtonType.primary}
        title={translate('popups.rules.action')}
        width="147px"
        onclick={handleNext}
      />
    </GamesRulesStyle>
  );
};

export default GamesRules;
