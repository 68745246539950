import { useContext, useEffect } from 'react';

import securityIcon from 'assets/img/common/securityIcon.svg';

import { ContextPopUps } from 'context';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import ButtonSubmit from 'components/Base/PopUps/components/ButtonSubmit';

import styles from './styles.module.scss';

interface Props {
  data: {
    setResponseStatus: (arg: boolean) => void;
  };
}

const ChangePasswordSuccess: React.FC<Props> = ({ data: { setResponseStatus } }) => {
  const { translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  useEffect(() => {
    setResponseStatus(false);
  }, []);

  return (
    <div className={styles.changePassSuccess}>
      {' '}
      <p className={styles.changePassSuccessTitle}>{translate('popups.password-change.success.title')}</p>
      <p className={styles.changePassSuccessSubTitle}>{translate('popups.password-change.success.desc')}</p>
      <img
        className={styles.changePassSuccessImg}
        src={securityIcon}
        width="300px"
        height="300px"
        alt="security Icon"
      />
      <ButtonSubmit
        className={styles.button}
        handleButton={() => setPopUpsOpen(null)}
        variant={ButtonType.secondary}
        width="288px"
        title={translate('general.buttons.close')}
        color="#242c45"
      />
    </div>
  );
};

export default ChangePasswordSuccess;
