import clsx from 'clsx';

import SelectComponent from './select';

import styles from './styles.module.scss';
import { ISelect } from './types';

const Select: React.FC<ISelect> = ({ label = '', labelClassName = '', labelTextClassName = '', ...props }) => {
  return label ? (
    <label className={clsx(styles.label, labelClassName)}>
      <span className={clsx(styles.labelText, labelTextClassName)}>{label}</span>
      <SelectComponent {...props} />
    </label>
  ) : (
    <SelectComponent {...props} />
  );
};

export default Select;
