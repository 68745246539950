import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { popUps } from 'components/constants/constants';
import { ContextTokenCode } from 'context';
import { getImgSrc } from 'func/common';
import { filterNetworksAddressBook, filterNetworksDeposit } from 'func/prepareDataPopUps';
import { GET_ADDRESS_BOOK } from 'graphQl/query/withdraw/withdraw';
import { useLocalization } from 'components/Internationalization';
import { ButtonType } from 'components/Base/Button/types';

import Button from 'components/Base/Button';
import DropDown from 'components/Base/DropDown';
import AddressBookItem from './compontnts/AddressBookItem';

import { IAddressBook } from './types';
import styles from './styles.module.scss';

const AddressBook: React.FC<IAddressBook> = ({
  setActivePopUp,
  setDataPopUp,
  selectedToken,
  selectedNetwork,
  setSelectedToken,
  setSelectedNetwork,
}) => {
  const { translate } = useLocalization();
  const { tokenWithNetworksList } = useContext(ContextTokenCode);

  const [networkDropdown, setNetworkDropdown] = useState(null);
  const [dropdownData, setDropdownData] = useState(null);
  const [walletList, setWalletList] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { data: walList } = useQuery(GET_ADDRESS_BOOK, { fetchPolicy: 'cache-and-network' });

  const handleAddWallet = () => {
    setDataPopUp(null);
    setActivePopUp(popUps.saveWalletAddressForm);
  };

  useEffect(() => {
    if (tokenWithNetworksList) {
      const result = tokenWithNetworksList.tokenWithNetworks.filter((elem) => elem.tokenCode !== 'STAR');
      const prepareData = result.map((elem) => ({
        title: elem.displayName,
        img: getImgSrc(elem.displayName),
        tokenCode: elem.tokenCode,
        networks: elem.networks,
        func: () => {
          filterNetworksDeposit(elem.networks, tokenWithNetworksList.networks, setNetworkDropdown, setSelectedNetwork);
          setSelectedToken({ token: elem.tokenCode, name: elem.displayName });
        },
      }));
      const firstElem = prepareData.find((elem) => elem.tokenCode === selectedToken.token);
      const properData = [firstElem, ...prepareData.filter((elem) => elem.tokenCode !== selectedToken.token)];

      setDropdownData(properData);
      filterNetworksDeposit(firstElem.networks, tokenWithNetworksList.networks, setNetworkDropdown, setSelectedNetwork);
      if (selectedNetwork) {
        const res = filterNetworksAddressBook(
          firstElem.networks,
          tokenWithNetworksList.networks,
          selectedNetwork,
          setSelectedNetwork,
        );
        setNetworkDropdown(res);
        setSelectedNetwork(selectedNetwork);
      }
    }
  }, [tokenWithNetworksList]);

  useEffect(() => {
    if (!selectedNetwork) {
      setWalletList(null);
    }
    if (selectedNetwork && selectedToken) {
      const netFilter = walList?.addressBook.filter((el) => el.network.code === selectedNetwork);
      const netTokenFilter = netFilter?.filter((el) => el.token.tokenCode === selectedToken.token);
      setWalletList(netTokenFilter?.length > 0 ? netTokenFilter : null);
    }
  }, [selectedNetwork, walList, selectedToken]);

  if (!dropdownData) return null;

  return (
    <div className={styles.addressWrap}>
      <div className={styles.addressTitle}>{translate('popups.wallet.addressBook')}</div>
      <div className={styles.addressHeader}>
        <div className={styles.addressHeaderItem}>
          <h6>{translate('popups.wallet.addressBook.currency')}:</h6>
          <DropDown data={dropdownData} width="100%" textPadding="50px" />
        </div>
        <div className={styles.addressHeaderItem}>
          <h6>{translate('popups.wallet.addressBook.network')}:</h6>
          {networkDropdown && <DropDown data={networkDropdown} width="100%" textPadding="20px" />}
        </div>
      </div>
      <div className={styles.addressWallets}>
        {walletList?.length > 0 && (
          <div className={styles.addressWalletsTitle}>{translate('popups.wallet.addressBook.list.title')}</div>
        )}
        {walletList
          ? walletList.map((elem) => (
              <AddressBookItem
                key={elem.id}
                tokenCode={elem.token.tokenCode}
                name={elem.name}
                title={elem.address}
                walletId={elem.id}
                isWhitelisted={elem.isWhitelisted}
                displayName={elem.token.displayName}
                memo={elem.memo}
                onError={setErrorMessage}
                setDataPopUp={setDataPopUp}
                setActivePopUp={setActivePopUp}
                selectedNetwork={elem.network.code}
              />
            ))
          : null}
        {(walletList?.length < 1 || !walletList) && (
          <div className={styles.addressWalletsEmpty}>{translate('popups.wallet.addressBook.empty')}</div>
        )}
      </div>
      <div className={styles.addressError}>{errorMessage}</div>
      <div>
        <Button
          className={styles.button}
          variant={ButtonType.secondary}
          title={translate('popups.wallet.addressBook.buttons.new')}
          onclick={handleAddWallet}
          color="#242C45"
        />
      </div>
    </div>
  );
};

export default AddressBook;
