import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { useContext, useEffect, useState } from 'react';

import emptyNotificationIcon from 'assets/img/Notification/empty-notification.webp';
import colorNotificationIcon from 'assets/img/Notification/notification.webp';

import NotificationContext from 'context/contextNotifications/context';

import NotificationList from 'components/Base/NotificationList';

import styles from './styles.module.scss';

const NotificationIcon: React.FC = () => {
  const { notifications, onOpenNotificationList } = useContext(NotificationContext);

  const desktop = useMediaQuery({ query: '(min-width: 1024px)' });

  const [unread, setUnread] = useState(false);
  const [showDot, setShowDot] = useState(false);

  useEffect(() => {
    const hasUnreadMessages = notifications.find((n) => !n.isRead);

    if (hasUnreadMessages) {
      setTimeout(() => {
        setShowDot(true);
      }, 2000);
    } else {
      setShowDot(false);
    }

    setUnread(Boolean(hasUnreadMessages));
  }, [notifications]);

  const handleToggleView = () => {
    onOpenNotificationList();
  };

  return (
    <article className={styles.wrapper}>
      <div className={styles.divider} />
      <div className={styles.notifyWrapper}>
        <button type="button" className={styles.button} onClick={handleToggleView}>
          <img
            className={clsx(styles.icon, unread ? styles.animation : '')}
            src={unread ? colorNotificationIcon : emptyNotificationIcon}
            alt="notification"
          />
          {unread && showDot ? <div className={styles.dot} /> : null}
        </button>
      </div>
      <div className={styles.divider} />
      {desktop ? <NotificationList /> : null}
    </article>
  );
};

export default NotificationIcon;
