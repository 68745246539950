import React, { useContext } from 'react';
import clsx from 'clsx';

import { ContextWallet } from 'context';
import { amount, handleSelectInputValue } from 'func/common';
import { lessThen } from 'func/prepareDataDice';
import { AUTO_BETTING_STATE } from 'components/constants/constants';
import InputCustom from 'components/Base/Input/InputCustom';
import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';
import { IAutoBetBlockNew } from './types';

const AutoBetBlockNew: React.FC<IAutoBetBlockNew> = ({
  disable,
  maxBet,
  minimumBet,
  checkboxValue,
  setRealBetValue,
  realBetValue,
  autoState,
  onChangeValue,
}) => {
  const { translate } = useLocalization();

  const { walletUser } = useContext(ContextWallet);

  const handleChangeInput = (e) => {
    let value = '';
    value = e.target.value;
    const valid = /^\?\d+\.\d*$|^\?[\d]*$/;
    const number = /\\d+\.\d*|\[\d]*|[\d]+\.[\d]*|[\d]+/;
    if (walletUser < +minimumBet) {
      if (e.nativeEvent.inputType === 'deleteContentBackward') {
        onChangeValue(AUTO_BETTING_STATE.maxBetAmount, e.target.value);
        return;
      }
      onChangeValue(AUTO_BETTING_STATE.maxBetAmount, '0.00000000');
      return;
    }
    if (value === '0.0000000' && e.nativeEvent.inputType !== 'deleteContentBackward') {
      if (walletUser >= +minimumBet) {
        onChangeValue(AUTO_BETTING_STATE.maxBetAmount, minimumBet);
        return;
      }
    }
    if (value === '00') {
      onChangeValue('maxBetAmount', '0.0');
      return;
    }
    if (!valid.test(value)) {
      const n = value.match(number);
      value = n ? n[0] : '';
    }
    if (+value > +maxBet && +value <= walletUser) {
      onChangeValue(AUTO_BETTING_STATE.maxBetAmount, String(amount(maxBet)));
      return;
    }
    if (+value > walletUser) {
      onChangeValue(AUTO_BETTING_STATE.maxBetAmount, String(amount(walletUser, false)));
      return;
    }
    onChangeValue(AUTO_BETTING_STATE.maxBetAmount, value);
  };

  const handleSetter = (e, setter) => {
    let value = '';
    value = e.target.value;
    const name = e.target.name;
    const valid = /^\?\d+\.\d*$|^\?[\d]*$/;
    const number = /\\d+\.\d*|\[\d]*|[\d]+\.[\d]*|[\d]+/;
    if (e.nativeEvent.inputType === 'deleteContentBackward') {
      setter(name, e.target.value);
      return;
    }
    if (value === '00') {
      setter(name, '0');
      return;
    }
    if (!valid.test(value)) {
      const n = value.match(number);
      value = n ? n[0] : '';
    }
    setter(name, value);
  };

  const handleBlurMaxBet = () => {
    if (autoState.maxBetAmount) {
      if (lessThen(autoState.maxBetAmount, minimumBet)) {
        onChangeValue(AUTO_BETTING_STATE.maxBetAmount, minimumBet);
      }
    }
  };

  const handleCopyPaste = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <div className={clsx(styles.autoBetNewWrap, checkboxValue && styles.autoBetsOpen)}>
      <div className={clsx(styles.autoBetNewContainer, disable && styles.autoBetsDisable)}>
        <div className={styles.autoBetNewItem}>
          <h6>{translate('inHouse.auto.bet.number')}</h6>
          <InputCustom
            type="text"
            placeholder="&#x221E;"
            name={AUTO_BETTING_STATE.betsNumbers}
            id="betsNumber"
            inputwidth="100%"
            value={Number(autoState.betsNumbers) > 0 ? autoState.betsNumbers : ''}
            onChange={(e) => handleSetter(e, onChangeValue)}
            onCopy={handleCopyPaste}
            onPaste={handleCopyPaste}
            onFocus={handleSelectInputValue}
          />
        </div>
        <div className={styles.autoBetNewItem}>
          <h6>{translate('inHouse.auto.bet.win')}</h6>
          <InputCustom
            type="text"
            placeholder="0"
            name={AUTO_BETTING_STATE.stopOnWinAmountDisplay}
            id="stopOnProfit"
            inputwidth="100%"
            value={autoState.stopOnWinAmountDisplay}
            onChange={(e) => {
              handleSetter(e, onChangeValue);
              onChangeValue(AUTO_BETTING_STATE.stopOnWinAmount, '');
            }}
            onCopy={handleCopyPaste}
            onPaste={handleCopyPaste}
            onFocus={handleSelectInputValue}
          />
        </div>
        <div className={styles.autoBetNewItem}>
          <h6>{translate('inHouse.auto.bet.loss')}</h6>
          <InputCustom
            type="text"
            placeholder="0"
            name={AUTO_BETTING_STATE.stopOnLossAmountDisplay}
            id="stopOnLoss"
            inputwidth="100%"
            value={autoState.stopOnLossAmountDisplay}
            onChange={(e) => {
              handleSetter(e, onChangeValue);
              onChangeValue(AUTO_BETTING_STATE.stopOnLossAmount, '');
            }}
            onCopy={handleCopyPaste}
            onPaste={handleCopyPaste}
            onFocus={handleSelectInputValue}
          />
        </div>
        <div className={styles.autoBetNewItem}>
          {' '}
          <h6>{translate('inHouse.auto.bet.max')}</h6>
          <InputCustom
            type="text"
            placeholder="0"
            name="maxBet"
            id="maxBet"
            inputwidth="100%"
            value={autoState.maxBetAmount}
            onChange={handleChangeInput}
            onBlur={handleBlurMaxBet}
            onCopy={handleCopyPaste}
            onPaste={handleCopyPaste}
            onFocus={handleSelectInputValue}
          />
        </div>
        <div className={styles.autoBetNewItem}>
          {' '}
          <h6>
            {translate('inHouse.auto.bet.win.reset')}{' '}
            {autoState.isOnWinIncrease ? translate('inHouse.auto.bet.increase') : translate('inHouse.auto.bet.reset')}
          </h6>
          <div
            style={{
              display: 'flex',
              position: 'relative',
            }}
          >
            <div className={styles.autoBetNewCheckboxWrap}>
              <input
                id="onWin"
                type="checkbox"
                checked={autoState.isOnWinIncrease}
                onChange={() => {
                  onChangeValue(AUTO_BETTING_STATE.isOnWinIncrease, !autoState.isOnWinIncrease);
                  if (!autoState.isOnWinIncrease) {
                    onChangeValue(AUTO_BETTING_STATE.onWinIncrease, '');
                    setRealBetValue(String(amount(realBetValue)));
                  }
                }}
                disabled={disable}
                onFocus={handleSelectInputValue}
              />
              <label htmlFor="onWin" className={styles.autoBetNewCheckboxLabel} />
            </div>

            <InputCustom
              type="text"
              placeholder="0"
              value={autoState.onWinIncrease}
              name={AUTO_BETTING_STATE.onWinIncrease}
              id="onWinIncrease"
              inputwidth="100%"
              onChange={(e) => {
                if (e.target.value) {
                  onChangeValue(AUTO_BETTING_STATE.isOnWinIncrease, true);
                } else {
                  onChangeValue(AUTO_BETTING_STATE.isOnWinIncrease, false);
                }
                handleSetter(e, onChangeValue);
              }}
              onCopy={handleCopyPaste}
              onPaste={handleCopyPaste}
              onFocus={handleSelectInputValue}
            />
            <p className={styles.autoBetNewFieldIcon}>%</p>
          </div>
        </div>
        <div className={styles.autoBetNewItem}>
          {' '}
          <h6>
            {translate('inHouse.auto.bet.loss.reset')}{' '}
            {autoState.isOnLossIncrease ? translate('inHouse.auto.bet.increase') : translate('inHouse.auto.bet.reset')}
          </h6>
          <div
            style={{
              display: 'flex',
              position: 'relative',
            }}
          >
            <div className={styles.autoBetNewCheckboxWrap}>
              <input
                id="onLoss"
                type="checkbox"
                checked={autoState.isOnLossIncrease}
                onChange={() => {
                  onChangeValue(AUTO_BETTING_STATE.isOnLossIncrease, !autoState.isOnLossIncrease);
                  if (!autoState.isOnLossIncrease) {
                    onChangeValue(AUTO_BETTING_STATE.onLossIncrease, '');
                    setRealBetValue(String(amount(realBetValue)));
                  }
                }}
                disabled={disable}
                onFocus={handleSelectInputValue}
              />
              <label htmlFor="onLoss" className={styles.autoBetNewCheckboxLabel} />
            </div>
            <InputCustom
              type="text"
              placeholder="0"
              value={autoState.onLossIncrease}
              name={AUTO_BETTING_STATE.onLossIncrease}
              id="onLossIncrease"
              inputwidth="100%"
              onChange={(e) => {
                if (e.target.value) {
                  onChangeValue(AUTO_BETTING_STATE.isOnLossIncrease, true);
                } else {
                  onChangeValue(AUTO_BETTING_STATE.isOnLossIncrease, false);
                }
                handleSetter(e, onChangeValue);
              }}
              onCopy={handleCopyPaste}
              onPaste={handleCopyPaste}
              onFocus={handleSelectInputValue}
            />
            <p className={styles.autoBetNewFieldIcon}>%</p>
          </div>
        </div>
      </div>{' '}
    </div>
  );
};

export default AutoBetBlockNew;
