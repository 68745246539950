import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { prepareDividendsPool } from 'func/prepareDataDividends';
import { getImgSrc } from 'func/common';
import { GET_DIVIDENDS_POOL, GET_DIVIDENDS_POOL_CHART } from 'graphQl/query/dividends/dividendsPool';
import { ContextTokenCode } from 'context';
import { useLocalization } from 'components/Internationalization';

import HighCardTitle from 'components/Base/Cards/HighCardTitle';
import DropDown from 'components/Base/DropDown';
import CurrencyRow from 'components/Base/Cards/CurrencyRow';

import PoolSchedule from './components';
import styles from './styles.module.scss';

const BackCard: React.FC = () => {
  const { translate } = useLocalization();
  const { tokenWithNetworksList } = useContext(ContextTokenCode);
  const [dropDownInfo, setDropDownInfo] = useState(null);
  const [requestToken, setRequestToken] = useState({ token: 'BTC', displayName: 'BTC' });
  const [balanceData, setBalanceData] = useState([]);

  const { data: dataChart, refetch: refetchChart } = useQuery(GET_DIVIDENDS_POOL_CHART, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: requestToken.token,
    },
  });

  const {
    data: dataPool,
    startPolling,
    stopPolling,
  } = useQuery(GET_DIVIDENDS_POOL, {
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    if (dataPool) {
      setBalanceData(prepareDividendsPool(dataPool.dividendPool));
    }
  }, [dataPool]);

  useEffect(() => {
    startPolling(5000);
    return () => {
      stopPolling();
    };
  }, []);

  useEffect(() => {
    if (requestToken) {
      refetchChart();
    }
  }, [requestToken]);

  useEffect(() => {
    if (tokenWithNetworksList) {
      const result = tokenWithNetworksList.tokenWithNetworks.filter((elem) => elem.tokenCode !== 'STAR');
      const prepareData = result.map((elem) => {
        const balanceObg = {
          title: elem.name ? elem.name : elem.displayName,
          img: getImgSrc(elem.displayName),
          tokenCode: elem.displayName,
          func: () => setRequestToken({ token: elem.tokenCode, displayName: elem.displayName }),
        };
        return balanceObg;
      });
      const indexToken = prepareData.findIndex((elem) => elem.tokenCode === 'BTC');
      const firstElem = prepareData.splice(indexToken, 1);
      setDropDownInfo([firstElem[0], ...prepareData]);
    }
  }, [tokenWithNetworksList]);

  if (!dropDownInfo) return null;

  return (
    <HighCardTitle
      subtitle=""
      title={translate('dividends.pool.chart.title.desktop')}
      titleMob={translate('dividends.pool.chart.title.mobile')}
      offBlur
    >
      <div className={styles.dividendsPoolBack}>
        <div className={styles.dividendsPoolBackTitleWrap}>
          <div className={styles.dividendsPoolBackDrop}>
            <h5>{translate('dividends.pool.chart.dropdown.title')}:</h5>
            <DropDown data={dropDownInfo} width="100%" textPadding="50px" tokens />
          </div>
          <div className={styles.dividendsPoolBackTitle}>
            <h5>{translate('dividends.pool.chart.current.title')}</h5>
            <CurrencyRow
              tokenCode={requestToken.token}
              displayName={requestToken.displayName}
              balanceData={balanceData}
            />
          </div>
        </div>
        <PoolSchedule requestToken={requestToken.token} dataSchedule={dataChart?.dividendPoolChart} />
      </div>
    </HighCardTitle>
  );
};

export default BackCard;
