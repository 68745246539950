import { useState } from 'react';

import { InHouseGamesPageTablesTitles, IN_HOUSE_TABLE_TITLES } from 'components/constants/table';

import TablesTitles from 'components/common/TablesTitles';

import styles from './styles.module.scss';
import MyLimboBets from './components/MyLimboBets';

const LimboTables: React.FC = () => {
  const [table, setTable] = useState(InHouseGamesPageTablesTitles.myBets);

  const handleTitle = (title) => {
    setTable(title);
  };

  return (
    <div className={styles.limboTablesWrap}>
      <TablesTitles currentTitle={table} titles={IN_HOUSE_TABLE_TITLES} onTitle={handleTitle} />
      {table === InHouseGamesPageTablesTitles.myBets ? <MyLimboBets /> : null}
    </div>
  );
};

export default LimboTables;
