import { RootState } from 'index';

export const dataFavoriteGamesList = (state: RootState) => state.data.favoriteGamesList;
export const dataUserBonuses = (state: RootState) => state.data.userBonuses;
export const dataIsDisplayBonus = (state: RootState) => state.data.isDisplayBonus;
export const dataIsActiveUserBonus = (state: RootState) => state.data.isActiveUserBonus;
export const dataFreeSpinsSettings = (state: RootState) => state.data.freeSpinsSettings;
export const dataGames = (state: RootState) => state.data.games;
export const dataVipStatus = (state: RootState) => state.data.vipStatus;
export const dataActiveBonusTokens = (state: RootState) => state.data.activeBonusTokens;
