import dayjs from 'dayjs';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { Cookies } from 'react-cookie';

import { GET_COMMON_SETTINGS } from 'graphQl/query/settings/bonusSettings';
import { getBgImage } from 'func/common';
import { ContextPopUps } from 'context';
import IntercomContext from 'context/contextIntercom/context';
import { Links } from 'components/constants';
import { userProfile } from 'store/user/user.selectors';
import { FIREBLOCKS_NOTIFICATION_COOKIE, FIREBLOCKS_RELEASE_DATE, popUps } from 'components/constants/constants';
import { useAppSelector } from 'hooks/useAppSelector';

import Sidebar from 'components/Sidebar';
import Header from 'components/Header';
import Footer from 'components/Footer';
import DefaultMeta from 'components/Base/DefaultMeta';
import PopUps from 'components/Base/PopUps';
import LotteryMobileContainer from 'components/Games/Lottery/LotteryMobileContainer';
import NotificationList from 'components/Base/NotificationList';
import FAQSection from 'components/Base/FAQSection';
import NotificationsLayout from 'components/NotificationsLayout';
import IntercomLauncher from 'components/IntercomLauncher';
import Router from 'components/Router';

import styles from './styles.module.scss';

const AppRoute = (): React.ReactElement => {
  const { popUpsOpen, setPopUpsOpen } = useContext(ContextPopUps);
  const { setLauncherHidden } = useContext(IntercomContext);
  const { pathname } = useLocation();

  const mobileNotificationView = useMediaQuery({ query: '(max-width: 1024px)' });
  const mobileLotteryView = useMediaQuery({ query: '(max-width: 1260px)' });

  const [bgImage, setBgImage] = useState(null);
  const [hideFooter, setHideFooter] = useState(false);
  const [isLanding, setIsLanding] = useState(false);

  const user = useAppSelector(userProfile);

  const { data: settingsData } = useQuery(GET_COMMON_SETTINGS, { fetchPolicy: 'cache-only' });

  useEffect(() => {
    if (settingsData) {
      setBgImage(getBgImage(settingsData.getSettings));
    }
  }, [settingsData]);

  useEffect(() => {
    setHideFooter(pathname === Links.notFound);
    setIsLanding(pathname.includes(Links.landing));
  }, [pathname]);

  useEffect(() => {
    setLauncherHidden(isLanding);
  }, [isLanding]);

  useEffect(() => {
    if (user) {
      const depositAddressExpired = dayjs(user.createdAt).isBefore(FIREBLOCKS_RELEASE_DATE);

      if (!depositAddressExpired) return;

      const cookies = new Cookies();

      const userAlreadyNotified = cookies.get(FIREBLOCKS_NOTIFICATION_COOKIE);

      if (userAlreadyNotified) return;

      setPopUpsOpen({
        modalOpen: popUps.depositChanged,
        data: { onClose: handleCloseDepositWarning },
      });
    }
  }, [user]);

  function handleCloseDepositWarning() {
    const cookies = new Cookies();
    const expires = dayjs().add(1, 'year').toDate();

    cookies.set(FIREBLOCKS_NOTIFICATION_COOKIE, true, { expires });
    setPopUpsOpen(null);
  }

  return (
    <div className={styles.routWrap}>
      <DefaultMeta />
      <img src={bgImage} alt="icon" className={clsx(styles.routBg, bgImage ? styles.active : '')} />
      <div className={popUpsOpen ? 'wrapper blur' : 'wrapper'}>
        {!isLanding ? <Header /> : null}
        <div className="radial-bg-1">
          <div className="home">
            {mobileNotificationView ? <NotificationList /> : null}
            {mobileLotteryView ? <LotteryMobileContainer /> : null}
            <Router />
            <FAQSection />
            {!hideFooter && !isLanding ? <Footer /> : null}
          </div>
          {!isLanding ? <Sidebar /> : null}
        </div>
      </div>
      {popUpsOpen ? <PopUps /> : null}
      <NotificationsLayout />
      <IntercomLauncher />
    </div>
  );
};

export default AppRoute;
