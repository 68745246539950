export const intercomCollectionKeys = {
  bonus: '10593684',
  mines: '10593685',
  coinflip: '10593686',
  limbo: '10593688',
  dice: '10593690',
  blackjack: '10593691',
  slots: '10593692',
  'live-casino': '10593693',
  keno: '10593694',
  'table-games': '10593695',
  account: '10593696',
  referral: '10593697',
  security: '10593698',
  cashback: '10593699',
  missions: '10593700',
  events: '10593701',
  dividends: '10593702',
  bigtimegaming: '10593704',
};
