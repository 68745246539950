import { useAppSelector } from 'hooks/useAppSelector';
import { userToken } from 'store/user/user.selectors';

import Wallet from './components/Wallet';
import NotificationIcon from './components/NotificationIcon';
import UserInfo from './components/UserInfo';

import styles from './styles.module.scss';

const HeaderAuth: React.FC = () => {
  const token = useAppSelector(userToken);

  return token ? (
    <div className={styles.authWrap}>
      <Wallet />
      <NotificationIcon />
      <UserInfo />
    </div>
  ) : null;
};

export default HeaderAuth;
