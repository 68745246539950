import { gql } from '@apollo/client';

export const GET_EVENTS = gql`
  query events {
    events {
      id
      name
      description
      status
      type
      prizeTokenCode
      totalWagerTokenCode
      rules
      eventRewardsCode
      prize
      image
      startAt
      endAt
      prizeTokenCodeDisplayName
    }
  }
`;

export const GET_CLOSED_EVENTS = gql`
  query closedEvents($input: EventsPaginationParams!) {
    closedEvents(paginationData: $input) {
      items {
        id
        name
        prizeTokenCode
        prize
        startAt
        prizeTokenCodeDisplayName
        totalWagerTokenCode
        type
      }
    }
  }
`;

export const GET_CURRENT_EVENT = gql`
  query eventCurrentResult($id: String!) {
    eventCurrentResult(eventId: $id) {
      id
      alias
      avatar
      rank
      totalWager
      prize
    }
  }
`;

export const GET_USER_EVENT_INFO = gql`
  query currentUserEventResult($id: String!) {
    currentUserEventResult(eventId: $id) {
      place
      totalWager
      prize
    }
  }
`;
