import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';

import bonusIcon from 'assets/img/Vip/bonus-category.webp';

import { useLocalization } from 'components/Internationalization';
import { applyStylesToText } from 'func/localization';
import { getImgSrc } from 'func/common';

import styles from './styles.module.scss';
import { VIP_WEEKLY_BONUSES } from './constants';

const VipBonuses: React.FC = () => {
  const { translate, language } = useLocalization();

  const [title, setTitle] = useState('');

  useEffect(() => {
    const titleText = translate('vip.bonuses.title');
    const styledText = applyStylesToText(titleText, styles.orange);

    setTitle(styledText);
  }, [language]);

  return (
    <section className={styles.wrapper}>
      <h3 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
      <p className={styles.desc}>{translate('vip.bonuses.desc')}</p>
      <ul className={clsx(styles.bonuses, 'animate__animated animate__fadeInUp')}>
        {VIP_WEEKLY_BONUSES.map((b) => (
          <li key={b.wager} className={styles.bonus}>
            <StyledGradient bg={b.background} gradient={b.gradient} />
            <div className={styles.iconBg} />
            <div className={styles.content}>
              <img className={styles.bonusIcon} src={bonusIcon} alt="Bonus Icon" />
              <div className={styles.textWrapper}>
                <p className={styles.text}>
                  <span className={styles.secondary}>{translate('vip.bonuses.wager')}:</span>
                  <span className={styles.main}>
                    <img src={getImgSrc('USD')} alt="USD" />
                    <b>${b.wager}</b>
                  </span>
                </p>
                <p className={styles.text}>
                  <span className={styles.secondary}>
                    <span className={styles.bonusEmoji}>🎁</span>
                    {translate('vip.bonuses.bonus')}:
                  </span>
                  <span className={styles.main}>
                    <img src={getImgSrc('STAR')} alt="Star" />
                    <b>{b.bonus} STARs</b>
                  </span>
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default VipBonuses;

const StyledGradient = styled.div.withConfig({
  shouldForwardProp: (prop) => !['bg', 'gradient'].includes(prop),
})<{ bg: string; gradient: string }>`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;

  display: block;
  width: 100%;
  height: 50%;

  background: ${({ bg }) => bg};
  background: ${({ gradient }) => gradient};
`;
