import { useContext } from 'react';

import DiceGameContext from 'context/contextDiceGame/context';

import AutoBetBlockNew from 'components/Games/base/AutoBetBlockNew';

const DiceGameAutoSettings: React.FC = () => {
  const { auto, autoSettings, amount, autoStarted, gameSettings, onChangeAmount, onChangeAutoSettings } =
    useContext(DiceGameContext);

  const handleChangeAutoState = (name: string, value: boolean | string) => {
    onChangeAutoSettings(name, value);
  };

  return (
    <AutoBetBlockNew
      checkboxValue={auto}
      autoState={autoSettings}
      realBetValue={amount}
      disable={autoStarted}
      maxBet={gameSettings?.maximumBet}
      minimumBet={gameSettings?.minimumBet}
      setRealBetValue={onChangeAmount}
      onChangeValue={handleChangeAutoState}
    />
  );
};

export default DiceGameAutoSettings;
