import React, { useEffect, useState } from 'react';

import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile } from 'store/user/user.selectors';

import RankSystemHeader from '../RankSystemHeader';
import { IRankSystem } from '../../types';
import styles from './styles.module.scss';
import RankSystemSlider from '../RankSystemSlider';
import RankSystemDescription from '../RankSystemDescription';

const RankSystemCard: React.FC<IRankSystem> = ({ rankList, setMyRank }) => {
  const user = useAppSelector(userProfile);

  const [active, setActive] = useState(user?.rank?.id || 0);

  useEffect(() => {
    if (user) {
      setMyRank((prev) => ({
        ...prev,
        my: user?.rank?.id,
      }));
      setActive(user?.rank?.id);
    }
  }, [user]);

  useEffect(() => {
    setMyRank((prev) => ({
      ...prev,
      active,
    }));
  }, [active]);

  return (
    <div className={styles.rankCardWrap}>
      <RankSystemHeader />
      <div className={styles.rankCardBody}>
        <RankSystemSlider rankList={rankList} setActiveSlide={setActive} active={active} />
        <RankSystemDescription rankList={rankList} active={active} />
      </div>
    </div>
  );
};

export default RankSystemCard;
