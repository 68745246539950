import { gql } from '@apollo/client';

export const BIG_WINS = gql`
  query bigWins {
    bigWins {
      id
      user {
        alias
        avatar {
          avatar
        }
        rank {
          id
        }
      }
      createdAt
      game {
        name
      }
      payout
      tokenCode
    }
  }
`;
