/* eslint-disable no-useless-escape */

import { Language } from 'components/Internationalization/types';
import { ROUTES_WITHOUT_LANGUAGE } from 'components/Router/constants';

export const applyStylesToText = (text: string, className: string): string => {
  return text.replace(/\[(.*?)\]/gim, (text) => {
    const textWithoutSymbols = text.replace(/[\[\]]/gim, '');

    return `<span class="${className}">${textWithoutSymbols}</span>`;
  });
};

export const getPathWithLanguage = (path: string, language?: Language) => {
  const serviceRoute = ROUTES_WITHOUT_LANGUAGE.find((r) => r.path === path);

  if (serviceRoute) {
    return path;
  }

  if (language && language !== Language.english) {
    const withSlash = path.startsWith('/');
    const localePath = (withSlash ? '/:lang' : '') + path;
    const newPath = localePath.replace(':lang', language);

    return newPath;
  }

  return path;
};

export const getCurrentLanguageFromPath = (LANGUAGES: string[]) => {
  const startWithLanguageRegex = new RegExp(`^\\/(${LANGUAGES.join('|')})`, 'gmi');

  const { pathname } = location;
  const matchLanguage = pathname.match(startWithLanguageRegex);

  const [currentLanguage] = matchLanguage || [''];

  return currentLanguage;
};
