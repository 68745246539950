import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Links } from 'components/constants';
import { IPageIcon, getFAQSectionIcon } from 'func/faq';
import { DEFAULT_META_DESCRIPTION } from 'components/constants/constants';
import { FAQSort } from 'components/constants/faq';
import { useNavigate } from 'hooks/useNavigate';
import IntercomContext from 'context/contextIntercom/context';
import { IIntercomCollection } from 'context/contextIntercom/types';

import DocumentTitle from 'components/Base/DocumentTitle';
import PageTitle from 'components/Base/PageTitle';
import StyledLink from 'components/Base/Links/StyledLink';
import Loader from 'components/Base/Loader';
import FAQToolbar from '../FAQPage/components/FAQToolbar';

import FAQArticles from './components/FAQArticles';
import styles from './styles.module.scss';

const FAQCategoryPage: React.FC = () => {
  const navigate = useNavigate();
  const { categoryName } = useParams<{ categoryName?: string }>();
  const { collections, loading } = useContext(IntercomContext);

  const [collection, setCollection] = useState<IIntercomCollection | null>(null);
  const [icon, setIcon] = useState<IPageIcon>();
  const [sortBy, setSortBy] = useState(FAQSort.date);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (categoryName && collections.length) {
      const newCollection = collections.find((c) => c.key === categoryName.toLowerCase());
      if (!newCollection) {
        navigate(Links.faq);
        return;
      }

      const newIcon = getFAQSectionIcon(newCollection.key);

      setCollection(newCollection);
      setIcon(newIcon);
    }
  }, [categoryName, collections]);

  const handleChangeSort = (sort: string) => {
    setSortBy(sort as FAQSort);
  };

  const handleChangeSearch = (value: string) => {
    setSearch(value);
  };

  if (loading) {
    return <Loader pages />;
  }

  return collection ? (
    <main className={styles.wrapper}>
      <DocumentTitle data={{ title: `${collection.name} FAQ`, description: DEFAULT_META_DESCRIPTION }} />
      <PageTitle icon={icon.icon} title={`${collection.name} FAQ`} lineColor={icon.color} titleSize="big" largeImage>
        <StyledLink to={Links.faq} className={styles.link}>
          Go Back
        </StyledLink>
      </PageTitle>
      <FAQToolbar sort={sortBy} search={search} onChangeSort={handleChangeSort} onChangeSearch={handleChangeSearch} />
      <FAQArticles sort={sortBy} search={search} collectionId={collection.id} />
    </main>
  ) : null;
};

export default FAQCategoryPage;
