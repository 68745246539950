import { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Links } from 'components/constants';
import BlogProvider from 'context/contextBlog';
import LiveStatsProvider from 'context/contextLiveStats';

import Loader from 'components/Base/Loader';
import EventsPage from 'components/Pages/EventsPage';
import RedirectWithParams from 'components/Base/RedirectWithParams';

import styles from './styles.module.scss';
import { EVENTS_ROUTES, GAMES_ROUTES, DEFAULT_ROUTES, BLOG_ROUTES, REDIRECT_ROUTES, LANDING_ROUTES } from './constants';
import { prepareRedirectRoutes, prepareRoutes, updatePathWithLanguageRoute } from './utils';

export default () => {
  return (
    <div className={styles.routerWrap}>
      <Suspense fallback={<Loader pages />}>
        <Routes>
          {prepareRoutes(DEFAULT_ROUTES).map(({ path, component: Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
          <Route path={updatePathWithLanguageRoute(Links.events)} element={<EventsPage />}>
            {EVENTS_ROUTES.map(({ path, component: Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
          </Route>
          <Route element={<LiveStatsProvider />}>
            {prepareRoutes(GAMES_ROUTES).map(({ path, component: Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
          </Route>
          <Route element={<BlogProvider />}>
            {BLOG_ROUTES.map(({ path, component: Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
          </Route>
          {prepareRedirectRoutes(REDIRECT_ROUTES).map(({ path, redirectsTo, replace, param }) => (
            <Route
              key={path}
              path={path}
              element={<RedirectWithParams param={param} redirectsTo={redirectsTo} replace={replace} />}
            />
          ))}
          <Route path={updatePathWithLanguageRoute(Links.landing)}>
            {LANDING_ROUTES.map(({ path, component: Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
          </Route>
          <Route path="*" element={<Navigate to={Links.notFound} />} />
        </Routes>
      </Suspense>
    </div>
  );
};
