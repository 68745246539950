import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import { setLotteryState } from 'store/generalData';
import { useAppDispatch } from 'hooks/useAppDispatch';
import LiveStatsContext from 'context/contextLiveStats/context';
import { ILotteryState } from 'types';
import { GET_LOTTERY_HISTORY, GET_LOTTERY_ROUND_INFO } from 'graphQl/query/lottery/lottery';
import { prepareDataLotteryTable, prepareDataSlider } from 'func/prepareDataLottery';
import LotteryContent from 'components/Games/Lottery/LotteryDesktop/components/LotteryContent';
import LotteryDesktopResult from 'components/Games/Lottery/LotteryDesktop/components/LotteryDesktopResult';
import LotteryHeader from 'components/Games/Lottery/LotteryDesktop/components/LotteryHeader';
import LiveStatistics from 'components/Base/LiveStatistics';
import { generalDataLotteryState } from 'store/generalData/generalDtata.selectors';

import { useAppSelector } from 'hooks/useAppSelector';
import LotteryDesktopStyle from './styles';
import { ILotteryDesktop, TChangeTickets } from './types';

const LotteryDesktop: React.FC<ILotteryDesktop> = ({ className = '', isPopUp }) => {
  const { enabled: liveStatsEnabled } = useContext(LiveStatsContext);
  const dispatch = useAppDispatch();

  const refLottery = useRef();
  const myTicketsRef = useRef<TChangeTickets>(null);

  const [lotteryRound, setLotteryRound] = useState<ILotteryState | undefined>();
  const [lotteryHistory, setLotteryHistory] = useState(null);
  const [numberOfTickets, setNumberOfTickets] = useState(0);
  const [ticketPrice, setTicketPrice] = useState(1);

  const lotteryState = useAppSelector(generalDataLotteryState);

  const [lotteryRounds, { data: lotteryHistoryData }] = useLazyQuery(GET_LOTTERY_HISTORY, {
    fetchPolicy: 'cache-and-network',
  });
  const [roundInfo, { data: roundInfoData }] = useLazyQuery(GET_LOTTERY_ROUND_INFO, { fetchPolicy: 'cache-only' });

  useEffect(() => {
    if (!lotteryState) {
      roundInfo();
      return;
    }

    updateState(lotteryState);
  }, [lotteryState]);

  useEffect(() => {
    if (roundInfoData) {
      const round = pathOr<ILotteryState | null>(null, ['currentLotteryRound'], roundInfoData);

      if (round) {
        dispatch(setLotteryState(round));
      }
    }
  }, [roundInfoData]);

  useEffect(() => {
    if (lotteryHistoryData) {
      setLotteryHistory(lotteryHistoryData);
    }
  }, [lotteryHistoryData]);

  useEffect(() => {
    lotteryRounds();
  }, []);

  function updateState(state: ILotteryState) {
    const { id, ticketPrice: lotteryTicketPrize, numberOfTickets: lotteryNumberOfTickets } = state;

    if (lotteryRound && id !== lotteryRound.id && myTicketsRef.current) {
      myTicketsRef.current(0);
    }
    setTicketPrice(Number(lotteryTicketPrize) || 1);
    setLotteryRound(state);
    setNumberOfTickets(lotteryNumberOfTickets);
  }

  const handleSubmitRefetch = () => {
    roundInfo();
  };

  return (
    <LotteryDesktopStyle className={className} ispopup={isPopUp ? 'true' : ''} ref={refLottery}>
      <LotteryHeader round={lotteryRound} onExpire={lotteryRounds} />
      <LotteryContent
        myTicketsRef={myTicketsRef}
        totalTickets={numberOfTickets}
        onSubmitRefetch={handleSubmitRefetch}
        ticketPrice={ticketPrice}
      />
      {liveStatsEnabled ? (
        <LiveStatistics />
      ) : (
        <LotteryDesktopResult
          data={prepareDataLotteryTable(lotteryHistory?.lotteryRounds)}
          sliderData={prepareDataSlider(lotteryHistory?.lotteryRounds)}
        />
      )}
    </LotteryDesktopStyle>
  );
};

export default LotteryDesktop;
