/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import io from 'socket.io-client';

import config from 'config';

const { socketUrl } = config;

const createSocket = (token: string) => {
  return io(socketUrl, {
    auth: { token },
    transports: ['websocket'],
    autoConnect: false,
    reconnection: true,
    requestTimeout: 5000,
  });
};

export { createSocket };
