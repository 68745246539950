import { gql } from '@apollo/client';

export const GET_CASHBACK_COLLECTION = gql`
  query cashbackCollections {
    cashbackCollections {
      amount
      createdAt
      token {
        tokenCode
        displayName
      }
    }
  }
`;
