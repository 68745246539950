import { useLocalization } from 'components/Internationalization';
import { ButtonType } from 'components/Base/Button/types';

import Button from 'components/Base/Button';
import GamesSlider from 'components/common/GamesSlider';

import styles from './styles.module.scss';
import { IGamesBlock } from './types';

const GamesBlock: React.FC<IGamesBlock> = ({ games, title, icon, onView, onLike }) => {
  const { translate } = useLocalization();

  return (
    <div className={styles.sliderWrap}>
      <div className={styles.sliderHeader}>
        <div className={styles.sliderHeaderTitle}>
          <img src={icon} alt={title} />
          <p>{translate(title)}</p>
        </div>
        <div className={styles.sliderHeaderBtn}>
          <Button
            className={styles.button}
            variant={ButtonType.secondary}
            title={translate('games.slider.view-all')}
            width="100%"
            onclick={onView}
            color="transparent"
          />
        </div>
      </div>
      <div className={styles.sliderBody}>
        <GamesSlider block={title} games={games} onLike={onLike} />
      </div>
    </div>
  );
};

export default GamesBlock;
