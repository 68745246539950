import { gql } from '@apollo/client';

export const GET_REFERRAL_COLLECTION = gql`
  query referralCollection {
    referralCollections {
      amount
      createdAt
      token {
        tokenCode
        displayName
      }
    }
  }
`;
