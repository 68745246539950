/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { useAppSelector } from 'hooks/useAppSelector';
import { GET_DIVIDENDS_POOL } from 'graphQl/query/dividends/dividendsPool';
import { GET_STAR_TOKENS } from 'graphQl/query/dividends/starTokens';
import { getEstimateDropdownType, handlePeriodCalculate } from 'func/prepareDataDividends';
import { BNDivide, getImgSrc } from 'func/common';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';
import { EDividendsDropdown } from 'components/constants/dividends';

import HighCardTitle from 'components/Base/Cards/HighCardTitle';
import CurrencySliderRow from 'components/Base/CurrencySlider/components/CurrencySliderRow';
import DropDown from 'components/Base/DropDown';
import CurrenciesTemplate from 'components/Base/CurrensiesTemplate';
import InputCustom from 'components/Base/Input';
import Button from 'components/Base/Button';
import { ButtonType } from 'components/Base/Button/types';

import { IEstimateBackCard } from './types';
import styles from './styles.module.scss';

const EstimateBackCard: React.FC<IEstimateBackCard> = ({ isFlipped, setIsFlipped }) => {
  const token = useAppSelector(userToken);
  const { language, translate } = useLocalization();

  const [starAmount, setStarAmount] = useState('');
  const [dropdownInfo, setDropdownInfo] = useState([]);
  const [type, setType] = useState(null);
  const [estimateData, setEstimateData] = useState(null);
  const [balance, setBalance] = useState([]);
  const [usdData, setUsdData] = useState(null);
  const [totalStaked, setTotalStaked] = useState('0');

  const [dropTitles, setDropTitles] = useState([]);

  useQuery(GET_DIVIDENDS_POOL, {
    onCompleted: (data) => {
      setEstimateData(data?.dividendPool);
    },
  });

  const { data: dataStarTokens } = useQuery(GET_STAR_TOKENS, {
    skip: !token,
    fetchPolicy: 'cache-only',
  });

  const handleFlip = () => setIsFlipped(!isFlipped);

  const handleDaily = () => {
    const percent = Number(BNDivide(starAmount, Number(totalStaked))) * 100;
    handlePeriodCalculate(Number(percent), 1, estimateData, setBalance, setUsdData);
  };

  const handleMonthly = () => {
    const percent = Number(BNDivide(starAmount, Number(totalStaked))) * 100;
    handlePeriodCalculate(Number(percent), 30, estimateData, setBalance, setUsdData);
  };
  // setBalance(amount(((Number(btcPercent) / 50) * percent * 30) / 100));   // origin formula

  const handleQuarterly = () => {
    const percent = Number(BNDivide(starAmount, Number(totalStaked))) * 100;
    handlePeriodCalculate(Number(percent), 90, estimateData, setBalance, setUsdData);
  };

  const handleCalc = () => {
    switch (type) {
      case EDividendsDropdown.daily:
        return handleDaily();
      case EDividendsDropdown.monthly:
        return handleMonthly();
      case EDividendsDropdown.quarterly:
        return handleQuarterly();
      default:
        return handleDaily();
    }
  };

  const handleChangePercent = (e) => {
    let value = '';
    value = e.target.value;
    const valid = /^\-?\d+\.\d*$|^\-?[\d]*$/;
    const number = /\-\d+\.\d*|\-[\d]*|[\d]+\.[\d]*|[\d]+/;
    if (e.nativeEvent.inputType === 'deleteContentBackward') {
      setStarAmount(e.target.value);
      return;
    }
    if (value === '0') {
      setStarAmount('1');
      return;
    }
    if (!valid.test(value)) {
      const n = value.match(number);
      value = n ? n[0] : '';
    }
    setStarAmount(value);
  };

  useEffect(() => {
    if (dataStarTokens) {
      setTotalStaked(dataStarTokens.starToken.totalStaked);
    }
  }, [dataStarTokens]);

  useEffect(() => {
    if (dropTitles.length) {
      const dropDownData = dropTitles.map((elem, index) => ({
        title: elem,
        func: () => setType(getEstimateDropdownType(index)),
      }));
      setDropdownInfo(dropDownData);
    }
  }, [dropTitles]);

  useEffect(() => {
    if (type) {
      handleCalc();
    }
  }, [type]);

  useEffect(() => {
    const dailyText = translate('dividends.estimated.daily');
    const monthlyText = translate('dividends.estimated.monthly');
    const quarterlyText = translate('dividends.estimated.quarterly');

    setDropTitles([dailyText, monthlyText, quarterlyText]);
  }, [language]);

  return (
    <HighCardTitle
      subtitle=""
      title={translate('dividends.calculator.title.desktop')}
      titleMob={translate('dividends.calculator.title.mobile')}
      offBlur
    >
      <button className="fip-card-button" type="button" onClick={handleFlip}>
        {translate('dividends.estimated.flip.button.back')}
      </button>
      <div className={styles.estimateBack}>
        <div className={styles.estimateBackCalc}>
          <div className={styles.estimateBackCalcInp}>
            <InputCustom
              type="text"
              id="percent"
              name="percent"
              inputwidth="85%"
              placeholder={translate('dividends.calculator.input.placeholder')}
              value={starAmount}
              onChange={handleChangePercent}
              maxLength={10}
              icon={getImgSrc('STAR')}
            />
          </div>
          <div className={styles.estimateBackCalcBtn}>
            <Button
              className={styles.button}
              variant={ButtonType.secondary}
              title={translate('dividends.calculator.button.title')}
              width="160px"
              onclick={handleCalc}
              color="#2A3E66"
            />
          </div>
        </div>
        <div className={styles.estimateBackDropdown}>
          <div className={styles.estimateBackBody}>
            <div className={styles.estimateBackBodyDrop}>
              <DropDown data={dropdownInfo} width="90%" />
            </div>
            <div className={styles.estimateBackBodyUsd}>
              <CurrencySliderRow tokenCode="USD" displayName="USD" data={usdData} />
            </div>
          </div>
          {isFlipped && <CurrenciesTemplate currencies={balance} />}
        </div>
      </div>
    </HighCardTitle>
  );
};

export default EstimateBackCard;
