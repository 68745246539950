import { useContext } from 'react';

import { useLocalization } from 'components/Internationalization';
import { popUps } from 'components/constants/constants';
import { ContextPopUps } from 'context';

import { IEventsResultLink } from './types';
import EventsResultLinkStyle from './stylex';

const EventsResultLink: React.FC<IEventsResultLink> = ({ id, eventPrizeToken, totalWagerTokenCode, type }) => {
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { translate } = useLocalization();

  const handleResults = () => {
    setPopUpsOpen({
      modalOpen: popUps.tableResults,
      data: {
        id,
        eventPrizeToken,
        totalWagerTokenCode,
        type,
        fullScreen: true,
      },
    });
  };
  return <EventsResultLinkStyle onClick={handleResults}>{translate('events.history.result')}</EventsResultLinkStyle>;
};

export default EventsResultLink;
