/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import faqIcon from 'assets/img/PageTitles/FAQ_Icon.svg';
import accountIcon from 'assets/img/PageTitles/Account_Icon.svg';
import referralIcon from 'assets/img/PageTitles/Referral_Icon.svg';
import securityIcon from 'assets/img/PageTitles/Security_Icon.svg';
import cashbackIcon from 'assets/img/PageTitles/Cashback_Icon.svg';
import missionsIcon from 'assets/img/PageTitles/Missions_Icon.svg';
import eventsIcon from 'assets/img/PageTitles/Events_Icon.svg';
import dividendsIcon from 'assets/img/PageTitles/Dividends_Icon.svg';
import gamesIcon from 'assets/img/GamesFiler/All-active.webp';
import { Links } from 'components/constants';

export interface IPageIcon {
  icon: string;
  color: string;
}

const sectionIcons: Record<string, IPageIcon> = {
  account: { icon: accountIcon, color: '#23A0C2' },
  referral: { icon: referralIcon, color: '#d16317' },
  security: { icon: securityIcon, color: '#C22328' },
  cashback: { icon: cashbackIcon, color: '#1C69CA' },
  missions: { icon: missionsIcon, color: '#A227C5' },
  events: { icon: eventsIcon, color: '#FFBD00' },
  dividends: { icon: dividendsIcon, color: '#69951A' },
  keno: { icon: gamesIcon, color: '#A227C5' },
};

const defaultIcon: IPageIcon = { icon: faqIcon, color: '#C22328' };

export const getFAQSectionIcon = (name = '') => {
  const formattedName = name.toLowerCase();
  const icon = sectionIcons[formattedName];

  return icon || defaultIcon;
};

export const highlightText = (text: string, search: string, highlightClass: string): string => {
  const regex = new RegExp(search, 'gmi');
  const updatedText = text.replace(regex, (v) => `<span class="${highlightClass}">${v}</span>`);

  return `<p>${updatedText}</p>`;
};

export const getTopicTitle = (count?: number): string => {
  return count === 1 ? 'Topic' : 'Topics';
};

export const getFAQLink = (categoryName: string): string => {
  return Links.faqCategory.replace(':categoryName', categoryName.toLowerCase());
};
