import styled from 'styled-components';
import { TimerSize } from 'components/Games/Lottery/base/LotteryTimer/types';

interface Props {
  size: TimerSize;
}

const LotteryTimerStyle = styled.div.withConfig({
  shouldForwardProp: (prop) => !['size'].includes(prop),
})<Props>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 135px;

  img {
    display: inline-block;
    width: ${(props) => {
      switch (props.size) {
        case TimerSize.DESKTOP:
          return '35px';
        case TimerSize.MOBILE:
          return '35px';
        case TimerSize.HEADER:
          return '40px';
        default:
          return '30px';
      }
    }};
  }

  p {
    font-size: ${(props) => {
      switch (props.size) {
        case TimerSize.DESKTOP:
          return '20px';
        case TimerSize.MOBILE:
          return '20px';
        case TimerSize.HEADER:
          return '16px';
        default:
          return '14px';
      }
    }};
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    color: #ffffff;
    margin: 0;
  }

  @media (max-width: 1300px) {
    p {
      font-size: ${(props) => {
        switch (props.size) {
          case TimerSize.DESKTOP:
            return '20px';
          case TimerSize.MOBILE:
            return '20px';
          case TimerSize.HEADER:
            return '14px';
          default:
            return '14px';
        }
      }};
    }
    img {
      display: inline-block;
      width: ${(props) => {
        switch (props.size) {
          case TimerSize.DESKTOP:
            return '35px';
          case TimerSize.MOBILE:
            return '25px';
          case TimerSize.HEADER:
            return '25px';
          default:
            return '30px';
        }
      }};
    }
    width: ${(props) => props.size === TimerSize.HEADER && '100px'};
  }
  @media (max-width: 1200px) {
    p {
      font-size: ${(props) => {
        switch (props.size) {
          case TimerSize.DESKTOP:
            return '20px';
          case TimerSize.MOBILE:
            return '20px';
          case TimerSize.HEADER:
            return '11px';
          default:
            return '14px';
        }
      }};
    }
    img {
      display: inline-block;
      width: ${(props) => {
        switch (props.size) {
          case TimerSize.DESKTOP:
            return '35px';
          case TimerSize.MOBILE:
            return '25px';
          case TimerSize.HEADER:
            return '25px';
          default:
            return '30px';
        }
      }};
    }
    width: ${(props) => props.size === TimerSize.HEADER && '80px'};
  }
  @media (max-width: 1100px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
    width: ${(props) => props.size === TimerSize.MOBILE && '95px'};
    img {
      display: inline-block;
      width: ${(props) => {
        switch (props.size) {
          case TimerSize.DESKTOP:
            return '35px';
          case TimerSize.MOBILE:
            return '25px';
          default:
            return '30px';
        }
      }};
    }

    p {
      font-size: ${(props) => {
        switch (props.size) {
          case TimerSize.DESKTOP:
            return '20px';
          case TimerSize.MOBILE:
            return '14px';
          default:
            return '14px';
        }
      }};
    }
  }
  @media (max-width: 580px) {
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 400px) {
  }
`;

export default LotteryTimerStyle;
