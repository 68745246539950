import React, { useEffect, useState } from 'react';

import { useMines } from 'context/contextMines/contextMines';
import { Types } from 'context/contextMines/minesReduser';
import { getMinesDropData } from 'func/prepareDataMines';
import { MINES_CONDITION } from 'components/constants/constants';
import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';
import MinesDropdown from './MinesDropdown';

const MinesDropdownAuto: React.FC = () => {
  const { translate } = useLocalization();
  const [state, dispatch] = useMines();

  const { minesSelected, gameCondition } = state;

  const [dropdownMines, setDropdownMines] = useState([]);

  const handleSetMine = (mines) => dispatch({ type: Types.SELECT_MINES, payload: { selected: mines } });

  useEffect(() => {
    if (minesSelected) {
      setDropdownMines(getMinesDropData(handleSetMine, translate));
    }
  }, [minesSelected]);

  return (
    <div className={styles.minesRiskAutoWrap}>
      <MinesDropdown
        dropTitle={`${translate('mines.dropdown.text')} ${minesSelected}`}
        dropData={dropdownMines}
        disable={gameCondition === MINES_CONDITION.getStarted || gameCondition === MINES_CONDITION.autoStarted}
      />
    </div>
  );
};

export default MinesDropdownAuto;
