import clsx from 'clsx';
import { useEffect, useState } from 'react';

import coinStarshipIcon from 'assets/img/Vip/coin-starship.webp';
import starshipIcon from 'assets/img/Vip/starship.webp';
import coinCasinoIcon from 'assets/img/Vip/coin-casino.webp';
import casinoIcon from 'assets/img/Vip/casino.webp';
import vipIcon from 'assets/img/Vip/vip-icon.webp';

import { useLocalization } from 'components/Internationalization';
import { useAppSelector } from 'hooks/useAppSelector';
import { generalDataVipRank } from 'store/generalData/generalDtata.selectors';

import styles from './styles.module.scss';

const VipBanner: React.FC = () => {
  const { translate, language } = useLocalization();

  const [subtitle, setSubtitle] = useState('');

  const vipRank = useAppSelector(generalDataVipRank);

  useEffect(() => {
    const subtitleText = translate('vip.banner.subtitle');
    const newSubtitle = subtitleText.replace(':rank', String(vipRank));

    setSubtitle(newSubtitle);
  }, [language, vipRank]);

  return (
    <section className={styles.wrapper}>
      <img
        className={clsx(styles.defaultIcon, styles.coinStarship, 'animate__animated animate__fadeInLeft')}
        src={coinStarshipIcon}
        alt="Coin Starship"
      />
      <img
        className={clsx(styles.defaultIcon, styles.coinCasino, 'animate__animated animate__fadeInRight')}
        src={coinCasinoIcon}
        alt="Coin Casino"
      />
      <img
        className={clsx(styles.defaultIcon, styles.starship, 'animate__animated animate__fadeInRight')}
        src={starshipIcon}
        alt="Starship"
      />
      <img
        className={clsx(styles.defaultIcon, styles.casino, 'animate__animated animate__fadeInLeft')}
        src={casinoIcon}
        alt="Casino"
      />
      <div className={styles.vipWrapper}>
        <img className={clsx(styles.vip, 'animate__animated animate__fadeInDown')} src={vipIcon} alt="VIP Club" />
        <div className={styles.content}>
          <h1 className={styles.title}>{translate('vip.banner.title')}</h1>
          <p className={styles.subtitle}>{subtitle}</p>
          <p className={styles.description}>{translate('vip.banner.desc')}</p>
        </div>
      </div>
    </section>
  );
};

export default VipBanner;
