/* eslint-disable no-useless-escape */
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import monster from 'assets/img/common/monster.webp';

import { popUps } from 'components/constants/constants';
import { ContextPopUps, ContextTokenCode } from 'context';
import { amount, BNMinus, getImgSrc } from 'func/common';
import { bigThen, lessOrEqual } from 'func/prepareDataDice';
import { GET_USER } from 'graphQl/query/auth/profile';
import { GET_WALLET } from 'graphQl/query/wallet/wallet';
import { useAppSelector } from 'hooks/useAppSelector';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';
import { userToken } from 'store/user/user.selectors';
import { useNavigate } from 'hooks/useNavigate';
import { dataActiveBonusTokens, dataIsActiveUserBonus } from 'store/data/data.selectors';

import InputCustom from 'components/Base/Input/InputCustom';
import Button from 'components/Base/Button';
import DropDown from 'components/Base/DropDown';
import WarningBlock from '../base/WarningBlock';

import { ITipsRequest } from './types';
import styles from './styles.module.scss';

const TipsRequest: React.FC<ITipsRequest> = ({
  data: { alias, avatar },
  setActivePopUp,
  setDataPopUp,
  selectedToken,
  setSelectedNetwork,
  setSelectedToken,
}) => {
  const navigate = useNavigate();
  const { translate } = useLocalization();

  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { tokenWithNetworksList } = useContext(ContextTokenCode);
  const { tokenCode } = useContext(ContextTokenCode);

  const [userName, setUserName] = useState(alias || '');
  const [tipsAmount, setTipsAmount] = useState('');
  const [error, setError] = useState(null);
  const [dropDownInfo, setDropDownInfo] = useState(null);
  const [selectCurrency, setSelectCurrency] = useState(selectedToken);
  const [walletUserLocal, setWalletUserLocal] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [walletUser, setWalletUser] = useState('0');

  const token = useAppSelector(userToken);
  const isActiveUserBonus = useAppSelector(dataIsActiveUserBonus);
  const activeBonusTokens = useAppSelector(dataActiveBonusTokens);

  const { data: user } = useQuery(GET_USER, { fetchPolicy: 'cache-and-network' });

  const [walletRequest, { data: walletResponse }] = useLazyQuery(GET_WALLET, { fetchPolicy: 'no-cache' });

  useQuery(GET_WALLET, {
    skip: !token,
    fetchPolicy: 'cache-and-network',
    variables: { input: selectCurrency ? selectCurrency.token : tokenCode.token },
    onCompleted: (data) => {
      setWalletUserLocal(data.wallet.availableBalance);
    },
  });

  const handleMoreGames = () => {
    setPopUpsOpen(null);
    navigate('/games');
  };

  const handleChangeTipsAmount = (e) => {
    const currentWallet = tokenCode.token === selectedToken.token ? walletUser : walletUserLocal;
    let value = '';
    setDisableButton(false);
    setError(null);
    value = e.target.value;
    const valid = /^\-?\d+\.\d*$|^\-?[\d]*$/;
    const number = /\-\d+\.\d*|\-[\d]*|[\d]+\.[\d]*|[\d]+/;
    if (e.nativeEvent.inputType === 'deleteContentBackward') {
      setTipsAmount(e.target.value);
      return;
    }
    if (value === '00') {
      setTipsAmount('0.0');
      return;
    }
    if (value && Number(value) >= Number(currentWallet)) {
      setTipsAmount(String(amount(currentWallet, false)));
      return;
    }
    if (value.length > 9 && lessOrEqual(value, '0.00000001')) {
      setTipsAmount('0.00000001');
      return;
    }
    if (!valid.test(value)) {
      const n = value.match(number);
      value = n ? n[0] : '';
    }
    setTipsAmount(value);
  };

  const handleChangeUserName = (event) => {
    setDisableButton(false);
    setError(null);
    setUserName(event.target.value);
  };

  const handleBlur = () => {
    if (tipsAmount?.length < 9 && tipsAmount?.length) {
      setTipsAmount(String(amount(tipsAmount, false)));
    }
    if (!tipsAmount) {
      setTipsAmount('');
    }
  };

  const checkFields = () => {
    if (userName.length < 3) {
      const errorMessage = translate('popups.wallet.tips.errors.alias');

      setError(errorMessage);
      return true;
    }
    if (!tipsAmount || (tipsAmount && !bigThen(tipsAmount, 0))) {
      const errorMessage = translate('popups.wallet.tips.errors.amount');

      setError(errorMessage);
      return true;
    }
    return false;
  };

  const handleConfirm = () => {
    if (checkFields()) {
      return;
    }
    const currentWallet = tokenCode.token === selectedToken.token ? walletUser : walletUserLocal;
    const remaining = BNMinus(currentWallet, tipsAmount);
    setDataPopUp({
      selectCurrency,
      userName,
      tipsAmount,
      remaining,
      avatar,
    });
    setActivePopUp(popUps.tipsConfirm);
  };

  useEffect(() => {
    walletRequest({ variables: { input: selectedToken.token } });
  }, [selectedToken]);

  useEffect(() => {
    if (walletResponse) {
      setWalletUser(walletResponse.wallet.availableBalance);
    }
  }, [walletResponse]);

  useEffect(() => {
    if (tokenWithNetworksList) {
      const result = tokenWithNetworksList.tokenWithNetworks.filter((elem) => elem.tokenCode !== 'STAR');
      const prepareData = result.map((elem) => {
        const balanceObg = {
          title: elem.displayName,
          img: getImgSrc(elem.displayName),
          tokenCode: elem.tokenCode,
          func: () => {
            setSelectCurrency({
              token: elem.tokenCode,
              name: elem.displayName,
            });
            setSelectedToken({
              token: elem.tokenCode,
              name: elem.displayName,
            });
            setError(null);
            setTipsAmount('');
            setSelectedNetwork(null);
          },
        };
        return balanceObg;
      });

      const firstEl = prepareData.find((elem) => elem.tokenCode === selectedToken.token);
      const rest = prepareData.filter((elem) => elem.tokenCode !== selectedToken.token);

      setDropDownInfo([firstEl, ...rest]);
    }
  }, [tokenWithNetworksList]);

  if (!dropDownInfo) {
    return null;
  }

  return (
    <>
      {user?.profile?.rank?.id > 1 ? (
        <>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>{translate('popups.wallet.tips')}</div>
          </div>
          <div className={styles.row}>
            <p className={styles.label}>{translate('popups.wallet.tips.alias')}:</p>
            <InputCustom
              id="alias"
              name="alias"
              type="text"
              onChange={handleChangeUserName}
              value={userName}
              inputwidth="100%"
              maxLength={17}
            />
          </div>
          <div className={styles.row}>
            <p className={styles.label}>{translate('popups.wallet.tips.currency')}:</p>
            <DropDown data={dropDownInfo} width="100%" textPadding="50px" />
          </div>
          <div className={styles.row}>
            <p className={styles.label}>{translate('popups.wallet.tips.amount')}:</p>
            <InputCustom
              id="amount"
              name="amount"
              type="text"
              onChange={handleChangeTipsAmount}
              value={tipsAmount}
              inputwidth="100%"
              maxLength={10}
              onBlur={handleBlur}
            />
          </div>
          <div className={styles.maxAmount}>
            <p className={clsx(styles.label, styles.wm)}>{translate('popups.wallet.tips.balance')}: </p>
            <span className={styles.amount}>
              {` ${amount(tokenCode.token === selectedToken.token ? walletUser : walletUserLocal)}
             ${selectCurrency ? selectCurrency.name : selectedToken.name}`}
            </span>
          </div>
          <span className={styles.error}>{error}</span>
          {isActiveUserBonus && activeBonusTokens.includes(selectedToken.token) ? (
            <WarningBlock text="popups.wallet.warning.desc" />
          ) : null}
          <Button
            className={styles.button}
            variant={ButtonType.primary}
            title={translate('popups.wallet.tips.buttons.confirm')}
            width="288px"
            onclick={handleConfirm}
            disabled={disableButton}
          />
        </>
      ) : (
        <div className={styles.forbidden}>
          <p className={styles.forbiddenText}>{translate('popups.wallet.tips.unavailable.title')}</p>
          <img className={styles.image} src={monster} alt="monster" />
          <Button
            className={styles.button}
            variant={ButtonType.primary}
            title={translate('popups.wallet.tips.unavailable.action')}
            width="288px"
            onclick={handleMoreGames}
          />
        </div>
      )}
    </>
  );
};

export default TipsRequest;
