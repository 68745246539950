import Big from 'big.js';
import { pathOr } from 'ramda';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { animateScroll as scroll } from 'react-scroll';

import { ReactComponent as InfoIcon } from 'assets/img/common/info.svg';
import rakebackPig from 'assets/img/Rakeback/rakeback-box-big.webp';

import { Links } from 'components/constants';
import { ContextPopUps } from 'context';
import { IExchangeRate, IRakeback, IRank, ISetting } from 'types';
import { GET_USER } from 'graphQl/query/auth/profile';
import { GET_RANKS } from 'graphQl/query/cashback/ranks';
import { COLLECT_RAKEBACK, CONVERT_RAKEBACK, RAKEBACK } from 'graphQl/query/rakeback';
import { GET_COMMON_SETTINGS } from 'graphQl/query/settings/bonusSettings';
import { EXCHANGE_RATES } from 'graphQl/query/exchangeRate/exchangeRate';
import { useAppSelector } from 'hooks/useAppSelector';
import { ICurrencyItem } from 'components/Base/CurrensiesTemplate/types';
import { prepareRakebackCurrencies, prepareRakebackCurrenciesToCollect } from 'func/rakeback';
import { SettingName, popUps } from 'components/constants/constants';
import { ButtonType } from 'components/Base/Button/types';
import { IncomeArray } from 'components/Base/DropDown/types';
import { getDisplayName } from 'func/prepareDataHeader';
import useRakeback from 'hooks/useRakeback';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';

import CurrenciesTemplate from 'components/Base/CurrensiesTemplate/currenciesTemplate';
import DropDown from 'components/Base/DropDown';
import ButtonTooltip from 'components/Base/ButtonTooltip';
import Button from 'components/Base/Button';
import StyledLink from 'components/Base/Links/StyledLink';

import { amount } from 'func/common';
import styles from './styles.module.scss';
import { RakebackErrors } from './types';

const RakebackCollect: React.FC = () => {
  const { translate, language } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const { minutes, seconds, available, collectable, minWithdrawal, withdrawDelay } = useRakeback();

  const [percent, setPercent] = useState('0');
  const [minRank, setMinRank] = useState('1');
  const [lowRank, setLowRank] = useState(false);
  const [rakebacks, setRakebacks] = useState<IRakeback[]>([]);
  const [fee, setFee] = useState('0');
  const [currencies, setCurrencies] = useState<ICurrencyItem[]>([]);
  const [exchangeRates, setExchangeRates] = useState<IExchangeRate[]>([]);
  const [currenciesToCollect, setCurrenciesToCollect] = useState<IncomeArray[]>([]);
  const [tokenToCollect, setTokenToCollect] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonTitle, setButtonTitle] = useState('');

  const token = useAppSelector(userToken);

  const { data: userData } = useQuery(GET_USER, { skip: !token, fetchPolicy: 'cache-and-network' });
  const { data: rankData } = useQuery(GET_RANKS, { fetchPolicy: 'cache-and-network' });
  const { data: exchangeRatesData } = useQuery(EXCHANGE_RATES, { fetchPolicy: 'cache-and-network' });
  const { data: rakebackData } = useQuery(RAKEBACK, { skip: !token, fetchPolicy: 'cache-and-network' });
  const { data: settingsData } = useQuery(GET_COMMON_SETTINGS, {
    fetchPolicy: 'cache-and-network',
  });

  const [collectRakeback, { loading: collectLoading }] = useMutation(COLLECT_RAKEBACK, {
    refetchQueries: [RAKEBACK],
  });
  const [convertRakeback, { loading: convertLoading }] = useMutation(CONVERT_RAKEBACK, {
    refetchQueries: [RAKEBACK],
  });

  useEffect(() => {
    if (userData && rankData) {
      const userRank = pathOr(1, ['profile', 'rank', 'id'], userData);
      const ranks = pathOr<IRank[]>([], ['ranks'], rankData);
      const sortedRanks = [...ranks].sort((a, b) => a.id - b.id);

      const newMinRank = sortedRanks.find((r) => Number(r.rakeback) > 0);
      const rank = sortedRanks.find((r) => r.id === userRank);
      const rakebackPercent = rank?.rakeback || '0';

      if (newMinRank) {
        setMinRank(newMinRank.rakeback);
      }

      setPercent(rakebackPercent);
      setLowRank(Number(rakebackPercent) < 1);
    }
  }, [userData, rankData]);

  useEffect(() => {
    if (rakebackData) {
      const newRakebacksData = pathOr<IRakeback[]>([], ['rakeback'], rakebackData);
      const newRakebacks = [...newRakebacksData];

      newRakebacks.sort((a, b) => b.tokenCode.localeCompare(a.tokenCode));

      setRakebacks(newRakebacks);
    }
  }, [rakebackData]);

  useEffect(() => {
    if (settingsData) {
      const settings = pathOr<ISetting[]>([], ['getSettings'], settingsData);

      const feeSetting = settings.find((s) => s.name === SettingName.conversionFee);
      const newFee = feeSetting?.value || '0';

      setFee(newFee);
    }
  }, [settingsData]);

  useEffect(() => {
    if (exchangeRatesData) {
      const newExchangeRates = pathOr<IExchangeRate[]>([], ['exchangeRates'], exchangeRatesData);

      setExchangeRates(newExchangeRates);
    }
  }, [exchangeRatesData]);

  useEffect(() => {
    if (rakebacks.length) {
      const newCurrencies = prepareRakebackCurrencies(rakebacks);
      const newCurrenciesToCollect = prepareRakebackCurrenciesToCollect(
        rakebacks,
        exchangeRates,
        fee,
        setTokenToCollect,
        translate,
      );

      setCurrencies(newCurrencies);
      setCurrenciesToCollect(newCurrenciesToCollect);
    }
  }, [rakebacks, minWithdrawal, fee, exchangeRates]);

  useEffect(() => {
    if (available) {
      setButtonTitle(translate('popups.rekeback.collect.button.collect'));
    } else {
      const title = translate('popups.rakeback.collect.button.title');
      const newTitle = title.replace(':min', String(minutes)).replace(':sec', String(seconds));

      setButtonTitle(newTitle);
    }
  }, [minutes, seconds, available, language]);

  const handleInfo = () => {
    const rakebackInfo = {
      dealy: withdrawDelay,
      minWithdrawal,
      minRank,
    };

    setPopUpsOpen({ modalOpen: popUps.rakebackInfo, data: rakebackInfo });
  };

  const handleLinkClick = () => {
    setPopUpsOpen(null);
    scroll.scrollTo(0, { duration: 0, delay: 0 });
  };

  const handleCollect = async () => {
    setErrorMessage('');

    const query = tokenToCollect ? convertRakeback : collectRakeback;
    const variables = tokenToCollect ? { token: tokenToCollect } : undefined;

    await query({ variables })
      .then(() => {
        if (tokenToCollect) {
          const collectedCurrency = currenciesToCollect.find((c) => c.tokenCode === tokenToCollect);

          if (collectedCurrency) {
            const { title, tokenCode } = collectedCurrency;

            const singleCurrencyData: ICurrencyItem = {
              title: title?.replace(tokenCode, ''),
              src: tokenCode,
              displayName: getDisplayName(tokenCode),
            };

            setPopUpsOpen({ modalOpen: popUps.collect, data: [singleCurrencyData] });
            return;
          }
        }

        const collectedCurrencies = currencies.filter((c) => Big(c.title).gt(0));

        setPopUpsOpen({ modalOpen: popUps.collect, data: collectedCurrencies });
      })
      .catch((err) => {
        if (err.message === RakebackErrors.noTokens) {
          setErrorMessage(
            `The minimum withdrawal amount is ${amount(minWithdrawal)}. None of the tokens match the requirement.`,
          );
          return;
        }

        if (err.message === RakebackErrors.notAvailable) {
          setErrorMessage('You cannot withdraw rakeback yet.');
          return;
        }

        // eslint-disable-next-line no-console
        console.log('[RAKEBACK_COLLECT_ERROR]:', err);
        setErrorMessage(err.message || 'Something went wrong, please try again later.');
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.gradient} />
      <InfoIcon className={styles.info} onClick={handleInfo} />
      <div className={styles.content}>
        <div className={styles.titleWrapper}>
          <h3 className={styles.title}>{translate('popups.rakeback.collect.title')}</h3>
          <span className={styles.percent}>{percent}%</span>
        </div>
        <img className={styles.image} src={rakebackPig} alt="Rakeback" />
        {!lowRank ? (
          <>
            <div className={styles.currencies}>
              <CurrenciesTemplate currencies={currencies} size="small" />
            </div>
            <div className={styles.currencies}>
              <DropDown data={currenciesToCollect} position="top" width="100%" textPadding="50px" tokens />
            </div>
            {errorMessage ? <p className={styles.error}>{errorMessage}</p> : null}
            <ButtonTooltip
              className={styles.collect}
              text={available && !collectable ? translate('popups.rakeback.collect.tooltip') : ''}
            >
              <Button
                className={styles.button}
                variant={ButtonType.primary}
                title={buttonTitle}
                disabled={!available || !collectable || collectLoading || convertLoading}
                width="100%"
                onclick={handleCollect}
              />
            </ButtonTooltip>
          </>
        ) : (
          <>
            <p className={styles.desc}>
              {translate('popups.rakeback.collect.description')} {minRank}.
            </p>
            <StyledLink to={Links.games} onClick={handleLinkClick}>
              {translate('popups.rakeback.collect.play')}
            </StyledLink>
          </>
        )}
      </div>
    </div>
  );
};

export default RakebackCollect;
