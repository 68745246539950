import React from 'react';

import { getImgSrc } from 'func/common';
import dollar from 'assets/img/currencyIcons/dollarIcon.webp';
import euro from 'assets/img/currencyIcons/euroIcon.webp';
import { ETokenCode } from 'components/constants/constants';

import { IInitGameCurrency } from './types';

const InitGameCurrency: React.FC<IInitGameCurrency> = ({
  currencyList,
  tokenCode,
  gameCurrency,
  onEurSelection,
  onUsdSelection,
  onTokenSelection,
}) => (
  <div className="game-screen-init">
    {tokenCode.token !== ETokenCode.STAR ? (
      <div className="currency__block">
        {currencyList?.includes(tokenCode.token === ETokenCode.BTC ? 'uBTC' : tokenCode.token) && (
          <div
            className={gameCurrency === tokenCode.token ? 'currency__elem active' : 'currency__elem'}
            onClick={onTokenSelection}
          >
            <div>
              <img src={getImgSrc(tokenCode.name)} alt="icon" className="inter-currency-token" />
            </div>
            <h3>{tokenCode.name}</h3>
          </div>
        )}
        {currencyList?.includes('USD') && (
          <div className={gameCurrency === 'USD' ? 'currency__elem active' : 'currency__elem'} onClick={onUsdSelection}>
            <div>
              <img src={dollar} alt="icon" className="inter-currency" />
            </div>
            <p>USD</p>
          </div>
        )}
        {currencyList?.includes('EUR') && (
          <div className={gameCurrency === 'EUR' ? 'currency__elem active' : 'currency__elem'} onClick={onEurSelection}>
            <div>
              <img src={euro} alt="icon" className="inter-currency" />
            </div>
            <p>EUR</p>
          </div>
        )}
      </div>
    ) : (
      <div className="currency__block-star-wallet">STAR tokens cannot be used in this particular game</div>
    )}
  </div>
);

export default InitGameCurrency;
