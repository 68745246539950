import clsx from 'clsx';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import styles from './styles.module.scss';
import { defaultSliderConfig } from './constants';
import { ISlider } from './types';

const Slider: React.FC<ISlider> = ({ className = '', children, ...props }) => {
  return (
    <Carousel {...defaultSliderConfig} {...props} className={clsx(styles.wrapper, className)}>
      {children}
    </Carousel>
  );
};

export default Slider;
