import FAQCategory from '../FAQCategory';

import styles from './styles.module.scss';
import { IFAQCategories } from './types';

const FAQCategories: React.FC<IFAQCategories> = ({ sort, search, collections }) => {
  return (
    <section className={styles.wrapper}>
      {collections.map((c) => (
        <FAQCategory key={c.id} sort={sort} search={search} collection={c} />
      ))}
    </section>
  );
};

export default FAQCategories;
