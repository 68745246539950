import clsx from 'clsx';

import moonPayIcon from 'assets/img/PopUps/BuyCrypto/moon-pay-logo.webp';
import banxIcon from 'assets/img/PopUps/BuyCrypto/banx-logo.webp';

import styles from './styles.module.scss';
import { IBuyCryptoProvidersLayout } from './types';
import { ProviderCode } from '../../types';

const BuyCryptoProvidersLayout: React.FC<IBuyCryptoProvidersLayout> = ({ provider, onProvider }) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={clsx(styles.block, provider === ProviderCode.moonpay ? styles.active : '')}
        onClick={() => onProvider(ProviderCode.moonpay)}
      >
        <img src={moonPayIcon} alt="provider" />
      </div>
      <div
        className={clsx(styles.block, provider === ProviderCode.banxa ? styles.active : '')}
        onClick={() => onProvider(ProviderCode.banxa)}
      >
        <img src={banxIcon} alt="provider" />
      </div>
    </div>
  );
};

export default BuyCryptoProvidersLayout;
