import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import startIcon from 'assets/img/currencyIcons/starcoin.webp';
import { GET_STAR_TOKENS } from 'graphQl/query/dividends/starTokens';
import { useAppSelector } from 'hooks/useAppSelector';
import { GET_WALLET } from 'graphQl/query/wallet/wallet';
import { amount, BNDivide } from 'func/common';
import coins from 'assets/img/DividendsPage/coins.webp';
import { userToken } from 'store/user/user.selectors';

import HighCardTitle from 'components/Base/Cards/HighCardTitle';
import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';
import { IDividendsStarToken } from './types';

const StarTokens: React.FC<IDividendsStarToken> = ({ setPercent, percent }) => {
  const token = useAppSelector(userToken);
  const { translate } = useLocalization();

  const [balanceData, setBalanceData] = useState(null);
  const [starToken, setStarToken] = useState(null);

  const { data: dataStarTokens } = useQuery(GET_STAR_TOKENS, {
    skip: !token,
    fetchPolicy: 'cache-only',
  });
  useQuery(GET_WALLET, {
    skip: !token,
    variables: { input: 'STAR' },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setStarToken(data.wallet.availableBalance.toString().slice(0, 10));
    },
  });

  const getPercent = (percentFix) => percentFix.toFixed(4).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1');

  useEffect(() => {
    if (dataStarTokens) {
      setBalanceData(dataStarTokens.starToken);
    }
  }, [dataStarTokens]);

  useEffect(() => {
    if (starToken && balanceData) {
      if (+starToken >= 10) {
        setPercent(Number(BNDivide(starToken, Number(balanceData.totalStaked))) * 100);
      }
      if (+starToken < 10) {
        setPercent(0);
      }
    }
  }, [balanceData, starToken]);

  return (
    <HighCardTitle
      title={translate('dividends.myStar.title')}
      subtitle={translate('dividends.myStar.description')}
      height="auto"
      blurIfNotAuth
    >
      <div className={styles.dividendsStar}>
        <div className={styles.dividendsStarBody}>
          <div className={styles.dividendsStarBodyItem}>
            <div>
              <img src={startIcon} alt="icon" />
              <h6>{starToken ? String(amount(starToken)) + ' STARs' : '0.00000000 STARs'}</h6>
            </div>
            <div className={styles.dividendsStarBodyTotal}>
              {percent > 0.001 && (
                <>
                  <strong>{percent ? String(getPercent(percent)) + ' %' : '0 %'}</strong>
                  <h4>{translate('dividends.myStar.total')}</h4>
                </>
              )}
            </div>
          </div>
        </div>
        <img src={coins} alt="coins" className={styles.dividendsStarImage} />
      </div>
    </HighCardTitle>
  );
};

export default StarTokens;
