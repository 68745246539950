/* eslint-disable no-useless-escape */
import { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { pathOr } from 'ramda';
import * as yup from 'yup';

import warningIcon from 'assets/img/common/warning-icon.webp';
import addressBookIcon from 'assets/img/PopUps/AddressBook/address-book-nav.webp';

import { ContextTokenCode } from 'context';
import { popUps, SettingName } from 'components/constants/constants';
import { GET_WALLET } from 'graphQl/query/wallet/wallet';
import { GET_COMMON_SETTINGS } from 'graphQl/query/settings/bonusSettings';
import {
  filterNetworksAddressBook,
  filterNetworksDeposit,
  getMinimumWithdraw,
  getMinimumWithdrawFee,
} from 'func/prepareDataPopUps';
import { amount, BN, getCommonSettingsValue, getImgSrc, prepareAmount } from 'func/common';
import { bigThen, lessThen } from 'func/prepareDataDice';
import { GET_ADDRESS_BOOK } from 'graphQl/query/withdraw/withdraw';
import { ButtonType } from 'components/Base/Button/types';
import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile, userToken } from 'store/user/user.selectors';
import { dataActiveBonusTokens, dataIsActiveUserBonus } from 'store/data/data.selectors';
import { useLocalization } from 'components/Internationalization';
import { UserPreferenceType } from 'components/constants/account';
import { NETWORKS_WITH_MEMO } from 'components/constants/withdraw';
import { getMemoValidationSchema } from 'func/withdraw';

import DropDown from 'components/Base/DropDown';
import InputCustom from 'components/Base/Input/InputCustom';
import ButtonSubmit from '../ButtonSubmit';
import WithdrawAddressesDrop from './components/WitgdrawAdressesDrop';
import WarningBlock from '../base/WarningBlock';
import EmptyWithdrawPage from './components/EmptyWithdrawPage';

import { IWithdraw } from './types';
import styles from './styles.module.scss';

const WAValidator = window.WAValidator;

const Withdraw: React.FC<IWithdraw> = ({
  setSelectedNetwork,
  selectedToken,
  memo,
  setMemo,
  setWithdraw,
  setTextareaValue,
  setWithdrawalToken,
  setActivePopUp,
  setDataPopUp,
  selectedNetwork,
}) => {
  // const { walletUser } = useContext(ContextWallet);
  const { tokenCode, tokenWithNetworksList } = useContext(ContextTokenCode);
  const { translate } = useLocalization();

  const [tokenDetails, setTokenDetails] = useState(null);
  const [walletUserLocal, setWalletUserLocal] = useState(null);
  const [valueTextArea, setValueTextArea] = useState('');
  const [errorValidation, setErrorValidation] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [tokenData, setTokenData] = useState(null);
  const [dropDownInfo, setDropDownInfo] = useState(null);
  const [selectCurrency, setSelectCurrency] = useState(null);
  const [isForbiddenWithdraw, setIsForbiddenWithdraw] = useState(false);
  const [networkDropdown, setNetworkDropdown] = useState(null);
  const [addressBookData, setAddressBookData] = useState([]);
  const [memoValidationSchema, setMemoValidationSchema] = useState<yup.AnySchema>();
  const [withMemo, setWithMemo] = useState(false);
  const [walletUser, setWalletUser] = useState('0');
  const [disableWithdrawPage, setDisableWithdrawPage] = useState(false);

  const token = useAppSelector(userToken);
  const user = useAppSelector(userProfile);
  const isActiveUserBonus = useAppSelector(dataIsActiveUserBonus);
  const activeBonusTokens = useAppSelector(dataActiveBonusTokens);

  const { data: mainSettings } = useQuery(GET_COMMON_SETTINGS, { fetchPolicy: 'cache-only' });

  useQuery(GET_WALLET, {
    skip: !token,
    fetchPolicy: 'cache-and-network',
    variables: { input: selectCurrency ? selectCurrency.token : tokenCode.token },
    onCompleted: (data) => {
      setWalletUserLocal(data.wallet.availableBalance);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log('[GET_WALLET_ERROR]: ', error);
    },
  });

  const [walletRequest, { data: walletResponse }] = useLazyQuery(GET_WALLET, { fetchPolicy: 'no-cache' });

  const { data: walletsList } = useQuery(GET_ADDRESS_BOOK, { fetchPolicy: 'cache-and-network' });

  const handleAddressBook = () => setActivePopUp(popUps.addressBook);

  function clearErrorField() {
    setErrorValidation(null);
  }

  function getMaximumNumber() {
    const currentWallet = tokenCode.token === selectedToken.token ? walletUser : walletUserLocal;
    const maxAmount: any = BN(currentWallet - +getMinimumWithdrawFee(tokenDetails?.networks, selectedNetwork), 15);

    if (maxAmount.lt(0)) {
      return currentWallet;
    }
    return maxAmount;
  }

  const handleMax = () => {
    const maxAmount: any = getMaximumNumber();
    let result: unknown = '';

    if (
      +maxAmount.valueOf() >
      +Number(
        +getMinimumWithdraw(tokenDetails?.networks, selectedNetwork) +
          +getMinimumWithdrawFee(tokenDetails?.networks, selectedNetwork),
      )
    ) {
      clearErrorField();
      setInputValue(String(amount(maxAmount.valueOf())));
      return;
    }

    if (maxAmount.valueOf().length < 9) {
      result = amount(maxAmount.valueOf());
    } else {
      result = /\d+(?:\.\d\d\d\d\d\d\d\d)?/.exec(maxAmount);
    }

    clearErrorField();
    setInputValue(String(amount(String(result))));
  };

  const handleChange = (event) => setValueTextArea(event.currentTarget.value);

  const handleChangeInput = (e) => {
    const currentWallet = tokenCode.token === selectedToken.token ? walletUser : walletUserLocal;
    let value = '';
    value = e.target.value;
    const valid = /^\-?\d+\.\d*$|^\-?[\d]*$/;
    const number = /\-\d+\.\d*|\-[\d]*|[\d]+\.[\d]*|[\d]+/;
    if (e.nativeEvent.inputType === 'deleteContentBackward') {
      clearErrorField();
      setInputValue(e.target.value);
      return;
    }
    if (value === '00') {
      clearErrorField();
      setInputValue('0');
      return;
    }
    if (!valid.test(value)) {
      const n = value.match(number);
      value = n ? n[0] : '';
    }
    if (+value > currentWallet - +getMinimumWithdrawFee(tokenDetails?.networks, selectedNetwork)) {
      const maxVal = getMaximumNumber();

      clearErrorField();
      setInputValue(String(amount(maxVal.valueOf(), false)));
      return;
    }

    clearErrorField();
    setInputValue(value);
  };

  const handleChangeMemo = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newMemo = ev.target.value;

    try {
      const isValid = !newMemo || !memoValidationSchema || memoValidationSchema.validateSync(newMemo);

      if (isValid) {
        setMemo(newMemo);
      }
    } catch {
      // pass
    }
  };

  const handleSavedMemo = (memo?: string) => {
    setMemo(memo || '');
  };

  const handleSubmit = () => {
    const currentWallet = tokenCode.token === selectedToken.token ? walletUser : walletUserLocal;
    const network = tokenWithNetworksList.networks.find((el) => el.code === selectedNetwork)?.name;
    const minValWithFee = getMinimumWithdraw(tokenDetails?.networks, selectedNetwork);

    if (!WAValidator.validate(valueTextArea, network, 'both')) {
      const networkCode = selectedNetwork || '';
      const errorMessage = translate('popups.wallet.withdraw.errors.address.selected').replace(
        ':networkCode',
        networkCode,
      );

      setErrorValidation(errorMessage);
      return;
    }
    if (bigThen(minValWithFee, currentWallet) || lessThen(inputValue || '0', minValWithFee)) {
      const minWithdrawalAmount = String(amount(getMinimumWithdraw(tokenDetails?.networks, selectedNetwork)));
      const currencyCode = String(selectedToken?.name || tokenCode.name);
      const errorMessage = translate('popups.wallet.withdraw.errors.min-withdrawal')
        .replace(':minWithdrawAmount', minWithdrawalAmount)
        .replace(':currencyCode', currencyCode);

      setErrorValidation(errorMessage);
      return;
    }
    if (!selectedNetwork) {
      const errorMessage = translate('popups.wallet.withdraw.errors.network');

      setErrorValidation(errorMessage);
      return;
    }
    if (!errorValidation) {
      setWithdraw(prepareAmount(inputValue));
      setTextareaValue(valueTextArea);
      setDataPopUp({ selectedNetwork });
      setActivePopUp(popUps.withdrawSuccess);
    }
  };

  const handleBlur = () => {
    if (inputValue) {
      setInputValue(String(amount(inputValue)));
    }
  };

  const handleFocus = () => {
    if (inputValue) {
      setInputValue(String(amount(prepareAmount(inputValue), false)));
    } else {
      setInputValue('');
    }
  };

  useEffect(() => {
    if (mainSettings) {
      const disableWithdraw = getCommonSettingsValue(mainSettings.getSettings, SettingName.withdrawal);

      const disableWithdawValue = disableWithdraw ? !JSON.parse(disableWithdraw) : false;

      setDisableWithdrawPage(disableWithdawValue);
    }
  }, [mainSettings]);

  useEffect(() => {
    walletRequest({ variables: { input: selectedToken.token } });
  }, [selectedToken]);

  useEffect(() => {
    if (walletResponse) {
      setWalletUser(walletResponse.wallet.availableBalance);
    }
  }, [walletResponse]);

  useEffect(() => {
    if (tokenWithNetworksList) {
      const result = tokenWithNetworksList.tokenWithNetworks.filter((elem) => elem.networks.length >= 1);
      setTokenData(result);
    }
  }, [tokenWithNetworksList]);

  useEffect(() => {
    const newWithMemo = NETWORKS_WITH_MEMO.includes(selectedNetwork);

    if (newWithMemo) {
      const newMemoValidationSchema = getMemoValidationSchema(selectedNetwork);

      setMemoValidationSchema(newMemoValidationSchema || undefined);
    } else {
      setMemoValidationSchema(undefined);
    }

    setWithMemo(newWithMemo);
  }, [selectedNetwork]);

  useEffect(() => {
    if (valueTextArea.length > 10) {
      const network = tokenWithNetworksList.networks.find((el) => el.code === selectedNetwork)?.name;
      const validateAddress = WAValidator.validate(valueTextArea, network, 'both');

      if (validateAddress) {
        clearErrorField();
      } else {
        const errorMessage = translate('popups.wallet.withdraw.errors.address.not-selected');

        setErrorValidation(errorMessage);
        return;
      }
    }
    if (
      +inputValue <
      +Number(
        +getMinimumWithdraw(tokenDetails?.networks, selectedNetwork) +
          +getMinimumWithdrawFee(tokenDetails?.networks, selectedNetwork),
      ).toFixed(8)
    ) {
      clearErrorField();
    }
  }, [inputValue, valueTextArea, selectedNetwork]);

  useEffect(() => {
    if (tokenData) {
      const prepareData = tokenData.map((elem) => {
        const balanceObg = {
          title: elem.displayName,
          img: getImgSrc(elem.displayName),
          tokenCode: elem.tokenCode,
          networks: elem.networks,
          func: () => {
            filterNetworksDeposit(
              elem.networks,
              tokenWithNetworksList.networks,
              setNetworkDropdown,
              setSelectedNetwork,
            );
            setSelectCurrency({ token: elem.tokenCode, name: elem.displayName, networks: elem.networks });
            setWithdrawalToken({ token: elem.tokenCode, name: elem.displayName });
            setInputValue('');
            setValueTextArea('');
            setErrorValidation(null);
          },
        };
        return balanceObg;
      });

      const firstEl = prepareData.find((elem) => elem.tokenCode === selectedToken.token);
      const rest = prepareData.filter((elem) => elem.tokenCode !== selectedToken.token);
      setSelectCurrency({ token: firstEl.tokenCode, name: firstEl.displayName, networks: firstEl.networks });
      setDropDownInfo([firstEl, ...rest]);
    }
  }, [tokenData]);

  useEffect(() => {
    if (selectCurrency) {
      filterNetworksDeposit(
        selectCurrency.networks,
        tokenWithNetworksList.networks,
        setNetworkDropdown,
        setSelectedNetwork,
        setErrorValidation,
      );
      const dataRes = tokenWithNetworksList.tokenWithNetworks.find((el) => el.tokenCode === selectCurrency.token);
      setTokenDetails(dataRes);
      setErrorValidation('');
      if (selectedNetwork) {
        const res = filterNetworksAddressBook(
          selectCurrency.networks,
          tokenWithNetworksList.networks,
          selectedNetwork,
          setSelectedNetwork,
        );
        setNetworkDropdown(res);
        setSelectedNetwork(selectedNetwork);
      }
    }
  }, [selectCurrency]);

  useEffect(() => {
    if (selectedNetwork && walletsList) {
      setInputValue('');
      setValueTextArea('');
      const data = walletsList.addressBook.filter((el) => el.network.code === selectedNetwork);
      const netTokenFilter = data?.filter((el) => el.token.tokenCode === selectCurrency?.token);
      setAddressBookData(netTokenFilter);
    }
    if (!selectedNetwork) {
      setAddressBookData([]);
    }
  }, [selectedNetwork, walletsList, selectCurrency]);

  useEffect(() => {
    if (user) {
      const withdrawalPreference = pathOr<boolean>(false, ['preferences', UserPreferenceType.withdrawal], user);
      setIsForbiddenWithdraw(withdrawalPreference);
    }
  }, [user]);

  if (!dropDownInfo) {
    return null;
  }

  if (disableWithdrawPage) {
    return <EmptyWithdrawPage />;
  }

  return (
    <>
      <div className={styles.withdrawalTitleWrap}>
        <div className={styles.withdrawalTitle}>{translate('popups.wallet.withdraw')}</div>
      </div>
      {!isForbiddenWithdraw ? (
        <div className={styles.withdrawalFormWrap}>
          <div className={styles.withdrawalForm}>
            <p>{translate('popups.wallet.withdraw.currency')}:</p>
            <div className={styles.withdrawalFormCopyDrop}>
              <DropDown data={dropDownInfo} width="100%" textPadding="50px" hideFirstElem="36%" />
            </div>
            {selectCurrency && (
              <>
                <p>{translate('popups.wallet.withdraw.network')}:</p>
                <div className={styles.withdrawalFormCopyDrop}>
                  {networkDropdown && <DropDown data={networkDropdown} width="100%" textPadding="20px" />}
                </div>
              </>
            )}

            {selectedNetwork && <h3>{translate('popups.wallet.withdraw.amount')}:</h3>}
            {selectedNetwork && (
              <div className={styles.withdrawalFormCopy}>
                <InputCustom
                  id="withdraw"
                  name="withdraw"
                  placeholder=""
                  type="text"
                  onChange={handleChangeInput}
                  value={inputValue}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  inputwidth="100%"
                />
                <button
                  type="button"
                  className={styles.withdrawalFormBtn}
                  onClick={handleMax}
                  disabled={!selectedNetwork}
                >
                  {translate('popups.wallet.withdraw.buttons.max')}
                </button>
              </div>
            )}
            {selectedNetwork && (
              <div className={styles.withdrawalAmountsWrap}>
                <div className={styles.withdrawalAmounts}>
                  <h5>
                    {translate('popups.wallet.withdraw.balance')}:
                    <span className={styles.withdrawalNumbers}>
                      {' ' +
                        String(amount(tokenCode.token === selectedToken.token ? walletUser : walletUserLocal)) +
                        ' '}
                    </span>
                    {selectCurrency ? selectCurrency.name : tokenCode}
                  </h5>
                  <h5>
                    {translate('popups.wallet.withdraw.min-withdrawal')}:
                    <span className={styles.withdrawalNumbers}>
                      {getMinimumWithdraw(tokenDetails?.networks, selectedNetwork)
                        ? amount(getMinimumWithdraw(tokenDetails?.networks, selectedNetwork))
                        : ''}
                    </span>
                    {selectCurrency ? selectCurrency.name : tokenCode}
                  </h5>
                  <h5>
                    {translate('popups.wallet.withdraw.fee')}:
                    <span className={styles.withdrawalNumbers}>
                      {getMinimumWithdrawFee(tokenDetails?.networks, selectedNetwork)
                        ? amount(getMinimumWithdrawFee(tokenDetails?.networks, selectedNetwork))
                        : ''}
                    </span>
                    {selectCurrency ? selectCurrency.name : tokenCode}
                  </h5>
                </div>
                <div onClick={handleAddressBook} className={styles.withdrawalAddressBook}>
                  <img src={addressBookIcon} alt="icon" />
                </div>
              </div>
            )}
          </div>
          {selectedNetwork && (
            <div className={styles.withdrawalFormFooter}>
              <p>{translate('popups.wallet.withdraw.address')}:</p>
              <div className={styles.withdrawalFormFooterAddress}>
                {addressBookData.length < 1 ? (
                  <textarea
                    value={valueTextArea}
                    onChange={handleChange}
                    className={styles.withdrawalFormTextarea}
                    rows={1}
                    spellCheck="false"
                    wrap="off"
                  />
                ) : (
                  <WithdrawAddressesDrop
                    data={addressBookData}
                    onChangeInput={setValueTextArea}
                    onChangeMemo={handleSavedMemo}
                    valueInput={valueTextArea}
                  />
                )}
                {errorValidation ? <div className={styles.withdrawalFormErrorMessage}>{errorValidation}</div> : null}
              </div>
              {withMemo ? (
                <>
                  <p>{translate('popups.wallet.withdraw.memo')}:</p>
                  <div className={styles.withdrawalFormFooterAddress}>
                    <InputCustom
                      id="memo"
                      name="memo"
                      placeholder="MEMO phrase"
                      type="text"
                      value={memo}
                      onChange={handleChangeMemo}
                      inputwidth="100%"
                    />
                  </div>
                  <div className={styles.memoWarning}>
                    <div className={styles.memoTitleWrapper}>
                      <img className={styles.memoIcon} src={warningIcon} alt="icon" />
                      <h5 className={styles.memoTitle}>{translate('popups.wallet.withdraw.memo.warning.title')}</h5>
                    </div>
                    <p className={styles.memoDesc}>{translate('popups.wallet.withdraw.memo.warning.desc')}</p>
                  </div>
                </>
              ) : null}
              {isActiveUserBonus && activeBonusTokens.includes(selectedToken.token) ? (
                <WarningBlock text="popups.wallet.warning.desc" />
              ) : null}
              <ButtonSubmit
                className={styles.button}
                handleButton={handleSubmit}
                variant={ButtonType.primary}
                width="288px"
                title={translate('general.buttons.continue')}
                dependencies={[valueTextArea]}
              />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.withdrawalTitleForbidden}>{translate('popups.wallet.withdraw.disabled')}</div>
      )}
    </>
  );
};

export default Withdraw;
