import styled from 'styled-components';

interface Props {
  $height?: string;
  $overflow?: string;
  $marginTop?: string;
  $content?: string;
  $marginBottom?: string;
  $tablePopUp?: boolean;
  $offBlur?: boolean;
  $zIndex?: string;
}

const CardWrapperStyle = styled.div<Props>`
  height: ${({ $height }) => $height || '100%'};
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: ${(props) => (props.content ? props.content : 'flex-start')};
  background: ${(props) => (!props.$tablePopUp ? '#202D4A' : '')};
  mix-blend-mode: normal;
  box-shadow: ${(props) => (!props.$tablePopUp ? '0px 20px 40px 10px rgba(0, 0, 0, 0.25)' : '')};
  backdrop-filter: ${(props) => !props.$offBlur && 'blur(10px)'};
  border-radius: 20px;
  font-size: 10px;
  overflow: ${(props) => (props.$overflow ? props.$overflow : 'visible')};
  margin-top: ${(props) => props.$marginTop && props.$marginTop};
  z-index: ${(props) => props.$zIndex && props.$zIndex};

  @media (max-width: 1200px) {
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 580px) {
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 400px) {
  }
`;

export default CardWrapperStyle;
