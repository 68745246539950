import referralIcon from 'assets/img/PageTitles/Referral_Icon.svg';
import PageTitle from 'components/Base/PageTitle';
import { useLocalization } from 'components/Internationalization';

const Title: React.FC = () => {
  const { translate } = useLocalization();
  return (
    <>
      <PageTitle
        icon={referralIcon}
        title={translate('referral.title')}
        lineColor="#d16317"
        titleSize="small"
        largeImage
      />
    </>
  );
};

export default Title;
