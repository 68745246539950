import { FastModeGames, HotKeys, initialAutoState, initialGameSettings } from 'components/constants/games';
import { MINES_CONDITION } from 'components/constants/constants';
import { minesSliderDataInit } from 'components/Games/Mines/components/MinesSlider/sliderDataInit';
import { getDefaultSquaresData } from 'func/prepareDataMines';

import { IInitialState } from './types';

export const initialState: IInitialState = {
  fastMode:
    JSON.parse(localStorage.getItem(FastModeGames.mines)) !== null
      ? JSON.parse(localStorage.getItem(FastModeGames.mines))
      : true,
  isHotkeys: JSON.parse(localStorage.getItem(HotKeys.mines)) || false,
  isAuto: false,
  gameId: '',
  betAmountValue: '0.00000000',
  minesSelected: 4,
  currentSliderNumber: 0,
  gameCondition: MINES_CONDITION.notStarted,
  gameSettings: initialGameSettings,
  historyList: [],
  autoState: initialAutoState,
  sliderData: minesSliderDataInit,
  errorsCount: 0,
  squaresData: getDefaultSquaresData(),
  autoPickItems: [],
  requestId: '',
  winInfo: null,
  hash: '',
};
