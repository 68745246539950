import { useState } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';

import GameOptionsButtonsItemNew from './GameOptionsButtonsItemNew';
import LiveStatisticsButton from './components/LiveStatisticsButton';

import { IGameOptionsButtonsNew } from './types';
import styles from './styles.module.scss';

const GameOptionsButtonsNew: React.FC<IGameOptionsButtonsNew> = ({ dataButtons }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 1260px)' });

  const [open, setOpen] = useState(false);

  const handleMenu = () => setOpen(!open);

  const handleBlur = (event) => {
    if (event?.relatedTarget) return;
    setOpen(false);
  };

  return (
    <div className={styles.wrapper}>
      {isMobile ? <LiveStatisticsButton /> : null}
      <div className={styles.gamesOptionsBtnWrap}>
        <div className={styles.gamesOptionsBtnDkt}>
          {dataButtons.map((elem, index) => (
            <GameOptionsButtonsItemNew
              key={elem.toolTipText}
              index={index}
              toolTipText={elem.toolTipText}
              activeImg={elem.activeImg}
              func={elem.func}
              id={elem.id}
            />
          ))}
        </div>
        <div className={styles.gamesOptionsBtnMbl}>
          <button className={styles.gamesOptionsBtnNavWrap} type="button" onBlur={handleBlur}>
            <div className={styles.gamesOptionsBtnNav} onClick={handleMenu}>
              <div />
              <div />
              <div />
              {open && <i />}
            </div>
            <div className={clsx(styles.gamesOptionsBtnNavBody, open && styles.gamesOptionsBtnNavBodyOpen)}>
              {dataButtons.map((elem, index) => (
                <GameOptionsButtonsItemNew
                  key={elem.toolTipText}
                  index={index}
                  toolTipText={elem.toolTipText}
                  activeImg={elem.activeImg}
                  func={elem.func}
                  id={elem.id}
                />
              ))}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default GameOptionsButtonsNew;
