import PageStile from './styles';
import styles from './styles.module.scss';
import { IPageTitle } from './types';

const PageTitle: React.FC<IPageTitle> = ({
  titleSize,
  largeImage,
  icon,
  title,
  lineColor,
  heightMob,
  long,
  subtitle,
  bigImage,
  middleImage,
  children,
  component: Tag = 'h2',
}) => (
  <div className={styles.wrapper}>
    <PageStile
      linecolor={lineColor}
      heightmob={heightMob}
      long={long}
      bigimage={bigImage}
      titlesize={titleSize}
      largeimage={largeImage ? 'true' : ''}
      middleimage={middleImage}
      title={title}
    >
      <img src={icon} alt="icon" className="title__icon" />
      <div className="title__body">
        <div className="title__body-line" />
        <Tag className="title__body-title" dangerouslySetInnerHTML={{ __html: title }} />
        {subtitle && <h6 className="title__description">{subtitle}</h6>}
      </div>
    </PageStile>
    {children}
  </div>
);

export default PageTitle;
