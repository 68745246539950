import React from 'react';

import DropdownWithTitle from 'components/Base/DropDown/components/DropdownWithTitle';

import styles from './styles.module.scss';
import { IMinesDropdown } from './types';

const MinesDropdown: React.FC<IMinesDropdown> = ({ disable, dropTitle, dropData }) => {
  return (
    <div className={styles.minesRiskDropWrap}>
      <DropdownWithTitle title={dropTitle} options={dropData} disable={disable} />
    </div>
  );
};

export default MinesDropdown;
