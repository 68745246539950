import { IDataLayerItem, LayerDispatchType } from 'types/dataLayerTypes';
import config from '../config';

export const useAnalyticsDispatch = (): LayerDispatchType => {
  window.dataLayer = window.dataLayer || [];

  const dataLayerDispatch = (item: IDataLayerItem) => {
    if (config.env !== 'prod') return;
    window.dataLayer.push(item);
  };

  return dataLayerDispatch;
};
