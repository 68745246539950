import { gql } from '@apollo/client';

export const LOGIN = gql`
  query login($email: String!, $password: String!, $captcha: String!) {
    login(email: $email, password: $password, captcha: $captcha) {
      ... on TokenPayload {
        authToken
        refreshToken
      }
      ... on VerifyPayload {
        shortLivedToken
        mfaType
      }
    }
  }
`;

export const VERIFY_LOGIN = gql`
  query ($shortLivedToken: String!, $securityCode: String) {
    loginVerify(shortLivedToken: $shortLivedToken, securityCode: $securityCode, emailOtp: $securityCode) {
      authToken
      refreshToken
    }
  }
`;

export const REFRESH_TOKEN = gql`
  query refreshTokens($refreshToken: String!) {
    refreshTokens(refreshToken: $refreshToken) {
      authToken
      refreshToken
    }
  }
`;
export const USER_SECURITY = gql`
  query {
    userSecurity {
      google_2fa
      email_2fa
      requireLogin_2fa
    }
  }
`;
export const CREATE_2FA_AUTH_QQCODE = gql`
  query {
    getGoogleMFASecret {
      recoveryCode
    }
  }
`;

export const RESEND_EMAIL_TWO_FA = gql`
  query sendOtpToEmail {
    sendOtpToEmail {
      status
    }
  }
`;

export const SEND_EMAIL_VERIFICATION_OTP = gql`
  query sendEmailVerificationOtp($email: String!) {
    sendEmailVerificationOtp(email: $email) {
      status
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  query changePassword($currentPassword: String!, $newPassword: String!, $securityCode: String, $emailOtp: String) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
      securityCode: $securityCode
      emailOtp: $emailOtp
    ) {
      status
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation ($email: String!, $password: String!, $code: String!) {
    verifyEmail(email: $email, password: $password, code: $code) {
      authToken
      refreshToken
    }
  }
`;

export const CHANGE_EMAIL = gql`
  mutation ($data: ChangeEmailDto!) {
    changeEmail(data: $data)
  }
`;
