import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';

import { useLocalization } from 'components/Internationalization';
import { TEXAS_HOLDEM_COMBINATIONS } from 'components/constants/texasHoldem';
import { cardIcon } from 'func/cards';

import styles from './styles.module.scss';
import { ITexasHoldemCombination } from './types';
import { formattedCard } from 'func/texasHoldem';

const TexasHoldemCombination: React.FC<ITexasHoldemCombination> = ({ title, hand }) => {
  const { translate } = useLocalization();

  const [combination, setCombination] = useState('');
  const [cards, setCards] = useState([]);
  const [kicker, setKicker] = useState('');

  useEffect(() => {
    if (hand.combination) {
      const newCombination = TEXAS_HOLDEM_COMBINATIONS[hand.combination];

      setCombination(newCombination);
      setCards(hand.combinationCards);
      setKicker(hand.kickerCard);

      return;
    }

    setCombination('');
    setCards([]);
  }, [hand]);

  return (
    <div className={styles.wrapper}>
      <p className={styles.row}>
        <span className={styles.text}>{title}:</span>
        <span className={styles.combination}>{translate(combination)}</span>
      </p>
      <div className={clsx(styles.row, styles.cards)}>
        {cards.map((c, i) => (
          <StyledCard key={c} index={i}>
            <img className={styles.card} src={cardIcon(c)} alt={c} />
          </StyledCard>
        ))}
      </div>
      {kicker ? (
        <p className={styles.row}>
          <span className={styles.text}>Kicker:</span>
          <span className={styles.kicker}>{formattedCard(kicker)}</span>
        </p>
      ) : null}
    </div>
  );
};

export default TexasHoldemCombination;

const StyledCard = styled.div.withConfig({
  shouldForwardProp: (prop) => !['index'].includes(prop),
})<{ index: number }>`
  position: absolute;
  z-index: ${({ index }) => index};
  left: ${({ index }) => index * 24}px;
`;
