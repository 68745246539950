import React from 'react';

import styles from './styles.module.scss';
import LimboSpace from './LimboSpace';
import LimboImages from './LimboImages';

const LimboScreen: React.FC = () => {
  return (
    <div className={styles.limboScreenWrap}>
      <LimboSpace />
      <LimboImages />
    </div>
  );
};

export default LimboScreen;
