import { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import monster from 'assets/img/common/monster.webp';

import { popUps } from 'components/constants/constants';
import { EVault } from 'components/constants/popUps';
import { GET_ALL_WALLETS, GET_WALLET } from 'graphQl/query/wallet/wallet';
import { GET_USER } from 'graphQl/query/auth/profile';
import { GET_VAULTS_LIST } from 'graphQl/query/vault/vault';
import { ContextPopUps } from 'context';
import { getTokenCodeExcludeStar, getVaultWalletsList, handleChangeInputMax } from 'func/prepareDataPopUps';
import { amount, BNMinus } from 'func/common';
import { bigThen } from 'func/prepareDataDice';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';
import { useNavigate } from 'hooks/useNavigate';
import { useAppSelector } from 'hooks/useAppSelector';
import { dataActiveBonusTokens, dataIsActiveUserBonus } from 'store/data/data.selectors';

import Button from 'components/Base/Button';
import VaultContentItem from './components/VaultContentItem';
import WarningBlock from '../base/WarningBlock';

import styles from './styles.module.scss';
import { IVault } from './types';

const Vault: React.FC<IVault> = ({
  setDataPopUp,
  setActivePopUp,
  data,
  selectedToken,
  setSelectedToken,
  setSelectedNetwork,
}) => {
  const navigate = useNavigate();
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { translate } = useLocalization();

  const [vaultType, setVaultType] = useState(data?.vaultType ? data.vaultType : EVault.add);
  const [errorMessage, setErrorMessage] = useState('');
  const [balanceDropdownWithdraw, setBalanceDropdownWithdraw] = useState([]);
  const [inputValueWithdraw, setInputValueWithdraw] = useState('');
  const [currentMaxAmountWithdraw, setCurrentMaxAmountWithdraw] = useState('0');
  const [selectedTokenWithdraw, setSelectedTokenWithdraw] = useState(getTokenCodeExcludeStar(selectedToken));
  const [balanceDropdownAdd, setBalanceDropdownAdd] = useState([]);
  const [inputValueAdd, setInputValueAdd] = useState('');
  const [currentMaxAmountAdd, seCurrentMaxAmountAdd] = useState('0');
  const [selectedTokenAdd, setSelectedTokenAdd] = useState(getTokenCodeExcludeStar(selectedToken));

  const isActiveUserBonus = useAppSelector(dataIsActiveUserBonus);
  const activeBonusTokens = useAppSelector(dataActiveBonusTokens);

  const { data: walletsList } = useQuery(GET_ALL_WALLETS, { fetchPolicy: 'cache-and-network' });
  const { data: vaultsList } = useQuery(GET_VAULTS_LIST, { fetchPolicy: 'cache-and-network' });
  const { data: user } = useQuery(GET_USER, { fetchPolicy: 'cache-and-network' });
  const [walletRequest, { data: walletResponse }] = useLazyQuery(GET_WALLET, { fetchPolicy: 'no-cache' });

  const handleWithdrawType = () => {
    setVaultType(EVault.withdraw);
    setErrorMessage('');
  };

  const handleAddType = () => {
    setVaultType(EVault.add);
    setErrorMessage('');
  };

  const handleCheckError = () => {
    if (vaultType === EVault.add) {
      if (!inputValueAdd || Number(inputValueAdd) <= 0) {
        setErrorMessage('Incorrect amount');
        return true;
      }
      if (inputValueAdd && bigThen(inputValueAdd, currentMaxAmountAdd)) {
        setErrorMessage('Incorrect amount');
        return true;
      }
      return false;
    }
    if (!inputValueWithdraw || Number(inputValueWithdraw) <= 0) {
      setErrorMessage('Incorrect amount');
      return true;
    }
    if (inputValueWithdraw && bigThen(inputValueWithdraw, currentMaxAmountWithdraw)) {
      setErrorMessage('Incorrect amount');
      return true;
    }
    return false;
  };

  const handleConfirm = () => {
    if (handleCheckError()) return;
    const remaining =
      vaultType === EVault.add
        ? BNMinus(currentMaxAmountAdd, inputValueAdd)
        : BNMinus(currentMaxAmountWithdraw, inputValueWithdraw);
    const currentToken = vaultType === EVault.add ? selectedTokenAdd : selectedTokenWithdraw;
    const balance = vaultType === EVault.add ? inputValueAdd : inputValueWithdraw;
    setDataPopUp({
      type: vaultType,
      remaining,
      currentToken,
      balance,
    });
    setActivePopUp(popUps.vaultConfirm);
  };

  const handleMoreGames = () => {
    setPopUpsOpen(null);
    navigate('/games');
  };

  const handleMaxAmount = (maxValue, setValue) => setValue(String(amount(maxValue, false)));

  const handleBlur = () => {
    vaultType === EVault.add
      ? setInputValueAdd(String(amount(inputValueAdd || '0', false)))
      : setInputValueWithdraw(String(amount(inputValueWithdraw || '0', false)));
  };

  useEffect(() => {
    walletRequest({ variables: { input: selectedToken.token } });
  }, [selectedToken]);

  useEffect(() => {
    if (walletResponse) {
      seCurrentMaxAmountAdd(walletResponse.wallet.availableBalance);
    }
  }, [walletResponse]);

  useEffect(() => {
    if (walletsList) {
      const addRes = getVaultWalletsList(
        walletsList?.wallets,
        setSelectedTokenAdd,
        selectedTokenAdd,
        setInputValueAdd,
        setSelectedToken,
        setSelectedNetwork,
      );
      setBalanceDropdownAdd(addRes);

      const withRes = getVaultWalletsList(
        walletsList?.wallets,
        setSelectedTokenWithdraw,
        selectedTokenWithdraw,
        setInputValueWithdraw,
        setSelectedToken,
        setSelectedNetwork,
      );
      setBalanceDropdownWithdraw(withRes);
    }
    if (vaultType === EVault.add) {
      walletRequest({ variables: { input: selectedToken.token } });
    }
  }, [walletsList, vaultType]);

  useEffect(() => {
    if (selectedTokenWithdraw && vaultsList) {
      const current = vaultsList?.vaults?.find((el) => el?.token?.tokenCode === selectedTokenWithdraw.token);
      if (current) {
        setCurrentMaxAmountWithdraw(current.availableBalance);
      } else {
        setCurrentMaxAmountWithdraw('0');
      }
    }
  }, [selectedTokenWithdraw, vaultsList, balanceDropdownWithdraw]);

  return (
    <>
      {user?.profile?.rank?.id > 1 ? (
        <>
          <div className={styles.title}>{translate('popups.wallet.vault')}</div>
          <div className={styles.header}>
            <Button
              className={styles.button}
              variant={vaultType === EVault.add ? ButtonType.secondaryBold : ButtonType.secondary}
              title={translate('popups.wallet.vault.deposit')}
              width="140px"
              onclick={handleAddType}
            />
            <Button
              className={styles.button}
              variant={vaultType === EVault.withdraw ? ButtonType.secondaryBold : ButtonType.secondary}
              title={translate('popups.wallet.vault.withdraw')}
              width="140px"
              onclick={handleWithdrawType}
            />
          </div>
          <div className={styles.content}>
            {vaultType === EVault.withdraw ? (
              <VaultContentItem
                dropData={balanceDropdownWithdraw}
                inpValue={inputValueWithdraw}
                onChangeInput={(e) => {
                  setErrorMessage('');
                  handleChangeInputMax(e, setInputValueWithdraw, currentMaxAmountWithdraw);
                }}
                maxAmount={String(amount(currentMaxAmountWithdraw))}
                onMaxButton={() => handleMaxAmount(currentMaxAmountWithdraw, setInputValueWithdraw)}
                onBlurInput={handleBlur}
              />
            ) : (
              <VaultContentItem
                dropData={balanceDropdownAdd}
                inpValue={inputValueAdd}
                onChangeInput={(e) => {
                  setErrorMessage('');
                  handleChangeInputMax(e, setInputValueAdd, currentMaxAmountAdd);
                }}
                maxAmount={currentMaxAmountAdd}
                onMaxButton={() => handleMaxAmount(currentMaxAmountAdd, setInputValueAdd)}
                onBlurInput={handleBlur}
              />
            )}
            {errorMessage && <p className={styles.error}>{errorMessage}</p>}
          </div>
          {vaultType === EVault.add && isActiveUserBonus && activeBonusTokens.includes(selectedTokenAdd.token) ? (
            <WarningBlock text="popups.wallet.warning.desc" />
          ) : null}
          <Button
            className={styles.button}
            variant={ButtonType.primary}
            title={translate('general.buttons.continue')}
            width="140px"
            onclick={handleConfirm}
          />
        </>
      ) : (
        <div className={styles.forbidden}>
          <p className={styles.forbiddenText}>{translate('popups.wallet.vault.unavailable.title')}</p>
          <img className={styles.image} src={monster} alt="monster" />
          <Button
            className={styles.button}
            variant={ButtonType.primary}
            title={translate('popups.wallet.vault.unavailable.action')}
            width="288px"
            onclick={handleMoreGames}
          />
        </div>
      )}
    </>
  );
};

export default Vault;
