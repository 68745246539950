import { gql } from '@apollo/client';

export const GET_TOP_PLAYERS = gql`
  query topPlayers {
    topPlayers {
      id
      alias
      avatar
      totalWager
      rank
    }
  }
`;
