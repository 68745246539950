import React from 'react';

import LotteryTimer from 'components/Games/Lottery/base/LotteryTimer';
import { getIsDisplayLotteryMobile } from 'func/prepareDataLottery';
import { getImgSrc } from 'func/common';
import ticketIcon from 'assets/img/Lottery/ticketIcon.webp';

import { ILotteryMobile } from './types';
import styles from './styles.module.scss';

const LotteryMobileLayout: React.FC<ILotteryMobile> = ({
  lotteryRoundDisplayName,
  lotteryRoundPrize,
  dateEnd,
  dateStart,
  timerSize,
  onExpire,
  onClick,
  path,
}) => {
  if (getIsDisplayLotteryMobile(path)) return null;

  return (
    <div className={styles.lotteryMobileWrap}>
      <img src={ticketIcon} alt="icon" className={styles.lotteryMobileImg} />
      <div className={styles.lotteryMobilePrize}>
        {lotteryRoundDisplayName ? <img src={getImgSrc(lotteryRoundDisplayName)} alt="icon" /> : null}
        <p>{lotteryRoundPrize}</p>
      </div>
      <LotteryTimer dateStart={dateStart} dateEnd={dateEnd} size={timerSize} onExpire={onExpire} />
      <div className={styles.lotteryMobileButton}>
        <button type="button" onClick={onClick}>
          Lottery
        </button>
      </div>
    </div>
  );
};

export default LotteryMobileLayout;
