import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';

import timerIcon from 'assets/img/DividendsPage/clock.svg';

import { getEventState, getEventTimerName } from 'func/prepareDataEvents';
import { useLocalization } from 'components/Internationalization';

import TimerStile from './styles';

interface Props {
  dateStart: Date;
  dateEnd: Date;
  frontCard?: boolean;
  onTick?: VoidFunction;
}

const TimerEvent: React.FC<Props> = ({ dateStart, dateEnd, frontCard, onTick }) => {
  const { translate } = useLocalization();

  const [eventState, setEventState] = useState('');
  const [isEventStarted, setIsEventStarted] = useState(false);
  const [isEventFinished, setIsEventFinished] = useState(false);

  const { days, hours, minutes, seconds, restart } = useTimer({
    expiryTimestamp: dayjs(dateEnd).toDate(),
    onExpire: handleExpire,
  });

  useEffect(() => {
    const now = dayjs();
    const started = dayjs(dateStart).isBefore(now);
    const finished = dayjs(dateEnd).isAfter(now);
    const countDownDate = dayjs(started ? dateEnd : dateStart).toDate();

    restart(countDownDate, true);

    setIsEventStarted(started);
    setIsEventFinished(finished);
  }, [dateStart, dateEnd]);

  useEffect(() => {
    setEventState(getEventState(isEventFinished, isEventStarted));
  }, [isEventStarted, isEventFinished]);

  const getFormattedTime = (time: number): string => {
    if (time <= 0) {
      return '00';
    }

    return ('0' + String(time)).slice(-2);
  };

  function handleExpire() {
    setEventState(getEventState(true, false));
    setTimeout(() => onTick(), 2000);
  }

  return (
    <TimerStile eventstate={eventState} frontcard={frontCard ? 'true' : ''}>
      <p>{translate(frontCard ? eventState : getEventTimerName(eventState))}</p>
      <div className="timer__content">
        <img src={timerIcon} alt="icon" />
        {isEventFinished ? (
          <span>
            {getFormattedTime(days)}d {getFormattedTime(hours)}:{getFormattedTime(minutes)}:{getFormattedTime(seconds)}
          </span>
        ) : (
          <span className="timer__uppercase">{translate('events.state.completed')}</span>
        )}
      </div>
    </TimerStile>
  );
};
export default TimerEvent;
