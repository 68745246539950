import React, { useContext } from 'react';

import { ISessionManagementContext } from './types';

const SessionManagementContext = React.createContext<ISessionManagementContext>({
  refreshTokens: () => null,
  logout: () => null,
});

export const useSession = () => useContext(SessionManagementContext);

export default SessionManagementContext;
