import { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import LiveStatsContext from 'context/contextLiveStats/context';

import LiveStatistics from 'components/Base/LiveStatistics';
import LotteryDesktop from '../Lottery/LotteryDesktop/LotteryDesktop';
import KenoHistory from './components/KenoHistory';
import KenoNavigationButtons from './components/KenoNavigationButtons';
import KenoBetAmountBlock from './components/KenoBetAmountBlock';
import KenoSubmitButton from './components/ KenoSubmitButton';
import KenoAdditionalButtons from './components/KenoAdditionalButtons';
import KenoRiskAuto from './components/KenoRiskAuto';
import KenoOdds from './components/KenoOdds';
import KenoSquares from './components/KenoSquares';
import KenoAutoSettings from './components/KenoAutoSettings';
import KenoTables from './components/KenoTables';
import KenoWinAnimation from './components/KenoWinAnimation';
import KenoSwitchAuto from './components/KenoSwitchAuto';
import KenoSeoText from './components/KenoSeoText';

import styles from './styles.module.scss';

const KenoGame: React.FC = () => {
  const { enabled, visible } = useContext(LiveStatsContext);

  const isMobile = useMediaQuery({ query: '(max-width: 1260px)' });

  return (
    <div className={styles.kenoGameWrap}>
      <div className={styles.kenoGameContentWrap}>
        <div className={styles.kenoGameContent}>
          <div className={styles.kenoGameContentHeader}>
            <KenoHistory />
            <KenoNavigationButtons />
          </div>
          <div className={styles.kenoGameContentMain}>
            <KenoSquares />
            <KenoWinAnimation />
            {isMobile ? enabled && visible ? <LiveStatistics mobile={isMobile} /> : null : null}
          </div>
          <div className={styles.kenoGameContentOdds}>
            <KenoOdds />
          </div>
          <div className={styles.kenoGameContentNavigation}>
            <KenoBetAmountBlock />
            <div className={styles.kenoGameContentNavigationSubmitWrap}>
              <KenoSubmitButton />
              <KenoSwitchAuto />
            </div>
            <div className={styles.kenoGameContentNavigationFooterWrap}>
              <KenoRiskAuto />
              <KenoAdditionalButtons />
            </div>
          </div>
        </div>
        <LotteryDesktop />
      </div>
      <div className={styles.kenoGameAutoSettings}>
        <KenoAutoSettings />
      </div>
      <div className={styles.kenoGameTableWrap}>
        <KenoTables />
      </div>
      <KenoSeoText />
    </div>
  );
};

export default KenoGame;
