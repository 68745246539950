import { GameType } from 'types';
import { IFreeSpinsSettings, IUserBonus } from 'components/Pages/BoonusPage/components/UserBonus/types';
import { EBonus } from 'components/constants/games';

export interface IDataState {
  favoriteGamesList: GameType[];
  userBonuses: null | IUserBonus[];
  isDisplayBonus: string;
  isActiveUserBonus: boolean;
  freeSpinsSettings: null | IFreeSpinsSettings;
  games: GameType[];
  vipStatus: boolean;
  activeBonusTokens: string[];
}

export const dataInitialState: IDataState = {
  favoriteGamesList: [],
  userBonuses: null,
  isDisplayBonus: EBonus.default,
  isActiveUserBonus: false,
  freeSpinsSettings: null,
  games: [],
  vipStatus: false,
  activeBonusTokens: [],
};
