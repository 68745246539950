import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import withdrawConfirmIcon from 'assets/img/PopUps/ConfirmationIcon/withdrawConfirm.webp';

import { ContextPopUps, ContextTokenCode } from 'context';
import { popUps } from 'components/constants/constants';
import { ResponseErrors } from 'components/constants/errors';
import { WITHDRAW } from 'graphQl/mutations/withdraw/withdraw';
import { amount, getImgSrc } from 'func/common';
import { GET_ALL_WALLETS, GET_WALLET } from 'graphQl/query/wallet/wallet';
import { USER_SECURITY } from 'graphQl/query/auth/auth';
import { GET_USER } from 'graphQl/query/auth/profile';
import { ButtonType } from 'components/Base/Button/types';
import { useAppSelector } from 'hooks/useAppSelector';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';

import ButtonSubmit from '../ButtonSubmit';
import styles from './styles.module.scss';
import { IWithdrawSuccess } from './types';

const WithdrawSuccess: React.FC<IWithdrawSuccess> = ({
  memo,
  withdrawBalance,
  textareaValue,
  withdrawalToken,
  data,
  selectedNetwork,
}) => {
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { tokenWithNetworksList } = useContext(ContextTokenCode);
  const { translate } = useLocalization();

  const [walletUser, setWalletUser] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [errorResponse, setErrorResponse] = useState(null);
  const [networkFee, setNetworkFee] = useState(null);

  const token = useAppSelector(userToken);

  const { data: userSecurity } = useQuery(USER_SECURITY, { fetchPolicy: 'cache-and-network' });

  const { data: user } = useQuery(GET_USER, { fetchPolicy: 'cache-only' });

  useQuery(GET_WALLET, {
    skip: !token,
    variables: { input: withdrawalToken.token },
    fetchPolicy: 'cache-and-network',
    onCompleted: (dataWallet) => {
      setWalletUser(dataWallet?.wallet?.availableBalance);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log('[GET_WALLET_ERROR]: ', error);
    },
  });

  const [withdraw] = useMutation(WITHDRAW, {
    refetchQueries: [GET_ALL_WALLETS, GET_WALLET],
  });

  const getWithdrawPayload = (code?: string) => {
    return {
      payload: {
        address: textareaValue,
        amount: withdrawBalance,
        networkCode: data.selectedNetwork,
        tokenCode: withdrawalToken?.token,
        emailOtp: code || null,
        otp: code || null,
        memo: memo || null,
      },
    };
  };

  const handleSubmitOnTwoFA = async (errorCodeSetter, securityCodeValue) => {
    try {
      const variables = getWithdrawPayload(securityCodeValue);
      await withdraw({ variables });

      setPopUpsOpen(null);
    } catch (error) {
      errorCodeSetter(error);
    }
  };

  const handleSubmit = async () => {
    try {
      setDisabled(true);

      const variables = getWithdrawPayload();
      const response = await withdraw({ variables });

      if (response) {
        setPopUpsOpen(null);
      }
    } catch (error) {
      if (error.message === ResponseErrors.googleMFA || error.message === ResponseErrors.emailMFA) {
        setPopUpsOpen({
          modalOpen: popUps.verifySecurityCode,
          data: {
            onSubmit: handleSubmitOnTwoFA,
            autoSendOtp: true,
            title: userSecurity?.userSecurity?.google_2fa ? 'Google' : 'Email',
            email: user?.profile?.email,
          },
        });
      } else {
        setErrorResponse(error.message);
        setDisabled(true);
      }

      // eslint-disable-next-line no-console
      console.log('[WITHDRAW_ERROR]: ', error);
    } finally {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (walletUser && withdrawBalance && withdrawalToken && selectedNetwork) {
      const feeObj = tokenWithNetworksList.tokenWithNetworks.find((elem) => elem.tokenCode === withdrawalToken.token);
      const fee = feeObj.networks.find((elem) => elem?.network.code === selectedNetwork);

      setNetworkFee(fee.withdrawalFee);
    }
  }, [walletUser, withdrawBalance, selectedNetwork, withdrawalToken]);

  return (
    <>
      <div className={styles.withdrawConfirmTitle}>{translate('popups.withdraw.success.title')}</div>
      <img src={withdrawConfirmIcon} alt="icon" className={styles.withdrawConfirmationImage} />
      {!disabled ? (
        <div className={styles.withdrawConfirmContent}>
          <div className={styles.withdrawConfirmItem}>
            <h4>{translate('popups.withdraw.success.amount')}:</h4>
            <p>
              <img src={getImgSrc(withdrawalToken.name)} alt="icon" />
              {amount(withdrawBalance)}
            </p>
          </div>
          <div className={styles.withdrawConfirmItem}>
            <h4>{translate('popups.withdraw.success.network')}:</h4>
            <p>
              <img src={getImgSrc(withdrawalToken.name)} alt="icon" />
              {amount(networkFee)}
            </p>
          </div>
          <div className={styles.withdrawConfirmItem}>
            <h4>{translate('popups.withdraw.success.sending')}:</h4>
            <p>{textareaValue}</p>
          </div>
        </div>
      ) : (
        <div className={styles.withdrawConfirmProgress}>
          <p>{translate('popups.withdraw.success.processing')}</p>
          <h4 className={styles.withdrawConfirmProgressItem}>
            <img src={getImgSrc(withdrawalToken.name)} alt="icon" />
            {amount(withdrawBalance)}
          </h4>
        </div>
      )}
      {errorResponse && <h3 className={styles.h3}>{errorResponse}</h3>}
      <ButtonSubmit
        className={styles.button}
        handleButton={handleSubmit}
        variant={ButtonType.primary}
        width="288px"
        title={translate('popups.withdraw.success.button')}
        disabled={disabled || errorResponse}
      />
    </>
  );
};

export default WithdrawSuccess;
