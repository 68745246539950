import { useEffect, useState } from 'react';

function returnTwoDigits(value: string) {
  if (value === 'NaN') {
    return '00';
  }

  return value.toString().split('').length === 1 ? `0${value}` : value;
}

const getReturnValues = (countDown): [string, string, string, string] => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24)).toString();
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString();
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)).toString();
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000).toString();

  return [returnTwoDigits(days), returnTwoDigits(hours), returnTwoDigits(minutes), returnTwoDigits(seconds)];
};

const useCountdown: (arg: string) => [string, string, string, string] = (targetDate: string) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

export { useCountdown };
