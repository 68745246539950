import clsx from 'clsx';

import styles from './styles.module.scss';
import { IWheelOfFortuneRewards } from './types';
import { WHEEL_REWARD_NAME } from '../../constants';
import { styled } from 'styled-components';

const WheelOfFortuneRewards: React.FC<IWheelOfFortuneRewards> = ({ className, rewards, wheelUrl, wheelSettings }) => {
  return (
    <ul className={clsx(styles.list, className)}>
      {rewards.map((r) => {
        const imageUrl = WHEEL_REWARD_NAME.replace(':num', String(r.weight));
        const image = `${wheelUrl}${imageUrl}`;

        return (
          <StyledReward key={r.id} className={styles.item} $color={wheelSettings.wheel_text_color}>
            <img className={styles.icon} src={image} />
            <span className={styles.text}>
              {r.prize} {r.displayName}
            </span>
          </StyledReward>
        );
      })}
    </ul>
  );
};

export default WheelOfFortuneRewards;

const StyledReward = styled.li.withConfig({
  shouldForwardProp: (prop) => !prop.includes('$'),
})<{ $color: string }>`
  ${({ $color }) => `color: ${$color || '#ffffff'};`}
`;
