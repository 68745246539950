import h_A from 'assets/img/Cards/Hearts/Ace.webp';
import h_K from 'assets/img/Cards/Hearts/King.webp';
import h_Q from 'assets/img/Cards/Hearts/Queen.webp';
import h_J from 'assets/img/Cards/Hearts/Jack.webp';
import h_10 from 'assets/img/Cards/Hearts/10.webp';
import h_5 from 'assets/img/Cards/Hearts/5.webp';
import h_4 from 'assets/img/Cards/Hearts/4.webp';

import d_A from 'assets/img/Cards/Diamonds/Ace.webp';
import d_J from 'assets/img/Cards/Diamonds/Jack.webp';
import d_10 from 'assets/img/Cards/Diamonds/10.webp';
import d_9 from 'assets/img/Cards/Diamonds/9.webp';
import d_8 from 'assets/img/Cards/Diamonds/8.webp';
import d_7 from 'assets/img/Cards/Diamonds/7.webp';
import d_6 from 'assets/img/Cards/Diamonds/6.webp';
import d_3 from 'assets/img/Cards/Diamonds/3.webp';

import c_A from 'assets/img/Cards/Clubs/Ace.webp';
import c_Q from 'assets/img/Cards/Clubs/Queen.webp';
import c_J from 'assets/img/Cards/Clubs/Jack.webp';
import c_9 from 'assets/img/Cards/Clubs/9.webp';
import c_7 from 'assets/img/Cards/Clubs/7.webp';
import c_4 from 'assets/img/Cards/Clubs/4.webp';
import c_3 from 'assets/img/Cards/Clubs/3.webp';
import c_2 from 'assets/img/Cards/Clubs/2.webp';

import s_J from 'assets/img/Cards/Spades/Jack.webp';
import s_10 from 'assets/img/Cards/Spades/10.webp';
import s_9 from 'assets/img/Cards/Spades/9.webp';
import s_8 from 'assets/img/Cards/Spades/8.webp';
import s_7 from 'assets/img/Cards/Spades/7.webp';
import s_6 from 'assets/img/Cards/Spades/6.webp';
import s_5 from 'assets/img/Cards/Spades/5.webp';
import s_4 from 'assets/img/Cards/Spades/4.webp';
import s_2 from 'assets/img/Cards/Spades/2.webp';

import foldIcon from 'assets/img/BetSteps/fold.webp';
import dealIcon from 'assets/img/BetSteps/deal.webp';
import backIcon from 'assets/img/BetSteps/back.webp';
import clearIcon from 'assets/img/BetSteps/clear.webp';
import x1 from 'assets/img/BetSteps/x1.webp';
import x2 from 'assets/img/BetSteps/x2.webp';
import x3 from 'assets/img/BetSteps/x3.webp';
import x4 from 'assets/img/BetSteps/x4.webp';
import doubleIcon from 'assets/img/BetSteps/double.webp';
import newBetIcon from 'assets/img/BetSteps/new.webp';
import reBetIcon from 'assets/img/BetSteps/rebet.webp';
import maxIcon from 'assets/img/BetSteps/max.webp';

import { TexasHoldemRules } from 'components/Base/PopUps/components/TexasHoldemRules/types';
import { ITexasHoldemAction } from 'components/Games/TexasHoldem/components/TexasHoldemActions/types';
import { TexasHoldemActions, TexasHoldemCombination, TexasHoldemGameState } from 'context/contextTexasHoldem/types';
import { TEXAS_HOLDEM_CHECK_STATE, TEXAS_HOLDEM_END_GAME_STATE } from 'context/contextTexasHoldem/constants';

export const TEXAS_HOLDEM_ACTIONS: ITexasHoldemAction[] = [
  {
    icon: dealIcon,
    title: 'texas-holdem.action.deal',
    action: TexasHoldemActions.deal,
    color: 'linear-gradient(180deg, rgba(74,162,46,1) 0%, rgba(2,46,0,1) 100%)',
    state: [TexasHoldemGameState.notStarted],
  },
  {
    icon: clearIcon,
    title: 'texas-holdem.action.clear',
    action: TexasHoldemActions.clear,
    color: 'linear-gradient(180deg, rgba(219,23,82,1) 0%, rgba(113,5,34,1) 100%)',
    state: [TexasHoldemGameState.notStarted],
  },
  {
    icon: backIcon,
    title: 'texas-holdem.action.back',
    action: TexasHoldemActions.back,
    color: 'linear-gradient(180deg, rgba(240,168,28,1) 0%, rgba(197,78,28,1) 100%)',
    state: [TexasHoldemGameState.notStarted],
  },
  {
    icon: x2,
    title: 'x2',
    action: TexasHoldemActions.x2,
    color: 'linear-gradient(180deg, rgba(240,168,28,1) 0%, rgba(197,78,28,1) 100%)',
    state: [TexasHoldemGameState.flop],
  },
  {
    icon: x3,
    title: 'x3',
    action: TexasHoldemActions.x3,
    color: 'linear-gradient(180deg, rgba(240,168,28,1) 0%, rgba(197,78,28,1) 100%)',
    state: [TexasHoldemGameState.preFlop],
  },
  {
    icon: x4,
    title: 'x4',
    action: TexasHoldemActions.x4,
    color: 'linear-gradient(180deg, rgba(240,168,28,1) 0%, rgba(197,78,28,1) 100%)',
    state: [TexasHoldemGameState.preFlop],
  },
  {
    icon: x1,
    title: 'x1',
    action: TexasHoldemActions.x1,
    color: 'linear-gradient(180deg, rgba(240,168,28,1) 0%, rgba(197,78,28,1) 100%)',
    state: [TexasHoldemGameState.turn],
  },
  {
    icon: foldIcon,
    title: 'texas-holdem.action.fold',
    action: TexasHoldemActions.fold,
    color: 'linear-gradient(180deg, rgba(74,162,46,1) 0%, rgba(2,46,0,1) 100%)',
    state: [TexasHoldemGameState.turn],
  },
  {
    icon: dealIcon,
    title: 'texas-holdem.action.check',
    action: TexasHoldemActions.check,
    color: 'linear-gradient(180deg, rgba(74,162,46,1) 0%, rgba(2,46,0,1) 100%)',
    state: TEXAS_HOLDEM_CHECK_STATE,
  },
  {
    title: 'texas-holdem.action.rebet.double',
    icon: doubleIcon,
    action: TexasHoldemActions.rebetDouble,
    color: 'linear-gradient(180deg, rgba(240,168,28,1) 0%, rgba(197,78,28,1) 100%)',
    state: TEXAS_HOLDEM_END_GAME_STATE,
    condition: 'doubleLowerThanMax',
  },
  {
    title: 'texas-holdem.action.rebet.max',
    icon: maxIcon,
    action: TexasHoldemActions.rebetDouble,
    color: 'linear-gradient(180deg, rgba(240,168,28,1) 0%, rgba(197,78,28,1) 100%)',
    state: TEXAS_HOLDEM_END_GAME_STATE,
    condition: 'doubleGreaterThanMax',
  },
  {
    title: 'texas-holdem.action.new.bet',
    icon: newBetIcon,
    action: TexasHoldemActions.newBet,
    color: 'linear-gradient(180deg, rgba(74,162,46,1) 0%, rgba(2,46,0,1) 100%)',
    state: TEXAS_HOLDEM_END_GAME_STATE,
  },
  {
    title: 'texas-holdem.action.rebet',
    icon: reBetIcon,
    action: TexasHoldemActions.rebet,
    color: 'linear-gradient(180deg, rgba(43,141,197,1) 0%, rgba(4,61,177,1) 100%)',
    state: TEXAS_HOLDEM_END_GAME_STATE,
  },
];

export const TEXAS_HOLDEM_RULES_TITLES = [
  { title: 'popups.rules.texas-holdem.titles.rules', value: TexasHoldemRules.rules },
  { title: 'popups.rules.texas-holdem.titles.hands', value: TexasHoldemRules.hands },
];

export const TEXAS_HOLDEM_COMBINATIONS: Record<TexasHoldemCombination, string> = {
  ROYAL_FLUSH: 'popups.rules.texas-holdem.hand.royal.title',
  STRAIGHT_FLUSH: 'popups.rules.texas-holdem.hand.straight-flush.title',
  FOUR_OF_A_KIND: 'popups.rules.texas-holdem.hand.one-kind.title',
  FULL_HOUSE: 'popups.rules.texas-holdem.hand.house.title',
  FLUSH: 'popups.rules.texas-holdem.hand.flush.title',
  STRAIGHT: 'popups.rules.texas-holdem.hand.straight.title',
  THREE_OF_A_KIND: 'popups.rules.texas-holdem.hand.three-kind.title',
  TWO_PAIRS: 'popups.rules.texas-holdem.hand.two-pair.title',
  ONE_PAIR: 'popups.rules.texas-holdem.hand.pair.title',
  HIGH_CARD: 'popups.rules.texas-holdem.hand.high.title',
};

export const TRIPS_ODDS = [
  { title: TEXAS_HOLDEM_COMBINATIONS.ROYAL_FLUSH, coefficient: '50:1' },
  { title: TEXAS_HOLDEM_COMBINATIONS.STRAIGHT_FLUSH, coefficient: '40:1' },
  { title: TEXAS_HOLDEM_COMBINATIONS.FOUR_OF_A_KIND, coefficient: '30:1' },
  { title: TEXAS_HOLDEM_COMBINATIONS.FULL_HOUSE, coefficient: '8:1' },
  { title: TEXAS_HOLDEM_COMBINATIONS.FLUSH, coefficient: '6:1' },
  { title: TEXAS_HOLDEM_COMBINATIONS.STRAIGHT, coefficient: '5:1' },
  { title: TEXAS_HOLDEM_COMBINATIONS.THREE_OF_A_KIND, coefficient: '3:1' },
];

export const BLIND_ODDS = [
  { title: TEXAS_HOLDEM_COMBINATIONS.ROYAL_FLUSH, coefficient: '500:1' },
  { title: TEXAS_HOLDEM_COMBINATIONS.STRAIGHT_FLUSH, coefficient: '50:1' },
  { title: TEXAS_HOLDEM_COMBINATIONS.FOUR_OF_A_KIND, coefficient: '10:1' },
  { title: TEXAS_HOLDEM_COMBINATIONS.FULL_HOUSE, coefficient: '3:1' },
  { title: TEXAS_HOLDEM_COMBINATIONS.FLUSH, coefficient: '3:2' },
  { title: TEXAS_HOLDEM_COMBINATIONS.STRAIGHT, coefficient: '1:1' },
  { title: 'popups.rules.texas-holdem.hand.all-others.title', coefficient: 'Push' },
];

export const TEXAS_HOLDEM_POKER_HANDS = [
  {
    title: TEXAS_HOLDEM_COMBINATIONS.ROYAL_FLUSH,
    desc: 'popups.rules.texas-holdem.hand.royal.desc',
    cards: [h_A, h_K, h_Q, h_J, h_10],
  },
  {
    title: TEXAS_HOLDEM_COMBINATIONS.STRAIGHT_FLUSH,
    desc: 'popups.rules.texas-holdem.hand.straight-flush.desc',
    cards: [s_8, s_7, s_6, s_5, s_4],
  },
  {
    title: TEXAS_HOLDEM_COMBINATIONS.FOUR_OF_A_KIND,
    desc: 'popups.rules.texas-holdem.hand.one-kind.desc',
    cards: [h_J, d_J, s_J, c_J, d_7],
  },
  {
    title: TEXAS_HOLDEM_COMBINATIONS.FULL_HOUSE,
    desc: 'popups.rules.texas-holdem.hand.house.desc',
    cards: [h_10, d_10, s_10, c_9, d_9],
  },
  {
    title: TEXAS_HOLDEM_COMBINATIONS.FLUSH,
    desc: 'popups.rules.texas-holdem.hand.flush.desc',
    cards: [s_4, s_J, s_8, s_2, s_9],
  },
  {
    title: TEXAS_HOLDEM_COMBINATIONS.STRAIGHT,
    desc: 'popups.rules.texas-holdem.hand.straight.desc',
    cards: [c_9, d_8, s_7, d_6, h_5],
  },
  {
    title: TEXAS_HOLDEM_COMBINATIONS.THREE_OF_A_KIND,
    desc: 'popups.rules.texas-holdem.hand.three-kind.desc',
    cards: [c_7, d_7, s_7, h_K, d_3],
  },
  {
    title: TEXAS_HOLDEM_COMBINATIONS.TWO_PAIRS,
    desc: 'popups.rules.texas-holdem.hand.two-pair.desc',
    cards: [c_4, s_4, c_3, d_3, c_Q],
  },
  {
    title: TEXAS_HOLDEM_COMBINATIONS.ONE_PAIR,
    desc: 'popups.rules.texas-holdem.hand.pair.desc',
    cards: [d_A, c_A, d_8, c_4, s_7],
  },
  {
    title: TEXAS_HOLDEM_COMBINATIONS.ONE_PAIR,
    desc: 'popups.rules.texas-holdem.hand.high.desc',
    cards: [d_3, c_J, s_8, h_4, c_2],
  },
];
