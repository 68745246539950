import { useState } from 'react';

import { IFAQContentItem } from './types';

const FAQContentItem: React.FC<IFAQContentItem> = ({ title, description }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  return (
    <div className="faq__content--item" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
      <div className="faq__content--item__question" onClick={handleOpen}>
        <h6 className="faq__content--item__question--title" itemProp="name">
          <strong>{title}</strong>
        </h6>
        <div className="faq__content--item__question--arrow">
          <i className={open ? 'up' : 'down'} />
        </div>
      </div>
      <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
        <div
          className={open ? 'faq__content--item__answer open' : 'faq__content--item__answer'}
          itemProp="text"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
};

export default FAQContentItem;
