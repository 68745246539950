import clsx from 'clsx';
import { useState } from 'react';

import InfoBlockAction from './infoBlockAction';

import { IInfoBlockItem } from './types';
import styles from './styles.module.scss';

const InfoBlockItem: React.FC<IInfoBlockItem> = ({ title, array }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <div className={styles.footerInfoBlock}>
      <div className={styles.footerInfoBlockTitle}>
        <h4>{title}</h4>
        <h3 onClick={handleOpen}>{!open ? '+' : '-'}</h3>
      </div>
      <div className={clsx(styles.footerInfoBlockItemWrap, open ? styles.open : '')}>
        {array.map((a) => (
          <InfoBlockAction key={a.id} action={a} />
        ))}
      </div>
    </div>
  );
};

export default InfoBlockItem;
