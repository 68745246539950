import { useContext } from 'react';

import { ContextPopUps } from 'context';
import { popUps } from 'components/constants/constants';
import { Links } from 'components/constants';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';
import { useSession } from 'context/contextSessionManagement/context';

import ButtonSubmit from '../ButtonSubmit';

import { ICancelEmailVerification } from './types';
import styles from './styles.module.scss';

const CancelEmailVerification: React.FC<ICancelEmailVerification> = ({ data }) => {
  const { translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { logout } = useSession();

  const handleCloseVerifyPopup = () => {
    setPopUpsOpen({
      modalOpen: popUps.cancelSignUpConfirm,
      data,
    });
  };

  const handleCancel = () => {
    setPopUpsOpen({
      modalOpen: popUps.signUpConfirm,
      data: {
        ...data,
        onClose: handleCloseVerifyPopup,
      },
    });
  };

  return (
    <div className={styles.cancelWrap}>
      <div className={styles.cancelTitle}>{translate('popups.email-verification.cancel.title')}</div>
      <div className={styles.cancelFormWrap}>
        <div className={styles.cancelForm}>
          <div className={styles.cancelFormText}>{translate('popups.email-verification.cancel.desc')}</div>
          <div className={styles.cancelFormText}>{translate('popups.email-verification.cancel.continue')}</div>
          <div className={styles.cancelFormText}>{translate('popups.email-verification.cancel.cancel')}</div>
        </div>
        <div className={styles.cancelBtnWrap}>
          <ButtonSubmit
            className={styles.button}
            variant={ButtonType.secondary}
            width="120px"
            title={translate('general.buttons.continue')}
            color="#242c45"
            handleButton={() => logout(Links.home)}
          />
          <ButtonSubmit
            className={styles.button}
            variant={ButtonType.primary}
            width="100px"
            title={translate('general.buttons.cancel')}
            color="#242c45"
            handleButton={handleCancel}
          />
        </div>
      </div>
    </div>
  );
};

export default CancelEmailVerification;
