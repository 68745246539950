export enum BuyCryptoState {
  default = '',
  notAvailable = 'notAvailable',
  available = 'available',
}

export const BuyCryptoSettings = {
  fiat: 'USD',
  crypto: 'BTC',
  amount: '100',
};

export enum ProviderCode {
  moonpay = 'moonpay',
  banxa = 'banxa',
}

export enum BuyCryptoType {
  crypto = 'crypto',
  fiat = 'fiat',
}

export enum ChangellyTokenCode {
  BTC = 'BTC',
  ETH = 'ETH',
  TRX = 'TRX',
  LTC = 'LTC',
  DOGE = 'DOGE',
  USDT = 'USDTRX',
  BSC = 'BNBBSC',
}

export enum ChangllyPaymentMethod {
  card = 'card',
}

export interface IAvailableCountryResponse {
  code: string;
  name: string;
}

export interface IProvidersResponse {
  code: ProviderCode;
  name: string;
  trustPilotRating: string;
  iconUrl: string;
}

export interface ICurrencyResponse {
  ticker: string;
  name: string;
  type: BuyCryptoType;
  extraIdName: string;
  iconUrl: string;
  precision: string;
}

export interface IOffersResponse {
  rate: string;
  invertedRate: string;
  fee: string;
  amountFrom: string;
  amountExpectedTo: string;
  errorMessage: string;
  providerCode: string;
  errorDetails: [
    {
      cause: string;
      value: string;
    },
  ];
}
