import clsx from 'clsx';
import styles from './styles.module.scss';
import { IMainButton } from './types';

const MainButton: React.FC<IMainButton> = ({ title, disabled, variant = 'primary', classList, onClick }) => {
  return (
    <div className={clsx(variant === 'primary' ? styles.bgPrimary : styles.bgSecondary, classList)}>
      <button
        type="button"
        className={clsx(styles.button, variant === 'primary' ? styles.primary : styles.secondary)}
        disabled={disabled}
        onClick={onClick}
      >
        {title}
      </button>
    </div>
  );
};

export default MainButton;
