import React from 'react';

import { DOCUMENT_TITLE_SETTINGS } from 'components/constants/constants';

import DocumentTitle from 'components/Base/DocumentTitle';
import PageTitleShadow from 'components/Base/PageTitleShadow';
import { PageShadow } from 'components/constants/pages';

import Title from './components/Title';
import Games from './components/Games';
import styles from './styles.module.scss';

const GamesPage: React.FC = () => {
  return (
    <div className={styles.gamesPage}>
      <DocumentTitle data={DOCUMENT_TITLE_SETTINGS.games} />
      <Title />
      <PageTitleShadow background={PageShadow.games} />
      <Games />
    </div>
  );
};

export default React.memo(GamesPage);
