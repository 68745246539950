import { gql } from '@apollo/client';

export const WHEEL_REWARDS = gql`
  query wheelSettings {
    wheelSettings {
      id
      tokenCode
      displayName
      prize
      weight
      rankId
    }
  }
`;

export const GET_WHEEL_STILE = gql`
  query wheelStyle {
    wheelStyle {
      wheel_style
      wheel_background_color
      wheel_button_color
    }
  }
`;

export const USER_WHEEL_HISTORY = gql`
  query userWheelHistory {
    userWheelHistory {
      id
      amount
      token {
        displayName
        tokenCode
      }
      createdAt
    }
  }
`;

export const TOTAL_WINS_HISTORY = gql`
  query totalWheelWins {
    totalWheelWins {
      id
      amount
      token {
        displayName
        tokenCode
      }
      user {
        alias
        avatar {
          avatar
        }
      }
    }
  }
`;
