import { useState } from 'react';

import { InHouseGamesPageTablesTitles, IN_HOUSE_TABLE_TITLES } from 'components/constants/table';

import TablesTitles from 'components/common/TablesTitles';
import MyKenoBets from './components/MyKenoBets';

import styles from './styles.module.scss';

const KenoTables: React.FC = () => {
  const [table, setTable] = useState<string>(InHouseGamesPageTablesTitles.myBets);

  const handleTitle = (title: string) => {
    setTable(title);
  };

  return (
    <div className={styles.kenoTablesWrap}>
      <TablesTitles currentTitle={table} titles={IN_HOUSE_TABLE_TITLES} onTitle={handleTitle} />
      {InHouseGamesPageTablesTitles.myBets ? <MyKenoBets /> : null}
    </div>
  );
};

export default KenoTables;
