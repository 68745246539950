import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

const FAQSectionStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1400px;
  margin: 0 0 50px;
  padding: 0 30px;

  font-family: 'Montserrat';
  color: #ffffff;

  .faq__title {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .faq__content--wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 30px;
  }

  .faq__content--item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #888aa7;
    margin: 10px;
    padding: 10px 0;
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      border-bottom: none;
      padding-bottom: 10px;
    }
  }

  .faq__content--item__question {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    cursor: url(${point}), auto !important;
  }

  .faq__content--item__question--title {
    display: flex;
    flex-grow: 1;
    font-weight: 700;
    font-size: 16px;
    line-height: 29px;

    letter-spacing: 0.12px;
    margin-bottom: 15px;
    strong {
      color: #ffffff;
    }
  }
  .faq__content--item__question--arrow {
    font-size: 20px;
    flex-grow: 0;
    min-width: 50px;
    i {
      border: 1px solid #ffffff;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      height: 3px;
      margin-right: 30px;
      transition: all 0.5s ease-in-out;
    }
  }

  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border: 1px solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-bottom: 3px;
  }

  .faq__content--item__answer {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    p,
    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 18px !important;
      color: #ffffff !important;
    }
    a {
      color: #0e9628;
    }
    ul {
      li {
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 18px !important;
        color: #ffffff !important;
        list-style-type: disc !important;
      }
    }

    height: auto;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    max-height: 0;
  }
  .faq__content--item__answer.open {
    max-height: 500px;
  }

  @media (max-width: 1200px) {
  }
  @media (max-width: 1024px) {
    .faq__content--item__question--title {
      font-size: 18px;
    }
    .faq__content--item__question--arrow {
      min-width: 20px;
      max-width: 20px;
      justify-content: flex-end;
    }
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
    padding: 0 15px;
  }
  @media (max-width: 580px) {
  }
  @media (max-width: 500px) {
    .faq__content--wrap {
      padding: 15px;
    }
  }
  @media (max-width: 400px) {
  }
`;

export default FAQSectionStyle;
