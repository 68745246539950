/* eslint-disable prefer-const */
import Big from 'big.js';
import { Outlet } from 'react-router-dom';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { ContextWallet } from 'context/contextWallet';
import { getAverageLiveStatsRecords } from 'func/live';

import LiveStatsContext from './context';
import { ILiveStatisticsBetData } from './types';

const DEFAULT_BET = {
  uv: 0,
  pv: 0,
};

const LiveStatsProvider: React.FC = () => {
  const { tokenDetails } = useContext(ContextWallet);

  const profitRef = useRef('0');
  const wageredRef = useRef('0');
  const winsRef = useRef(0);
  const lossesRef = useRef(0);

  const [enabled, setEnabled] = useState(false);
  const [visible, setVisible] = useState(true);
  const [bets, setBets] = useState<ILiveStatisticsBetData[]>([]);

  useEffect(() => {
    handleReset();
  }, [tokenDetails]);

  useEffect(() => {
    if (bets.length === 100) {
      const newTestBets = getAverageLiveStatsRecords(bets);

      setBets(newTestBets);
    }
  }, [bets]);

  const handleBet = (wager: string, payout: string) => {
    let newProfit: string;
    let newWager: string;

    const profit = profitRef.current;
    const wagered = wageredRef.current;

    const isWin = Big(payout).gte(wager);
    newWager = Big(wagered).plus(wager).toFixed();

    wageredRef.current = newWager;

    if (isWin) {
      const wins = winsRef.current;

      const winAmount = Big(payout).minus(wager).toFixed();

      newProfit = Big(profit).plus(winAmount).toFixed();

      profitRef.current = newProfit;
      winsRef.current = wins + 1;
    } else {
      const losses = lossesRef.current;
      const isPaid = Big(payout).gt(0);
      const currentWager = isPaid ? Big(wager).minus(payout).toFixed() : wager;

      newProfit = Big(profit).minus(currentWager).toFixed();

      profitRef.current = newProfit;
      lossesRef.current = losses + 1;
    }

    setBets((b) => {
      const newBets = [...b];

      if (!b.length) {
        newBets.push(DEFAULT_BET);
      }

      newBets.push({
        uv: Number(newProfit) * 100,
        pv: Number(newProfit),
      });

      return newBets;
    });
  };

  function handleReset() {
    profitRef.current = '0';
    wageredRef.current = '0';
    winsRef.current = 0;
    lossesRef.current = 0;

    setBets(() => []);
  }

  function handleToggleStatistics(newEnabled?: boolean, newVisible?: boolean) {
    // if disabled
    if (typeof newEnabled !== 'undefined' && !newEnabled) {
      handleReset();
    }

    setEnabled((e) => (typeof newEnabled !== 'undefined' ? newEnabled : !e));
    setVisible((v) => (typeof newVisible !== 'undefined' ? newVisible : !v));
  }

  const liveStatsValues = useMemo(
    () => ({
      enabled,
      visible,
      profit: profitRef.current,
      wagered: wageredRef.current,
      wins: winsRef.current,
      losses: lossesRef.current,
      bets,
      onBet: handleBet,
      onReset: handleReset,
      onToggleStatistics: handleToggleStatistics,
    }),
    [enabled, visible, bets],
  );

  return (
    <LiveStatsContext.Provider value={liveStatsValues}>
      <Outlet />
    </LiveStatsContext.Provider>
  );
};

export default LiveStatsProvider;
