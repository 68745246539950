import { gql } from '@apollo/client';

export const COLLECT_BONUS = gql`
  mutation collectBonus($input: String!) {
    collectBonus(userBonusId: $input)
  }
`;

export const SELECT_BONUS = gql`
  mutation selectUserBonus($input: String!) {
    selectUserBonus(userBonusId: $input)
  }
`;

export const CANCEL_BONUS = gql`
  mutation cancelUserBonus($input: String!) {
    cancelUserBonus(userBonusId: $input)
  }
`;

export const ACTIVATE_FREE_SPINS = gql`
  mutation activateUserBonusFreespins($input: String!) {
    activateUserBonusFreespins(userBonusId: $input)
  }
`;

export const ACTIVATE_PROMO_CODE = gql`
  mutation getUserBonusByPromoCode($input: String!) {
    getUserBonusByPromoCode(promoCode: $input)
  }
`;
