import styled from 'styled-components';

export const StyledFirstBlock = styled.div`
  .react-card-flip {
    height: auto !important;
  }
  .react-card-flipper {
    height: 100% !important;
  }
`;
