import React from 'react';
import clsx from 'clsx';

import rankCheckedIcon from 'assets/img/ranks/rowDetails/rank-checked.webp';

import { IRankDetailsRow } from './types';
import styles from './styles.module.scss';

const RankDetailsRow: React.FC<IRankDetailsRow> = ({ isReached, image, title, popUp }) => {
  if (popUp && !isReached) return null;
  return (
    <div className={styles.rankRow}>
      <div className={clsx(styles.rankRowWrap, !isReached && styles.rankNoReached)}>
        <img src={image} alt="icon" className={styles.rankRowImg} />
        <h2 className={styles.rankRowTitle}>{title}</h2>
      </div>
      {popUp && isReached ? <img src={rankCheckedIcon} alt="icon" className={styles.rankRowCheckmark} /> : null}
    </div>
  );
};

export default RankDetailsRow;
