import { gql } from '@apollo/client';

export const GET_HOME_QUERIES = gql`
  query home {
    activeSliders {
      id
      deskImage
      tabImage
      mobImage
      url
      status
      weight
      isAuthenticated
    }
    topPlayers {
      id
      alias
      avatar
      totalWager
      rank
    }
    numberOfAccounts
    totalDividends
    totalCashbacks
    totalReferrals
  }
`;
