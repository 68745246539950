import clsx from 'clsx';

import activeSearchIcon from 'assets/img/GamesFiler/active-search.webp';

import InputCustom from 'components/Base/Input';

import styles from './styles.module.scss';
import { IBuyCryptoInputDropLayout } from './types';

const BuyCryptoInputDropLayout: React.FC<IBuyCryptoInputDropLayout> = ({
  inputTitle,
  inputValue,
  onChange,
  disable,
  searchValue,
  isOpen,
  data,
  dropTitle,
  disableInput,
  currencyText,
  searchText,
  onSearch,
  onOpen,
  onBlur,
  onDropChange,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <p>{inputTitle}:</p>
        <InputCustom
          id="send"
          name="send"
          type="text"
          value={inputValue}
          onChange={onChange}
          disable={disable || disableInput}
        />
      </div>
      <div className={styles.block}>
        <p>{currencyText}:</p>
        <div className={clsx(styles.buyCryptoInputDropWrap, disable ? styles.disable : '')}>
          <div tabIndex={0} className={styles.buyCryptoInputDropHeader} onClick={onOpen} onBlur={onBlur}>
            <p className={styles.buyCryptoInputDropHeaderPara}>{dropTitle}</p>
            <i className={clsx(styles.buyCryptoInputDropI, isOpen ? styles.iUp : styles.iDown)} />
          </div>
          <div className={`${styles.buyCryptoInputDropBody} ${isOpen && styles.buyCryptoInputDropBodyOpen}`}>
            <div className={styles.buyCryptoInputDropBodyInputWrap}>
              <input
                tabIndex={100000}
                type="text"
                value={searchValue}
                onChange={onSearch}
                className={styles.buyCryptoInputDropBodyInput}
                onBlur={onBlur}
                placeholder={searchText}
              />
              <img src={activeSearchIcon} alt="search" />
            </div>
            <div className={styles.buyCryptoInputDropBodyContent}>
              {data.length ? (
                data.map((c, index: number) => (
                  <div
                    key={c.ticker}
                    tabIndex={index + 100000}
                    className={styles.buyCryptoInputDropBodyItem}
                    onClick={() => {
                      onDropChange(c.ticker, c.type);
                      onOpen();
                    }}
                  >
                    <h2>
                      <img src={c.iconUrl} alt="currency" />
                    </h2>
                    <div>
                      <h4>{c.name}</h4>
                      <h3>{c.ticker}</h3>
                    </div>
                  </div>
                ))
              ) : (
                <p className={styles.byCryptoInputDropNoResults}>No Results Found</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyCryptoInputDropLayout;
