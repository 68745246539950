import * as R from 'ramda';

import { TableElem, Token, RankItem } from 'types/requestTypes';
import { TABLE_CLASSNAMES } from 'components/constants/constants';

import rank1 from 'assets/img/ranks/withoutShadow/1.webp';
import rank2 from 'assets/img/ranks/withoutShadow/2.webp';
import rank3 from 'assets/img/ranks/withoutShadow/3.webp';
import rank4 from 'assets/img/ranks/withoutShadow/4.webp';
import rank5 from 'assets/img/ranks/withoutShadow/5.webp';
import rank6 from 'assets/img/ranks/withoutShadow/6.webp';
import rank7 from 'assets/img/ranks/withoutShadow/7.webp';
import rank8 from 'assets/img/ranks/withoutShadow/8.webp';
import rank9 from 'assets/img/ranks/withoutShadow/9.webp';
import rank10 from 'assets/img/ranks/withoutShadow/10.webp';
import rank11 from 'assets/img/ranks/withoutShadow/11.webp';
import rank12 from 'assets/img/ranks/withoutShadow/12.webp';
import rank13 from 'assets/img/ranks/withoutShadow/13.webp';
import rank14 from 'assets/img/ranks/withoutShadow/14.webp';
import rank15 from 'assets/img/ranks/withoutShadow/15.webp';
import rank16 from 'assets/img/ranks/withoutShadow/16.webp';
import rank17 from 'assets/img/ranks/withoutShadow/17.webp';

import rankSilver1 from 'assets/img/ranks/silver/1.webp';
import rankSilver2 from 'assets/img/ranks/silver/2.webp';
import rankSilver3 from 'assets/img/ranks/silver/3.webp';
import rankSilver4 from 'assets/img/ranks/silver/4.webp';
import rankSilver5 from 'assets/img/ranks/silver/5.webp';
import rankSilver6 from 'assets/img/ranks/silver/6.webp';
import rankSilver7 from 'assets/img/ranks/silver/7.webp';
import rankSilver8 from 'assets/img/ranks/silver/8.webp';
import rankSilver9 from 'assets/img/ranks/silver/9.webp';
import rankSilver10 from 'assets/img/ranks/silver/10.webp';
import rankSilver11 from 'assets/img/ranks/silver/11.webp';
import rankSilver12 from 'assets/img/ranks/silver/12.webp';
import rankSilver13 from 'assets/img/ranks/silver/13.webp';
import rankSilver14 from 'assets/img/ranks/silver/14.webp';
import rankSilver15 from 'assets/img/ranks/silver/15.webp';
import rankSilver16 from 'assets/img/ranks/silver/16.webp';
import rankSilver17 from 'assets/img/ranks/silver/17.webp';

import { getDate, getImgSrc, amount } from './common';

interface DataElem {
  title: string | number;
  src: string;
}

interface ReferralBalance {
  availableBalance?: number;
  __typename?: string;
  token?: Token;
}

interface ReferralCollections {
  amount?: number;
  createdAt?: string;
  __typename?: string;
  token?: Token;
  totalCollected?: string;
}

const ranksListWithoutShadow = [
  rank1,
  rank2,
  rank3,
  rank4,
  rank5,
  rank6,
  rank7,
  rank8,
  rank9,
  rank10,
  rank11,
  rank12,
  rank13,
  rank14,
  rank15,
  rank16,
  rank17,
];

const ranksListSilver = [
  rankSilver1,
  rankSilver2,
  rankSilver3,
  rankSilver4,
  rankSilver5,
  rankSilver6,
  rankSilver7,
  rankSilver8,
  rankSilver9,
  rankSilver10,
  rankSilver11,
  rankSilver12,
  rankSilver13,
  rankSilver14,
  rankSilver15,
  rankSilver16,
  rankSilver17,
];

export const prepareCashbackBalance = (arrayData: ReferralBalance[]): Array<string & DataElem> | DataElem[] => {
  const balanceData = [];

  arrayData.forEach((elem) => {
    const elemObj = {
      title: amount(elem.availableBalance),
      src: elem.token.tokenCode,
      displayName: elem.token.displayName,
    };
    balanceData.push(elemObj);
  });
  return balanceData;
};

export const prepareCashbackCollections = (arrayData: ReferralCollections[]): TableElem[][] => {
  const tableData = arrayData.map((elem) => {
    const date = {
      title: getDate(elem.createdAt),
      columnClass: TABLE_CLASSNAMES.column.default,
      textClass: TABLE_CLASSNAMES.text.dateFormat,
    };
    const elemObj = {
      title: `${amount(elem.amount)} ${elem.token.displayName}`,
      src: getImgSrc(elem.token.displayName),
      columnClass: TABLE_CLASSNAMES.column.default,
      textClass: TABLE_CLASSNAMES.text.dateFormat,
      imageClass: TABLE_CLASSNAMES.images.currency,
    };
    return [date, elemObj];
  });

  return tableData;
};

export const prepareCashbackBalanceDashboard = (
  arrayData: ReferralBalance[],
): Array<string & DataElem> | DataElem[] => {
  const balanceData = [];
  const emptyData = [{ title: 0, src: 'BTC' }];
  if (arrayData.length < 1) {
    return emptyData;
  }

  arrayData.forEach((elem) => {
    const elemObj = {
      title: elem.availableBalance,
      src: elem.token.tokenCode,
      displayName: elem.token.displayName,
    };
    balanceData.push(elemObj);
  });
  return balanceData;
};

export const prepareCashbackTotalCollected = (arrayData: ReferralCollections[]): DataElem[] => {
  const properData = arrayData.map((elem) => ({
    title: amount(elem.totalCollected),
    src: elem.token.tokenCode,
    displayName: elem.token.displayName,
  }));
  return properData;
};

export const getRankImg = (id: number): string => {
  const img = ranksListWithoutShadow.find((_, index) => index + 1 === id);
  return img;
};

export const getRanksImgSilver = (id: number): string => {
  const img = ranksListSilver.find((_, index) => index + 1 === id);
  return img;
};

export const getSortedRankList = (list: RankItem[]): RankItem[] => {
  const diff = function (a: RankItem, b: RankItem) {
    return a.id - b.id;
  };
  const sorted = R.sort(diff, list);
  return sorted;
};

export const getVipRankNum = (list: RankItem[]): number => {
  const vipRank = list.find((el) => el.vip);
  return vipRank?.id || 12;
};

export const getPremiumAvatarsNum = (list: RankItem[]): number => {
  const avatarRank = list.find((el) => el.avatars);
  return avatarRank?.id || 12;
};
