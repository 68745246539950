import React, { useContext, useEffect } from 'react';
import hotkeys from 'hotkeys-js';

import { useAppSelector } from 'hooks/useAppSelector';
import { ContextPopUps, ContextTokenCode, ContextWallet } from 'context';
import SocketContext from 'context/contextSocket/context';
import { SocketActions } from 'types';
import { popUps } from 'components/constants/constants';
import { ECoinCondition } from 'components/constants/games';
import { useEventListener } from 'hooks/useEventListener';
import useGameSettings from 'hooks/useGameSettings';
import LiveStatsContext from 'context/contextLiveStats/context';
import { lessThen } from 'func/prepareDataDice';
import { amount, removeComas } from 'func/common';
import {
  getBetCashOut,
  getButtonTitleCoin,
  getCoinHistoryTableCollect,
  isDisableCoinButton,
  isDisableCoinInitButton,
  isDisplayCoinCashOut,
} from 'func/prepareDataCoinFlip';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';

import BetAmountBlock from 'components/Games/base/BetAmountBlock';
import GameBetButton from 'components/Games/base/GameBetButton';

import CoinButtonsStyle from './styles';
import { ICoinButtons } from './types';
import { ICollectCoinRound, ICollectCoinRoundResponse, ICreateCoinRound } from '../../types';

const CoinButtons: React.FC<ICoinButtons> = ({
  gameState,
  setBetAmountValue,
  betAmountValue,
  setGameState,
  isHotKeys,
  coinId,
  isMultiplyValue,
  betSettings,
  setBetSettings,
  setMultiplierSeries,
  multiplierSeries,
  setHistory,
  setHistoryTable,
  fastMode,
  disableByRequest,
}) => {
  const { translate } = useLocalization();
  const { connected: socketConnected, socket } = useContext(SocketContext);
  const { enabled: liveStatsEnabled, onBet: recordLiveStats } = useContext(LiveStatsContext);
  const { tokenCode } = useContext(ContextTokenCode);
  const { walletUser } = useContext(ContextWallet);
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const gameSettings = useGameSettings(coinId);

  const token = useAppSelector(userToken);

  const createCoinRound = async (betData: ICreateCoinRound): Promise<boolean> => {
    return new Promise((res) => {
      socket.volatile.emit(SocketActions.createCoinRound, betData, (data: boolean) => {
        res(data);
      });
    });
  };

  const collectCoinRound = async (betData: ICollectCoinRound): Promise<ICollectCoinRoundResponse> => {
    return new Promise((res) => {
      socket.volatile.emit(SocketActions.collectCoinRound, betData, (data: ICollectCoinRoundResponse) => {
        res(data);
      });
    });
  };

  const handleSignUp = () => setPopUpsOpen(popUps.registration);

  const handleSubmit = async () => {
    if (!token) return;
    const currentBetAmount = removeComas(betAmountValue);
    const notEnoughMoney = lessThen(walletUser, betSettings.minBet) || lessThen(walletUser, currentBetAmount);

    if (lessThen(currentBetAmount, betSettings.minBet)) return;

    if (notEnoughMoney && gameState === ECoinCondition.sessionNotStarted) {
      setPopUpsOpen({
        modalOpen: popUps.walletNavigation,
        data: { config: popUps.deposit, active: popUps.deposit },
      });
      return;
    }
    if (isMultiplyValue && !multiplierSeries.series) {
      // by default series = 0 (to init session)
      setGameState(ECoinCondition.sessionInit);
      await createCoinRound({
        gameId: coinId,
        token: tokenCode.token,
        wager: currentBetAmount,
      });
      setHistory([]);
    }
    if (isMultiplyValue && multiplierSeries.series > 0) {
      // each game series grow up 1
      setGameState(ECoinCondition.sessionNotStarted);
      const collectRes = await collectCoinRound({
        // (to collect session)
        gameId: coinId,
        token: tokenCode.token,
      });

      if (liveStatsEnabled) {
        recordLiveStats(currentBetAmount, collectRes.payout);
      }

      setHistoryTable((prev) => getCoinHistoryTableCollect(prev, collectRes, tokenCode.name, betAmountValue));
      setTimeout(() => setMultiplierSeries({ series: 0, multiplier: '0' }), fastMode ? 1300 : 1500);
    }
  };

  useEffect(() => {
    if (gameState === ECoinCondition.notStarted && gameSettings) {
      setBetAmountValue(String(amount(gameSettings.betAmount)));
    }
    if (gameSettings) {
      setBetSettings({
        minBet: gameSettings.minBet || '0',
        maxBet: gameSettings.maxBet || '0',
        maxPayout: gameSettings.maxPay || '0',
      });
    }
  }, [gameSettings, gameState]);

  const handleKyePress = (event) => {
    if (isHotKeys) {
      if (isDisableCoinInitButton(gameState, multiplierSeries.series)) return;
      const key = event?.key;
      if (key === ' ') handleSubmit();
    }
  };

  useEventListener('keyup', handleKyePress);
  hotkeys('space', (event) => event.preventDefault());

  return (
    <CoinButtonsStyle>
      <div className={!isMultiplyValue ? 'coin__buttons1 full' : 'coin__buttons1'}>
        <BetAmountBlock
          betAmountValue={betAmountValue}
          minimumBet={betSettings.minBet}
          maxBet={betSettings.maxBet}
          setBetAmountValue={setBetAmountValue}
          disable={isDisableCoinButton(gameState) || !socketConnected || disableByRequest}
          isHotKeys={isHotKeys}
          gameId={coinId}
        />
      </div>
      <div className={!isMultiplyValue ? 'submit__wrap hide' : 'submit__wrap'}>
        <GameBetButton
          disable={
            isDisableCoinInitButton(gameState, multiplierSeries.series) ||
            (token && !socketConnected) ||
            disableByRequest
          }
          onClick={token ? handleSubmit : handleSignUp}
          isHotkeys={isHotKeys}
        >
          <div className="submit__bet">
            <h5>{translate(getButtonTitleCoin(gameState))}</h5>
            {isDisplayCoinCashOut(gameState, isMultiplyValue) && (
              <h6>{getBetCashOut(betAmountValue, multiplierSeries.multiplier, betSettings.maxPayout)}</h6>
            )}
          </div>
        </GameBetButton>
      </div>
    </CoinButtonsStyle>
  );
};

export default CoinButtons;
