import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { GET_MY_DIVIDENDS_DISTRIBUTIONS } from 'graphQl/query/dividends/myDividendDistributions';
import { prepareMyDividendsDistribution } from 'func/prepareDataDividends';
import TableWithoutPagination from 'components/Base/Table/TableWithoutPagination/TableWithoutPagination';
import { TABLE_CLASSNAMES } from 'components/constants/constants';
import { useLocalization } from 'components/Internationalization';

const MyDividends: React.FC = () => {
  const { translate, language } = useLocalization();
  const [titles, setTitles] = useState([]);

  const { data } = useQuery(GET_MY_DIVIDENDS_DISTRIBUTIONS, {
    variables: {
      input: {
        limit: 20,
        offset: 0,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const newTitles = [
      { title: translate('dividends.tabs.all.dividends.date'), columnClass: TABLE_CLASSNAMES.column.default },
      { title: translate('dividends.tabs.all.dividends.staked'), columnClass: TABLE_CLASSNAMES.column.default },
      { title: translate('dividends.tabs.all.dividends.amount'), columnClass: TABLE_CLASSNAMES.column.default },
    ];

    setTitles(newTitles);
  }, [language]);

  return (
    <TableWithoutPagination
      thWidth="30%"
      titles={titles}
      prepareDataTable={prepareMyDividendsDistribution}
      data={data?.myDividendDistributions?.items}
      config="second"
      cutCurrency
    />
  );
};

export default MyDividends;
