import React, { useContext } from 'react';
import DiceGameContext from 'context/contextDiceGame/context';

import AutoSwitch from 'components/Games/base/AutoSwitch';

import styles from './styles.module.scss';

const DiceGameSwitchAuto: React.FC = () => {
  const { auto, autoStarted, betInProgress, onChangeAuto } = useContext(DiceGameContext);

  return (
    <div className={styles.diceAutoSwitchWrap}>
      <AutoSwitch checked={auto} disabled={autoStarted || betInProgress} onCheck={onChangeAuto} />
    </div>
  );
};

export default DiceGameSwitchAuto;
