import Big from 'big.js';
import { useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import useSocketRequest from 'hooks/useSocketRequest';
import { DiceBetMode, IBet, IGameSettings, SocketActions } from 'types';
import { useAppSelector } from 'hooks/useAppSelector';
import { ContextTokenCode } from 'context/contextTokenCode';
import { ContextWallet } from 'context/contextWallet';
import SocketContext from 'context/contextSocket/context';
import LiveStatsContext from 'context/contextLiveStats/context';
import { ContextPopUps } from 'context/contextPopups';
import { GET_BETS_HISTORY } from 'graphQl/query/betsPage/betsHistory';
import { GET_GAME_SETTINGS } from 'graphQl/query/settings/gameSettings';
import useGame from 'hooks/useGame';
import {
  DEFAULT_BET_VALUE,
  DICE_ANIMATION_KEY,
  DICE_BET_ANIMATION_DURATION,
  EGamesSlugName,
  HotKeys,
} from 'components/constants/games';
import { amount, prepareAmount, removeComas } from 'func/common';
import { getDiceBetResult } from 'func/games';
import { ETokenCode, popUps } from 'components/constants/constants';
import { AutoBetSettings } from 'components/Games/base/AutoBetBlockNew/types';
import { isAutoBetCanRun, processAutoBetGetValuesForReducer } from 'func/commonGames';
import { userProfile, userToken } from 'store/user/user.selectors';

import DiceGameContext, { initialDiceAutoSettings } from './context';
import { DiceBetState, IDiceBetResult, IDiceRollPayload, IDiceRollResponse } from './types';

const DiceGameProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { walletUser } = useContext(ContextWallet);
  const { tokenCode } = useContext(ContextTokenCode);
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { inGame: socketInGame, connected: socketConnected } = useContext(SocketContext);
  const { enabled: liveStatsEnabled, onBet: recordLiveStats } = useContext(LiveStatsContext);

  const autoBetStarted = useRef(false);
  const autoBetSettings = useRef<AutoBetSettings | null>(null);
  const autoBetAmount = useRef<string | null>(null);
  const autoBetUserWallet = useRef<string | null>(null);
  const autoBetAnimation = useRef<boolean | null>(null);
  const liveStatisticsEnabled = useRef<boolean>(false);
  const firstSpin = useRef<boolean>(true);

  const [diceId, setDiceId] = useState('');
  const [animation, setAnimation] = useState(true);
  const [firstBet, setFirstBet] = useState(true);
  const [animationInProgress, setAnimationInProgress] = useState(false);
  const [betState, setBetState] = useState<DiceBetState>(DiceBetState.once);
  const [betInProgress, setBetInProgress] = useState(false);
  const [betAmount, setBetAmount] = useState(String(amount(DEFAULT_BET_VALUE)));
  const [betMode, setBetMode] = useState<DiceBetMode>(DiceBetMode.under);
  const [betPrediction, setBetPrediction] = useState(50);
  const [history, setHistory] = useState<IDiceBetResult[]>([]);
  const [autoSettings, setAutoSettings] = useState<AutoBetSettings>(initialDiceAutoSettings);
  const [gameSettings, setGameSettings] = useState<IGameSettings | undefined>(undefined);
  const [settings, setSettings] = useState<IGameSettings[]>([]);
  const [withHotKeys, setWithHotKeys] = useState(false);

  const token = useAppSelector(userToken);
  const user = useAppSelector(userProfile);
  const diceGame = useGame(
    {
      slug: EGamesSlugName.dice,
      providerName: 'In-House',
      userId: user?.id,
    },
    !token,
  );

  const { data: betsHistoryData, error: betsHistoryError } = useQuery(GET_BETS_HISTORY, {
    skip: !token || !diceId,
    variables: {
      input: {
        limit: 16,
        offset: 0,
        gameId: diceId,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const { data: settingsData, error: settingsError } = useQuery(GET_GAME_SETTINGS, {
    skip: !diceId,
    variables: { input: diceId },
    fetchPolicy: 'cache-and-network',
  });

  /* Preparation of data */

  useEffect(() => {
    liveStatisticsEnabled.current = liveStatsEnabled;
  }, [liveStatsEnabled]);

  useEffect(() => {
    if (diceGame) {
      setDiceId(diceGame.id);
    }
  }, [diceGame]);

  useEffect(() => {
    if (betsHistoryData) {
      const betsHistory = pathOr<IBet[]>([], ['userBets', 'items'], betsHistoryData);
      const betsResults = betsHistory.map((h) => ({
        ...h.result,
        id: h.id,
        multiplier: h.multiplier,
        amount: h.amount,
        payout: h.payout,
        token: h.tokenCode,
        createdAt: h.createdAt as unknown as Date,
      }));

      setHistory(betsResults);
    }
  }, [betsHistoryData]);

  useEffect(() => {
    if (settingsData) {
      const newSettings = pathOr<IGameSettings[]>([], ['gameSettings'], settingsData);

      setSettings(newSettings);
    }
  }, [settingsData]);

  /* Updating key dependencies */

  useEffect(() => {
    const userFastMode = JSON.parse(localStorage.getItem(DICE_ANIMATION_KEY));
    const savedWithHotKeys = localStorage.getItem(HotKeys.dice);
    const defaultFastMode = false;

    setAnimation(userFastMode !== null ? userFastMode : defaultFastMode);

    if (savedWithHotKeys) {
      setWithHotKeys(savedWithHotKeys === 'true');
    }
  }, []);

  useEffect(() => {
    const settingsForToken = settings.find((s) => s.token.tokenCode === tokenCode.token);

    if (settingsForToken && walletUser) {
      if (settingsForToken.token.displayName === gameSettings?.token?.displayName) {
        return;
      }

      const { defaultBet = '0', minimumBet = '0' } = settingsForToken;

      setGameSettings(settingsForToken);

      const formattedWallet = removeComas(`${walletUser}`);
      const formattedDefaultBet = removeComas(defaultBet);
      const formattedMinimumBet = removeComas(minimumBet);

      if (Big(formattedWallet).gte(formattedDefaultBet)) {
        setBetAmount(String(amount(formattedDefaultBet)));
      } else if (Big(formattedWallet).gte(formattedMinimumBet)) {
        setBetAmount(String(amount(formattedMinimumBet)));
      } else {
        setBetAmount(String(amount('0')));
      }
    } else {
      setBetAmount(String(amount('0')));
    }
  }, [settings, walletUser]);

  useEffect(() => {
    if (autoBetStarted.current) stopAutoBet();
  }, [tokenCode]);

  useEffect(() => {
    if (autoBetUserWallet.current) {
      autoBetUserWallet.current = String(walletUser);
    }
  }, [walletUser]);

  useEffect(() => {
    if (typeof autoBetAnimation.current === 'boolean') {
      autoBetAnimation.current = animation;
    }
  }, [animation]);

  /* Error handling */

  useEffect(() => {
    if (betsHistoryError) {
      // eslint-disable-next-line no-console
      console.log('[BETS_HISTORY_ERROR]: ', betsHistoryError);
      return;
    }

    if (settingsError) {
      // eslint-disable-next-line no-console
      console.log('[SETTINGS_ERROR]: ', settingsError);
    }
  }, [betsHistoryError, settingsError]);

  /* Restart autobet if socket connection was lost */
  useEffect(() => {
    if (socketConnected && socketInGame && autoBetStarted.current) {
      const valid = isAutoBetCanRun(
        {
          ...autoBetSettings.current,
          firstSpin: firstSpin.current,
          autoBetAmount: autoBetAmount.current,
        },
        autoBetAmount.current,
        gameSettings.minimumBet,
        handleChangeAutoSettings,
      );

      if (valid) {
        handleBet(true);
      } else {
        stopAutoBet();

        firstSpin.current = true;
      }
    }
  }, [socketConnected, socketInGame]);

  /* Utils Functions */

  const handleChangeAuto = () => {
    setBetState(betState === DiceBetState.once ? DiceBetState.auto : DiceBetState.once);
  };

  const handleChangeBetAmount = (newAmount: string) => {
    setBetAmount(newAmount);
  };

  const handleChangeMode = (newMode: DiceBetMode) => {
    setBetMode(newMode);
  };

  const handleChangePrediction = (newPrediction: number) => {
    setBetPrediction(newPrediction);
  };

  const handleChangeAutoSettings = (name: string, value: string | boolean) => {
    autoBetSettings.current = {
      ...autoSettings,
      ...autoBetSettings.current,
      [name]: value,
    };

    setAutoSettings((currentSettings) => ({
      ...currentSettings,
      [name]: value,
    }));
  };

  const handleChangeAnimation = (newValue: boolean) => {
    setAnimation(newValue);
    localStorage.setItem(DICE_ANIMATION_KEY, newValue.toString());
  };

  const handleChangeWithHotKeys = (newValue: boolean) => {
    setWithHotKeys(newValue);
    localStorage.setItem(HotKeys.dice, newValue.toString());
  };

  const handleToggleAnimationInProgress = (newValue: boolean) => {
    setAnimationInProgress(newValue);
  };

  /* Socket Functions */
  const diceRoll = useSocketRequest<IDiceRollPayload, IDiceRollResponse>(SocketActions.diceRoll);

  /* Bet Functions */

  const updateBetsHistory = (betResult: IDiceRollResponse, wager: string, betToken: string) => {
    const result = getDiceBetResult(betResult, wager, betToken);

    setHistory((h) => [result, ...h]);
  };

  async function handleBet(auto: boolean) {
    if (!auto) {
      setBetInProgress(true);
    }

    const newAmount = autoBetStarted.current && autoBetAmount.current ? autoBetAmount.current : betAmount;
    const newWalletsUser = autoBetStarted.current && autoBetUserWallet.current ? autoBetUserWallet.current : walletUser;
    const wager = prepareAmount(newAmount);

    if (Big(wager).gt(newWalletsUser)) {
      setPopUpsOpen({
        modalOpen: popUps.walletNavigation,
        data: {
          config: popUps.deposit,
          active: popUps.deposit,
        },
      });

      if (auto) stopAutoBet();
      if (!auto) setBetInProgress(false);

      return;
    }

    const bet = await diceRoll({
      gameId: diceId,
      token: tokenCode.token as ETokenCode,
      mode: betMode,
      wager,
      prediction: betPrediction,
      auto,
    });

    if (firstSpin.current) {
      firstSpin.current = false;
      setFirstBet(false);
    }

    if (liveStatisticsEnabled.current) {
      recordLiveStats(wager, bet.payout);
    }

    updateBetsHistory(bet, wager, tokenCode.token);

    if (!auto) {
      setBetInProgress(false);
    }

    const timeout = autoBetAnimation.current ? DICE_BET_ANIMATION_DURATION + 200 : 20;

    setTimeout(() => {
      if (autoBetStarted.current) {
        const autoState = {
          ...autoBetSettings.current,
          firstSpin: firstSpin.current,
          autoBetAmount: autoBetAmount.current,
        };

        const [newAutoState] = processAutoBetGetValuesForReducer(
          autoState,
          betAmount,
          bet.payout,
          removeComas(autoBetAmount.current, 12),
          gameSettings.maximumBet,
          gameSettings.minimumBet,
        );

        autoBetSettings.current = newAutoState;
        autoBetAmount.current = newAutoState.autoBetAmount;

        const valid = isAutoBetCanRun(
          newAutoState,
          newAutoState.autoBetAmount,
          gameSettings.minimumBet,
          handleChangeAutoSettings,
        );

        if (valid) {
          handleBet(true);
        } else {
          firstSpin.current = true;

          stopAutoBet();
        }

        return;
      }

      // update number of bets if bets are stopped
      if (!autoBetStarted.current && autoSettings.betsNumbers) {
        const { betsNumbers } = autoSettings;

        if (Big(betsNumbers).gt(0)) {
          setAutoSettings((s) => ({
            ...s,
            betsNumbers: Big(s.betsNumbers).minus(1).toFixed(),
          }));
        }
      }
    }, timeout);
  }

  function startAutoBet() {
    setBetState(DiceBetState.autoStarted);

    autoBetAnimation.current = animation;
    autoBetStarted.current = true;
    autoBetSettings.current = {
      ...autoSettings,
      stopOnWinAmount: autoSettings.stopOnWinAmountDisplay,
      stopOnLossAmount: autoSettings.stopOnLossAmountDisplay,
    };
    autoBetAmount.current = betAmount;
    autoBetUserWallet.current = String(walletUser);

    const valid = isAutoBetCanRun(
      {
        ...autoBetSettings.current,
        firstSpin: firstSpin.current,
        autoBetAmount: autoBetAmount.current,
      },
      autoBetAmount.current,
      gameSettings.minimumBet,
      handleChangeAutoSettings,
    );

    if (valid) {
      handleBet(true);
    } else {
      stopAutoBet();

      firstSpin.current = true;
    }
  }

  function stopAutoBet() {
    const formattedAmount = String(amount(autoBetAmount.current, false, 12));

    setBetAmount(formattedAmount);
    setAutoSettings(autoBetSettings.current);
    setBetState(DiceBetState.auto);

    firstSpin.current = true;
    autoBetStarted.current = false;
    autoBetAnimation.current = null;
    autoBetSettings.current = null;
    autoBetAmount.current = null;
    autoBetUserWallet.current = null;
  }

  const handleAutoBet = () => {
    if (betState === DiceBetState.autoStarted) {
      stopAutoBet();
    } else {
      firstSpin.current = true;

      startAutoBet();
    }
  };

  return (
    <DiceGameContext.Provider
      value={{
        animation,
        auto: betState === DiceBetState.auto || betState === DiceBetState.autoStarted,
        autoStarted: betState === DiceBetState.autoStarted,
        autoSettings: autoBetSettings.current || autoSettings,
        animationInProgress,
        betInProgress,
        diceId,
        firstBet,
        amount: autoBetAmount.current ? String(amount(autoBetAmount.current)) : betAmount,
        mode: betMode,
        prediction: betPrediction,
        gameSettings,
        history,
        withHotKeys,
        onChangeAuto: handleChangeAuto,
        onChangeAmount: handleChangeBetAmount,
        onChangeMode: handleChangeMode,
        onChangePrediction: handleChangePrediction,
        onChangeAutoSettings: handleChangeAutoSettings,
        onChangeAnimation: handleChangeAnimation,
        onChangeWithHotKeys: handleChangeWithHotKeys,
        onToggleAnimationInProgress: handleToggleAnimationInProgress,
        onBet: async () => handleBet(false),
        onAutoBet: handleAutoBet,
      }}
    >
      {children}
    </DiceGameContext.Provider>
  );
};

export default DiceGameProvider;
