export enum TimerSize {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  HEADER = 'header',
}

export interface ILotteryTimer {
  dateStart: string;
  dateEnd: string;
  size: TimerSize;
  onExpire: VoidFunction;
}
