import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

interface Props {
  isflipped: boolean;
}

const EstimatedStyle = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isflipped'].includes(prop),
})<Props>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 20px;

  width: 100%;
  height: 408px;

  overflow: hidden;

  #estimated {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
    transform-style: preserve-3d;
  }
  .flipped {
    transform: rotateY(180deg);
  }

  .side {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    -webkit-perspective: 0;
    -webkit-transform: translate3d(0, 0, 0);
    visibility: visible;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
  }

  .side.front {
    border-radius: 20px;
  }

  .side.front > div,
  .side.back > div {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  .side.back {
    border-radius: 20px;
    background: #202d4a;
    transform: ${(props) =>
      !props.isflipped ? 'rotateY(180deg) translate(0,-150%)' : 'rotateY(180deg) translate(0,-100%)'};
  }

  .fip-card-button {
    position: absolute;
    width: 70px;
    height: 27px;
    right: 30px;
    top: 30px;
    z-index: 2;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 17px;
    border: none;
    cursor: url(${point}), auto !important;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 9px;
    line-height: 12px;
    color: #ffffff;
    z-index: 12;
  }

  @media (max-width: 1200px) {
  }
  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 30px;
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
    height: ${(props) => (!props.isflipped ? '466px' : '466px')};
    .fip-card-button {
      right: 20px;
      top: 20px;
    }
  }
  @media (max-width: 580px) {
    height: ${(props) => (!props.isflipped ? '486px' : '526px')};
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 450px) {
    height: ${(props) => (!props.isflipped ? '506px' : '526px')};
  }
`;

export default EstimatedStyle;
