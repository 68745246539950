import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { pathOr } from 'ramda';
import { useQuery } from '@apollo/client';

import limboPopUp from 'assets/img/Limbo/limboPopUp.webp';

import config from 'config';
import { GET_BET } from 'graphQl/query/bet/bet';
import { GET_USER } from 'graphQl/query/auth/profile';
import { amount, getImgSrc } from 'func/common';
import { bigThen } from 'func/prepareDataDice';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import Loader from 'components/Base/Loader';
import AutoCheckbox from 'components/Games/base/AutoCheckbox';
import Button from 'components/Base/Button';
import CopyButton from 'components/Base/CopyButton';

import LimboPopUpStyle from './styles';
import { IBetResponse } from '../types';
import { ILimboDetails } from './types';

const { codesandboxUrl } = config;

const LimboBetDetails: React.FC<ILimboDetails> = ({ data }) => {
  const { translate } = useLocalization();

  const [bet, setBet] = useState<IBetResponse>();
  const [inputValue, setInputValue] = useState(bet?.isAuto);

  const { data: betData, loading } = useQuery(GET_BET, {
    variables: { betId: data.id },
  });

  const { data: user } = useQuery(GET_USER, {
    fetchPolicy: 'cache-only',
  });

  const handleCheckbox = (val) => setInputValue(val);

  useEffect(() => {
    const newBet = pathOr(undefined, ['bet'], betData);

    if (newBet) {
      setBet(newBet);
      setInputValue(newBet.isAuto);
    }
  }, [betData]);

  const getDate = (string: string): string => dayjs(string).format('MM/DD/YYYY  hh:mm:ss A');

  const handleVerify = () => {
    window.open(codesandboxUrl, '_blank');
  };

  return loading ? (
    <Loader pages />
  ) : (
    <LimboPopUpStyle payout={data?.payout}>
      <div className="title">{translate('popups.bets.details.title')}</div>
      <div className="subtitle">
        <div className="subtitle-item1">
          <p>{translate('popups.bets.details.player')}:</p>
          <p>{translate('popups.bets.details.date')}:</p>
          <p>{translate('popups.bets.details.id')}:</p>
        </div>
        <div className="subtitle-item2">
          <h3>{user.profile.alias}</h3>
          <h3>{getDate(bet?.createdAt)}</h3>
          <h3>{bet?.id}</h3>
        </div>
      </div>
      <div className="amount-block">
        <div className="amount">
          <p className="amount-title">{translate('popups.bets.details.amount')}</p>
          <div className="amount-subtitle">
            <img alt="icon" src={getImgSrc(bet?.token.displayName)} className="token-icon" />
            <span className="amount-result">{amount(bet?.amount)}</span>
          </div>
        </div>
        <div className="payout">
          <p className="amount-title">{translate('popups.bets.details.payout')}</p>
          <div className="amount-subtitle">
            <img src={getImgSrc(bet?.token.displayName)} alt="icon" className="token-icon" />
            <span className="amount-result">{amount(bet?.payout)}</span>
          </div>
        </div>
      </div>
      <div className="screen-limbo">
        <div className="screen-limbo--content">
          <img src={limboPopUp} alt="limbo" className="screen-limbo--content-image" />
          <p className={bigThen(data?.payout, '0') ? 'screen-limbo--content-vin' : 'screen-limbo--content-lose'}>
            {bet?.result?.coefficient}x
          </p>
        </div>
        <div className="screen__mines-detail-wrap">
          <AutoCheckbox value={inputValue} setValue={(val) => handleCheckbox(val)} disable />
          <div className="screen-item-limbo">
            {translate('popups.bets.limbo.stop')}: {bet?.betOn?.prediction}
          </div>
        </div>
        <div className="subtitle seeds">
          <div className="subtitle-item1">
            <p>{translate('popups.bets.details.player-seed')}:</p>
            <p>{translate('popups.bets.details.server-seed')}:</p>
          </div>
          <div className="subtitle-item2">
            <div className="seeds-text">
              <p>{bet?.playerSeed}</p>
              <CopyButton copyContent={bet?.playerSeed} />
            </div>
            <div className="seeds-text">
              <p>{bet?.serverSeed}</p>
              <CopyButton copyContent={bet?.serverSeed} />
            </div>
          </div>
        </div>
        <div className="verify-button-wrapper">
          <Button
            className="verify-button"
            title={translate('popups.bets.details.verify')}
            variant={ButtonType.primary}
            onclick={handleVerify}
            width="100px"
          />
        </div>
      </div>
    </LimboPopUpStyle>
  );
};

export default LimboBetDetails;
