import styled from 'styled-components';
import { IInputCustomStyled, IInputCustomStyleWrap } from './types';

export const InputCustomWrap = styled.div.withConfig({
  shouldForwardProp: (prop) => !['inputwidth'].includes(prop),
})<IInputCustomStyleWrap>`
  width: ${(props) => (props.inputwidth ? props.inputwidth : '288px')};
`;

export const InputCustomStyled = styled.input.withConfig({
  shouldForwardProp: (prop) => !['bordercolor'].includes(prop),
})<IInputCustomStyled>`
  padding: ${(props) => (props.icon ? '6px 50px' : '6px 20px')};
  border: ${(props) => {
    if (props.errormessage) return '2px solid #f02f41';
    if (props.bordercolor) return `2px solid ${props.bordercolor}`;
    return 'none';
  }};
  filter: ${(props) => props.errormessage && 'drop-shadow(0px 0px 1px rgba(240, 47, 65, 0.5))'};
`;
