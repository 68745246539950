import styled from 'styled-components';

const TablesStyleCoin = styled.div`
  max-width: 1339px;
  width: 100%;
  height: 100%;
  margin: 64px 0 135px 0;
  position: relative;
  color: white;

  .table__body {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 30px;
    flex-direction: column;
  }
  .table__body1 {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .table__body3 {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .table__empty-body {
    display: flex;
    width: 100%;
    height: 100px;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 24px;
    color: #ffffff;
  }
  .table__pagination {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .table__pagination-mob {
    display: none;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 1500px) {
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 900px) {
  }

  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
    .table__body {
      height: 100%;
    }

    .table__empty-body {
      font-size: 18px;
    }

    margin: 0 0 100px 0;
  }
  @media (max-width: 580px) {
    .table {
      margin: 0;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 400px) {
    .table__empty-body {
      font-size: 18px;
    }
  }
`;

export default TablesStyleCoin;
