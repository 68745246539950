import { useContext, useEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { authenticator } from 'otplib';
import QRCode from 'qrcode';

import copyIcon from 'assets/img/common/copyIcon.svg';

import { ContextPopUps } from 'context';
import { CREATE_2FA_AUTH_QQCODE } from 'graphQl/query/auth/auth';
import { popUps } from 'components/constants/constants';
import { GET_USER } from 'graphQl/query/auth/profile';
import { ENABLE_GOOGLE_MFA } from 'graphQl/mutations/security/security';
import { SecurityCodeContainer } from './styles';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import ButtonSubmit from 'components/Base/PopUps/components/ButtonSubmit';
import Input2FA from 'components/Base/Input/Input2FA';

interface Props {
  data: {
    enabling: boolean;
    refetchUserSecurity: (setError: (value: unknown) => void, inputValue: string) => void;
  };
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Switch2FA: React.FC<Props> = ({ data }) => {
  const ref = useRef();

  const { translate } = useLocalization();

  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [savedRecoveryCode, setSavedRecoveryCode] = useState(false);
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const [showTooltip, setShowTooltip] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      setUser(res?.profile);
    },
  });
  const [getQrCode, { data: qrCode }] = useLazyQuery(CREATE_2FA_AUTH_QQCODE);
  const [enable2FA] = useMutation(ENABLE_GOOGLE_MFA);

  useEffect(() => {
    getQrCode();
  }, []);

  const handleCopy = () => {
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };

  const handleChange = (value) => {
    setError(null);
    setInputValue(value);
  };

  function handleResize() {
    setWindowDimensions(getWindowDimensions());
  }
  const handleSubmit = async () => {
    if (inputValue.length < 6) {
      setError('Code must contain 6 characters');
      return;
    }
    try {
      const response = await enable2FA({ variables: { code: inputValue } });
      data.refetchUserSecurity(setError, inputValue);
      if (response?.data) {
        setPopUpsOpen({
          modalOpen: popUps.securityCodeSuccess,
          data: {
            enabled: true,
          },
        });
      }
    } catch (err) {
      setError(err.message);
      console.log(err);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let currentUrl = window.location.href.split('/')[2];
    if (currentUrl.includes('localhost')) currentUrl = 'localhost';
    if (qrCode?.getGoogleMFASecret && user && user.email) {
      QRCode.toCanvas(
        ref.current,
        authenticator.keyuri(user.email, currentUrl, qrCode.getGoogleMFASecret.recoveryCode),
      );
    }
  }, [qrCode, user]);

  const getLinkToAuthenticator = () => {
    if (windowDimensions.width < 768) {
      return 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=uk&gl=US';
    }
    return 'https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai?hl=uk';
  };

  return (
    <SecurityCodeContainer>
      <div className="switch2faOn">
        <h2 className="switch2fa__title" style={{ color: '#fff' }}>
          {translate('popups.switch.2fa.title')}
        </h2>
        <p className="switch2faOn__subtitle">
          {translate('popups.switch.2fa.install')}{' '}
          <a className="switch2fa__link" target="_blank" rel="noreferrer" href={getLinkToAuthenticator()}>
            {translate('popups.switch.2fa.link')}
          </a>{' '}
          {translate('popups.switch.2fa.link.description')}
        </p>
        <div className="switch2fa__qrContainer">
          {qrCode?.getGoogleMFASecret && (
            <>
              <div className="switch2fa__subtitleContainer">
                <CopyToClipboard text={qrCode.getGoogleMFASecret.recoveryCode}>
                  <div className="link__body-copy">
                    <input className="link__body-copy-dkt" readOnly value={qrCode.getGoogleMFASecret.recoveryCode} />
                    <div className="link__body-copy-mob">
                      <span>{qrCode.getGoogleMFASecret.recoveryCode}</span>
                    </div>
                    <div
                      className={
                        !showTooltip ? 'link-body-button-style-tooltip' : 'link-body-button-style-tooltip open'
                      }
                    >
                      {translate('popups.switch.2fa.tooltip')}
                    </div>
                    <div className="link-body-button-style" onClick={handleCopy}>
                      <img src={copyIcon} alt="icon" />
                    </div>
                  </div>
                </CopyToClipboard>
                <p>{translate('popups.switch.2fa.tooltip.description')}</p>
              </div>
            </>
          )}
          {qrCode?.getGoogleMFASecret && <canvas ref={ref} className="switch2fa__qrImage" />}
        </div>
        {qrCode?.getGoogleMFASecret && (
          <div className="qr-code-checkbox">
            <label className="switch2fa__checkBox">
              <input type="checkbox" name="securityCheckbox" onClick={() => setSavedRecoveryCode(!savedRecoveryCode)} />
              {translate('popups.switch.2fa.checkbox')}
            </label>
          </div>
        )}
        <p className="switch2faOn__subtitle">{translate('popups.switch.2fa.checkbox.description')}</p>
        <div className="switch2fa__inputContainer">
          <Input2FA allowedCharacters="numeric" onChange={handleChange} inputClassName="input-2fa-style" />
        </div>
        <div className="switch2fa__errorContainer">{error && `${error.message || error}`}</div>
        <div>
          <ButtonSubmit
            handleButton={handleSubmit}
            variant={ButtonType.secondaryRed}
            width="100%"
            title={translate('general.buttons.submit')}
            disabled={!savedRecoveryCode}
            color="#2B3E65"
            dependencies={inputValue}
          />
        </div>
      </div>
    </SecurityCodeContainer>
  );
};
export default Switch2FA;
