import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import { CHANGELLY_OFFERS } from 'graphQl/query/buyCrypto';
import { amount, BNMultiply } from 'func/common';
import { getChangeLlyToken } from 'func/prepareDataPopUps';

import { IBuyCryptoRate } from './types';
import BuyCryptoRateLayout from './buyCryptoRateLayout';
import { IOffersResponse } from '../../types';

const BuyCryptoRate: React.FC<IBuyCryptoRate> = ({
  selectedCrypto,
  selectedFiat,
  sendAmount,
  provider,
  setGetAmountValue,
}) => {
  const [rate, setRate] = useState('0');
  const [errorMessage, setErrorMessage] = useState('');

  const [changellyFiatOffers] = useLazyQuery(CHANGELLY_OFFERS, { fetchPolicy: 'no-cache' });

  const handleRate = async (currencyFrom: string, currencyTo: string, amountFrom: string) => {
    if (!amountFrom) {
      setGetAmountValue('0');
      return;
    }

    const properCurrencyTo = getChangeLlyToken(currencyTo);
    const response = await changellyFiatOffers({
      variables: { providerCode: provider, currencyFrom, currencyTo: properCurrencyTo, amountFrom },
    });

    const rate = pathOr<IOffersResponse[]>([], ['data', 'changellyFiatOffers'], response);

    if (rate.length) {
      const [newRate] = rate;

      if (newRate.errorMessage) {
        const errorDetails = newRate?.errorDetails ? newRate.errorDetails[0] : null;
        setErrorMessage(`${newRate.errorMessage} ${errorDetails?.cause || ''} ${errorDetails?.value || ''}`);
        setRate('0');
        setGetAmountValue('0');
        return;
      }
      if (newRate.invertedRate) {
        console.log(newRate.rate);
        setRate(newRate.invertedRate || '0');
        setGetAmountValue(newRate.rate ? BNMultiply(newRate.rate, amountFrom) : '0');
        setErrorMessage('');
      }
    }
  };

  useEffect(() => {
    handleRate(selectedFiat, selectedCrypto, sendAmount);
  }, [selectedCrypto, selectedFiat, sendAmount, provider]);

  return (
    <BuyCryptoRateLayout
      selectedCrypto={selectedCrypto}
      selectedFiat={selectedFiat}
      rate={String(amount(rate))}
      errorMessage={errorMessage}
    />
  );
};

export default BuyCryptoRate;
