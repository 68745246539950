import { useState } from 'react';
import clsx from 'clsx';

import StarPoolFront from './components/StarPoolFront';
import StarPoolHistory from './components/StarPoolHistory';
import styles from './styles.module.scss';

const StarPool: React.FC = () => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <div className={styles.starPool}>
      <div className={clsx(styles.pool, isFlipped ? styles.flippedPool : '')}>
        <div className={clsx(styles.side, styles.front)}>
          <StarPoolFront setIsFlipped={setIsFlipped} isFlipped={isFlipped} />
        </div>
        <div className={clsx(styles.side, styles.back)}>
          <StarPoolHistory setIsFlipped={setIsFlipped} isFlipped={isFlipped} />
        </div>
      </div>
    </div>
  );
};

export default StarPool;
