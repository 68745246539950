import { useState, useEffect, useContext } from 'react';

import DiceGameContext from 'context/contextDiceGame/context';

import GameBetButton from 'components/Games/base/GameBetButton';
import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';
import { IBetButton } from './types';

const BetButton: React.FC<IBetButton> = ({ auto, autoStarted, disabled, onBet, onAutoBet }) => {
  const { translate } = useLocalization();
  const { withHotKeys } = useContext(DiceGameContext);
  const [title, setTitle] = useState(translate('common.games.bet.button'));

  useEffect(() => {
    if (auto) {
      if (autoStarted) {
        setTitle(translate('common.games.stop.auto.bet'));
      } else {
        setTitle(translate('common.games.start.auto.bet'));
      }
    } else {
      setTitle(translate('common.games.bet.button'));
    }
  }, [auto, autoStarted]);

  const handleBet = () => {
    if (auto) {
      onAutoBet();
    } else {
      onBet();
    }
  };

  return (
    <div className={styles.button}>
      <GameBetButton onClick={handleBet} disable={disabled} isHotkeys={withHotKeys}>
        <h4>{title}</h4>
      </GameBetButton>
    </div>
  );
};

export default BetButton;
