import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import { IBigWin } from 'types';
import { BIG_WINS } from 'graphQl/bigwins';
import { prepareBigWins } from 'func/prepareDataHomePage';
import { BIG_WINS_TABLE_TITLS } from 'components/constants/table';

import TableWithoutPagination from 'components/Base/Table/TableWithoutPagination/TableWithoutPagination';

const BigWins: React.FC = () => {
  const [bigWins, setBigWins] = useState<IBigWin[]>([]);

  const { data } = useQuery(BIG_WINS);

  useEffect(() => {
    if (data) {
      const newBigWins = pathOr<IBigWin[]>([], ['bigWins'], data);

      setBigWins(newBigWins);
    }
  }, [data]);

  return (
    <TableWithoutPagination
      thWidth="25%"
      titles={BIG_WINS_TABLE_TITLS}
      prepareDataTable={prepareBigWins}
      data={bigWins}
      cutCurrency
      config="topPlayers"
    />
  );
};

export default BigWins;
