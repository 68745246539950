import { lazy } from 'react';

import { Links } from 'components/constants';

import ReferralPage from 'components/Pages/ReferralPage';
import DividendsPage from 'components/Pages/DividendsPage';
import CashbackPage from 'components/Pages/CashbackPage';
import HomePage from 'components/Pages/HomePage';
import GamesPage from 'components/Pages/GamesPage';
import GameScreenPage from 'components/Pages/GameScreenPage';
import Tournaments from 'components/Pages/EventsPage/components/Tournaments';
import StarWars from 'components/Pages/EventsPage/components/StarWars';
import Limbo from 'components/Games/Limbo';
import CoinFlip from 'components/Games/CoinFlip';
import DiceGame from 'components/Games/Dice';
import Keno from 'components/Games/Keno';
import Mines from 'components/Games/Mines';
import NotFoundPage from 'components/Pages/NotFound';
import ErrorPage from 'components/Pages/ErrorPage';
import BlogPage from 'components/Pages/BlogPage';
import BlogPostPage from 'components/Pages/BlogPostPage';
import FAQPage from 'components/Pages/FAQPage';
import FAQCategoryPage from 'components/Pages/FAQCategoryPage';
import VipPage from 'components/Pages/VipPage';

import { IRouterElement, IRouterRedirectElement } from './types';
import { updatePathWithLanguageRoute } from './utils';

const MyAccountPage = lazy(async () => import('components/Pages/MyAccountPage'));
const TransactionsPage = lazy(async () => import('components/Pages/TransactionsPage'));
const BetsPage = lazy(async () => import('components/Pages/BetsPage'));
const SecurityPage = lazy(async () => import('components/Pages/SecurityPage'));
const WhitePaperPage = lazy(async () => import('components/Pages/WhitePaperPage'));
const TermsOfServicePage = lazy(async () => import('components/Pages/TermsOfServicePage'));
const BonusPage = lazy(async () => import('components/Pages/BoonusPage'));
const AffiliatesPage = lazy(async () => import('components/Pages/AffiliatesPage'));
const RoadmapPage = lazy(async () => import('components/Pages/Roadmap'));
const SpecialEventPage = lazy(async () => import('components/Pages/SpecialEventPage'));
const RoulettePage = lazy(async () => import('components/Pages/RoulettePage'));
const LivePage = lazy(async () => import('components/Pages/LivePage'));
const SlotsSeoPage = lazy(async () => import('components/Pages/SlotsSeoPage'));
const BlackjackSeoPage = lazy(async () => import('components/Pages/BlackjackSeoPage'));
const TournamentPage = lazy(async () => import('components/Pages/TournamentPage'));
const MissionsPage = lazy(async () => import('components/Games/Missions'));
const DashboardPage = lazy(async () => import('components/Pages/DashboardPage'));
const PromoMaterialsPage = lazy(async () => import('components/Pages/PromoMaterialsPage'));
const BlackJackGame = lazy(async () => import('components/Games/BlackJack'));
const TexasHoldemGame = lazy(async () => import('components/Games/TexasHoldem'));
const BitcoinSeoPage = lazy(async () => import('components/Pages/BitcoinPage'));
const UKSeoPage = lazy(async () => import('components/Pages/UKSeoPage'));
const EthereumSeoPage = lazy(async () => import('components/Pages/EthereumPage'));
const LandingJoinPage = lazy(async () => import('components/Pages/LandingJoinPage'));
const UsdtSeoPage = lazy(async () => import('components/Pages/UsdtPage'));
const DiceSeoPage = lazy(async () => import('components/Pages/DiceSeoPage'));
const LandingSignupBonus = lazy(async () => import('components/Pages/LandingSignupBonusPage'));
const PlinkoGame = lazy(async () => import('components/Games/Plinko'));

const MAIN_ROUTES: IRouterElement[] = [
  { path: Links.home, component: HomePage },
  { path: Links.games, component: GamesPage },
  { path: Links.dividends, component: DividendsPage },
  { path: Links.cashback, component: CashbackPage },
  { path: Links.referral, component: ReferralPage },
  { path: Links.dashboard, component: DashboardPage },
  { path: Links.account, component: MyAccountPage },
  { path: Links.transactions, component: TransactionsPage },
  { path: Links.bets, component: BetsPage },
  { path: Links.security, component: SecurityPage },
  { path: Links.bonus, component: BonusPage },
  { path: Links.affiliates, component: AffiliatesPage },
  { path: Links.missions, component: MissionsPage },
  { path: Links.roadmap, component: RoadmapPage },
  { path: Links.tournament, component: TournamentPage },
  { path: Links.specialEvent, component: SpecialEventPage },
  { path: Links.slots, component: GameScreenPage },
  { path: Links.vip, component: VipPage },
  { path: Links.promoMaterials, component: PromoMaterialsPage },
  { path: Links.whitepaper, component: WhitePaperPage },
  { path: Links.terms, component: TermsOfServicePage },
];

export const SERVICE_ROUTES: IRouterElement[] = [
  { path: Links.error, component: ErrorPage },
  { path: Links.notFound, component: NotFoundPage },
];

const SEO_ROUTES: IRouterElement[] = [
  { path: Links.seoBlackJack, component: BlackjackSeoPage },
  { path: Links.seoBitcoin, component: BitcoinSeoPage },
  { path: Links.seoEthereum, component: EthereumSeoPage },
  { path: Links.slotsSeo, component: SlotsSeoPage },
  { path: Links.roulette, component: RoulettePage },
  { path: Links.live, component: LivePage },
  { path: Links.tetherUsdt, component: UsdtSeoPage },
  { path: Links.diceSeo, component: DiceSeoPage },
  { path: Links.ukSeo, component: UKSeoPage },
];

const FAQ_ROUTES: IRouterElement[] = [
  { path: Links.faq, component: FAQPage },
  { path: Links.faqCategory, component: FAQCategoryPage },
];

export const GAMES_ROUTES: IRouterElement[] = [
  { path: Links.dice, component: DiceGame },
  { path: Links.mines, component: Mines },
  { path: Links.limbo, component: Limbo },
  { path: Links.coinflip, component: CoinFlip },
  { path: Links.keno, component: Keno },
  { path: Links.blackJack, component: BlackJackGame },
  { path: Links.blackJackVip, component: BlackJackGame },
  { path: Links.texasHoldem, component: TexasHoldemGame },
  { path: Links.texasHoldemVip, component: TexasHoldemGame },
  { path: Links.plinko, component: PlinkoGame },
];

export const EVENTS_ROUTES: IRouterElement[] = [
  { path: Links.starwars, component: StarWars },
  { path: Links.tournaments, component: Tournaments },
];

export const BLOG_ROUTES: IRouterElement[] = [
  { path: Links.blog, component: BlogPage },
  { path: Links.blogPost, component: BlogPostPage },
];

export const REDIRECT_ROUTES: IRouterRedirectElement[] = [
  { path: Links.inHouseMines, redirectsTo: Links.mines, replace: true, param: 'lang' },
  { path: Links.inHouseLimbo, redirectsTo: Links.limbo, replace: true, param: 'lang' },
  { path: Links.inHouseCoinflip, redirectsTo: Links.coinflip, replace: true, param: 'lang' },
  { path: Links.inHouseKeno, redirectsTo: Links.keno, replace: true, param: 'lang' },
  { path: Links.inHousePlinko, redirectsTo: Links.plinko, replace: true, param: 'lang' },
];

export const LANDING_ROUTES: IRouterElement[] = [
  { path: Links.landingJoin, component: LandingJoinPage },
  { path: Links.landingSignupBonus, component: LandingSignupBonus },
];

export const DEFAULT_ROUTES = [FAQ_ROUTES, SEO_ROUTES, MAIN_ROUTES, SERVICE_ROUTES].flat(1);
export const ROUTES_WITHOUT_LANGUAGE = [SERVICE_ROUTES, BLOG_ROUTES].flat(1);
export const ALL_ROUTES_WITH_LANGUAGE = [MAIN_ROUTES, SEO_ROUTES, GAMES_ROUTES, EVENTS_ROUTES]
  .flat(1)
  .map((r) => ({ path: updatePathWithLanguageRoute(r.path) }));
