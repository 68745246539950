import { useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';

import slotsIcon from 'assets/img/GamesFiler/Slots-active.webp';
import inHouseIcon from 'assets/img/GamesFiler/In-House-active.webp';
import tableIcon from 'assets/img/GamesFiler/Table-active.webp';
import liveIcon from 'assets/img/GamesFiler/live-active.webp';

import { CATEGORY_NAMES, GAMES_QUALITY } from 'components/constants/constants';
import { GET_GAMES_SLIDER } from 'graphQl/query/games/gameQueries';
import { Links } from 'components/constants';
import { useAppSelector } from 'hooks/useAppSelector';
import { getFormattedGames } from 'func/prepareDataGamesPage';
import { ContextPopUps } from 'context';
import { userProfile, userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';
import { useNavigate } from 'hooks/useNavigate';

import GamesBlock from './components/GamesBlock';

import styles from './styles.module.scss';

const GamesCardsSlider: React.FC = () => {
  const navigate = useNavigate();
  const { translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [slotsGames, setSlotsGames] = useState(null);
  const [inHouseGames, setInHouseGames] = useState(null);
  const [tableGames, setTableGames] = useState(null);
  const [liveGames, setLiveGames] = useState(null);

  const user = useAppSelector(userProfile);
  const token = useAppSelector(userToken);

  useQuery(GET_GAMES_SLIDER, {
    fetchPolicy: 'no-cache',
    skip: !(!token || (token && user)),
    variables: {
      categoryGameListParams: {
        limit: 20,
        userId: user?.id,
      },
    },
    onCompleted: (data) => {
      setInHouseGames(getFormattedGames(data.categoryGameList.InHouse, navigate, setPopUpsOpen, translate));
      setSlotsGames(getFormattedGames(data.categoryGameList.Slots, navigate, setPopUpsOpen, translate));
      setTableGames(getFormattedGames(data.categoryGameList.TableGames, navigate, setPopUpsOpen, translate));
      setLiveGames(getFormattedGames(data.categoryGameList.LiveCasino, navigate, setPopUpsOpen, translate));
    },
  });

  const handleSlots = (id: string) => {
    navigate({ pathname: Links.games, search: `${GAMES_QUALITY.categories.id}=${id}` });
    scroll.scrollTo(0, { duration: 0, delay: 0 });
  };

  if (!slotsGames || !inHouseGames || !tableGames || !liveGames) return null;

  return (
    <div className={styles.gamesSliderWrap}>
      <GamesBlock
        games={inHouseGames}
        icon={inHouseIcon}
        title="games.nav.inhouse"
        onView={() => handleSlots(CATEGORY_NAMES.inHouse.urlTitle)}
      />
      <GamesBlock
        games={slotsGames}
        icon={slotsIcon}
        title="games.nav.slots"
        onView={() => handleSlots(CATEGORY_NAMES.slots.urlTitle)}
      />
      <GamesBlock
        games={tableGames}
        icon={tableIcon}
        title="homepage.games.table"
        onView={() => handleSlots(CATEGORY_NAMES.tableGames.urlTitle)}
      />
      <GamesBlock
        games={liveGames}
        icon={liveIcon}
        title="homepage.games.live"
        onView={() => handleSlots(CATEGORY_NAMES.liveCasino.urlTitle)}
      />
    </div>
  );
};

export default GamesCardsSlider;
