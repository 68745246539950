import styled from 'styled-components';
import { generate as id } from 'shortid';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';

import { GameElem } from 'types/requestTypes';
import { useAppSelector } from 'hooks/useAppSelector';
import { EBonus } from 'components/constants/games';
import { getMyActiveBonuses } from 'func/prepareDataBonus';
import { dataIsDisplayBonus, dataUserBonuses } from 'store/data/data.selectors';

import Slider from 'components/Base/Slider';
import GamesSlideButtonPrev from 'components/common/GamesSlideButton/gamesSlideButtonPrev';
import GamesSlideButtonNext from 'components/common/GamesSlideButton/gamesSlideButtonNext';
import GamesSlide from './gamesSlide';

import styles from './styles.module.scss';
import { IGamesSlider } from './types';

const GamesSlider: React.FC<IGamesSlider> = ({ block = '', games, onLike }) => {
  const mobile = useMediaQuery({ query: '(max-width: 550px)' });
  const tablet = useMediaQuery({ query: '(max-width: 1200px)' });

  const [sliderKey, setSliderKey] = useState(id());
  const [slidesShow, setSlidesShow] = useState(4);
  const [gamesSlides, setGamesSlides] = useState<GameElem[][]>([]);
  const [bonusIsActive, setBonusIsActive] = useState(false);

  useEffect(() => {
    const desktopView = !mobile && !tablet;
    const tabletView = tablet && !mobile;

    if (desktopView) {
      setSlidesShow(4);
      return;
    }

    if (tabletView) {
      setSlidesShow(3);
      return;
    }

    setSlidesShow(2);
  }, [mobile, tablet]);

  useEffect(() => {
    const newGamesSlides = games.reduce<GameElem[][]>((acc, game, i) => {
      const newSlide = i % slidesShow === 0;

      if (newSlide) {
        return [...acc, [game]];
      }

      const lastSlide = acc[acc.length - 1];

      if (!lastSlide) return acc;

      lastSlide.push(game);

      return acc;
    }, []);

    setGamesSlides(newGamesSlides);
    setSliderKey(id());
  }, [slidesShow, games]);

  const userBonuses = useAppSelector(dataUserBonuses);
  const isDisplayBonus = useAppSelector(dataIsDisplayBonus);

  useEffect(() => {
    if (isDisplayBonus === EBonus.available && userBonuses) {
      const currentBonuses = getMyActiveBonuses(userBonuses);

      setBonusIsActive(Boolean(currentBonuses?.length));
      return;
    }

    setBonusIsActive(false);
  }, [isDisplayBonus, userBonuses]);

  return games.length ? (
    <StyledSlider
      key={sliderKey}
      className={styles.wrapper}
      showArrows
      infiniteLoop
      renderArrowPrev={(onPrev, hasPrev, label) => (
        <GamesSlideButtonPrev label={label} hasPrev={hasPrev} onPrev={onPrev} />
      )}
      renderArrowNext={(onNext, hasNext, label) => (
        <GamesSlideButtonNext label={label} hasNext={hasNext} onNext={onNext} />
      )}
    >
      {gamesSlides.map((g, i) => (
        <GamesSlide
          key={`${block}-${i}`}
          games={g}
          slidesToShow={slidesShow}
          bonusIsActive={bonusIsActive}
          onLike={onLike}
        />
      ))}
    </StyledSlider>
  ) : null;
};

export default GamesSlider;

const StyledSlider = styled(Slider)`
  .carousel.carousel-slider {
    position: unset;
    overflow: unset;
  }
`;
