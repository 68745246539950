export enum BuyTicketSize {
  BIG = 'big',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export interface IBuyTicketBlock {
  size: BuyTicketSize;
  inputValue: number;
  ticketPrice: number;
  disable: boolean;
  disabledInput: boolean;
  onChange: (el: ((prev) => number) | number) => void;
  onSubmit: () => void;
}
