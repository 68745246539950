import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

const WalletNavigationStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: auto;
  background: #242c45;
  min-height: 600px;
  border-radius: 20px;

  .wallet__nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40%;

    z-index: 1;
  }

  .wallet__nav--title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0 0 16px;
    width: 251px;
    min-width: 250px;
    height: 59px;
    cursor: url(${point}), auto !important;

    color: #ffffff;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.12px;
    //opacity: 0.5;

    img {
      width: 30px;
      height: 30px;
      display: inline-block;
      margin: 0 20px;
    }

    h5 {
      margin: 0;
      color: #424862;
      text-align: left;
    }
  }

  .wallet__nav--title.active {
    opacity: 1;
    //background: linear-gradient(90deg, #3856DD 3.45%, #7A2CFF 98.28%);
    background: linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
    mix-blend-mode: normal;
    border-radius: 19px;
    h5 {
      color: #ffffff;
    }
  }

  .wallet__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: auto;
    padding: 30px;
    z-index: 2;
    height: 600px;

    border-left: 2px solid #414358;
    overflow: hidden;
    overflow-y: auto;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    padding-top: 0;
    border-radius: 19px;
    height: 630px;

    .wallet__content {
      border-left: transparent;
      height: 545px;
    }

    .wallet__nav {
      width: 85%;
      margin: 0 auto;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .wallet__nav--title {
      min-width: 52px;
      height: 52px;
      width: 52px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      background: #242c45;
      border: none;
      border-radius: 4px;
      margin: 29px 0 0 16px;

      line-height: 17px;

      letter-spacing: 0.12px;

      mix-blend-mode: normal;

      img {
        display: inline-block;
        width: 22px;
        height: 22px;
        margin: 0;
      }

      h5 {
        font-size: 9px;
        height: 10px;
        overflow: hidden;
        margin-top: 3px;
        text-align: center;
      }
      &:first-of-type {
        margin-left: 0;
      }
    }
    .wallet__nav--title.active {
      background: #242c45;
      position: relative;
      min-width: 52px;
      height: 52px;
      width: 52px;
      border: none;
      border-radius: 4px;
      margin-top: 29px;
    }
    .wallet__nav--title.active::before {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      background: linear-gradient(180deg, #0e9628 0%, #7db30a 100%);
      width: 60px;
      height: 60px;
      z-index: -1;
      border-radius: 8px;
    }
    .wallet__nav--title::before {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      background: rgba(136, 138, 167, 0.3);
      width: 60px;
      height: 60px;
      z-index: -1;
      border-radius: 8px;
    }

    .wallet__content {
      width: 100%;
    }
  }

  @media (max-width: 540px) {
    .wallet__content {
      padding: 15px 15px 30px;
    }
  }

  @media screen and (max-width: 540px) {
    padding-top: 10px;
  }
`;

export default WalletNavigationStyle;
