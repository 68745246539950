import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { GET_DIVIDENDS_COLLECTIONS } from 'graphQl/query/dividends/dividendsCillections';
import { prepareDividendCollections } from 'func/prepareDataDividends';
import TableWithoutPagination from 'components/Base/Table/TableWithoutPagination/TableWithoutPagination';
import { TABLE_CLASSNAMES } from 'components/constants/constants';
import { useLocalization } from 'components/Internationalization';

const MyCollections: React.FC = () => {
  const { translate, language } = useLocalization();
  const [titles, setTitles] = useState([]);

  const { data } = useQuery(GET_DIVIDENDS_COLLECTIONS, {
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    const newTitles = [
      { title: translate('dividends.tabs.all.dividends.date'), columnClass: TABLE_CLASSNAMES.column.default },
      { title: translate('dividends.tabs.all.dividends.amount'), columnClass: TABLE_CLASSNAMES.column.default },
    ];

    setTitles(newTitles);
  }, [language]);

  return (
    <TableWithoutPagination
      thWidth="50%"
      titles={titles}
      prepareDataTable={prepareDividendCollections}
      data={data?.dividendCollections}
      cutCurrency
    />
  );
};

export default MyCollections;
