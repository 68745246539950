import { animateScroll as scroll } from 'react-scroll';

import linkImage from 'assets/img/common/external-link.webp';

import Link from 'components/Base/Links/Link';

import styles from './styles.module.scss';
import { IInfoBlockAction } from './types';

const InfoBlockAction: React.FC<IInfoBlockAction> = ({ action }) => {
  if (action.external) {
    return (
      <a className={styles.footerInfoBlockItem} href={action.url} target="_blank" rel="noreferrer">
        {action.name}
        <img src={linkImage} alt="link" />
      </a>
    );
  }

  if (action.url.includes('/')) {
    const handleClick = () => {
      scroll.scrollTo(0, { duration: 0, delay: 0 });
    };

    return (
      <Link className={styles.footerInfoBlockItem} to={action.url} onClick={handleClick}>
        {action.name}
      </Link>
    );
  }

  return (
    <button type="button" className={styles.footerInfoBlockItem} onClick={action.func}>
      {action.name}
    </button>
  );
};

export default InfoBlockAction;
