import { useEffect, useState } from 'react';

import useWindowSize from 'hooks/useWindowSize';

import Table from 'components/Base/Table';
import Pagination from 'components/Base/Pagination';
import EmptyTable from 'components/Base/EmptyTable';

import { IEventsTable } from './types';

const EventsTable: React.FC<IEventsTable> = ({
  setPerPage,
  setDataTable,
  perPage,
  prepareData,
  dataTable,
  titles,
  tablePopUp,
}) => {
  const [windowSize] = useWindowSize();
  const [pageDisplayed, setPageDisplayed] = useState(4);
  const [currentPage, setCurrentPage] = useState(null);
  const [pageMargin, setPageMargin] = useState(1);

  useEffect(() => {
    if (currentPage === 1) setCurrentPage(0);
    setDataTable(prepareData.slice(currentPage === 1 ? 0 : currentPage, +currentPage + perPage));
  }, [prepareData]);
  useEffect(() => {
    if (windowSize < 650) {
      setPerPage(10);
      setPageDisplayed(2);
      setPageMargin(0);
    }
    if (windowSize > 651) {
      setPerPage(10);
    }
  }, [windowSize]);
  const onPageChanged = (elem: number) => {
    if (elem === 1) elem = 0;
    setDataTable(prepareData.slice(elem, elem + perPage));
  };
  if (dataTable.length < 1) {
    return (
      <div className="table__events-body1">
        <EmptyTable lines={2} marginTop="32px" />
      </div>
    );
  }
  return (
    <div className="table__events-body">
      <>
        {' '}
        <div className="table__events-body-style">
          <Table data={dataTable} titles={titles} thWidth="25%" config="place" tablePopUp={tablePopUp} />
        </div>
        {prepareData.length > perPage && (
          <Pagination
            itemsLength={prepareData.length}
            perPage={perPage}
            pageDisplayed={pageDisplayed}
            setCurrentPage={setCurrentPage}
            onPageChanged={onPageChanged}
            pageMargin={pageMargin}
            popUpTable
          />
        )}{' '}
      </>
    </div>
  );
};

export default EventsTable;
