import { generate as id } from 'shortid';

import { IKenoOddsItem } from 'components/Games/Keno/components/KenoOdds/types';
import { EKenoRisk, EKenoSquareClass, KenoCondition } from 'components/constants/games';
import { IGameSettings, IKenoBet, IKenoSquare, IWinInfo } from 'context/contextKeno/types';
import { HistoryElem, TranslationType } from 'types';
import { kenoOddsList } from 'components/Games/Keno/components/KenoOdds/kenoOddsList';
import { bigThen, lessThen } from './prepareDataDice';
import { removeComas } from './common';

export const getKenoRisk = (risk: string): string => {
  const newStr = risk.replace('Risk', '').replace(' ', '');
  return newStr.toLowerCase();
};

export const getOddsList = (length: number, risk: string): IKenoOddsItem[] => {
  const currentOdds = kenoOddsList[getKenoRisk(risk)];
  const oddsList = currentOdds[length];
  const result = new Array(length ? length + 1 : length).fill(null).map((_, ind) => ({
    id: id(),
    hits: ind,
    ods: oddsList[ind],
    isActive: false,
  }));
  return result;
};

export const getKenoSquares = (): IKenoSquare[] => {
  const result = new Array(40).fill(null).map((_, ind) => ({
    id: id(),
    index: ind,
    className: '',
    selected: false,
  }));
  return result;
};

const getOppositeClassName = (name): string => {
  switch (name) {
    case EKenoSquareClass.win:
      return EKenoSquareClass.fastWin;
    case EKenoSquareClass.fastWin:
      return EKenoSquareClass.win;
    case EKenoSquareClass.lose:
      return EKenoSquareClass.fastLose;
    case EKenoSquareClass.fastLose:
      return EKenoSquareClass.lose;
    case EKenoSquareClass.pick:
      return EKenoSquareClass.fastPick;
    case EKenoSquareClass.fastPick:
      return EKenoSquareClass.pick;
    default:
      return '';
  }
};

export const getSquareListOnChangeMode = (list: IKenoSquare[]): IKenoSquare[] =>
  list.map((item) => ({
    ...item,
    className: getOppositeClassName(item.className),
  }));

export const setSelectedSquare = (squaresList: IKenoSquare[], index: number, fastMode: boolean): IKenoSquare[] => {
  const result = squaresList.map((item) => {
    if (item.index === index) {
      return {
        ...item,
        selected: !item.selected,
        className: !item.selected ? (fastMode ? EKenoSquareClass.fastPick : EKenoSquareClass.pick) : '',
      };
    }
    return item;
  });
  return result;
};

export const setSelectedSquareAutoPick = (
  squaresList: IKenoSquare[],
  list: number[],
  fastMode: boolean,
): IKenoSquare[] => {
  const result = squaresList.map((item) => {
    if (list.includes(item.index)) {
      return {
        ...item,
        selected: true,
        className: fastMode ? EKenoSquareClass.fastPick : EKenoSquareClass.pick,
      };
    }
    return {
      ...item,
      selected: false,
      className: '',
    };
  });
  return result;
};

export const setSelectedSquareList = (
  selectedSquaresList: number[],
  index: number,
  squaresList: IKenoSquare[],
): number[] => {
  const currentSquare = selectedSquaresList.find((el) => el === index);
  if (currentSquare) {
    return selectedSquaresList.filter((el) => el !== index);
  }
  const newEl = squaresList.find((el) => el.index === index);
  return [...selectedSquaresList, newEl.index];
};

export const setSquareListStartBet = (list: IKenoSquare[], fastMode: boolean): IKenoSquare[] =>
  list.map((item) => ({
    ...item,
    className: item.selected ? (fastMode ? EKenoSquareClass.fastPick : EKenoSquareClass.pick) : '',
  }));

export const setSquaresBetResult = (list: IKenoSquare[], winList: number[], fastMode: boolean): IKenoSquare[] =>
  list.map((item) => {
    if (item.selected) {
      if (winList.includes(item.index)) {
        return {
          ...item,
          className: fastMode ? EKenoSquareClass.fastWin : EKenoSquareClass.win,
        };
      }
      return { ...item };
    }
    if (winList.includes(item.index)) {
      return {
        ...item,
        className: fastMode ? EKenoSquareClass.fastLose : EKenoSquareClass.lose,
      };
    }
    return item;
  });

export const setSquareListForBetDetails = (winArr: number[], betArr: number[]): IKenoSquare[] => {
  const defSquares = getKenoSquares();
  return defSquares.map((el) => ({
    ...el,
    className:
      betArr.includes(el.index) && winArr.includes(el.index)
        ? EKenoSquareClass.detailWin
        : betArr.includes(el.index)
        ? EKenoSquareClass.detailPick
        : winArr.includes(el.index)
        ? EKenoSquareClass.detailLose
        : '',
  }));
};

export const getWinInfo = (bet: IKenoBet, displayName: string, selectedSquareList: number[]): IWinInfo => {
  let hits = 0;
  bet.arr.forEach((el) => selectedSquareList.includes(el) && (hits = hits + 1));
  return {
    id: id(),
    multiplier: `${bet.multiplier}`,
    payout: bet.payout,
    displayName,
    oddsNum: hits,
  };
};

export const setHistory = (
  list: HistoryElem[],
  bet: IKenoBet,
  betAmountValue: string,
  displayName: string,
): HistoryElem[] => {
  const date = new Date();
  const newEl = {
    createdAt: date,
    id: bet.id,
    betId: bet.id,
    amount: betAmountValue,
    multiplier: `${bet.multiplier}x`,
    payout: bet.payout,
    displayName,
    result: bigThen(bet.multiplier, 0) ? 'win' : 'lose',
    num: `${bet.multiplier}x`,
  };
  return [newEl, ...list.slice(0, 20)];
};

export const getGameSettings = (gameSettings: IGameSettings): IGameSettings => {
  const currentGameSettings = { ...gameSettings };
  delete currentGameSettings.betAmount;
  return currentGameSettings;
};

export const getAllHistoryKeno = (state: HistoryElem[]): HistoryElem[] =>
  state.map((el) => ({
    num: lessThen(el.payout, '0.00000001') ? '0x' : `${el.multiplier}x`,
    result: Number(el.payout) > 0 ? 'win' : 'lose',
    createdAt: el.createdAt,
    id: el.id,
    amount: el.amount,
    isAuto: el.isAuto,
    multiplier: lessThen(el.payout, '0.00000001') ? '0x' : `${el.multiplier}x`,
    payout: el.payout,
    tokenCode: el.tokenCode,
    displayName: el.displayName,
    isBlack: el.isBlack,
    betId: el.id,
  }));

export const disableKenoSquare = (gameCondition: string, square: IKenoSquare, selectedLength: number): boolean => {
  const inGame = gameCondition === KenoCondition.autoStarted || gameCondition === KenoCondition.getStarted;
  const isFullList = selectedLength >= 10;
  if (inGame) {
    return true;
  }
  if (!inGame && isFullList && !square.selected) {
    return true;
  }
  return false;
};

export const getSubmitButtonTitle = (gameCondition: string): string => {
  switch (gameCondition) {
    case KenoCondition.autoStarted:
      return 'common.games.stop.auto.bet';
    case KenoCondition.autoNotStarted:
      return 'common.games.start.auto.bet';
    default:
      return 'common.games.bet.button';
  }
};

export const disableKenoButtons = (gameCondition: string, disable: boolean): boolean => {
  if (disable) {
    return true;
  }
  if (gameCondition === KenoCondition.getStarted || gameCondition === KenoCondition.autoStarted) {
    return true;
  }
  return false;
};

export const disableKenoSubmitButtons = (gameCondition: string, disable: boolean): boolean => {
  if (gameCondition === KenoCondition.getStarted) {
    return true;
  }
  if (disable && gameCondition !== KenoCondition.autoStarted && gameCondition !== KenoCondition.autoNotStarted) {
    return true;
  }
  return false;
};

function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const getAutoPickKenoNumber = (): number[] => {
  const integerArr = [];
  while (integerArr.length < 10) {
    const rndInt = randomIntFromInterval(0, 39);
    if (!integerArr.includes(rndInt)) {
      integerArr.push(rndInt);
    }
  }
  return integerArr;
};

export const checkMaxPayKeno = (payout: string, maxPay: string): string => {
  const currentPay = removeComas(payout);
  const currentMaxPay = removeComas(maxPay);
  return bigThen(currentPay || '0', currentMaxPay || '0') ? currentMaxPay : currentPay;
};

export const getKenoRiskDrop = (t: TranslationType) => [
  {
    title: t('keno.risk.classic'),
    idTitle: EKenoRisk.classic,
  },
  {
    title: t('keno.risk.low'),
    idTitle: EKenoRisk.low,
  },
  {
    title: t('keno.risk.medium'),
    idTitle: EKenoRisk.medium,
  },
  {
    title: t('keno.risk.high'),
    idTitle: EKenoRisk.high,
  },
];

export const getKenoDropTitle = (risk: string, dropData: Array<{ idTitle: string; title: string }>): string => {
  const title = dropData.find((d) => d.idTitle === risk);
  return title ? title.title : '';
};
