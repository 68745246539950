import React, { useState } from 'react';
import { pathOr } from 'ramda';
import { useQuery } from '@apollo/client';

import { IRank, ISetting, IToken } from 'types';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  setActiveTokens,
  setExchangeRates,
  setRanksList,
  setSettings,
  setTournamentsList,
  setVipRank,
} from 'store/generalData';
import { userToken } from 'store/user/user.selectors';
import Loader from 'components/Base/Loader';
import { useAppSelector } from 'hooks/useAppSelector';
import { setUser } from 'store/user';
import { getAuthToken, getRefreshToken } from 'func/auth';

import { QUERY_LIST_FOR_AUTH } from 'graphQl/query/account/queryListForAuth';
import { QUERY_LIST_FOR_NOT_AUTH } from 'graphQl/query/account/queryListForNotAuth';

import { getSortedRankList } from 'func/prepareDataCashback';
import { getExchangeRates } from 'func/common';
import { setVipStatus } from '../store/data';

export const ContextTokenCode = React.createContext({
  tokenCode: { token: '', name: '' },
  setTokenCode: (elem: { token: string; name: string }) => {},
  blockedIp: false,
  tokenWithNetworksList: { networks: [], tokenWithNetworks: [] },
});

export const TokenCodeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();

  const [tokenWithNetworksList, setTokenWithNetworksList] = useState(null);
  const [tokenCode, setTokenCode] = useState({
    token: localStorage.getItem('tokenCode') || 'BTC',
    name: localStorage.getItem('displayName') || 'BTC',
  });
  const [loading, setLoading] = useState(true);
  const [blockedIp, setBlockedIp] = useState(false);

  const token = useAppSelector(userToken);

  // useQuery(CHECK_CONNECT, {
  //   fetchPolicy: 'cache-and-network',
  //   onCompleted: () => {
  //     setBlockedIp(false);
  //   },
  //   onError: () => {
  //     setBlockedIp(true);
  //   },
  // });

  const getVipRankId = (ranks: IRank[]) => {
    const sortedRanks = [...ranks].sort((a, b) => a.id - b.id);
    const vipRank = sortedRanks.find((r) => r.vip);
    const rankId = vipRank?.id || 11;

    return rankId;
  };

  useQuery(QUERY_LIST_FOR_NOT_AUTH, {
    skip: Boolean(token) || Boolean(getRefreshToken()),
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const ranks = pathOr<IRank[]>([], ['ranks'], data);
      const settings = pathOr<ISetting[]>([], ['getSettings'], data);
      const activeTokens = pathOr<IToken[]>([], ['activeTokens'], data);

      const vipRankId = getVipRankId(ranks);

      dispatch(setRanksList(getSortedRankList(ranks)));
      dispatch(setVipRank(vipRankId));
      dispatch(setActiveTokens(activeTokens));
      dispatch(setExchangeRates(getExchangeRates(data.exchangeRates)));
      dispatch(setTournamentsList(data.tournaments));
      setTokenWithNetworksList({
        tokenWithNetworks: data.tokenWithNetworks,
        networks: data.networks,
      });
      dispatch(setSettings(settings));
      setLoading(false);
      setBlockedIp(false);
    },
  });

  useQuery(QUERY_LIST_FOR_AUTH, {
    skip: !token || !getAuthToken(),
    variables: { tokenCode: 'STAR' },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const ranks = pathOr<IRank[]>([], ['ranks'], data);
      const settings = pathOr<ISetting[]>([], ['getSettings'], data);
      const activeTokens = pathOr<IToken[]>([], ['activeTokens'], data);

      const vipRankId = getVipRankId(ranks);

      dispatch(setUser(data?.profile));
      dispatch(setRanksList(getSortedRankList(ranks)));
      dispatch(setVipRank(vipRankId));
      dispatch(setActiveTokens(activeTokens));
      dispatch(setExchangeRates(getExchangeRates(data.exchangeRates)));
      dispatch(setTournamentsList(data.tournaments));
      dispatch(setVipStatus(data.profile.rank.vip));
      dispatch(setSettings(settings));
      setTokenWithNetworksList({
        tokenWithNetworks: data.tokenWithNetworks,
        networks: data.networks,
      });
      setLoading(false);
      setBlockedIp(false);
    },
  });

  return (
    <ContextTokenCode.Provider
      value={{
        tokenCode,
        setTokenCode,
        blockedIp,
        tokenWithNetworksList,
      }}
    >
      {loading ? <Loader /> : null}
      {children}
    </ContextTokenCode.Provider>
  );
};
