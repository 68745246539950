import React, { useEffect, useState } from 'react';
import { generate as id } from 'shortid';

import { useLocalization } from 'components/Internationalization';

import goldCoinSide from 'assets/img/CoinFlip/goldSide.webp';
import silverCoinSide from 'assets/img/CoinFlip/silverSide.webp';

import { ICoinAnimation } from './types';
import CoinAnimationStyle from './styles';

const CoinAnimation: React.FC<ICoinAnimation> = ({ isMultiplyValue, multiplier, series, history }) => {
  const { translate } = useLocalization();
  const [animationKey, setAnimationKey] = useState('');
  const [nameAnimation, setNameAnimation] = useState('');

  useEffect(() => {
    if (history.length) {
      setNameAnimation(history[0]?.animationName);
      setAnimationKey(id());
    }
  }, [history]);

  return (
    <CoinAnimationStyle ismultiplyvalue={isMultiplyValue ? 'true' : ''}>
      <div className="coin__animation--sides">
        <h4>{translate('coinflip.series.text')}</h4>
        <h5>{isMultiplyValue ? series : '-'}</h5>
      </div>
      <div id="coin" className={nameAnimation} key={animationKey}>
        <div className="side-a">
          <img src={goldCoinSide} alt="icon" className="coin__pump" />
        </div>
        <div className="side-b">
          <img src={silverCoinSide} alt="icon" className="coin__pump" />
        </div>
      </div>
      <div className="coin__animation--sides">
        <h4>{translate('coinflip.multiplier.text')}</h4>
        <h5>x{multiplier}</h5>
      </div>
    </CoinAnimationStyle>
  );
};

export default CoinAnimation;
