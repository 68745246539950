import styled from 'styled-components';

const CurrencyRowStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;

  img {
    margin-left: -5px;
    width: 45px;
    height: 45px;
  }
  h6 {
    font-size: 22px;
    color: #ffffff;
    margin: 0;
  }

  @media (max-width: 1350px) {
    h6 {
      font-size: 17px;
    }

    img {
      height: 32px;
      width: 32px;
    }
  }
  @media (max-width: 1150px) {
  }
  @media (max-width: 1024px) {
    h6 {
      font-size: 20px;
    }

    img {
      height: 40px;
      width: 40px;
    }
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
    h6 {
      font-size: 18px;
    }
    img {
      height: 35px;
      width: 35px;
    }
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 580px) {
  }
  @media (max-width: 470px) {
  }
  @media (max-width: 400px) {
    h6 {
      font-size: 18px;
    }
  }
  @media (max-width: 359px) {
    h6 {
      font-size: 16px;
    }
  }
`;

export default CurrencyRowStyle;
