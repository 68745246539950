import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { useAppSelector } from 'hooks/useAppSelector';
import { ContextTokenCode } from 'context';
import { GET_DIVIDENDS_POOL } from 'graphQl/query/dividends/dividendsPool';
import { getEstimateDropdownType, handlePeriodCalculate } from 'func/prepareDataDividends';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';
import { EDividendsDropdown } from 'components/constants/dividends';

import HighCardTitle from 'components/Base/Cards/HighCardTitle';
import DropDown from 'components/Base/DropDown';
import CurrenciesTemplate from 'components/Base/CurrensiesTemplate';
import CurrencySliderRow from 'components/Base/CurrencySlider/components/CurrencySliderRow';

import { IEstimateFrontCard } from './types';
import styles from './styles.module.scss';

const EstimatedFrontCard: React.FC<IEstimateFrontCard> = ({ percent, isFlipped, setIsFlipped }) => {
  const token = useAppSelector(userToken);
  const { language, translate } = useLocalization();

  const { tokenWithNetworksList } = useContext(ContextTokenCode);
  const [type, setType] = useState(null);
  const [estimateData, setEstimateData] = useState(null);
  const [balance, setBalance] = useState([]);
  const [dropdownInfo, setDropdownInfo] = useState([]);
  const [tokenData, setTokenData] = useState(null);
  const [usdData, setUsdData] = useState(null);

  const [dropTitles, setDropTitles] = useState([]);

  useQuery(GET_DIVIDENDS_POOL, {
    onCompleted: (data) => {
      setEstimateData(data?.dividendPool);
    },
  });

  const handleFlip = () => setIsFlipped(!isFlipped);

  const handleDaily = () => handlePeriodCalculate(Number(percent), 1, estimateData, setBalance, setUsdData);

  const handleMonthly = () => handlePeriodCalculate(Number(percent), 30, estimateData, setBalance, setUsdData);
  // setBalance(amount(((Number(btcPercent) / 50) * percent * 30) / 100));   // origin formula

  const handleQuarterly = () => handlePeriodCalculate(Number(percent), 90, estimateData, setBalance, setUsdData);

  useEffect(() => {
    if (tokenWithNetworksList && dropTitles.length) {
      const tokens = tokenWithNetworksList.tokenWithNetworks.filter((elem) => elem.tokenCode !== 'STAR');
      setTokenData(tokens);
      const dropDownData = dropTitles.map((elem, index) => ({
        title: elem,
        func: () => setType(getEstimateDropdownType(index)),
      }));
      setDropdownInfo(dropDownData);
    }
  }, [tokenWithNetworksList, dropTitles]);

  useEffect(() => {
    if (estimateData && percent) {
      switch (type) {
        case EDividendsDropdown.daily:
          return handleDaily();
        case EDividendsDropdown.monthly:
          return handleMonthly();
        case EDividendsDropdown.quarterly:
          return handleQuarterly();
        default:
          return handleDaily();
      }
    }
  }, [estimateData, percent, type]);

  useEffect(() => {
    const dailyText = translate('dividends.estimated.daily');
    const monthlyText = translate('dividends.estimated.monthly');
    const quarterlyText = translate('dividends.estimated.quarterly');

    setDropTitles([dailyText, monthlyText, quarterlyText]);
  }, [language]);

  if (!tokenData && !dropdownInfo) return null;

  return (
    <HighCardTitle
      title={translate('dividends.estimated.title.desktop')}
      subtitle={translate('dividends.estimated.description')}
      offBlur
      titleMob={translate('dividends.estimated.title.mobile')}
      blurIfNotAuth
    >
      {token && (
        <button className="fip-card-button" type="button" onClick={handleFlip}>
          {translate('dividends.estimated.flip.button.front')}
        </button>
      )}
      <div className={styles.estimateFront}>
        <div className={styles.estimateFrontDropdown}>
          <div className={styles.estimateFrontBody}>
            <div className={styles.estimateFrontBodyDrop}>
              <DropDown data={dropdownInfo} width="90%" />
            </div>
            <div className={styles.estimateFrontBodyUsd}>
              <CurrencySliderRow tokenCode="USD" displayName="USD" data={usdData} />
            </div>
          </div>
          {!isFlipped && <CurrenciesTemplate currencies={balance} />}
        </div>
      </div>
    </HighCardTitle>
  );
};

export default EstimatedFrontCard;
