import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { CoinMultiplier, ECoinCondition } from 'components/constants/games';
import { ContextTokenCode } from 'context';
import { SocketActions } from 'types';
import SocketContext from 'context/contextSocket/context';
import LiveStatsContext from 'context/contextLiveStats/context';
import {
  getBetCashOut,
  getBetProfit,
  getCoinHistoryRound,
  getCountMultiplier,
  isDisableCoinCheckbox,
  isDisableCoinOptionsButton,
} from 'func/prepareDataCoinFlip';
import { amount } from 'func/common';

import LiveStatistics from 'components/Base/LiveStatistics';
import CoinButtons from './components/CoinButtons';
import CoinOptionButtons from './components/CoinOptionButtons';
import CoinMultiplyCheckbox from './components/CoinMultiplyCheckbox';
import CoinAnimation from './components/CoinAnimation';
import CoinWinAnimation from './components/CoinWinAnimation';

import GameContentCoinStyle from './styles';
import { IGameContentCoin, IGetCoinRound, IGetCoinRoundResponse } from './types';

const CoinContent: React.FC<IGameContentCoin> = ({
  fastMode,
  setHistory,
  isHotKeys,
  coinId,
  setHistoryTable,
  history,
  historyTable,
}) => {
  const { tokenCode } = useContext(ContextTokenCode);
  const { socket, inGame: socketInGame } = useContext(SocketContext);
  const { enabled, visible } = useContext(LiveStatsContext);

  const isMobile = useMediaQuery({ query: '(max-width: 1260px)' });

  const [gameState, setGameState] = useState(ECoinCondition.notStarted);
  const [betAmountValue, setBetAmountValue] = useState('0.00000000');
  const [betSettings, setBetSettings] = useState({
    minBet: '0',
    maxBet: '0',
    maxPayout: '0',
  });
  const [isMultiplyValue, setIsMultiplyValue] = useState(false);
  const [multiplierSeries, setMultiplierSeries] = useState({ series: 0, multiplier: String(CoinMultiplier) });
  const [disableByRequest, setDisableByRequest] = useState(false);

  const getCoinRound = async (betData: IGetCoinRound): Promise<IGetCoinRoundResponse | null> => {
    return new Promise((res) => {
      socket.volatile.emit(SocketActions.getCoinRound, betData, (data: IGetCoinRoundResponse | null) => {
        res(data);
      });
    });
  };

  const getRound = async () => {
    const res = await getCoinRound({ token: tokenCode.token });
    if (res?.round) {
      const { wager, sequence } = res.round;
      setIsMultiplyValue(true);
      setBetAmountValue(String(amount(wager)));
      setGameState(sequence.length ? ECoinCondition.sessionStarted : ECoinCondition.sessionInit);
      setMultiplierSeries({ series: sequence.length, multiplier: getCountMultiplier(CoinMultiplier, sequence.length) });
      setHistory(getCoinHistoryRound(sequence));
    } else {
      setIsMultiplyValue(false);
      setGameState(ECoinCondition.notStarted);
      setHistory([]);
    }
  };

  useEffect(() => {
    if (tokenCode && socketInGame) {
      setGameState(ECoinCondition.sessionNotStarted);
      getRound();
    }
  }, [tokenCode, socketInGame]);

  return (
    <GameContentCoinStyle>
      <div className="coin__game-content--wrap">
        <CoinMultiplyCheckbox
          value={isMultiplyValue}
          setValue={setIsMultiplyValue}
          disable={isDisableCoinCheckbox(gameState) || disableByRequest}
          setGameState={setGameState}
          setMultiplierSeries={setMultiplierSeries}
          setHistory={setHistory}
          isHotkeys={isHotKeys}
        />
        <CoinWinAnimation
          fastMode={fastMode}
          historyTable={historyTable}
          isMultiplyValue={isMultiplyValue}
          displayName={tokenCode.name}
          profit={getBetCashOut(betAmountValue, multiplierSeries.multiplier, betSettings.maxPayout)}
          series={multiplierSeries.series}
        />
        <CoinAnimation
          multiplier={multiplierSeries.multiplier}
          series={multiplierSeries.series}
          isMultiplyValue={isMultiplyValue}
          history={history}
        />
        {isMobile ? enabled && visible ? <LiveStatistics mobile={isMobile} /> : null : null}
      </div>
      <CoinOptionButtons
        isMultiply={isMultiplyValue}
        disable={isDisableCoinOptionsButton(gameState) || disableByRequest}
        profit={getBetProfit(betAmountValue, multiplierSeries.series, betSettings.maxPayout)}
        isHotKeys={isHotKeys}
        betSettings={betSettings}
        coinId={coinId}
        fastMode={fastMode}
        betAmountValue={betAmountValue}
        setGameState={setGameState}
        setHistoryTable={setHistoryTable}
        setHistory={setHistory}
        setMultiplierSeries={setMultiplierSeries}
        setRequest={setDisableByRequest}
      />
      <CoinButtons
        gameState={gameState}
        coinId={coinId}
        betAmountValue={betAmountValue}
        isHotKeys={isHotKeys}
        setBetAmountValue={setBetAmountValue}
        setGameState={setGameState}
        isMultiplyValue={isMultiplyValue}
        betSettings={betSettings}
        setBetSettings={setBetSettings}
        setMultiplierSeries={setMultiplierSeries}
        multiplierSeries={multiplierSeries}
        setHistory={setHistory}
        setHistoryTable={setHistoryTable}
        fastMode={fastMode}
        disableByRequest={disableByRequest}
      />
    </GameContentCoinStyle>
  );
};

export default CoinContent;
