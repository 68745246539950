export interface IDataLayerItem {
  event: DataLayerEvent;
  user_id: string;
  method?: DataLayerMethod;
  search_position?: string;
  search_term?: string;
  question?: string;
  content_type?: string;
  content_type_id?: string;
  deposit_currency?: string;
  currency?: string;
  value?: string;
}
export type DataLayerType = IDataLayerItem[];

export type LayerDispatchType = (item: IDataLayerItem) => void;

export enum DataLayerEvent {
  login = 'login',
  signUp = 'sign_up',
  viewSearchResult = 'view_search_results',
  faq = 'faq',
  promotionViewed = 'promotion_viewed',
  promotionClicked = 'promotion_clicked',
  deposit = 'deposit',
  emailVerified = 'email_verified',
  bonusSelected = 'bonus_selected',
  depositAddressCopied = 'deposit_address_copied',
}

export enum DataLayerMethod {
  email = 'email',
}
