import { CardGameStake, HistoryElem } from 'types';

export interface IBlackJackInitialState {
  fastMode: boolean;
  isHotkeys: boolean;
  hash: string;
  gameId: string;
  walletUSD: string;
  isHighMode: CardGameStake;
  chips: BlackJackChipType[];
  chipsHistory: BlackJackChipType[];
  prevChipsHistory: BlackJackChipType[];
  betAmount: string;
  prevBetAmount: string;
  minBet: number;
  maxBet: number;
  actions: BlackJackActionTypes[];
  playerHand: IBlackJackHand;
  dealerHand: BlackJackCardsType[];
  historyList: HistoryElem[];
  isSessionStarted: boolean;
  payout: string;
  emptyBetAmount: string;
  isStarToken: boolean;
}

export interface IAction {
  type: string;
  payload?: any;
}

export interface BlackJackChipType {
  amount: number;
  icon: string;
  id: string;
}

export enum BlackJackActionTypes {
  deal = 'deal',
  hit = 'hit',
  stand = 'stand',
  double = 'double',
  split = 'split',
  back = 'back',
  clear = 'clear',
  reBet = 'reBet',
  newBet = 'newBet',
  betDeal = 'betDeal',
  reBetDefault = 'reBetDefault',
}

export interface IActionSteps {
  title: string;
  icon: string;
  btnBg: string;
  type: BlackJackActionTypes;
  onSubmit: VoidFunction;
}

export interface BlackJackCardsType {
  cardValue: string;
  flip: boolean;
  zIndex: number;
  flipDelay: number;
  leftBase: number;
  id: string;
  top: number;
  isFirst: boolean;
}

export enum HandType {
  notActive = '',
  open = 'open',
  closed = 'closed',
}

export interface IBlackJackHand {
  cards: BlackJackCardsType[];
  isSplit: boolean;
  firstHand: IBlackJackPlayerHandDetails;
  secondHand: IBlackJackPlayerHandDetails;
  cardsCount: number;
}

interface IBlackJackPlayerHandDetails {
  status: HandType;
  isDouble: boolean;
  betAmount: string;
  result: HandResultType;
}

export enum HandResultType {
  default = '',
  blackJack = 'BLACKJACK',
  win = 'WIN',
  push = 'PUSH',
  bust = 'BUST',
  loss = 'LOSS',
}

export interface BlackjackPlayerHandResponseType {
  cards: string[];
  availableActions?: HandResultType[];
  outcome?: HandResultType;
  isClosed?: boolean;
  isDouble?: boolean;
  isProcessed?: boolean;
  isSplitHand?: boolean;
  leftSplitHand?: BlackjackPlayerHandResponseType;
  rightSplitHand?: BlackjackPlayerHandResponseType;
}

type BlackjackLowStakeChipBE = '0.1' | '0.5' | '2.5' | '10';
type BlackjackHighStakeChipBE = '10' | '50' | '250' | '1000';

export type BlackjackChipsRequest = {
  [key in BlackjackLowStakeChipBE | BlackjackHighStakeChipBE]?: number;
};

export interface ISessionStartRequest {
  gameId: string;
  stake: CardGameStake;
  tokenCode: string;
  chips: BlackjackChipsRequest;
}
export interface IActionRequest {
  gameId: string;
  tokenCode: string;
  stake: CardGameStake;
}
export interface ISessionRequest {
  tokenCode: string;
  stake: CardGameStake;
}
export interface ISessionGetRequest {
  tokenCode: string;
  stake: CardGameStake;
}

export interface IResponseSessionActive {
  hash: string;
  playerHands: BlackjackPlayerHandResponseType;
  dealerCards: string[];
}

export interface IResponseActive {
  playerHands: BlackjackPlayerHandResponseType;
  dealerCards: string[];
}

export interface IResponseClose {
  hash: string;
  betId: string;
  payout: string;
  multiplier: number;
  playerHands: BlackjackPlayerHandResponseType;
  dealerCards: string[];
}

export interface ISessionGetResponse {
  amount: string;
  wager: string;
  hash: string;
  playerHands: BlackjackPlayerHandResponseType;
  dealerCards: string[];
  stake: BlackjackPlayerHandResponseType;
}

export interface IBlackJackResult {
  betId: string;
  payout: string;
  multiplier: number;
  tokenCode: string;
  displayName: string;
}

export interface IGetSessionResponse {
  amount: string;
  wager: string;
  hash: string;
  playerHands: BlackjackPlayerHandResponseType;
  dealerCards: string[];
  stake: CardGameStake;
  chips: BlackjackChipsRequest;
}
