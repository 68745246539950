/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import styles from './styles.module.scss';

const LimboSpace: React.FC = () => {
  const canvasRef = useRef(null);
  const canvasWrap = useRef(null);

  window.requestAnimFrame = (function () { return window.requestAnimationFrame; }());

  const canvas = canvasRef.current;

  const c = canvas?.getContext('2d');

  const numStars = 90;
  const radius = '0.' + Math.floor(Math.random() * 9) + 1;
  const focalLength = canvas?.width;
  const warp = 0;
  let centerX; let centerY;

  let stars = [], star;
  let i;
  let animate = true;

  initializeStars();

  function executeFrame() {
    if (animate) requestAnimFrame(executeFrame);
    moveStars();
    drawStars();
  }

  function initializeStars() {
    centerX = (canvas?.width || 2) / 2;
    centerY = (canvas?.height || 2) / 2;

    stars = [];
    for (i = 0; i < numStars; i++) {
      star = {
        x: Math.random() * canvas?.width,
        y: Math.random() * canvas?.height,
        z: Math.random() * canvas?.width,
        o: '0.' + Math.floor(Math.random() * 99) + 1,
      };
      stars.push(star);
    }
  }

  function moveStars() {
    for (i = 0; i < numStars; i++) {
      star = stars[i];
      star.z--;

      if (star.z <= 0) {
        star.z = (canvas?.width || 2) / 2;
      }
    }
  }

  function drawStars() {
    let pixelX; let pixelY; let pixelRadius;

    // Resize to the screen
    if (canvas?.width !== canvasWrap.current?.clientWidth || canvas?.height !== canvasWrap.current?.clientHeight) {
      if (canvas?.width && canvas?.height) {
        canvas.width = canvasWrap.current?.clientWidth;
        canvas.height = canvasWrap.current?.clientHeight;
        initializeStars();
      }
    }

    if (warp == 0 && c) {
      c.fillStyle = '#100B2F';
      c.fillRect(0, 0, canvas?.width, canvas?.height);
    }
    if (c) {
      c.fillStyle = 'rgba(209, 255, 255, ' + radius + ')';
    }
    for (i = 0; i < numStars; i++) {
      star = stars[i];

      pixelX = (star.x - centerX) * (focalLength / star.z);
      pixelX += centerX;
      pixelY = (star.y - centerY) * (focalLength / star.z);
      pixelY += centerY;
      pixelRadius = 1 * ((focalLength / 2) / star.z);

      if (c) {
        c.fillStyle = 'rgba(209, 255, 255,' + star.o + ')';
        c.beginPath();
        c.arc(pixelX,pixelY,pixelRadius,0,Math.PI*2,true);
        c.fill();
      }
    }
  }

  useEffect(() => {
    if (canvas) {
      executeFrame();
    }
  }, [canvas]);

  return (
    <div className={styles.limboCanvasWrap} ref={canvasWrap}>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default LimboSpace;
