import { useLocalization } from 'components/Internationalization';
import { LANDING_JOIN_TERMS } from 'components/constants/landing/join';

import styles from './styles.module.scss';

const LandingJoinTerms: React.FC = () => {
  const { translate } = useLocalization();

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{translate('landing.join.popups.title')}</p>
      <ol className={styles.list}>
        {LANDING_JOIN_TERMS.map((t) => (
          <li key={t}>{translate(t)}</li>
        ))}
      </ol>
      <p className={styles.description}>{translate('landing.join.popups.descriptio')}</p>
    </div>
  );
};

export default LandingJoinTerms;
