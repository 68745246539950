import { gql } from '@apollo/client';

export const GET_DIVIDENDS_DISTRIBUTIONS = gql`
  query {
    dividendDistributions {
      amount
      token {
        tokenCode
        displayName
      }
      dividendBatch {
        distributedAt
        totalStaked
      }
    }
  }
`;
