import { useEffect, useState } from 'react';

import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile } from 'store/user/user.selectors';

import HighCardTitle from 'components/Base/Cards/HighCardTitle';
import CashbackProgressBar from 'components/Pages/CashbackPage/components/CardBlockSecond/components/MyRank/components/CashbackProgressBar';
import { generalDataRanksList } from 'store/generalData/generalDtata.selectors';
import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';

const MyCashbackPercent: React.FC = () => {
  const user = useAppSelector(userProfile);
  const dataRands = useAppSelector(generalDataRanksList);
  const { translate } = useLocalization();

  const [progressWidth, setProgressWidth] = useState(0);
  const [rankValue, setRankValue] = useState(null);

  useEffect(() => {
    if (dataRands?.length > 1 && user) {
      setRankValue(dataRands.find((elem) => elem.id === user.rank?.id + 1));
    }
    if (rankValue && user) {
      const value = Number((Number(user.totalWager) * 100) / Number(rankValue.wager)).toFixed(9);
      setProgressWidth(Number(value));
    }
  }, [dataRands, user, rankValue]);

  return (
    <HighCardTitle
      title={translate('cashback.percent.title')}
      subtitle={translate('cashback.percent.description')}
      height="auto"
      blurIfNotAuth
    >
      <div className={styles.cashbackMyWrap}>
        <>
          <div className={styles.cashbackMyBody}>
            <code>{translate('cashback.percent.card.text')}</code>
            <div />
            <code>{user ? user.rank.cashback + ' %' : '1 %'}</code>
          </div>
          <CashbackProgressBar
            progressWidth={progressWidth}
            currentWager={user?.totalWager || '0'}
            wager={rankValue?.wager || '0'}
            rank={user?.rank?.id || 1}
            percent={rankValue?.cashback || '0'}
            currentPercent={user?.rank?.cashback || '0'}
            rankLine
          />
        </>
      </div>
    </HighCardTitle>
  );
};

export default MyCashbackPercent;
