import { gql } from '@apollo/client';

export const GET_MY_DIVIDENDS_DISTRIBUTIONS = gql`
  query myDividendDistributions($input: MyDividendsPaginationParams!) {
    myDividendDistributions(paginationData: $input) {
      count
      items {
        amount
        distributed_at
        staked_stars
        token_code
        display_name
      }
    }
  }
`;
