import { gql } from '@apollo/client';

export const GET_MISSIONS = gql`
  query {
    missions {
      id
      mission
      weight
      gameId
      gameCategory
      num_bets
      oneStarMinBet
      twoStarMinBet
      threeStarMinBet
      oneStarMultiplier
      twoStarMultiplier
      threeStarMultiplier
    }
  }
`;

export const GET_MISSIONS_PROGRESS = gql`
  query {
    missionsProgress {
      missionId
      oneStarProgress
      twoStarProgress
      threeStarProgress
    }
  }
`;

export const GET_MISSION_ROUND = gql`
  query {
    missionRound {
      id
      startAt
      endAt
      prize
      prizeTokenCode
    }
  }
`;

export const GET_MISSION_ROUND_PROGRESS = gql`
  query {
    missionRoundsProgress {
      id
      userMissionRoundId
      multiplier
      status
      startAt
      endAt
      prize
      prizeTokenCode
    }
  }
`;

export const COLLECT_MISSION_REWARD = gql`
  mutation ($userMissionRoundId: String!) {
    collectMissionReward(userMissionRoundId: $userMissionRoundId)
  }
`;
