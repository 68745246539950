import React, { useContext, useEffect } from 'react';

import { KenoProvider } from 'context/contextKeno/contextKeno';
import SocketContext from 'context/contextSocket/context';
import { DOCUMENT_TITLE_SETTINGS } from 'components/constants/constants';

import DocumentTitle from 'components/Base/DocumentTitle';

import KenoGame from './KenoGame';

const KenoGameWrap: React.FC = () => {
  const { connected, joinGameRoom, leaveGameRoom } = useContext(SocketContext);

  useEffect(() => {
    joinGameRoom();

    return () => {
      leaveGameRoom();
    };
  }, [connected]);

  return (
    <KenoProvider>
      <DocumentTitle data={DOCUMENT_TITLE_SETTINGS.keno} />
      <KenoGame />
    </KenoProvider>
  );
};

export default React.memo(KenoGameWrap);
