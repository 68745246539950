import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';

const DescriptionBlock: React.FC = () => {
  const { translate } = useLocalization();

  return (
    <div className={styles.footerDescription}>
      <div className={styles.footerDescriptionItem1}>
        <div className={styles.footerDescriptionStop}>18</div>
        <div className={styles.footerDescriptionStop1}>{translate('footer.warning.title')}</div>
      </div>
      <div className={styles.footerDescriptionItem2}>
        <p>{translate('footer.warning.desc')}</p>
      </div>
    </div>
  );
};

export default DescriptionBlock;
