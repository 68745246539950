import React from 'react';

import bigWin from 'assets/img/Mines/big-win.webp';
import { getCoinSrc } from 'func/common';

import { IMinesBigWinAnimation } from './types';
import styles from './styles.module.scss';

const BigWinMinesAnimation: React.FC<IMinesBigWinAnimation> = ({ multiplier, tokenCode }) => (
  <div className={styles.minesWinAnimationContentBig}>
    <img src={bigWin} alt="icon" className={styles.minesWinAnimationContentBigWin} />
    <div className={styles.minesWinAnimationContentBigItem1}>
      <p>{`x ${multiplier}`}</p>
    </div>
    <img src={getCoinSrc(tokenCode)} className={styles.minesWinAnimationContentBigLarge} alt="icon" />
    <img src={getCoinSrc(tokenCode)} className={styles.minesWinAnimationContentBigMiddle1} alt="icon" />
    <img src={getCoinSrc(tokenCode)} className={styles.minesWinAnimationContentBigMiddle2} alt="icon" />
    <img src={getCoinSrc(tokenCode)} className={styles.minesWinAnimationContentBigMiddle3} alt="icon" />
    <img src={getCoinSrc(tokenCode)} className={styles.minesWinAnimationContentBigSmall1} alt="icon" />
    <img src={getCoinSrc(tokenCode)} className={styles.minesWinAnimationContentBigSmall2} alt="icon" />
    <img src={getCoinSrc(tokenCode)} className={styles.minesWinAnimationContentBigSmall3} alt="icon" />
    <img src={getCoinSrc(tokenCode)} className={styles.minesWinAnimationContentBigSmall4} alt="icon" />
    <img src={getCoinSrc(tokenCode)} className={styles.minesWinAnimationContentBigTiny} alt="icon" />
    <div className={styles.minesWinAnimationContentShadow} />
  </div>
);

export default BigWinMinesAnimation;
