import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { getEventsPopupTableTitles } from 'components/constants/table';
import { GET_CURRENT_EVENT } from 'graphQl/query/events/events';
import { prepareCompetitionStandings } from 'func/prepareDataEvents';
import { createFirstLetterCapital } from 'func/common';

import EventsTable from 'components/Pages/EventsPage/components/base/EventsTable';

import TablePopUpStyle from './styles';
import { ITablePopUp } from './types';

const TablePopUp: React.FC<ITablePopUp> = ({ data }) => {
  const { id, eventPrizeToken, totalWagerTokenCode, type } = data;

  const [perPage, setPerPage] = useState(10);
  const [prepareData, setPrepareData] = useState([]);
  const [dataTable, setDataTable] = useState([]);

  const { startPolling, stopPolling } = useQuery(GET_CURRENT_EVENT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
    onCompleted: (dataEvent) => {
      const result = prepareCompetitionStandings(dataEvent.eventCurrentResult, eventPrizeToken, totalWagerTokenCode);
      setPrepareData(result);
      setDataTable(result.slice(0, perPage));
    },
  });

  useEffect(() => {
    startPolling(60000);
    return () => stopPolling();
  }, []);

  return (
    <TablePopUpStyle>
      <EventsTable
        dataTable={dataTable}
        perPage={perPage}
        prepareData={prepareData}
        setDataTable={setDataTable}
        setPerPage={setPerPage}
        titles={getEventsPopupTableTitles(createFirstLetterCapital(type))}
        tablePopUp
      />
    </TablePopUpStyle>
  );
};

export default TablePopUp;
