import { useContext } from 'react';

import bonusIcon from 'assets/img/PageTitles/Blog_Page_Icon.svg';

import BlogContext from 'context/contextBlog/context';
import { DOCUMENT_TITLE_SETTINGS } from 'components/constants/constants';

import DocumentTitle from 'components/Base/DocumentTitle';
import PageTitle from 'components/Base/PageTitle';

import BlogToolbar from './components/BlogToolbar';
import BlogList from './components/BlogList';
import BlogPagination from './components/BlogPagination';

import styles from './styles.module.scss';

const BlogPage: React.FC = () => {
  const { pagination, onChangePage } = useContext(BlogContext);

  return (
    <main className={styles.wrapper}>
      <DocumentTitle data={DOCUMENT_TITLE_SETTINGS.blog} />
      <PageTitle component="h1" icon={bonusIcon} title="blog" lineColor="#5AA3A9" titleSize="big" largeImage />
      <BlogToolbar />
      <BlogList />
      <BlogPagination
        page={pagination?.page ? pagination.page - 1 : 0}
        pages={pagination?.pages || 1}
        onChangePage={onChangePage}
      />
    </main>
  );
};

export default BlogPage;
