import clsx from 'clsx';
import sanitize from 'sanitize-html';

import { ReactComponent as ArrowIcon } from 'assets/img/common/arrow.svg';

import styles from './styles.module.scss';
import { IFAQArticle } from './types';

const FAQArticle: React.FC<IFAQArticle> = ({ article, open, onOpen }) => {
  return (
    <li className={styles.wrapper} itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
      <div className={styles.titleWrapper} onClick={onOpen}>
        <h6 className={styles.title} itemProp="name">
          {article.title}
        </h6>
        <div className={styles.iconWrapper}>
          <ArrowIcon className={clsx(styles.icon, open ? styles.open : '')} />
        </div>
      </div>
      <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
        <div
          className={clsx(styles.body, open ? styles.open : '')}
          itemProp="text"
          dangerouslySetInnerHTML={{ __html: sanitize(article.body) }}
        />
      </div>
    </li>
  );
};

export default FAQArticle;
