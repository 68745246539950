import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

const GameScreenStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  max-width: 1400px;
  color: #a9b3bc;
  padding-top: 28px;

  .game-screen-wrapper {
    overflow: hidden;
    height: calc(100vh - 75px - 28px - 36px);
    width: 90%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0px 20px 40px 10px rgba(0, 0, 0, 0.25);
    mix-blend-mode: normal;
    position: relative;
  }
  .game-screen-init {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(27, 34, 51, 0.78);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .currency__block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .currency__elem {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 0 10px 0;
    width: 147px;
    height: 70px;
    border: 3px solid #313e57;
    border-radius: 16px;
    transition: 0.3s ease;
    cursor: url(${point}), auto !important;
    p {
      margin: 0 0 0 10px;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 24px;
      color: #ffffff;
    }
    h3 {
      margin: 0 0 0 -5px;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 24px;
      color: #ffffff;
    }
    &:hover {
      transform: scale(1.05);
    }
    &:active {
      border: 3px solid #0e9628;
    }
  }
  .currency__elem.active {
    border: 3px solid #0e9628;
  }
  .inter-currency {
    width: 40px;
  }
  .inter-currency-token {
    width: 70px;
    margin: 0 0 0 -13px;
  }
  .fullscreen {
    height: calc(93vh - 75px - 28px - 36px);
    border-radius: 20px;
  }
  .game-screen {
    overflow: hidden;
    height: 100%;
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .game-screen-full {
    height: 100vh;
    width: 100%;
  }
  .game-screen-buttons {
    display: flex;
    width: 100%;
    height: 70px;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    background: rgba(136, 138, 167, 0.15);
  }
  .game-screen-close,
  .game-screen-full-button,
  .game-screen-close-mob {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: space-around;
    background: #242c45;
    mix-blend-mode: normal;
    backdrop-filter: blur(10px);
    border-radius: 8px;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    cursor: url(${point}), auto !important;
    transition: 0.1s ease-in-out;
    &:hover {
      box-shadow: 1px 1px 6px 1px rgba(1, 9, 15, 0.979);
    }
  }
  .game-screen-close-mob {
    display: none;
    width: 100px;
    text-align: center;
    margin-top: 20px;
    box-shadow: 1px 1px 6px 1px rgba(1, 9, 15, 0.979);
  }
  .close-icon {
    width: 10px;
    height: 10px;
  }
  .full-screen-icon {
    width: 20px;
    height: 15px;
  }
  .game__blocked {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 1400px;
    padding: 0 50px;

    .error__title {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 550px;
      height: 100%;
      padding: 15% 0 0 0;

      p,
      h3 {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 20px;
        margin: 0;
        color: #ffffff;
      }

      h3 {
        font-size: 28px;
        margin: 0 0 15px 0;
      }
    }

    .error_image {
      height: 300px;
    }
  }

  .currency__block-star-wallet {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    padding: 0 20px;
  }

  @media (max-width: 1024px) {
    .game__blocked {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .error__title {
        max-width: 300px;
        height: auto;
        p {
          font-size: 14px;
        }
      }
      .error_image {
        height: 200px;
      }
    }
    .game-screen-full-button,
    .game-screen-close {
      display: none;
    }
    .game-screen-buttons {
      display: none;
    }
    .game-screen-close-mob {
      display: flex;
    }
    .game-screen-wrapper {
      margin-top: 0;
      //height: 100vh;
      //width: 100vw;
      height: 100%;
      width: 100%;
      max-height: 100%;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 0;
      overflow: hidden;
      background: radial-gradient(44.11% 51.33% at 10.08% 47.02%, #442a81 0%, rgba(22, 30, 49, 0) 100%),
        radial-gradient(58.77% 68.38% at 100% 52.05%, #2654a9 0%, rgba(26, 61, 122, 0) 100%), #161e31;
      z-index: 999999;
    }
    .game-screen {
      position: absolute;
      width: 100%;
      //height: 100vh;
      height: 100%;
      overflow: hidden;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      z-index: 1;
    }
    .fullscreen {
      height: 100vh;
      border-radius: 0;
    }
  }
`;

export default GameScreenStyle;
