import TooltipStyle from './styles';
import { ITooltipCustom } from './types';

const TooltipCustom: React.FC<ITooltipCustom> = ({ state, children, top, right, width, height, arrow }) => (
  <TooltipStyle right={right} top={top} width={width} height={height} arrow={arrow}>
    <div className="tooltip-custom__wrapper">
      <div className="tooltip-custom">
        <div className={state ? 'tooltip-custom__content open' : 'tooltip-custom__content'}>{children}</div>
      </div>
    </div>
  </TooltipStyle>
);

export default TooltipCustom;
