import clsx from 'clsx';

import planet from 'assets/img/NotFound/planet.webp';
import asteroids from 'assets/img/NotFound/asteroids.webp';
import number from 'assets/img/NotFound/4.webp';

import { Links } from 'components/constants';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';
import { useNavigate } from 'hooks/useNavigate';

import Button from 'components/Base/Button';

import styles from './styles.module.scss';

const NotFoundPage: React.FC = () => {
  const { translate } = useLocalization();
  const navigate = useNavigate();

  const handleHome = () => {
    navigate(Links.home);
  };

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.planetWrapper}>
          <div className={styles.asteroidsWrapper}>
            <img className={styles.asteroids} src={asteroids} alt="Asteroid" />
          </div>
          <img className={clsx(styles.number, styles.firstNumber)} src={number} alt="4" />
          <img className={styles.planet} src={planet} alt="Planet" />
          <img className={clsx(styles.number, styles.lastNumber)} src={number} alt="4" />
        </div>
        <p className={styles.text}>{translate('error.page.title')}</p>
        <div className={styles.button}>
          <Button
            variant={ButtonType.primary}
            title={translate('error.page.button')}
            width="180px"
            onclick={handleHome}
          />
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
