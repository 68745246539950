import React from 'react';

import { useMines } from 'context/contextMines/contextMines';
import { MINES_CONDITION } from 'components/constants/constants';
import { Types } from 'context/contextMines/minesReduser';

import AutoBetBlockNew from 'components/Games/base/AutoBetBlockNew';

const MinesAutoSettings: React.FC = () => {
  const [state, dispatch] = useMines();

  const { betAmountValue, autoState, gameCondition, isAuto, gameSettings } = state;

  const handleBetAmount = (value) => {
    dispatch({ type: Types.SET_BET_AMOUNT, payload: { betAmount: value } });
  };

  const handleChangeAutoState = (name: string, value: boolean | string) => {
    dispatch({ type: Types.CHANGE_AUTO_STATE, payload: { name, value } });
  };

  return (
    <AutoBetBlockNew
      checkboxValue={isAuto}
      autoState={autoState}
      realBetValue={betAmountValue}
      disable={gameCondition === MINES_CONDITION.autoStarted}
      maxBet={gameSettings.maxBet}
      minimumBet={gameSettings.minBet}
      setRealBetValue={handleBetAmount}
      onChangeValue={handleChangeAutoState}
    />
  );
};

export default MinesAutoSettings;
