import { pathOr } from 'ramda';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import { generate as id } from 'shortid';

import { media } from 'config';
import { GET_FOOTER_LOGOS } from 'graphQl/query/footerLogos/footerLogos';

import Slider from 'components/Base/Slider';

import styles from './styles.module.scss';
import { IFooterLogo } from './types';

const SupportBlock: React.FC = () => {
  const mobile = useMediaQuery({ query: '(max-width: 600px)' });
  const tablet = useMediaQuery({ query: '(max-width: 1270px)' });

  const [sliderKey, setSliderKey] = useState(id());
  const [providers, setProviders] = useState<IFooterLogo[]>([]);
  const [providersSlides, setProvidersSlides] = useState<IFooterLogo[][]>([]);
  const [slidesShow, setSlidesShow] = useState(4);

  const { data } = useQuery(GET_FOOTER_LOGOS, {
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    if (data) {
      const newProviders = pathOr<IFooterLogo[]>([], ['footerLogos'], data);

      setProviders(newProviders);
    }
  }, [data]);

  useEffect(() => {
    const desktopView = !mobile && !tablet;
    const tabletView = tablet && !mobile;

    if (desktopView) {
      setSlidesShow(5);
      return;
    }

    if (tabletView) {
      setSlidesShow(3);
      return;
    }

    setSlidesShow(2);
  }, [mobile, tablet]);

  useEffect(() => {
    const newProvidersSlides = providers.reduce<IFooterLogo[][]>((acc, provider, i) => {
      const newSlide = i % slidesShow === 0;

      if (newSlide) {
        return [...acc, [provider]];
      }

      const lastSlide = acc[acc.length - 1];

      if (!lastSlide) return acc;

      lastSlide.push(provider);

      return acc;
    }, []);

    setProvidersSlides(newProvidersSlides);
    setSliderKey(id());
  }, [slidesShow, providers]);

  return (
    <div className={styles.footerSupport}>
      <div className={styles.wrapper}>
        <Slider key={sliderKey} className={styles.slider} autoPlay interval={5000} infiniteLoop>
          {providersSlides.map((ps, i) => (
            <div key={`${i}-provider-slide`} className={styles.providerSlide}>
              {ps.map((p) => (
                <div key={p.id} className={styles.providerBlock}>
                  <img className={styles.providerImage} src={media.footerLogos + p.logo} alt="icon" />
                </div>
              ))}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SupportBlock;
