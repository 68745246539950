import { useEffect } from 'react';
import Intercom, { update } from '@intercom/messenger-js-sdk';
import { IntercomSettings } from '@intercom/messenger-js-sdk/dist/types';

import config from 'config';
import { INTERCOM_LAUNCHER_ID } from 'components/IntercomLauncher/constants';
import { userProfile } from 'store/user/user.selectors';
import { useAppSelector } from 'hooks/useAppSelector';

const useIntercomInit = () => {
  const { intercomAppId } = config;

  const user = useAppSelector(userProfile);

  useEffect(() => {
    Intercom({
      app_id: intercomAppId,
      hide_default_launcher: true,
      custom_launcher_selector: `#${INTERCOM_LAUNCHER_ID}`,
    });
  }, []);

  useEffect(() => {
    if (user) {
      const createdAt = Math.floor(new Date(user.createdAt).getTime() / 1000);

      const settings: IntercomSettings = {
        app_id: intercomAppId,
        hide_default_launcher: true,
        custom_launcher_selector: `#${INTERCOM_LAUNCHER_ID}`,
        user_id: user.id,
        name: user.alias,
        email: user.email,
        created_at: createdAt,
      };

      update(settings);
    }
  }, [user]);
};

export default useIntercomInit;
