import styled from 'styled-components';

const CoinButtonsStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 91px;
  width: 100%;

  .coin__buttons1 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 91px;
    width: 48.5%;
  }
  .coin__buttons1.full {
    width: 100%;
  }

  .submit__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    flex-grow: 1;
    margin-left: 20px;
    height: 91px;
  }
  .submit__wrap.hide {
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
    width: 0;
    margin-left: 0;
    height: 0;
  }
  .submit__bet {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    h5,
    h6 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.09px;
      color: #ffffff;
      text-transform: uppercase;
      margin: 0;
    }
    &:disabled {
      opacity: 0.7;
      pointer-events: none;
      touch-action: none;
    }
    &:hover {
      filter: brightness(1.05);
    }
  }

  @media (max-width: 1400px) {
    .submit__bet {
      h5,
      h6 {
        font-size: 13px;
      }
    }
  }
  @media (max-width: 1200px) {
    .submit__bet {
      h5,
      h6 {
        font-size: 11px;
      }
    }
  }
  @media (max-width: 1100px) {
    .submit__bet {
      h5,
      h6 {
        font-size: 14px;
      }
    }
  }
  @media (max-width: 1024px) {
    .submit__bet {
      h5,
      h6 {
        font-size: 14px;
      }
    }
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 680px) {
    .submit__bet {
      h5,
      h6 {
        font-size: 12px;
      }
    }
  }
  @media (max-width: 640px) {
    flex-direction: column;
    height: 100%;
    .submit__wrap {
      width: 100%;
      margin-left: 0;
      height: 60px;
    }
    .coin__buttons1 {
      width: 100%;
      margin-bottom: 10px;
    }
    .submit__bet {
      width: 100%;
      justify-content: center;
      h5,
      h6 {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  @media (max-width: 580px) {
    margin: 0 0 15px;
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 400px) {
  }
`;

export default CoinButtonsStyle;
