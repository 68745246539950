import React from 'react';

import DropdownWithTitle from 'components/Base/DropDown/components/DropdownWithTitle';

import { IKenoRiskDropdown } from './types';
import style from './styles.module.scss';

const KenoRiskDropdown: React.FC<IKenoRiskDropdown> = ({ disable, dropData, dropTitle }) => {
  return (
    <div className={style.kenoRiskDropWrap}>
      <DropdownWithTitle title={dropTitle} options={dropData} disable={disable} />
    </div>
  );
};

export default KenoRiskDropdown;
