import BuyCryptoProvidersLayout from './buyCryptoProvidersLayout';
import { IBuyCryptoProviders } from './types';
import { ProviderCode } from '../../types';

const BuyCryptoProviders: React.FC<IBuyCryptoProviders> = ({ provider, setProvider }) => {
  const handleProvider = (name: ProviderCode) => setProvider(name);

  return <BuyCryptoProvidersLayout provider={provider} onProvider={handleProvider} />;
};

export default BuyCryptoProviders;
