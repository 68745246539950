import styled from 'styled-components';
import bg from 'assets/img/CoinFlip/CoinFlipBackground.webp';

const GameContentCoinStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 15px 0;

  .coin__game-content--wrap {
    display: flex;
    width: 100%;
    height: 356px;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    background-image: url(${bg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media (max-width: 1300px) {
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
    .coin__game-content--wrap {
      height: 280px;
    }
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 580px) {
  }
  @media (max-width: 500px) {
    .coin__game-content--wrap {
      height: 250px;
    }
  }
  @media (max-width: 400px) {
  }
`;

export default GameContentCoinStyle;
