import { useState } from 'react';

import DropdownWithTitleLayout from './DropdownWithTitleLayout';
import { IDropdownWithTitle } from './types';

const DropdownWithTitle: React.FC<IDropdownWithTitle> = ({ title, options, disable }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);

  const handleBlur = (event) => {
    if (event.relatedTarget?.tabIndex >= 1) return;
    setOpen(false);
  };

  if (!title) return null;

  return (
    <DropdownWithTitleLayout
      onBlur={handleBlur}
      onOpen={handleOpen}
      options={options}
      title={title}
      open={open}
      disable={disable}
    />
  );
};

export default DropdownWithTitle;
