import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CATEGORY_NAMES, GAMES_QUALITY } from 'components/constants/constants';
import { getGamesNavigation } from 'components/constants/games';
import { useLocalization } from 'components/Internationalization';
import { setMainSearchParam } from 'func/prepareDataGamesPage';
import { useNavigate } from 'hooks/useNavigate';

import GamesNavigationItem from './components/GamesNavigationItem';
import GamesNavigationInput from './components/GamesNavigationInput';

import styles from './styles.module.scss';
import { IGameNavItem, IGamesNavigation } from './types';

const GamesNavigation: React.FC<IGamesNavigation> = ({ inputValue, onSearch, setInputValue }) => {
  const navigate = useNavigate();
  const { language, translate } = useLocalization();
  const { search: locationSearch } = useLocation();

  const [categoryUrl, setCategoryUrl] = useState('');
  const [gamesNav, setGamesNav] = useState<IGameNavItem[]>([]);

  useEffect(() => {
    const params = new URLSearchParams(locationSearch);
    const category = params.get(GAMES_QUALITY.categories.id);

    setCategoryUrl(category);
  }, [locationSearch]);

  useEffect(() => {
    const newNav = getGamesNavigation(translate);

    setGamesNav(newNav);
  }, [language]);

  const handleCategory = (urlTitle) => {
    setInputValue('');
    setMainSearchParam(navigate, GAMES_QUALITY.categories.id, urlTitle);
  };

  return (
    <div className={styles.gamesNavWrap}>
      <div className={styles.gamesNavItemWrap}>
        {gamesNav.map((el) => (
          <GamesNavigationItem
            key={el.urlTitle}
            inactiveImage={el.inactiveImg}
            activeImage={el.activeImg}
            title={el.title}
            activeTab={
              el.urlTitle === categoryUrl || (!categoryUrl && el.urlTitle === CATEGORY_NAMES.allGames.urlTitle)
            }
            categoryUrl={el.urlTitle}
            onClick={() => handleCategory(el.urlTitle)}
          />
        ))}
      </div>
      <div className={styles.gamesNavSearchWrap}>
        <GamesNavigationInput inputValue={inputValue} onSearch={onSearch} />
      </div>
    </div>
  );
};

export default GamesNavigation;
