import { ELimboCondition } from 'components/constants/games';
import { bigThen, lessThen } from 'func/prepareDataDice';
import { generate as Id } from 'shortid';
import { Token } from 'types/requestTypes';
import { HistoryElem } from 'types';

export const isAnimateLimboImages = (gameState: string, fastMode: boolean, switchShipOnAuto: boolean): boolean => {
  if (fastMode) return false;
  if (!switchShipOnAuto) return false;
  if (switchShipOnAuto && gameState === ELimboCondition.autoStarted) return true;
  if (!switchShipOnAuto && gameState === ELimboCondition.autoStarted) return false;
  switch (gameState) {
    case ELimboCondition.getStarted:
      return true;
    default:
      return false;
  }
};

export const isDisplayShip = (gameState: string, fly: boolean, switchShipOnAuto: boolean): boolean => {
  if (fly) {
    if (switchShipOnAuto && gameState === ELimboCondition.autoStarted) return true;
    if (!switchShipOnAuto && gameState === ELimboCondition.autoStarted) return false;
    switch (gameState) {
      case ELimboCondition.getStarted:
        return true;
      case ELimboCondition.autoStarted:
        return true;
      default:
        return false;
    }
  }
  if (!switchShipOnAuto && gameState === ELimboCondition.autoStarted) return true;
  if (switchShipOnAuto && gameState === ELimboCondition.autoStarted) return false;
  switch (gameState) {
    case ELimboCondition.notStarted:
      return true;
    case ELimboCondition.autoNotStarted:
      return true;
    default:
      return false;
  }
};

export const getWinInfoLimbo = (payout: string, coefficient: string): { value: string; vin: boolean; id: string } => {
  if (bigThen(payout, '0')) {
    return { value: `${coefficient}x`, vin: true, id: Id() };
  }
  return { value: `${coefficient}x`, vin: false, id: Id() };
};

export const getButtonTitleLimbo = (gameState: string): string => {
  switch (gameState) {
    case ELimboCondition.autoNotStarted:
      return 'common.games.start.auto.bet';
    case ELimboCondition.autoStarted:
      return 'common.games.stop.auto.bet';
    default:
      return 'common.games.bet.button';
  }
};

export const isDisabledButtonLimbo = (gameState: string): boolean => {
  switch (gameState) {
    case ELimboCondition.autoStarted:
      return true;
    case ELimboCondition.getStarted:
      return true;
    default:
      return false;
  }
};

export const isDisabledMainButtonLimbo = (gameState: string): boolean => {
  switch (gameState) {
    case ELimboCondition.getStarted:
      return true;
    default:
      return false;
  }
};

export const setAllHistoryLimbo = (
  state: HistoryElem[],
  result: HistoryElem,
  token: Token,
  betAmountValue: string,
): HistoryElem[] => {
  const date = new Date();
  const isBlack = !state[0]?.isBlack;
  const object = {
    num: lessThen(result.payout, '0.00000001') ? `${result.coefficient}x` : `${result.coefficient}x`,
    result: Number(result.payout) > 0 ? 'win' : 'lose',
    createdAt: date,
    id: result.id,
    amount: betAmountValue,
    multiplier: `${result.multiplier}x`,
    payout: result.payout,
    displayName: token.name,
    isBlack,
  };
  return [object, ...state.slice(0, 30)];
};

export const getAllHistoryLimbo = (
  state: Array<{
    multiplier: string;
    payout: string;
    createdAt: string;
    id: string;
    amount: string;
    isAuto: boolean;
    tokenCode: string;
    displayName: string;
    isBlack: boolean;
    coefficient: string;
    result: {
      coefficient: number;
    };
  }>,
): Array<{ num: string; result: string }> => {
  if (!state?.length || !state) return [];
  return state.map((el) => ({
    num: lessThen(el.payout, '0.00000001') ? `${el.result.coefficient}x` : `${el.result.coefficient}x`,
    result: Number(el.payout) > 0 ? 'win' : 'lose',
    createdAt: el.createdAt,
    id: el.id,
    amount: el.amount,
    isAuto: el.isAuto,
    multiplier: lessThen(el.payout, '0.00000001') ? '0x' : `${el.multiplier}x`,
    payout: el.payout,
    tokenCode: el.tokenCode,
    displayName: el.displayName,
    isBlack: el.isBlack,
    betId: el.id,
  }));
};
