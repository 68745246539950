import React from 'react';
import clsx from 'clsx';

import { useLimbo } from 'context/contextLimbo/contextLimbo';

import { isAnimateLimboImages, isDisplayShip } from 'func/prepareDataLimbo';

import moon from 'assets/img/Limbo/moon.webp';
import saturn from 'assets/img/Limbo/saturn.webp';
import bricksRight from 'assets/img/Limbo/bricsRight.webp';
import bricksLeft from 'assets/img/Limbo/bticksLeft.webp';
import moonBottom from 'assets/img/Limbo/moonBottom.webp';
import ship from 'assets/img/Limbo/ship.webp';
import shipFly from 'assets/img/Limbo/shipFly.webp';

import styles from './styles.module.scss';

const LimboImages: React.FC = () => {
  const [state] = useLimbo();

  const { gameCondition, fastMode, switchShip } = state;

  return (
    <div className={styles.limboImagesBlock}>
      <div className={styles.limboImagesWrap}>
        <img
          src={moon}
          className={clsx(
            styles.limboImagesMoon,
            isAnimateLimboImages(gameCondition, fastMode, switchShip) && styles.limboAnim,
          )}
          alt="moon"
        />
        <img
          src={saturn}
          className={clsx(
            styles.limboImagesSaturn,
            isAnimateLimboImages(gameCondition, fastMode, switchShip) && styles.limboAnim,
          )}
          alt="saturn"
        />
        <img
          src={bricksRight}
          className={clsx(
            styles.limboImagesBrickRight,
            isAnimateLimboImages(gameCondition, fastMode, switchShip) && styles.limboAnim,
          )}
          alt="bricks"
        />
        <img
          src={bricksLeft}
          className={clsx(
            styles.limboImagesBrickLeft,
            isAnimateLimboImages(gameCondition, fastMode, switchShip) && styles.limboAnim,
          )}
          alt="bricks"
        />
        <img
          src={moonBottom}
          className={clsx(
            styles.limboImagesMoonBottom,
            isAnimateLimboImages(gameCondition, fastMode, switchShip) && styles.limboAnim,
          )}
          alt="moon"
        />
        {!fastMode && (
          <img
            src={ship}
            className={clsx(
              styles.limboImagesShip,
              isDisplayShip(gameCondition, false, switchShip) && styles.shipVisible,
            )}
            alt="moon"
          />
        )}
        {!fastMode && (
          <img
            src={shipFly}
            className={clsx(
              styles.limboImagesShipFly,
              isDisplayShip(gameCondition, true, switchShip) && styles.shipVisible,
            )}
            alt="moon"
          />
        )}
      </div>
    </div>
  );
};

export default LimboImages;
