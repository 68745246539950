import clsx from 'clsx';

import { IDropdownWithTitleLayout } from './types';
import styles from './styles.module.scss';

const DropdownWithTitleLayout: React.FC<IDropdownWithTitleLayout> = ({
  onBlur,
  disable,
  title,
  open,
  options,
  onOpen,
}) => {
  return (
    <div className={clsx(styles.wrapper, disable ? styles.disabled : '')}>
      <div
        tabIndex={0}
        onClick={onOpen}
        className={clsx(styles.header, disable ? styles.disabled : '')}
        onBlur={onBlur}
      >
        <p className={styles.titleItem}>{title}</p>
        <i className={clsx(styles.arrowDrop, open ? styles.up : styles.down)} />
      </div>
      <div className={clsx(styles.body, open ? styles.open : '')}>
        {options.map((o, index) => (
          <p
            className={styles.titleItem}
            key={o.title}
            tabIndex={index + 1}
            onClick={() => {
              o.func();
              onOpen();
            }}
          >
            {o.title}
          </p>
        ))}
      </div>
    </div>
  );
};

export default DropdownWithTitleLayout;
