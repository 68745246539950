import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

export const GamesRulesStyle = styled.div`
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    width: 100%;
  }

  .slick-dots li button {
    cursor: url(${point}), auto !important;
  }

  .slick-dots li button:before {
    font-size: 12px;
    opacity: 1;
    color: #d9d9d9;
    cursor: url(${point}), auto !important;
  }

  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #6579ff;
    cursor: url(${point}), auto !important;
  }
`;
