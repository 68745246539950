import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

const LotterySliderStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  padding: 0 30px;
  overflow: hidden;
  position: relative;

  .lottery-slider {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slick-list {
    min-width: 130px;
  }

  .slide-lottery {
    width: auto;
    display: flex !important;
    justify-content: center;
    align-items: center;

    h5 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      margin: 0;
      display: flex;
      width: auto;
      padding: 6px 10px;

      color: #ffffff;

      background: rgba(255, 255, 255, 0.3);
      border-radius: 16px;
    }
  }

  .slider-arrow.left {
    position: absolute;
    top: 10px;
    left: 3px;
    border: 1px solid #ffffff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    height: 10px;
    cursor: url(${point}), auto !important;
    transform: rotate(135deg);
  }
  .slider-arrow.right {
    position: absolute;
    top: 10px;
    right: 3px;
    border: 1px solid #ffffff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    height: 10px;
    cursor: url(${point}), auto !important;
    transform: rotate(315deg);
  }

  .slider-arrow.left.disable,
  .slider-arrow.right.disable {
    pointer-events: none;
    opacity: 0.5;
  }

  @media (max-width: 1300px) {
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 580px) {
    //margin: -5px 0 -9px 0;
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 480px) {
  }
`;

export default LotterySliderStyle;
