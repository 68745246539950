import { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';

import collectAllIcon from 'assets/img/common/collectAllIcon.webp';
import lockIcon from 'assets/img/common/lockWithSircle.webp';

import { useAppSelector } from 'hooks/useAppSelector';
import { popUps } from 'components/constants/constants';
import { ContextPopUps } from 'context';
import { amount, getImgSrc } from 'func/common';

import DropDown from 'components/Base/DropDown';
import CardWrapper from 'components/Base/CardWrapper';
import Button from 'components/Base/Button';
import CurrenciesTemplate from 'components/Base/CurrensiesTemplate';
import { ButtonType } from 'components/Base/Button/types';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';

import { IBalanceCardSlide } from './types';
import styles from './styles.module.scss';

const BalanceCardSlide: React.FC<IBalanceCardSlide> = ({
  subtitle,
  title,
  titleState,
  setTitleState,
  handleCollect,
  disabled,
  balanceData,
  collectedData,
  content,
  isStarToken,
  setCollectToken,
  convertedBalance,
  blurIfNotAuth,
  zIndex,
  marginBottom,
}) => {
  const token = useAppSelector(userToken);
  const { translate, language } = useLocalization();

  const { setPopUpsOpen } = useContext(ContextPopUps);
  const [dropDownInfo, setDropDownInfo] = useState(null);

  useEffect(() => {
    if (convertedBalance && language) {
      const prepareData = convertedBalance.map((elem) => {
        return {
          title: `${amount(elem?.title || '0')} ${elem.displayName}`,
          img: getImgSrc(elem.displayName),
          tokenCode: elem.src,
          func: () => setCollectToken(elem?.src),
        };
      });
      const firstElem = {
        title: translate('dividends.balance.dropdown.top.text'),
        img: collectAllIcon,
        func: () => setCollectToken(null),
      };
      setDropDownInfo([firstElem, ...prepareData]);
    }
  }, [convertedBalance, language]);

  const handleBalance = () => setTitleState(false);
  const handleCollected = () => setTitleState(true);
  const handleLogIn = () => setPopUpsOpen(popUps.login);

  if (!dropDownInfo) return null;

  return (
    <CardWrapper height="auto" content={content} zIndex={zIndex} marginBottom={marginBottom}>
      <div className={styles.balanceCardWrap}>
        <div className={styles.balanceCardTitleDkt}>
          <div className={styles.balanceCardTitleElem1}>
            <h3
              className={clsx(
                styles.balanceCardTitleElem1Title,
                !titleState ? styles.balanceCardTitleElem1TitleActive : '',
              )}
              onClick={handleBalance}
            >
              {title}
            </h3>
            <h4
              className={clsx(
                styles.balanceCardTitleElem1Title,
                titleState ? styles.balanceCardTitleElem1TitleActive : '',
              )}
              onClick={handleCollected}
            >
              {translate('dividends.balance.collected.title')}
            </h4>
          </div>
          <div className={styles.balanceCardTitleElem2}>
            <h5>{subtitle}</h5>
          </div>
        </div>
        <div className={styles.balanceCardTitleMob}>
          <div className={styles.balanceCardTitleElem1}>
            <h3
              className={clsx(
                styles.balanceCardTitleElem1Title,
                !titleState ? styles.balanceCardTitleElem1TitleActive : '',
              )}
              onClick={handleBalance}
            >
              {translate('dividends.balance.title.mobile')}
            </h3>
            <h4
              className={clsx(
                styles.balanceCardTitleElem1Title,
                titleState ? styles.balanceCardTitleElem1TitleActive : '',
              )}
              onClick={handleCollected}
            >
              {translate('dividends.balance.collected.title.mobile')}
            </h4>
          </div>
          <div className={styles.balanceCardTitleElem2}>
            <h5>{subtitle}</h5>
          </div>
        </div>
        <div className={styles.balanceCardBody}>
          <>
            <div className={styles.balanceCardSlideBody}>
              <div className={styles.balanceCardSlideBodyWrap}>
                {!titleState ? (
                  <>
                    <div className={styles.balanceCardSlideBodyElemTop}>
                      <div className={styles.balanceCardSlideBodyElemTitle}>
                        <DropDown data={dropDownInfo} width="95%" textPadding="50px" tokens />
                      </div>
                      <div className={clsx(styles.balanceCardButton, !disabled ? styles.active : '')}>
                        <Button
                          className={styles.button}
                          variant={ButtonType.secondary}
                          onclick={handleCollect}
                          width="118px"
                          color="#2B3E65"
                          title={translate('dividends.balance.button.title')}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                    <div className={styles.balanceCardSlider}>
                      <CurrenciesTemplate currencies={balanceData} isStarToken={isStarToken} />
                    </div>
                  </>
                ) : (
                  <div className={styles.balanceCardSlider}>
                    <CurrenciesTemplate currencies={collectedData} isStarToken={isStarToken} />
                  </div>
                )}
              </div>
            </div>
            {!token && blurIfNotAuth && (
              <div className={styles.balanceCardBlur}>
                <div className={styles.balanceCardBlurContent}>
                  <img src={lockIcon} alt="lock" />
                  <h6>{translate('cards.not.auth')}</h6>
                  <Button
                    variant={ButtonType.primary}
                    title={translate('cards.not.auth.button')}
                    width="130px"
                    onclick={handleLogIn}
                  />
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </CardWrapper>
  );
};

export default BalanceCardSlide;
