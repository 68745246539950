import { useContext } from 'react';

import monster from 'assets/img/common/monster.webp';

import { ContextPopUps } from 'context';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import ButtonSubmit from 'components/Base/PopUps/components/ButtonSubmit';

import styles from './styles.module.scss';

const BlockedIp: React.FC = () => {
  const { translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const handleClose = () => setPopUpsOpen(null);

  return (
    <div className={styles.blockedIpWrap}>
      <div className={styles.blockedIpTitle}>{translate('popups.blocked-ip.title')}</div>
      <div className={styles.blockedIpBody}>
        <h3>{translate('popups.blocked-ip.desc')}</h3>
        <img src={monster} alt="icon" className={styles.blockedIpImage} />
      </div>
      <ButtonSubmit
        className={styles.button}
        handleButton={handleClose}
        variant={ButtonType.primary}
        width="288px"
        title={translate('general.buttons.close')}
        color="#242c45"
      />
    </div>
  );
};

export default BlockedIp;
