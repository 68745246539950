import { gql } from '@apollo/client';

export const GET_FOOTER_LOGOS = gql`
  query footerLogos {
    footerLogos {
      id
      logo
      weight
    }
  }
`;
