import React from 'react';
import clsx from 'clsx';

import { amount, getImgSrc } from 'func/common';

import styles from './styles.module.scss';
import { ICurrencyTemplate } from './types';

const CurrenciesTemplate: React.FC<ICurrencyTemplate> = ({ currencies, size }) => {
  return (
    <div className={styles.currencyWrap}>
      <div className={styles.currencyScrollWrap}>
        {currencies.map((el) => (
          <div
            key={`${el.displayName}${el.title}`}
            className={clsx(styles.currencyItem, size ? styles.currencyItemSmall : '')}
          >
            <img src={getImgSrc(el.src)} alt={el.displayName} />
            <div className={styles.currencyToken}>{`${amount(el.title)} ${el.displayName}`}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CurrenciesTemplate;
