import { IOption } from 'components/Base/Select/types';
import { IBreadCrumb } from 'components/Base/BreadCrumbs/types';
import { Links } from '.';

export enum BlogSort {
  date = 'date',
  popular = 'popular',
}

export const BLOG_SORT_OPTIONS: IOption[] = [
  { value: BlogSort.date, label: 'Date' },
  { value: BlogSort.popular, label: 'Popular' },
];

export const ARTICLES_PER_PAGE = 8;

export const ARTICLE_BREAD_CRUMBS: IBreadCrumb[] = [
  { title: 'Blog', link: Links.blog },
  { title: 'Article', link: '' },
];
