import { useContext } from 'react';
import clsx from 'clsx';

import logoIcon from 'assets/default/logo.svg';
import IntercomContext from 'context/contextIntercom/context';

import { INTERCOM_LAUNCHER_ID } from './constants';
import styles from './styles.module.css';

const IntercomLauncher: React.FC = () => {
  const { launcherHidden } = useContext(IntercomContext);

  return (
    <div id={INTERCOM_LAUNCHER_ID} className={clsx(styles.launcher, launcherHidden ? styles.hidden : '')}>
      <img src={logoIcon} alt="Starbets logo" width={30} height={30} />
    </div>
  );
};

export default IntercomLauncher;
