import { useContext } from 'react';

import NotificationContext from 'context/contextNotifications/context';

import NotificationPreview from './components/NotificationPreview';
import CustomBonusNotification from './components/NotificationPreview/CustomBonusNotification';
import styles from './styles.module.scss';
import { CustomNotificationType } from '../../types';

const NotificationsLayout: React.FC = () => {
  const { pendingNotifications } = useContext(NotificationContext);

  return (
    <ul className={styles.wrapper}>
      {pendingNotifications.map((n) => {
        if (
          n.customType === CustomNotificationType.customBonusSuccess ||
          n.customType === CustomNotificationType.customBonusReject
        ) {
          return <CustomBonusNotification key={n.id} notification={n} />;
        }
        return <NotificationPreview key={n.id} notification={n} />;
      })}
    </ul>
  );
};

export default NotificationsLayout;
