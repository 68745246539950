import styled from 'styled-components';

export const MissionsStageGameStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 300px;
  padding-bottom: 30px;

  .missions__games--images__wrap {
    background: radial-gradient(100% 100% at 50% 0%, rgba(231, 44, 235, 0.4) 0%, rgba(36, 44, 69, 0.24) 100%);
    margin-top: -20px;
    padding-top: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .missions__games--images__image1 {
    display: inline-block;
    width: 70%;
  }

  .missions__games--images__image2 {
    display: inline-block;
    width: 20%;
    height: auto;
  }

  .missions__games--title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    margin: 15px 0;
    color: #ffffff;
  }

  .missions__games--description {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 67px;
  }

  .missions__games--description__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      display: inline-block;
      width: 54px;
    }
    h4 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 8.05634px;
      line-height: 15px;
      color: #ffffff;
      margin: 5px 0 0;
    }
    h5 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      margin: 0;
    }
  }

  .missions__games--progress--wrap {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin: 20px 0 40px;
  }

  .missions__games--progress-item-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 78px;
    height: max-content;
  }

  .missions__games--progress--item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 78px;
    height: 78px;
    position: relative;

    h4 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      margin: 0;
    }
    h5 {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #888aa7;
      margin: 0;
    }
  }

  .missions__games--progress--item-check {
    position: absolute;
    top: 7px;
    right: -5px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: none;
    overflow: hidden;
    z-index: 5;
    background: #242c45;
    padding: 5px;
  }

  .missions__games--progress--item-check.active {
    display: inline-block;
  }

  .missions__games--progress--tooltip {
    margin: 4px 0;

    font-family: Montserrat;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #888aa7;
  }

  @media screen and (max-width: 540px) {
    .missions__games--description {
      padding: 0 41px;
    }
  }
`;
