import { amount, getImgSrc, prepareAmount } from 'func/common';
import dayjs from 'dayjs';

import React from 'react';
import { Wallet } from '../types/requestTypes';
import { bigOrEqual } from './prepareDataDice';
import { ETokenCode } from '../components/constants/constants';
import { EWheelImg } from '../components/constants/games';
import { IBuyCryptoCurrency } from '../components/Base/PopUps/components/BuyCrypto/components/BuyCryptoContent/types';
import { BuyCryptoType, ChangellyTokenCode } from '../components/Base/PopUps/components/BuyCrypto/types';

interface Networks {
  network: {
    code: string;
  };
}

interface NetworksList {
  code: string;
  explorer?: string;
  name?: string;
  standard?: string;
}

interface TokenData {
  tokenCode: string;
  displayName: string;
  name: string;
  networks: [
    {
      network: {
        code: string;
      };
    },
  ];
}

interface TokenDataDeposit {
  title: string;
  img: string;
  tokenCode: string;
  networks: Networks[];
  func: () => void;
}

interface TokenDeposit {
  token: string;
  name: string;
}

interface DataImageWheel {
  id: string;
  prize: string;
  weight: number;
  rankId: number;
  displayName: string;
  tokenCode: string;
}
export interface DataImageWheelSorted {
  id: string;
  prize: string;
  weight: number;
  rankId: number;
  displayName: string;
  tokenCode: string;
  image: string;
  index: number;
  rotation: number;
}

interface IVaultDropBalance {
  title: string;
  tokenCode: string;
  displayName: string;
  img: string;
  func: VoidFunction;
  balance: string;
}

type StringSetter = (elem: string) => void;

export const filterNetworksDeposit = (
  firstElemNetworks: Networks[],
  networks: NetworksList[],
  dropSetter: (p: Array<{ title: string } | { func: () => void; title: string }>) => void,
  selectSetter: StringSetter,
  setErrorValidation?: (el: boolean) => void,
) => {
  const currentNetworks = firstElemNetworks.map((el) => el.network.code);
  const networksList = networks.filter((elem) => currentNetworks.includes(elem.code));

  if (networksList.length > 1) {
    const result = networksList.map((elem) => ({
      title: `${elem.standard}`,
      func: () => {
        selectSetter(elem.code);
        if (setErrorValidation) setErrorValidation(null);
      },
    }));

    dropSetter([{ title: '' }, ...result]);
    selectSetter(null);
  } else {
    dropSetter(networksList.map((elem) => ({ title: `${elem.standard}` })));
    selectSetter(networksList[0]?.code ? networksList[0]?.code : '');
  }
};

export const filterNetworksAddressBook = (
  firstElemNetworks: Networks[],
  networks: NetworksList[],
  selectedNetwork: string,
  selectNetworkSetter: (el: string) => void,
): Array<{ title: string; func: (el: string) => void }> => {
  const currentNetworks = firstElemNetworks.map((el) => el.network.code);
  const networksList = networks.filter((elem) => currentNetworks.includes(elem.code));
  if (firstElemNetworks.length === 1) {
    const result = networksList.map((elem) => ({
      title: `${elem.standard}`,
      func: () => selectNetworkSetter(elem.code),
    }));
    return result;
  }
  const firstEl = networksList.filter((el) => el.code === selectedNetwork);
  const rest = [...firstEl, ...networksList.filter((el) => el.code !== selectedNetwork)];
  const result = rest.map((elem) => ({
    title: `${elem.standard}`,
    func: () => selectNetworkSetter(elem.code),
  }));
  return result;
};

export const getCurrencyDropDeposit = (
  tokenData: TokenData[],
  networks: NetworksList[],
  setNetworkDropdown: (p: Array<{ title: string } | { func: () => void; title: string }>) => void,
  setSelectedNetwork: StringSetter,
  setTokenDeposit: (el: TokenDeposit) => void,
): TokenDataDeposit[] =>
  tokenData.map((elem) => ({
    title: elem.displayName,
    img: getImgSrc(elem.displayName),
    tokenCode: elem.tokenCode,
    networks: elem.networks,
    func: () => {
      setSelectedNetwork(null);
      filterNetworksDeposit(elem.networks, networks, setNetworkDropdown, setSelectedNetwork);
      setTokenDeposit({
        token: elem.tokenCode,
        name: elem.displayName,
      });
    },
  }));

export const getMinimumDeposit = (
  array: Array<{ network: { code: string }; minimumDeposit: string }>,
  token: string,
): string => {
  const res = array.find((el) => el.network.code === token);
  if (res) {
    return String(amount(res.minimumDeposit));
  }
  return '';
};

export const getMinimumWithdraw = (
  array: Array<{ network: { code: string }; minimumWithdrawal: string; withdrawalFee: string }>,
  token: string,
): string => {
  if (!array || !token) return '0';
  const res = array.find((el) => el.network.code === token);
  if (res) {
    return res.minimumWithdrawal;
  }
  return '0';
};

export const getMinimumWithdrawFee = (
  array: Array<{ network: { code: string }; minimumWithdrawal: string; withdrawalFee: string }>,
  token: string,
): string => {
  if (!array || !token) return '0';
  const res = array.find((el) => el.network.code === token);
  if (res) {
    return res.withdrawalFee;
  }
  return '0';
};

export const cutWalletAddressWithdraw = (address: string): string => {
  const first = address.slice(0, 4);
  const last = address.slice(-4);
  return `${first}...${last}`;
};

export const getWheelFortuneClass = (finalRotation: number): string => {
  if (finalRotation || finalRotation === 0) return 'wheel__images--wrap active';
  return 'wheel__images--wrap';
};

const getWheelImage = (ind: number): string => {
  switch (ind) {
    case 1:
      return EWheelImg.item1;
    case 2:
      return EWheelImg.item2;
    case 3:
      return EWheelImg.item3;
    case 4:
      return EWheelImg.item4;
    case 5:
      return EWheelImg.item5;
    case 6:
      return EWheelImg.item6;
    case 7:
      return EWheelImg.item7;
    case 8:
      return EWheelImg.item8;
    default:
      return '';
  }
};

const getWheelImageRotation = (ind: number): number => {
  switch (ind) {
    case 8:
      return 0;
    case 7:
      return 315;
    case 6:
      return 270;
    case 5:
      return 225;
    case 4:
      return 180;
    case 3:
      return 135;
    case 2:
      return 90;
    case 1:
      return 45;
    default:
      return 0;
  }
};

export const getDataWin = (rank: number, array: DataImageWheel[], url: string): DataImageWheelSorted[] => {
  const filtered = array.filter((el) => el.rankId === rank);
  const sorted = filtered.sort(function (a, b) {
    return a.weight === b.weight ? 0 : a.weight > b.weight ? 1 : -1;
  });
  return sorted.map((el, ind) => ({
    ...el,
    index: ind + 1,
    image: `${url}${getWheelImage(ind + 1)}`,
    rotation: getWheelImageRotation(ind + 1),
  }));
};

export const isDisplayWheelTimer = (date: string, hoursLimit: number): boolean => {
  if (!date) return true;
  const dateNow = new Date();
  const dateUntilStop = dayjs(date).add(hoursLimit, 'minutes').utc();

  return dayjs(dateUntilStop).isBefore(dateNow);
};

export const getVaultWalletsList = (
  list: Wallet[],
  setSelectedToken: (el: TokenDeposit) => void,
  selectedToken: { token: string; name: string },
  setInputValue: (el: string) => void,
  setSelectedTokenGlobal: (el: TokenDeposit) => void,
  setSelectedNetwork: (el: string) => void,
): IVaultDropBalance[] => {
  if (!list?.length) return [];
  const fullList = list.map((item) => ({
    title: item.token.displayName,
    tokenCode: item.token.tokenCode,
    displayName: item.token.displayName,
    img: getImgSrc(item.token.displayName),
    balance: String(amount(item.availableBalance)),
    func: () => {
      setSelectedToken({ token: item.token.tokenCode, name: item.token.displayName });
      setSelectedTokenGlobal({ token: item.token.tokenCode, name: item.token.displayName });
      setInputValue('');
      setSelectedNetwork(null);
    },
  }));
  const firstEl = fullList.find((el) => el.tokenCode === selectedToken.token);
  let result;
  if (firstEl) {
    result = [firstEl, ...fullList.filter((el) => el.tokenCode !== selectedToken.token)];
  } else {
    result = [...fullList];
  }
  return result.filter((el) => el.tokenCode !== ETokenCode.STAR);
};

export const handleChangeInputMax = (
  event: React.ChangeEvent<HTMLInputElement>,
  setValue: (el: string) => void,
  maxValue: string,
): void => {
  let value = '';
  value = event.target.value;
  const valid = /^\\-?\d+\.\d*$|^\\-?[\d]*$/;
  const number = /\\-\d+\.\d*|\\-[\d]*|[\d]+\.[\d]*|[\d]+/;

  if ((event.nativeEvent as any).inputType === 'deleteContentBackward') {
    setValue(event.target.value);
    return;
  }
  if (value === '00') {
    setValue('0.0');
    return;
  }
  if (value === '0.0000000') {
    setValue('0.00000001');
    return;
  }

  const formattedAmount = prepareAmount(value);
  const formattedMaxAmount = prepareAmount(maxValue);

  if (value && bigOrEqual(formattedAmount, formattedMaxAmount)) {
    setValue(String(amount(maxValue, false)));
    return;
  }
  if (!valid.test(value)) {
    const n = value.match(number);
    value = n ? n[0] : '';
  }
  setValue(value);
};

export const getTokenCodeExcludeStar = (token: TokenDeposit): TokenDeposit => {
  if (token.token === ETokenCode.STAR) {
    return { token: ETokenCode.BTC, name: ETokenCode.BTC };
  }
  return token;
};

export const formatBuyCryptoCurrencies = (
  currencies: IBuyCryptoCurrency[],
  type: BuyCryptoType,
  selectedCurrency: string,
): IBuyCryptoCurrency[] => {
  const filteredCurrencies = currencies.filter((c) => c.type === type);
  const sortedCurrencies = filteredCurrencies.sort((c) => (c.ticker === selectedCurrency ? -1 : 1));
  return sortedCurrencies;
};

export const handleChangeInputNumber = (event: React.ChangeEvent<HTMLInputElement>): string => {
  let value = '';
  value = event.target.value;
  const valid = /^\\-?\d+\.\d*$|^\\-?[\d]*$/;
  const number = /\\-\d+\.\d*|\\-[\d]*|[\d]+\.[\d]*|[\d]+/;

  if ((event.nativeEvent as any).inputType === 'deleteContentBackward') {
    return value;
  }
  if (value === '00') {
    return '0';
  }

  if (!valid.test(value)) {
    const n = value.match(number);
    value = n ? n[0] : '';
  }
  return value;
};

export const getChangeLlyToken = (token: string): string => {
  switch (token) {
    case ChangellyTokenCode.USDT:
      return ETokenCode.USDT;
    case ChangellyTokenCode.BSC:
      return ETokenCode.BSC;
    default:
      return token;
  }
};
