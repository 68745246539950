import React from 'react';
import dividendsIcon from 'assets/img/PageTitles/Dividends_Icon.svg';
import PageTitle from 'components/Base/PageTitle';
import { useLocalization } from 'components/Internationalization';

const Title: React.FC = () => {
  const { translate } = useLocalization();
  const title = translate('dividends.title');
  return (
    <>
      <PageTitle icon={dividendsIcon} title={title} lineColor="#69951A" titleSize="small" largeImage />
    </>
  );
};

export default Title;
