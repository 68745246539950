import React, { useContext, useEffect, useRef } from 'react';
import { useMutation } from '@apollo/client';
import randomstring from 'randomstring';
import { sha3_256 } from 'js-sha3';
import { pathOr } from 'ramda';

import { useAppSelector } from 'hooks/useAppSelector';
import { useLimbo } from 'context/contextLimbo/contextLimbo';
import { ContextPopUps, ContextTokenCode, ContextWallet } from 'context';
import LiveStatsContext from 'context/contextLiveStats/context';
import { getButtonTitleLimbo, isDisabledMainButtonLimbo } from 'func/prepareDataLimbo';
import { removeComas } from 'func/common';
import { lessThen } from 'func/prepareDataDice';
import { isAutoBetCanRun } from 'func/commonGames';
import { AUTO_BETTING_STATE, popUps } from 'components/constants/constants';
import { ELimboCondition } from 'components/constants/games';
import useGameSettings from 'hooks/useGameSettings';
import { Types } from 'context/contextLimbo/limboReduser';
import { COMMIT_LIMBO_HASH, PLAY_LIMBO_BET } from 'graphQl/mutations/limbo/limbo';
import { userToken } from 'store/user/user.selectors';
import { useLocalization } from 'components/Internationalization';

import GameBetButton from 'components/Games/base/GameBetButton';

import styles from './styles.module.scss';

let timer;

const LimboSubmitButton: React.FC = () => {
  const { translate } = useLocalization();
  const token = useAppSelector(userToken);

  const [state, dispatch] = useLimbo();
  const { tokenCode } = useContext(ContextTokenCode);
  const { walletUser } = useContext(ContextWallet);
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { enabled: liveStatsEnabled, onBet: recordLiveStats } = useContext(LiveStatsContext);

  const {
    isHotkeys,
    gameCondition,
    gameId,
    betAmountValue,
    optionInputValue,
    isAuto,
    gameSettings,
    fastMode,
    requestId,
    autoState,
  } = state;

  const currentGameSettings = useGameSettings(gameId);

  const gameConditionRef = useRef(gameCondition);
  const firstSpinRef = useRef(true);
  const startBetAmountRef = useRef('');

  const handleSignUp = () => setPopUpsOpen(popUps.registration);

  const [playLimboBet] = useMutation(PLAY_LIMBO_BET, { fetchPolicy: 'no-cache' });
  const [commitLimboHash] = useMutation(COMMIT_LIMBO_HASH, { fetchPolicy: 'no-cache' });

  const handleChangeAutoState = (name: string, value: boolean | string) => {
    dispatch({ type: Types.CHANGE_AUTO_STATE, payload: { name, value } });
  };

  const handleRequestBet = async () => {
    const currentBetAmount = removeComas(betAmountValue);
    const seed = randomstring.generate({ length: 32, charset: 'alphabetic' });
    try {
      const response1 = await commitLimboHash({
        variables: {
          hash: sha3_256(seed),
        },
      });
      const response2 = await playLimboBet({
        variables: {
          bet: {
            stateId: response1.data.commitLimboHash.stateId,
            gameId,
            seed,
            token: tokenCode.token,
            wager: currentBetAmount,
            prediction: Number(optionInputValue),
            auto: isAuto,
          },
        },
      });

      setTimeout(
        () => {
          if (liveStatsEnabled) {
            const bet = pathOr(null, ['data', 'playLimboBet'], response2);

            recordLiveStats(currentBetAmount, bet?.payout || '0');
          }

          dispatch({
            type: Types.BET_RESPONSE,
            payload: {
              bet: response2.data.playLimboBet,
              tokenCode,
              firstSpin: firstSpinRef.current,
              startBetAmount: startBetAmountRef.current,
            },
          });
          firstSpinRef.current = false;
        },
        document.hidden ? 0 : fastMode ? 0 : 1500,
      );
    } catch (e) {
      dispatch({ type: Types.ERROR });
      console.log(e.message);
    }
  };

  const handleBet = async () => {
    if (!token) return;
    if (lessThen(walletUser, gameSettings.minBet) || lessThen(walletUser, betAmountValue)) {
      setPopUpsOpen({
        modalOpen: popUps.walletNavigation,
        data: {
          config: popUps.deposit,
          active: popUps.deposit,
        },
      });
      return;
    }
    dispatch({ type: Types.START_BET });
    gameConditionRef.current = ELimboCondition.getStarted;
    await handleRequestBet();
    gameConditionRef.current = ELimboCondition.notStarted;
  };

  const handleAutoBet = async () => {
    if (!token) return;
    if (lessThen(walletUser, gameSettings.minBet) || lessThen(walletUser, betAmountValue)) {
      setPopUpsOpen({
        modalOpen: popUps.walletNavigation,
        data: {
          config: popUps.deposit,
          active: popUps.deposit,
        },
      });
      dispatch({ type: Types.CHANGE_GAME_CONDITION, payload: { gameCondition: ELimboCondition.autoNotStarted } });
      gameConditionRef.current = ELimboCondition.autoNotStarted;
      firstSpinRef.current = true;
      return;
    }
    if (autoState.firstSpin) {
      handleChangeAutoState(AUTO_BETTING_STATE.autoBetAmount, removeComas(betAmountValue));
    }

    const currentBetAmount = !firstSpinRef.current ? autoState.autoBetAmount : removeComas(betAmountValue);

    if (
      !isAutoBetCanRun(autoState, currentBetAmount, gameSettings.minBet, handleChangeAutoState) ||
      gameConditionRef.current === ELimboCondition.autoNotStarted
    ) {
      dispatch({ type: Types.CHANGE_GAME_CONDITION, payload: { gameCondition: ELimboCondition.autoNotStarted } });
      gameConditionRef.current = ELimboCondition.autoNotStarted;
      firstSpinRef.current = true;
      return;
    }

    dispatch({ type: Types.BEFORE_EVERY_AUTO_BET });

    await handleRequestBet();
  };

  const handleSubmit = () => {
    if (!token) {
      handleSignUp();
      return;
    }
    if (lessThen(walletUser, gameSettings.minBet) || lessThen(walletUser, betAmountValue)) {
      setPopUpsOpen({
        modalOpen: popUps.walletNavigation,
        data: {
          config: popUps.deposit,
          active: popUps.deposit,
        },
      });
      return;
    }
    if (gameCondition === ELimboCondition.notStarted) {
      handleBet();
      return;
    }
    if (gameCondition === ELimboCondition.autoNotStarted) {
      gameConditionRef.current = ELimboCondition.autoStarted;
      startBetAmountRef.current = betAmountValue;
      firstSpinRef.current = true;
      dispatch({ type: Types.START_AUTO_BET });
      handleAutoBet();
      return;
    }
    if (gameCondition === ELimboCondition.autoStarted) {
      dispatch({ type: Types.STOP_AUTO_BET });
      gameConditionRef.current = ELimboCondition.autoNotStarted;
      clearTimeout(timer);
    }
  };

  useEffect(() => {
    if (requestId) {
      if (gameConditionRef.current === ELimboCondition.autoStarted) {
        if (fastMode) {
          handleAutoBet();
        } else if (document.hidden) {
          handleAutoBet();
        } else {
          setTimeout(async () => handleAutoBet(), document.hidden ? 0 : 300);
        }
      } else {
        gameConditionRef.current = ELimboCondition.autoNotStarted;
        clearTimeout(timer);
      }
    }
  }, [requestId]);

  useEffect(() => {
    if (currentGameSettings.minBet) {
      dispatch({
        type: Types.CHANGE_TOKEN_CODE,
        payload: { gameSettings: currentGameSettings },
      });
      gameConditionRef.current = ELimboCondition.notStarted;
      clearTimeout(timer);
    }
  }, [currentGameSettings]);

  return (
    <div className={styles.limboSubmitButtonWrap}>
      <GameBetButton onClick={handleSubmit} disable={isDisabledMainButtonLimbo(gameCondition)} isHotkeys={isHotkeys}>
        <h5 className={styles.limboSubmitButtonText}>{translate(getButtonTitleLimbo(gameCondition))}</h5>
      </GameBetButton>
    </div>
  );
};

export default LimboSubmitButton;
