import styled from 'styled-components';

const ModeStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding-top: 10%;
  background: radial-gradient(44.11% 51.33% at 10.08% 47.02%, #442a81 0%, rgba(22, 30, 49, 0) 100%),
    radial-gradient(58.77% 68.38% at 100% 52.05%, #2654a9 0%, rgba(26, 61, 122, 0) 100%), #161e31;

  div {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 19px;
    color: #ffffff;
    text-align: center;
  }
  img {
    width: 400px;
  }

  @media (max-width: 1024px) {
    padding-top: 25%;
    div {
      font-size: 16px;
    }
    img {
      width: 340px;
    }
  }
  @media (max-width: 500px) {
    padding-top: 40%;
    div {
      font-size: 14px;
    }
    img {
      width: 280px;
    }
  }
`;

export default ModeStyle;
