import React from 'react';
import Slider, { Settings } from 'react-slick';

import { IUserBonus } from 'components/Pages/BoonusPage/components/UserBonus/types';

import styles from './styles.module.scss';
import BonusItem from './bonusItem';
import BonusItemArrows from './bonusItemArrows';

const settings: Settings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  prevArrow: <BonusItemArrows orientation="left" />,
  nextArrow: <BonusItemArrows />,
};

const BonusSlider: React.FC<{ data: IUserBonus[]; handleBonus: VoidFunction }> = ({ data, handleBonus }) => {
  return (
    <>
      <Slider className={styles.wrapperSlider} {...settings}>
        {data.map((slide) => (
          <BonusItem
            key={slide?.bonus?.name}
            name={slide?.bonus?.name}
            type={slide?.bonus?.type}
            startAt={slide?.startAt}
            endAt={slide?.endAt}
            handleBonus={handleBonus}
          />
        ))}
      </Slider>
    </>
  );
};

export default BonusSlider;
