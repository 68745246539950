/* eslint-disable @typescript-eslint/no-explicit-any */

import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

import config from 'config';

import PragmaticDGAContext from './context';
import { ITable, ITables } from './types';

const { pragmaticUrl, pragmaticCasinoId } = config;

const PragmaticDGAProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const tablesData = useRef<Record<string, ITable>>({});
  const lastPublishedAt = useRef<number>(0);

  const [connected, setConnected] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [publicTableData, setPublicTableData] = useState<Record<string, ITable>>({});
  const [tablesId, setTablesId] = useState<string[]>([]);

  const isSocketsConnected = () => {
    if (!window.dga) return false;

    return connected && window.dga.websocket?.readyState === 1;
  };

  useEffect(() => {
    return () => {
      if (window.dga) {
        disconnect();

        // eslint-disable-next-line no-console
        console.log('[PRAGMATIC_WS]: unmounted');
      }
    };
  }, []);

  useEffect(() => {
    if (!pragmaticUrl || !pragmaticCasinoId) return;

    const { dga } = window;

    if (dga && !connected) {
      dga.onConnect = handleConnected;
      dga.onMessage = handleMessage;
      dga.onWsError = handleError;
      dga.onWsClose = handleClose;

      // eslint-disable-next-line no-console
      console.log('[PRAGMATIC_WS]: connect');
      connect();
    }
  }, [window.dga, connected]);

  useEffect(() => {
    const { dga } = window;

    const socketConnected = isSocketsConnected();
    const readyToRequest = dga && socketConnected && !tablesId.length;

    if (readyToRequest) {
      dga.available(pragmaticCasinoId);
    }
  }, [window.dga, connected, tablesId]);

  useEffect(() => {
    const { dga } = window;

    const readyToRequest = dga && tablesId.length && !subscribed;

    if (readyToRequest) {
      subscribeToTables(tablesId);
      return;
    }

    setSubscribed(false);
  }, [tablesId]);

  const resetState = () => {
    setConnected(false);
    setSubscribed(false);
    setPublicTableData({});
    setTablesId([]);
  };

  function connect() {
    const { dga } = window;

    if (!dga) return;

    if (dga.websocket) {
      dga.websocket = null;
    }

    if (connected) {
      setConnected(false);
    }

    dga.tryToConnect = true;
    dga.connect(pragmaticUrl, pragmaticCasinoId);
  }

  function disconnect() {
    try {
      const { dga } = window;

      resetState();

      if (dga) {
        dga.tryToConnect = false;
        dga.disconnect();
      }
    } catch {
      // pass
    }
  }

  const subscribe = (casino: string, tableId: string) => {
    if (window.dga && connected) {
      const { dga } = window;

      dga.subscribe(casino, tableId, 'USD');
    }
  };

  function subscribeToTables(ids: string[]) {
    ids.forEach((id) => {
      if (isSocketsConnected()) {
        subscribe(pragmaticCasinoId, id);
      }
    });

    setSubscribed(true);
  }

  function handleConnected() {
    setConnected(true);
  }

  const publishData = (updateTime = true) => {
    if (updateTime) {
      const newPublishDate = dayjs().valueOf();

      lastPublishedAt.current = newPublishDate;
    }

    setPublicTableData({ ...tablesData.current });

    // eslint-disable-next-line no-console
    console.log('[PRAGMATIC_PUBLISHED]');
  };

  const updateTablesData = (table: ITable) => {
    const { tableId } = table;

    tablesData.current = { ...tablesData.current, [tableId]: table };

    if (!lastPublishedAt.current) {
      const newPublishDate = dayjs().valueOf();

      lastPublishedAt.current = newPublishDate;

      setTimeout(() => publishData(false), 2000);
      return;
    }

    const nextPublishDate = dayjs(lastPublishedAt.current).add(5, 'seconds');
    const canBePublished = dayjs().isAfter(nextPublishDate);

    if (canBePublished) {
      publishData();
    }
  };

  function handleMessage(message: any) {
    if (message?.tableKey) {
      const data = message as ITables;

      setTablesId(data.tableKey);
      return;
    }

    if (message?.tableId) {
      const data = message as ITable;

      updateTablesData(data);
    }
  }

  function handleError(message: MessageEvent<string>) {
    // eslint-disable-next-line no-console
    console.log('[PRAGMATIC_ERROR]: ', message);
  }

  function handleClose() {
    resetState();
  }

  return <PragmaticDGAContext.Provider value={{ tables: publicTableData }}>{children}</PragmaticDGAContext.Provider>;
};

export default PragmaticDGAProvider;
