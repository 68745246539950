import { useState } from 'react';
import ReactCardFlip from 'react-card-flip';

import { useLocalization } from 'components/Internationalization';

import FrontCard from './componets/FrontCard';
import BackCard from './componets/BackCard';
import styles from './styles.module.scss';

const BalanceCardReferral: React.FC = () => {
  const { translate } = useLocalization();
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal" containerClassName={styles.dividendsPoolWrap}>
      <>
        <FrontCard isFlipped={isFlipped} />
        {/* <button className={styles.fipCardButton} type="button" onClick={() => setIsFlipped(!isFlipped)}> */}
        {/*  {translate('dividends.pool.flip.button.front')} */}
        {/* </button> */}
      </>
      <>
        <BackCard />
        <button className={styles.fipCardButton} type="button" onClick={() => setIsFlipped(!isFlipped)}>
          {translate('dividends.pool.flip.button.back')}
        </button>
      </>
    </ReactCardFlip>
  );
};

export default BalanceCardReferral;
