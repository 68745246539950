import React from 'react';
import clsx from 'clsx';

import { IKenoOddsItem } from './types';
import styles from './styles.module.scss';

const KenoOddsItem: React.FC<IKenoOddsItem> = ({ isActive, hits, ods, hitsText }) => {
  return (
    <div className={clsx(styles.kenoOddsItem, isActive && styles.kenoOddsItemActive)}>
      <h4>{`${ods}x`}</h4>
      <h5>{`${hits} ${hitsText}`}</h5>
    </div>
  );
};

export default KenoOddsItem;
