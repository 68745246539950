/* eslint-disable no-underscore-dangle */
import ReCAPTCHA from 'react-google-recaptcha';
import decode from 'jwt-decode';
import dayjs from 'dayjs';
import { useContext, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useApolloClient } from '@apollo/client';
import { pathOr } from 'ramda';

import emailIcon from 'assets/img/common/emailIcon.webp';
import lockIcon from 'assets/img/common/lockIcon.webp';
import openPassword from 'assets/img/common/openPassword.webp';
import closePassword from 'assets/img/common/closePassword.webp';

import config from 'config';
import { ITokenPayload, IVerifyPayload } from 'types/requestTypes';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setToken } from 'store/user';
import { LOGIN, VERIFY_LOGIN } from 'graphQl/query/auth/auth';
import { ContextPopUps } from 'context';
import { popUps } from 'components/constants/constants';
import { AuthErrors, initialLoginState } from 'components/constants/auth';
import { setAuthToken, setRefreshToken } from 'func/auth';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';
import { getLoginValidationSchema } from 'components/Base/Input/validation';
import { useNavigate } from 'hooks/useNavigate';
import { Links } from 'components/constants';
import { DataLayerEvent, DataLayerMethod } from 'types/dataLayerTypes';
import { useAnalyticsDispatch } from 'hooks/useAnalyticsDispatch';

import ButtonSubmit from 'components/Base/PopUps/components/ButtonSubmit';
import InputCustom from 'components/Base/Input/InputCustom';
import { IDecodedToken } from 'components/LoginLayer/types';

import styles from './styles.module.scss';
import { ILoginComponent, ILoginValues } from './types';

const { recaptchaKey } = config;

const LogIn: React.FC<ILoginComponent> = ({ setCurrentEmail, data }) => {
  const { translate } = useLocalization();
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const navigate = useNavigate();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const dataLayerDispatch = useAnalyticsDispatch();

  const formik = useFormik({
    initialValues: initialLoginState,
    validationSchema: getLoginValidationSchema(translate),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [passwordShow, setPasswordShow] = useState(false);

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage('');
    }
  }, [formik.values]);

  const getCaptchaToken = async () => {
    try {
      const token = await recaptchaRef.current?.executeAsync();

      return token;
    } catch (e) {
      recaptchaRef.current?.reset();

      return null;
    }
  };

  const navigateHomePage = () => {
    if (data?.isLandingPage) {
      navigate(Links.bonus);
    }
  };

  function authUser(tokens: ITokenPayload) {
    const { authToken, refreshToken } = tokens;
    const { id } = decode<IDecodedToken>(authToken);

    setAuthToken(authToken);
    setRefreshToken(refreshToken);
    dispatch(setToken(authToken));
    dataLayerDispatch({ event: DataLayerEvent.login, user_id: id, method: DataLayerMethod.email });

    setPopUpsOpen(null);
  }

  function handleSubmitOnTwoFA(shortLivedToken: string) {
    return async (errorCodeSetter: (err: Error) => void, securityCode: string) => {
      const variables = { shortLivedToken, securityCode };

      try {
        const response = await client.query({ query: VERIFY_LOGIN, variables, fetchPolicy: 'no-cache' });
        const tokens = pathOr<ITokenPayload | null>(null, ['data', 'loginVerify'], response);

        if (tokens) {
          authUser(tokens);
          navigateHomePage();
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('[SUBMIT_ON_2FA_ERROR]: ', e);
        errorCodeSetter(e);
      }
    };
  }

  async function handleSubmit(values: ILoginValues) {
    const tokenCaptcha = await getCaptchaToken();

    if (!tokenCaptcha) return;

    const variables = {
      captcha: tokenCaptcha,
      email: values.email.toLowerCase(),
      password: values.password,
    };

    setLoading(true);
    setCurrentEmail(values.email);

    await client
      .query({ query: LOGIN, variables, fetchPolicy: 'no-cache' })
      .then((res) => {
        const responseData = pathOr<ITokenPayload | IVerifyPayload | null>(null, ['data', 'login'], res);

        if (responseData?.__typename === 'TokenPayload') {
          authUser(responseData);
        }

        if (responseData?.__typename === 'VerifyPayload') {
          const { shortLivedToken, mfaType } = responseData;

          setPopUpsOpen({
            data: {
              onSubmit: handleSubmitOnTwoFA(shortLivedToken),
              title: mfaType === 'email' ? 'Email' : 'Google',
            },
            modalOpen: popUps.verifySecurityCode,
          });
        }
        navigateHomePage();
      })
      .catch((err) => {
        recaptchaRef.current?.reset();

        switch (err.message) {
          case AuthErrors.unavailable: {
            setPopUpsOpen(popUps.blockedIp);
            break;
          }
          case AuthErrors.notVerified: {
            setPopUpsOpen({
              modalOpen: popUps.signUpConfirm,
              data: { email: formik.values.email, password: formik.values.password },
            });
            break;
          }
          case AuthErrors.accountDeleted: {
            const newErrorMessage = translate('popups.login.errors.deleted');

            setErrorMessage(newErrorMessage);
            break;
          }
          case AuthErrors.notFound:
          case AuthErrors.wrongCredentials: {
            const newErrorMessage = translate('popups.login.errors.invalid');

            setErrorMessage(newErrorMessage);
            break;
          }
          default: {
            if (err.message?.includes(AuthErrors.excluded)) {
              const dateRegex = /\[(.*?)\]/gim;
              const excludedUntil = (err.message as string).match(dateRegex);

              if (excludedUntil) {
                const [date] = excludedUntil;

                const bracketRegex = /\[|\]/gim;
                const parsedDate = date.replace(bracketRegex, '');
                const formattedDate = dayjs(parsedDate).format('MMMM ddd YYYY, h:mm:ss A');

                if (formattedDate !== 'Invalid date') {
                  const excludeError = translate('popups.login.errors.excluded').replace(
                    ':excludedUntil',
                    formattedDate,
                  );

                  setErrorMessage(excludeError);
                  break;
                }
              }
            }

            const newErrorMessage = translate('popups.login.errors.undefined');

            setErrorMessage(newErrorMessage);
            break;
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleForgotPassword = () => {
    setPopUpsOpen(popUps.forgotPassword);
  };

  const handleRegistration = () => {
    setPopUpsOpen(popUps.registration);
  };

  const handleShowPassword = () => {
    setPasswordShow(!passwordShow);
  };

  return (
    <form className={styles.loginWrap} onSubmit={formik.handleSubmit}>
      <div className={styles.loginImage} />
      <div className={styles.loginContent}>
        <div className={styles.loginTitle}>{translate('popups.login.title')}</div>
        <div className={styles.loginForm}>
          <p>{translate('popups.login.email')}:</p>
          <InputCustom
            id="email"
            name="email"
            placeholder={translate('popups.login.email')}
            type="text"
            icon={emailIcon}
            iconClassName={styles.loginFormEmailIcon}
            onChange={formik.handleChange}
            value={formik.values.email}
            touched={formik.touched.email}
            errors={formik.errors.email}
          />
          <p>{translate('popups.login.password')}:</p>
          <InputCustom
            id="password"
            name="password"
            placeholder={translate('popups.login.password')}
            type={!passwordShow ? 'password' : 'text'}
            icon={lockIcon}
            iconClassName={styles.loginFormLockIcon}
            additionalIconClassName={styles.loginFormAdditionalIcon}
            additionalIcon={!passwordShow ? closePassword : openPassword}
            onAdditionalIconClick={handleShowPassword}
            onChange={formik.handleChange}
            value={formik.values.password}
            touched={formik.touched.password}
            errors={formik.errors.password}
          />
        </div>
        <div className={styles.loginCaptchaWrap}>
          <div className={styles.loginCaptcha}>
            <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={recaptchaKey} />
          </div>
        </div>
        <ButtonSubmit
          className={styles.button}
          type="submit"
          variant={ButtonType.secondary}
          width="288px"
          title={translate('popups.login.buttons.login')}
          disabled={loading}
          color="#242c45"
          handleButton={() => formik.handleSubmit()}
        />
        <div className={styles.loginErrorMessage}>{errorMessage}</div>
        <div className={styles.loginFooter}>
          <button className={styles.loginBtn1} onClick={handleForgotPassword} type="button">
            {translate('popups.login.buttons.forgot')}
          </button>
          <div>
            <span>{translate('popups.login.new')}</span>
            <button className={styles.loginBtn2} onClick={handleRegistration} type="button">
              {translate('popups.login.buttons.registration')}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LogIn;
