import { useContext, useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useMutation, useQuery } from '@apollo/client';
import clsx from 'clsx';

import closeAvatarsIcon from 'assets/img/PopUps/close-avatars.webp';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { setUser } from 'store/user';
import { media } from 'config';
import { ContextPopUps } from 'context';
import { useAppSelector } from 'hooks/useAppSelector';
import { GET_AVATARS } from 'graphQl/query/user/getAvatars';
import {
  getDefaultAvatars,
  getPremiumAvatars,
  isDisableScroll,
  isDisplayPremium,
  isDisplayShadow,
} from 'func/prepareDataMyAccount';
import { UPDATE_USER_AVATAR } from 'graphQl/mutations/User/updateUserAvatar';
import { getPremiumAvatarsNum } from 'func/prepareDataCashback';
import { ButtonType } from 'components/Base/Button/types';
import { userProfile } from 'store/user/user.selectors';
import { generalDataRanksList } from 'store/generalData/generalDtata.selectors';
import { useLocalization } from 'components/Internationalization';

import Button from 'components/Base/Button';
import Loader from 'components/Base/Loader';

import styles from './styles.module.scss';
import './styles.css';

const key = 0;
const scrollYSuppressed = false;
const sampleContainer = {
  maxHeight: '360px',
  padding: '0 5px',
  background: 'transparent',
  width: '95%',
};

export enum EAvatarsMode {
  default = 'default',
  premium = 'premium',
}

const Avatars: React.FC = () => {
  const dispatch = useAppDispatch();
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { translate } = useLocalization();

  const scrollRef = useRef(null);

  const [shadow, setShadow] = useState(true);
  const [newAva, setNewAva] = useState('');
  const [assetsList, setAssetsList] = useState([]);
  const [avatarsMode, setAvatarsMode] = useState(EAvatarsMode.default);
  const [defaultAvatars, setDefaultAvatars] = useState([]);
  const [premiumAvatars, setPremiumAvatars] = useState([]);
  const [statusPremium, setStatusPremium] = useState(null);
  const [focus, setFocus] = useState(null);

  const user = useAppSelector(userProfile);
  const ranksList = useAppSelector(generalDataRanksList);

  const { data: dataAvatars, loading, error } = useQuery(GET_AVATARS);
  const [updateUserAvatar] = useMutation(UPDATE_USER_AVATAR);

  const handleSubmit = async () => {
    try {
      const response = await updateUserAvatar({
        variables: {
          userAvatarData: {
            avatarId: newAva,
          },
        },
      });
      const avatar = response?.data?.updateUserAvatar?.avatar;
      const newUser = { ...user, avatar };
      dispatch(setUser(newUser));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('[UPDATE_AVATAR_ERROR]: ', e);
    } finally {
      setPopUpsOpen(null);
    }
  };

  const handleAvatar = (src: string) => setNewAva(src);

  const handleSwitchMode = () => {
    if (avatarsMode === EAvatarsMode.default) {
      setAvatarsMode(EAvatarsMode.premium);
    } else {
      setAvatarsMode(EAvatarsMode.default);
    }
    if (scrollRef?.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (dataAvatars) {
      setDefaultAvatars(getDefaultAvatars(dataAvatars.avatars));
      setPremiumAvatars(getPremiumAvatars(dataAvatars.avatars));
    }

    if (error) {
      // eslint-disable-next-line no-console
      console.log('[GET_AVATARS_ERROR]: ', error);
    }
  }, [dataAvatars, error]);

  useEffect(() => {
    if (avatarsMode === EAvatarsMode.default) {
      setAssetsList(defaultAvatars);
    }
    if (avatarsMode === EAvatarsMode.premium) {
      setAssetsList(premiumAvatars);
    }
  }, [avatarsMode, defaultAvatars, premiumAvatars]);

  useEffect(() => {
    if (ranksList) {
      setStatusPremium(getPremiumAvatarsNum(ranksList));
    }
  }, [ranksList]);

  return (
    <div className={styles.avatarsWrap}>
      <div className={styles.avatarsTitle}>{translate('popups.avatars')}</div>
      <div className={styles.avatarsMode}>
        <button
          type="button"
          onClick={handleSwitchMode}
          className={clsx(styles.avatarsModeBtn, avatarsMode === EAvatarsMode.default && styles.avatarsModeDefault)}
        >
          {translate('popups.avatars.default')}
        </button>
        <button
          type="button"
          onClick={handleSwitchMode}
          className={clsx(styles.avatarsModeBtn, avatarsMode === EAvatarsMode.premium && styles.avatarsModePremium)}
        >
          {translate('popups.avatars.premium')}
        </button>
      </div>
      <PerfectScrollbar
        className={isDisableScroll(user?.rank?.id, avatarsMode, statusPremium) ? styles.disableScroll : ''}
        style={sampleContainer}
        options={{ suppressScrollY: scrollYSuppressed }}
        onScrollY={(container) => {
          scrollRef.current = container;
          if (container?.scrollHeight - container.scrollTop < 700) {
            setShadow(false);
          } else {
            setShadow(true);
          }
        }}
        key={key}
      >
        <div className={styles.avatarsBody}>
          {!loading ? (
            assetsList.map((ava) => (
              <div
                className={styles.avatarsBodyElem}
                key={ava.id}
                onClick={() => {
                  handleAvatar(ava.id);
                  setFocus(ava.id);
                }}
              >
                <img
                  src={`${media.avatars}${ava.src}`}
                  alt="icon"
                  className={clsx(styles.avatarsBodyElemImg, focus === ava.id && styles.focus)}
                />
              </div>
            ))
          ) : (
            <Loader pages />
          )}
          {isDisplayShadow(
            shadow,
            avatarsMode === EAvatarsMode.default ? defaultAvatars.length : premiumAvatars.length,
          ) &&
            isDisplayPremium(user?.rank?.id, avatarsMode, statusPremium) && (
              <div className={styles.avatarsBodyShadow} />
            )}
          {!isDisplayPremium(user?.rank?.id, avatarsMode, statusPremium) && (
            <div className={styles.avatarsBodyClose}>
              <h2>{translate('popups.avatars.premium.unavailable.title')}</h2>
              <h2>{translate('popups.avatars.premium.unavailable.subtitle')}</h2>
              <img src={closeAvatarsIcon} alt="icon" />
              <h3>
                {translate('popups.avatars.premium.unavailable.from')} {statusPremium}
              </h3>
            </div>
          )}
        </div>
      </PerfectScrollbar>
      <div className={styles.avatarsButton}>
        {isDisplayPremium(user?.rank?.id, avatarsMode, statusPremium) && (
          <Button
            className={styles.button}
            variant={ButtonType.primary}
            width="288px"
            title={translate('general.buttons.continue')}
            onclick={handleSubmit}
            type="submit"
          />
        )}
      </div>
    </div>
  );
};

export default Avatars;
