import clsx from 'clsx';
import sanitize from 'sanitize-html';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PostOrPage } from '@tryghost/content-api';
import { animateScroll as scroll } from 'react-scroll';

import imagePlaceholder from 'assets/img/Blog/image-placeholder.webp';

import BlogContext from 'context/contextBlog/context';
import { ARTICLE_BREAD_CRUMBS } from 'components/constants/blog';
import { Links } from 'components/constants';
import { getNotificationTime } from 'func/notifications';
import { filterArticleTags } from 'func/blog';

import BreadCrumbs from 'components/Base/BreadCrumbs';
import Loader from 'components/Base/Loader';
import BlogMeta from './components/BlogMeta';
import BlogLastArticles from './components/BlogLastArticles';
import BlogMoreArticles from './components/BlogMoreArticles';
import Link from 'components/Base/Links/Link';

import styles from './styles.module.scss';

const BlogPostPage: React.FC = () => {
  const { slug } = useParams<{ slug?: string }>();
  const { allArticles, loading } = useContext(BlogContext);

  const [article, setArticle] = useState<PostOrPage>();

  useEffect(() => {
    const defaultDescriptions = document.querySelectorAll('[data-default-desk]');

    defaultDescriptions.forEach((desc) => {
      const parent = desc.parentNode;

      parent.removeChild(desc);
    });
  }, []);

  useEffect(() => {
    if (slug) {
      const newArticle = allArticles.find((a) => a.slug === slug);

      setArticle(newArticle);
    }
  }, [allArticles, slug]);

  const handleLinkClick = () => {
    scroll.scrollTo(0, { duration: 0, delay: 0 });
  };

  return !loading && article ? (
    <>
      <BlogMeta article={article} />
      <main className={styles.wrapper}>
        <nav className={styles.nav}>
          <BreadCrumbs items={ARTICLE_BREAD_CRUMBS} onLinkClick={handleLinkClick} />
          <Link to={Links.blog} className={styles.link} onClick={handleLinkClick}>
            <span>Back to all</span>
          </Link>
        </nav>
        <div className={clsx(styles.imageWrapper, 'animate__animated animate__fadeInLeft')}>
          <img
            className={styles.image}
            src={article.feature_image || imagePlaceholder}
            alt={article.feature_image_alt || article.feature_image_caption || 'article image'}
          />
        </div>
        <section className={styles.content}>
          <div className={clsx(styles.meta, 'animate__animated animate__fadeInLeft')}>
            <p className={styles.tagWrapper}>
              {article.tags
                ? filterArticleTags(article.tags).map((t) => (
                    <span key={t.id} className={styles.tag}>
                      {t.name}
                    </span>
                  ))
                : null}
            </p>
            <p className={styles.date}>{getNotificationTime(article.published_at)}</p>
          </div>
          <h1 className={clsx(styles.title, 'animate__animated animate__fadeInLeft')}>{article.title}</h1>
          <div
            className={clsx(styles.html, 'animate__animated animate__fadeInLeft')}
            dangerouslySetInnerHTML={{ __html: sanitize(article.html) }}
          />
        </section>
        <BlogLastArticles slug={slug} />
        <BlogMoreArticles slug={slug} />
      </main>
    </>
  ) : (
    <div className={styles.loader}>
      <Loader pages />
    </div>
  );
};

export default BlogPostPage;
