import { amount, getImgSrc } from 'func/common';

import styles from './styles.module.scss';
import { ICurrencySlideRow } from './types';

const CurrencySliderRow: React.FC<ICurrencySlideRow> = ({ displayName, tokenCode, data }) => {
  const tokenData = data?.find((item) => item.src === tokenCode);
  const title = tokenData?.title || amount('0');

  return (
    <div className={styles.currencyRow}>
      <img src={getImgSrc(displayName)} alt="icon" />
      <h3>
        {title} {displayName}
      </h3>
    </div>
  );
};

export default CurrencySliderRow;
