import styles from './styles.module.scss';

const BugBounty: React.FC = () => (
  <div className={styles.bugBountyWrap}>
    <div className={styles.bugBountyTitle}>Bug Bounty</div>
    <div className={styles.bugBountyBody}>
      <p>
        StarBets bounty program was created to enable us to compensate users for discovering and reporting any
        significant security vulnerabilities to our team.
      </p>
      <p>
        Compensation amount will start at $100 equivalent in BTC and will increase based on the magnitude of the
        reported bug. Final compensation will be determined by StarBets.
      </p>
      <p>
        In order for the bug to be eligible for compensation, it must be properly investigated and described, so that it
        can be reproduced by our team.
      </p>

      <h3>Examples of eligible bugs:</h3>
      <h4>Bypassing authentication</h4>
      <h4>Access to sensitive data</h4>
      <h4>Injection and execution of code</h4>

      <p>
        Any other reported bugs that have significant implications for the success of our platform might be compensated
        as determined by StarBets team.
      </p>

      <p>Only first person reporting a vulnerability will be compensated.</p>

      <p>
        To report a bug, send and email to
        <span> support@starbets.io </span> with a thorough description, screen captures, and instructions on how it can
        be replicated.
      </p>
    </div>
  </div>
);

export default BugBounty;
