import React from 'react';

import { useLimbo } from 'context/contextLimbo/contextLimbo';
import { Types } from 'context/contextLimbo/limboReduser';
import { disableButtonsMines } from 'func/prepareDataMines';

import BetAmountBlock from 'components/Games/base/BetAmountBlock';

import styles from './styles.module.scss';

const LimboBetAmountBlock: React.FC = () => {
  const [state, dispatch] = useLimbo();

  const { gameSettings, betAmountValue, isHotkeys, gameCondition, gameId } = state;

  const handleBetAmount = (value) => {
    dispatch({ type: Types.SET_BET_AMOUNT, payload: { betAmount: value } });
  };

  return (
    <div className={styles.limboBetAmountBlockWrap}>
      <BetAmountBlock
        minimumBet={gameSettings.minBet}
        maxBet={gameSettings.maxBet}
        betAmountValue={betAmountValue}
        disable={disableButtonsMines(gameCondition)}
        isHotKeys={isHotkeys}
        setBetAmountValue={handleBetAmount}
        gameId={gameId}
      />
    </div>
  );
};

export default LimboBetAmountBlock;
