import React, { useState } from 'react';

import Button from 'components/Base/Button';
import { ButtonType } from 'components/Base/Button/types';
import { useLocalization } from 'components/Internationalization';

import { ISeoWrap } from './types';
import styles from './styles.module.scss';

const SeoWrap: React.FC<ISeoWrap> = ({ children, defaultHeight, title }) => {
  const { translate } = useLocalization();
  const [seoHeight, setSeoHeight] = useState(defaultHeight);
  const [buttonText, setButtonText] = useState(translate('seo.button.show.more'));

  const handleHeight = () => {
    if (seoHeight === defaultHeight) {
      setSeoHeight('auto');
      setButtonText(translate('general.buttons.close'));
    }
    if (seoHeight === 'auto') {
      setSeoHeight(defaultHeight);
      setButtonText(translate('seo.button.show.more'));
    }
  };

  return (
    <div className={styles.seoWrap}>
      {title ? <h1 className={styles.seoTitle}>{title}</h1> : null}
      <div className={styles.seoContent} style={{ height: `${seoHeight}` }}>
        {children}
      </div>
      {seoHeight === defaultHeight ? <div className={styles.seoShadow} /> : null}
      <div className={styles.seoBtn}>
        <Button
          title={buttonText}
          width="150px"
          onclick={handleHeight}
          color="#202D4A"
          variant={ButtonType.secondary}
        />
      </div>
    </div>
  );
};

export default SeoWrap;
