import hotkeys from 'hotkeys-js';
import Tappable from 'react-tappable/lib/Tappable';

import { useEventListener } from 'hooks/useEventListener';

import { IGameBetButton } from './types';
import styles from './styles.module.scss';

const GameBetButton: React.FC<IGameBetButton> = ({ children, onClick, disable, isHotkeys }) => {
  const handleKyePress = (event: KeyboardEvent) => {
    if (isHotkeys && !disable) {
      const key = event.key;

      if (key === ' ') onClick();
    }
  };

  useEventListener('keyup', handleKyePress);
  hotkeys('space', (event) => event.preventDefault());

  return (
    <Tappable component="button" className={styles.gameBetButton} disabled={disable} onTap={onClick}>
      {children}
    </Tappable>
  );
};

export default GameBetButton;
