import { useContext, useEffect } from 'react';

import SocketContext from 'context/contextSocket/context';
import { DOCUMENT_TITLE_SETTINGS } from 'components/constants/constants';

import DiceGameProvider from 'context/contextDiceGame';
import DocumentTitle from 'components/Base/DocumentTitle';
import DiceGameHistory from './components/DiceGameHistory';
import DiceGamePrediction from './components/DiceGamePrediction';
import DiceGameInfo from './components/DiceGameInfo';
import DiceGameControls from './components/DiceGameControls';
import DiceGameAutoSettings from './components/DiceGameAutoSettings';
import DiceGameNavigation from './components/DiceGameNavigation';
import DiceGameBetsTable from './components/DiceGameBetsTable';
import LotteryDesktop from '../Lottery/LotteryDesktop';

import styles from './styles.module.scss';

const DiceGame: React.FC = () => {
  const { connected, joinGameRoom, leaveGameRoom } = useContext(SocketContext);

  useEffect(() => {
    joinGameRoom();

    return () => {
      leaveGameRoom();
    };
  }, [connected]);

  return (
    <DiceGameProvider>
      <DocumentTitle data={DOCUMENT_TITLE_SETTINGS.dice} />
      <div className={styles.wrapper}>
        <section className={styles.gameWrapper}>
          <div className={styles.dice}>
            <header className={styles.gameHeader}>
              <DiceGameHistory />
              <DiceGameNavigation />
            </header>
            <main>
              <DiceGamePrediction />
              <DiceGameInfo />
              <DiceGameControls />
            </main>
          </div>
          <div className={styles.lottery}>
            <LotteryDesktop />
          </div>
        </section>
        <div className={styles.autoSettings}>
          <DiceGameAutoSettings />
        </div>
        <DiceGameBetsTable />
      </div>
    </DiceGameProvider>
  );
};

export default DiceGame;
