import React, { useContext, useEffect, useState } from 'react';

import { useAppSelector } from 'hooks/useAppSelector';
import { EBonus } from 'components/constants/games';
import { ContextTokenCode } from 'context';
import useGame from 'hooks/useGame';
import { getActiveBonus, getMyActiveBonuses } from 'func/prepareDataBonus';
import { bigThen, lessThen } from 'func/prepareDataDice';
import { dataIsDisplayBonus, dataUserBonuses } from 'store/data/data.selectors';
import { generalDataExchangeRates } from 'store/generalData/generalDtata.selectors';
import { useLocalization } from 'components/Internationalization';
import { convertUSDToCrypto } from 'func/common';

import styles from './styles.module.scss';
import { IBonusAmountInfo } from './types';

const BonusAmountInfo: React.FC<IBonusAmountInfo> = ({ gameId, betAmount }) => {
  const { translate } = useLocalization();

  const { tokenCode } = useContext(ContextTokenCode);
  const userBonuses = useAppSelector(dataUserBonuses);
  const isDisplayBonus = useAppSelector(dataIsDisplayBonus);
  const rates = useAppSelector(generalDataExchangeRates);

  const game = useGame({ id: gameId, providerName: 'In-House' }, !gameId);

  const [currentGame, setCurrentGame] = useState(null);
  const [activeBonuses, setActiveBonuses] = useState(null);
  const [displayError, setDisplayError] = useState(false);
  const [gameInfo, setGameInfo] = useState({ token: '', isBonus: false, maxBet: null, minBet: null });

  useEffect(() => {
    if (isDisplayBonus === EBonus.available && userBonuses && game && gameId) {
      const currentBonuses = getMyActiveBonuses(userBonuses);
      setCurrentGame(game);
      setActiveBonuses(currentBonuses);
    }
  }, [isDisplayBonus, userBonuses, game, gameId]);

  useEffect(() => {
    if (activeBonuses && tokenCode && currentGame && rates) {
      const currentBonus = getActiveBonus(activeBonuses, tokenCode.token);
      const currentRate = rates[tokenCode.token];
      if (currentBonus) {
        setGameInfo({
          token: currentBonus.tokenCode,
          isBonus: currentGame.isBonusGame,
          maxBet: convertUSDToCrypto(currentRate, currentBonus.bonus.maxBet),
          minBet: convertUSDToCrypto(currentRate, currentBonus.bonus.minBet),
        });
      } else {
        setGameInfo({
          token: '',
          isBonus: currentGame.isBonusGame,
          maxBet: '',
          minBet: '',
        });
      }
    }
  }, [activeBonuses, tokenCode, currentGame, rates]);

  useEffect(() => {
    const isCurrentToken = gameInfo?.token === tokenCode?.token;
    const isBiggerBet = bigThen(betAmount || '0', gameInfo.maxBet || '0');
    const isLowBet = lessThen(betAmount || '0', gameInfo.minBet || '0');
    if (!isCurrentToken || (isBiggerBet && isCurrentToken) || (isLowBet && isCurrentToken)) {
      setDisplayError(true);
    } else {
      setDisplayError(false);
    }
  }, [gameInfo, tokenCode, betAmount]);

  if (!gameInfo.isBonus) return null;

  return (
    <div className={styles.bonusAmountInfoWrap}>
      {displayError ? (
        <div className={styles.bonusAmountInfoWrong}>{translate('inHouse.bet.amount.bonus.description')}</div>
      ) : null}
    </div>
  );
};

export default BonusAmountInfo;
