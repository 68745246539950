import styled from 'styled-components';

import point from 'assets/img/common/cursor-pointer.webp';

const EventsResultLinkStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  cursor: url(${point}), auto !important;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  text-decoration: underline;

  @media (max-width: 1250px) {
  }

  @media (max-width: 1024px) {
  }

  @media (max-width: 863px) {
  }
  @media (max-width: 700px) {
    font-size: 12px;
  }
  @media (max-width: 580px) {
  }
  @media (max-width: 400px) {
  }
  @media (max-width: 380px) {
  }
`;

export default EventsResultLinkStyle;
