import styled from 'styled-components';

interface Props {
  inputwdth?: string;
  inputheight?: string;
  height?: string;
  right?: string;
  left?: string;
  top?: string;
}

const RangeStyle = styled.div.withConfig({
  shouldForwardProp: (prop) => !['inputwdth'].includes(prop),
})<Props>`
  display: flex;
  width: ${(props) => (props.inputwdth ? props.inputwdth : '310px')};
  height: ${(props) => (props.height ? props.height : '100px')};
  align-items: center;
  justify-content: center;
  position: relative;

  input[type='range'] {
    margin: auto;
    -webkit-appearance: none;
    position: relative;
    overflow: hidden;
    width: ${(props) => (props.inputwdth ? props.inputwdth : '310px')};
    height: ${(props) => (props.inputheight ? props.inputheight : '6px')};
    cursor: pointer;
    border-radius: 10px;
    cursor: unset;
  }

  input[type='range']::-webkit-slider-runnable-track {
    background: #515b77;
    border: none;
  }
  input[type='range'] {
    background: #515b77;
    border: none;
  }
  input[type='range']::-moz-range-progress {
    background-color: #15d839;
    border: none;
    height: 6px;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    background-color: #15d839;
    box-shadow: -100vw 0 0 99.5vw #15d839;
    -moz-box-shadow: -100vw 0 0 99.5vw #15d839;
    -webkit-box-shadow: -100vw 0 0 99.5vw #15d839;
    -o-box-shadow: -100vw 0 0 99.5vw #15d839;
    -ms-box-shadow: -100vw 0 0 99.5vw #15d839;

    border: 0.5px solid #15d839;
    border-radius: 50%;
  }
  input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    background-color: #15d839;
    box-shadow: -100vw 0 0 99.5vw #15d839;
    -moz-box-shadow: -100vw 0 0 99.5vw #15d839;
    -webkit-box-shadow: -100vw 0 0 99.5vw #15d839;
    -o-box-shadow: -100vw 0 0 99.5vw #15d839;
    -ms-box-shadow: -100vw 0 0 99.5vw #15d839;

    border: 0.5px solid #15d839;
    border-radius: 50%;
  }
  .range__label1 {
    position: absolute;
    top: ${(props) => (props.top ? props.top : '25px')};
    left: ${(props) => (props.left ? props.left : '5px')};
    span {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 13px;
      color: white;
    }
    code {
      color: #15d839;
      text-transform: uppercase;
      font-family: 'Montserrat';
      font-size: 13px;
      font-weight: 600;
    }
  }
  .range__label2 {
    position: absolute;
    top: ${(props) => (props.top ? props.top : '25px')};
    right: ${(props) => (props.right ? props.right : '5px')};
    span {
      color: #15d839;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 13px;
    }
    code {
      color: #515b77;
      font-family: 'Montserrat';
      font-size: 13px;
      font-weight: 600;
    }
  }
`;

export default RangeStyle;
