import clsx from 'clsx';
import { useEffect, useState } from 'react';

import rouletteIcon from 'assets/img/HomePage/NvigationImages/games1.webp';
import cherryIcon from 'assets/img/HomePage/NvigationImages/games2.webp';

import { useLocalization } from 'components/Internationalization';
import { Links } from 'components/constants';
import { getCoinSrc } from 'func/common';

import PromoBlock from '../PromoBlock';

import styles from './styles.module.scss';

const GamesNavigationItem: React.FC = () => {
  const { language, translate } = useLocalization();

  const [title, setTitle] = useState('Play');
  const [subtitle, setSubtitle] = useState('Games');

  useEffect(() => {
    const newText = translate('homepage.welcome.games');

    const [newTitle, newSubtitle] = newText.split(' ');

    setTitle(newTitle);
    setSubtitle(newSubtitle);
  }, [language]);

  return (
    <PromoBlock
      className={styles.wrapper}
      bgClassName={clsx(styles.bg, styles.gamesBg)}
      title={title}
      subtitle={subtitle}
      link={Links.games}
    >
      <img src={cherryIcon} alt="icon" className={styles.gamesCherry} />
      <img src={getCoinSrc('STAR')} alt="star" className={styles.gamesFirstStar} />
      <img src={getCoinSrc('STAR')} alt="star" className={styles.gamesSecondStar} />
      <img src={rouletteIcon} alt="icon" className={styles.gamesRoulette} />
    </PromoBlock>
  );
};

export default GamesNavigationItem;
