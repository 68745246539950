import { pathOr } from 'ramda';
import { useEffect, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { DOCUMENT_TITLE_SETTINGS } from 'components/constants/constants';
import { EVENTS_TITLES } from 'components/constants/table';
import { GET_TOURNAMENT } from 'graphQl/query/tournaments/tournaments';
import { ITableTitle } from 'components/common/TablesTitles/types';
import { ITournament } from 'types/requestTypes';
import { useNavigate } from 'hooks/useNavigate';
import { getSpecialEvents } from 'func/prepareDataEvents';

import DocumentTitle from 'components/Base/DocumentTitle';
import TablesTitles from 'components/common/TablesTitles';
import Title from './components/Title';

import EventsStyle from './styles';

const EventsPage: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [title, setTitle] = useState('');
  const [eventsTitles, setEventsTitles] = useState<ITableTitle[]>(EVENTS_TITLES);

  const { data } = useQuery(GET_TOURNAMENT, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    const splittedPath = pathname.split('/');
    const currentTitle = splittedPath[splittedPath.length - 1];

    setTitle(currentTitle);
  }, [pathname]);

  useEffect(() => {
    if (data) {
      const tournaments = pathOr<ITournament[]>([], ['tournaments'], data);
      const specialEvents = getSpecialEvents(tournaments);

      if (specialEvents) {
        setEventsTitles([...EVENTS_TITLES, ...specialEvents]);
      }
    }
  }, [data]);

  const handleTitle = (title: string) => {
    navigate(title);
  };

  return (
    <EventsStyle>
      <DocumentTitle data={DOCUMENT_TITLE_SETTINGS.events} />
      <Title />
      <div className="events__navigation--wrap">
        <TablesTitles currentTitle={title} titles={eventsTitles} onTitle={handleTitle} />
      </div>
      <Outlet />
    </EventsStyle>
  );
};

export default EventsPage;
