import { IFooterItem } from './types';
import styles from './styles.module.scss';

const FooterItem: React.FC<IFooterItem> = ({ link, icon, title }) => {
  return (
    <div className={styles.footerSocialItem}>
      <a target="_blank" rel="noopener noreferrer" href={link}>
        <img src={icon} alt={title} />
      </a>
    </div>
  );
};

export default FooterItem;
