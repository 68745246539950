import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

const WithdrawDropDownStyle = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  .with__drop__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 38px;
    background: #00000033;
    border-radius: 19px;
    cursor: url(${point}), auto !important;
    padding-left: 10px;
    &:hover {
      transition: 0.2s ease-in-out;
      border: 2px solid #6c5dd3;
      p {
        color: #ffffff;
        font-weight: 400;
      }
    }
  }

  .with__drop__body {
    visibility: hidden;
    flex-direction: column;
    width: 100%;
    height: 0;
    background-color: #000000;
    transform: translateY(-100%);
    transition: all 0.1s ease-in-out;
    transition-duration: 000ms;
    transition-delay: 000ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    margin-top: 10px;
    border-radius: 24px;
    overflow: hidden;
    opacity: 0.9;

    position: absolute;
    top: 40px;
  }
  .with__drop__body.open {
    visibility: visible;
    transform: translateY(0%);
    transition: all 0.1s ease-in-out;
    transition-duration: 100ms;
    transition-delay: 100ms;
    z-index: 20;
    padding: 10px 15px 10px 0;

    height: auto;
    max-height: 80px;
  }

  .with__drop__body-item {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    cursor: url(${point}), auto !important;
    &:hover {
      transition: 0.2s ease-in-out;
      .with__drop__body-icon {
        transform: scale(1.03);
      }
    }
  }

  .with__drop__body-title,
  .with__drop__body-title-2 {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    cursor: url(${point}), auto !important;
    margin: 0 5px;

    &:hover {
      color: #ffa608;
      font-weight: 600;
    }
  }
  .with__drop__body-title {
    flex-shrink: 0;
  }
  .with__drop__body-title-2 {
    white-space: nowrap;
    overflow: hidden;
    padding: 5px;
    text-overflow: ellipsis;
  }

  .with__drop__body-inp {
    width: 100%;
    max-width: 348px;
    background: transparent;
    border: none;
    outline: none;
    height: 100%;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    padding: 0 0 3px 5px;
    cursor: url(${point}), auto !important;
  }

  .with__drop__body-icon {
    width: 30px !important;
    height: 30px !important;
    display: inline-block;
    position: unset !important;
    cursor: url(${point}), auto !important;
  }

  .with__drop__body-btn,
  .with__drop__body-btn-off,
  .with__drop__body-btn-on {
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    width: 20px;
    height: 20px;
    cursor: url(${point}), auto !important;

    &:hover,
    &:focus,
    &:active {
      border: none;
      outline: none;
      box-shadow: none;
    }

    img {
      width: 15px;
      height: 15px;
      display: inline-block;
      position: unset !important;
    }
  }

  .with__drop__body-btn-on {
    background: #7a2cff;
    box-shadow: 0px 0px 6px rgba(122, 44, 255, 0.7);
    border-radius: 4px;
  }

  .with__drop__body-btn-off {
    background: #696c80;
    border-radius: 4px;
  }

  i {
    border: 1px solid #888aa7;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    height: 3px;
    margin-right: 30px;
    position: absolute;
    top: 12px;
    right: -8px;
    cursor: url(${point}), auto !important;
  }

  .up {
    margin-top: 3px;
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
  }
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border: 1px solid #888aa7;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    cursor: url(${point}), auto !important;
    margin-bottom: 3px;
  }

  @media (max-width: 1024px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 540px) {
    .with__drop__body-inp {
      width: 100%;
      max-width: 228px;
    }
  }
  @media (max-width: 420px) {
  }
`;

export default WithdrawDropDownStyle;
