import { gql } from '@apollo/client';

export const GET_VAULTS_LIST = gql`
  query vaults {
    vaults {
      id
      availableBalance
      token {
        tokenCode
        displayName
      }
    }
  }
`;
