import { generate as id } from 'shortid';

import { amount } from 'func/common';
import { FastModeGames, HotKeys, initialKenoWinInfo, KenoCondition } from 'components/constants/games';
import {
  getAllHistoryKeno,
  getAutoPickKenoNumber,
  getGameSettings,
  getKenoSquares,
  getSquareListOnChangeMode,
  getWinInfo,
  setHistory,
  setSelectedSquare,
  setSelectedSquareAutoPick,
  setSelectedSquareList,
  setSquareListStartBet,
  setSquaresBetResult,
} from 'func/prepareDataKeno';

import { initialState } from './initialState';
import { IAction } from './types';
import { processAutoBetGetValuesForReducer } from '../../func/commonGames';

export const Types = {
  FAST_MODE: 'FAST_MODE',
  RISK: 'RISK', // dropdown value
  IS_AUTO: 'IS_AUTO',
  HOTKEYS: 'HOTKEYS',
  GAME_ID: 'GAME_ID',
  SET_BET_AMOUNT: 'SET_BET_AMOUNT',
  AUTO_PICK: 'AUTO_PICK', // event onClick autoSelect squares
  CHANGE_TOKEN_CODE: 'CHANGE_TOKEN_CODE',
  SET_FIRST_HISTORY: 'SET_FIRST_HISTORY', // history on first load page
  CLEAR_TABLE: 'CLEAR_TABLE', // event button setup default squares
  SELECT_SQUARE: 'SELECT_SQUARE', // event onClick each square
  RESPONSE_BET: 'RESPONSE_BET', // setup data on response bet and auto bet
  START_BET: 'START_BET',
  RESPONSE_AUTO_BET: 'RESPONSE_AUTO_BET',
  STOP_AUTO_BET: 'STOP_AUTO_BET',
  START_AUTO_BET: 'START_AUTO_BET', // additional setup on auto bet
  SET_REQUEST_ID: 'SET_REQUEST_ID', // setup for next request to auto bet
  CHANGE_AUTO_STATE: 'CHANGE_AUTO_STATE',
  CHANGE_GAME_CONDITION: 'CHANGE_GAME_CONDITION',
  DISABLE: 'DISABLE', // to disable buttons
};

export const kenoReducer = (state = initialState, action: IAction): typeof initialState => {
  switch (action.type) {
    case Types.FAST_MODE:
      return setFastMode(state);
    case Types.IS_AUTO:
      return setIsAuto(state);
    case Types.HOTKEYS:
      return setHotkeys(state);
    case Types.CLEAR_TABLE:
      return clearTable(state);
    case Types.START_BET:
      return setStartBet(state);
    case Types.STOP_AUTO_BET:
      return stopAutoBet(state);
    case Types.START_AUTO_BET:
      return startAutoBet(state);
    case Types.SET_REQUEST_ID:
      return setRequestId(state);
    case Types.DISABLE:
      return setDisableFalse(state);
    case Types.AUTO_PICK:
      return setAutoPick(state);
    case Types.CHANGE_AUTO_STATE:
      return setAutoState(state, action);
    case Types.GAME_ID:
      return setGameId(state, action);
    case Types.CHANGE_GAME_CONDITION:
      return setGameCondition(state, action);
    case Types.RISK:
      return setRisk(state, action);
    case Types.CHANGE_TOKEN_CODE:
      return changeTokenCode(state, action);
    case Types.SET_BET_AMOUNT:
      return setBetAmountValue(state, action);
    case Types.SET_FIRST_HISTORY:
      return setFirstHistory(state, action);
    case Types.SELECT_SQUARE:
      return selectSquare(state, action);
    case Types.RESPONSE_BET:
      return setBetResponse(state, action);
    default:
      return state;
  }
};

function setFastMode(state) {
  const isFastMode = !state.fastMode;
  localStorage.setItem(FastModeGames.keno, isFastMode.toString());
  return {
    ...state,
    fastMode: isFastMode,
    squaresList: getSquareListOnChangeMode(state.squaresList),
  };
}

function setIsAuto(state) {
  const isAutoMode = state.isAuto;
  return {
    ...state,
    isAuto: !isAutoMode,
    gameCondition: !isAutoMode ? KenoCondition.autoNotStarted : KenoCondition.notStarted,
  };
}

function setHotkeys(state) {
  const isHotkeysMode = !state.isHotkeys;
  localStorage.setItem(HotKeys.keno, isHotkeysMode.toString());
  return {
    ...state,
    isHotkeys: isHotkeysMode,
  };
}

function setRisk(state, action) {
  return {
    ...state,
    risk: action.payload.risk,
  };
}

function setBetAmountValue(state, action) {
  return {
    ...state,
    betAmountValue: action.payload.betAmount,
  };
}

function setAutoPick(state) {
  const pickedArr = getAutoPickKenoNumber();
  return {
    ...state,
    selectedSquaresList: pickedArr,
    squaresList: setSelectedSquareAutoPick(state.squaresList, pickedArr, state.fastMode),
  };
}

function clearTable(state) {
  return {
    ...state,
    squaresList: getKenoSquares(),
    selectedSquaresList: [],
  };
}

function selectSquare(state, action) {
  const itemId = action.payload.itemId;
  return {
    ...state,
    squaresList: setSelectedSquare(state.squaresList, itemId, state.fastMode),
    selectedSquaresList: setSelectedSquareList(state.selectedSquaresList, itemId, state.squaresList),
  };
}

function setAutoState(state, action) {
  const name = action.payload.name;
  const value = action.payload.value;
  return {
    ...state,
    autoState: {
      ...state.autoState,
      [name]: value,
    },
  };
}

function setGameCondition(state, action) {
  return {
    ...state,
    gameCondition: action.payload.gameCondition,
    disable: false,
  };
}

// function response from DB for game

function setGameId(state, action) {
  return {
    ...state,
    gameId: action.payload.gameId,
  };
}

function changeTokenCode(state, action) {
  return {
    ...state,
    gameSettings: { ...getGameSettings(action.payload.gameSettings) },
    gameCondition: KenoCondition.notStarted,
    betAmountValue: amount(action.payload.gameSettings.betAmount),
    isAuto: false,
  };
}

function setFirstHistory(state, action) {
  return {
    ...state,
    historyList: getAllHistoryKeno(action.payload.historyList),
  };
}

// function work with bet

function setStartBet(state) {
  return {
    ...state,
    gameCondition: state.isAuto ? KenoCondition.autoStarted : KenoCondition.getStarted,
    winInfo: initialKenoWinInfo,
    squaresList: setSquareListStartBet(state.squaresList, state.fastMode),
    requestId: '',
    disable: true,
  };
}

function setBetResponse(state, action) {
  let currentAutoState;
  let currentBetAmount;
  if (state.isAuto) {
    const [newAutoState, newBetAmount] = processAutoBetGetValuesForReducer(
      {
        ...state.autoState,
        firstSpin: action.payload.firstSpin,
      },
      action.payload.startBetAmount,
      action.payload.bet.payout,
      state.betAmountValue,
      state.gameSettings.maxBet,
      state.gameSettings.minBet,
    );
    currentBetAmount = newBetAmount;
    currentAutoState = newAutoState;
  }
  return {
    ...state,
    gameCondition: state.isAuto ? state.gameCondition : KenoCondition.notStarted,
    squaresList: setSquaresBetResult(state.squaresList, action.payload.bet.arr, state.fastMode),
    winInfo: getWinInfo(action.payload.bet, action.payload.displayName, state.selectedSquaresList),
    historyList: setHistory(state.historyList, action.payload.bet, state.betAmountValue, action.payload.displayName),
    autoState: state.isAuto ? currentAutoState : state.autoState,
    betAmountValue: state.isAuto ? amount(currentBetAmount) : amount(state.betAmountValue),
  };
}

function stopAutoBet(state) {
  return {
    ...state,
    gameCondition: KenoCondition.autoNotStarted,
    winInfo: initialKenoWinInfo,
    requestId: '',
    autoState: {
      ...state.autoState,
      firstSpin: true,
    },
    disable: false,
  };
}

function startAutoBet(state) {
  return {
    ...state,
    gameCondition: KenoCondition.autoStarted,
    winInfo: initialKenoWinInfo,
  };
}

function setRequestId(state) {
  return {
    ...state,
    requestId: id(),
    disable: false,
  };
}

function setDisableFalse(state) {
  return {
    ...state,
    disable: false,
  };
}
