import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ContextPopUps } from 'context';
import { useLocalization } from 'components/Internationalization';

import EmptyTable from 'components/Base/EmptyTable';

import { IMyBetsTableWrap } from './types';
import styles from './styles.module.scss';
import MyBetsTable from './MyBetsTable';

const MyBetsTableWrap: React.FC<IMyBetsTableWrap> = ({ history, popUpsWindow }) => {
  const { translate, language } = useLocalization();

  const { setPopUpsOpen } = useContext(ContextPopUps);
  const isMobile = useMediaQuery({ query: '(max-width: 581px)' });
  const [tableList, setTableList] = useState([]);
  const [titles, setTitles] = useState([]);

  const handleDetails = (item) => {
    setPopUpsOpen({
      modalOpen: popUpsWindow,
      data: item,
    });
  };

  useEffect(() => {
    if (isMobile) {
      const resultSlice = history.slice(0, 10);
      setTableList(resultSlice.map((el) => ({ ...el, func: () => handleDetails(el) })));
    } else {
      const resultSlice = history.slice(0, 20);
      setTableList(resultSlice.map((el) => ({ ...el, func: () => handleDetails(el) })));
    }
  }, [history]);

  useEffect(() => {
    setTitles([
      translate('inHouse.table.date'),
      translate('inHouse.table.amount'),
      translate('inHouse.table.multiplier'),
      translate('inHouse.table.payout'),
    ]);
  }, [language]);

  if (!history?.length) {
    return <EmptyTable lines={2} marginTop="0" />;
  }

  return (
    <div className={styles.myBetsTableWrap}>
      <MyBetsTable history={tableList} titles={titles} />
    </div>
  );
};

export default MyBetsTableWrap;
