import { useState } from 'react';

import { HomePageTablesTitles, HOMEPAGE_TABLE_TITLES } from 'components/constants/table';

import TablesTitles from 'components/common/TablesTitles';
import TopPlayers from './components/TopPlaters';
import BigWins from './components/BigWins';

import styles from './styles.module.scss';

const HomeTable: React.FC = () => {
  const [table, setTable] = useState<string>(HomePageTablesTitles.topPlayers);

  const handleTitle = (title: string) => {
    setTable(title);
  };

  return (
    <div className={styles.homeTablesWrap}>
      <div className={styles.homeTablesNavigation}>
        <TablesTitles currentTitle={table} titles={HOMEPAGE_TABLE_TITLES} onTitle={handleTitle} />
      </div>
      <div className={styles.homeTablesBody}>
        {table === HomePageTablesTitles.topPlayers ? <TopPlayers /> : null}
        {table === HomePageTablesTitles.bigWins ? <BigWins /> : null}
      </div>
    </div>
  );
};

export default HomeTable;
