import RegistrationForm from './components/registrationForm';
import { SignUpWrapper } from './styles';
import { ISignUp } from './types';

const SignUp: React.FC<ISignUp> = ({ setCurrentEmail }) => {
  return (
    <SignUpWrapper>
      <div className="signup__image" />
      <RegistrationForm setCurrentEmail={setCurrentEmail} />
    </SignUpWrapper>
  );
};

export default SignUp;
