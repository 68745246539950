import { handleSelectInputValue } from 'func/common';

import { IInputCustom } from './types';
import { InputCustomStyled, InputCustomWrap } from './style';
import styles from './styles.module.scss';

const InputCustom: React.FC<IInputCustom> = ({
  id,
  touched,
  errors,
  name,
  type,
  placeholder,
  onChange,
  onKeyPress,
  value,
  errorMessage,
  disable,
  onBlur,
  inputwidth,
  maxLength,
  onFocus,
  onPaste,
  onCopy,
  bordercolor,
  icon,
  iconClassName,
  onIconClick,
  additionalIcon,
  additionalIconClassName,
  onAdditionalIconClick,
}) => {
  return (
    <InputCustomWrap inputwidth={inputwidth} className={styles.inputCustomWrapper}>
      {icon && <img src={icon} alt="icon" className={iconClassName} onClick={() => onIconClick?.()} />}
      {additionalIcon && (
        <img
          src={additionalIcon}
          alt="icon"
          className={additionalIconClassName}
          onClick={() => onAdditionalIconClick?.()}
        />
      )}
      <InputCustomStyled
        id={id}
        type={type}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        onKeyPress={onKeyPress}
        value={value}
        onBlur={onBlur}
        disabled={disable}
        autoComplete="off"
        autoFocus={false}
        maxLength={maxLength}
        onFocus={(e) => {
          if (onFocus) onFocus(e);
          handleSelectInputValue(e);
        }}
        onCopy={onCopy}
        onPaste={onPaste}
        bordercolor={bordercolor}
        errormessage={(touched && errors) || errorMessage}
        icon={icon}
      />
    </InputCustomWrap>
  );
};

export default InputCustom;
