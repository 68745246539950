import clsx from 'clsx';

import { useAppSelector } from 'hooks/useAppSelector';
import { dataIsActiveUserBonus } from 'store/data/data.selectors';
import bonusIcon from 'assets/img/BonusPage/bonusTab.webp';

import { IGamesBonusTab } from './types';
import styles from './styles.module.scss';
import { GamesTabsWrap } from '../GamesTabs/styles';

const GamesTabBonus: React.FC<IGamesBonusTab> = ({ isActive, onClick, idTitle, isDisplay }) => {
  const isActiveUserBonus = useAppSelector(dataIsActiveUserBonus);

  if (!isActiveUserBonus) return null;

  return (
    <GamesTabsWrap
      display={isDisplay}
      className={clsx(styles.bonusTab, isActive ? styles.bonusTabActive : '')}
      onClick={() => onClick(idTitle)}
    >
      <img src={bonusIcon} alt="bonus" />
      <p>BONUS</p>
    </GamesTabsWrap>
  );
};

export default GamesTabBonus;
