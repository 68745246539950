import Big from 'big.js';
import { useEffect, useState } from 'react';

import bonusIcon from 'assets/img/ranks/bonus-rank.webp';
import cashbackIcon from 'assets/img/ranks/cash-back.svg';
import bankIcon from 'assets/img/ranks/piggy-bank.webp';
import wagerIcon from 'assets/img/ranks/bitcoin-wallet.webp';

import { RankItem } from 'types/requestTypes';
import { getRanksRowList } from 'components/constants/pages';
import { getAmountWithoutZeroEnd } from 'func/common';
import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';
import { IRankSystemDescription } from './types';
import RankDetailsRow from '../RankDetailsRow';

const RankSystemDescription: React.FC<IRankSystemDescription> = ({ rankList, active }) => {
  const { translate, language } = useLocalization();
  const [currentRank, setCurrentRank] = useState<RankItem>(null);
  const [rakebackMinRank, setRakebackMinRank] = useState(1);
  const [ranksRows, setRanksRows] = useState([]);

  useEffect(() => {
    const newRakebackMinRank = rankList.find((r) => Big(r.rakeback).gt(0));

    setRakebackMinRank((r) => newRakebackMinRank?.id || r);
  }, [rankList]);

  useEffect(() => {
    if (rankList) {
      const current = rankList.find((el) => el.id === (active || 1));
      setCurrentRank(current);
    }
  }, [active]);

  useEffect(() => {
    const rows = getRanksRowList(translate);

    setRanksRows(rows);
  }, [language]);

  return (
    <div className={styles.rankDescriptionWrap}>
      <h3 className={styles.rankDescriptionTitle}>{translate('cashback.ranks.requirements.text')}:</h3>
      <div className={styles.rankDescriptionItemWrap}>
        <div className={styles.rankDescriptionItem}>
          <img src={wagerIcon} alt="icon" />
          <p>{translate('cashback.ranks.wager.text')}</p>
        </div>
        <h4 key={currentRank?.wager} className={styles.rankDescriptionItemResult}>{`${currentRank?.wager} BTC`}</h4>
      </div>
      <h3 className={styles.rankDescriptionTitle}>Benefits:</h3>
      <div className={styles.rankDescriptionItemWrap}>
        <div className={styles.rankDescriptionItem}>
          <img src={cashbackIcon} alt="icon" />
          <p>{translate('cashback.ranks.cashback.text')}</p>
        </div>
        <h4 key={currentRank?.wager} className={styles.rankDescriptionItemResult}>{`${currentRank?.cashback} %`}</h4>
      </div>
      {currentRank?.id >= rakebackMinRank ? (
        <div className={styles.rankDescriptionItemWrap}>
          <div className={styles.rankDescriptionItem}>
            <img src={bankIcon} alt="icon" />
            <p>{translate('cashback.ranks.rakeback')}</p>
          </div>
          <h4 key={currentRank?.wager} className={styles.rankDescriptionItemResult}>{`${currentRank.rakeback}%`}</h4>
        </div>
      ) : null}
      {currentRank?.id > 1 ? (
        <div className={styles.rankDescriptionItemWrap}>
          <div className={styles.rankDescriptionItem}>
            <img src={bonusIcon} alt="icon" />
            <p>{translate('cashback.ranks.bonus.text')}</p>
          </div>
          <h4 key={currentRank?.wager} className={styles.rankDescriptionItemResult}>{`${getAmountWithoutZeroEnd(
            currentRank?.bonus,
          )} ${currentRank?.displayName || 'BTC'}`}</h4>
        </div>
      ) : null}
      <div className={styles.rankDescriptionRowWrap}>
        {ranksRows.map((el, ind) => (
          <RankDetailsRow
            key={el.title}
            isReached={currentRank ? currentRank[el.id] : false}
            title={el.title}
            image={el.image}
            index={ind}
          />
        ))}
      </div>
    </div>
  );
};

export default RankSystemDescription;
