import styled from 'styled-components';

const TablePopUpStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  max-width: 1400px;
  padding: 0 52px 0 40px;
  color: #ffffff;
  min-height: 300px;
  //max-height: 600px;
  display: block;
  overflow-y: auto;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
  @-moz-document url-prefix() {
    /* Disable scrollbar Firefox */
    .popUps__content {
      scrollbar-width: none;
    }
  }

  .table__popup-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: -0.5px;
    color: #ffffff;
    margin: 36px 0 30px;
  }

  @media (max-width: 1350px) {
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1024px) {
    .table__events-body-style {
      margin-bottom: -15px;
    }
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
    .table__popup-title {
      font-size: 26px;
      line-height: 31px;
      margin: 0 0 20px;
    }
  }
  @media (max-width: 580px) {
    padding: 0 15px 0px !important;
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 400px) {
  }
`;

export default TablePopUpStyle;
