import React from 'react';
import { animateScroll as scroll } from 'react-scroll';

import scrollTopIcon from 'assets/img/footer/scrollTop.svg';

import styles from './styles.module.scss';

const ScrollToTop: React.FC = () => (
  <div className={styles.footerScroll}>
    <img src={scrollTopIcon} alt="icon" onClick={() => scroll.scrollTo(0, { duration: 0, delay: 0 })} />
  </div>
);

export default ScrollToTop;
