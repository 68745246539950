import DateBlock from './components/DateBlock';
import DescriptionBlock from './components/DescriptionBlock';
import SocialBlock from './components/SocialBlock';
import SupportBlock from './components/SupportBlock';
import ScrollToTop from './components/ScrollToTop';
import InfoBlock from './components/InfoBlock';
import BtcRate from './components/BtcRate';

import styles from './styles.module.scss';

const Footer: React.FC = () => (
  <div className={styles.footer}>
    <SupportBlock />
    <BtcRate />
    <InfoBlock />
    <DescriptionBlock />
    <DateBlock />
    <SocialBlock />
    <ScrollToTop />
  </div>
);

export default Footer;
