import React, { useEffect, useState } from 'react';
import { generate as id } from 'shortid';

import { amount, getImgSrc } from 'func/common';
import winImage from 'assets/img/CoinFlip/CoinWinImage.webp';
import loseImage from 'assets/img/CoinFlip/CoinLoseImage.webp';

import CoinWinAnimationStyle from './styles';
import { ICoinWinAnimation } from './types';

const CoinWinAnimation: React.FC<ICoinWinAnimation> = ({
  fastMode,
  historyTable,
  isMultiplyValue,
  displayName,
  profit,
  series,
}) => {
  const [animationKey, setAnimationKey] = useState('');
  const [animationCondition, setAnimationCondition] = useState({
    result: false,
    active: false,
  });

  useEffect(() => {
    if (historyTable?.length) {
      if (historyTable[0].animationName) {
        setAnimationCondition({
          result: historyTable[0]?.result === 'win',
          active: true,
        });
        setAnimationKey(id());
      }
    }
  }, [historyTable]);

  useEffect(() => {
    setAnimationCondition({
      result: false,
      active: false,
    });
  }, [isMultiplyValue]);

  useEffect(() => {
    if (!series) {
      setAnimationCondition({
        result: false,
        active: false,
      });
    }
  }, [series]);

  return (
    <CoinWinAnimationStyle fastmode={fastMode ? 'true' : ''}>
      <div className="coin__win--animation" key={animationKey}>
        {animationCondition.result && !isMultiplyValue && (
          <img
            src={winImage}
            alt="icon"
            className={animationCondition.active ? 'coin__win--img active' : 'coin__win--img'}
          />
        )}
        {!animationCondition.result && !isMultiplyValue && (
          <img
            src={loseImage}
            alt="icon"
            className={animationCondition.active ? 'coin__lose--img active' : 'coin__lose--img'}
          />
        )}
      </div>
      {animationCondition.result && isMultiplyValue && (
        <div className={isMultiplyValue && animationCondition.active ? 'coin__win--div active' : 'coin__win--div'}>
          <img src={getImgSrc(displayName)} alt="icon" />
          <h4>{`${amount(profit)} ${displayName}`}</h4>
        </div>
      )}
      {!animationCondition.result && isMultiplyValue && (
        <div className="coin__win--animation" key={animationKey + '1'}>
          <img
            src={loseImage}
            alt="icon"
            className={isMultiplyValue && animationCondition.active ? 'coin__lose--img active' : 'coin__lose--img'}
          />
        </div>
      )}
    </CoinWinAnimationStyle>
  );
};

export default CoinWinAnimation;
