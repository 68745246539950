import clsx from 'clsx';
import { useContext } from 'react';
import { Area, AreaChart, ReferenceLine, ResponsiveContainer } from 'recharts';

import LiveStatsContext from 'context/contextLiveStats/context';

import styles from './styles.module.scss';
import { ILiveStatisticsChart } from './types';

const LiveStatisticsChart: React.FC<ILiveStatisticsChart> = ({ className = '' }) => {
  const { profit, bets } = useContext(LiveStatsContext);

  return (
    <div className={clsx(styles.wrapper, className)}>
      <ResponsiveContainer minHeight={100}>
        <AreaChart data={bets} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="colorRed" x1=".5" x2=".5" y2="1">
              <stop offset=".004" stopColor="#ee4d6a" stopOpacity=".8" />
              <stop offset=".886" stopColor="#ee4d6a" stopOpacity=".0" />
            </linearGradient>
            <linearGradient id="colorGreen" x1=".5" x2=".5" y2="1">
              <stop stopColor="#99d290" stopOpacity=".8" />
              <stop offset="1" stopColor="#99d290" stopOpacity=".0" />
            </linearGradient>
          </defs>
          <ReferenceLine y={0} stroke="#888AA7" strokeWidth={1} />
          <Area
            type="monotone"
            dataKey="uv"
            baseLine={0}
            isAnimationActive={false}
            strokeWidth={3}
            dot={false}
            stroke={Number(profit) < 0 ? '#EE4D6A' : '#99D290'}
            fill="transparent"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LiveStatisticsChart;
