import React, { useContext } from 'react';

import { useKeno } from 'context/contextKeno/contextKeno';
import { Types } from 'context/contextKeno/kenoReduser';
import SocketContext from 'context/contextSocket/context';
import { disableKenoButtons } from 'func/prepareDataKeno';

import BetAmountBlock from 'components/Games/base/BetAmountBlock';

import styles from './styles.module.scss';

const KenoBetAmountBlock: React.FC = () => {
  const [state, dispatch] = useKeno();
  const { connected: socketConnected } = useContext(SocketContext);

  const { gameSettings, betAmountValue, isHotkeys, gameCondition, disable, gameId } = state;

  const handleBetAmount = (value) => {
    dispatch({ type: Types.SET_BET_AMOUNT, payload: { betAmount: value } });
  };

  return (
    <div className={styles.kenoBetAmountBlockWrap}>
      <BetAmountBlock
        minimumBet={gameSettings.minBet}
        maxBet={gameSettings.maxBet}
        betAmountValue={betAmountValue}
        disable={disableKenoButtons(gameCondition, disable) || !socketConnected}
        isHotKeys={isHotkeys}
        setBetAmountValue={handleBetAmount}
        gameId={gameId}
      />
    </div>
  );
};

export default KenoBetAmountBlock;
