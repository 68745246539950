import { CarouselProps } from 'react-responsive-carousel';

export const defaultSliderConfig: Partial<CarouselProps> = {
  autoPlay: false,
  infiniteLoop: true,
  interval: 4000,
  showIndicators: false,
  showArrows: false,
  showStatus: false,
  swipeable: true,
  useKeyboardArrows: true,
};
