import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useLazyQuery, useReactiveVar } from '@apollo/client';

import closeIcon from 'assets/img/common/cancel.webp';
import fullScreenIcon from 'assets/img/common/full-screen.webp';

import { IGame } from 'types';
import { useAppSelector } from 'hooks/useAppSelector';
import { ContextTokenCode } from 'context';
import { INIT_GAME_SESSION } from 'graphQl/query/games/gameQueries';
import { InitSlotsGameState } from 'components/constants/games';
import useGame from 'hooks/useGame';
import { dataFreeSpinsSettings } from 'store/data/data.selectors';

import { settingVar } from 'cache';
import Loader from 'components/Base/Loader';

import { pathOr } from 'ramda';
import BlockedScreen from './components/BlockedScreen';
import InitGameCurrency from './components/InitGameCurrency';
import GameScreenStyle from './styles';

const GameScreenPage: React.FC = () => {
  const navigate = useNavigate();
  const handleFullScreen = useFullScreenHandle();
  const { gameId } = useParams<{ gameId?: string }>();
  const { tokenCode } = useContext(ContextTokenCode);

  useReactiveVar(settingVar);

  const game = useGame({ slug: gameId });

  const [fullScreen, setFullScreen] = useState(false);
  const [initGame, setInitGame] = useState(InitSlotsGameState.stop);
  const [gameCurrency, setGameCurrency] = useState('');
  const [gameURI, changeGameURI] = useState('');
  const [currentGame, setCurrentGame] = useState<IGame | null>(null);
  const [currencyList, setCurrencyList] = useState([]);
  const [isFreeSpins, setIsFreeSpins] = useState(false);

  const freeSpinsSettings = useAppSelector(dataFreeSpinsSettings);

  const [initGamePlay, { loading, data: gameInitData }] = useLazyQuery(INIT_GAME_SESSION, {
    fetchPolicy: 'no-cache',
    onError: () => setInitGame(InitSlotsGameState.blocked),
  });

  const removeData = () => {
    changeGameURI(null);
    settingVar({
      ...settingVar(),
      isSlotsRun: false,
    });
  };

  const handleClose = () => {
    navigate(-1);
    window.scrollTo(0, 0);
  };

  const handleOpenFullScreen = () => {
    setFullScreen(true);
    handleFullScreen.enter();
  };

  const handleTokenCode = () => {
    if (tokenCode.token === 'BTC') {
      setGameCurrency('uBTC');
    } else {
      setGameCurrency(tokenCode.token);
    }
    setInitGame(InitSlotsGameState.request);
  };

  const handleCurrency = (val: string) => {
    setGameCurrency(val);
    setInitGame(InitSlotsGameState.request);
  };

  const handleSetGameParams = (newGame: IGame) => {
    setCurrentGame(game);
    const currencies = pathOr([], ['currencies'], newGame);
    if (!currencies.length || newGame.isBlocked) {
      setInitGame(InitSlotsGameState.blocked);
    } else {
      setInitGame(InitSlotsGameState.stop);
      setCurrencyList(currencies);
    }
  };

  useEffect(() => {
    if (game) {
      if (freeSpinsSettings) {
        if (freeSpinsSettings.gameId === game.id && freeSpinsSettings.numberOfSpins) {
          setInitGame(InitSlotsGameState.freeSpins);
          setIsFreeSpins(true);
          setGameCurrency(freeSpinsSettings.currency);
          initGamePlay({
            variables: {
              slug: game.slug,
              currency: tokenCode.token,
              sessionCurrency: freeSpinsSettings.currency,
            },
          });
        } else {
          handleSetGameParams(game);
        }
      }
      if (!freeSpinsSettings) {
        handleSetGameParams(game);
      }
    }
  }, [game, freeSpinsSettings]);

  useEffect(() => {
    if (tokenCode) {
      if (initGame === InitSlotsGameState.display) {
        if (isFreeSpins) {
          setInitGame(InitSlotsGameState.request);
        } else {
          setInitGame(InitSlotsGameState.stop);
        }
      }
    }
  }, [tokenCode]);

  useEffect(() => {
    if (!handleFullScreen.active) {
      setFullScreen(false);
    }
  }, [handleFullScreen]);

  useEffect(() => {
    if (initGame === InitSlotsGameState.request) {
      initGamePlay({
        variables: {
          slug: currentGame.slug,
          currency: tokenCode.token,
          sessionCurrency: gameCurrency,
        },
      });
    }
  }, [initGame, currentGame, tokenCode]);

  useEffect(() => {
    const gameUrl = pathOr('', ['initGame', 'url'], gameInitData);

    if (gameUrl) {
      changeGameURI(gameUrl);
    }
    if (gameInitData) {
      if (!gameInitData.initGame.url) {
        setInitGame(InitSlotsGameState.blocked);
      } else {
        setInitGame(InitSlotsGameState.display);
        settingVar({
          ...settingVar(),
          isSlotsRun: true,
        });
      }
    }

    return removeData;
  }, [gameInitData]);

  console.log('working');

  if (loading) return <Loader pages />;

  return (
    <GameScreenStyle key={gameId}>
      <div className="game-screen-wrapper">
        {initGame === InitSlotsGameState.stop && (
          <InitGameCurrency
            currencyList={currencyList}
            tokenCode={tokenCode}
            gameCurrency={gameCurrency}
            onEurSelection={() => handleCurrency('EUR')}
            onUsdSelection={() => handleCurrency('USD')}
            onTokenSelection={handleTokenCode}
          />
        )}

        <FullScreen handle={handleFullScreen}>
          {initGame === InitSlotsGameState.blocked ? (
            <BlockedScreen onClick={handleClose} />
          ) : (
            initGame === InitSlotsGameState.display && (
              <iframe
                title="game-screen"
                frameBorder="0"
                className={!fullScreen ? 'game-screen' : 'game-screen-full'}
                src={gameURI}
                allowFullScreen
              />
            )
          )}
        </FullScreen>

        <div className="game-screen-buttons">
          <div className="game-screen-close" onClick={handleClose}>
            <img alt="icon" src={closeIcon} className="close-icon" />
          </div>
          <div className="game-screen-full-button" onClick={handleOpenFullScreen}>
            <img alt="icon" src={fullScreenIcon} className="full-screen-icon" />
          </div>
        </div>
      </div>
    </GameScreenStyle>
  );
};

export default React.memo(GameScreenPage);
