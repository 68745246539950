import { gql } from '@apollo/client';

export const GET_MINES_ROUND_HASH = gql`
  mutation Mutation($minesBet: MinesBet!) {
    minesRound(bet: $minesBet) {
      hash
    }
  }
`;

export const MINES_BET = gql`
  mutation Mutation($prediction: Int!, $token: String!, $gameId: ID!) {
    minesBet(prediction: $prediction, token: $token, gameId: $gameId) {
      ... on MinesPredictionResult {
        option
      }
      ... on MinesRoundResult {
        betId
        mines
        predictions
        payout
        multiplier
        prediction {
          option
        }
      }
    }
  }
`;

export const MINES_COLLECT = gql`
  mutation Mutation($token: String!, $gameId: ID!) {
    minesCollect(token: $token, gameId: $gameId) {
      betId
      seed
      mines
      predictions
      payout
      multiplier
    }
  }
`;

export const GET_MINES_SESSION = gql`
  query MinesSession($token: String!) {
    minesSession(token: $token) {
      wager
      hash
      prediction
      complexity
    }
  }
`;

export const MINES_AUTO_BET = gql`
  mutation Mutation($predictions: [Int!]!, $token: String!, $gameId: ID!) {
    minesAutoBet(predictions: $predictions, token: $token, gameId: $gameId) {
      betId
      mines
      predictions
      payout
      multiplier
      prediction {
        option
      }
    }
  }
`;
