import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { EGamesTabs, GAMES_QUALITY } from 'components/constants/constants';
import { useMutation } from '@apollo/client';
import clsx from 'clsx';

import { ADD_FAVORITE_GAME, DELETE_FAVORITE_GAME } from 'graphQl/mutations/gamesList/gamesList';

import likedIcon from 'assets/img/games/liked-star.webp';
import notLikedIcon from 'assets/img/games/non-like-star.webp';
import popularIcon from 'assets/img/GamesFiler/LabelPopular.webp';
import newIcon from 'assets/img/GamesFiler/labelNew.webp';
import giftIcon from 'assets/img/common/giftHand.webp';
import noGifIcon from 'assets/img/BonusPage/no-bomus-icon.webp';
import dropsIcon from 'assets/img/games/dpro-and-wins-label.webp';
import lockIcon from 'assets/img/common/lockWithSircle.webp';

import PragmaticDGAContext from 'context/contextPragmatic/context';
import { ITable } from 'context/contextPragmatic/types';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setFavoriteGamesList, setGames } from 'store/data';
import { getGameCardClass } from 'func/prepareDataGamesPage';
import { userToken } from 'store/user/user.selectors';
import { dataFavoriteGamesList, dataGames } from 'store/data/data.selectors';

import SlotLive from '../SlotLive';

import { CardElem } from '../../types';
import styles from './styles.module.scss';

const SlotsCard: React.FC<CardElem> = ({
  src,
  onClick,
  modeTitle,
  providerName,
  id,
  isFavorite,
  index,
  freeSpins,
  popular,
  isNew,
  isBonusGame,
  drops,
  bonusIsActive,
  game,
  providerGameId,
  isBlocked,
}) => {
  const { search: locationSearch } = useLocation();

  const { tables } = useContext(PragmaticDGAContext);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const [open, setOpen] = useState(false);
  const [liveData, setLiveData] = useState<ITable>();
  const [favorite, setFavorite] = useState(isFavorite);

  const [addFavoriteGame] = useMutation(ADD_FAVORITE_GAME);
  const [deleteFavoriteGame] = useMutation(DELETE_FAVORITE_GAME);
  const dispatch = useAppDispatch();

  const token = useAppSelector(userToken);
  const games = useAppSelector(dataGames);
  const favoriteGamesList = useAppSelector(dataFavoriteGamesList);

  useEffect(() => {
    const liveTable = tables[providerGameId];

    setLiveData(liveTable);
  }, [tables, providerGameId]);

  const handleOpen = () => {
    if (!isTabletOrMobile && !open) {
      onClick(token);
    }
    if (open) {
      onClick(token);
    }
    if (isTabletOrMobile) {
      setOpen(true);
    }
  };

  const handleLike = async () => {
    const params = new URLSearchParams(locationSearch);
    const tabURL = params.get(GAMES_QUALITY.tabs.id);

    if (tabURL === EGamesTabs.favorite) {
      const newGames = games.filter((g) => g.gameId !== id);
      dispatch(setGames(newGames));
      dispatch(setFavoriteGamesList(newGames));
    }

    if (tabURL !== EGamesTabs.favorite) {
      if (favorite) {
        dispatch(setFavoriteGamesList(favoriteGamesList.filter((g) => g.gameId !== id)));
      } else {
        dispatch(setFavoriteGamesList([...favoriteGamesList, game]));
      }
    }

    const options = { variables: { gameId: id } };
    setFavorite((prev) => !prev);
    try {
      await (favorite ? deleteFavoriteGame(options) : addFavoriteGame(options));
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleBlur = () => setOpen(false);

  return (
    <div
      className={clsx(styles.slotCardWrap, isBlocked ? styles.slotBlocked : '')}
      onBlur={handleBlur}
      tabIndex={index}
    >
      {liveData ? <SlotLive className={styles.live} table={liveData} /> : null}
      {isNew ? <img src={newIcon} alt="icon" className={styles.slotCardLabel} /> : null}
      {bonusIsActive ? (
        <img src={isBonusGame ? giftIcon : noGifIcon} alt="icon" className={styles.slotCardGift} />
      ) : null}
      {popular ? <img src={popularIcon} alt="icon" className={styles.slotCardLabel} /> : null}
      {drops ? <img src={dropsIcon} alt="icon" className={styles.slotCardDropsIcon} /> : null}
      <img onClick={() => onClick(token)} src={src} alt="icon" className={styles.slotCardBg} />
      <div className={styles[getGameCardClass(bonusIsActive, isBonusGame, freeSpins)]} onClick={handleOpen}>
        <p>{modeTitle}</p>
      </div>
      <h3 className={styles.slotCardProvider}>{providerName}</h3>
      {token && (
        <button type="button" className={styles.slotCardButton} onClick={handleLike}>
          <img src={favorite ? likedIcon : notLikedIcon} alt="star" />
        </button>
      )}
      {isBlocked ? (
        <div className={styles.slotCardLock}>
          <img src={lockIcon} alt="lock" />
          <h6 onClick={() => onClick(token)}>Not available in your region</h6>
        </div>
      ) : null}
    </div>
  );
};

export default SlotsCard;
