import Big from 'big.js';
import { useContext, useEffect, useState } from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';

import diceGameIcon from 'assets/img/Missions/PopUpsImages/Dice.webp';
import emptyStars from 'assets/img/Missions/Stars/0.webp';
import missionsCheckMark from 'assets/img/Missions/check-decagram-outline.webp';

import { ContextPopUps } from 'context';
import {
  getFormattedGameName,
  getMissionGameLink,
  getMissionMultipliersWithImage,
  getMissionPopUpImageByProgress,
  getMissionProgress,
  IMissionFormattedProgress,
  IMissionMultiplier,
} from 'func/missions';
import { MISSION_ICONS } from 'components/constants/missions';
import { ButtonType } from 'components/Base/Button/types';
import useGame from 'hooks/useGame';
import { useLocalization } from 'components/Internationalization';
import { useNavigate } from 'hooks/useNavigate';

import Button from 'components/Base/Button';

import { MissionsStageGameStyle } from './styles';
import { IMissionsStageGame } from './types';

const MissionsStageGame: React.FC<IMissionsStageGame> = ({ data }) => {
  const navigate = useNavigate();
  const { translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const { gameId } = data;

  const [title, setTitle] = useState('');
  const [gameName, setGameName] = useState('');
  const [gameLink, setGameLink] = useState('');
  const [gameIcon, setGameIcon] = useState(diceGameIcon);
  const [gameCategory, setGameCategory] = useState<string | null>(null);
  const [starsIcon, setStarsIcon] = useState(emptyStars);
  const [nubmerOfBets, setNumberOfBets] = useState(100);
  const [missionMultipliers, setMissionMultipliers] = useState<IMissionMultiplier[]>([]);
  const [missionProgress, setMissionProgress] = useState<IMissionFormattedProgress[]>([]);

  const game = useGame({ id: gameId }, Boolean(data?.gameCategory));

  useEffect(() => {
    const { mission, progress } = data;

    const newGameName = getFormattedGameName(game, data.gameCategory);
    const newGameLink = getMissionGameLink(game, data.gameCategory);
    const newStarsIcon = getMissionPopUpImageByProgress(mission, progress);
    const newMultipliers = getMissionMultipliersWithImage(mission);
    const newProgress = getMissionProgress(mission, progress);
    const newGameIcon = MISSION_ICONS[newGameName.toLowerCase()];

    setTitle(mission.mission);
    setGameLink(newGameLink);
    setStarsIcon(newStarsIcon);
    setMissionMultipliers(newMultipliers);
    setMissionProgress(newProgress);
    setNumberOfBets(mission.num_bets || 100);
    setGameName(newGameName);
    setGameIcon((icon) => newGameIcon || icon);
    setGameCategory(data.gameCategory);
  }, [data, game]);

  const handleRedirect = () => {
    navigate(gameLink);
    setPopUpsOpen(null);
  };

  return (
    <MissionsStageGameStyle>
      <div className="missions__games--images__wrap">
        <img src={starsIcon} alt="icon" className="missions__games--images__image1" />
        <img src={gameIcon} alt="icon" className="missions__games--images__image2" />
      </div>
      <div className="missions__games--title">{translate(title)}</div>
      <div className="missions__games--description">
        {missionMultipliers.map((elem, index) => (
          <div className="missions__games--description__item" key={`${elem.image}${index}`}>
            <img src={elem.image} alt="icon" />
            <h4>{translate('popups.mission.stage.multiplier')}</h4>
            <h5>{elem.multiplier}</h5>
          </div>
        ))}
      </div>
      <div className="missions__games--progress--wrap">
        {missionProgress.map((elem, index) => (
          <div key={`${elem.betAmount}${index}`} className="missions__games--progress-item-wrapper">
            <div className="missions__games--progress--item">
              <CircularProgressbarWithChildren
                minValue={0}
                maxValue={nubmerOfBets}
                value={Number(elem.progress || 0)}
                styles={buildStyles({
                  textColor: '#ffffff',
                  pathColor: '#50A817',
                  trailColor: '#3C4561',
                })}
              >
                <h4>${elem.betAmount}</h4>
                <h5>{translate('popups.mission.stage.min.bet')}</h5>
              </CircularProgressbarWithChildren>
              <img
                src={missionsCheckMark}
                alt="icon"
                className={
                  Big(elem.progress || '0').gte(nubmerOfBets)
                    ? 'missions__games--progress--item-check active'
                    : 'missions__games--progress--item-check'
                }
              />
            </div>
            <div className="missions__games--progress--tooltip">
              {Big(elem?.progress || '0').gte(nubmerOfBets) ? nubmerOfBets : elem?.progress} / {nubmerOfBets}
            </div>
          </div>
        ))}
      </div>
      <Button
        variant={ButtonType.primary}
        title={`${translate('popups.mission.stage.button.play')} ${gameCategory || gameName}`}
        width="240px"
        onclick={handleRedirect}
      />
    </MissionsStageGameStyle>
  );
};

export default MissionsStageGame;
