import { useContext, useEffect, useRef, useState } from 'react';

import startIcon from 'assets/img/header/starCoin.webp';

import { useAppSelector } from 'hooks/useAppSelector';
import { ContextWallet } from 'context';
import { amount, checkIsVip } from 'func/common';
import { userProfile } from 'store/user/user.selectors';
import { generalDataVipRank } from 'store/generalData/generalDtata.selectors';

import Avatar from 'components/Base/Avatar';
import UserTooltip from './components/UserTooltip';

import styles from './styles.module.scss';

const UserInfo: React.FC = () => {
  const { walletUserStar } = useContext(ContextWallet);
  const imgRef = useRef<HTMLImageElement>();

  const [state, setState] = useState(false);

  const user = useAppSelector(userProfile);
  const vipRank = useAppSelector(generalDataVipRank);

  const handleInfo = () => {
    setState(!state);
  };

  const handleBlur = () => {
    setState(false);
  };

  useEffect(() => {
    const handleOpen = (event) => {
      if (!state && event.target !== imgRef.current) {
        handleBlur();
      }
      if (state && event.target === imgRef.current) {
        handleInfo();
      }
    };
    window.addEventListener('click', handleOpen);
    return () => {
      window.removeEventListener('click', handleOpen);
    };
  }, [imgRef]);

  return (
    <div className={styles.authUser}>
      <div className={styles.authUserDescription}>
        <p>{user?.alias}</p>
        <h3>
          {' '}
          <img src={startIcon} alt="icon" className={styles.authUserCoin} />
          {walletUserStar ? amount(walletUserStar) : '0.00000000'}
        </h3>
      </div>
      <Avatar
        imageRef={imgRef}
        className={styles.authUserBorderImg}
        userAvatar={user?.avatar?.avatar}
        vip={checkIsVip(vipRank, user?.rank?.id)}
        onClick={handleInfo}
      />
      <UserTooltip handleInfo={handleInfo} state={state} setState={setState} />
    </div>
  );
};

export default UserInfo;
