import { useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_STAR_POOL_HISTORY } from 'graphQl/query/dividends/starTokens';
import { amount, getDate, getImgSrc } from 'func/common';

import HighCardTitle from 'components/Base/Cards/HighCardTitle';
import { useLocalization } from 'components/Internationalization';

import { IStarHistory, IStarPoolHistory } from './types';
import styles from './styles.module.scss';

const StarPoolHistory: React.FC<IStarPoolHistory> = ({ isFlipped, setIsFlipped }) => {
  const { translate } = useLocalization();
  const [starPoolHistory, setStarPoolHistory] = useState<IStarHistory[]>(null);

  useQuery(GET_STAR_POOL_HISTORY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setStarPoolHistory(data.starTokenBurn);
    },
  });

  const handleFlip = () => setIsFlipped(!isFlipped);

  return (
    <HighCardTitle title={translate('dividends.star.history.title')} subtitle="" offBlur>
      <button className={styles.poolBtn} type="button" onClick={handleFlip}>
        {translate('dividends.star.pool.flip.button.back')}
      </button>
      <div className={styles.poolBackCard}>
        <div className={styles.poolBackCardHeader}>
          <p>{translate('dividends.star.table.date')}</p>
          <p>{translate('dividends.star.table.burned')}</p>
        </div>
        {starPoolHistory
          ? starPoolHistory.map((el) => (
              <div className={styles.poolBackCardContent} key={el.id}>
                <h4>{getDate(el.createdAt)}</h4>
                <h4>
                  <img src={getImgSrc('STAR')} alt="star" />
                  {amount(el.amount)} STARs
                </h4>
              </div>
            ))
          : null}
      </div>
    </HighCardTitle>
  );
};

export default StarPoolHistory;
