import { generate as id } from 'shortid';

import goldCoinSide from 'assets/img/CoinFlip/goldSide.webp';
import silverCoinSide from 'assets/img/CoinFlip/silverSide.webp';
import diceImage1 from 'assets/img/GamesRules/Dice/dice1.webp';
import diceImage2 from 'assets/img/GamesRules/Dice/dice2.webp';
import diceImage3 from 'assets/img/GamesRules/Dice/dice3.webp';
import diceImage4 from 'assets/img/GamesRules/Dice/dice4.webp';
import minesImage1 from 'assets/img/GamesRules/Mines/mines1.webp';
import minesImage2 from 'assets/img/GamesRules/Mines/mines2.webp';
import minesImage3 from 'assets/img/GamesRules/Mines/mines3.webp';
import minesImage4 from 'assets/img/GamesRules/Mines/mines4.webp';
import limboImage1 from 'assets/img/GamesRules/Limbo/limbo1.webp';
import limboImage2 from 'assets/img/GamesRules/Limbo/limbo2.webp';
import limboImage3 from 'assets/img/GamesRules/Limbo/limbo3.webp';
import coinFlipImage1 from 'assets/img/GamesRules/CoinFlip/coinFlip1.webp';
import coinFlipImage2 from 'assets/img/GamesRules/CoinFlip/coinFlip2.webp';
import coinFlipImage3 from 'assets/img/GamesRules/CoinFlip/coinFlip3.webp';
import kenoImage1 from 'assets/img/GamesRules/Keno/keno1.webp';
import kenoImage2 from 'assets/img/GamesRules/Keno/keno2.webp';
import kenoImage3 from 'assets/img/GamesRules/Keno/keno3.webp';
import blackJack1 from 'assets/img/GamesRules/BlackJack/BlackJack-1.webp';
import blackJack2 from 'assets/img/GamesRules/BlackJack/BlackJack-2.webp';
import blackJack3 from 'assets/img/GamesRules/BlackJack/BlackJack-3.webp';
import blackJack4 from 'assets/img/GamesRules/BlackJack/BlackJack-4.webp';
import blackJack5 from 'assets/img/GamesRules/BlackJack/BlackJack-5.webp';
import plinko1 from 'assets/img/GamesRules/Plinko/plinko1.webp';
import plinko2 from 'assets/img/GamesRules/Plinko/plinko2.webp';
import plinko3 from 'assets/img/GamesRules/Plinko/plinko3.webp';

import allInactiveImg from 'assets/img/GamesFiler/all-inactine.webp';
import inHouseInactiveImg from 'assets/img/GamesFiler/in-house-inactive.webp';
import slotsInactiveImg from 'assets/img/GamesFiler/slots-inactive.webp';
import tableInactiveImg from 'assets/img/GamesFiler/tables-inactive.webp';
import liveInactiveImg from 'assets/img/GamesFiler/live-inactive.webp';
import allActiveImg from 'assets/img/GamesFiler/All-active.webp';
import inHouseActiveImg from 'assets/img/GamesFiler/In-House-active.webp';
import slotsActiveImg from 'assets/img/GamesFiler/Slots-active.webp';
import tableActiveImg from 'assets/img/GamesFiler/Table-active.webp';
import liveActiveImg from 'assets/img/GamesFiler/live-active.webp';

import { CoinBetMode, TranslationType } from 'types';
import { IGameNavItem } from 'components/common/GamesNavigation/types';

import { CATEGORY_NAMES } from './constants';

export enum InitSlotsGameState {
  stop = 'stop',
  request = 'request',
  blocked = 'blocked',
  display = 'display',
  loading = 'loading',
  freeSpins = 'freeSpins',
}

export const ELimboCondition = {
  notStarted: 'notStarted',
  getStarted: 'getStarted',
  autoNotStarted: 'autoNotStarted',
  autoStarted: 'autoStarted',
};

export const EDiceCondition = {
  notStarted: 'notStarted',
  getStarted: 'getStarted',
  autoNotStarted: 'autoNotStarted',
  autoStarted: 'autoStarted',
};
export const ECoinCondition = {
  notStarted: 'notStarted',
  getStarted: 'getStarted',
  sessionNotStarted: 'sessionNotStarted',
  sessionInit: 'sessionInit',
  sessionStarted: 'sessionStarted',
};

export const CoinAnimationNames = {
  heads: 'heads',
  headsFast: 'heads-fast',
  tails: 'tails',
  tailsFast: 'tails-fast',
};

export const WheelStatus = {
  active: 'Active',
};

export const FastModeGames = {
  dice: 'FastModeDice',
  mines: 'FastModeMines',
  limbo: 'FastModeLimbo',
  coin: 'FastModeCoin',
  keno: 'FastModeKeno',
  blackJack: 'FastModeBlackJack',
  texasHoldem: 'FastModeTexasHoldem',
  plinko: 'FastModePlinko',
};

export const HotKeys = {
  dice: 'HotKeysDice',
  mines: 'HotKeysMines',
  limbo: 'HotKeysLimbo',
  coin: 'HotKeysCoin',
  keno: 'HotKeysKeno',
  blackJack: 'HotKeysBlackJack',
  texasHoldem: 'HotKeysTexasHoldem',
  plinko: 'HotKeysPlinko',
};

export enum EGamesNames {
  dice = 'dice',
  mines = 'mines',
  limbo = 'limbo',
  coinFlip = 'coinflip',
  keno = 'keno',
  blackJack = 'blackjack',
  texasHoldem = 'texasHoldem',
  plinko = 'plinko',
}

export enum EGamesSlugName {
  dice = 'dice',
  mines = 'mines',
  limbo = 'limbo',
  coinFlip = 'coinflip',
  keno = 'keno',
  blackJack = 'blackjack',
  blackJackVip = 'blackjack-vip',
  texasHoldem = 'texas-holdem',
  texasHoldemVip = 'texas-holdem-vip',
  plinko = 'plinko',
}

export const DEFAULT_HOTKEYS = [
  { key: 'J', description: 'popups.hotkeys.min' },
  { key: 'K', description: 'popups.hotkeys.half' },
  { key: 'SPACE', description: 'popups.hotkeys.bet' },
  { key: 'L', description: 'popups.hotkeys.mul' },
];
export const RulesDescription = [
  {
    name: EGamesNames.dice,
    images: [diceImage1, diceImage2, diceImage3, diceImage4],
    description: [
      'popups.rules.dice.first',
      'popups.rules.dice.second',
      'popups.rules.dice.third',
      'popups.rules.dice.fourth',
    ],
  },
  {
    name: EGamesNames.mines,
    images: [minesImage1, minesImage2, minesImage3, minesImage4],
    description: [
      'popups.rules.mines.first',
      'popups.rules.mines.second',
      'popups.rules.mines.third',
      'popups.rules.mines.fourth',
    ],
  },
  {
    name: EGamesNames.limbo,
    images: [limboImage1, limboImage2, limboImage3],
    description: ['popups.rules.limbo.first', 'popups.rules.limbo.second', 'popups.rules.limbo.third'],
  },
  {
    name: EGamesNames.coinFlip,
    images: [coinFlipImage1, coinFlipImage2, coinFlipImage3],
    description: ['popups.rules.coinflip.first', 'popups.rules.coinflip.second', 'popups.rules.coinflip.third'],
  },
  {
    name: EGamesNames.keno,
    images: [kenoImage1, kenoImage2, kenoImage3],
    description: ['popups.rules.keno.first', 'popups.rules.keno.second', 'popups.rules.keno.third'],
  },
  {
    name: EGamesNames.blackJack,
    images: [blackJack1, blackJack2, blackJack3, blackJack4, blackJack5],
    description: [
      'popups.rules.blackjack.first',
      'popups.rules.blackjack.second',
      'popups.rules.blackjack.third',
      'popups.rules.blackjack.fourth',
      'popups.rules.blackjack.fifth',
    ],
  },
  {
    name: EGamesNames.plinko,
    images: [plinko1, plinko2, plinko3],
    description: ['popups.rules.plinko.first', 'popups.rules.plinko.second', 'popups.rules.plinko.third'],
  },
];

export const controlDataKeyCoinFlip = [
  { key: 'J', description: 'popups.hotkeys.min' },
  { key: 'K', description: 'popups.hotkeys.half' },
  { key: 'L', description: 'popups.hotkeys.mul' },
  { key: 'B', description: 'popups.hotkeys.mode' },
  { key: 'N', description: 'popups.hotkeys.coinflip.heads' },
  { key: 'M', description: 'popups.hotkeys.coinflip.tails' },
  { key: 'SPACE', description: 'popups.hotkeys.coinflip.bet' },
];

export const controlDataKeysKeno = [
  { key: 'J', description: 'popups.hotkeys.min' },
  { key: 'K', description: 'popups.hotkeys.half' },
  { key: 'L', description: 'popups.hotkeys.mul' },
  { key: 'A', description: 'popups.hotkeys.keno.auto' },
  { key: 'X', description: 'popups.hotkeys.keno.clear' },
  { key: 'SPACE', description: 'popups.hotkeys.bet' },
];

export const controlDataKeysTexasHoldem = [
  { key: 'SPACE', description: 'popups.hotkeys.texas-holdem.start' },
  { key: 'B', description: 'popups.hotkeys.texas-holdem.new-bet' },
  { key: 'R', description: 'popups.hotkeys.texas-holdem.rebet' },
  { key: '1', description: 'popups.hotkeys.texas-holdem.x1' },
  { key: '2', description: 'popups.hotkeys.texas-holdem.x2' },
  { key: '3', description: 'popups.hotkeys.texas-holdem.x3' },
  { key: 'C', description: 'popups.hotkeys.texas-holdem.check' },
  { key: 'F', description: 'popups.hotkeys.texas-holdem.fold' },
];

export const buttonsCoinFlipList = [
  {
    title: 'coinflip.button.heads',
    src: goldCoinSide,
    class: 'coin__button--multiply',
    condition: false,
    betCondition: CoinBetMode.heads,
    id: id(),
  },
  {
    title: 'coinflip.button.tails',
    src: silverCoinSide,
    class: 'coin__button--multiply',
    condition: false,
    betCondition: CoinBetMode.tails,
    id: id(),
  },
  {
    title: 'coinflip.button.heads',
    src: goldCoinSide,
    class: 'coin__button--regular',
    condition: true,
    betCondition: CoinBetMode.heads,
    id: id(),
  },
  {
    title: 'coinflip.button.tails',
    src: silverCoinSide,
    class: 'coin__button--regular',
    condition: true,
    betCondition: CoinBetMode.tails,
    id: id(),
  },
];

export const controlDataKeysPlinko = [
  { key: 'J', description: 'popups.hotkeys.min' },
  { key: 'K', description: 'popups.hotkeys.half' },
  { key: 'L', description: 'popups.hotkeys.mul' },
  { key: 'SPACE', description: 'popups.hotkeys.bet' },
];

export const CoinMultiplier = 1.96;

export const DICE_ANIMATION_KEY = 'dice.animation';
export const DEFAULT_BET_VALUE = '0.00000000';
export const DEFAULT_MIN_BET_VALUE = '0.00000001';
export const DEFAULT_MAX_BET_VALUE = '1.00000000';
export const DEFAULT_ZERO_BET = '0.00000000';
export const DICE_BET_ANIMATION_DURATION = 1000;
export const DICE_RANGE_STEP = 1;
export const DICE_RANGE_MIN_VALUE = -3;
export const DICE_RANGE_MAX_VALUE = 102;

export const getGamesNavigation = (t: TranslationType): IGameNavItem[] => {
  return [
    {
      title: t('games.nav.all'),
      urlTitle: CATEGORY_NAMES.allGames.urlTitle,
      inactiveImg: allInactiveImg,
      activeImg: allActiveImg,
    },
    {
      title: t('games.nav.inhouse'),
      urlTitle: CATEGORY_NAMES.inHouse.urlTitle,
      inactiveImg: inHouseInactiveImg,
      activeImg: inHouseActiveImg,
    },
    {
      title: t('games.nav.slots'),
      urlTitle: CATEGORY_NAMES.slots.urlTitle,
      inactiveImg: slotsInactiveImg,
      activeImg: slotsActiveImg,
    },
    {
      title: t('games.nav.table'),
      urlTitle: CATEGORY_NAMES.tableGames.urlTitle,
      inactiveImg: tableInactiveImg,
      activeImg: tableActiveImg,
    },
    {
      title: t('games.nav.live'),
      urlTitle: CATEGORY_NAMES.liveCasino.urlTitle,
      inactiveImg: liveInactiveImg,
      activeImg: liveActiveImg,
    },
  ];
};

export enum EKenoRisk {
  low = 'Risk Low',
  medium = 'Risk Medium',
  high = 'Risk High',
  classic = 'Risk Classic',
}

export enum EKenoSquareClass {
  lose = 'kenoSquareItemLose',
  pick = 'kenoSquareItemPick',
  win = 'kenoSquareItemWin',
  fastLose = 'kenoSquareItemLoseFast',
  fastPick = 'kenoSquareItemPickFast',
  fastWin = 'kenoSquareItemWinFast',
  detailLose = 'kenoSquareItemDetailLose',
  detailWin = 'kenoSquareItemDetailWin',
  detailPick = 'kenoSquareItemDetailPick',
}

export const initialGameSettings = {
  minBet: '0',
  maxBet: '0',
  maxPay: '0',
};

export const KenoCondition = {
  notStarted: 'notStarted',
  getStarted: 'getStarted',
  autoNotStarted: 'autoNotStarted',
  autoStarted: 'autoStarted',
};

export const initialKenoWinInfo = {
  id: '',
  multiplier: '',
  payout: '0',
  displayName: '',
  oddsNum: 0,
};

export const initialAutoState = {
  betsNumbers: '',
  maxBetAmount: '',
  onLossIncrease: '',
  isOnLossIncrease: false,
  onWinIncrease: '',
  isOnWinIncrease: false,
  stopOnLossAmount: '',
  stopOnLossAmountDisplay: '',
  stopOnWinAmount: '',
  stopOnWinAmountDisplay: '',
  firstSpin: true,
  autoBetAmount: '',
};

export enum EGamesButtonsBar {
  speed = 'Warp Speed',
  verify = 'Verify',
  limits = 'Limits',
  rules = 'Rules',
  keys = 'Hot Keys',
  statistic = 'Live Statistic',
}

export enum EWheelImg {
  item1 = 'item-1.webp',
  item2 = 'item-2.webp',
  item3 = 'item-3.webp',
  item4 = 'item-4.webp',
  item5 = 'item-5.webp',
  item6 = 'item-6.webp',
  item7 = 'item-7.webp',
  item8 = 'item-8.webp',
  wheelBg = 'wheelBg.webp',
  bright = 'bright.webp',
  mainImage = 'mainImage.webp',
  mainImageMob = 'mainImageMob.webp',
  spinButton = 'spinButton.webp',
  wheel = 'wheel.webp',
  wheelBgCircle = 'wheelBgCircle.webp',
  wheelHeader = 'wheelHeader.webp',
  wheelHeaderMob = 'wheelHeaderMob.webp',
  pointer = 'wheelPointer.webp',
  winImage = 'winImage.webp',
  winInfo = 'winInfo.webp',
}

export enum EBonus {
  default = '',
  available = 'available',
  closed = 'closed',
}

export const GAMES_REQUEST_STEP = 24;

export const DEFAULT_PARAMS = 'default';

export enum GameSubcategory {
  favorite = 'favorite',
  new = 'new',
  popular = 'popular',
  dropsAndWins = 'dropsAndWins',
  vip = 'vip',
  bonus = 'bonus',
}
