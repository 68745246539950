import { useContext, useState } from 'react';

import { checkIsVip, getImgSrc } from 'func/common';
import { getPlaceImage } from 'func/prepareDataLottery';
import { ContextPopUps } from 'context';
import { popUps } from 'components/constants/constants';
import { useLocalization } from 'components/Internationalization';
import { useAppSelector } from 'hooks/useAppSelector';
import { generalDataVipRank } from 'store/generalData/generalDtata.selectors';

import LotteryRoundSlider from 'components/Games/Lottery/LotteryDesktop/components/LotteryDesktopResult/components/LotteryRoundSlider';
import Avatar from 'components/Base/Avatar';

import LotteryDesktopResultStyle from './styles';
import { ILotteryDesktopResult } from './types';

const LotteryDesktopResult: React.FC<ILotteryDesktopResult> = ({ data, sliderData }) => {
  const { setPopUpsOpen } = useContext(ContextPopUps);
  const { translate } = useLocalization();

  const [currentRound, setCurrentRound] = useState(0);

  const vipRank = useAppSelector(generalDataVipRank);

  const handleClick = (alias, avatar, rank) => {
    setPopUpsOpen({
      modalOpen: popUps.tipsInfo,
      data: {
        alias,
        avatar,
        rank,
      },
    });
  };

  return (
    <LotteryDesktopResultStyle>
      <div className="lot__result-header">
        <div className="lot__result-header-title">{translate('popups.lottery.result')}</div>
        <LotteryRoundSlider data={sliderData} setCurrentRound={setCurrentRound} currentRound={currentRound} />
      </div>
      {data[currentRound]?.map((elem, index) => (
        <div className="lot__result-table" key={elem.id}>
          <div className="lot__result-table-col">
            <div className="lot__result-table-row1">
              {getPlaceImage(index) ? <img src={getPlaceImage(index)} alt="icon" /> : <p>{index + 1}</p>}
            </div>
            <div
              className="lot__result-table-row2"
              onClick={() => handleClick(elem.alias, elem.avatar, elem.rank || 1)}
            >
              <Avatar className="lot__result-avatar" userAvatar={elem.avatar} vip={checkIsVip(vipRank, elem.rank)} />
              <h5>{elem?.alias}</h5>
            </div>
            <div className="lot__result-table-row3">
              <img src={getImgSrc(elem.displayName)} alt="icon" />
              <h3>{elem.amount}</h3>
            </div>
          </div>
        </div>
      ))}
    </LotteryDesktopResultStyle>
  );
};

export default LotteryDesktopResult;
