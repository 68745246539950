import React, { useContext, useReducer } from 'react';
import { initialState } from './initialState';
import { limboReducer } from './limboReduser';
import { IAction } from './types';

const ContextLimbo = React.createContext(null);

export const useLimbo = (): [state: typeof initialState, dispatch: (action: IAction) => void] =>
  useContext(ContextLimbo);

export const LimboProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const value = useReducer(limboReducer, initialState);

  return <ContextLimbo.Provider value={value}>{children}</ContextLimbo.Provider>;
};
