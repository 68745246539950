import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';

import FrontCard from './components/FrontCard';
import BackCard from './components/BackCard';
import { IEventCard } from './types';

const EventCard: React.FC<IEventCard> = ({
  image,
  type,
  title,
  description,
  eventPrizeToken,
  prize,
  endAt,
  startAt,
  rulesArr,
  id,
  totalWagerTokenCode,
  onTick,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      <FrontCard
        image={image}
        onClick={handleFlip}
        type={type}
        title={title}
        description={description}
        eventPrizeToken={eventPrizeToken}
        prize={prize}
        startAt={startAt}
        endAt={endAt}
        id={id}
        totalWagerTokenCode={totalWagerTokenCode}
        onTick={onTick}
      />
      <BackCard
        image={image}
        onClick={handleFlip}
        type={type}
        title={title}
        description={description}
        eventPrizeToken={eventPrizeToken}
        prize={prize}
        startAt={startAt}
        endAt={endAt}
        rulesArr={rulesArr}
        id={id}
        totalWagerTokenCode={totalWagerTokenCode}
      />
    </ReactCardFlip>
  );
};

export default EventCard;
