import { IButtonLink } from './types';
import styles from './styles.module.scss';

const ButtonLink: React.FC<IButtonLink> = ({ link, title, subtitle, icon }) => {
  return (
    <a className={styles.link} href={link} rel="noopener noreferrer" target="_blank">
      <img src={icon} alt="link" />
      <div>
        <p className={styles.title}>{title}</p>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>
    </a>
  );
};

export default ButtonLink;
