import styled from 'styled-components';
import { BuyTicketSize } from 'components/Games/Lottery/base/BuyTicketBlock/types';
import point from 'assets/img/common/cursor-pointer.webp';

interface Props {
  size: BuyTicketSize;
}

const BuyTicketBlockStyle = styled.div.withConfig({
  shouldForwardProp: (prop) => !['size'].includes(prop),
})<Props>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 15px 0;

  .buy__ticket-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .buy__ticket-content__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 7px;
    height: 20px;
  }

  .buy__ticket-content__item.item1 {
    width: 30%;
  }

  .buy__ticket-content__item.item2 {
    width: 70%;
  }
  .buy__ticket-content__item.item2 img {
    width: 30px;
  }

  .buy__ticket-content__item.item2 h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin: 0;

    color: #ffffff;
  }

  .buy__ticket-content__item-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin: 0;

    color: #888aa7;
  }

  .buy__ticket-content-submit {
    display: flex;
    width: 100%;
    padding: 0;
    height: 38px;
    margin: 5px 0 0 0;
    position: relative;
  }

  .buy__ticket-content-submit input {
    width: 100%;
    height: 38px;
    border-radius: 20px;
    border: none;
    line-height: 20px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.2);
    font-family: Montserrat;
    font-weight: 400;
    font-size: 14px;
    color: rgb(255, 255, 255);
    padding-left: 25px;
    box-shadow: none;
    outline: none;

    &:focus,
    &:active {
      border: 2px solid rgb(108, 93, 211);
      box-shadow: none;
      outline: none;
    }
    &:disabled {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }

  .buy__ticket-content-submit--button {
    position: absolute;
    right: 0;
  }
  .input--label--reset {
    position: absolute;
    right: ${(props) => (props.size === BuyTicketSize.BIG ? '165px' : '125px')};
    top: 10px;
    color: #888aa7;
    font-size: 13px;
    cursor: url(${point}), auto !important;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input--label--reset.disabled {
    pointer-events: none;
  }

  @media (max-width: 1300px) {
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1100px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 580px) {
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 400px) {
  }
`;

export default BuyTicketBlockStyle;
