import clsx from 'clsx';
import dayjs from 'dayjs';
import Big from 'big.js';
import { pathOr } from 'ramda';
import { styled } from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useTimer } from 'react-timer-hook';

import { GET_TRANSACTIONS } from 'graphQl/query/transactions/transactions';
import { useLocalization } from 'components/Internationalization';
import { useAppSelector } from 'hooks/useAppSelector';
import { userProfile, userToken } from 'store/user/user.selectors';
import { ContextPopUps } from 'context';
import { popUps } from 'components/constants/constants';

import styles from './styles.module.scss';
import { IWheelOfFortuneActions } from './types';
import { WHEEL_TRANSACTIONS } from './constants';
import { getFormattedTime } from './utils';
import { WHEEL_ANIMATION_TIME } from '../../constants';

const WheelOfFortuneActions: React.FC<IWheelOfFortuneActions> = ({
  className,
  reward,
  wheelSettings,
  available,
  disabled,
  onSpin,
  onChangeAvailable,
  onShowHistory,
}) => {
  const { hours, minutes, seconds, restart } = useTimer({ expiryTimestamp: new Date(), onExpire: handleExpire });

  const { translate } = useLocalization();
  const { setPopUpsOpen } = useContext(ContextPopUps);

  const [wheelEnabled, setWheelEnabled] = useState(true);

  const user = useAppSelector(userProfile);
  const token = useAppSelector(userToken);

  const { data, refetch } = useQuery(GET_TRANSACTIONS, {
    fetchPolicy: 'no-cache',
    skip: !token,
    variables: WHEEL_TRANSACTIONS,
  });

  useEffect(() => {
    if (reward) {
      // animation timeout
      setTimeout(async () => refetch(), WHEEL_ANIMATION_TIME - 200);
    }
  }, [reward]);

  useEffect(() => {
    if (user) {
      const { totalWager } = user;

      const newIsEnabled = Big(totalWager).gt(0);

      setWheelEnabled(newIsEnabled);
    }
  }, [user]);

  useEffect(() => {
    if (data) {
      const lastSpin = pathOr<string>(null, ['paginatedTransactions', 'items', '0', 'createdAt'], data);

      if (lastSpin) {
        const now = dayjs();
        const newSpinTime = dayjs(lastSpin).add(Number(wheelSettings.wheel_duration), 'minutes');
        const newIsAvailable = newSpinTime.isBefore(now);

        if (newIsAvailable) {
          onChangeAvailable(true);
          return;
        }

        onChangeAvailable(false);
        restart(newSpinTime.toDate(), true);
      }
    }
  }, [data]);

  const handleLogin = () => {
    setPopUpsOpen(popUps.login);
  };

  function handleExpire() {
    onChangeAvailable(true);
  }

  return wheelEnabled ? (
    <div className={clsx(styles.wrapper, className)}>
      {available ? (
        <StyledButton
          type="button"
          className={styles.button}
          $bgColor={wheelSettings?.wheel_button_color}
          disabled={disabled}
          onClick={user ? onSpin : handleLogin}
        >
          {translate(user ? 'popups.wheel.button.spin' : 'popups.wheel.button.login')}
        </StyledButton>
      ) : (
        <>
          <StyledText className={styles.notAvailable} $textColor={wheelSettings?.wheel_text_color}>
            {translate('popups.wheel.titmer.title')}:
          </StyledText>
          <div className={styles.timer}>
            <StyledTime className={styles.time} $bgColor={wheelSettings?.wheel_button_color}>
              <p className={styles.unit}>{getFormattedTime(hours)}</p>
              <span className={styles.label}>{translate('general.timer.hour.short')}</span>
            </StyledTime>
            <div className={styles.div}>:</div>
            <StyledTime className={styles.time} $bgColor={wheelSettings?.wheel_button_color}>
              <p className={styles.unit}>{getFormattedTime(minutes)}</p>
              <span className={styles.label}>{translate('general.timer.min')}</span>
            </StyledTime>
            <div className={styles.div}>:</div>
            <StyledTime className={styles.time} $bgColor={wheelSettings?.wheel_button_color}>
              <p className={styles.unit}>{getFormattedTime(seconds)}</p>
              <span className={styles.label}>{translate('general.timer.sec')}</span>
            </StyledTime>
          </div>
        </>
      )}
      <StyledHistory
        type="button"
        className={styles.history}
        $textColor={wheelSettings?.wheel_text_color}
        onClick={() => onShowHistory()}
      >
        {translate('popups.wheel.button.wins')}
      </StyledHistory>
    </div>
  ) : (
    <StyledText className={clsx(styles.wheelDisabled, className)} $textColor={wheelSettings?.wheel_text_color}>
      {translate('popups.wheel.not.available')}
    </StyledText>
  );
};

export default WheelOfFortuneActions;

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !prop.includes('$'),
})<{ $bgColor: string }>`
  ${({ $bgColor }) => ($bgColor ? `background: ${$bgColor};` : '')}
`;

const StyledTime = styled.div.withConfig({
  shouldForwardProp: (prop) => !prop.includes('$'),
})<{ $bgColor: string }>`
  ${({ $bgColor }) => ($bgColor ? `background: ${$bgColor};` : '')}
`;

const StyledText = styled.p.withConfig({
  shouldForwardProp: (prop) => !prop.includes('$'),
})<{ $textColor: string }>`
  ${({ $textColor }) => ($textColor ? `color: ${$textColor} !important;` : '')}
`;

const StyledHistory = styled.button.withConfig({
  shouldForwardProp: (prop) => !prop.includes('$'),
})<{ $textColor: string }>`
  ${({ $textColor }) => ($textColor ? `color: ${$textColor} !important;` : '')}
`;
