import clsx from 'clsx';

import { ReactComponent as ArrowIcon } from 'assets/img/common/arrow.svg';
import { ISliderArrow } from 'components/Base/WalletSlider/components/arrow/types';

import styles from './styles.module.scss';

const BonusItemArrows: React.FC<ISliderArrow> = (props) => {
  const { orientation = 'right', onClick } = props;

  return (
    <button
      type="button"
      className={clsx(styles.button, orientation === 'left' ? styles.left : styles.right)}
      onClick={onClick}
    >
      <ArrowIcon className={clsx(styles.arrow, orientation === 'left' ? styles.arrowLeft : styles.arrowRight)} />
    </button>
  );
};

export default BonusItemArrows;
