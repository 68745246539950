import clsx from 'clsx';

import { IButton } from './types';
import styles from './styles.module.scss';

const Button: React.FC<IButton> = ({
  className = '',
  variant,
  type = 'button',
  title,
  onclick,
  width,
  disabled,
  ...props
}) => {
  return (
    <button
      type={type}
      className={clsx(styles.commonButton, styles[variant], className)}
      style={{ width: width || '100%' }}
      disabled={disabled}
      onClick={onclick}
      {...props}
    >
      {title}
    </button>
  );
};

export default Button;
