import React from 'react';

import { useKeno } from 'context/contextKeno/contextKeno';
import { popUps } from 'components/constants/constants';

import MyBetsTableWrap from 'components/Games/base/MyBetsTable';

import styles from '../../styles.module.scss';

const MyKenoBets: React.FC = () => {
  const [state] = useKeno();
  const { historyList } = state;

  return (
    <div className={styles.kenoTableMyBetsWrap}>
      <MyBetsTableWrap history={historyList} popUpsWindow={popUps.kenoBetDetails} />
    </div>
  );
};

export default MyKenoBets;
