import clsx from 'clsx';
import { useContext } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { Tag } from '@tryghost/content-api';

import imagePlaceholder from 'assets/img/Blog/image-placeholder.webp';

import BlogContext from 'context/contextBlog/context';
import { Links } from 'components/constants';
import { getNotificationTime } from 'func/notifications';
import { filterArticleTags } from 'func/blog';

import Link from 'components/Base/Links/Link';

import styles from './styles.module.scss';
import { IBlogItem } from './types';

const BlogItem: React.FC<IBlogItem> = ({ article }) => {
  const { onGetArticlesByTag } = useContext(BlogContext);

  const link = Links.blogPost.replace(':slug', article.slug);

  const handleLinkClick = () => {
    scroll.scrollTo(0, { duration: 0, delay: 0 });
  };

  const handleGetArticelsByTag = (newTag: Tag) => {
    scroll.scrollTo(0, { duration: 0, delay: 0 });
    onGetArticlesByTag(newTag);
  };

  return (
    <article className={clsx(styles.article, 'animate__animated animate__fadeInUp')}>
      <Link to={link} className={styles.imageWrapper} onClick={handleLinkClick}>
        <img
          className={styles.image}
          src={article.feature_image || imagePlaceholder}
          alt={article.feature_image_alt || article.feature_image_caption || 'placeholder'}
        />
      </Link>
      <div className={styles.contentWrapper}>
        <div className={styles.meta}>
          <p className={styles.tagWrapper}>
            {article.tags
              ? filterArticleTags(article.tags).map((t) => (
                  <span key={t.id} className={styles.tag}>
                    <button type="button" onClick={() => handleGetArticelsByTag(t)}>
                      {t.name}
                    </button>
                  </span>
                ))
              : null}
          </p>
          <p className={styles.date}>{getNotificationTime(article.published_at)}</p>
        </div>
        <Link to={link} className={styles.title} onClick={handleLinkClick}>
          {article.title}
        </Link>
        <p className={styles.desc}>{article.feature_image_caption}</p>
        <Link to={link} className={styles.link} onClick={handleLinkClick}>
          <span>Read more</span>
        </Link>
      </div>
    </article>
  );
};

export default BlogItem;
