import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useMediaQuery } from 'react-responsive';

import { footerTelegramChats } from 'components/constants/pages';

import telegramIcon from 'assets/img/footer/telegram.svg';
import mainChannelIcon from 'assets/img/footer/main-chat.svg';

import styles from './styles.module.scss';

const FooterTelegram: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' });
  return (
    <>
      <div className={styles.footerSocialItem} data-tip data-for="telegram">
        <img src={telegramIcon} alt="icon" />
      </div>
      <ReactTooltip
        id="telegram"
        place="top"
        effect="solid"
        className={styles.telWrap}
        delayHide={400}
        arrowColor="transparent"
        overridePosition={({ left, top }, currentEvent, currentTarget, node) => {
          const d = document.documentElement;
          left = Math.min(d.clientWidth - node.clientWidth, left);
          top = Math.min(d.clientHeight - node.clientHeight, top);
          left = Math.max(0, isMobile ? left : left - 70);
          top = Math.max(0, top);
          return { top, left };
        }}
      >
        <div className={styles.telContent}>
          <h3>Channel</h3>
          <div className={styles.telOfficialItem}>
            <a target="_blank" rel="noopener noreferrer" href="https://t.me/starbets_io_channel">
              <img src={mainChannelIcon} alt="channel" />
              <p>StarBets.io Official</p>
            </a>
          </div>
          <h3>Chats</h3>
          <div className={styles.telFlagsWrap}>
            {footerTelegramChats.map((item) => (
              <a
                key={item.title}
                target="_blank"
                rel="noopener noreferrer"
                href={item.link}
                className={styles.telFlagItem}
              >
                <img src={item.img} alt={item.title} />
                <p>{item.title}</p>
              </a>
            ))}
          </div>
        </div>
      </ReactTooltip>
    </>
  );
};

export default FooterTelegram;
