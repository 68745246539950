import React from 'react';
import { generate as id } from 'shortid';

import { useLocalization } from 'components/Internationalization';

import goldCoinSide from 'assets/img/CoinFlip/goldSide.webp';
import silverCoinSide from 'assets/img/CoinFlip/silverSide.webp';

import CoinHistoryStyle from './styles';

interface Props {
  data: any;
}

const CoinHistory: React.FC<Props> = ({ data }) => {
  const { translate } = useLocalization();

  return (
    <CoinHistoryStyle>
      <div className="coin__history__title">{translate('inHouse.games.history')}</div>
      <div className="coin__history__content">
        {data?.length > 0 &&
          data.map((elem) => (
            <div key={id()} className="coin__history__content-elem first">
              {elem.result === 'win' ? (
                <img
                  src={elem?.condition === 'heads' ? goldCoinSide : silverCoinSide}
                  alt="icon"
                  className="coin__history--img__win"
                />
              ) : (
                <img
                  src={elem?.condition === 'heads' ? goldCoinSide : silverCoinSide}
                  alt="icon"
                  className="coin__history--img__lose"
                />
              )}
            </div>
          ))}
        <div className="history__elem-shadow" />
      </div>
    </CoinHistoryStyle>
  );
};

export default React.memo(CoinHistory);
