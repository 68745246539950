import { NavigateOptions, To, useNavigate as useRouterNavigate } from 'react-router-dom';

import { useLocalization } from 'components/Internationalization';
import { getPathWithLanguage } from 'func/localization';

export const useNavigate = () => {
  const navigate = useRouterNavigate();
  const { language } = useLocalization();

  const handleNavigate = (to: To, options?: NavigateOptions) => {
    if (typeof to === 'string') {
      const newPath = getPathWithLanguage(to, language);

      navigate(newPath, options);
      return;
    }

    let newPath: string;
    const currentPathname = to.pathname;

    if (currentPathname) {
      newPath = getPathWithLanguage(currentPathname, language);
    }

    const newTo = { ...to };

    if (newPath) {
      newTo.pathname = newPath;
    }

    navigate(newTo, options);
  };

  return handleNavigate;
};

export type CustomNavigateFunction = (to: To, options?: NavigateOptions) => void;
