import React from 'react';
import { generate as id } from 'shortid';
import { fillArray } from 'func/common';
import CardWrapper from '../CardWrapper';
import StyledEmptyTable from './styles';

interface Props {
  lines: number;
  marginTop?: string;
}

const EmptyTable: React.FC<Props> = ({ lines, marginTop }) => {
  const emptyCount = fillArray(lines);
  return (
    <CardWrapper marginTop={marginTop || '50px'}>
      <StyledEmptyTable>
        <thead>
          <tr>
            <td />
          </tr>
        </thead>
        <tbody>
          {emptyCount.map((elem) => (
            <tr key={elem + String(id())}>
              <td />
            </tr>
          ))}
        </tbody>
      </StyledEmptyTable>
    </CardWrapper>
  );
};

export default EmptyTable;
