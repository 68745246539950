import clsx from 'clsx';
import { styled } from 'styled-components';
import { useEffect, useState } from 'react';

import { useLocalization } from 'components/Internationalization';

import styles from './styles.module.scss';
import { IWheelOfFortuneWheel } from './types';
import {
  WHEEL_ANIMATION_TIME,
  WHEEL_BG_IMAGE_NAME,
  WHEEL_BUTTON_IMAGE_NAME,
  WHEEL_IMAGE_NAME,
  WHEEL_POINTER_IMAGE_NAME,
  WHEEL_WIN_IMAGE_NAME,
} from '../../constants';
import { getWheelRotate } from '../../utils';

const WheelOfFortuneWheel: React.FC<IWheelOfFortuneWheel> = ({
  className,
  reward,
  wheelUrl,
  available,
  disabled,
  onSpin,
}) => {
  const { translate } = useLocalization();

  const [animate, setAnimate] = useState(false);
  const [showReward, setShowReward] = useState(false);
  const [wheelBgImage, setWheelBgImage] = useState('');
  const [wheelImage, setWheelImage] = useState('');
  const [wheelPointerImage, setWheelPointerImage] = useState('');
  const [wheelButtonImage, setWheelButtonImage] = useState('');
  const [wheelWinImage, setWheelWinImage] = useState('');

  useEffect(() => {
    if (reward) {
      const newRotate = getWheelRotate(reward.weight);

      window.document.body.style.setProperty('--wheel-rotate', `${newRotate}deg`);

      setTimeout(() => setAnimate(true));
      setTimeout(() => setShowReward(true), WHEEL_ANIMATION_TIME);
    } else {
      window.document.body.style.setProperty('--wheel-rotate', `${0}deg`);

      setAnimate(false);
      setShowReward(false);
    }
  }, [reward]);

  useEffect(() => {
    if (wheelUrl) {
      const newWheelBgImage = `${wheelUrl}${WHEEL_BG_IMAGE_NAME}`;
      const newWheelImage = `${wheelUrl}${WHEEL_IMAGE_NAME}`;
      const newWheelPointerImage = `${wheelUrl}${WHEEL_POINTER_IMAGE_NAME}`;
      const newWheelButtonImage = `${wheelUrl}${WHEEL_BUTTON_IMAGE_NAME}`;
      const newWheelWinImage = `${wheelUrl}${WHEEL_WIN_IMAGE_NAME}`;

      setWheelBgImage(newWheelBgImage);
      setWheelImage(newWheelImage);
      setWheelPointerImage(newWheelPointerImage);
      setWheelButtonImage(newWheelButtonImage);
      setWheelWinImage(newWheelWinImage);
    }
  }, [wheelUrl]);

  const handleSpin = () => {
    if (!disabled && available) onSpin();
  };

  return (
    <div className={clsx(styles.wrapper, className)}>
      <StyledWheelImage className={styles.wheelWrapper} $bgImage={wheelBgImage}>
        <img className={styles.pointer} src={wheelPointerImage} alt="Pointer" />
        <img className={clsx(styles.wheel, animate ? styles.active : '')} src={wheelImage} alt="Wheel" />
        <img className={styles.button} src={wheelButtonImage} alt="Spin button" onClick={handleSpin} />
        <span className={styles.text}>{translate('popups.wheel.button.spin')}</span>
        {showReward ? (
          <StyledWinImage className={clsx(styles.result, 'animate__animated animate__fadeIn')} $bgImage={wheelWinImage}>
            <p className={styles.winText}>{translate('popups.wheel.you.won')}</p>
            <span className={styles.winText}>
              {reward?.prize} {reward?.displayName}
            </span>
          </StyledWinImage>
        ) : null}
      </StyledWheelImage>
    </div>
  );
};

export default WheelOfFortuneWheel;

const StyledWheelImage = styled.div.withConfig({
  shouldForwardProp: (prop) => !prop.includes('$'),
})<{ $bgImage: string }>`
  ${({ $bgImage }) => ($bgImage ? `background-image: url(${$bgImage});` : '')}
`;

const StyledWinImage = styled.div.withConfig({
  shouldForwardProp: (prop) => !prop.includes('$'),
})<{ $bgImage: string }>`
  ${({ $bgImage }) => ($bgImage ? `background-image: url(${$bgImage});` : '')}
`;
