import { gql } from '@apollo/client';

export const EXCHANGE_RATE = gql`
  query exchangeRate($tokenCode: String!) {
    exchangeRate(tokenCode: $tokenCode) {
      usdValue
    }
  }
`;

export const EXCHANGE_RATES = gql`
  query exchangeRates {
    exchangeRates {
      token {
        tokenCode
        displayName
      }
      usdValue
      btcValue
      eurValue
      updatedAt
    }
  }
`;
