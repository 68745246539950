import dayjs from 'dayjs';

import depositIcon from 'assets/img/Notification/deposit.webp';
import withdrawIcon from 'assets/img/Notification/withdrawal.webp';
import tipsIcon from 'assets/img/Notification/tips.webp';
import eventIcon from 'assets/img/Notification/event.webp';
import rankIcon from 'assets/img/Notification/rank.webp';
import bonusIcon from 'assets/img/Notification/bonus.webp';
import lotteryIcon from 'assets/img/Notification/lottery.webp';
import giftIcon from 'assets/img/common/giftHand.webp';

import { NotificationType } from 'types';
import { TOKEN_CODES } from 'components/constants/constants';
import { amount, getDate, getImgSrc } from './common';

type NotificationIcons = {
  [key in NotificationType]: string;
};

const NOTIFICATION_ICONS: NotificationIcons = {
  deposit: depositIcon,
  withdraw: withdrawIcon,
  tips: tipsIcon,
  event: eventIcon,
  rank: rankIcon,
  bonus: bonusIcon,
  customBonus: giftIcon,
  lottery: lotteryIcon,
};

export const getNotificationIconByType = (type: NotificationType): string => {
  return NOTIFICATION_ICONS[type];
};

export const getNotificationTime = (date: string): string => {
  const now = dayjs();
  const formattedDate = dayjs(date);

  const diffInSeconds = now.diff(formattedDate, 'second');

  if (diffInSeconds < 5) {
    return 'now';
  }

  if (diffInSeconds < 60) {
    return `${diffInSeconds} ${diffInSeconds > 1 ? 'seconds' : 'second'} ago`;
  }

  const diffInMinutes = now.diff(formattedDate, 'minutes');

  if (diffInMinutes < 60) {
    return `${diffInMinutes} ${diffInMinutes > 1 ? 'minutes' : 'minute'} ago`;
  }

  const diffInHours = now.diff(formattedDate, 'hours');

  if (diffInHours < 24) {
    return `${diffInHours} ${diffInHours > 1 ? 'hours' : 'hour'} ago`;
  }

  const diffInDays = now.diff(formattedDate, 'days');

  if (diffInDays < 31) {
    return `${diffInDays} ${diffInDays > 1 ? 'days' : 'day'} ago`;
  }

  return getDate(date);
};

const handleNotificationAmount = (value: string) => {
  return String(amount(value, true));
};

export const getNotificationsContent = (content: string): JSX.Element => {
  const NUMBER_REGEX = /\d/gim;

  if (NUMBER_REGEX.test(content)) {
    const TOKEN_CODE_REGEX = new RegExp(`(${TOKEN_CODES.join('|')})`, 'gmi');
    const tokenCodes = content.match(TOKEN_CODE_REGEX);

    if (tokenCodes) {
      const tokenCode = tokenCodes[0];
      const icon = getImgSrc(tokenCode);

      const firstIndex = content.search(NUMBER_REGEX);

      return icon ? (
        <>
          <span>{content.slice(0, firstIndex)}</span>
          <img src={icon} alt={tokenCode} />
          <span>{content.slice(firstIndex).replace(/[\d.]+/gim, handleNotificationAmount)}</span>
        </>
      ) : (
        <>{content}</>
      );
    }
  }

  return <>{content}</>;
};
