import { ETokenCode } from 'components/constants/constants';
import { CardGameStake, IBetHistory } from 'types';

export type TexasHoldemBetField = 'main' | 'bonus';
export type TexasHoldemTokenCode = Exclude<ETokenCode, ETokenCode.STAR>;

type TexasHoldemLowStakeChip = '0.1' | '0.5' | '1' | '5' | '10';
type TexasHoldemHighStakeChip = '1' | '20' | '50' | '100' | '1000';

export type TexasHoldemChips = {
  [key in TexasHoldemLowStakeChip | TexasHoldemHighStakeChip]?: number;
};

export enum TexasHoldemActions {
  fold = 'fold',
  deal = 'deal',
  clear = 'clear',
  back = 'back',
  check = 'check',
  x1 = 'x1',
  x2 = 'x2',
  x3 = 'x3',
  x4 = 'x4',
  rebet = 'rebet',
  rebetDouble = 'rebetDouble',
  newBet = 'newBet',
}

export enum TexasHoldemGameState {
  rebet = 'rebet',
  notStarted = 'notStarted',
  preFlop = 'Pre-Flop',
  flop = 'Flop',
  turn = 'Turn',
  multiply = 'multiply',
  finished = 'finished',
}

export enum TexasHoldemStage {
  preFlop = 'Pre-Flop',
  flop = 'Flop',
  turn = 'Turn',
}

export enum TexasHoldemCombination {
  HighCard = 'HIGH_CARD',
  OnePair = 'ONE_PAIR',
  TwoPairs = 'TWO_PAIRS',
  ThreeOfAKind = 'THREE_OF_A_KIND',
  Straight = 'STRAIGHT',
  Flush = 'FLUSH',
  FullHouse = 'FULL_HOUSE',
  FourOfAKind = 'FOUR_OF_A_KIND',
  StraightFlush = 'STRAIGHT_FLUSH',
  RoyalFlush = 'ROYAL_FLUSH',
}

export enum TexasHoldemOutcome {
  win = 'WIN',
  loss = 'LOSS',
  tie = 'TIE',
  fold = 'FOLD',
}

export interface TexasHoldemBets {
  ante: string;
  blind: string;
  trips?: string;
  play?: string;
}

export interface ITexasHoldemHand {
  combination: TexasHoldemCombination | null;
  combinationCards: string[] | null;
  kickerCard: string | null;
  cards: string[];
}

export interface ITexasHoldemLimits {
  min: number;
  max: number;
}

export interface ITexasHoldemBetAmount {
  id: string;
  amount: string;
}

export interface ITexasHoldemChip {
  icon: string;
  value: string;
  multiplier: number;
  color: string;
}

interface TexasHoldemResult {
  boardCards: string[];
  outcome: TexasHoldemOutcome;
  payouts: TexasHoldemBets;
  dealerHand: ITexasHoldemHand;
  playerHand: ITexasHoldemHand;
}

export type TexasHoldemBetHistory = IBetHistory<TexasHoldemResult>;

export interface ITexasHoldemGameContext {
  ante: ITexasHoldemBetAmount[];
  anteLimits: ITexasHoldemLimits;
  trips: ITexasHoldemBetAmount[];
  tripsLimits: ITexasHoldemLimits;
  maxPayout: string;
  play: ITexasHoldemBetAmount[];
  mainSum: string;
  bonusSum: string;
  playSum: string;
  playerHand: ITexasHoldemHand;
  communityCards: string[];
  dealerHand: ITexasHoldemHand;
  payouts: TexasHoldemBets | null;
  outcome: TexasHoldemOutcome | '';
  gameState: TexasHoldemGameState;
  previousGameState: TexasHoldemGameState | null;
  fastMode: boolean;
  withHotKeys: boolean;
  animationInProgress: boolean;
  history: TexasHoldemBetHistory[];
  chip: ITexasHoldemChip;
  chips: ITexasHoldemChip[];
  stake: CardGameStake;
  onChangeBetAmount: (field: TexasHoldemBetField) => void;
  onChangeChip: (c: ITexasHoldemChip) => void;
  onChangeFastMode: (fastMode: boolean) => void;
  onChangeWithHotKeys: (hotkeys: boolean) => void;
  onMultiplyBy: (multiplier: number) => void;
  onRoundHash: () => Promise<string>;
  onCheck: VoidFunction;
  onFold: VoidFunction;
  onDeal: VoidFunction;
  onRebet: (double: boolean) => void;
  onNewBet: VoidFunction;
  onBack: VoidFunction;
  onClear: VoidFunction;
}
