import styled from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

const LotteryDesktopResultStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0;

  .lot__result-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    padding-right: 10px;
  }

  .lot__result-header-title {
    width: 71px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    letter-spacing: 0.12px;

    color: #ffffff;
  }

  .lot__result-table {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .lot__result-table-col {
    display: flex;
    width: 100%;
    background: rgba(22, 32, 54, 0.5);
    mix-blend-mode: normal;
    border-radius: 14px;
    margin: 5px 0;
    height: 45px;
    padding-left: 5px;
  }

  .lot__result-table-row1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      margin: 0;

      text-align: center;

      color: #c4c4c4;

      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.55);

      border: 1px solid #c4c4c4;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
    }

    img {
      width: 20px;
    }
  }

  .lot__result-avatar {
    margin: 0 10px 0 0;

    width: 25px;
  }

  .lot__result-table-row2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 40%;
    cursor: url(${point}), auto !important;

    h5 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 25px;
      margin: 0;

      color: #ffffff;
    }
  }
  .lot__result-table-row3 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    img {
      width: 30px;
      display: inline-block;
    }
    p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: #696c80;
      margin: 0;
    }
    h3 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      margin: 0;

      color: #ffffff;
    }
  }

  @media (max-width: 1300px) {
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1100px) {
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 640px) {
  }
  @media (max-width: 580px) {
  }
  @media (max-width: 500px) {
  }
  @media (max-width: 400px) {
  }
`;

export default LotteryDesktopResultStyle;
