import styled, { keyframes } from 'styled-components';
import point from 'assets/img/common/cursor-pointer.webp';

interface Props {
  disabled?: string;
  border?: string;
  toparrow?: string;
  shadow?: string;
  currencystyle?: string;
  config?: string;
  arrowwidth?: string;
}

export const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

export const Label = styled.label.withConfig({
  shouldForwardProp: (prop) => !['shadow'].includes(prop),
})<Props>`
  position: relative;
  display: inline-block;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin: ${(props) => (props.currencystyle ? '8px 5px' : '0.6em 1em')};
`;

const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

export const Indicator = styled.div.withConfig({
  shouldForwardProp: (prop) => !['config'].includes(prop),
})<Props>`
  width: ${(props) => {
    if (props.config === 'twoFA') {
      return '16px';
    }
    return '1.2em';
  }};
  height: ${(props) => {
    if (props.config === 'twoFA') {
      return '16px';
    }
    return '1.2em';
  }};
  position: absolute;
  top: ${(props) => (props.toparrow ? '0' : '0.2em')};
  top: ${(props) => props.currencystyle && '4px'};
  left: 0;
  //border: ${(props) => (props.shadow ? '2px solid red' : props.border ? '2px solid #727274' : 'none')};
  border: ${(props) => {
    if (props.config === 'purple') return '2px solid transparent';
    if (props.shadow) return '2px solid red';
    if (props.border) return '2px solid #727274';
    return 'none';
  }};
  border-radius: 0.2em;
  background: ${(props) => props.config === 'purple' && '#6C5DD3'};

  ${Input}:not(:disabled):checked & {
    background: #727274;
  }

  ${Label}:hover & {
    background: #727274;
    cursor: url(${point}), auto !important;
  }

  &::after {
    content: '';
    position: absolute;
    display: none;
    color: #52c330;
  }

  ${Input}:checked + &::after {
    display: block;
    top: -0.1em;
    left: 0.3em;
    width: ${(props) => (props.currencystyle ? '35%' : '45%')};
    height: ${(props) => (props.currencystyle ? '65%' : '90%')};
    //border: solid #52c330;
    border: ${(props) => (props.config === 'purple' ? 'solid #ffffff' : 'solid #52c330')};
    border-width: ${(props) => (props.arrowwidth ? props.arrowwidth : '0 0.15em 0.15em 0')};
    animation-name: ${rotate};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
